import { createContext, useContext, useMemo } from 'react';
import { useAuthUser } from '../../../api';
import { MonitorSpec } from '../types';
import { AddMonitorStore } from './store';

const AddMonitorStoreContext = createContext<AddMonitorStore | null>(null);

export interface AddMonitorStoreProviderProps {
	children: React.ReactNode;
	initialMonitorSpec: MonitorSpec;
	initialIntegration?: string;
	initialTable?: string;
}

export function AddMonitorStoreProvider({
	children,
	initialMonitorSpec,
	initialIntegration,
	initialTable,
}: AddMonitorStoreProviderProps) {
	const { user } = useAuthUser();

	const store = useMemo(
		() =>
			new AddMonitorStore(
				initialMonitorSpec,
				user.id,
				initialTable,
				initialIntegration
			),
		[initialMonitorSpec, user.id, initialIntegration, initialTable]
	);
	return (
		<AddMonitorStoreContext.Provider value={store}>
			{children}
		</AddMonitorStoreContext.Provider>
	);
}

export function useAddMonitorStoreContext() {
	const ctx = useContext(AddMonitorStoreContext);
	if (ctx === null) {
		throw new Error(
			'useFormContext was called outside of FormProvider context'
		);
	}

	return ctx;
}
