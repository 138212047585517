import type { LiteQueryOut } from '@repo/api-codegen';
import { makeAutoObservable } from 'mobx';
import type { IQuery } from '../../../api';
import type { Lineage } from '../../../lib/models';

export interface EntityTabsStore {
	lineageCount?: number;
	setLineageCount: (count: number) => void;
	lineageUpstreamResults?: Lineage[];
	lineageDownstreamResults?: Lineage[];
	setLineageUpstreamResults?: (results: Lineage[]) => void;
	setLineageDownstreamResults?: (results: Lineage[]) => void;
	creationQuery?: IQuery | LiteQueryOut;
	discussionCount?: number;
	setDiscussionCount?: (count: number) => void;
	setCreationQuery?: (query: IQuery | LiteQueryOut) => void;
	queryCount?: number;
	setQueryCount?: (count: number) => void;
}

export class TableEntityTabsStore implements EntityTabsStore {
	discussionCount: number | undefined = undefined;

	columnCount: number | undefined = undefined;

	lineageCount: number | undefined = undefined;

	queryCount: number | undefined = undefined;

	testCount: number | undefined = undefined;

	// Only populated on some SecodaEntity elements.
	creationQuery: IQuery | LiteQueryOut | undefined = undefined;

	incidentsCount: number | undefined = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	get isDiscussionLoading() {
		return this.discussionCount === undefined;
	}

	get isColumnLoading() {
		return this.columnCount === undefined;
	}

	get isLineageLoading() {
		return this.lineageCount === undefined;
	}

	get isQueryLoading() {
		return this.queryCount === undefined;
	}

	get isTestLoading() {
		return this.testCount === undefined;
	}

	get isIncidentsLoading() {
		return this.incidentsCount === undefined;
	}

	reset = () => {
		this.columnCount = undefined;
		this.lineageCount = undefined;
		this.queryCount = undefined;
		this.testCount = undefined;
		this.creationQuery = undefined;
		this.discussionCount = undefined;
		this.incidentsCount = undefined;
	};

	setCreationQuery = (query: IQuery | LiteQueryOut) => {
		this.creationQuery = query;
	};

	setLineageCount = (count: number) => {
		this.lineageCount = count;
	};

	setColumnCount = (count: number) => {
		this.columnCount = count;
	};

	setQueryCount = (count: number) => {
		this.queryCount = count;
	};

	setTestCount = (count: number) => {
		this.testCount = count;
	};

	setDiscussionCount = (count: number) => {
		this.discussionCount = count;
	};

	setIncidentsCount = (count: number) => {
		this.incidentsCount = count;
	};
}
