import { Box, Group, Stack, Tooltip } from '@mantine/core';
import { Text } from '@repo/foundations';
import { colors } from '@repo/theme/primitives';
import { isNil } from 'lodash-es';
import { MetricNumericFormat } from '../../../api';
import { formatMetricNumber } from '../../../utils/metrics';

interface LegendElementProps {
	value: number;
	color: string;
	name: string;
	decimals?: number;
	currency?: string;
	numericFormat?: MetricNumericFormat;
}

interface TruncatedTextProps {
	text: string;
}

function TruncatedText({ text }: TruncatedTextProps) {
	return (
		<Tooltip label={text} openDelay={500} closeDelay={500}>
			<Text truncate>{text}</Text>
		</Tooltip>
	);
}

export function LegendElement({
	value,
	color,
	name,
	currency = 'USD',
	decimals = 2,
	numericFormat = 'number',
}: LegendElementProps) {
	const showRound = !isNil(value) && value > 0;
	const showZero = !isNil(value) && value === 0;

	const valueString = (() => {
		if (showZero) {
			return '0';
		}
		if (showRound && !isNil(value)) {
			return formatMetricNumber(
				value,
				numericFormat,
				decimals,
				decimals,
				currency
			);
		}
		return value?.toString() ?? 'N/A';
	})();

	return (
		<Stack spacing={4} w={250}>
			<Group spacing={8} sx={{ fontSize: 11 }} color={colors.gray[7]}>
				<Box
					w={8}
					h={8}
					sx={{
						padding: 0,
						margin: 0,
						borderRadius: 8,
						backgroundColor: color,
					}}
				/>
				<TruncatedText text={name} />
			</Group>
			<Group sx={{ fontSize: 24 }} color={colors.dark[7]} fw={500}>
				<TruncatedText text={valueString} />
			</Group>
		</Stack>
	);
}
