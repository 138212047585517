import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import nameToEmoji from 'gemoji/name-to-emoji.json';
import type Token from 'markdown-it/lib/token';
import { InputRule } from 'prosemirror-inputrules';
import type { NodeSpec, Node as ProsemirrorNode } from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import { TextSelection } from 'prosemirror-state';
import emojiRule from '../rules/emoji';
import type { Dispatch } from '../types';
import Node, { NodeOptions } from './Node';

export default class Emoji extends Node {
	get name() {
		return 'emoji';
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				'data-name': {
					default: undefined,
				},
			},
			inline: true,
			content: 'text*',
			marks: '',
			group: 'inline',
			selectable: false,
			parseDOM: [
				{
					tag: 'span.emoji',
					preserveWhitespace: 'full',
					getAttrs: (dom: HTMLElement | string) =>
						typeof dom === 'string'
							? null
							: {
									'data-name': dom.dataset.name,
								},
				},
			],
			toDOM: (node) => {
				// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
				if (nameToEmoji[node.attrs['data-name']]) {
					const text = document.createTextNode(
						// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
						nameToEmoji[node.attrs['data-name']]
					);
					return [
						'span',
						{
							class: `emoji ${node.attrs['data-name']}`,
							'data-name': node.attrs['data-name'],
						},
						text,
					];
				}
				const text = document.createTextNode(`:${node.attrs['data-name']}:`);
				return ['span', { class: 'emoji' }, text];
			},
		};
	}

	get rulePlugins() {
		return [emojiRule];
	}

	commands({ type }: NodeOptions) {
		return (attrs?: Record<string, string>) =>
			(state: EditorState, dispatch: Dispatch) => {
				const { selection } = state;
				const position =
					selection instanceof TextSelection
						? selection.$cursor?.pos
						: selection.$to.pos;
				if (position === undefined) {
					return false;
				}

				const node = type.create(attrs);
				const transaction = state.tr.insert(position, node);
				dispatch(transaction);
				return true;
			};
	}

	inputRules({ type }: NodeOptions): InputRule[] {
		return [
			new InputRule(/^:([a-zA-Z0-9_+-]+):$/, (state, match, start, end) => {
				const [okay, markup] = match;
				const { tr } = state;
				if (okay) {
					tr.replaceWith(
						start - 1,
						end,
						type.create({
							'data-name': markup,
							markup,
						})
					);
				}

				return tr;
			}),
		];
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		const name = node.attrs['data-name'];
		if (name) {
			state.write(`:${name}:`);
		}
	}

	parseMarkdown() {
		return {
			node: 'emoji',
			getAttrs: (tok: Token) => ({ 'data-name': tok.markup.trim() }),
		};
	}
}
