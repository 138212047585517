import type { ISecodaEntity } from '../../api';
import type { EntityTabsStore } from '../../pages/TableEntityPage/TableEntityTabs/TableEntityTabsStore';
import { QueryList as QueryListV2 } from '../QueryListV2/QueryList';

export interface IQueryListProps {
	entity: ISecodaEntity;
	store: EntityTabsStore;
}
export function QueryList({ entity, store }: IQueryListProps) {
	return <QueryListV2 entity={entity} store={store} />;
}
