import { Divider } from '@mantine/core';
import { useDebounceFn } from 'ahooks';
import produce from 'immer';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import {
	commentsQueryKeyFactory,
	IComment,
	queryClient,
	useAuthUser,
	useCommentList,
	useCreateComment,
	useDeleteComment,
	useDictionaryTerm,
	useUpdateComment,
	useUpdateDictionaryTerm,
	useWorkspace,
} from '../../api';
import { CommentSidebar } from '../../components/Comment/CommentSidebar.tsx';
import { useCommentStoreContext } from '../../components/Comment/context.tsx';
import EditorWithComments from '../../components/Comment/EditorWithComments.tsx';
import EntityPageActions from '../../components/EntityPageLayout/EntityPageActions';
import EntityPageNavBar from '../../components/EntityPageLayout/EntityPageNavBar';
import EntityPageToggles from '../../components/EntityPageLayout/EntityPageToggles';
import { EntityPageDescription } from '../../components/EntityPageLayout/index.ts';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../../components/PageLayout';
import { DictionaryTerm } from '../../lib/models';
import { EntityType } from '../../lib/types.ts';
import { WithOnlyIdRequired } from '../../lib/typescript.ts';
import { trackEvent } from '../../utils/analytics';
import { isViewerOfEntity } from '../../utils/authorization/roles.ts';
import { useRedirectURLIncludingTitle } from '../../utils/hook/useRedirectURLIncludingTitle';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';

export interface IDictionaryTermPageProps {
	id?: string;
}

function DictionaryTermPage({ id: propsId }: IDictionaryTermPageProps) {
	const paramsId = useParamsIdSuffixUuid();
	const id = propsId || paramsId;

	const { workspace } = useWorkspace();
	const { user } = useAuthUser();
	const navigate = useNavigate();

	useEffect(() => {
		if (workspace.migrated_glossary) {
			trackEvent(
				'dictionary_term/redirect_to_glossary',
				{ id },
				user,
				workspace
			);
			navigate(`/glossary/${id}`, { replace: true });
		}
	}, [id, navigate, user, workspace]);

	const { data: dictionaryTerm } = useDictionaryTerm({
		id,
		options: {
			useErrorBoundary: true,
			onSuccess: () => {
				trackEvent('dictionary_term/open', {}, user, workspace);
			},
			cacheTime: 1000,
			suspense: true,
		},
	});

	useRedirectURLIncludingTitle(dictionaryTerm);

	const { mutateAsync } = useUpdateDictionaryTerm({});

	const { run: updateDictionaryTerm } = useDebounceFn(
		async (key: string, value: DjangoValueType) => {
			await mutateAsync({ data: { id, [key]: value } });
		},
		{ wait: 500 }
	);

	const handleEntityChange = useCallback(
		(key: string) =>
			async (value: DjangoValueType, saveRemotely = true) => {
				updateDictionaryTerm(key, value);
				trackEvent(`dictionary_term/${key}/update`, { id }, user, workspace);
			},
		[id, updateDictionaryTerm, user, workspace]
	);

	const dictionaryTermModel = useMemo(() => {
		if (!dictionaryTerm) {
			return null;
		}
		return new DictionaryTerm(dictionaryTerm);
	}, [dictionaryTerm]);

	if (!dictionaryTerm || !dictionaryTermModel) {
		return null;
	}

	const readOnly = isViewerOfEntity(user, dictionaryTerm);

	const {
		handleProseMirrorRemoveComment,
		setCommentIds,
		reorderComments,
		setPlaceholderVisible,
		setUploadingEditorID,
	} = useCommentStoreContext();

	const [removedCommentID, setRemovedCommentID] = useState<string | null>(null);

	const { data: commentThreads } = useCommentList({
		filters: { entity_id: id },
		options: {
			refetchOnMount: 'always',
			select: (data) =>
				produce({}, (draftState: { [rootID: string]: IComment[] }) => {
					data?.results.forEach((comment) => {
						const { root } = comment;
						if (!root) {
							return;
						}
						if (!draftState[root]) {
							draftState[root] = [comment];
						} else {
							draftState[root].push(comment);
							draftState[root].sort((a, b) =>
								a.created_at < b.created_at ? -1 : 1
							);
						}
					});
				}) as { [rootID: string]: IComment[] },
			onSuccess(data) {
				setCommentIds(Object.keys(data) as string[]);
				setPlaceholderVisible(false);
				setUploadingEditorID('');
				reorderComments();
			},
		},
	});

	const invalidateCommentQuery = () => {
		queryClient.invalidateQueries(
			commentsQueryKeyFactory.list(1, { entity_id: id })
		);
	};

	const { mutateAsync: createComment } = useCreateComment({
		options: {
			onSuccess: invalidateCommentQuery,
		},
	});

	const { mutateAsync: updateComment } = useUpdateComment({
		options: {
			onSuccess: invalidateCommentQuery,
		},
	});
	const { mutateAsync: deleteComment } = useDeleteComment({
		options: {
			onSuccess: () => {
				invalidateCommentQuery();
				if (removedCommentID) {
					handleProseMirrorRemoveComment(removedCommentID);
					setRemovedCommentID(null);
				}
			},
		},
	});

	const handleCreateComment = useCallback(
		async (data: Partial<Omit<IComment, 'id'>>): Promise<IComment> =>
			createComment({
				data: {
					entity: id,
					...data,
				},
			}),
		[createComment, id]
	);

	const handleUpdateComment = useCallback(
		async (data: WithOnlyIdRequired<IComment>) => {
			await updateComment({
				data,
			});
		},
		[updateComment]
	);

	const handleDeleteComment = useCallback(
		async (commentID: string) => {
			setRemovedCommentID(commentID);
			await deleteComment({ id: commentID });
		},
		[deleteComment]
	);

	return (
		<PageLayoutOuterWrapper key={dictionaryTerm.id}>
			<Helmet>
				<title>{dictionaryTerm?.title ?? 'Dictionary Term'}</title>
			</Helmet>
			<PageLayoutWrapper name="dictionary-term">
				<PageLayoutContentWrapper name="dictionary-term">
					{dictionaryTerm && (
						<EntityPageNavBar
							entity={dictionaryTerm}
							actions={<EntityPageActions entity={dictionaryTerm} />}
							toggles={
								<EntityPageToggles entity={dictionaryTerm} withComment />
							}
						/>
					)}
					<PageLayoutContent>
						<EditorWithComments
							entity={dictionaryTerm}
							commentThreads={commentThreads || {}}
							handleCreateComment={handleCreateComment}
							handleUpdateComment={handleUpdateComment}
							handleDeleteComment={handleDeleteComment}
							handleEntityChange={handleEntityChange}
							entityType="dictionary"
							children={
								<>
									<EntityPageDescription
										entityId={dictionaryTerm.id}
										description={dictionaryTerm?.description ?? ''}
										readOnly={readOnly}
										onChange={handleEntityChange('description')}
										integrationId={dictionaryTerm.integration}
									/>
									<Divider />
								</>
							}
						/>
					</PageLayoutContent>
				</PageLayoutContentWrapper>
				<CommentSidebar
					entity={dictionaryTerm}
					commentThreads={commentThreads || {}}
					handleCreateComment={handleCreateComment}
					handleUpdateComment={handleUpdateComment}
					handleDeleteComment={handleDeleteComment}
					updateEntity={handleEntityChange}
					entityType={EntityType.dictionary_term}
				/>
			</PageLayoutWrapper>
		</PageLayoutOuterWrapper>
	);
}

export default observer(DictionaryTermPage);
