import { Group, Image, Stack } from '@mantine/core';
import {
	MarketplaceIntegrationSpecVersionOut,
	useApiGetMarketplaceIntegrationSpecVersions,
} from '@repo/api-codegen';
import { Text, Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { useMarketplaceIntegrationSpec } from '../../api/hooks/marketplace';

import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';

import { FetchModelListHook } from '../../api/factories/types';
import { TableV2 } from '../../components/TableV2';
import { ExtendedDataTableColumn } from '../../components/TableV2/types';

import MarketplaceIntegrationNavbar from './MarketplaceIntegrationNavbar';
import { useColumns } from './MarketplaceIntegrationSpecDetailPage.hooks';
import MarketplaceIntegrationSpecSidebar from './MarketplaceIntegrationSpecSidebar';

function MarketplaceIntegrationSpecDetailPage() {
	const { specId } = useParams();

	const columns = useColumns();
	const navigate = useNavigate();

	const { data: spec } = useMarketplaceIntegrationSpec({
		id: specId as string,
	});

	if (!spec || !spec.versions.length) {
		return <FullWidthLoadingSpinner />;
	}

	// eslint-disable-next-line prefer-destructuring
	const latestVersion = spec.versions[0];

	return (
		<Group spacing={0} noWrap>
			<Stack
				align="flex-start"
				spacing={0}
				h="100%"
				sx={{
					flexGrow: 1,
					overflowX: 'auto',
				}}
			>
				<MarketplaceIntegrationNavbar />
				<Stack px="2xl" w="100%" spacing="md" sx={{ flexGrow: 1 }}>
					<Group spacing="xs">
						<Image
							src={latestVersion.icon_url}
							radius="sm"
							width={28}
							height={28}
						/>
						<Title size="xl">{latestVersion.name}</Title>
					</Group>
					<Text size="md" color="text/secondary/default">
						{latestVersion.description}
					</Text>

					<TableV2<MarketplaceIntegrationSpecVersionOut>
						onCellClick={(cell: {
							column: ExtendedDataTableColumn<MarketplaceIntegrationSpecVersionOut>;
							record: MarketplaceIntegrationSpecVersionOut;
						}) => {
							if (cell.column.navigate !== false) {
								navigate(
									`/marketplace-integration/${cell.record.spec_id}/version/${cell.record.id}`
								);
							}
						}}
						columns={columns}
						defaultRequiredOptions={{ pathParams: { specId } }}
						usePaginationList={
							useApiGetMarketplaceIntegrationSpecVersions as unknown as FetchModelListHook<MarketplaceIntegrationSpecVersionOut>
						}
						withInteractiveHeader={false}
						withFilters={false}
					/>
				</Stack>
			</Stack>
			<MarketplaceIntegrationSpecSidebar
				latestVersion={latestVersion}
				specId={spec.id}
				totalInstallations={spec.total_installations}
			/>
		</Group>
	);
}

export default observer(MarketplaceIntegrationSpecDetailPage);
