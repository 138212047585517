/* eslint-disable react/destructuring-assignment */
import { Loader } from '@mantine/core';
import { observer } from 'mobx-react-lite';

import { useEffect, useState } from 'react';
import { ISecodaEntity, ITest } from '../../../api';
import { TableV2 } from '../../../components/TableV2';

import { useTestList } from '../../../api/hooks/test';
import { SecodaEntity } from '../../../lib/models';
import { ColumnEntityTabsStore } from '../../ColumnEntityPage/ColumnEntityTabs/ColumnEntityTabsStore';
import type { TableEntityTabsStore } from '../../TableEntityPage/TableEntityTabs/TableEntityTabsStore';
import TestDrawer from './TestDrawer';
import { useColumns } from './hooks';

function Tests(props: {
	tests: ITest[] | undefined;
	entity: SecodaEntity | ISecodaEntity;
}) {
	const { entity } = props;
	const columns = useColumns();

	const [testDetailsShown, setTestDetailsShown] = useState(false);
	const [currentTest, setCurrentTest] = useState<ITest | null>(null);

	return (
		<>
			<TableV2
				onRowClick={(test) => {
					setTestDetailsShown(true);
					setCurrentTest(test);
				}}
				columns={columns}
				usePaginationList={useTestList}
				defaultSort={null}
				defaultRequiredSearchParams={{
					entity_id:
						entity.entity_type === 'table'
							? entity.id
							: (entity.parent as string),
				}}
				listFilterFunction={
					entity.entity_type === 'column'
						? (test) =>
								test.columns?.includes(entity.title || 'Untitled') ?? false
						: undefined
				}
			/>
			{currentTest && (
				<TestDrawer
					test={currentTest}
					opened={testDetailsShown}
					onClose={() => {
						setTestDetailsShown(false);
						setCurrentTest(null);
					}}
				/>
			)}
		</>
	);
}

interface ITestsProviderProps {
	entity: SecodaEntity | ISecodaEntity;
	store: TableEntityTabsStore | ColumnEntityTabsStore;
}

export function TestsProvider({ entity, store }: ITestsProviderProps) {
	const { data } = useTestList({
		filters: {
			entity_id:
				entity.entity_type === 'table' ? entity.id : (entity.parent as string),
		},
	});

	const filteredTests =
		(entity.entity_type === 'column'
			? data?.results.filter((test) =>
					test.columns?.includes(entity.title || 'Untitled')
				)
			: data?.results) || [];

	useEffect(() => {
		store.setTestCount(filteredTests.length);
	}, [filteredTests.length, store]);

	if (!data) {
		return <Loader />;
	}

	return <Tests tests={filteredTests} entity={entity} />;
}

export default observer(Tests);
