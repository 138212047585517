import { Icon } from '@repo/foundations';
import { IMetricWidget } from '../../../../api';
import { TimeRange } from '../../../../api/hooks/monitoring/useMeasurementsChartData';
import { ScrollableDropdownFilter } from '../../../../components/ScrollableDropdownFilter/ScrollableDropdownFilter';
import { LOOKBACK_KEY } from '../../utils/utils';

function MonitorFilters({
	onFilterChange,
	metricWidget,
}: {
	onFilterChange: (filterKey: string, value: string) => void;
	metricWidget: IMetricWidget;
}) {
	const icon = () => <Icon name="calendarEvent" />;
	return (
		<ScrollableDropdownFilter
			key={LOOKBACK_KEY}
			icon={icon}
			options={[
				{
					value: '7',
					label: 'Last week',
				},
				{
					value: '14',
					label: 'Last 14 days',
				},
				{
					value: '30',
					label: 'Last 30 days',
				},
			]}
			onChange={(value: TimeRange) => onFilterChange(LOOKBACK_KEY, value)}
			value={metricWidget.metric_metadata?.user_filter?.[LOOKBACK_KEY] || '7'}
		/>
	);
}

export default MonitorFilters;
