import { EmbedDescriptor, MenuItem } from '@repo/secoda-editor';
import { memo, useCallback, useMemo } from 'react';
import { useAuthUser } from '../../../../../api';
import getMenuItems from '../menus/block';
import { CommandMenu, CommandMenuProps } from './Toolbar/CommandMenu';
import { CommandMenuItem } from './Toolbar/CommandMenuItem';

type BlockMenuProps = Omit<
	CommandMenuProps,
	'renderMenuItem' | 'items' | 'id'
> &
	Required<Pick<CommandMenuProps, 'onLinkToolbarOpen'>> & {
		isTemplate: boolean;
		embeds: EmbedDescriptor[];
	};

export const BlockMenu = memo((props: BlockMenuProps) => {
	const { isTemplate, dictionary, embeds } = props;
	const { workspace } = useAuthUser();

	const items = useMemo(() => {
		const allItems: Array<MenuItem> = getMenuItems(
			dictionary,
			isTemplate,
			workspace.migrated_glossary ?? false
		);

		const embedsItems = embeds
			.filter((embed) => embed.title && embed.icon)
			.map((embed) => ({ ...embed, name: 'embed' }));

		if (embedsItems.length > 0) {
			allItems.push({ name: 'separator' });
			allItems.push(...embedsItems);
		}

		return allItems;
	}, [dictionary, embeds, isTemplate, workspace.migrated_glossary]);

	const renderMenuItem = useCallback(
		(item: MenuItem, _index: number, options: Record<string, unknown>) => (
			<CommandMenuItem
				key={_index}
				icon={item.icon}
				shortcutKeys={item.shortcutKeys}
				{...options}
			>
				{item.title}
			</CommandMenuItem>
		),
		[]
	);

	return (
		<CommandMenu
			{...props}
			id="block-menu-container"
			trigger="/"
			filterable
			renderMenuItem={renderMenuItem}
			items={items}
		/>
	);
});
BlockMenu.displayName = 'BlockMenu';
