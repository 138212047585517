import {
	createStyles,
	// eslint-disable-next-line no-restricted-imports
	SegmentedControl as MantineSegmentedControl,
	SegmentedControlProps as MantineSegmentedControlProps,
} from '@mantine/core';

export interface SegmentedControlProps extends MantineSegmentedControlProps {
	w?: string;
	size?: string;
}

const useStyles = createStyles(() => ({}));

function SegmentedControl({
	data,
	w,
	value,
	onChange,
	size,
}: SegmentedControlProps): JSX.Element {
	const { theme } = useStyles();
	return (
		<MantineSegmentedControl
			data={data}
			w={w}
			value={value}
			onChange={onChange}
			size={size}
			radius="md"
			px={theme.spacing.xs[1]}
			py={theme.spacing.xs[1]}
		/>
	);
}

export { SegmentedControl };
