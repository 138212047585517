import type { IconNames } from '@repo/foundations';
import type { SidebarSettings } from '../../api/types/models/team';

export const MENU_ITEMS: {
	key: keyof SidebarSettings;
	iconName: IconNames;
	label: string;
}[] = [
	{
		key: 'home',
		iconName: 'home',
		label: 'Home',
	},
	{
		key: 'catalog',
		iconName: 'listDetails',
		label: 'Catalog',
	},
	{
		key: 'collections',
		iconName: 'folders',
		label: 'Collections',
	},
	{
		key: 'metrics',
		iconName: 'chartHistogram',
		label: 'Metrics',
	},
	{
		key: 'dictionary',
		iconName: 'book',
		label: 'Dictionary',
	},

	{
		key: 'documents',
		iconName: 'fileDescription',
		label: 'Documents',
	},
	{
		key: 'questions',
		iconName: 'messages',
		label: 'Questions',
	},
];
