import { useQuery } from '@tanstack/react-query';
import { omit } from 'lodash-es';
import { documentsQueryKeyFactory } from '.';
import { apiClient, getEndpoints } from '../../common';
import type { IDocument } from '../../types';
import { useCurrentTeam } from '../team/myMemberships';

export function useGetOrCreateDocument({
	id,
	...props
}: Partial<IDocument> & { options: Record<string, unknown> }) {
	const { currentTeamId } = useCurrentTeam();

	return useQuery(
		[...documentsQueryKeyFactory.all(), 'get_or_create', id],
		async () => {
			if (!props.options?.enabled) {
				return null;
			}

			const data = omit(props, ['options']);

			const url = getEndpoints([...documentsQueryKeyFactory.namespace]).root();
			const { data: document } = await apiClient.post<IDocument>(url, {
				id,
				teams: [currentTeamId],
				...data,
			});
			// eslint-disable-next-line consistent-return
			return document;
		},

		{ suspense: true }
	);
}
