import { Box, createStyles } from '@mantine/core';
import { useMemo } from 'react';
import {
	fetchCatalogList,
	useCatalogList,
} from '../../../api/hooks/resourceCatalog';
import { TableV2 } from '../../../components/TableV2';
import { useDataQualityScoreColumns } from '../../../components/TableV2/SecodaEntity.hooks';
import { EntityType } from '../../../lib/types';
import {
	useActions,
	useColumns,
} from '../../TeamCatalogPage/TeamCatalogPage.hooks';

interface ITablesTabProps {
	schemaId: string;
}

const useStyles = createStyles({
	tableWrapper: {
		height: '100%',
	},
});

function TablesTab({ schemaId }: ITablesTabProps) {
	const { classes } = useStyles();

	const defaultColumns = useColumns();
	const dataQualityColumns = useDataQualityScoreColumns({
		catalogType: EntityType.table,
	});

	const columns = useMemo(
		() => [...defaultColumns, ...dataQualityColumns],
		[dataQualityColumns, defaultColumns]
	);

	const actions = useActions();

	return (
		<Box className={classes.tableWrapper}>
			<TableV2
				withCustomProperties={EntityType.table}
				pluralTypeString="tables"
				withSearch
				withInteractiveHeader
				withCsvExport
				withCheckbox
				withActions={actions}
				columns={columns}
				fetchPaginationList={fetchCatalogList}
				usePaginationList={useCatalogList}
				columnVisibility={{
					catalogType: EntityType.table,
					catalogServerType: EntityType.table,
				}}
				defaultRequiredSearchParams={{
					entity_type: EntityType.table,
				}}
				defaultRequiredCatalogFilters={{
					operands: [
						{
							operands: [],
							field: 'parent_id',
							operator: 'exact',
							value: schemaId,
						},
					],
				}}
			/>
		</Box>
	);
}

export default TablesTab;
