/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useAuthUser, useCollection, useUpdateCollection } from '../../api';

import IconEmojiSelector from '@repo/common/components/IconEmojiSelector';
import CollectionsEntityTabs from '../../components/CollectionsEntityTabs';
import { CollectionsEntityTabsStore } from '../../components/CollectionsEntityTabs/store';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import EntityPageLayout from '../../components/EntityPageLayout';
import { EntityType } from '../../lib/types';
import { trackEvent } from '../../utils/analytics';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';

export interface ICollectionPageProps {
	id?: string;
}

function CollectionPage({ id: propsId }: ICollectionPageProps) {
	const paramsId = useParamsIdSuffixUuid();
	const id = (propsId || paramsId) as string;

	const { tab } = useParams();
	const { user, workspace } = useAuthUser();

	const [store, setStore] = useState<CollectionsEntityTabsStore>();

	const { data: collectionEntity } = useCollection({
		id,
		options: {
			useErrorBoundary: true,
			onSuccess: () => {
				trackEvent('collection_entity/open', {}, user, workspace);
			},
			suspense: true,
		},
	});

	const { mutateAsync } = useUpdateCollection({
		disableInvalidation: true,
		options: {
			onSuccess: () => {
				trackEvent('collection/properties/update', {}, user, workspace!);
			},
		},
	});

	useEffect(() => {
		// We set a new store here to reset the state of the tabs. This is important
		// to avoid flickering, when switching between collections.
		if (collectionEntity) {
			const newStore = new CollectionsEntityTabsStore(collectionEntity, tab);
			setStore(newStore);
		}
	}, [collectionEntity, id, tab]);

	// Debouncing this is not a good idea, because it overwrites changes, does not
	// queue them.
	const updateCollectionEntity = useCallback(
		async (key: string, value: DjangoValueType) => {
			await mutateAsync({
				data: {
					id,
					[key]: value,
				},
			});
		},
		[id, mutateAsync]
	);

	const handleIconChange = useCallback(
		(value: string) => {
			updateCollectionEntity('icon', value);
		},
		[updateCollectionEntity]
	);

	if (!store || !collectionEntity) {
		return null;
	}

	return (
		<EntityPageLayout
			key={collectionEntity.id}
			name="Collection"
			entity={collectionEntity as never}
			icon={
				<IconEmojiSelector
					value={collectionEntity.icon ?? 'iconName:folder iconColor:#4a4a4a'}
					onChange={handleIconChange}
				/>
			}
			updateEntity={updateCollectionEntity}
			withDescription
			withPinnedToggle
			{...getEntityPageSidebarProps(EntityType.collection)}
		>
			<CollectionsEntityTabs store={store} />
		</EntityPageLayout>
	);
}

export default observer(CollectionPage);
