import { createStyles } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { observer } from 'mobx-react-lite';
import type { Monitor } from '../../../api';
import { useAuthUser, useDeleteMonitor } from '../../../api';
// eslint-disable-next-line
import AddMonitorButton from '../../../components/AddMonitor/AddMonitorButton';
import MonitorList from '../../MonitorListPage/MonitorList';
interface IMonitorsTabProps {
	integrationId: string;
	tableId: string;
}

const useStyles = createStyles({
	wrapper: {
		padding: 0,
	},
});

function MonitorsTab({ integrationId, tableId }: IMonitorsTabProps) {
	const { classes } = useStyles();
	const { mutateAsync: deleteMonitor } = useDeleteMonitor({});

	const { isEditorOrAdminUser } = useAuthUser();

	const handleDeleteMonitors = async (selected: Monitor[]) => {
		await Promise.all(
			selected.map((monitor) => deleteMonitor({ id: monitor.id }))
		);

		showNotification({
			title: 'Monitors deleted',
			message: `${selected.length} monitors deleted successfully`,
		});
	};

	return (
		<MonitorList
			defaultRequiredSearchParams={{
				target_id: tableId,
			}}
			withAdditionalButtons={
				isEditorOrAdminUser && (
					<AddMonitorButton
						initialIntegration={integrationId}
						initialTable={tableId}
					/>
				)
			}
		/>
	);
}

export default observer(MonitorsTab);
