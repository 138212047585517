import { useMemo, useState } from 'react';
import { QueryResults } from '../../../../../../lib/types';
import { guessColumnTypes } from '../../../../../../utils/metrics';

interface UseQueryResultsProps {
	results: QueryResults;
	pageSize?: number;
}

export function useQueryResults({
	results,
	pageSize = 10,
}: UseQueryResultsProps) {
	const [currentPage, setPage] = useState(0);

	return useMemo(() => {
		const allDataRows = results.slice(1);
		const totalPages = Math.ceil(allDataRows.length / pageSize);
		const startIndex = currentPage * pageSize;
		const endIndex = startIndex + pageSize;
		const dataRows = allDataRows.slice(startIndex, endIndex);
		const columnTypes = guessColumnTypes(results);

		return {
			header: results?.[0] ?? [],
			dataRows,
			totalRows: allDataRows.length,
			firstPageRow: startIndex + 1,
			lastPageRow: endIndex,
			nextPage: () => setPage((p) => (p < totalPages - 1 ? p + 1 : 0)),
			prevPage: () => setPage((p) => (p > 0 ? p - 1 : totalPages - 1)),
			columnTypes,
		};
	}, [results, pageSize, currentPage]);
}
