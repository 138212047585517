/* eslint-disable class-methods-use-this */
// eslint-disable-next-line max-classes-per-file
import { ActionIcon, MantineProvider, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Icon } from '@repo/foundations';
import { SecodaTheme } from '@repo/theme/mantine/secodaTheme';
import type { PluginView } from 'prosemirror-state';
import { Plugin } from 'prosemirror-state';
import type { EditorView } from 'prosemirror-view';
import ReactDOM from 'react-dom';
import { AttachmentContainer } from '../components/AttachmentContainer';
import Extension from '../lib/Extension';
class AttachmentButtonView implements PluginView {
	editorView: EditorView;
	attachmentWrapper?: HTMLDivElement;
	buttonTrigger?: HTMLDivElement;
	fileInput?: HTMLInputElement;
	onAttachmentUpload?: (url: string) => void;
	onAttachmentRemove?: (url: string) => void;

	uploadFile: (
		file: File,
		isImage: boolean,
		forAI?: boolean
	) => Promise<string>;

	async handleFileUpload(file: File) {
		const attachmentContainer = document.createElement('div');
		attachmentContainer.className = 'ai-attachment-upload-container';

		let uploadedUrl: string | undefined;

		const renderContainer = (href?: string) => {
			ReactDOM.render(
				<MantineProvider
					theme={{ ...SecodaTheme, colorScheme: 'light' }}
					withGlobalStyles
					withNormalizeCSS
				>
					<AttachmentContainer
						title={file.name}
						href={href}
						isSelected={false}
						onRemove={() => {
							attachmentContainer.remove();
							if (uploadedUrl && this.onAttachmentRemove) {
								this.onAttachmentRemove(uploadedUrl);
							}
						}}
					/>
				</MantineProvider>,
				attachmentContainer
			);
		};

		this.attachmentWrapper?.appendChild(attachmentContainer);
		renderContainer();

		try {
			const result = await this.uploadFile(file, false, true);
			renderContainer(result);
			if (this.onAttachmentUpload) {
				this.onAttachmentUpload(result);
			}
		} catch (error) {
			showNotification({
				title: 'Error',
				message: `File upload failed: ${error}`,
				color: 'red',
			});
		}
	}

	constructor(
		editorView: EditorView,
		uploadFile: (
			file: File,
			isImage: boolean,
			forAI?: boolean
		) => Promise<string>,
		onAttachmentUpload?: (url: string) => void,
		onAttachmentRemove?: (url: string) => void
	) {
		this.editorView = editorView;
		this.uploadFile = uploadFile;
		this.onAttachmentUpload = onAttachmentUpload;
		this.onAttachmentRemove = onAttachmentRemove;
		this.buttonTrigger = document.createElement('div');
		this.buttonTrigger.className = 'ai-attachment-trigger-wrapper';

		this.attachmentWrapper = document.createElement('div');
		this.attachmentWrapper.className = 'ai-attachment-upload-wrapper';

		this.editorView.dom.parentNode?.insertBefore(
			this.attachmentWrapper,
			this.editorView.dom.parentNode.firstChild
		);

		this.fileInput = Object.assign(document.createElement('input'), {
			type: 'file',
			multiple: true,
			style: { display: 'none' },
			accept: '.txt,.csv,.json',
			onchange: (e: Event) => {
				const { files } = e.target as HTMLInputElement;
				if (files?.length) {
					Promise.all([...files].map((file) => this.handleFileUpload(file)));
				}
			},
		});

		document.body.appendChild(this.fileInput);

		ReactDOM.render(
			<MantineProvider
				theme={{ ...SecodaTheme, colorScheme: 'light' }}
				withGlobalStyles
				withNormalizeCSS
			>
				<Tooltip label="Attach .txt, .csv, or .json files">
					<ActionIcon
						data-testid="attachment-button"
						size="md"
						id="attachment-menu-trigger"
						variant="subtle"
						onClick={() => {
							this.fileInput?.click();
						}}
					>
						<Icon name="paperclip" color="icon/primary/default" />
					</ActionIcon>
				</Tooltip>
			</MantineProvider>,
			this.buttonTrigger
		);
	}

	destroy() {
		this.buttonTrigger?.remove();
		this.fileInput?.remove();
	}
}

export default class AttachmentTrigger extends Extension {
	get name() {
		return 'attachmenttrigger';
	}

	get plugins() {
		return [
			new Plugin({
				view: (view) => {
					const attachmentButtonView = new AttachmentButtonView(
						view,
						this.options.uploadFile,
						this.options.onAttachmentUpload,
						this.options.onAttachmentRemove
					);
					if (attachmentButtonView.buttonTrigger) {
						view.dom.parentNode?.append(
							attachmentButtonView.buttonTrigger,
							view.dom
						);
					}
					return attachmentButtonView;
				},
			}),
		];
	}
}
