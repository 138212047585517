import { useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useAuthUser } from '../../api';
import { useAutomationList } from '../../api/hooks/automation';
import { TableV2 } from '../../components/TableV2';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { useActions, useColumns } from './AutomationList.hooks';

function AutomationList() {
	const { workspace } = useAuthUser();

	const columns = useColumns();
	const actions = useActions();

	const navigate = useNavigate();

	const handleClick = useCallback(
		(id: string) => {
			navigate(`/automation/${id}`);
		},
		[navigate]
	);

	if (!workspace.automation_enabled) {
		return <Navigate to="/" />;
	}

	return (
		<TableV2
			pluralTypeString="automations"
			columns={columns}
			columnVisibility={{
				catalogServerType: 'automations',
				catalogType: 'automation',
			}}
			usePaginationList={useAutomationList}
			withActions={actions}
			withCheckbox={true}
			withQuickActions={['actions::run']}
			withCsvExport
			withInteractiveHeader
			withSearch
			withFilters={false}
			defaultSort={null}
			onCellClick={(cell) => {
				if (
					(cell.column as ExtendedDataTableColumn<unknown>).navigate !== false
				) {
					handleClick(cell.record.id);
				}
			}}
		/>
	);
}

export default AutomationList;
