// eslint-disable-next-line no-restricted-imports
import { Badge, createStyles } from '@mantine/core';
import { ColorNames } from '@repo/theme/utils';
import { ForwardedRef, forwardRef } from 'react';
import { IncidentStatus } from '../../../api';

const BADGE_DEF: Record<
	IncidentStatus,
	{ textColor: ColorNames; bg: ColorNames; text: string }
> = {
	active: {
		textColor: 'text/critical/default',
		bg: 'fill/critical-secondary/default',
		text: 'Open',
	},
	acknowledged: {
		textColor: 'text/caution/default',
		bg: 'fill/caution-secondary/default',
		text: 'Acknowledged',
	},
	resolved: {
		textColor: 'text/success/default',
		bg: 'fill/success-secondary/default',
		text: 'Resolved',
	},
	expired: {
		textColor: 'text/primary/default',
		bg: 'surface/secondary/default',
		text: 'Expired',
	},
};

const useStyles = createStyles(
	(
		theme,
		{
			incidentStatus,
		}: {
			incidentStatus: IncidentStatus;
		}
	) => ({
		root: {
			background: theme.other.getColor(BADGE_DEF[incidentStatus].bg),
			borderRadius: theme.radius.sm,
			padding: `${theme.spacing['4xs']} ${theme.spacing['2xs']}`,
			margin: 0,
		},
		inner: {
			color: theme.other.getColor(BADGE_DEF[incidentStatus].textColor),
		},
	})
);

export interface IncidentStatusBadgeProps {
	incidentStatus: IncidentStatus;
}

export const IncidentStatusBadge = forwardRef(
	(
		{ incidentStatus }: IncidentStatusBadgeProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		const { classes } = useStyles({ incidentStatus });

		return (
			<Badge
				ref={ref}
				classNames={{ root: classes.root, inner: classes.inner }}
			>
				{BADGE_DEF[incidentStatus].text}
			</Badge>
		);
	}
);
IncidentStatusBadge.displayName = 'IncidentStatusBadge';
