/* eslint-disable react/destructuring-assignment */
import { Box, Group } from '@mantine/core';
import { useResizeObserver } from '@mantine/hooks';
import * as Plot from '@observablehq/plot';
import { chartColors, colors } from '@repo/theme/primitives';
import dayjs from 'dayjs';
import { isNil, maxBy, uniqBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { MetricNumericFormat } from '../../api';
// @ts-expect-error JS file
import { tooltip } from '../../utils/charts/TooltipMark/tooltipMark';
// @ts-expect-error JS file
import { pointer, pointerX } from '../../utils/charts/TooltipMark/pointer';
import { LegendElement } from './components/LegendElement';
import { Wrapper } from './components/Wrapper';
import Element = React.JSX.Element;

const MAX_LEGENDS = 10;

interface ChartProps {
	results: any[];
	primary: string;
	dimension?: string;
	callback?: (value: any) => void;
	hideLegend?: boolean;
	lineColor?: string;
	axisFontSize?: number;
	strokeWidth?: number;
	numericFormat?: MetricNumericFormat;
	tooltipRenderer?: (value: {
		x: number;
		y: number;
		dataIndex: number[] | null;
	}) => null | Element;
	width?: number;
}

const vectorChart: (
	props: ChartProps
) => (SVGSVGElement | HTMLElement) & Plot.Plot = ({
	results,
	primary,
	dimension,
	hideLegend = false,
	lineColor,
	axisFontSize,
	strokeWidth,
	tooltipRenderer,
	width,
}: ChartProps) => {
	const plotConfig = {
		// Transparent background is required for the "answered question" on the
		// `/question/<id>` page.
		width,
		style: 'width: 100%; background-color: transparent;',
		color: {
			range: lineColor ? [lineColor] : chartColors,
		},
		marks: [
			Plot.gridY({
				stroke: 'black',
				strokeWidth: 0.7,
			}),
			Plot.axisX({
				fontSize: axisFontSize || 8,
				color: colors.gray[6],
				tickSize: 0,
			}),
			Plot.axisY({
				tickFormat: 's',
				fontSize: axisFontSize || 8,
				color: colors.gray[6],
				label: null,
				tickSize: 0,
			}),
			Plot.dot(
				results,
				pointerX({
					x: 'time',
					y: primary,
					stroke: `${colors.gray[2]}`,
					r: 3,
				})
			),
			Plot.lineY(results, {
				curve: 'monotone-x',
				x: 'time',
				y: primary,
				z: 'series',
				strokeWidth: strokeWidth || 2,
				stroke: dimension ? 'series' : lineColor || colors.primary[6],
				sort: (a: { series: string }, b: { series: string }) =>
					a.series.localeCompare(b.series),
			}),
			Plot.crosshairX(results, {
				x: 'time',
				color: colors.gray[6],
			}),
		],
	};

	if (tooltipRenderer) {
		plotConfig.marks.push(
			tooltip(
				results,
				pointer({
					x: 'time',
					y: primary,
					tooltipRenderer: tooltipRenderer,
				})
			)
		);
	}

	return Plot.plot(plotConfig);
};

export function Chart(props: ChartProps) {
	const [hoverValues, setHoverValues] = useState<any[]>();
	const { callback, primary, dimension, results } = props;

	const [chartWidth, setChartWidth] = useState<number | undefined>();
	const [ref, rect] = useResizeObserver();
	useEffect(() => {
		setChartWidth(rect.width);
	}, [rect]);

	const legends = useMemo(() => {
		let legends = [primary];

		if (dimension) {
			legends = uniqBy(results, 'series')
				.map((r) => r.series)
				.slice(0, MAX_LEGENDS);
		}

		legends = legends.sort((a, b) => a.localeCompare(b));
		return legends;
	}, [primary, dimension, results]);

	const data = useMemo(() => {
		let filtered = results
			.sort((a, b) => a.time - b.time)
			.filter((r) => {
				if (isNil(r.time)) {
					return false;
				}

				if (dimension) {
					return legends.includes(r.series);
				}
				return true;
			});

		if (!dimension) {
			filtered = uniqBy(results, 'time');
		}

		return filtered;
	}, [results, legends, dimension]);

	const plot = useMemo(
		() =>
			vectorChart({
				...props,
				results: data,
				width: chartWidth,
			}),
		[data, props, chartWidth]
	);

	useEffect(() => {
		const listen = () => {
			const { value } = plot;
			if (value) {
				const values = results?.filter(
					(r: { time: Date }) => r.time?.getTime() === value.time?.getTime()
				);
				callback?.(values);
				setHoverValues(values);
			}
		};
		plot.addEventListener('input', listen);
		return () => {
			plot.removeEventListener('input', listen);
		};
	}, [plot, results, callback]);

	const largestValues = useMemo(() => {
		const largest = maxBy(results, (r) => dayjs(r.time).unix());
		return results?.filter(
			(r: { time: Date }) => r.time?.getTime() === largest?.time?.getTime()
		);
	}, [results]);

	return (
		<Box pos="relative">
			{!props.hideLegend && (
				<Group p={12} pb={0} mb={0}>
					{legends.map((legend, index) => (
						<LegendElement
							key={legend}
							value={
								(hoverValues ?? largestValues)?.find(
									(v) => v.series === legend
								)?.[primary]
							}
							color={
								dimension
									? chartColors[index % chartColors.length]
									: props.lineColor || colors.primary[6]
							}
							name={legend}
							numericFormat={props.numericFormat}
						/>
					))}
				</Group>
			)}
			<Wrapper vectorEl={plot} ref={ref} />
		</Box>
	);
}
