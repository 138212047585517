import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useMetricFromWidget } from '../../../api/hooks/metric/useMetric';
import { IAnalyticsMetric } from '../../../api/types/models/analyticsMetric';
import type { ISampleOrActualMetricWidget } from '../constants';
import {
	convertValue,
	DEFAULT_COLORS,
	downsample,
	findDefaultInterval,
	formatMetricWidgetTitle,
	isEmptyMetric,
} from '../utils/utils';
import { MetricLoadingOrEmpty } from './MetricLoadingOrEmpty';
import {
	MetricWidgetLineChart,
	type MetricWidgetLineChartProps,
} from './MetricWidgetLineChart/MetricWidgetLineChart';

export type IMetricWorkspaceLineChartProps = ISampleOrActualMetricWidget;

export function MetricWorkspaceLineChart(
	props: IMetricWorkspaceLineChartProps
) {
	const { metricWidget, source } = props;
	const { data: allDatapoints, isLoading } = useMetricFromWidget(metricWidget);

	const isLoadingOrEmpty = isEmptyMetric(allDatapoints) || isLoading;

	const chartProps = useMemo(() => {
		if (isLoadingOrEmpty) {
			return { channels: [], data: [] };
		}

		const datapoints = downsample(
			allDatapoints,
			findDefaultInterval(allDatapoints[0])
		);

		const channels = [
			{
				label: formatMetricWidgetTitle(metricWidget),
				x: 'date',
				y: 'value',
				color: DEFAULT_COLORS[0],
			},
		] as MetricWidgetLineChartProps<{
			date: Date;
			value: number;
		}>['channels'];

		const data = datapoints.map((point) => ({
			date: dayjs(point.created_at).toDate(),
			value: convertValue(point.value as number),
		}));

		// TODO [marcio]: to be memoized before release
		const getTooltipData = (dataIndex: number) => {
			const point = data[dataIndex] as IAnalyticsMetric & { date: Date };

			const items = [
				{
					label: formatMetricWidgetTitle(metricWidget),
					value: convertValue(point.value as number),
					color: DEFAULT_COLORS[0],
				},
			];

			return { date: point.date, items };
		};

		return { data, channels, getTooltipData };
	}, [allDatapoints, isLoadingOrEmpty, metricWidget]);

	if (isLoadingOrEmpty) {
		return (
			<MetricLoadingOrEmpty
				isLoading={isLoading}
				isEmpty={isEmptyMetric(allDatapoints)}
				source={source}
			/>
		);
	}

	return <MetricWidgetLineChart {...props} {...chartProps} />;
}
