// NOTE: We should avoid using `@mantine/core/Button` in favour of `@repo/foundations/Button`
// eslint-disable-next-line no-restricted-imports
import { Button, Group, useMantineTheme } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import type { IncidentStatus } from '../../../../api';
import { useIncident, useIncidentStatusUpdate } from '../../../../api';
import { notificationQueryKeyFactory } from '../../../../api/hooks/notification/constants';
import { useApplyAccessRequest } from '../../../../api/hooks/notification/useApplyAccessRequest';
import { useApplyChangeRequest } from '../../../../api/hooks/notification/useApplyChangeRequest';
import type { INotification } from '../../../../api/types/models/notification';
import {
	getIncidentStatusIcon,
	isAcknowledgeEnabled,
	isResolveEnabled,
} from '../../../IncidentPage/utils';
import { isMonitorIncidentEvent } from '../InboxViewContent/utils';

interface ITaskActionsProps {
	selected: INotification;
}

function TaskActions({ selected }: ITaskActionsProps) {
	const queryClient = useQueryClient();
	const theme = useMantineTheme();

	const { mutate: applyChangeRequest } = useApplyChangeRequest({
		id: selected.request?.id,
	});

	const { mutate: applyAccessRequest } = useApplyAccessRequest({
		id: selected.request?.id,
	});

	const isMonitorIncident = isMonitorIncidentEvent(selected.event);

	const { data: incident } = useIncident({
		id: selected.resource_id as string,
		options: {
			enabled: Boolean(selected.resource_id) && isMonitorIncident,
		},
	});

	const { mutateAsync: updateIncident } = useIncidentStatusUpdate();

	const handleIncidentStatusUpdate = (status: IncidentStatus) => async () => {
		if (!incident?.id) {
			return;
		}

		if (status === 'active' || status === 'expired') {
			return;
		}

		await updateIncident({
			data: {
				id: incident.id,
				monitorId: incident.monitor,
				status,
			},
		});

		if (status === 'resolved') {
			queryClient.invalidateQueries(notificationQueryKeyFactory.allLists());
		}
	};

	const handleRequestClick = () => {
		if (selected.event === 'change_request') {
			applyChangeRequest();
		} else if (selected.event === 'access_request') {
			applyAccessRequest();
		}
	};

	if (!isNil(incident) && isMonitorIncidentEvent(selected.event)) {
		const acknowledgeIcon = getIncidentStatusIcon('acknowledged', { size: 20 });
		const resolveIcon = getIncidentStatusIcon('resolved', { size: 20 });

		const showAcknowledgeButton = isAcknowledgeEnabled(incident?.status);
		const showResolveButton = isResolveEnabled(incident?.status);

		return (
			<Group spacing={theme.other.space[2]}>
				{showAcknowledgeButton && (
					<Button
						onClick={handleIncidentStatusUpdate('acknowledged')}
						disabled={!isNil(incident.acknowledged_at)}
						leftIcon={acknowledgeIcon}
					>
						Acknowledge
					</Button>
				)}
				{showResolveButton && (
					<Button
						onClick={handleIncidentStatusUpdate('resolved')}
						disabled={!isNil(incident.resolved_at)}
						leftIcon={resolveIcon}
					>
						Resolve
					</Button>
				)}
			</Group>
		);
	}

	if (
		!isNil(selected.request?.id) &&
		['change_request', 'access_request'].includes(selected.event)
	) {
		return (
			<Button
				disabled={!!selected.request?.applied}
				onClick={handleRequestClick}
			>
				{selected.request?.applied ? 'Applied' : 'Accept'}
			</Button>
		);
	}

	return null;
}

export default TaskActions;
