import { AutomationField } from '../../../api';
import type { AutomationActionCardType } from '../constants';

export enum DefaultAutomationCardType {
	TRIGGER = 'Trigger',
}

export type AutomationCardType =
	| DefaultAutomationCardType
	| AutomationActionCardType;

export enum AutomationValueType {
	TEXT_INPUT = 'TEXT_INPUT',
	DROPDOWN = 'DROPDOWN',
	DATE_INPUT = 'DATE_INPUT',
	MULTI_SELECT_DROPDOWN = 'MULTI_SELECT_DROPDOWN',
}

interface AutomationValueSettings {
	valueType: AutomationValueType;
}

export const AUTOMATION_CARD_VALUE_TYPE_MAP: {
	[key in AutomationField]: AutomationValueSettings;
} = {
	[AutomationField.DESCRIPTION]: {
		valueType: AutomationValueType.TEXT_INPUT,
	},
	[AutomationField.DEFINITION]: {
		valueType: AutomationValueType.TEXT_INPUT,
	},
	[AutomationField.TITLE]: {
		valueType: AutomationValueType.TEXT_INPUT,
	},
	[AutomationField.TITLE_FULL]: {
		valueType: AutomationValueType.TEXT_INPUT,
	},
	[AutomationField.DATABASE]: {
		valueType: AutomationValueType.TEXT_INPUT,
	},
	[AutomationField.SCHEMA]: {
		valueType: AutomationValueType.TEXT_INPUT,
	},
	[AutomationField.TABLE]: {
		valueType: AutomationValueType.TEXT_INPUT,
	},
	[AutomationField.INTERNAL_USAGE]: {
		valueType: AutomationValueType.TEXT_INPUT,
	},
	[AutomationField.EXTERNAL_USAGE]: {
		valueType: AutomationValueType.TEXT_INPUT,
	},
	[AutomationField.OWNERS]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.SUBSCRIBERS]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.TAGS]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.TEAMS]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.COLLECTIONS]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.PII]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.VERIFIED]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.PUBLISHED]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.QUALITY]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.QUALITY_ACCURACY]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.QUALITY_RELIABILITY]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.QUALITY_STEWARDSHIP]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.QUALITY_USABILITY]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.NATIVE_TYPE]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.INTEGRATION_ID]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.SOURCES]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.SLACK_CHANNELS]: {
		valueType: AutomationValueType.DROPDOWN,
	},
	[AutomationField.CREATED_AT]: {
		valueType: AutomationValueType.DATE_INPUT,
	},
	[AutomationField.EXTERNAL_UPDATED_AT]: {
		valueType: AutomationValueType.DATE_INPUT,
	},
};
