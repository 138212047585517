import { Group, HoverCard, Stack, useMantineTheme } from '@mantine/core';
import { IconButton, PlainButton, Text, TextInput } from '@repo/foundations';
import * as Yup from 'yup';

import { showNotification } from '@mantine/notifications';
import { useFormik } from 'formik';
import { isNil } from 'lodash-es';
import { FocusEventHandler, useCallback } from 'react';
import {
	IIntegration,
	useAuthUser,
	useUpdateIntegration,
} from '../../../../../api';
import { trackEvent } from '../../../../../utils/analytics.ts';
import {
	cronValidationMessage,
	isValidCron,
} from '../../../IntegrationSchedule/utils';

interface ScheduleInputProps {
	integration: IIntegration;
	type: 'PULL' | 'PUSH';
}

export function ScheduleInput({ integration, type }: ScheduleInputProps) {
	const theme = useMantineTheme();

	const { user, workspace } = useAuthUser();

	const { mutateAsync: updateIntegration } = useUpdateIntegration({});

	const formik = useFormik({
		initialValues: integration,
		validationSchema: Yup.object({
			scheduled_extractions_cron:
				type === 'PULL'
					? Yup.string().test(
							'is-valid-cron',
							(value) => cronValidationMessage(value.value || '', workspace.id),
							(value) => isNil(value) || isValidCron(value, workspace.id)
						)
					: Yup.string(),
			scheduled_push_metadata_cron:
				type === 'PUSH'
					? Yup.string().test(
							'is-valid-cron',
							(value) => cronValidationMessage(value.value || '', workspace.id),
							(value) => isNil(value) || isValidCron(value, workspace.id)
						)
					: Yup.string(),
		}),
		onSubmit: async (values) => {},
	});

	const handleBlur: FocusEventHandler<HTMLInputElement> =
		useCallback(async () => {
			const { values } = formik;
			try {
				const updateData: Record<string, string> = {};
				if (type === 'PULL' && values.scheduled_extractions_cron) {
					updateData['scheduled_extractions_cron'] =
						values.scheduled_extractions_cron;
				}
				if (type === 'PUSH' && values.scheduled_push_metadata_cron) {
					updateData['scheduled_push_metadata_cron'] =
						values.scheduled_push_metadata_cron;
				}

				if (Object.keys(updateData).length > 0) {
					await updateIntegration({
						data: {
							id: integration.id,
							...updateData,
						},
					});
					showNotification({
						message: 'Integration schedule updated successfully',
						color: 'green',
					});
					trackEvent(
						'integration/schedule/update',
						{
							label: integration.name,
							type: integration.type,
						},
						user!,
						workspace!
					);
				}
			} catch (error) {
				showNotification({
					message: 'Failed to update integration schedule',
					color: 'red',
				});
			}
		}, [
			formik,
			integration.id,
			integration.name,
			integration.type,
			type,
			updateIntegration,
			user,
			workspace,
		]);

	const handleLearnMore = () => {
		window.open('https://crontab.guru/', '_blank');
	};

	return (
		<form autoComplete="off">
			<Stack spacing="3xs">
				<Group spacing="3xs" align="center" noWrap>
					<Text size="sm" weight="semibold">
						Cron expression<span aria-hidden>{'*'}</span>
					</Text>
					<HoverCard width={theme.other.width.xs} position={'bottom-start'}>
						<HoverCard.Target>
							<IconButton variant="tertiary" iconName="infoCircle" size="sm" />
						</HoverCard.Target>
						<HoverCard.Dropdown>
							<Stack align="flex-start" spacing="xs">
								<Stack spacing="4xs">
									<Text size="sm" weight="semibold">
										Please enter a cron expression to schedule the extractions.
									</Text>
									<Text size="sm" color="text/secondary/default">
										For example, 0 0 * * * runs the extraction daily at midnight
										UTC. Ensure your cron expression is in UTC and not more
										frequent than once a day.
									</Text>
								</Stack>
								<PlainButton tone="default" size="sm" onClick={handleLearnMore}>
									Learn more
								</PlainButton>
							</Stack>
						</HoverCard.Dropdown>
					</HoverCard>
				</Group>
				<Stack spacing={theme.spacing['2xl']}>
					<TextInput
						name={
							type === 'PULL'
								? 'scheduled_extractions_cron'
								: 'scheduled_push_metadata_cron'
						}
						value={
							(type === 'PULL'
								? formik.values.scheduled_extractions_cron
								: formik.values.scheduled_push_metadata_cron) || ''
						}
						error={
							type === 'PULL'
								? formik.errors.scheduled_extractions_cron
								: formik.errors.scheduled_push_metadata_cron
						}
						onChange={formik.handleChange}
						onBlur={handleBlur}
						optional
					/>
				</Stack>
			</Stack>
		</form>
	);
}
