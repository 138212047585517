import { Group, GroupProps } from '@mantine/core';
import { Text } from '@repo/foundations';

type InlineRelatedResourcesBodyProps = GroupProps;

export function InlineRelatedResourcesBody({
	children,
	...rest
}: InlineRelatedResourcesBodyProps) {
	return (
		<Group spacing="xs" noWrap align="flex-start" {...rest}>
			<Text w={145} size="sm" py="4xs">
				Related
			</Text>
			{children}
		</Group>
	);
}
