import { Center, createStyles, MantineTheme, Stack } from '@mantine/core';
import { getSummaryAndBreadCrumbs } from '@repo/common/utils/breadcrumb';
import { IconButton, ListBox, Text } from '@repo/foundations';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import type { SecodaEntity } from '../../lib/models';
import { buildResourceUrl } from '../../utils/navigationUtils';
import { SecodaEntityIcon } from '../SecodaEntity';

interface IResourceSelectorItemProps {
	item: SecodaEntity;
	isViewerUser: boolean;
	onClick: (item: SecodaEntity) => void;
	getProps: (
		userProps: Omit<React.HTMLProps<HTMLElement>, 'ref'>
	) => Omit<React.HTMLProps<HTMLElement>, 'ref'>;
}

const useStyles = createStyles((theme: MantineTheme) => ({
	card: {
		cursor: 'pointer',
	},
	actionIcon: {
		backgroundColor: theme.other.getColor('fill/secondary/default'),
		width: 20,
		height: 20,
		padding: 0,
		margin: 5,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/secondary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/secondary/active'),
		},
	},
}));

function ResourceSelectorItem({
	item,
	isViewerUser,
	onClick,
	getProps,
}: IResourceSelectorItemProps) {
	const { theme } = useStyles();
	const [isHovered, setIsHovered] = useState(false);
	const navigate = useNavigate();

	const handleMouseEnter = useCallback(() => {
		setIsHovered(true);
	}, []);

	const handleMouseLeave = useCallback(() => {
		setIsHovered(false);
	}, []);

	const RightSection = useMemo(
		() => (
			<Center w={theme.other.iconSize.md}>
				{isHovered && (
					<IconButton
						iconName="chevronRight"
						variant="tertiary"
						onClick={(event) => {
							event.stopPropagation();
							navigate(
								buildResourceUrl({
									id: item.id,
									entity_type: item.entity_type,
								})
							);
						}}
					/>
				)}
			</Center>
		),
		[theme.other.iconSize.md, isHovered, navigate, item.id, item.entity_type]
	);

	const handleOnClick = () => {
		if (isViewerUser) {
			navigate(
				buildResourceUrl({ id: item.id, entity_type: item.entity_type })
			);
		} else {
			onClick(item);
		}
	};

	const breadcrumbs = getSummaryAndBreadCrumbs(
		item.native_type || item.entity_type,
		item.search_metadata
	);

	return (
		<ListBox.Item
			{...getProps({
				onClick: handleOnClick,
			})}
			rightSection={RightSection}
			icon={<SecodaEntityIcon entity={item} size={20} inverseIconColor />}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<Stack spacing={0}>
				<Text size="sm" lineClamp={1} data-testid="search-result-item">
					{item.title || 'Untitled'}
				</Text>
				<Text size="xs" color="text/secondary/default" lineClamp={1}>
					{breadcrumbs}
				</Text>
			</Stack>
		</ListBox.Item>
	);
}

export default ResourceSelectorItem;
