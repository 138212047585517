import { showNotification } from '@mantine/notifications';
import { pluralize } from '@repo/common/utils';
import { Icon } from '@repo/foundations';
import type { MutationFunction } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { capitalize, lowerCase } from 'lodash-es';
import { apiClient, getEndpoints } from '../../common';
import type { Namespace } from '../../types';
import { secodaEntitiesQueryKeyFactory } from './constants';

export type BulkDeleteData = Array<{
	id: string;
}>;

type RequestData = {
	data: BulkDeleteData;
	signal?: AbortSignal;
};

export function getDefaultBulkDeleteFn(
	namespace: Namespace
): MutationFunction<string[], RequestData> {
	return async ({ data, signal }) => {
		const baseUrl = getEndpoints(namespace).root();
		const url = `${baseUrl}bulk_delete`;

		const { data: responseData } = await apiClient.post<string[]>(url, data, {
			signal,
		});
		return responseData;
	};
}

interface UseBulkDeleteSecodaEntitiesArgs {
	name?: string;
}

function useBulkDeleteSecodaEntities({
	name = 'resources',
}: UseBulkDeleteSecodaEntitiesArgs = {}) {
	const { namespace } = secodaEntitiesQueryKeyFactory;
	const mutationFn = getDefaultBulkDeleteFn(namespace);

	return useMutation<string[], unknown, RequestData>({
		mutationFn,
		onError: (_error) => {
			const error = _error as AxiosError;
			let message = `An error occurred while bulk deleting ${pluralize(lowerCase(name))}: ${error?.message}`;
			if (error.message?.includes('413')) {
				message = `Too many entities selected for bulk deletion. Please select fewer than 100 entities at a time.`;
			}
			showNotification({
				title: `Error bulk deleting ${pluralize(lowerCase(name))}`,
				message,
				color: 'red',
				icon: <Icon name="alertCircle" />,
			});
		},
		onSuccess: (data) => {
			showNotification({
				title: `${capitalize(pluralize(name, data.length))} deleted`,
				message: `The ${lowerCase(pluralize(name, data.length))} have been deleted`,
				color: 'green',
				icon: <Icon name="check" />,
			});
		},
	});
}

export default useBulkDeleteSecodaEntities;
