import { Checkbox, Group, useMantineTheme } from '@mantine/core';
import { Button } from '@repo/foundations';
import { getColor } from '@repo/theme/utils';
import type React from 'react';
import { useCallback } from 'react';
import { propagateMetadataModalStore } from './store';

interface IActionsProps {
	done: boolean;
	disabled: boolean;
	isUpdating: boolean;
	isModal: boolean;
	onOverrideMetadataChange: (checked: boolean) => void;
	onClick: VoidFunction;
	overrideMetadata: boolean;
	selectedEntitiesCount: number;
}

export function Actions({
	done,
	disabled,
	isUpdating,
	isModal,
	onOverrideMetadataChange,
	onClick,
	overrideMetadata,
	selectedEntitiesCount,
}: IActionsProps) {
	const theme = useMantineTheme();

	const handleCheckboxChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			onOverrideMetadataChange(event.target.checked);
		},
		[onOverrideMetadataChange]
	);

	const applyMetadataLabel =
		selectedEntitiesCount > 0
			? `Apply metadata to ${selectedEntitiesCount} resources`
			: 'Apply metadata';

	const label = done ? 'Done' : 'Cancel';

	return (
		<Group
			sx={{
				borderTop: `thin solid ${getColor('border/secondary/default')}`,
				backgroundColor: theme.white,
				justifyContent: 'space-between',
				alignItems: 'center',
				position: 'sticky',
				bottom: 0,
			}}
			py={theme.spacing.md}
		>
			<Checkbox
				label="Override if there are existing properties"
				checked={overrideMetadata}
				onChange={handleCheckboxChange}
			/>
			<Group position="right">
				{/* Change cancel button to CTA when data got updated */}
				{isModal && (
					<Button
						variant={done ? 'primary' : 'default'}
						onClick={propagateMetadataModalStore.closeModal}
					>
						{label}
					</Button>
				)}
				{!done && (
					<Button
						type="submit"
						variant="primary"
						onClick={onClick}
						disabled={disabled}
						loading={isUpdating}
					>
						{applyMetadataLabel}
					</Button>
				)}
			</Group>
		</Group>
	);
}
