import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import type { IEmbeddedPromptResponseStep } from '../../api';
import type { AIAssistantCurrentPage } from './types';

export function generatePromptMessage(
	value: string,
	currentPage?: AIAssistantCurrentPage,
	attachments?: string[]
) {
	let message = value;

	if (attachments?.length) {
		const fileLinks = attachments
			.map((url) => `[Attached File](${url})`)
			.join('\n');
		message = `${fileLinks}\n\n${message}`;
	}

	if (!isNil(currentPage)) {
		return `<SECODA_AI_CONTEXT>[Secoda AI Context](${currentPage.type}/${currentPage.id})</SECODA_AI_CONTEXT>\n${message}`;
	}

	return message;
}

export function sanitizePromptMessage(value?: string) {
	if (!value) {
		return '';
	}
	return value.replace(/<SECODA_AI_CONTEXT>.*?<\/SECODA_AI_CONTEXT>/g, '');
}

export function getTimePeriod(dateString: string) {
	const day = dayjs(dateString);
	if (day.isToday()) return 'Today';
	if (day.isYesterday()) return 'Yesterday';
	if (day.isAfter(dayjs().subtract(7, 'day'))) return 'Previous 7 Days';
	if (day.isAfter(dayjs().subtract(30, 'day'))) return 'Previous 30 Days';
	return 'Older';
}

enum AIStepFormat {
	General = 'General',
	SQL = 'SQL',
}

export interface AIStepData {
	format: AIStepFormat;
	functionCall: string;
	definition: string;
	title: string;
}

export function extractStepData(step: IEmbeddedPromptResponseStep): AIStepData {
	const { functionCall } = step;

	if (functionCall.includes('run_sql')) {
		const sql = functionCall.substring(8, functionCall.length - 1);
		return {
			...step,
			format: AIStepFormat.SQL,
			functionCall: sql ?? step.functionCall,
		};
	}

	return { ...step, format: AIStepFormat.General };
}
