import type { Options } from 'markdown-it';
import markdownit from 'markdown-it';
import markdownItAttrs from 'markdown-it-attrs';
import { MarkdownPlugin, MarkdownPluginOptions } from '../../types';

export default function rules({
	options = {},
	plugins = [],
	disabledExtensions = [],
}: {
	options?: Partial<Options & { disable?: string | string[] }>;
	plugins?: Array<MarkdownPlugin>;
	disabledExtensions?: Array<string>;
}) {
	const markdownIt = markdownit('default', {
		breaks: false,
		html: false,
		linkify: false,
		...options,
	});
	markdownIt.use(markdownItAttrs, {
		allowedAttributes: [
			// used for heading anchor links
			'id',
			// used for table column's resizing
			'colwidth',
		],
	});
	plugins.forEach((plugin) =>
		markdownIt.use<MarkdownPluginOptions>(plugin, { disabledExtensions })
	);

	if (options.disable) {
		markdownIt.disable(options.disable, true);
	}

	return markdownIt;
}
