import { Group, Loader, Tooltip } from '@mantine/core';
import { FilterOption } from '@repo/common/components/Filter/types';
import { Icon, ListBox, Text } from '@repo/foundations';

interface AIFilterItemProps {
	option: FilterOption;
	onClick: (close: boolean) => void;
	className?: string;
	getProps: (userProps: React.HTMLProps<HTMLElement>) => any;
	searchTerm?: string;
	error?: boolean;
	loading?: boolean;
}

export function AIFilterItem({
	option,
	onClick,
	className,
	getProps,
	searchTerm,
	error = false,
	loading = false,
}: AIFilterItemProps) {
	return (
		<ListBox.Item
			{...getProps({
				onClick: () => onClick(true),
				className,
			})}
		>
			<Group
				data-testid={`filter-button-${option.label.toLowerCase()}`}
				spacing="2xs"
				noWrap
			>
				{option.icon}
				<Text size="sm" style={{ whiteSpace: 'nowrap' }}>
					{option.label}
				</Text>
				{searchTerm && (
					<Text
						size="sm"
						inline
						color="text/secondary/default"
						style={{ whiteSpace: 'nowrap' }}
					>
						“{searchTerm}”
					</Text>
				)}
				{loading && <Loader size={12} />}
				{error && (
					<Tooltip label="An error has ocurred">
						<Icon name="infoCircle" color="text/critical/default" />
					</Tooltip>
				)}
			</Group>
		</ListBox.Item>
	);
}
