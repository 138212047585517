import { LiteQueryOut } from '@repo/api-codegen';
import { makeAutoObservable } from 'mobx';
import { IQuery } from '../../../api';
import type { EntityTabsStore } from '../../TableEntityPage/TableEntityTabs/TableEntityTabsStore';

export class ColumnEntityTabsStore implements EntityTabsStore {
	discussionCount: number | undefined = undefined;

	lineageCount: number | undefined = undefined;

	queryCount: number | undefined = undefined;

	testCount: number | undefined = undefined;

	creationQuery: IQuery | LiteQueryOut | undefined = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	get isDiscussionLoading() {
		return this.discussionCount === undefined;
	}

	setCreationQuery = (query: IQuery | LiteQueryOut) => {
		this.creationQuery = query;
	};

	get isLineageLoading() {
		return this.lineageCount === undefined;
	}

	get isQueryLoading() {
		return this.queryCount === undefined;
	}

	get isTestLoading() {
		return this.testCount === undefined;
	}

	reset = () => {
		this.lineageCount = undefined;
		this.queryCount = undefined;
		this.discussionCount = undefined;
		this.testCount = undefined;
	};

	setLineageCount = (count: number) => {
		this.lineageCount = count;
	};

	setDiscussionCount = (count: number) => {
		this.discussionCount = count;
	};

	setQueryCount = (count: number) => {
		this.queryCount = count;
	};

	setTestCount = (count: number) => {
		this.testCount = count;
	};
}
