import { useDisclosure } from '@mantine/hooks';
import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

interface IncidentDrawerContextType {
	incidentId: string | null;
	openIncident: (incident: string | null) => void;
	opened: boolean;
	close: () => void;
}

const defaultContext: IncidentDrawerContextType = {
	incidentId: null,
	openIncident: () => {},
	opened: false,
	close: () => {},
};

const IncidentDrawerContext =
	createContext<IncidentDrawerContextType>(defaultContext);

export function IncidentDrawerProvider({ children }: { children: ReactNode }) {
	const [incidentId, setIncidentId] = useState<string | null>(null);
	const [opened, { open, close }] = useDisclosure(false);

	// Queue up the incident to be opened after the monitor page is loaded.
	const [queueStatus, setQueueStatus] = useState<
		'unqueued' | 'queued' | 'opened'
	>('unqueued');
	const location = useLocation();

	const handleOpen = useCallback(
		(incident: string | null) => {
			setQueueStatus('opened');
			setIncidentId(incident);
			if (incident) {
				open();
			}
		},
		[setIncidentId, open]
	);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const incident = params.get('incident');
		if (incident && queueStatus === 'unqueued') {
			setQueueStatus('queued');
			setTimeout(() => {
				// Use a functional update to ensure the latest value is checked
				setQueueStatus((prevQueued) => {
					if (prevQueued === 'queued') {
						handleOpen(incident);
					}
					return prevQueued; // Return the previous value to avoid overriding it
				});
			}, 600);
		}
	}, [location.search, handleOpen, queueStatus]);

	const contextValue = useMemo(
		() => ({
			incidentId: incidentId,
			openIncident: handleOpen,
			opened,
			close,
		}),
		[incidentId, handleOpen, opened, close]
	);

	return (
		<IncidentDrawerContext.Provider value={contextValue}>
			{children}
		</IncidentDrawerContext.Provider>
	);
}

export const useIncidentDrawer = (): IncidentDrawerContextType => {
	const context = useContext(IncidentDrawerContext);
	const navigate = useNavigate();

	// Handle a fallback when a context is not initialized (for example, inside a chart embedded in another context)
	if (context === defaultContext) {
		return {
			...context,
			openIncident: (incident: string | null) =>
				navigate(`/incident/${incident ?? ''}`),
		};
	}

	return context;
};
