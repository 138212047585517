import { Box, Stack } from '@mantine/core';
import { useBoolean } from 'ahooks';
import produce from 'immer';
import { fromPairs, isEmpty, keys, map, pickBy, size } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import type { ISecodaEntity } from '../../api';
import {
	useAuthUser,
	usePropagateSecodaEntityMetadata,
	useSecodaEntity,
} from '../../api';
import { trackEvent } from '../../utils/analytics';
import LoadingTable from '../LoadingTable';
import { Actions } from './Actions';
import { FILTER_OPTIONS, METADATA_PROPS } from './constants';
import EntitiesList from './EntitiesList';
import PropertySelector from './PropertySelector';
import { propagateMetadataModalStore } from './store';
import type { MetadataMapKey } from './types';
import { useExtractMetadata } from './utils';

export interface IPropagateMetadataProps {
	entityId: string;
	isModal?: boolean;
}

function PropagateMetadata({ entityId, isModal }: IPropagateMetadataProps) {
	const { user, workspace } = useAuthUser();

	const { isFetching, data: entity } = useSecodaEntity({
		id: entityId,
	});

	const [overrideMetadata, { set: setOverrideMetadata }] = useBoolean(false);
	const [overrideChildrenMetadata, { toggle: toggleOverrideChildrenMetadata }] =
		useBoolean(false);

	const [selectedEntities, setSelectedEntities] = useState<object[]>([]);

	const {
		isLoading: isUpdatingEntities,
		mutateAsync: updateEntities,
		data: updatedEntities,
		reset: resetUpdatedEntities,
	} = usePropagateSecodaEntityMetadata();

	const [properties, setProperties] = useState(
		fromPairs(map(METADATA_PROPS, (key) => [key, false])) as Record<
			MetadataMapKey,
			boolean
		>
	);

	const selectedProperties = useMemo(
		() => keys(pickBy(properties)),
		[properties]
	);

	const metadata = useExtractMetadata(entity);

	const isDone = updatedEntities !== undefined;

	const handlePropertyChange = useCallback(
		(key: MetadataMapKey, value: boolean) => {
			setProperties(
				produce((draft) => {
					draft[key] = value;
				})
			);
		},
		[]
	);

	const handleSelectionChanged = useCallback(
		(selectedItems: object[]) => {
			resetUpdatedEntities();
			setSelectedEntities(selectedItems);
		},
		[resetUpdatedEntities]
	);

	const handleSubmit = useCallback(async () => {
		if (entity) {
			trackEvent(
				`${entity.entity_type}/metadata/propagate`,
				{},
				user,
				workspace
			);
		}
		if (isDone) {
			propagateMetadataModalStore.closeModal();
		}

		await updateEntities(
			{
				data: {
					source: entity as ISecodaEntity,
					destinations: selectedEntities as ISecodaEntity[],
					fields: selectedProperties,
					override: overrideMetadata,
					override_children: overrideChildrenMetadata,
				},
			},
			{
				onSuccess: () => {
					setSelectedEntities([]);
				},
			}
		);
	}, [
		entity,
		isDone,
		overrideChildrenMetadata,
		overrideMetadata,
		selectedEntities,
		selectedProperties,
		updateEntities,
		user,
		workspace,
	]);

	const disabled =
		(!overrideChildrenMetadata && isEmpty(selectedProperties)) ||
		isEmpty(selectedEntities);

	const [filterMode, setFilterMode] = useState<
		(typeof FILTER_OPTIONS)[number] | null
	>('exactName');

	const defaultRequiredSearchParams = useMemo(() => {
		if (!entity) {
			return {};
		}

		if (filterMode === 'exactName') {
			return { title: entity.title, entity_type: entity.entity_type };
		} else if (filterMode === 'sameName') {
			return {
				title__trigram_word_similar: entity.title,
				entity_type: entity.entity_type,
			};
		} else if (filterMode === 'lineageDownstream') {
			return { upstream: entity.id, lineage_depth: 5 };
		} else {
			return {};
		}
	}, [entity, filterMode]);

	if (isFetching || !entity) {
		return (
			<Box h="60vh" mih="60vh">
				<LoadingTable />
			</Box>
		);
	}

	return (
		<Stack>
			<PropertySelector
				title={entity?.title}
				metadata={metadata}
				properties={properties}
				onPropertyChange={handlePropertyChange}
				showOverrideMetadata={entity.entity_type === 'table'}
				overrideChildrenMetadata={overrideChildrenMetadata}
				onOverrideChildrenMetadataToggle={toggleOverrideChildrenMetadata}
			/>
			<EntitiesList
				key={entity.id}
				selectedEntities={selectedEntities}
				onSelectionChanged={handleSelectionChanged}
				defaultRequiredSearchParams={
					defaultRequiredSearchParams as Record<string, string>
				}
				onFilterChange={setFilterMode}
			/>
			<Actions
				done={isDone}
				disabled={disabled}
				isUpdating={isUpdatingEntities}
				isModal={Boolean(isModal)}
				overrideMetadata={overrideMetadata}
				onOverrideMetadataChange={setOverrideMetadata}
				onClick={handleSubmit}
				selectedEntitiesCount={size(selectedEntities)}
			/>
		</Stack>
	);
}

export default PropagateMetadata;
