import { fromPairs, includes, map, partition, pick, toLower } from 'lodash-es';
import type {
	IApiListResponse,
	ISecodaEntity,
	IUser,
	IUserGroup,
} from '../../api';
import {
	useCollectionList,
	useTagList,
	useUserGroupList,
	useUserList,
} from '../../api';
import { useExtendedUserList } from '../../api/hooks/user/useExtendedUserList';
import { METADATA_MAP, METADATA_PROPS } from './constants';
import type { FilterOptionType, Metadata, MetadataMapKey } from './types';

export const getMetadataIconAndLabel = (key: MetadataMapKey) =>
	METADATA_MAP[key];

function getPartitionedOwners(
	groups: IApiListResponse<IUserGroup> | undefined,
	serviceAccounts: IApiListResponse<IUser> | undefined,
	ownerIds: string[]
) {
	const [groupIds, accountIds] = partition(ownerIds, (id) =>
		includes(map(groups?.results, 'id'), id)
	);

	const [serviceAccountIds, userIds] = partition(accountIds, (id) =>
		includes(map(serviceAccounts?.results, 'id'), id)
	);

	return { groupIds, serviceAccountIds, userIds };
}

function usePartitionOwners(ownerIds: string[]) {
	const { data: groups } = useUserGroupList({});
	const { data: serviceAccounts } = useUserList({
		filters: {
			is_service_account: false,
		},
	});

	const { groupIds, serviceAccountIds, userIds } = getPartitionedOwners(
		groups,
		serviceAccounts,
		ownerIds
	);

	return { groupIds, serviceAccountIds, userIds };
}

export function useExtractMetadata(entity: ISecodaEntity | undefined) {
	const metadata = pick(entity, METADATA_PROPS) as Record<
		MetadataMapKey,
		string | string[] | boolean
	>;

	const { data: tagData } = useTagList({});
	const { data: collectionData } = useCollectionList({});

	const {
		activeUsers: usersData,
		userGroups: groupsData,
		serviceAccountsActive: serviceAccountsData,
	} = useExtendedUserList();

	const owners = [
		...(entity?.owners ?? []),
		...(entity?.owners_groups ?? []),
	] as string[];

	const data = fromPairs(
		map(metadata, (value, key) => {
			if (key === 'verified') {
				return [key, Boolean(value)];
			}

			if (key === 'tags') {
				const tags = tagData?.results.filter((tag) =>
					(value as string[]).includes(tag.id)
				);
				return [key, tags];
			}

			if (key === 'collections') {
				const collections = collectionData?.results.filter((collection) =>
					(value as string[]).includes(collection.id)
				);
				return [key, collections];
			}

			return [key, value];
		})
	);

	const ret = {
		...data,
		owners: usersData?.filter((user) => owners?.includes(user.id)) ?? [],
	} as unknown as Metadata;
	return ret;
}

export function getFilters(
	entity: ISecodaEntity | undefined,
	filterOption: FilterOptionType
): Record<string, string> {
	if (!entity) {
		return {};
	}

	if (filterOption === 'lineageDownstream') {
		return {
			upstream: entity.id,
			lineage_depth: '5',
		};
	}

	if (filterOption === 'exactName') {
		return {
			title: toLower(entity.title || 'untitled'),
			// Having the same entity type as the selected entity is essential
			// in ensuring we don't crash the database. Without entity_type, the title
			// could search over million of entities without any index.
			entity_type: entity.entity_type,
		};
	}

	return {
		title__trigram_word_similar: toLower(entity.title || 'untitled'),
		entity_type: entity.entity_type,
	};
}
