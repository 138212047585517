import { Group, createStyles } from '@mantine/core';
import { useState } from 'react';
import {
	ScheduleCadence,
	ScheduleConfig,
} from '../../api/types/models/schedule';
import { isValidEnumValue } from '../../utils/enumUtils';
import AutomationCardButton from '../Automation/AutomationCard/AutomationCardButton';
import {
	CADENCE_OPTIONS,
	CadenceSelectOption,
	DAY_OPTIONS,
	HOUR_OPTIONS,
	convertCadenceOptionToSchedule,
	convertScheduleToCadenceOption,
	getCadenceLabel,
	getRunDayLabel,
	getRunTimeLabel,
} from './constants';

const useStyles = createStyles((theme) => ({
	group: {
		display: 'flex',
		gap: theme.spacing['2xs'],
	},
}));

interface HorizontalScheduleSelectorProps {
	schedule: ScheduleConfig;
	onScheduleUpdate: (schedule: ScheduleConfig) => Promise<void>;
	className?: string;
}

function HorizontalScheduleSelector({
	schedule,
	onScheduleUpdate,
	className,
}: HorizontalScheduleSelectorProps) {
	const { classes, cx } = useStyles();

	const [cadenceOption, setCadenceOption] = useState<CadenceSelectOption>(
		convertScheduleToCadenceOption(schedule)
	);
	const [cadence, setCadence] = useState<ScheduleCadence>(schedule.cadence);
	const [hour, setHour] = useState<number>(schedule.hour || 0);
	const [day, setDay] = useState<number>(schedule.day || 0);

	const handleCadenceChange = (value: string | boolean) => {
		if (
			typeof value !== 'string' ||
			!isValidEnumValue(CadenceSelectOption, value)
		) {
			return;
		}

		setCadenceOption(value);
		const newSchedule = convertCadenceOptionToSchedule(value);
		setCadence(newSchedule.cadence);

		onScheduleUpdate({
			...schedule,
			cadence: newSchedule.cadence,
			frequency: newSchedule.frequency,
		});
	};

	const handleHourChange = (value: string | boolean) => {
		if (typeof value !== 'string') {
			return;
		}

		setHour(Number(value));
		onScheduleUpdate({
			...schedule,
			hour: Number(value),
		});
	};

	const handleDayChange = (value: string | boolean) => {
		if (typeof value !== 'string') {
			return;
		}

		setDay(Number(value));
		onScheduleUpdate({
			...schedule,
			day: Number(value),
		});
	};

	const dayButton = (
		<AutomationCardButton
			buttonLabel={getRunDayLabel(day)}
			buttonOptions={DAY_OPTIONS}
			onClick={handleDayChange}
		/>
	);
	const hourButton = (
		<AutomationCardButton
			buttonLabel={getRunTimeLabel(hour)}
			buttonOptions={HOUR_OPTIONS}
			onClick={handleHourChange}
		/>
	);
	const cadenceButton = (
		<AutomationCardButton
			buttonLabel={getCadenceLabel(cadenceOption)}
			buttonOptions={CADENCE_OPTIONS}
			onClick={handleCadenceChange}
		/>
	);

	return (
		<Group className={cx(classes.group, className)}>
			Repeats {cadenceButton}{' '}
			{cadence === ScheduleCadence.WEEKLY ? <>on {dayButton}</> : ''}
			{cadence === ScheduleCadence.DAILY ||
			cadence === ScheduleCadence.WEEKLY ? (
				<>at {hourButton}</>
			) : (
				''
			)}
		</Group>
	);
}

export default HorizontalScheduleSelector;
