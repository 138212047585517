import { IIntegration } from '../../api';
import { sentenceCase } from '../../utils/stringUtils';

export const isResourceDecoratingTag = (
	integration: IIntegration | undefined
) => {
	const resourceDecorators = ['Cyera'];
	const integrationName = integration?.name ? integration.name : '';
	return resourceDecorators.includes(sentenceCase(integrationName));
};
