import { isAnalyticsMonitor, MonitorSpec, MonitorType } from './types';

export const MONITOR_SPECS: MonitorSpec[] = [
	{
		type: { metric_type: 'freshness' },
		iconName: 'clockPlay',
		iconColor: 'icon/decorative/sunset',
		backgroundColor: 'fill/decorative/sunset',
		title: 'Freshness',
		group: 'Table',
		description: 'Monitors the time elapsed since last update.',
	},
	{
		type: { metric_type: 'row_count' },
		iconName: 'layoutRows',
		iconColor: 'icon/decorative/sunset',
		backgroundColor: 'fill/decorative/sunset',
		title: 'Row Count',
		group: 'Table',
		description: 'Monitors the number of rows over time.',
	},
	{
		type: { metric_type: 'cardinality' },
		iconName: 'columns2',
		iconColor: 'icon/decorative/grape',
		backgroundColor: 'fill/decorative/grape',
		title: 'Cardinality',
		group: 'Column',
		description: 'Monitors the number of distinct values in a column.',
	},
	{
		type: { metric_type: 'max' },
		iconName: 'hash',
		iconColor: 'icon/decorative/grape',
		backgroundColor: 'fill/decorative/grape',
		title: 'Max',
		group: 'Column',
		description: 'Monitors the maximum of all values in a column.',
	},
	{
		type: { metric_type: 'mean' },
		iconName: 'hash',
		iconColor: 'icon/decorative/grape',
		backgroundColor: 'fill/decorative/grape',
		title: 'Mean',
		group: 'Column',
		description: 'Monitors the average of all values in a column.',
	},
	{
		type: { metric_type: 'min' },
		iconName: 'hash',
		iconColor: 'icon/decorative/grape',
		backgroundColor: 'fill/decorative/grape',
		title: 'Minimum',
		group: 'Column',
		description: 'Monitors the minimum of all values in a column.',
	},
	{
		type: { metric_type: 'null_percentage' },
		iconName: 'percentage',
		iconColor: 'icon/decorative/grape',
		backgroundColor: 'fill/decorative/grape',
		title: 'Null Percentage',
		group: 'Column',
		description: 'Monitors the percentage of values in a column that are null.',
	},
	{
		type: { metric_type: 'unique_percentage' },
		iconName: 'percentage',
		iconColor: 'icon/decorative/grape',
		backgroundColor: 'fill/decorative/grape',
		title: 'Unique Percentage',
		group: 'Column',
		description:
			'Monitors the percentage of values in a column that are unique.',
	},
	{
		type: { metric_type: 'custom_sql' },
		iconName: 'code',
		iconColor: 'icon/decorative/lime',
		backgroundColor: 'fill/decorative/lime',
		title: 'Custom SQL',
		group: 'Custom',
		description: 'Define a monitor using custom SQL.',
	},
	{
		type: { metric_type: 'analytics', integration_metric: 'snowflake_cost' },
		iconName: 'coin',
		iconColor: 'icon/decorative/cyan',
		backgroundColor: 'fill/decorative/cyan',
		title: 'Snowflake cost',
		group: 'Warehouse',
		description: 'Monitors the costs incurred of a Snowflake warehouse.',
		integrationType: 'snowflake',
	},
	{
		type: {
			metric_type: 'analytics',
			integration_metric: 'snowflake_query_volume',
		},
		iconName: 'database',
		iconColor: 'icon/decorative/cyan',
		backgroundColor: 'fill/decorative/cyan',
		title: 'Snowflake query volume',
		description:
			'Monitors the number of queries executed against a Snowflake integration.',
		group: 'Warehouse',
		integrationType: 'snowflake',
	},
	{
		type: {
			metric_type: 'analytics',
			integration_metric: 'snowflake_compute_usage',
		},
		iconName: 'businessplan',
		iconColor: 'icon/decorative/cyan',
		backgroundColor: 'fill/decorative/cyan',
		title: 'Snowflake compute credits',
		description: 'Monitors the compute credit usage of a Snowflake warehouse.',
		group: 'Warehouse',
		integrationType: 'snowflake',
	},
	{
		type: {
			metric_type: 'analytics',
			integration_metric: 'snowflake_storage_usage',
		},
		iconName: 'businessplan',
		iconColor: 'icon/decorative/cyan',
		backgroundColor: 'fill/decorative/cyan',
		title: 'Snowflake storage usage',
		description: 'The storage usage of a Snowflake database.',
		group: 'Warehouse',
		integrationType: 'snowflake',
	},

	// {
	// 	type: { metric_type: 'analytics', integration_metric: 'dbt_test_warnings' },
	// 	iconName: 'alertTriangle',
	// 	iconColor: 'icon/decorative/orange',
	// 	backgroundColor: 'fill/decorative/orange',
	// 	title: 'dbt test warnings',
	// 	description:
	// 		'Monitors the number of failed dbt tests across a table or column.',
	// 	group: 'Tests',
	// 	integrationType: 'dbt',
	// },
	// {
	// 	type: { metric_type: 'analytics', integration_metric: 'dbt_test_skips' },
	// 	iconName: 'playerSkipForward',
	// 	iconColor: 'icon/decorative/orange',
	// 	backgroundColor: 'fill/decorative/orange',
	// 	title: 'dbt test skips',
	// 	description:
	// 		'Monitors the number of successful dbt tests across a table or column.',
	// 	group: 'Tests',
	// 	integrationType: 'dbt',
	// },
	// {
	// 	type: { metric_type: 'analytics', integration_metric: 'dbt_test_errors' },
	// 	iconName: 'exclamationMark',
	// 	iconColor: 'icon/decorative/orange',
	// 	backgroundColor: 'fill/decorative/orange',
	// 	title: 'dbt test errors',
	// 	description:
	// 		'Monitors the number of dbt test warnings across a table or column.',
	// 	group: 'Tests',
	// 	integrationType: 'dbt',
	// },
	// {
	// 	type: { metric_type: 'analytics', integration_metric: 'dbt_test_passes' },
	// 	iconName: 'check',
	// 	iconColor: 'icon/decorative/orange',
	// 	backgroundColor: 'fill/decorative/orange',
	// 	title: 'dbt test passes',
	// 	description: 'The number of successful dbt tests across a table or column.',
	// 	group: 'Tests',
	// 	integrationType: 'dbt',
	// },
	// {
	// 	type: { metric_type: 'analytics', integration_metric: 'dbt_test_failures' },
	// 	iconName: 'x',
	// 	iconColor: 'icon/decorative/orange',
	// 	backgroundColor: 'fill/decorative/orange',
	// 	title: 'dbt test failures',
	// 	description: 'The number of failed dbt tests across a table or column.',
	// 	group: 'Tests',
	// 	integrationType: 'dbt',
	// },
	// {
	// 	type: {
	// 		metric_type: 'analytics',
	// 		integration_metric: 'dbt_test_execution_times',
	// 	},
	// 	iconName: 'x',
	// 	iconColor: 'icon/decorative/orange',
	// 	backgroundColor: 'fill/decorative/orange',
	// 	title: 'dbt test execution time',
	// 	description: 'The execution time of dbt tests across a table or column.',
	// 	group: 'Tests',
	// 	integrationType: 'dbt',
	// },
];

export const getMonitorSpec = (type: MonitorType): MonitorSpec => {
	const spec = MONITOR_SPECS.find(
		(s) =>
			s.type.metric_type === type.metric_type &&
			(isAnalyticsMonitor(type) && isAnalyticsMonitor(s.type)
				? s.type.integration_metric === type.integration_metric
				: true)
	);
	if (!spec) {
		throw new Error(`Monitor spec not found for ${JSON.stringify(type)}`);
	}
	return spec;
};
