import {
	ActionIcon,
	Box,
	createStyles,
	getStylesRef,
	Group,
	Stack,
	Tooltip,
} from '@mantine/core';
import { useDisclosure, useScrollIntoView } from '@mantine/hooks';
import { AvatarSkeleton, Icon, TextSkeleton } from '@repo/foundations';
import { isEqual } from 'lodash-es';
import type { RefObject } from 'react';
import { memo, useCallback, useEffect } from 'react';
import CopyButton from '../../../../../../packages/foundations/components/Buttons/CopyButton';
import { useUser, type IEmbeddedPrompt } from '../../../api';
import { useAIFeedback } from '../../../api/hooks/ai/useAIFeedback';
import { useTemplateList } from '../../../api/hooks/templates';
import { useStreamingContent } from '../../../hooks/useStreamingContent';
import { EntityType } from '../../../lib/types';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { AIAvatar } from '../../AIAvatar';
import { entityModalStore } from '../../EntityModal/store';
import { RichEditor } from '../../RichEditor';
import { UserAvatar } from '../../UserAvatar';
import { sanitizePromptMessage } from '../utils';
import { AISteps } from './AISteps';
import { MessageAIError } from './MessageAIError';
import { MessageAILoading } from './MessageAILoading';
import { MessageBody } from './MessageBody';

const useStyles = createStyles((theme) => ({
	markdown: {
		color: theme.other.getColor('text/primary/default'),
		fontSize: theme.fontSizes.md,
		fontWeight: theme.other.typography.weight.regular,
		lineHeight: theme.other.typography.lineHeight.text.md,
	},
	richEditorContainer: {
		'.markdown-body': {
			color: theme.other.getColor('text/primary/default'),
			fontSize: theme.fontSizes.md,
			fontWeight: theme.other.typography.weight.bold,
			lineHeight: theme.other.typography.lineHeight.text.md,
		},
	},
	toolbar: {
		ref: getStylesRef('toolbar'),
		marginLeft: `-${theme.spacing['3xs']}`,
	},
	messageContainer: {
		[`.${getStylesRef('toolbar')}`]: {
			opacity: 0,
		},
		[`&:hover .${getStylesRef('toolbar')}`]: {
			opacity: 1,
		},
	},
}));

type MessageGroupProps = {
	message: IEmbeddedPrompt;
	scrollAreaRef?: RefObject<HTMLDivElement>;
	onRetry?: () => void;
	onNegativeFeedback?: () => void;
};

function MessageGroupInternal({
	message,
	scrollAreaRef,
	onRetry,
	onNegativeFeedback,
}: MessageGroupProps) {
	const { data: user } = useUser({ id: message.user_id || '' });
	const { classes } = useStyles();
	const { aiToQuestionsAnswers } = useFeatureFlags();
	const { data: defaultQuestionTemplates } = useTemplateList({
		filters: {
			is_default: true,
		},
		options: {
			suspense: false,
		},
	});

	const showQuestionModal = useCallback(
		({ title, description }: { title?: string; description?: string } = {}) => {
			entityModalStore.open({
				type: EntityType.question,
				template: defaultQuestionTemplates?.results?.[0],
				title: title || '',
				description: description || '',
				metadata: {
					owners: [user?.id].filter(Boolean),
				},
			});
		},
		[defaultQuestionTemplates?.results, user?.id]
	);

	const parsePromptForModal = (prompt: string) => {
		const markdownLinkRegex = /!?\[.*?\]\(.*?\)/g;
		const title = prompt.replace(markdownLinkRegex, '').trim();
		const attachments = prompt.match(markdownLinkRegex) || [];

		return {
			title,
			description: attachments.join('\n'),
		};
	};

	const { showPositiveFeedbackModal, showNegativeFeedbackModal } =
		useAIFeedback({
			id: message.id,
			onNegativeFeedbackSubmitted: onNegativeFeedback,
		});
	const { targetRef, scrollableRef, scrollIntoView } = useScrollIntoView<
		HTMLDivElement,
		HTMLDivElement
	>({ duration: 200 });

	const handleNegativeFeedback = useCallback(() => {
		showNegativeFeedbackModal();
	}, [showNegativeFeedbackModal]);

	useEffect(() => {
		if (scrollAreaRef?.current) {
			scrollableRef.current = scrollAreaRef.current;
		}
	}, [scrollAreaRef, scrollableRef]);

	const [showSteps, { toggle: toggleSteps }] = useDisclosure(false);

	useEffect(() => {
		if (showSteps) {
			scrollIntoView({ alignment: 'end' });
		}
	}, [showSteps, scrollIntoView]);

	const messageResponse = useStreamingContent(
		message.status === 'running',
		message.response?.content ?? ''
	);
	const shouldDisplayLoading =
		message.status === 'pending' ||
		(message.status === 'running' && messageResponse.length === 0);
	const shouldDisplayFailure = message.status === 'failed';
	const shouldDisplayAnswerToolbar = message.status === 'completed';
	const hasSteps = (message.response?.steps?.length ?? 0) > 0;

	return (
		<Stack spacing="md" pb="md">
			<Box className={classes.messageContainer}>
				<MessageBody
					avatar={
						user ? (
							<UserAvatar user={user} size="md" />
						) : (
							<AvatarSkeleton size="md" />
						)
					}
					author={
						user ? user.display_name : <TextSkeleton width={200} size="md" />
					}
				>
					<RichEditor readOnly value={sanitizePromptMessage(message.prompt)} />
					{aiToQuestionsAnswers && (
						<Group spacing={0} className={`${classes.toolbar}`}>
							<Group spacing={0} noWrap>
								<Tooltip label="Ask team">
									<ActionIcon
										size="xs"
										onClick={() => {
											const { title, description } = parsePromptForModal(
												sanitizePromptMessage(message.prompt)
													.replace(/(<([^>]+)>)/gi, '')
													.replace(/\n+/g, ' ')
											);
											showQuestionModal({ title, description });
										}}
									>
										<Icon name="messageCircleQuestion" />
									</ActionIcon>
								</Tooltip>
							</Group>
						</Group>
					)}
				</MessageBody>
			</Box>
			<MessageBody
				avatar={
					<AIAvatar size="md" speed={shouldDisplayLoading ? 'slow' : 'none'} />
				}
				author={message.persona?.name || 'Secoda AI'}
			>
				{shouldDisplayLoading && (
					<MessageAILoading steps={message.response?.steps ?? []} />
				)}
				{shouldDisplayFailure && <MessageAIError onRetry={onRetry} />}
				{!shouldDisplayLoading && !shouldDisplayFailure && (
					<Box className={classes.messageContainer}>
						<Box className={classes.richEditorContainer}>
							<RichEditor readOnly value={messageResponse} />
						</Box>
						{shouldDisplayAnswerToolbar && (
							<Group spacing={0} className={`${classes.toolbar}`}>
								<CopyButton
									value={message.response?.content || ''}
									color="gray"
								/>
								<Group spacing={0} noWrap>
									<Tooltip label="Good response">
										<ActionIcon size="xs" onClick={showPositiveFeedbackModal}>
											<Icon name="thumbUp" />
										</ActionIcon>
									</Tooltip>
									<Tooltip label="Bad response">
										<ActionIcon size="xs" onClick={handleNegativeFeedback}>
											<Icon name="thumbDown" />
										</ActionIcon>
									</Tooltip>
								</Group>
								{hasSteps && (
									<Tooltip label="Show steps">
										<ActionIcon
											size="xs"
											onClick={toggleSteps}
											variant={showSteps ? 'filled' : 'subtle'}
										>
											<Icon name="listSearch" />
										</ActionIcon>
									</Tooltip>
								)}
								{aiToQuestionsAnswers && (
									<Tooltip label="Ask team">
										<ActionIcon
											size="xs"
											onClick={() =>
												showQuestionModal({
													description: message.response?.content || '',
												})
											}
										>
											<Icon name="messageCircleQuestion" />
										</ActionIcon>
									</Tooltip>
								)}
							</Group>
						)}
					</Box>
				)}
				{showSteps && <AISteps ref={targetRef} prompt={message} />}
			</MessageBody>
		</Stack>
	);
}

export const MessageGroup = memo(MessageGroupInternal, (prevProps, nextProps) =>
	isEqual(prevProps.message, nextProps.message)
);
