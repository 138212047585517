import { startCase } from 'lodash-es';
import type { ILineage } from '../../../../api';
import { EntityType } from '../../../../lib/types';

export const getMetadata = (entity: ILineage) => {
	const label = startCase(entity.native_type || entity.entity_type);
	let metadata = '';

	if (entity.entity_type === EntityType.table) {
		const tableMetadata = [];
		if (entity.metadata.database) {
			tableMetadata.push(entity.metadata.database);
		}
		if (entity.metadata.schema) {
			tableMetadata.push(entity.metadata.schema);
		}
		metadata = tableMetadata.join(' / ');
	}
	if (entity.entity_type === EntityType.column) {
		const columnMetadata = [];
		if (entity.metadata.database) {
			columnMetadata.push(entity.metadata.database);
		}
		if (entity.metadata.schema) {
			columnMetadata.push(entity.metadata.schema);
		}
		if (entity.metadata.table) {
			columnMetadata.push(entity.metadata.table);
		}
		metadata = columnMetadata.join(' / ');
	}
	if (entity.entity_type === EntityType.dashboard) {
		const dashboardMetadata = [];
		if (entity.metadata.product) {
			dashboardMetadata.push(entity.metadata.product);
		}
		if (entity.metadata.group) {
			dashboardMetadata.push(entity.metadata.group);
		}
		metadata = dashboardMetadata.join(' / ');
	}
	if (entity.entity_type === EntityType.chart) {
		metadata = entity.metadata.group || '';
	}
	if (entity.entity_type === EntityType.job) {
		metadata = entity.metadata.group || '';
	}

	return { label, metadata };
};
