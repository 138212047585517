import { Menu } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { useAuthUser } from '../../../../api';
import { trackEvent } from '../../../../utils/analytics';

interface CreateAnnouncementActionProps {
	openAnnouncementModal: VoidFunction;
}

export function CreateAnnouncementAction({
	openAnnouncementModal,
}: CreateAnnouncementActionProps) {
	const { user, workspace } = useAuthUser();

	const handleAnnouncementClick = () => {
		openAnnouncementModal();
		trackEvent('inbox/new_announcement_toggle/click', {}, user, workspace);
	};

	return (
		<Menu.Item
			icon={<Icon name="speakerphone" />}
			onClick={handleAnnouncementClick}
		>
			Create announcement
		</Menu.Item>
	);
}
