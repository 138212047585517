import {
	Accordion,
	Card,
	Divider,
	Group,
	JsonInput,
	Progress,
	Stack,
} from '@mantine/core';
import {
	apiQueryKey,
	BackgroundJobOut,
	useApiTerminateBackgroundJob,
	useGetLatestGitInfo,
} from '@repo/api-codegen';
import { Badge, Button, Text, Title } from '@repo/foundations';
import dayjs from 'dayjs';
import { queryClient } from '../../../api';
import {
	formatEntityDateTime,
	humanReadableTimeElapsed,
} from '../../../utils/time';
import { useStyles } from '../IntegrationsStatusPage/IntegrationsStatusPage.styles';
import DescriptionGroup from './DescriptionGroup';
import { computeTimeLeft } from './utils';

interface BackgroundJobCardProps {
	job: BackgroundJobOut;
}

function BackgroundJobCard({ job }: BackgroundJobCardProps) {
	const { classes } = useStyles();

	const { mutateAsync: terminateJob } = useApiTerminateBackgroundJob({
		onSuccess: () => {
			queryClient.invalidateQueries(apiQueryKey('admin/background_job'));
		},
	});

	// Git
	const { data: latestGitInfo } = useGetLatestGitInfo({});
	const latestCommitTag =
		latestGitInfo?.version.split('-')[2]?.substring(1) ?? 'undefined';

	// Job status parsing
	const running = job.started && !job.completed && !job.failed;
	const error = job.failed;
	const success = job.completed;

	// Config parsing
	const progress = job.config?.progress || 0;
	const jobConfigString = JSON.stringify(job.config, null, 2);

	return (
		<Card className={classes.card}>
			<Stack w="100%">
				<Group>
					<Title size="xl">{job.title ? job.title : 'Undefined'}</Title>
					<Group>
						{running && (
							<Button
								variant="primary"
								tone="critical"
								onClick={() => terminateJob({ pathParams: { jobId: job.id } })}
							>
								Terminate job
							</Button>
						)}
						{error && <Badge variant="critical">Error</Badge>}
						{success && <Badge variant="success">Finished</Badge>}
					</Group>
				</Group>
			</Stack>
			<Divider my="sm" />
			<Title order={2} pb="sm">
				Metadata
			</Title>
			<Group w="100%" position="apart" pb="md">
				<DescriptionGroup
					title="Job created at"
					description={formatEntityDateTime(job.created_at)}
				/>
				<DescriptionGroup
					title="Last updated"
					description={formatEntityDateTime(job.created_at)}
				/>
				<DescriptionGroup
					title="Estimated time left"
					description={
						progress
							? humanReadableTimeElapsed(
									computeTimeLeft(
										progress,
										dayjs().diff(dayjs(job.created_at), 'seconds')
									)
								)
							: 'N/A no config.progress metadata'
					}
				/>
			</Group>
			<Group w="100%" position="apart" pb="md">
				<DescriptionGroup
					title="Git tag that indexing job started on"
					description={job.git_tag}
				/>
				<DescriptionGroup
					title="Git latest deployment tag"
					description={
						<Text size="sm">
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={`https://github.com/secoda/secoda/commit/${latestCommitTag}`}
							>
								{latestGitInfo?.version}
							</a>
						</Text>
					}
				/>
			</Group>
			<Accordion>
				<Accordion.Item value="config">
					<Accordion.Control>Display raw config</Accordion.Control>
					<Accordion.Panel>
						<JsonInput value={jobConfigString} minRows={10} disabled />
					</Accordion.Panel>
				</Accordion.Item>
			</Accordion>
			<Group w="100%" position="apart" pb="md">
				<DescriptionGroup
					title="Progress status"
					description={`${progress.toFixed(2)}%`}
				/>
				<DescriptionGroup
					title="Latest uuid indexed"
					description={job.config?.latest_uuid || 'N/A no config.latest_uuid'}
				/>
			</Group>

			{progress > 0 && <Progress size="md" value={progress} color="blue" />}
		</Card>
	);
}

export default BackgroundJobCard;
