import { createStyles, getStylesRef } from '@mantine/core';
import { useId } from '@mantine/hooks';
import { EditorDictionary } from '@repo/secoda-editor';
import { Node as ProsemirrorNode } from 'prosemirror-model';
import { Resizable } from 're-resizable';
import { useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const useStyles = createStyles((theme) => ({
	wrapper: {
		display: 'inline-block',
		position: 'relative',

		[`&.ProseMirror-selectednode + .${getStylesRef('caption')}`]: {
			visibility: 'visible !important' as 'visible',
		},
	},
	image: {
		userSelect: 'none',
		pointerEvents: 'none',
		MozWindowDragging: 'no-drag',
		height: '100%',
		width: '100%',
	},
	caption: {
		ref: getStylesRef('caption'),
		border: 0,
		display: 'block',
		fontSize: '13px',
		fontStyle: 'italic',
		fontWeight: 'normal',
		color: theme.other.getColor('text/secondary/default'),
		padding: '2px 0',
		lineHeight: '16px',
		textAlign: 'center',
		minHeight: '1em',
		outline: 'none',
		background: 'none',
		resize: 'none',
		userSelect: 'text',
		cursor: 'text',
		'&:empty:not(:focus)': {
			visibility: 'hidden',
		},
		'&:empty:before': {
			color: theme.other.getColor('text/secondary/default'),
			content: 'attr(data-caption)',
			pointerEvents: 'none',
		},
	},
}));

interface ImageContainerProps {
	src: string;
	caption: string;
	width: number;
	layoutClass: string;
	title: string;
	isSelected: boolean;
	node: ProsemirrorNode;
	onResize: (props: {
		node: ProsemirrorNode;
		size: {
			width: number | string;
			height: number | string;
		};
	}) => void;
	onClick: (event: React.MouseEvent) => void;
	onKeyDown: (event: React.KeyboardEvent<HTMLParagraphElement>) => void;
	onCaptionBlur: (event: React.FocusEvent<HTMLParagraphElement>) => void;
	readOnly: boolean;
	dictionary: EditorDictionary;
}

export function ImageContainer({
	src,
	caption,
	width,
	layoutClass,
	isSelected,
	node,
	onResize,
	onClick,
	onKeyDown,
	onCaptionBlur,
	readOnly,
	title,
	dictionary,
}: ImageContainerProps) {
	const { classes, cx } = useStyles();
	const [imgWidth, setWidth] = useState(width);
	const captionId = useId();

	return (
		<div
			contentEditable={false}
			className={`image image-${layoutClass}`}
			onClick={onClick}
			role="figure"
			aria-labelledby={`caption-${captionId}`}
		>
			<Zoom>
				<div
					className={cx(classes.wrapper, {
						'ProseMirror-selectednode': isSelected,
					})}
				>
					<Resizable
						size={{ width: imgWidth ? imgWidth : 'auto', height: 'auto' }}
						lockAspectRatio
						enable={{
							top: !readOnly,
							right: !readOnly,
							bottom: !readOnly,
							left: !readOnly,
							topRight: !readOnly,
							bottomRight: !readOnly,
							bottomLeft: !readOnly,
							topLeft: !readOnly,
						}}
						onResizeStop={(e, direction, resizeRef) => {
							if (readOnly) return;
							setWidth(resizeRef.clientWidth);
							onResize({
								node,
								size: {
									width: resizeRef.clientWidth,
									height: resizeRef.clientHeight,
								},
							});
						}}
					>
						<img
							className={classes.image}
							src={src}
							alt={caption}
							width={width}
							title={title}
						/>
					</Resizable>
				</div>
				<p
					id={`caption-${captionId}`}
					onKeyDown={onKeyDown}
					onBlur={onCaptionBlur}
					className={classes.caption}
					tabIndex={-1}
					role="textbox"
					contentEditable
					suppressContentEditableWarning
					data-caption={dictionary.imageCaptionPlaceholder}
				>
					{caption}
				</p>
			</Zoom>
		</div>
	);
}
