import type { ImageProps } from '@mantine/core';
import { Image } from '@mantine/core';
import { integrationList } from '@repo/common/constants/integration/integrations';
import { colors } from '@repo/theme/primitives';
import {
	IconBook,
	IconChartHistogram,
	IconFileAnalytics,
	IconFolders,
	IconMessages,
} from '@tabler/icons-react';
import type { RefAttributes } from 'react';
import type { IIntegration } from '../api';
import { useIntegrationPrefetched } from '../api';
import { SecodaMinimalLogo } from '../components/SecodaMinimalLogo';
import type { Integration } from '../lib/models';
import { EntityType } from '../lib/types';

export const COLOR_MAP = {
	[EntityType.collection]: colors.teal[5],
	[EntityType.metric]: colors.primary[5],
	[EntityType.dictionary_term]: colors.primary[5],
	[EntityType.glossary]: colors.primary[5],
	[EntityType.document]: colors.indigo[5],
	[EntityType.question]: colors.yellow[5],
	[EntityType.all]: colors.blue[5],
};

export const iconSrcFromIntegration = (
	integration: Integration | IIntegration
) => {
	if (integration.marketplace_integration_spec_version) {
		return integration.marketplace_integration_spec_version.icon_url;
	}

	if (integration.type === 'custom' && (integration as IIntegration).icon_url) {
		return (integration as IIntegration).icon_url;
	}

	return integrationList.find(
		(i) => i.type?.toLowerCase() === integration.type.toLowerCase()
	)?.src;
};

export const vectorGraphicFromIntegrationType = (integrationType: string) =>
	integrationList.find(
		(i) => i.type?.toLowerCase() === integrationType?.toLowerCase()
	)?.src;

export const useIconSrcFromIntegrationId = (id: string) => {
	const { data: integration } = useIntegrationPrefetched({ id });
	if (!integration) {
		return '';
	}
	return iconSrcFromIntegration(integration);
};

export function getIconByEntityType(
	// By the order of precedence entity type > integration > integration type
	entityType?: EntityType,
	integration?: Integration | IIntegration,
	integrationType?: string
) {
	switch (entityType) {
		case EntityType.collection:
			return {
				icon: IconFolders,
				backgroundColor: COLOR_MAP[entityType],
			};
		case EntityType.metric:
			return {
				icon: IconChartHistogram,
				backgroundColor: COLOR_MAP[entityType],
			};
		case EntityType.dictionary_term:
			return {
				icon: IconBook,
				backgroundColor: COLOR_MAP[entityType],
			};
		case EntityType.glossary:
			return {
				icon: IconBook,
				backgroundColor: COLOR_MAP[entityType],
			};
		case EntityType.document:
			return {
				icon: IconFileAnalytics,
				backgroundColor: COLOR_MAP[entityType],
			};
		case EntityType.question:
			return {
				icon: IconMessages,
				backgroundColor: COLOR_MAP[entityType],
			};
		default:
			// eslint-disable-next-line no-case-declarations
			let src: string | undefined | null = null;
			if (integration) {
				src = iconSrcFromIntegration(integration);
			} else if (integrationType) {
				src = vectorGraphicFromIntegrationType(integrationType);
			}

			return {
				icon: src
					? (
							props: JSX.IntrinsicAttributes &
								ImageProps &
								RefAttributes<HTMLDivElement>
						) => <Image {...props} src={src} />
					: SecodaMinimalLogo,
				backgroundColor: COLOR_MAP[EntityType.all],
			};
	}
}
