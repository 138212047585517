import { EntityType } from '../../../lib/types';
import CollectionFooter from './Collection/CollectionFooter';
import DictionaryTermFooter from './DictionaryTerm/DictionaryTermFooter';
import DocumentFooter from './Document/DocumentFooter';
import GlossaryTermFooter from './GlossaryTerm/GlossaryTermFooter';
import MetricFooter from './Metric/MetricFooter';
import QuestionFooter from './Question/QuestionFooter';

interface EntityModalFooterProps {
	type?: EntityType;
}

function EntityModalFooter({ type }: EntityModalFooterProps) {
	return (
		<>
			{type === EntityType.collection && <CollectionFooter />}
			{type === EntityType.dictionary_term && <DictionaryTermFooter />}
			{type === EntityType.glossary && <GlossaryTermFooter />}
			{type === EntityType.metric && <MetricFooter />}
			{type && [EntityType.document, EntityType.query].includes(type) && (
				<DocumentFooter />
			)}
			{type === EntityType.question && <QuestionFooter />}
		</>
	);
}

export default EntityModalFooter;
