import type { MantineTheme } from '@mantine/core';
import {
	Card,
	createStyles,
	Divider,
	Group,
	Select,
	Stack,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { Button, Icon } from '@repo/foundations';
import { find, isNil, map } from 'lodash-es';
import { useState } from 'react';
import type { NodeProps } from 'reactflow';
import { Handle, Position } from 'reactflow';
import { useMention } from '../../../api/hooks';
import { SecodaEntityIcon } from '../../SecodaEntity';
import { lineageStore } from '../store';
import type { TemporaryNodeData } from '../types';
import { EdgeHandle } from '../types';
import SelectItemComponent from './SelectItemComponent';

const useStyles = createStyles((theme: MantineTheme) => ({
	card: {
		width: 500,
		overflow: 'unset',
		borderColor: theme.colors.primary[1],
	},
	group: {
		flexDirection: 'row-reverse',
	},
	handle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '18px !important',
		height: '18px !important',
		'&:first-of-type': {
			backgroundColor: theme.colors.primary[6],
			left: -9,
		},
		'&:nth-of-type(2)': {
			backgroundColor: theme.colors.primary[6],
			right: -9,
		},
	},
}));

function TemporaryNode({ id, data }: NodeProps<TemporaryNodeData>) {
	const [searchValue, setSearchValue] = useState('');
	const [debouncedSearchTerm] = useDebouncedValue(searchValue, 350);

	const { data: searchResults } = useMention({
		searchTerm: searchValue,
		options: {
			select: ({ results }) => results,
		},
	});

	const { classes } = useStyles();

	const handleSave = () => {
		lineageStore.saveTemporaryNode(id);
	};

	const handleRemove = () => {
		lineageStore.removeTemporaryNode(id);
	};

	const handleItemSelect = (value: string) => {
		lineageStore.setTemporaryNodeEntity(
			id,
			find(searchResults ?? [], { id: value })
		);
	};

	return (
		<Card className={classes.card} withBorder>
			<Handle
				className={classes.handle}
				id={EdgeHandle.TEMPORARY_TARGET}
				type="target"
				position={Position.Left}
			/>
			<Handle
				className={classes.handle}
				id={EdgeHandle.TEMPORARY_SOURCE}
				type="source"
				position={Position.Right}
			/>
			<Stack>
				<Select
					classNames={{
						itemsWrapper: 'nowheel nopan nodrag',
					}}
					label="Add a Lineage Node"
					placeholder="Search for an entity"
					searchable
					filter={() => true}
					searchValue={searchValue}
					onSearchChange={setSearchValue}
					onChange={handleItemSelect}
					data={map(searchResults, (result) => ({
						label: result.title,
						value: result.id,
						entity: result,
					}))}
					value={data.entity?.id}
					icon={
						data.entity ? (
							<SecodaEntityIcon entity={data.entity} size={24} />
						) : null
					}
					iconWidth={36}
					rightSection={
						<Icon name="chevronDown" color="icon/primary/default" />
					}
					dropdownPosition="bottom"
					itemComponent={SelectItemComponent}
				/>

				<Divider />
				<Group className={classes.group} spacing={12}>
					<Button
						size="md"
						disabled={!data.hasEdges || isNil(data.entity)}
						onClick={handleSave}
					>
						Save
					</Button>
					<Button variant="default" size="md" onClick={handleRemove}>
						Cancel
					</Button>
				</Group>
			</Stack>
		</Card>
	);
}

export default TemporaryNode;
