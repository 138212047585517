import {
	createStyles,
	Timeline as MantineTimeline,
	Stack,
} from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { capitalize, lowerCase } from 'lodash-es';
import moment from 'moment-timezone';
import { Virtuoso } from 'react-virtuoso';
import { ISecodaEntity } from '../../api';
import type { IActivity } from '../../api/types/models/notification';
import type { SecodaEntity } from '../../lib/models';
import { getEntityDisplayType } from '../../utils/entityDisplayUtils';
import {
	notificationEventToIconName,
	notificationEventToTitle,
} from './Timeline.helpers';
import ActivityLogDescription from './TimelineDescription';

export interface ITimelineProps {
	notifications: IActivity[];
	// TODO: Remove the following prop. At this level, the concept
	// of entity should not exist. The notifications are sufficient.
	// This currently exists because of the hardcoded notification
	// defined below.
	entity?: SecodaEntity | ISecodaEntity;
	// This is used to determine whether to fetch more notifications
	// when the user scrolls to the bottom of the timeline, and
	// it marks that the component should render a infinite scroll
	onEndReached?: VoidFunction;
}

const useStyles = createStyles((theme) => ({
	itemBody: {
		paddingLeft: theme.spacing['2xl'],
		paddingBottom: theme.spacing.sm,
	},
}));

function Timeline({ notifications, entity, onEndReached }: ITimelineProps) {
	const { classes, theme } = useStyles();

	const notificationContent = (notification: IActivity) => {
		const iconName = notificationEventToIconName(notification);

		const title = notificationEventToTitle(
			notification,
			false,
			false,
			capitalize
		);

		return (
			<MantineTimeline.Item
				key={notification.id}
				bullet={<Icon name={iconName} />}
				title={title}
				classNames={{
					itemBody: classes.itemBody,
				}}
			>
				<ActivityLogDescription notification={notification} />
				<Text size="xs" mt={4}>
					{moment(notification.created_at).fromNow()}
				</Text>
			</MantineTimeline.Item>
		);
	};

	if (onEndReached) {
		return (
			<Virtuoso
				data={notifications}
				itemContent={(_, notification) => notificationContent(notification)}
				components={{
					List: Stack,
				}}
				endReached={onEndReached}
			/>
		);
	}

	return (
		<MantineTimeline
			data-testid="activity-log-timeline"
			reverseActive
			bulletSize={24}
			lineWidth={2}
		>
			{notifications.map(notificationContent)}
			{/* The following is hardcoded because the backend
                does not currently return creation events. */}
			{entity && (
				<MantineTimeline.Item
					key={0}
					bullet={<Icon name="plus" />}
					title={`New ${lowerCase(getEntityDisplayType(entity))}`}
				>
					<Text color="text/secondary/default" size="sm">
						{`The ${lowerCase(getEntityDisplayType(entity))} was first created with identifier `}
						<Text variant="link" span inherit>
							{entity.id}
						</Text>
					</Text>
					<Text size="xs" mt={4}>
						{moment(entity.created_at).fromNow()}
					</Text>
				</MantineTimeline.Item>
			)}
		</MantineTimeline>
	);
}

export default Timeline;
