import { Checkbox, Tooltip } from '@mantine/core';
import { noop } from 'lodash-es';
import { SelectablePropertyItem } from '../SingleSelector/types';

export interface SelectorItemCheckboxProps {
	checked: boolean;
	disabled: boolean;
	disabledTooltip?: string;
	item: SelectablePropertyItem;
	isViewerUser: boolean;
	permittedId: string;
}
function SelectorItemCheckbox({
	checked,
	disabled,
	disabledTooltip,
	item,
	isViewerUser,
	permittedId,
}: SelectorItemCheckboxProps) {
	if (!isViewerUser || permittedId === item.value) {
		if (disabled && disabledTooltip) {
			return (
				<Tooltip label={disabledTooltip}>
					<span>
						<Checkbox checked={checked} onChange={noop} disabled={disabled} />
					</span>
				</Tooltip>
			);
		}

		return <Checkbox checked={checked} onChange={noop} disabled={disabled} />;
	}

	return null;
}

export default SelectorItemCheckbox;
