import { Box } from '@mantine/core';
import SingleSelector from '@repo/common/components/SingleSelector/SingleSelector';
import type { ButtonVariants } from '@repo/foundations';
import { isEmpty } from 'lodash-es';
import React, { useEffect } from 'react';
import type { ITemplate } from '../../../api';
import { useTemplateList } from '../../../api/hooks/templates';
import type { EntityType } from '../../../lib/types';

interface TemplateSelectorProps {
	onChange?: (template: ITemplate) => void;
	initialValue?: string;
	forceVariant?: ButtonVariants;
	entityType: EntityType;
}

export function TemplateSelector({
	entityType,
	onChange,
	forceVariant,
	initialValue,
}: TemplateSelectorProps) {
	const [selected, setSelected] = React.useState<string>(initialValue ?? '');

	const { data } = useTemplateList({
		filters: {
			entity_type_template: entityType,
		},
		options: {
			enabled: !!entityType,
			suspense: false,
		},
	});

	useEffect(() => {
		if (!selected) {
			const defaultTemplate = data?.results.find(
				(template) => template.is_default
			);
			if (defaultTemplate) {
				setSelected(defaultTemplate.id);
				onChange?.(defaultTemplate);
			}
		}
	}, [data, selected, onChange]);

	const templates =
		data?.results
			?.filter((template) => !isEmpty(template.title))
			?.map((template) => ({
				label: template.title || 'Untitled',
				value: template.id,
				is_default: template.is_default,
			})) ?? [];

	const handleChange = (value: string) => {
		setSelected(value);
		onChange?.(
			data?.results?.find(
				(template) => template.id === value
			) as unknown as ITemplate
		);
	};

	let variant: ButtonVariants = selected ? 'default' : 'tertiary';

	if (forceVariant) {
		variant = forceVariant;
	}

	return (
		<Box maw={512}>
			<SingleSelector
				variant={variant}
				iconType="avatar"
				isViewerUser={false}
				searchable
				options={templates}
				placeholder="Template"
				placeholderIconName="fileDescription"
				onChange={handleChange}
				initialSelected={selected}
				property="template"
			/>
		</Box>
	);
}
