import { Box, createStyles } from '@mantine/core';
import { useMemo } from 'react';
import { useCatalogList } from '../../../api/hooks/resourceCatalog';
import { TableV2 } from '../../../components/TableV2';
import { useDataQualityScoreColumns } from '../../../components/TableV2/SecodaEntity.hooks';
import { EntityType } from '../../../lib/types';
import {
	useActions,
	useColumns,
} from '../../TeamCatalogPage/TeamCatalogPage.hooks';

interface ISchemasTabProps {
	databaseId: string;
}

const useStyles = createStyles({
	schemaWrapper: {
		height: '100%',
	},
});

function SchemasTab({ databaseId }: ISchemasTabProps) {
	const { classes } = useStyles();

	const defaultColumns = useColumns();
	const dataQualityColumns = useDataQualityScoreColumns({
		catalogType: EntityType.schema,
	});

	const columns = useMemo(
		() => [...defaultColumns, ...dataQualityColumns],
		[dataQualityColumns, defaultColumns]
	);

	const actions = useActions();

	return (
		<Box className={classes.schemaWrapper}>
			<TableV2
				pluralTypeString="schemas"
				withSearch
				withInteractiveHeader
				withCsvExport
				withCheckbox
				withActions={actions}
				columns={columns}
				usePaginationList={useCatalogList}
				columnVisibility={{
					catalogType: EntityType.schema,
					catalogServerType: EntityType.schema,
				}}
				defaultRequiredSearchParams={{
					entity_type: EntityType.schema,
				}}
				defaultRequiredCatalogFilters={{
					operands: [
						{
							operands: [],
							field: 'parent_id',
							operator: 'exact',
							value: databaseId,
						},
					],
				}}
			/>
		</Box>
	);
}

export default SchemasTab;
