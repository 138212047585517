import { ActionIcon, TextInput, TextInputProps } from '@mantine/core';
import { getHotkeyHandler } from '@mantine/hooks';
import { Icon } from '@repo/foundations';
import type { Ref } from 'react';
import { forwardRef, useCallback, useRef } from 'react';
import { useForceReRender } from '../../utils/hook/useForceReRender';
import { useStyles } from './SearchBox.styles';

interface ISearchBoxProps extends TextInputProps {
	defaultSearchTerm?: string;
	onSearch: (searchTerm: string) => void;
	onCancelSearch?: () => void;
	placeholder?: string;
	disabled?: boolean;
	autoFocus?: boolean;
	onlySearchOnEnter?: boolean;
	alwaysShowCancel?: boolean;
}

const SearchBox = forwardRef(
	(
		{
			defaultSearchTerm,
			onSearch,
			onCancelSearch = () => {},
			placeholder = 'Search',
			disabled = false,
			autoFocus = true,
			variant = 'primary',
			onlySearchOnEnter = false,
			alwaysShowCancel = false,
			...other
		}: ISearchBoxProps,
		ref: Ref<HTMLInputElement | undefined>
	) => {
		const { classes } = useStyles({ variant });

		const searchTerm = useRef<string>(defaultSearchTerm || '');

		const { forceUpdate, key } = useForceReRender();

		const searchIcon = (
			<Icon
				name="search"
				color="icon/secondary/default"
				className={classes.searchIcon}
			/>
		);

		const handleCancelSearch = useCallback(() => {
			searchTerm.current = '';
			onSearch('');
			onCancelSearch();
			forceUpdate();
		}, [forceUpdate, onCancelSearch, onSearch]);

		return (
			<TextInput
				data-testid="search-box-input"
				autoComplete="off"
				autoCorrect="off"
				spellCheck="false"
				key={key}
				ref={ref as Ref<HTMLInputElement>}
				autoFocus={autoFocus}
				defaultValue={searchTerm.current}
				onChange={(e) => {
					searchTerm.current = e.currentTarget.value;
					if (!onlySearchOnEnter) {
						onSearch(e.currentTarget.value);
					}
				}}
				onKeyDown={getHotkeyHandler([
					['Escape', handleCancelSearch],
					['Enter', () => onSearch(searchTerm.current)],
				])}
				icon={searchIcon}
				placeholder={placeholder}
				className={classes.textInput}
				classNames={{
					wrapper: classes.wrapper,
					input: classes.input,
				}}
				disabled={disabled}
				rightSection={
					(alwaysShowCancel || searchTerm.current) && (
						<ActionIcon onClick={handleCancelSearch}>
							<Icon name="x" />
						</ActionIcon>
					)
				}
				{...other}
			/>
		);
	}
);

SearchBox.displayName = 'SearchBox';

export default SearchBox;
