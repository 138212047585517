import { IntegrationSelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { SecodaThemeShades } from '@repo/theme/types';
import { IIntegration } from '../api';

export interface TagProps {
	label: string;
	value: string | boolean;
	color: SecodaThemeShades;
	tagIntegration: IIntegration;
	maintainProp: boolean;
}

export function extractInheritedTags(
	tags: Array<TagProps> | IntegrationSelectablePropertyItem[] | undefined,
	initialValue: string[] | undefined
) {
	return tags
		?.filter(
			(tag) =>
				tag.tagIntegration &&
				tag.tagIntegration.id !== '' &&
				initialValue?.includes(tag.value as string) &&
				tag.maintainProp === true
		)
		.map((tag) => tag.value as string);
}
