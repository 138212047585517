import { Stack } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { IMetric, useAuthUser } from '../../api';
import { SelectableProperty } from '../../components/EntityPageLayout/EntityPropertySidebar';
import { useQueryableIntegrationOptions } from '../../components/Sql/Actions/IntegrationSelectAction.hooks';
import { isViewerOfEntity } from '../../utils/authorization/roles';
import { metricOptions } from '../../utils/metrics';
import { hashCode } from '../../utils/utils';
import type { DjangoValueType } from '../TemplatePage/types';

const EMPTY_DIMENSION_SENTINAL_VALUE = 'EMPTY_DIMENSION_SENTINAL_VALUE';

const NUMERIC_FORMAT_OPTIONS = [
	{ value: 'number', label: 'Number', icon: null },
	{ value: 'percent', label: 'Percent', icon: null },
	{ value: 'currency', label: 'Currency', icon: null },
	{ value: 'decimal', label: 'Decimal', icon: null },
];

const SCHEDULE_OPTIONS = [
	{ value: null as unknown as string, label: 'None', icon: null },
	{ value: 1 as unknown as string, label: 'Hourly', icon: null },
	{ value: 24 as unknown as string, label: 'Daily', icon: null },
	{
		value: (24 * 7) as unknown as string,
		label: 'Weekly',
		icon: null,
	},
	{
		value: (24 * 30) as unknown as string,
		label: 'Monthly',
		icon: null,
	},
];

export const MetricSidebarSection = observer(
	({
		metric,
		onChange,
	}: {
		metric: IMetric;
		onChange: (key: string) => (value: DjangoValueType) => void;
	}) => {
		const { user } = useAuthUser();
		const viewerOfEntity = isViewerOfEntity(user, metric);

		const results = metric.results ?? [];
		const resultsKey = hashCode(JSON.stringify(results));
		const chartKey = hashCode(
			`${resultsKey}${metric.primary}${metric.time}${metric.dimension}${metric.integration}`
		);

		const { timeCols, metricCols, dimensionCols } = metricOptions(results);

		const integrationOptions = useQueryableIntegrationOptions();

		const primaryOptions = metricCols.map(({ name }) => ({
			value: name,
			label: name,
			icon: null,
		}));

		const timeOptions = timeCols.map(({ name }) => ({
			value: name,
			label: name,
			icon: null,
		}));

		let dimensionOptions: { value: string; label: string; icon: null }[] = [];
		if (dimensionCols.length > 0) {
			dimensionOptions = dimensionOptions.concat([
				{
					value: EMPTY_DIMENSION_SENTINAL_VALUE,
					label: 'None',
					icon: null,
				},
			]);
		}
		dimensionOptions = dimensionOptions.concat(
			dimensionCols.map(({ name }) => ({
				value: name,
				label: name,
				icon: null,
			}))
		);

		return (
			<Stack key={chartKey} spacing="xs">
				<SelectableProperty
					key={`${chartKey}integration`}
					onChange={onChange('integration')}
					selected={metric.integration}
					type="single"
					label="Integration"
					value="integration"
					iconType="react-node"
					isViewerUser={viewerOfEntity}
					searchable
					options={integrationOptions}
					placeholder="Select integration"
				/>
				<SelectableProperty
					key={`${chartKey}primary`}
					searchable
					selected={metric.primary ?? ''}
					onChange={onChange('primary')}
					type="single"
					label="Primary"
					value="primary"
					iconType="react-node"
					isViewerUser={viewerOfEntity}
					options={primaryOptions}
					placeholder="Select primary"
				/>
				<SelectableProperty
					key={`${chartKey}time`}
					searchable
					selected={metric.time ?? ''}
					onChange={onChange('time')}
					type="single"
					label="Time"
					value="time"
					iconType="react-node"
					isViewerUser={viewerOfEntity}
					options={timeOptions}
					placeholder="Select time"
				/>
				<SelectableProperty
					key={`${chartKey}dimension`}
					searchable
					selected={metric.dimension ?? ''}
					onChange={onChange('dimension')}
					type="single"
					label="Dimension"
					value="dimension"
					iconType="react-node"
					isViewerUser={viewerOfEntity}
					options={dimensionOptions}
					placeholder="Select dimension"
				/>
				<SelectableProperty
					searchable
					key={`${chartKey}numeric_format`}
					selected={metric.numeric_format ?? ''}
					onChange={onChange('numeric_format')}
					type="single"
					label="Numeric Format"
					value="numeric_format"
					iconType="react-node"
					isViewerUser={viewerOfEntity}
					options={NUMERIC_FORMAT_OPTIONS}
					placeholder="Select numeric format"
				/>
				<SelectableProperty
					key={`${chartKey}scheduled_delta`}
					selected={metric.scheduled_delta ?? (null as unknown as string)}
					onChange={onChange('scheduled_delta')}
					type="single"
					label="Refresh"
					value="scheduled_delta"
					iconType="react-node"
					isViewerUser={viewerOfEntity}
					searchable
					options={SCHEDULE_OPTIONS}
					placeholder="Select refresh"
				/>
			</Stack>
		);
	}
);
