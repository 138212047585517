import { IBaseModel } from '../../../../api';
import CommandPalette from '../CommandPalette';
import type { ICommandListItem } from '../CommandPalette/constants';

export interface BulkActionsCommandPaletteProps<T extends IBaseModel> {
	actions?: ICommandListItem<T>[];
}

export function BulkActionsCommandPalette({
	actions = [],
}: BulkActionsCommandPaletteProps<IBaseModel>) {
	return <CommandPalette withAI={false} actions={actions} />;
}
