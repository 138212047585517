import { Menu } from '@mantine/core';
import { Icon, IconButton } from '@repo/foundations';
import { useState } from 'react';
import type { BackgroundJob } from '../../../lib/models';
import { BackgroundJobProgress } from '../../BackgroundJobProgress';
import { lineageStore } from '../store';

export function DownloadMenuButton() {
	const [exportJob, setExportJob] = useState<BackgroundJob | null>(null);

	const handleExportToCSV = async () => {
		const backgroundJob = await lineageStore.exportToCSV();
		setExportJob(backgroundJob);
	};

	return (
		<>
			<Menu>
				<Menu.Target>
					<IconButton variant="tertiary" iconName="dots" />
				</Menu.Target>
				<Menu.Dropdown>
					<Menu.Item
						icon={<Icon name="download" />}
						onClick={lineageStore.exportToPNG}
					>
						Export PNG image
					</Menu.Item>
					<Menu.Item
						icon={<Icon name="download" />}
						onClick={handleExportToCSV}
					>
						Export CSV file
					</Menu.Item>
				</Menu.Dropdown>
			</Menu>
			{exportJob && (
				<BackgroundJobProgress
					key={exportJob.id}
					job={exportJob}
					fileName="impact-analysis.csv"
				/>
			)}
		</>
	);
}
