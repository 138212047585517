import { MantineNumberSize, Skeleton } from '@mantine/core';

interface DataQualityTimeseriesChartLoadingProps {
	width?: MantineNumberSize;
	height?: MantineNumberSize;
}

export function DataQualityTimeseriesChartLoading({
	width,
	height,
}: DataQualityTimeseriesChartLoadingProps) {
	return <Skeleton width={width} height={height} />;
}
