import {
	Box,
	Dialog,
	Group,
	Loader,
	ScrollArea,
	Skeleton,
	Stack,
} from '@mantine/core';
import { Button, Title } from '@repo/foundations';
import { useBoolean } from 'ahooks';
import { isNil, map, noop, split, truncate } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback, useRef } from 'react';
import { useBackgroundJob } from '../../api/hooks/backgroundJob';
import type { IBackgroundJob } from '../../api/types/models/backgroundJob';
import type { BackgroundJob } from '../../lib/models';
import { useStyles } from './BackgroundJobProgress.styles';

interface IBackgroundJobProgressProps {
	job: BackgroundJob;
	onCompleted?: (backgroundJob?: IBackgroundJob) => void;
	withReloadButton?: boolean;
	fileName?: string;
}

export const BackgroundJobProgress = observer(
	({
		job,
		onCompleted = noop,
		withReloadButton = false,
		fileName,
	}: IBackgroundJobProgressProps) => {
		const { classes, theme } = useStyles();

		const [opened, openedHandlers] = useBoolean(true);
		const viewport = useRef<HTMLDivElement>(null);

		const scrollToBottom = () => {
			viewport.current?.scrollTo({
				top: viewport.current.scrollHeight,
				behavior: 'smooth',
			});
		};

		const { data: backgroundJob } = useBackgroundJob({
			id: job.id,
			options: {
				suspense: false,
				enabled: !isNil(job.id),
				refetchInterval: (data) => {
					scrollToBottom();
					if (data?.failed || data?.completed) {
						onCompleted(data);
						return false;
					}

					return 1000;
				},
			},
		});

		const handleDownload = useCallback(async () => {
			await job.getArtifact(fileName);
			onCompleted();
			openedHandlers.setFalse();
		}, [fileName, job, onCompleted, openedHandlers]);

		const {
			title = '',
			has_artifact: hasArtifact = false,
			logs = '',
			started = false,
			completed = false,
			failed = false,
		} = backgroundJob || {};

		const jobLogs = split(logs, '\n');
		const isJobRunning = started && !completed && !failed;

		const handleRefreshClick = window.location.reload;

		return (
			<Dialog
				className={classes.dialog}
				opened={opened}
				onClose={openedHandlers.setFalse}
				withCloseButton
				size="xl"
			>
				<Stack spacing="xs">
					<Group>
						<Title size="md">
							<Skeleton visible={!started}>
								{truncate(title, { length: 30 })}
							</Skeleton>
						</Title>
						{isJobRunning && <Loader size="xs" />}
					</Group>
					<ScrollArea
						className={classes.logs}
						offsetScrollbars
						type="always"
						w={theme.other.space[120]}
						h={theme.other.space[100]}
						viewportRef={viewport}
					>
						<Skeleton visible={!started}>
							{map(jobLogs, (log: string, index: number) => (
								<Box key={index}>{log}</Box>
							))}
						</Skeleton>
					</ScrollArea>
					<Group position="right">
						<Skeleton visible={!started}>
							{hasArtifact && (
								<Button size="sm" onClick={handleDownload}>
									Download artifact
								</Button>
							)}
							{withReloadButton && (
								<Button
									disabled={!(completed || failed)}
									size="sm"
									onClick={handleRefreshClick}
									variant="primary"
								>
									Reload page
								</Button>
							)}
						</Skeleton>
					</Group>
				</Stack>
			</Dialog>
		);
	}
);
