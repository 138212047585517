import { Menu } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { ISecodaEntity } from '../../../../api';
import type { SecodaEntity } from '../../../../lib/models';
import { downloadEntityFile } from '../helper';

interface ExportActionProps {
	entity: SecodaEntity | ISecodaEntity;
}

export function ExportAction({ entity }: ExportActionProps) {
	const handleJSONExportClick = () => {
		downloadEntityFile(entity, 'json');
	};

	const handleCSVExportClick = () => {
		downloadEntityFile(entity, 'csv');
	};

	return (
		<>
			<Menu.Item icon={<Icon name="csv" />} onClick={handleCSVExportClick}>
				Export CSV
			</Menu.Item>
			<Menu.Item icon={<Icon name="json" />} onClick={handleJSONExportClick}>
				Export JSON
			</Menu.Item>
			<Menu.Item icon={<Icon name="pdf" />} onClick={() => window.print()}>
				Export PDF
			</Menu.Item>
		</>
	);
}
