import { useDebouncedValue } from '@mantine/hooks';

import { Stack } from '@mantine/core';
import { getSummaryAndBreadCrumbs } from '@repo/common/utils/breadcrumb';
import { Text } from '@repo/foundations';
import { MenuItem } from '@repo/secoda-editor';
import { useCallback } from 'react';
import { SecodaEntityIcon } from '../../../../SecodaEntity';
import { MentionMenuItem, useMentionMenu } from '../hooks/useMentionMenu';
import { CommandMenu, CommandMenuProps } from './Toolbar/CommandMenu';
import { CommandMenuItem } from './Toolbar/CommandMenuItem';

export function MentionMenu(
	props: Omit<
		CommandMenuProps<MentionMenuItem>,
		'renderMenuItem' | 'items' | 'id'
	>
) {
	const { search: searchTerm } = props;

	const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 350);

	const { isLoading, data } = useMentionMenu({
		searchTerm: debouncedSearchTerm,
	});

	const renderMenuItem = useCallback(
		(item: MenuItem, _index: number, options: Record<string, unknown>) => {
			const mentionItem = item as MentionMenuItem;

			const breadcrumbs = mentionItem.entity
				? getSummaryAndBreadCrumbs(
						mentionItem.entity.native_type || mentionItem.entity.entity_type,
						mentionItem.entity.search_metadata
					)
				: null;

			return (
				<CommandMenuItem
					key={_index}
					// eslint-disable-next-line react/no-unstable-nested-components
					icon={() => (
						<SecodaEntityIcon
							entity={mentionItem.entity}
							size={20}
							inverseIconColor
						/>
					)}
					shortcutKeys={item.shortcutKeys}
					{...options}
				>
					<Stack spacing={0}>
						<Text size="sm" lineClamp={1}>
							{mentionItem.title || 'Untitled'}
						</Text>
						<Text size="xs" color="text/secondary/default" lineClamp={1}>
							{breadcrumbs}
						</Text>
					</Stack>
				</CommandMenuItem>
			);
		},
		[]
	);

	return (
		<CommandMenu
			{...props}
			loading={isLoading}
			id="mention-menu-container"
			trigger="@"
			renderMenuItem={renderMenuItem}
			items={data ?? []}
		/>
	);
}
