import { Box, createStyles, Flex, Skeleton, Stack, Tabs } from '@mantine/core';
import type { IntegrationTab } from '@repo/common/constants/integration/integrations.preferences';
import { getSupportedTabs } from '@repo/common/constants/integration/integrations.preferences';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Title } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import { capitalize, isNil } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useIntegration, useIntegrationSpec } from '../../../api';
import { useAiEnabled } from '../../../hooks/useAIEnabled';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { FullWidthLoadingSpinner } from '../../LoadingSpinner';
import { toTitleCase } from '../../SearchListItem/helpers';
import { IntegrationTitle } from '../components/IntegrationTitle';
import IntegrationAlertConfig from '../IntegrationAlertConfig';
import { IntegrationImport } from '../IntegrationImport';
import { IntegrationLookerGit } from '../IntegrationLookerProjects';
import IntegrationMapping from '../IntegrationMapping';
import { IntegrationPermissions } from '../IntegrationPermissions';
import { IntegrationPopularity } from '../IntegrationPopularity';
import { ConnectionStep } from '../IntegrationSelectionPage/StepComponents';
import IntegrationSlackAIPreferences from '../IntegrationSlackAIPreferences';
import IntegrationSlackChannels from '../IntegrationSlackChannels';
import IntegrationSlackNotifications from '../IntegrationSlackNotifications';
import {
	PreferencesPanel,
	SchedulePanel,
	SelectPanel,
	SyncPanel,
} from './TabPanels';

interface IntegrationPageProps {
	id: string;
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		flexWrap: 'nowrap',
		width: '100%',
		height: '100%',
		padding: `${theme.spacing.lg} ${theme.spacing.xl}`,
		gap: theme.spacing['3xl'],
	},
	tabsWrapper: {
		alignItems: 'flex-start',
		width: theme.other.width.sm,
		height: '100%',
		overflowY: 'auto',
		gap: theme.spacing.sm,
	},
	tabsHeader: {
		padding: `${theme.spacing['3xs']} ${theme.spacing.sm}`,
	},
	tabsRoot: {
		width: '100%',
		height: '100%',
	},
	tabsList: {
		width: '100%',
		gap: theme.spacing['5xs'],
	},
	tab: {
		backgroundColor: theme.other.getColor('fill/transparent/default'),
		padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
		fontSize: theme.other.typography.text.sm,
		fontWeight: theme.other.typography.weight.semibold,
		color: theme.other.getColor('text/secondary/default'),
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/transparent/hover'),
		},
		'&:focus, &:active': {
			backgroundColor: theme.other.getColor('fill/transparent/active'),
			color: theme.other.getColor('text/primary/default'),
		},
	},
	panelWrapper: {
		width: '100%',
		justifyContent: 'center',
		overflowY: 'auto',
	},
	panel: {
		alignItems: 'flex-start',
		maxWidth: theme.other.width.lg,
		height: '100%',
		padding: `0 ${theme.spacing.xs}`,
		margin: '0 auto',
	},
	tabDescription: {
		fontSize: theme.other.typography.text.sm,
		color: theme.other.getColor('text/secondary/default'),
	},
}));

function IntegrationPage({ id }: IntegrationPageProps) {
	const [tabs, setTabs] = useState<IntegrationTab[]>();
	const [activeTab, setActiveTab] = useState<string>();

	const { slackV3 } = useFeatureFlags();
	const { enableAi } = useAiEnabled();

	const { classes, theme } = useStyles();

	const { tab: tabParam } = useParams();

	const { data: integration } = useIntegration({
		id,
	});
	const integrationSpec = useIntegrationSpec(id);

	const navigate = useNavigate();

	useEffect(() => {
		if (integrationSpec && !tabs) {
			const supportedTabs = getSupportedTabs(integrationSpec, {
				isSlackV3: slackV3,
				isAIEnabledWorkspace: enableAi,
			});
			setTabs(supportedTabs);
			setActiveTab(supportedTabs[0].name);
		}
	}, [integrationSpec, slackV3, enableAi, tabs]);

	// Set the selected tab based on the browser URL.
	useEffect(() => {
		if (tabParam && tabs) {
			const found = tabs?.findIndex(
				(f) => f.name.toLowerCase() === tabParam.toLowerCase()
			);
			if (!isNil(found)) {
				// Use `isNil` because it can be 0, which will resolve to false.
				setActiveTab(tabs[found].name);
			}
		}
	}, [tabParam, tabs]);

	const handleTabChange = useCallback(
		(tabName: string) => {
			setActiveTab(tabName);
			navigate(`/integrations/${integration?.id}/${tabName.toLowerCase()}`);
		},
		[navigate, integration?.id]
	);

	const getTabName = useCallback((integrationTab: IntegrationTab) => {
		if (integrationTab.preserveCase) {
			return integrationTab.displayName || integrationTab.name;
		}
		return toTitleCase(integrationTab.displayName || integrationTab.name);
	}, []);

	if (!tabs || !integrationSpec || !integration) {
		return <FullWidthLoadingSpinner />;
	}

	return (
		<Tabs
			classNames={{
				root: classes.tabsRoot,
				tabsList: classes.tabsList,
				tab: classes.tab,
				panel: classes.panel,
			}}
			orientation="vertical"
			variant="pills"
			value={activeTab}
			onTabChange={handleTabChange}
		>
			<Flex className={classes.wrapper}>
				<Stack className={classes.tabsWrapper}>
					<IntegrationTitle
						integrationSpec={integrationSpec}
						integration={integration}
						titleSize={'md'}
					/>
					<Tabs.List>
						{tabs.map((tab) => (
							<Tabs.Tab key={tab.name} value={tab.name}>
								{getTabName(tab)}
							</Tabs.Tab>
						))}
					</Tabs.List>
				</Stack>
				<Flex className={classes.panelWrapper} id="integration-page-scroll">
					{tabs.map((tab) => (
						<Tabs.Panel
							id={`panel-${tab.name}`}
							key={tab.name}
							value={tab.name}
						>
							<Stack h="100%" spacing="2xl">
								<Stack spacing={theme.spacing['3xs']}>
									<Title size="xl">{tab.title ?? capitalize(tab.name)}</Title>
									<Box className={classes.tabDescription}>
										{tab.description}
									</Box>
								</Stack>
								{/* SYNC TAB */}
								{tab.name === 'syncs' && (
									<SyncPanel
										integration={integration}
										spec={integrationSpec}
										navigateToSchedule={() => handleTabChange('schedule')}
									/>
								)}
								{/* SCHEDULE TAB */}
								{tab.name === 'schedule' && (
									<SchedulePanel
										integration={integration}
										spec={integrationSpec}
									/>
								)}
								{/* MAPPING TAB */}
								{tab.name === 'mappings' && (
									<IntegrationMapping integration={integration as any} />
								)}
								{/* GROUPS + SCHEMAS TAB */}
								{(tab.name === 'groups' || tab.name === 'schemas') && (
									<SelectPanel
										integration={integration}
										spec={integrationSpec}
									/>
								)}
								{/* POPULARITY TAB */}
								{tab.name === 'popularity' && (
									<IntegrationPopularity integration={integration} />
								)}
								{/* PERMISSIONS TAB */}
								{tab.name === 'permissions' && (
									<IntegrationPermissions
										integration={integration}
										spec={integrationSpec}
									/>
								)}
								{/* PROJECTS TAB */}
								{tab.name === 'project' && (
									<IntegrationLookerGit integration={integration} />
								)}
								{/* IMPORT TAB */}
								{tab.name === 'import' && (
									<IntegrationImport integration={integration} />
								)}
								{/* NOTIFICATIONS TAB */}
								{tab.name === 'notifications' && (
									<IntegrationSlackNotifications />
								)}
								{/* CHANNELS TAB */}
								{tab.name === 'channels' && integration.id && (
									<IntegrationSlackChannels integrationId={integration.id} />
								)}
								{/* AI TAB */}
								{tab.name === 'AI' && integration.id && (
									<IntegrationSlackAIPreferences
										integrationId={integration.id}
									/>
								)}
								{/* PREFERENCES TAB */}
								{tab.name === 'preferences' && (
									<PreferencesPanel
										integration={integration}
										spec={integrationSpec}
										options={tab.options || []}
									/>
								)}
								{tab.name === 'alerts' && integration?.id && (
									<IntegrationAlertConfig integrationId={integration.id} />
								)}
								{/* CONNECTION TAB */}
								{tab.name === 'connection' && (
									<ConnectionStep
										integrationSpec={integrationSpec}
										integration={integration}
										hideTitle
									/>
								)}
							</Stack>
						</Tabs.Panel>
					))}
				</Flex>
			</Flex>
		</Tabs>
	);
}

function IntegrationPageWrapper() {
	const { id } = useParams();

	if (!id) {
		return (
			<Flex p={space[4]}>
				<Skeleton />
			</Flex>
		);
	}

	return <IntegrationPage id={id} />;
}

export default IntegrationPageWrapper;
