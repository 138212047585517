// eslint-disable-next-line no-restricted-imports
import { createStyles, MantineSize, Text } from '@mantine/core';
import { useApiGetGroup } from '@repo/api-codegen';
import { Icon } from '@repo/foundations';
import { SelectablePropertyItem } from '../ItemIcon/types';

type IItemIconItem = Partial<Pick<SelectablePropertyItem, 'value'>>;

interface GroupIconProps {
	item: IItemIconItem;
	size?: MantineSize;
}

const useStyles = createStyles((theme, { size }: { size: MantineSize }) => ({
	groupEmoji: {
		fontSize: theme.fontSizes[size],
		lineHeight: theme.fontSizes[size],
	},
}));

function GroupIcon({ item, size = 'md' }: GroupIconProps) {
	const { classes } = useStyles({ size });
	const { data: group } = useApiGetGroup({
		pathParams: { groupId: item.value as string },
	});

	if (group && group.icon) {
		return <Text className={classes.groupEmoji}>{group.icon}</Text>;
	} else {
		return <Icon name="usersGroup" />;
	}
}

export default GroupIcon;
