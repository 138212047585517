import { useMemo } from 'react';
import { AutomationField, useDataQualityAccess } from '../../../../../api';
import { isValidEnumValue } from '../../../../../utils/enumUtils';
import AutomationCardButton from '../../AutomationCardButton';
import {
	AUTOMATION_CARD_VALUE_TYPE_MAP,
	type AutomationValueType,
} from '../../constants';
import { AUTOMATION_FIELD_OPTIONS } from './constants';
import { useAutomationActionCardFilterStore } from './context';
import { getFilterConditionOptions, getFilterFieldDisplayValue } from './utils';

interface AutomationActionCardFilterFieldButtonProps {
	filterSearchKey: string;
	field?: AutomationField;
	setValueType: (value: React.SetStateAction<AutomationValueType>) => void;
}

function AutomationActionCardFilterFieldButton({
	field,
	filterSearchKey,
	setValueType,
}: AutomationActionCardFilterFieldButtonProps) {
	const label = getFilterFieldDisplayValue(field) || 'Property';
	const store = useAutomationActionCardFilterStore();
	const dqsEnabled = useDataQualityAccess();

	const buttonOptions = useMemo(() => {
		if (!dqsEnabled) {
			return AUTOMATION_FIELD_OPTIONS.filter(
				(option) =>
					![
						AutomationField.QUALITY,
						AutomationField.QUALITY_ACCURACY,
						AutomationField.QUALITY_RELIABILITY,
						AutomationField.QUALITY_STEWARDSHIP,
						AutomationField.QUALITY_USABILITY,
					].includes(option.value as AutomationField)
			);
		}
		return AUTOMATION_FIELD_OPTIONS;
	}, [dqsEnabled]);

	const handleFieldSelection = (option: string | boolean) => {
		if (isValidEnumValue(AutomationField, option)) {
			store.setFilterField(filterSearchKey, option);

			// Set the condition as the first available option.
			const firstCondition = getFilterConditionOptions(option)[0];
			store.setFilterCondition(filterSearchKey, firstCondition);

			setValueType(AUTOMATION_CARD_VALUE_TYPE_MAP[option].valueType);
		}
	};

	return (
		<AutomationCardButton
			addFilter
			buttonLabel={label}
			buttonOptions={buttonOptions}
			onClick={handleFieldSelection}
			defaultOpened={!field}
			property="filter"
		/>
	);
}

export default AutomationActionCardFilterFieldButton;
