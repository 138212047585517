/* eslint-disable func-style */
/* eslint-disable no-plusplus */
import { showNotification } from '@mantine/notifications';
import type {
	InfiniteData,
	MutationFunction,
	QueryKey,
} from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { assign, forEach, isNil, merge, omit } from 'lodash-es';
import { WithOnlyIdRequired } from '../../../lib/typescript';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import type {
	DefaultContext,
	IApiListResponse,
	IBaseModel,
	IBaseModelUpdateArgs,
	Namespace,
	UpdateRequestParams,
} from '../../types';
import { RESOURCE_CATALOG_NAMESPACE } from '../resourceCatalog/constants.ns';
import { isQueryKeyPrefix, isQueryKeySuffix } from '../utils/fns';

export function getDefaultUpdateFn<
	TApiResponseData extends IBaseModel,
	TRequestData extends
		UpdateRequestParams<TApiResponseData> = UpdateRequestParams<TApiResponseData>,
>(namespace: Namespace): MutationFunction<TApiResponseData, TRequestData> {
	const mutationFn = async function updateBaseModel({
		data,
		signal,
	}: TRequestData) {
		const url = getEndpoints(namespace).byId(data.id);

		// Filter out the id from the body, since the id is in the url. This is
		// needed because the backend will assume `id` in the body is to be mutated,
		// and will throw an error.

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { id, ...body } = data;

		// We also remove some additional readOnly properties here but add them in
		// the original data for optimistic updates.
		const updateData = omit(body, ['display_metadata']);

		const { data: responseData } = await apiClient.patch<TApiResponseData>(
			url,
			updateData,
			{ signal }
		);
		return responseData;
	};

	return mutationFn;
}

const defaultContextValues = {
	previousData: undefined,
	updatedData: undefined,
};

/**
 * Optimistically update the entity in the cache.
 * @param data
 * @param queryKey
 * @param onMutateFn
 * @returns
 */
export function optimisticUpdateById(
	data: WithOnlyIdRequired<IBaseModel>,
	queryKeyParam: QueryKey,
	onMutateFn?: () => void
) {
	function optimisticUpdate(queryKey: QueryKey) {
		// Snapshot the previous value.
		const previousData = queryClient.getQueryData<IBaseModel>(queryKey);

		// Ignore if the entity is not in the cache, or if the entity `id` does not match.
		if (isNil(previousData) || previousData.id !== data.id) {
			onMutateFn?.();
			return {
				...defaultContextValues,
				queryKey,
			} as unknown as DefaultContext<IBaseModel>;
		}

		const updatedData = merge(previousData, {
			...data,
			updated_at: new Date().toISOString(),
		}) as WithOnlyIdRequired<IBaseModel>;

		// Optimistically update the entity in the cache.
		queryClient.setQueryData(queryKey, updatedData);

		return {
			queryKey,
			previousData,
			updatedData,
		} as DefaultContext<IBaseModel>;
	}

	queryClient
		.getQueryCache()
		.getAll()
		.map((cache) => cache.queryKey)
		.forEach((existingQueryKey) => {
			if (isQueryKeySuffix(existingQueryKey, [data.id])) {
				optimisticUpdate(existingQueryKey);
			}
		});

	// Invoke the custom onMutate function if it exists.
	onMutateFn?.();

	return optimisticUpdate(queryKeyParam);
}

/**
 *  Optimistically update the entity in the cache.
 * @param data
 * @param optimisticUpdatePrefixKeys
 */
export function optimisticUpdateLists(
	data: WithOnlyIdRequired<IBaseModel>,
	optimisticUpdatePrefixKeys: QueryKey[]
) {
	// Optimistically update the list queries if they are provided.
	optimisticUpdatePrefixKeys?.forEach((prefix) => {
		queryClient
			.getQueryCache()
			.getAll()
			.map((cache) => cache.queryKey)
			.forEach((existingQueryKey) => {
				// Check if the query key contains the passed prefix. OR if there
				// is a  'list' inside the query key.
				if (
					isQueryKeyPrefix(existingQueryKey, prefix) ||
					existingQueryKey.includes('list')
				) {
					const listQueryData =
						queryClient.getQueryData<IApiListResponse<IBaseModel>>(
							existingQueryKey
						);

					if (
						(
							listQueryData &&
							(listQueryData as unknown as InfiniteData<
								IApiListResponse<IBaseModel>
							>)
						)?.pages
					) {
						// Infinite list query data.
						const updatedListQueryData = (
							listQueryData as unknown as InfiniteData<
								IApiListResponse<IBaseModel>
							>
						)?.pages.map((page) => ({
							...page,
							results: page.results.map((entity) =>
								entity.id === data.id ? assign({}, entity, data) : entity
							),
						}));
						queryClient.setQueryData(existingQueryKey, {
							...listQueryData,
							pages: updatedListQueryData,
						});
						// Paginated list query data.
					} else if (listQueryData?.results) {
						const updatedListQueryData = listQueryData?.results.map((entity) =>
							entity.id === data.id ? assign({}, entity, data) : entity
						);
						queryClient.setQueryData(existingQueryKey, {
							...listQueryData,
							results: updatedListQueryData,
						});
					}
				}
			});
	});
}

/**
 * Hook for updating a base model. It automatically does an optimistic update of
 * the entity in the cache. This can be disabled by setting
 * disableOptimisticUpdate to true.
 *
 * @param params Params for react-query.
 * - Set disableOptimisticUpdate to disable the optimistic update.
 * - queryKeyFactory is used to get queryKey for optimistic updates.
 * - Caches of queries from invalidationKeys are invalidated after the mutation
 *   is successful.
 * @returns React Query hook for updating a base model
 */
function useUpdateBaseModel<
	TApiResponseData extends IBaseModel,
	TUpdateRequestData extends
		UpdateRequestParams<TApiResponseData> = UpdateRequestParams<TApiResponseData>,
	TContext = DefaultContext<TApiResponseData>,
	TError = unknown,
>({
	namespace,
	mutationFn: customMutationFn,
	queryKeyFactory,
	disableOptimisticUpdate = false,
	disableInvalidation = false,
	// Default to the resource catalog list query key.
	optimisticUpdatePrefixKeys = [RESOURCE_CATALOG_NAMESPACE],
	invalidationKeys = [],
	options,
}: IBaseModelUpdateArgs<
	TApiResponseData,
	TUpdateRequestData,
	TContext,
	TError
>) {
	const mutationFn =
		customMutationFn ||
		getDefaultUpdateFn<TApiResponseData, TUpdateRequestData>(namespace);

	return useMutation<TApiResponseData, TError, TUpdateRequestData, TContext>({
		mutationFn,
		...options,
		onMutate: async (variables: TUpdateRequestData) => {
			const { data } = variables;

			if (!disableOptimisticUpdate) {
				optimisticUpdateLists(data, optimisticUpdatePrefixKeys);
			}

			if (disableOptimisticUpdate) {
				// Invoke the custom onMutate function if it exists.
				options?.onMutate?.(variables);

				return defaultContextValues as TContext;
			}

			// Optimistically update all appearances of the entity in the cache,
			// filtered by id.
			queryClient
				.getQueryCache()
				.getAll()
				.map((cache) => cache.queryKey)
				.forEach((existingQueryKey) => {
					if (isQueryKeySuffix(existingQueryKey, [data.id])) {
						optimisticUpdateById(data, existingQueryKey, () =>
							options?.onMutate?.(variables)
						);
					}
				});

			const defaultQueryKey = queryKeyFactory?.byId(data.id);

			// Cancel any outgoing refetches (so they don't overwrite our optimistic
			// update).
			await queryClient.cancelQueries({ queryKey: defaultQueryKey });

			if (!defaultQueryKey) {
				options?.onMutate?.(variables);
				return defaultContextValues as TContext;
			}

			return optimisticUpdateById(data, defaultQueryKey, () =>
				options?.onMutate?.(variables)
			) as TContext;
		},
		onError: (
			error: TError,
			variables: TUpdateRequestData,
			context: TContext | undefined
		) => {
			// Provide a notification if the mutation fails.
			showNotification({
				title: 'Failed to update the entity',
				message: 'Please check permissions and try again',
				color: 'red',
			});

			if (!context) {
				// Invoke the custom onError function if it exists.
				options?.onError?.(error, variables, context);
			}

			const { queryKey, previousData } =
				context as DefaultContext<TApiResponseData>;

			if (disableOptimisticUpdate || !queryKey || !previousData) {
				// Invoke the custom onError function if it exists.
				options?.onError?.(error, variables, context);

				return;
			}

			// Rollback to the previous value if the mutation fails
			queryClient.setQueryData(queryKey, previousData);

			// Invoke the custom onError function if it exists
			options?.onError?.(error, variables, context);
		},
		onSettled: (
			data: TApiResponseData | undefined,
			error: TError | null,
			variables: TUpdateRequestData,
			context: TContext | undefined
		) => {
			const { queryKey } = context as DefaultContext<TApiResponseData>;

			if (!disableInvalidation) {
				// QueryKey can be undefined, move it up here to avoid early return
				// Invalidate the cache of all given invalidation keys
				forEach(invalidationKeys, (invalidationKey) => {
					queryClient.invalidateQueries({ queryKey: invalidationKey });
				});
			}

			if (!disableInvalidation && queryKey) {
				// Invalidate the cache of the updated entity.
				queryClient.invalidateQueries({ queryKey });
			}

			options?.onSettled?.(data, error, variables, context);
		},
	});
}

export default useUpdateBaseModel;
