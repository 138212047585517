import type { GroupProps, ModalProps } from '@mantine/core';
import { Divider, Group, Modal, ScrollArea, Stack } from '@mantine/core';
import { Button, IconButton, Text } from '@repo/foundations';
import type React from 'react';
import { PropsWithChildren } from 'react';

export interface IScrollableModalProps {
	opened: boolean;

	onClose(): void;

	title: string | React.ReactNode;
	size?: string | number;
	withHeaderDivider?: boolean;
	withDoneFooter?: boolean;
	modalProps?: Partial<ModalProps>;
	footer?: React.ReactNode;
	headerProps?: GroupProps;
}

export function ScrollableModal({
	opened,
	onClose,
	title,
	children,
	size,
	withHeaderDivider = true,
	withDoneFooter = true,
	modalProps = {},
	footer,
	headerProps = {},
}: PropsWithChildren<IScrollableModalProps>) {
	return (
		<Modal
			padding={0}
			opened={opened}
			onClose={onClose}
			title={null}
			size={size}
			withCloseButton={false}
			{...modalProps}
		>
			<Stack spacing={0}>
				<Group
					pl="lg"
					pt="md"
					pr="sm"
					pb="sm"
					position="apart"
					noWrap
					{...headerProps}
				>
					{typeof title === 'string' ? (
						<Text size="md" weight="bold">
							{title}
						</Text>
					) : (
						title
					)}
					<IconButton onClick={onClose} iconName="x" />
				</Group>
				{withHeaderDivider && <Divider />}
				{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
				{/* @ts-ignore poorly typed component by Mantine */}
				<ScrollArea.Autosize mah="75vh">{children}</ScrollArea.Autosize>

				{withDoneFooter && (
					<>
						<Divider />
						<Group px="lg" py="md" position="right">
							<Button size="md" onClick={onClose}>
								Done
							</Button>
						</Group>
					</>
				)}
				{footer && <Divider />}
				{footer}
			</Stack>
		</Modal>
	);
}
