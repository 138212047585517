import {
	Box,
	Center,
	Group,
	Menu,
	Select,
	Stack,
	Table,
	Tooltip,
	useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import {
	Badge,
	Button,
	IconButton,
	Title,
	TruncateText,
} from '@repo/foundations';
import { capitalize } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { IUser } from '../../../api';

import { UserRole } from '@repo/common/enums/UserRole';
import {
	queryClient,
	useAuthUser,
	useInviteUsers,
	useUpdateUser,
	usersQueryKeyFactory,
} from '../../../api';
import { useExtendedUserList } from '../../../api/hooks/user/useExtendedUserList';
import type { User } from '../../../lib/models';
import { InviteMembersModal } from '../../PriceIncreaseModals';
import SearchBox from '../../SearchBox/SearchBox';
import { UserAvatar } from '../../UserAvatar';
import { SettingsTableEmpty } from '../Empty';
import RoleSelect from '../Selectors/RoleSelector';
import { Setting } from '../Setting';
import { useMembersTabSelectRole } from './MembersTab.helpers';

type MembersListFilterType =
	| 'Members'
	| 'INVITED'
	| 'DEACTIVATED'
	| keyof typeof UserRole;

const sortByRole = (a: IUser, b: IUser) => {
	// This is the order of roles, that is always used.
	const roleOrder = ['ADMIN', 'EDITOR', 'VIEWER'];

	const roleA = a.role?.toUpperCase();
	const roleB = b.role?.toUpperCase();

	// Determine the role index for each user.
	const roleIndexA =
		roleOrder.indexOf(roleA) !== -1
			? roleOrder.indexOf(roleA)
			: roleOrder.length;

	const roleIndexB =
		roleOrder.indexOf(roleB) !== -1
			? roleOrder.indexOf(roleB)
			: roleOrder.length;

	// Compare roles based on role index
	if (roleIndexA < roleIndexB) return -1;
	if (roleIndexA > roleIndexB) return 1;

	// If roles are the same, compare display names.
	return a.display_name.localeCompare(b.display_name);
};

export const Members = observer(() => {
	const theme = useMantineTheme();
	const [queryParams, setQueryParams] = useSearchParams();
	const { isAdminUser, user: currentUser, workspace } = useAuthUser();
	const { activeUsers, disabledUsers } = useExtendedUserList();
	const disabled = !isAdminUser;
	const navigate = useNavigate();

	const [searchTerm, setSearch] = useState('');
	const [selectedFilter, setSelectedFilter] = useState<MembersListFilterType>(
		(queryParams.get('member_role') as MembersListFilterType) ?? 'Members'
	);

	const [opened, { open, close }] = useDisclosure(false);

	const { selectRole } = useMembersTabSelectRole();
	const { mutateAsync: inviteUsers } = useInviteUsers(
		usersQueryKeyFactory.list()
	);
	const { mutateAsync: updateUser } = useUpdateUser({
		options: {
			onSuccess: () => {
				queryClient.invalidateQueries(usersQueryKeyFactory.allLists());
			},
		},
	});

	const handleToggleActivation = useCallback(
		async (toggleUser: IUser) => {
			await updateUser({
				data: {
					id: toggleUser.id,
					disabled: !toggleUser.disabled,
				},
			});
			showNotification({
				message: !toggleUser.disabled ? 'User deactivated' : 'User activated',
			});
		},
		[updateUser]
	);

	const handleInvite = useCallback(
		(emails: string[], role: UserRole, groups: string[], teams: string[]) =>
			inviteUsers({
				emails,
				role,
				groups,
				teams,
			}),
		[inviteUsers]
	);

	const handleSelectedFilterChange = useCallback(
		(value: MembersListFilterType) => {
			setQueryParams((prev) => ({ ...prev, member_role: value }));
			setSelectedFilter(value);
		},
		[setQueryParams]
	);

	const users = useMemo(() => {
		const initialUsers = [...(activeUsers ?? []), ...(disabledUsers ?? [])];

		let filtered = [];

		if (selectedFilter === 'Members') {
			filtered = initialUsers.filter((user) => !user.pending && !user.disabled);
		} else if (selectedFilter === 'INVITED') {
			filtered = initialUsers.filter((user) => user.pending && !user.disabled);
		} else if (selectedFilter === 'DEACTIVATED') {
			filtered = initialUsers.filter((user) => user.disabled);
		} else {
			filtered = initialUsers.filter(
				(user) =>
					user.role &&
					user.role.toLowerCase() === selectedFilter?.toLowerCase() &&
					!user.disabled &&
					!user.pending
			);
		}

		return filtered
			.filter(
				(user) =>
					user.display_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
					user.email.toLowerCase().includes(searchTerm.toLowerCase())
			)
			.sort(sortByRole);
	}, [activeUsers, disabledUsers, searchTerm, selectedFilter]);

	const handleOpenUser = useCallback(
		(userId: string) => {
			navigate(`/user/${userId}`);
		},
		[navigate]
	);

	const handleCsvExport = () => {
		const csvContent = [
			['Display Name', 'Email', 'Role'],
			...users.map((user) => [user.display_name, user.email, user.role]),
		]
			.map((row) => row.join(','))
			.join('\n');

		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.setAttribute('download', `Secoda ${workspace.name} members.csv`);
		link.click();
	};

	return (
		<Stack>
			<Setting
				title="Manage members"
				description="Members can view and edit your workspace depending on their assigned roles."
			>
				<Box pos="relative">
					<Button onClick={open} variant="primary" disabled={disabled}>
						Invite members
					</Button>
				</Box>
			</Setting>
			<Title order={3} weight="semibold" size="md">
				<Group noWrap>
					<SearchBox placeholder="Search by name" onSearch={setSearch} />
					<Select
						data={[
							'Members',
							'ADMIN',
							'EDITOR',
							'VIEWER',
							'GUEST',
							'INVITED',
							'DEACTIVATED',
						].map((value) => ({
							label: capitalize(value),
							value,
						}))}
						value={selectedFilter}
						onChange={handleSelectedFilterChange}
					/>
					<Tooltip label="Export CSV file">
						<IconButton iconName="download" onClick={handleCsvExport} />
					</Tooltip>
				</Group>
			</Title>

			{users?.length === 0 && (
				<SettingsTableEmpty
					title="No users found."
					description="Add a user to get started."
				/>
			)}

			{users?.length > 0 && (
				<Table
					style={{ width: '100%', tableLayout: 'fixed', cursor: 'pointer' }}
					highlightOnHover
				>
					<tbody>
						{users.map((user) => (
							<tr
								key={user.id}
								data-testid={`membership-row-${user.id}-2y1sVvJp`}
							>
								<td
									aria-label="User"
									onClick={() => handleOpenUser(user.id)}
									width={theme.other.space[100]}
								>
									<Group>
										<UserAvatar user={user} size="sm" />
										<Stack spacing={0}>
											<TruncateText
												text={user.display_name}
												length={36}
												size="sm"
												weight="bold"
											/>
											<TruncateText text={user.email} length={34} size="xs" />
										</Stack>
									</Group>
								</td>
								<td width={theme.other.space[40]}>
									{user.pending && !user.disabled ? (
										<Tooltip label={`${user.email} has a pending invitation `}>
											<Center>
												<Badge w="100%">Invited</Badge>
											</Center>
										</Tooltip>
									) : (
										!user.disabled && (
											<RoleSelect
												disabled={disabled || currentUser?.id === user.id}
												role={user.role as UserRole}
												selectRole={(role) =>
													selectRole(user as unknown as User, role)
												}
											/>
										)
									)}
									{user.disabled && (
										<Tooltip
											label={`${user.email} has been deactivated from this workspace`}
										>
											<Center>
												<Badge w="100%">Deactivated</Badge>
											</Center>
										</Tooltip>
									)}
								</td>
								<td aria-label="Actions" align="right">
									<Menu position="bottom-end">
										<Menu.Target>
											<IconButton iconName="dots" variant="tertiary" />
										</Menu.Target>
										<Menu.Dropdown>
											<Menu.Item onClick={() => handleToggleActivation(user)}>
												{user.disabled ? 'Activate' : 'Deactivate'}
											</Menu.Item>
										</Menu.Dropdown>
									</Menu>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			)}
			<InviteMembersModal
				opened={opened}
				onClose={close}
				onConfirm={handleInvite}
			/>
		</Stack>
	);
});
