import { Menu } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { type SecodaEntity } from '../../../../lib/models';
import { closeModal, openModal } from '../../../ModalManager';

import { ISecodaEntity } from '../../../../api';
import { ChangeRequestModal } from '../../../ChangeRequestModal';

const MODAL_ID = 'change-request-modal';

interface ChangeRequestActionProps {
	entity: SecodaEntity | ISecodaEntity;
}

export function ChangeRequestAction({ entity }: ChangeRequestActionProps) {
	const handleRequestChange = () =>
		openModal({
			modalId: MODAL_ID,
			title: 'Change Request',
			children: (
				<ChangeRequestModal
					entity={entity}
					close={() => closeModal(MODAL_ID)}
				/>
			),
		});

	return (
		<Menu.Item icon={<Icon name="edit" />} onClick={handleRequestChange}>
			Request change
		</Menu.Item>
	);
}
