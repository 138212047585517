import {
	MonitorStatus,
	useApiGetMonitorPossibleStaled,
	useApiGetResourcePossibleArchived,
} from '@repo/api-codegen';
import { useEffect, useState } from 'react';
import { useUser } from '../../api';
import { createMockableHook } from '../../utils/createMockableHook';
import type {
	EntityPreview,
	MonitorPreview,
	UserPreview,
} from '../ResourcePreview/ResourcePreview';

const ID_REGEX =
	/\/([^ ]*)\/([^ ]*)?([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/;

export enum ResourceLinkType {
	Entity = 'entity',
	User = 'user',
	Monitor = 'monitor',
}

function useResourceLinkInternal({
	href,
}: {
	href: string | undefined | null;
}) {
	const [id, setId] = useState<string>('');
	const [resourceType, setResourceType] = useState<ResourceLinkType | null>(
		null
	);

	const {
		data: userData,
		isInitialLoading: isLoadingUser,
		error: errorUser,
	} = useUser({
		id,
		options: {
			enabled: resourceType === ResourceLinkType.User,
		},
	});

	const {
		data: monitorData,
		isInitialLoading: isLoadingMonitor,
		error: errorMonitor,
	} = useApiGetMonitorPossibleStaled(
		{
			pathParams: {
				monitorId: id,
			},
		},
		{
			enabled: resourceType === ResourceLinkType.Monitor,
		}
	);

	const {
		data: entityData,
		isInitialLoading: isLoadingEntity,
		error: errorEntity,
	} = useApiGetResourcePossibleArchived(
		{
			pathParams: {
				entityId: id,
			},
		},
		{
			enabled: resourceType === ResourceLinkType.Entity,
		}
	);

	useEffect(() => {
		if (!href) {
			return;
		}

		const matches = href.match(ID_REGEX);

		if (matches?.length !== 4) {
			return;
		} else if (matches?.[1].endsWith('user')) {
			setResourceType(ResourceLinkType.User);
			setId(matches?.[3]);
		} else if (matches?.[1].endsWith('monitor')) {
			setResourceType(ResourceLinkType.Monitor);
			setId(matches?.[3]);
		} else {
			setResourceType(ResourceLinkType.Entity);
			setId(matches?.[3]);
		}
	}, [href]);

	let data: UserPreview | MonitorPreview | EntityPreview | undefined =
		entityData as EntityPreview | undefined;
	if (resourceType === ResourceLinkType.User) {
		data = userData as UserPreview | undefined;
	} else if (resourceType === ResourceLinkType.Monitor) {
		data = {
			id: monitorData?.id,
			entity_type: 'monitor',
			title: monitorData?.name,
			description: monitorData?.description,
			status: monitorData?.status as MonitorStatus,
			stale: !!monitorData?.staled_at,
			owners: monitorData?.owner_users ?? [],
		} as MonitorPreview | undefined;
	}

	return {
		id,
		data,
		resourceType,
		isLoading: isLoadingEntity || isLoadingUser || isLoadingMonitor,
		hasError: !!errorEntity || !!errorUser || !!errorMonitor,
	};
}

export const [useResourceLink, MockUseResourceLink] = createMockableHook(
	useResourceLinkInternal
);
