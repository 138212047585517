import { Box, BoxProps, createStyles } from '@mantine/core';
import { Text } from '@repo/foundations';

interface CommandMenuDividerProps extends BoxProps {
	index: number;
	title?: string;
}

const useStyles = createStyles(
	(theme, { withDivider }: { withDivider: boolean }) => {
		if (!withDivider) {
			return {
				divider: {},
			};
		}

		return {
			divider: {
				margin: `${theme.spacing['2xs']} 0`,
				borderTop: `${theme.other.borderWidth.xs}px solid ${theme.other.getColor('border/secondary/default')}`,
			},
		};
	}
);

export function CommandMenuDivider({
	title,
	index,
	className,
	...boxProps
}: CommandMenuDividerProps) {
	const { classes, cx } = useStyles({
		withDivider: index !== 0,
	});

	return (
		<Box {...boxProps} className={cx(classes.divider, className)}>
			{title && (
				<Text
					size="xs"
					pt="sm"
					px="sm"
					weight="bold"
					color="text/secondary/default"
				>
					{title}
				</Text>
			)}
		</Box>
	);
}
