import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import type { Monitor } from '../../api';
import { useMonitorList } from '../../api';
import { TableV2 } from '../../components/TableV2';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import { useActions, useColumns } from './MonitorList.hooks';

function MonitorList({
	withAdditionalButtons,
	defaultRequiredSearchParams,
}: {
	withAdditionalButtons?: React.ReactNode;
	defaultRequiredSearchParams?: Record<string, any>;
}) {
	const columns = useColumns();
	const actions = useActions();

	const navigate = useNavigate();

	const handleClick = useCallback(
		(id: string) => {
			navigate(`/monitor/${id}`);
		},
		[navigate]
	);

	return (
		<TableV2
			pluralTypeString="monitors"
			tableCacheKey="monitors"
			columns={columns}
			columnVisibility={{
				catalogServerType: 'monitors',
				catalogType: 'monitor',
			}}
			usePaginationList={useMonitorList}
			withActions={actions}
			withCheckbox={true}
			withQuickActions={['actions::run']}
			withCsvExport
			withInteractiveHeader
			withSearch
			withFilters={false}
			defaultSort={null}
			withAdditionalButtons={withAdditionalButtons}
			defaultRequiredSearchParams={defaultRequiredSearchParams}
			onCellClick={(cell: {
				column: ExtendedDataTableColumn<Monitor>;
				record: { id: string };
			}) => {
				if (cell.column.navigate !== false) {
					handleClick(cell.record.id);
				}
			}}
		/>
	);
}

export default observer(MonitorList);
