import { Box, BoxProps } from '@mantine/core';
import { useListBoxStyles } from './ListBox.styles';

interface ListBoxDividerProps extends BoxProps {}

export function ListBoxDivider({
	className,
	...boxProps
}: ListBoxDividerProps) {
	const { classes, cx } = useListBoxStyles({} as any, {
		name: 'Menu',
	});

	return <Box {...boxProps} className={cx(classes.divider, className)} />;
}
