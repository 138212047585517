export const base = {
	page: 'Page',
	metric: 'Metric',
	queryChart: 'Query Chart',
	queryBlock: 'Query',
	addColumnAfter: 'Insert column after',
	addColumnBefore: 'Insert column before',
	addRowAfter: 'Insert row after',
	addRowBefore: 'Insert row before',
	alignCenter: 'Align center',
	alignLeft: 'Align left',
	alignRight: 'Align right',
	bulletList: 'Bulleted list',
	checkboxList: 'Checklist',
	codeBlock: 'Code block',
	codeCopied: 'Copied to clipboard',
	chartDuplicated: 'Chart duplicated',
	chartDeleted: 'Chart deleted',
	queryDuplicated: 'Query duplicated',
	queryDeleted: 'Query deleted',
	codeInline: 'Code',
	createLink: 'Create link',
	createLinkError: 'Sorry, an error occurred creating the link',
	comment: 'Comment',
	createNewDoc: 'Create a new doc',
	deleteColumn: 'Delete column',
	deleteRow: 'Delete row',
	deleteTable: 'Delete table',
	deleteImage: 'Delete image',
	downloadImage: 'Download image',
	replaceImage: 'Replace image',
	alignImageLeft: 'Float left half width',
	alignImageRight: 'Float right half width',
	alignImageDefault: 'Center large',
	em: 'Italic',
	embedInvalidLink: 'Sorry, that link won’t work for this embed type',
	file: 'File attachment',
	findOrCreateDoc: 'Find or create a doc…',
	toc: 'Table of Contents',
	heading1: 'Heading 1',
	heading2: 'Heading 2',
	heading3: 'Heading 3',
	text: 'Text',
	hr: 'Divider',
	image: 'Image',
	fileUploadError: 'Sorry, an error occurred uploading the file',
	imageCaptionPlaceholder: 'Write a caption',
	info: 'Info',
	infoNotice: 'Info notice',
	link: 'Link',
	mention: 'Mention',
	linkCopied: 'Link copied',
	list: 'List',
	style: 'Style',
	mark: 'Highlight',
	newLineEmpty: 'Type "/" for commands…',
	newLineWithSlash: 'Keep typing to filter…',
	newMentionWithAt: ' Mention a user or resource...',
	noResults: 'No results',
	openLink: 'Open in new tab',
	orderedList: 'Numbered list',
	pageBreak: 'Page break',
	pasteLink: 'Paste a link…',
	pasteLinkWithTitle: (title: string): string => `Paste a ${title} link…`,
	placeholder: 'Placeholder',
	quote: 'Quote',
	removeLink: 'Delete link',
	searchOrPasteLink: 'Enter link URL',
	strikethrough: 'Strikethrough',
	strong: 'Bold',
	table: 'Table',
	tip: 'Tip',
	tipNotice: 'Tip notice',
	warning: 'Warning',
	warningNotice: 'Warning notice',
	placeholderInput: 'Add placeholder text',
	close: 'Close',
	createSummary: 'Summarize',
	matchCase: 'Match case',
	enableRegex: 'Enable regex',
	previous: 'Previous',
	next: 'Next',
	replace: 'Replace',
	replaceAll: 'Replace all',
	toggleReplace: 'Toggle replace',
	find: 'Find',
	toggleHeader: 'Toggle header',
};

export default base;
