import axios from 'axios';
import axiosRetry from 'axios-retry';
import { authHeaders } from '../../api/common';
import { ExecutionStatus } from './types';

export async function fetchExecutionStatusWithRetry(
	url: string,
	retryDelay = 5
): Promise<ExecutionStatus> {
	const client = axios.create({
		headers: authHeaders().headers,
	});

	client.interceptors.response.use((response) => {
		const { status } = response.data;

		if (status === 'completed' || status === 'failed') {
			return response.data;
		}

		return Promise.reject(response);
	});

	axiosRetry(client, {
		retryDelay: () => retryDelay * 1_000,
		retries: Infinity,
	});

	return client.get(url);
}
