import { keymap } from 'prosemirror-keymap';
import type { Command } from 'prosemirror-state';
import * as Y from 'yjs';
import Extension from './Extension';
import {
	redo,
	undo,
	yCursorPlugin,
	ySyncPlugin,
	yUndoPlugin,
} from './yjs/y-prosemirror';

export default class MultiplayerExtension extends Extension {
	// eslint-disable-next-line class-methods-use-this
	get name() {
		return 'multiplayer';
	}

	get plugins() {
		const { user, provider, document: doc } = this.options;
		const type = doc.get('default', Y.XmlFragment);

		const assignUser = (tr: Y.Transaction) => {
			const clientIds = Array.from(doc.store.clients.keys());

			if (
				tr.local &&
				tr.changed.size > 0 &&
				!clientIds.includes(doc.clientID)
			) {
				const permanentUserData = new Y.PermanentUserData(doc);
				permanentUserData.setUserMapping(doc, doc.clientID, user.id);
				doc.off('afterTransaction', assignUser);
			}
		};

		// Only once an actual change has been made do we add the userId <> clientId
		// mapping, this avoids stored mappings for clients that never made a change
		doc.on('afterTransaction', assignUser);

		return [
			ySyncPlugin(type),
			yCursorPlugin(provider.awareness),
			yUndoPlugin(),
			keymap({
				'Mod-z': undo as Command,
				'Mod-y': redo as Command,
				'Mod-Shift-z': redo as Command,
			}),
		];
	}
}
