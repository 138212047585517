import {
	Alert,
	Card,
	Container,
	Divider,
	Group,
	ScrollArea,
	Stack,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
	InspectLineageMatcher,
	InspectLineageRequest,
	InspectLineageResponse,
	LiteSecodaEntity,
	TableMatcher,
	useApiInspectLineageFromSql,
	useApiSubmitFixLineageRequest,
} from '@repo/api-codegen';
import { Button, Text, TextArea, TextInput, Title } from '@repo/foundations';

// Main component that accepts the InspectLineageResponse as a prop
function TableMatcherCard({ matcher }: { matcher: TableMatcher }) {
	return (
		<Card shadow="xs" padding="lg" withBorder>
			<Text>Cluster: {matcher.cluster || 'N/A'}</Text>
			<Text>Database: {matcher.database || 'N/A'}</Text>
			<Text>Schema: {matcher.schema || 'N/A'}</Text>
			<Text>Table: {matcher.table}</Text>
		</Card>
	);
}

// Component to display a matcher
function LiteSecodaEntityCard({ entity }: { entity: LiteSecodaEntity }) {
	return (
		<Card shadow="xs" padding="lg" withBorder maw={400}>
			<Title order={4}>{entity.title || 'Entity'}</Title>
			<Text>ID: {entity.id}</Text>
			<Text>Entity Type: {entity.entity_type}</Text>
			<Text lineClamp={3}>
				Search metadata: {JSON.stringify(entity.search_metadata)}
			</Text>
			{entity.integration && (
				<>
					<Divider my="sm" />
					<Text>Integration: {entity.integration.name}</Text>
					<Text>Integration Type: {entity.integration.type}</Text>
				</>
			)}
		</Card>
	);
}

function SourceMatcherCard({
	inspectLineageMatcher,
}: {
	inspectLineageMatcher: InspectLineageMatcher;
}) {
	return (
		<Card shadow="sm" padding="lg" withBorder>
			<Title order={3}>Source Matcher</Title>
			<TableMatcherCard matcher={inspectLineageMatcher.matcher} />
			{inspectLineageMatcher.resolved_entity && (
				<>
					<Divider my="sm" />
					<LiteSecodaEntityCard
						entity={inspectLineageMatcher.resolved_entity}
					/>
				</>
			)}
		</Card>
	);
}

function InspectLineageResponseComponent({
	response,
}: {
	response: InspectLineageResponse;
}) {
	return (
		<Stack>
			<Title order={2}>Inspect Lineage Response</Title>
			<Divider />
			<Stack spacing="md">
				<Title order={3}>Source matchers and resolved entities</Title>
				<ScrollArea>
					<Group noWrap>
						{response.source_matcher_results.map((matcher, index) => (
							<SourceMatcherCard key={index} inspectLineageMatcher={matcher} />
						))}
					</Group>
				</ScrollArea>
				<Divider />

				{response.inferred_target_matcher && (
					<>
						<Title order={3}>
							Inferred target matcher (if target entity is not provided)
						</Title>
						<TableMatcherCard matcher={response.inferred_target_matcher} />
					</>
				)}

				{response.target_entity && (
					<>
						<Title order={3}>Target entity</Title>
						<LiteSecodaEntityCard entity={response.target_entity} />
					</>
				)}
			</Stack>
		</Stack>
	);
}

function InspectSQLLineagePage() {
	const {
		mutateAsync: apiInspectLineageFromSql,
		isLoading: isInspectingLineage,
		error,
		data,
	} = useApiInspectLineageFromSql();

	const {
		mutateAsync: apiSubmitFixLineage,
		error: fixLineageError,
		data: fixLineageData,
		isLoading: fixLineageIsLoading,
	} = useApiSubmitFixLineageRequest();

	// Define the form structure
	const form = useForm<InspectLineageRequest>({
		initialValues: {
			workspace_id: null,
			sql: null,
			target_entity_id: null,
			default_database: null,
			default_schema: null,
			dialect: null,
		},

		validate: {
			sql: (value, values) => {
				if (!value && !values.target_entity_id) {
					return 'If SQL query is not provided, target_entity_id must be provided';
				}
				return null;
			},
			target_entity_id: (value, values) => {
				if (!value && !values.sql) {
					return 'If target_entity_id is not provided, SQL query must be provided';
				}
				return null;
			},
			workspace_id: (value, values) => {
				if (!value && !values.target_entity_id) {
					return 'If workspace_id is not provided, target_entity_id must be provided';
				}
				return null;
			},
		},
	});

	const handleSubmit = async (values: InspectLineageRequest) => {
		await apiInspectLineageFromSql({
			body: values,
		});
	};

	const handleFixLineage = async () => {
		await apiSubmitFixLineage({
			body: {
				target_entity_id: form.values.target_entity_id as string,
			},
		});
	};

	return (
		<Container p="md">
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack spacing="md">
					<TextInput
						label="Workspace ID"
						placeholder="Workspace ID"
						{...form.getInputProps('workspace_id')}
					/>

					<TextArea
						label="SQL Query"
						placeholder="Enter SQL query"
						minRows={10}
						{...form.getInputProps('sql')}
					/>

					<TextInput
						label="Target Entity ID"
						placeholder="Enter target entity ID"
						{...form.getInputProps('target_entity_id')}
					/>

					<TextInput
						label="Default Database"
						placeholder="Enter default database"
						{...form.getInputProps('default_database')}
					/>

					<TextInput
						label="Default Schema"
						placeholder="Enter default schema"
						{...form.getInputProps('default_schema')}
					/>

					<TextInput
						label="Dialect"
						placeholder="Enter dialect"
						{...form.getInputProps('dialect')}
					/>

					<Group position="right" mt="md">
						<Button
							type="submit"
							variant="primary"
							loading={isInspectingLineage}
						>
							Submit
						</Button>
					</Group>
				</Stack>
			</form>

			{error && <Alert color="red">{JSON.stringify(error)}</Alert>}
			{data && (
				<>
					<InspectLineageResponseComponent response={data} />
					<Group position="right" mt="md">
						<Button
							disabled={!form.values.target_entity_id}
							onClick={handleFixLineage}
							variant="primary"
							loading={fixLineageIsLoading}
						>
							Fix Lineage of This Target Entity
						</Button>
					</Group>
					{fixLineageError && (
						<Alert color="red">{JSON.stringify(fixLineageError)}</Alert>
					)}
					{fixLineageData && (
						<>
							<Alert color="green">
								Upstream lineages of this table will be updated next time the
								integration run
							</Alert>
							<Alert color="green">{JSON.stringify(fixLineageData)}</Alert>
						</>
					)}
				</>
			)}
		</Container>
	);
}

export default InspectSQLLineagePage;
