import { QueryMetadata } from '@repo/api-codegen';
import { useCatalogList } from '../../../../api/hooks/resourceCatalog';
import { TableV2 } from '../../../../components/TableV2';
import { useColumns } from '../../../TeamCatalogPage/TeamCatalogPage.hooks';

interface QueryResourcesProps {
	queryMetadata?: QueryMetadata;
}

function QueryResources({ queryMetadata }: QueryResourcesProps) {
	const columns = useColumns();

	if (!queryMetadata) {
		return null;
	}

	return (
		<TableV2
			withSearch
			withInteractiveHeader
			withCsvExport
			withCheckbox
			withActions={[]}
			columns={columns}
			usePaginationList={useCatalogList}
			defaultRequiredCatalogFilters={{
				operands: [
					{
						operands: [],
						field: 'id',
						operator: 'in',
						value: [
							...(queryMetadata.related_resources?.map(
								(resource) => resource.id as string
							) || []),
							...[queryMetadata.destination_resource?.id || ''],
						],
					},
				],
			}}
		/>
	);
}

export default QueryResources;
