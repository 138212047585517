import { ActionIcon, Box, createStyles, Flex } from '@mantine/core';
import { IconEmoji } from '@repo/common/components/IconEmoji/IconEmoji';
import { Icon, IconSkeleton, Text } from '@repo/foundations';
import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { SmallLoadingSpinner } from '../../components/LoadingSpinner';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import { TREE_INDENT } from './constants';

interface TreeNodeStyleProps {
	open?: boolean;
	selected?: boolean;
}

export const useTreeNodeStyle = createStyles(
	(theme, { open, selected }: TreeNodeStyleProps) => ({
		button: {
			position: 'relative',
			display: 'flex',
			alignCenter: 'center',
			width: '100%',
			height: theme.other.space[7],
			borderRadius: theme.other.borderRadius.sm,
			paddingTop: theme.other.space[0.25],
			color: theme.other.getColor('text/primary/default'),
			cursor: 'pointer',
			backgroundColor: selected
				? theme.other.getColor('fill/transparent/selected')
				: 'transparent',
			':hover': {
				backgroundColor: theme.other.getColor('fill/transparent/hover'),
			},
			':active': {
				backgroundColor: theme.other.getColor('fill/transparent/active'),
			},
		},
		loadMoreButton: {
			cursor: 'pointer',
			display: 'flex',
			width: '100%',
			height: theme.other.space[7],
			borderRadius: theme.radius.sm,
			fontWeight: theme.other.typography.weight.semibold,
			color: theme.other.getColor('text/primary/default'),
			':hover': {
				backgroundColor: theme.other.getColor('fill/transparent/hover'),
			},
			':active': {
				backgroundColor: theme.other.getColor('fill/transparent/active'),
			},
		},
		toggleContainer: {
			display: 'flex',
			alignItems: 'center',
			width: theme.other.space[7],
		},
		chevron: {
			transform: open ? 'rotate(90deg)' : 'none',
			transition: 'transform 100ms ease',
		},
		chevronButton: {
			padding: 0,
			width: theme.other.space[7],
			height: theme.other.space[7],
			marginRight: theme.other.space[1],
			':hover': {
				backgroundColor: theme.other.getColor('fill/transparent/hover'),
			},
		},
	})
);

type State<T> =
	| { state: 'init' }
	| { state: 'loading' }
	| {
			state: 'loaded';
			open: boolean;
			children: T[];
			nextPage?: number | null;
			isLoadingMore?: boolean;
	  };

interface LoadMoreProps {
	level: number;
	onClick: () => void;
	isLoading: boolean;
}

export function LoadMore({ isLoading, level, onClick }: LoadMoreProps) {
	const { classes, theme } = useTreeNodeStyle({});

	return (
		<Box
			role="button"
			className={classes.loadMoreButton}
			pl={theme.other.space[7] + (level + 1) * TREE_INDENT}
			onClick={onClick}
		>
			<Flex align="center">
				<Box mr={theme.other.space[1]}>
					{isLoading ? <IconSkeleton /> : <Icon name="dots" />}
				</Box>
				<Text size="sm" weight="semibold" color="text/primary/default">
					Load more
				</Text>
			</Flex>
		</Box>
	);
}

export type TreeNodeBase = {
	hasChildren: boolean;
	id: string;
};

export interface TreeNodeProps<T extends TreeNodeBase> {
	node: T;
	level: number;
	getIcon: (node: T, size: number) => React.ReactNode;
	getLabel: (node: T) => React.ReactNode;
	onLoadChildren: (
		node: T,
		page?: number
	) => Promise<T[] | { results: T[]; nextPage?: number | null }>;
	onClick: (e: React.MouseEvent, node: T) => void;
}

export function TreeNode<T extends TreeNodeBase>({
	node,
	level,
	onLoadChildren,
	getIcon,
	getLabel,
	onClick,
}: TreeNodeProps<T>) {
	const paramsId = useParamsIdSuffixUuid();
	const [state, setState] = useState<State<T>>({ state: 'init' });

	const isSelected = useMemo(() => {
		if ('entity' in node) {
			return paramsId === node.id;
		}
		return false;
	}, [node, paramsId]);

	const { classes, theme } = useTreeNodeStyle({
		open: state.state === 'loaded' && state.open,
		selected: isSelected,
	});

	const toggle = useCallback(
		async (e: React.MouseEvent) => {
			e.stopPropagation();
			if (node.hasChildren) {
				if (state.state === 'init') {
					setState({ state: 'loading' });
					const result = await onLoadChildren(node);
					if (Array.isArray(result)) {
						setState({ state: 'loaded', open: true, children: result });
					} else {
						setState({
							state: 'loaded',
							open: true,
							children: result.results,
							nextPage: result.nextPage,
						});
					}
				} else if (state.state === 'loaded') {
					setState({ ...state, open: !state.open });
				}
			}
		},
		[node, state, onLoadChildren]
	);

	const onLoadMore = useCallback(async () => {
		if (state.state === 'loaded' && state.nextPage) {
			setState({ ...state, isLoadingMore: true });
			const result = await onLoadChildren(node, state.nextPage);

			if (Array.isArray(result)) {
				setState({
					state: 'loaded',
					open: true,
					children: result,
					isLoadingMore: false,
				});
			} else {
				setState({
					state: 'loaded',
					open: true,
					children: [...state.children, ...result.results],
					nextPage: result.nextPage,
					isLoadingMore: false,
				});
			}
		}
	}, [node, state, onLoadChildren]);

	const shouldRenderToggle =
		node.hasChildren &&
		(state.state === 'init' ||
			(state.state === 'loaded' && state.children.length > 0));
	const shouldRenderChildTreeNodes = state.state === 'loaded' && state.open;
	const shouldRenderLoadMore =
		state.state === 'loaded' && state.open && state.nextPage;

	const icon = getIcon(node, 16);

	return (
		<>
			<Box
				role="button"
				className={classes.button}
				pl={(level + 1) * TREE_INDENT - theme.other.space[1]}
				onClick={(e) => onClick(e, node)}
				data-testid={`tree-node-${node.id}`}
			>
				<Flex direction="row" align="center" justify="space-between" w="100%">
					<Flex align="center" style={{ flex: '0 0 auto' }}>
						<Box className={classes.toggleContainer}>
							{shouldRenderToggle && (
								<ActionIcon
									onClick={toggle}
									data-testid={`tree-node-${node.id}-collapsable`}
									className={classes.chevronButton}
								>
									<Icon name="chevronRight" className={classes.chevron} />
								</ActionIcon>
							)}
							{node.hasChildren && state.state === 'loading' && (
								<SmallLoadingSpinner size="xs" />
							)}
						</Box>
						<Flex align="center">
							<Flex
								mx={theme.other.space[1]}
								w={theme.other.space[5]}
								justify="center"
							>
								{typeof icon === 'string' ? (
									<IconEmoji value={icon} size="md" />
								) : (
									icon
								)}
							</Flex>
							<Text
								size="sm"
								weight="semibold"
								color="text/primary/default"
								lineClamp={1}
								style={{
									textOverflow: 'ellipsis',
								}}
							>
								{getLabel(node)}
							</Text>
						</Flex>
					</Flex>
				</Flex>
			</Box>

			{shouldRenderChildTreeNodes && (
				<>
					{state.children.map((childNode) => (
						// eslint-disable-next-line no-use-before-define
						<TreeNode
							key={childNode.id}
							node={childNode}
							onLoadChildren={onLoadChildren}
							level={level + 1}
							getIcon={getIcon}
							onClick={onClick}
							getLabel={getLabel}
						/>
					))}
				</>
			)}

			{shouldRenderLoadMore && (
				<LoadMore
					level={level + 1}
					onClick={onLoadMore}
					isLoading={state.isLoadingMore || false}
				/>
			)}
		</>
	);
}
