import {
	Box,
	createStyles,
	Group,
	Loader,
	ScrollArea,
	Skeleton,
	Stack,
} from '@mantine/core';
import { Title } from '@repo/foundations';
import { isNil, map, noop, split, truncate } from 'lodash-es';
import { useCallback, useRef } from 'react';
import { useBackgroundJob } from '../../api/hooks/backgroundJob';
import type { IBackgroundJob } from '../../api/types/models/backgroundJob';
import type { BackgroundJob } from '../../lib/models';

interface IBackgroundJob2Props {
	job: BackgroundJob;
	onCompleted?: (backgroundJob?: IBackgroundJob) => void;
}

const useStyles = createStyles((theme) => ({
	logs: {
		flexGrow: 1,
		fontSize: theme.fontSizes.xs,
		fontFamily: theme.fontFamilyMonospace,
		backgroundColor: theme.colors.gray[1],
		padding: theme.spacing.sm,
		borderRadius: theme.radius.xs,
	},
}));

function BackgroundJob2({ job, onCompleted = noop }: IBackgroundJob2Props) {
	const { classes } = useStyles();

	const viewport = useRef<HTMLDivElement>(null);

	const scrollToBottom = useCallback(() => {
		viewport.current?.scrollTo({
			top: viewport.current.scrollHeight,
			behavior: 'smooth',
		});
	}, []);

	const { data: backgroundJob } = useBackgroundJob({
		id: job.id,
		options: {
			suspense: false,
			enabled: !isNil(job.id),
			refetchInterval: (data) => {
				scrollToBottom();
				if (data?.failed || data?.completed) {
					onCompleted(data);
					return false;
				}
				return 1000;
			},
		},
	});

	const {
		title = '',
		logs = '',
		started = false,
		completed = false,
		failed = false,
	} = backgroundJob || {};

	const jobLogs = split(logs, '\n');
	const isJobRunning = started && !completed && !failed;

	return (
		<Stack spacing="xs">
			<Group>
				<Title size="sm">
					<Skeleton visible={!started}>
						{truncate(title, { length: 30 })}
					</Skeleton>
				</Title>
				{isJobRunning && <Loader size="xs" />}
			</Group>
			<ScrollArea
				type="always"
				viewportRef={viewport}
				className={classes.logs}
				offsetScrollbars
			>
				<Skeleton visible={!started}>
					{map(jobLogs, (log: string, index: number) => (
						<Box key={index} fs={'xs'}>
							{log}
						</Box>
					))}
				</Skeleton>
			</ScrollArea>
		</Stack>
	);
}

export default BackgroundJob2;
