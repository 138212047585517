import { ChromeMessageType } from '../constants';
import {
	CheckStateResponse,
	ChromeMessage,
	ChromeResponse,
	ConnectResponse,
	DisconnectResponse,
} from '../interfaces';

export const isValidChromeMessage = (
	message: any,
	expectedType?: ChromeMessageType
): message is ChromeMessage => {
	if (typeof message !== 'object' || message === null) {
		return false;
	}

	if (expectedType !== undefined && message.type !== expectedType) {
		return false;
	}

	switch (message.type) {
		case ChromeMessageType.CHECK_STATE:
			return true;
		case ChromeMessageType.CONNECT:
			return (
				typeof message.token === 'string' &&
				typeof message.anonymousId === 'string' &&
				typeof message.url === 'string'
			);
		case ChromeMessageType.DISCONNECT:
			return true;
		default:
			return false;
	}
};

export const isValidChromeResponse = (
	response: any,
	messageType: ChromeMessageType
): response is ChromeResponse => {
	if (
		typeof response !== 'object' ||
		response === null ||
		response === undefined
	) {
		return false;
	}

	if (typeof response.success !== 'boolean') {
		return false;
	}

	switch (messageType) {
		case ChromeMessageType.CHECK_STATE:
			return (
				typeof (response as CheckStateResponse).installed === 'boolean' &&
				typeof (response as CheckStateResponse).authenticated === 'boolean' &&
				typeof (response as CheckStateResponse).version === 'string'
			);
		case ChromeMessageType.CONNECT:
			return typeof (response as ConnectResponse).message === 'string';
		case ChromeMessageType.DISCONNECT:
			return typeof (response as DisconnectResponse).message === 'string';
		default:
			return false;
	}
};
