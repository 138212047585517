import type { ModalProps } from '@mantine/core';
// eslint-disable-next-line no-restricted-imports
import { Badge, createStyles, Group, Tabs } from '@mantine/core';
import type { TeamOut } from '@repo/api-codegen';
import { IconWrapper } from '@repo/common/components/IconWrapper';
import { Text } from '@repo/foundations';
import { useState } from 'react';
import { ScrollableModal } from '../ScrollableModal/ScrollableModal';
import { TeamGeneralTab } from './TeamGeneralTab';
import { TeamMembersTab } from './TeamMembersTab';
import { TeamSidebarTab } from './TeamSidebarTab';

type Props = ModalProps & { team: TeamOut };

export const useStyle = createStyles((theme) => ({
	tab: {
		padding: theme.other.space[2],
	},
	tabsList: {
		paddingLeft: theme.other.space[4],
		paddingRight: theme.other.space[4],
		borderBottom: `{${theme.other.borderWidth.sm}px solid ${theme.other.getColor('border/secondary/default')}`,
	},
}));

export function TeamSettingsModal({ opened, onClose, team }: Props) {
	const { classes } = useStyle();

	const [activeTab, setActiveTab] = useState<string | null>('general');

	return (
		<ScrollableModal
			withDoneFooter={false}
			withHeaderDivider={false}
			opened={opened}
			headerProps={{
				pb: 4,
			}}
			onClose={onClose}
			size={540}
			modalProps={{
				top: 150,
			}}
			title={
				<Group spacing={8} align="center">
					<IconWrapper>{team.icon}</IconWrapper>
					<Text weight="bold">{team.name}</Text>
					{team.is_default_team && <Badge m={0}>Default Team</Badge>}
				</Group>
			}
		>
			<Tabs
				keepMounted={false}
				defaultValue="general"
				classNames={classes}
				color="dark"
				value={activeTab}
				onTabChange={setActiveTab}
			>
				<Tabs.List>
					<Tabs.Tab value="general">
						<Text
							size="sm"
							weight="semibold"
							color={
								activeTab === 'general'
									? 'text/primary/default'
									: 'text/secondary/default'
							}
						>
							General
						</Text>
					</Tabs.Tab>
					<Tabs.Tab value="members">
						<Text
							size="sm"
							weight="semibold"
							color={
								activeTab === 'members'
									? 'text/primary/default'
									: 'text/secondary/default'
							}
						>
							Members
						</Text>
					</Tabs.Tab>
					<Tabs.Tab value="sidebar">
						<Text
							size="sm"
							weight="semibold"
							color={
								activeTab === 'sidebar'
									? 'text/primary/default'
									: 'text/secondary/default'
							}
						>
							Sidebar
						</Text>
					</Tabs.Tab>
				</Tabs.List>

				<Tabs.Panel value="general">
					<TeamGeneralTab team={team} />
				</Tabs.Panel>

				<Tabs.Panel value="members">
					<TeamMembersTab team={team} />
				</Tabs.Panel>

				<Tabs.Panel value="sidebar">
					<TeamSidebarTab team={team} />
				</Tabs.Panel>
			</Tabs>
		</ScrollableModal>
	);
}
