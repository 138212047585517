import { Filter } from '@repo/api-codegen';
import { QueryKey } from '@tanstack/react-query';
import cuid from 'cuid';
import { castDraft, produce } from 'immer';
import { last } from 'lodash-es';
import type { IBaseModel } from '../../api';
import { FetchModelList } from '../../api/factories/types';
import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';

export type ExpandedRecords<T> = {
	[id: string]: { expanded: T; nested: T[]; queryKey: QueryKey };
};

export const makeRecordsExpandable = <
	T extends IBaseModel & {
		has_child_of_same_type?: boolean;
		paramExpandedLevel?: number;
		paramExpandedParentPrefix?: string;
		paramExpanded?: boolean;
	},
>(
	records: T[],
	expandedRecords: ExpandedRecords<T>,
	setExpandedRecords: (
		arg0: (prev: ExpandedRecords<T>) => ExpandedRecords<T>
	) => void,
	nestingFilter?: string,
	defaultRequiredSearchParamsNesting?: Record<string, string | boolean>,
	fetchPaginationList?: FetchModelList<T>
) =>
	records.map((record) => {
		const queryParams = {
			page: 1,
			filters: {
				calculate_children_count: true,
				...defaultRequiredSearchParamsNesting,
				filter: {
					operator: 'and',
					operands: [
						{
							operands: [],
							field: nestingFilter,
							operator: 'in',
							value: [last(record.id?.split('.')) ?? ''],
						},
					],
				} as Filter,
			},
		};
		const queryKey = resourceCatalogQueryKeyFactory.list(
			queryParams.page,
			queryParams.filters
		);

		const prefix = record.paramExpandedParentPrefix
			? `${record.paramExpandedParentPrefix}.${record.id}`
			: record.id;
		return {
			...record,
			paramExpanded: Object.keys(expandedRecords).some((expandedRecord) =>
				expandedRecord.includes(prefix)
			),
			onExpandClick: record.has_child_of_same_type
				? async () => {
						const result = await fetchPaginationList?.(queryParams);

						setExpandedRecords((prev) =>
							produce(prev, (draft) => {
								if (draft[prefix]) {
									// Delete all that start with the given prefix.
									Object.keys(draft).forEach((key) => {
										if (key.startsWith(prefix)) {
											delete draft[key];
										}
									});
								} else {
									draft[prefix] = castDraft({
										// We need the queryKey to be able to refetch the nested records from cache upon mutation.
										queryKey,
										expanded: record,
										nested:
											result?.results?.map((r) => ({
												// The `root` level of nesting is 0.
												...r,
												paramExpandedLevel:
													(record?.paramExpandedLevel ?? 0) + 1,
												paramExpandedParentPrefix: prefix,
												paramExpandUid: cuid(),
												// This `id` is used internally by `TableV2`.
												_id: `${prefix}.${r.id}`,
											})) || [],
									});
								}
							})
						);
					}
				: undefined,
		};
	}) as T[];
