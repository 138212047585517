import { Group } from '@mantine/core';
import { noop } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { FilterValue } from '../../../../pages/SearchPage/FilterCarousel/FilterCarousel.constants';
import FilterMenu from '../../../FilterMenu';
import { ImpactAnalysisSearch } from './ImpactAnalysisSearch';
import { impactAnalysisStore } from './store';

export const ImpactAnalysisFilters = observer(() => {
	const handleDangerClick = (
		filterValue:
			| FilterValue.NATIVE_TYPE
			| FilterValue.INTEGRATION_NAME
			| 'direction'
	) => {
		impactAnalysisStore.clearSelectedOptions(filterValue);
	};

	return (
		<Group position="apart" noWrap>
			{!impactAnalysisStore.showSearch && (
				<Group spacing="xs" noWrap>
					<FilterMenu
						filterMenuItem={impactAnalysisStore.filterMenuItems.direction}
						setIsInclude={noop}
						toggleSelectedOptions={impactAnalysisStore.toggleSelectedOptions}
						onDangerClick={() => handleDangerClick('direction')}
						showSearch={false}
						isRadio
					/>
					<FilterMenu
						filterMenuItem={impactAnalysisStore.filterMenuItems.native_type}
						setIsInclude={impactAnalysisStore.setIsInclude}
						toggleSelectedOptions={impactAnalysisStore.toggleSelectedOptions}
						onDangerClick={() => handleDangerClick(FilterValue.NATIVE_TYPE)}
						showInclusionDropdown
					/>
					<FilterMenu
						filterMenuItem={
							impactAnalysisStore.filterMenuItems.integration_name
						}
						setIsInclude={impactAnalysisStore.setIsInclude}
						toggleSelectedOptions={impactAnalysisStore.toggleSelectedOptions}
						onDangerClick={() =>
							handleDangerClick(FilterValue.INTEGRATION_NAME)
						}
						showInclusionDropdown
					/>
				</Group>
			)}
			<ImpactAnalysisSearch
				searchTerm={impactAnalysisStore.searchTerm}
				setSearchTerm={impactAnalysisStore.setSearchTerm}
				hidden={!impactAnalysisStore.showSearch}
			/>
		</Group>
	);
});
