import type { Filter } from '@repo/api-codegen';
import {
	DEFAULT_FILTER_OPTIONS,
	FILTER_OPERATOR_TO_NEGATED_OPERATOR,
	FILTER_OPTIONS_DIVIDER,
} from '@repo/common/components/Filter/constants';
import type {
	FilterDropdownConfigList,
	FilterOption,
	FilterValue,
	FilterValueType,
	TopLevelOperatorType,
} from '@repo/common/components/Filter/types';
import {
	FilterOperator,
	FilterOptionType,
} from '@repo/common/components/Filter/types';
import type { IFilterSelection } from '@repo/common/interfaces/filterSelection';
import { isNil, uniq, uniqBy } from 'lodash-es';
import { FilterValue as LegacyFilterValue } from '../../pages/SearchPage/FilterCarousel/FilterCarousel.constants';
import { captureError } from '../../web-tracing';
import { FILTER_OPTIONS_CONFIG } from './constants';

export function getValueAsArray(value: FilterValue | null) {
	if (!value || isNil(value.value)) {
		return [];
	}

	return Array.isArray(value.value) ? value.value : [value.value];
}

// TODO: this is a temporary list of mappings to keep the searchParams
// backwards compatible with ag-grid (table v1). Once the table v1 is removed
// and we support these filters natively this can be removed as well.
const COMPATIBILITY_MAPPINGS: Record<string, string> = {
	integration: 'integration_id',
	parent: 'parent_id',
};

export function getSearchParamFilters(
	searchParams: URLSearchParams,
	filterOptions = DEFAULT_FILTER_OPTIONS
) {
	// These use the legacy format of filters, which is `field: value`.
	const entries = JSON.parse(searchParams.get('filters') ?? '{}');

	const output = Object.entries(entries)
		.filter(([key]) =>
			filterOptions.find(
				(option) =>
					option === key.toLowerCase() ||
					option === COMPATIBILITY_MAPPINGS[key.toLowerCase()]
			)
		)
		.map(([key, value]) => {
			const filterType = filterOptions.find(
				(option) =>
					option === key.toLowerCase() ||
					option === COMPATIBILITY_MAPPINGS[key.toLowerCase()]
			) as FilterOptionType;

			const filterValue: FilterValue = {
				operator:
					FILTER_OPTIONS_CONFIG[filterType].filterDropdownConfig
						.defaultOperator,
				filterType,
				value: value as FilterValueType,
			};

			return filterValue;
		});

	return output;
}

export function getFilterValueFromApiCatalogFilter(
	filter: Filter,
	isNegate: boolean = false
): FilterValue[] {
	const allFilterOptions = Object.values(FILTER_OPTIONS_CONFIG);

	const result: FilterValue[] = [];

	const filterOperands = filter.operands ?? [];

	if (filter.operator === 'and' || filter.operator === 'or') {
		if (filterOperands.length === 0) {
			return [];
		}

		const parsedOperands = filterOperands
			.map((operand) => getFilterValueFromApiCatalogFilter(operand, isNegate))
			.flat();

		const allOperandsForTheSameField =
			uniqBy(parsedOperands, (o) => FILTER_OPTIONS_CONFIG[o.filterType].field)
				.length === 1;

		if (!allOperandsForTheSameField || parsedOperands.length === 0) {
			return parsedOperands;
		}

		// override for IsBetween filter
		if (
			parsedOperands.length === 2 &&
			parsedOperands.every(
				(o) =>
					o.operator === FilterOperator.IsOnOrAfter ||
					o.operator === FilterOperator.IsOnOrBefore
			)
		) {
			return [
				{
					operator: FilterOperator.IsBetween,
					filterType: parsedOperands[0].filterType,
					value: [
						parsedOperands[0].value as FilterValueType,
						parsedOperands[1].value as FilterValueType,
					],
				},
			];
		}

		const operators = uniq(parsedOperands.map((o) => o.operator));
		let isNotSetApplied = false;

		if (operators.includes(FilterOperator.isNotSet)) {
			// simple isNotSet filter
			if (operators.length === 1) {
				return parsedOperands;
			}

			// isNotSet filter combined with other filters
			if (operators.length > 1) {
				isNotSetApplied = true;
			}
		}

		const operandsWithoutIsNotSet = parsedOperands.filter(
			(o) => o.operator !== FilterOperator.isNotSet
		);

		// combine all operands for the same field into a single filter
		return [
			{
				operator: operandsWithoutIsNotSet[0].operator,
				filterType: operandsWithoutIsNotSet[0].filterType,
				value: operandsWithoutIsNotSet
					.map((o) => o.value as FilterValueType | FilterValueType[])
					.flat(),
				isNotSetApplied,
			},
		];
	}

	if (filter.operator === 'not') {
		if (filterOperands.length === 0) {
			return [];
		}

		const parsedOperands = filterOperands
			.map((operand) => getFilterValueFromApiCatalogFilter(operand, true))
			.flat();

		return parsedOperands;
	}

	const option = allFilterOptions.find((o) => o.field === filter.field);
	const filterValue = filter.value ?? null;

	if (!option) {
		return [];
	}

	if (filter.operator === 'exact' || filter.operator === 'in') {
		return [
			{
				operator: isNegate ? FilterOperator.IsNot : FilterOperator.Is,
				filterType: option.type,
				value: filterValue,
			},
		];
	}

	if (filter.operator === 'contains') {
		return [
			{
				operator: isNegate
					? FilterOperator.DoesNotContain
					: FilterOperator.Contains,
				filterType: option.type,
				value: filterValue,
			},
		];
	}

	if (filter.operator === 'is_set') {
		return [
			{
				operator: isNegate ? FilterOperator.isNotSet : FilterOperator.isSet,
				filterType: option.type,
				value: filterValue,
			},
		];
	}

	if (filter.operator === 'gte') {
		return [
			{
				operator: FilterOperator.IsOnOrAfter,
				filterType: option.type,
				value: filterValue,
			},
		];
	}

	if (filter.operator === 'lte') {
		return [
			{
				operator: FilterOperator.IsOnOrBefore,
				filterType: option.type,
				value: filterValue,
			},
		];
	}

	return result;
}

export async function getApiCatalogFilterFromFilterValues(
	filterValues: FilterValue[],
	topLevelOperator: TopLevelOperatorType
): Promise<Filter | undefined> {
	if (filterValues.length === 0) {
		return undefined;
	}

	const operands: Filter[] = [];

	for (const value of filterValues) {
		const values = Array.isArray(value.value) ? value.value : [value.value];
		const filterOption = FILTER_OPTIONS_CONFIG[value.filterType];

		if (!filterOption) {
			// just being defensive - if the filterType passed in is invalid (no longer supported / removed), we should ignore this entry and log an error
			captureError(new Error(`Invalid filterType: ${value.filterType}`));
			// eslint-disable-next-line no-continue
			continue;
		}

		if (
			value.operator === FilterOperator.Is ||
			value.operator === FilterOperator.IsNot
		) {
			const isOperands: Filter[] = // eslint-disable-next-line no-await-in-loop
				(
					await Promise.all(
						values
							.filter((v) => !isNil(v))
							.map(
								(v) =>
									filterOption.filterDropdownConfig.convertToCatalogFilter?.(
										v
									) ?? {
										operands: [],
										field: filterOption.field,
										operator: 'exact',
										value: v,
									}
							)
					)
				).filter(Boolean) as Filter[];

			if (value.isNotSetApplied) {
				isOperands.push({
					operator: 'not',
					operands: [
						{
							operands: [],
							field: filterOption.field,
							operator: 'is_set',
							value: null,
						},
					],
				});
			}

			if (value.isSetApplied) {
				isOperands.push({
					operands: [],
					field: filterOption.field,
					operator: 'is_set',
					value: null,
				});
			}

			const isFilters: Filter = {
				operator: 'or',
				operands: isOperands,
			};

			if (value.operator === FilterOperator.IsNot) {
				operands.push({
					operator: 'not',
					operands: [isFilters],
				});
			} else {
				operands.push(isFilters);
			}
			// eslint-disable-next-line no-continue
			continue;
		}

		if (
			value.operator === FilterOperator.Contains ||
			value.operator === FilterOperator.DoesNotContain
		) {
			const containsOperands: Filter[] =
				// eslint-disable-next-line no-await-in-loop
				(
					await Promise.all(
						values.filter(Boolean).map(
							(v) =>
								filterOption.filterDropdownConfig.convertToCatalogFilter?.(
									v
								) ?? {
									operands: [],
									field: filterOption.field,
									operator: 'contains',
									value: v,
								}
						)
					)
				).filter(Boolean) as Filter[];

			if (value.isNotSetApplied) {
				containsOperands.push({
					operator: 'not',
					operands: [
						{
							operands: [],
							field: filterOption.field,
							operator: 'is_set',
							value: null,
						},
					],
				});
			}

			if (value.isSetApplied) {
				containsOperands.push({
					operands: [],
					field: filterOption.field,
					operator: 'is_set',
					value: null,
				});
			}

			const containsFilters: Filter = {
				operator: 'or',
				operands: containsOperands,
			};

			if (value.operator === FilterOperator.DoesNotContain) {
				operands.push({
					operator: 'not',
					operands: [containsFilters],
				});
			} else {
				operands.push(containsFilters);
			}
		}

		if (
			value.operator === FilterOperator.isSet ||
			value.operator === FilterOperator.isNotSet
		) {
			const isSetFilters: Filter = {
				operator: 'or',
				operands: [
					{
						operands: [],
						field: filterOption.field,
						operator: 'is_set',
						value: null,
					},
				],
			};
			if (value.operator === FilterOperator.isNotSet) {
				operands.push({
					operator: 'not',
					operands: [isSetFilters],
				});
			} else {
				operands.push(isSetFilters);
			}
		}

		if (value.operator === FilterOperator.IsOnOrAfter && values.length === 1) {
			operands.push({
				operator: 'and',
				operands: [
					{
						operands: [],
						field: filterOption.field,
						operator: 'gte',
						value: values[0],
					},
				],
			});
		}

		if (value.operator === FilterOperator.IsOnOrBefore && values.length === 1) {
			operands.push({
				operator: 'and',
				operands: [
					{
						operands: [],
						field: filterOption.field,
						operator: 'lte',
						value: values[0],
					},
				],
			});
		}

		if (value.operator === FilterOperator.IsBetween && values.length === 2) {
			operands.push({
				operator: 'and',
				operands: [
					{
						operands: [],
						field: filterOption.field,
						operator: 'gte',
						value: values[0],
					},
					{
						operands: [],
						field: filterOption.field,
						operator: 'lte',
						value: values[1],
					},
				],
			});
		}
	}

	return {
		operator: topLevelOperator,
		operands,
	};
}

const LEGACY_FILTERS_TO_NEW_FILTERS: Record<
	LegacyFilterValue,
	FilterOptionType
> = {
	[LegacyFilterValue.NATIVE_TYPE]: FilterOptionType.NATIVE_TYPE,
	[LegacyFilterValue.INTEGRATION_NAME]: FilterOptionType.INTEGRATION,
	[LegacyFilterValue.DATABASE]: FilterOptionType.DATABASE,
	[LegacyFilterValue.SCHEMA]: FilterOptionType.SCHEMA,
	[LegacyFilterValue.TAGS]: FilterOptionType.TAGS,
	[LegacyFilterValue.PUBLISHED]: FilterOptionType.PUBLISHED,
	[LegacyFilterValue.VERIFIED]: FilterOptionType.VERIFICATION,
	[LegacyFilterValue.PII]: FilterOptionType.PII,
	[LegacyFilterValue.COLLECTIONS]: FilterOptionType.COLLECTIONS,
	[LegacyFilterValue.OWNERS]: FilterOptionType.OWNERS,
	[LegacyFilterValue.SOURCES]: FilterOptionType.SOURCES,
	[LegacyFilterValue.PARENT_ID]: FilterOptionType.PARENT_ID,
	[LegacyFilterValue.RELATED]: FilterOptionType.RELATED,
	[LegacyFilterValue.SLACK_CHANNELS]: FilterOptionType.SLACK_CHANNELS,
	[LegacyFilterValue.QUESTION_STATUS]: FilterOptionType.QUESTION_STATUS,
	[LegacyFilterValue.QUESTION_PRIORITY]: FilterOptionType.QUESTION_PRIORITY,
};

async function getFilterItems(filterOptionType: FilterOptionType) {
	const filterDropdownConfig = FILTER_OPTIONS_CONFIG[filterOptionType]
		.filterDropdownConfig as FilterDropdownConfigList;

	if (typeof filterDropdownConfig.getItems !== 'function') {
		return filterDropdownConfig.getItems;
	}

	return filterDropdownConfig.getItems();
}

export async function legacyFilterToFilterValue(
	filters: Partial<Record<LegacyFilterValue, IFilterSelection>>
): Promise<FilterValue[]> {
	// integrations and schemas have changed between legacy and filters v3
	// for Filters v3 we use the entity ID, but legacy filters use the entity name
	// this method does the correct translation to store the backwards compatible filters in views
	const integrations = await getFilterItems(FilterOptionType.INTEGRATION);
	const schemas = await getFilterItems(FilterOptionType.SCHEMA);

	const newFilters: FilterValue[] = [];

	Object.entries(filters).forEach(([key, value]) => {
		if (value.selectedOptionValues.length === 0 && !value.isNotSetApplied) {
			return;
		}

		const filterType =
			LEGACY_FILTERS_TO_NEW_FILTERS?.[key as LegacyFilterValue] ?? key;

		const { defaultOperator } =
			FILTER_OPTIONS_CONFIG[filterType].filterDropdownConfig;

		let filterValue: FilterValue['value'] = null;

		if (filterType === FilterOptionType.INTEGRATION) {
			filterValue = value.selectedOptionValues
				.map((integration) => {
					const integrationItem = integrations.find(
						(i) => i.label?.toLowerCase() === integration
					);
					if (integrationItem) {
						return integrationItem?.value;
					}

					return null;
				})
				.filter(Boolean) as FilterValue['value'];
		} else if (filterType === FilterOptionType.SCHEMA) {
			filterValue = value.selectedOptionValues
				.map((schema) => {
					const schemaItem = schemas.find(
						(i) => i.label?.toLowerCase() === schema
					);
					if (schemaItem) {
						return schemaItem?.value;
					}

					return null;
				})
				.filter(Boolean) as FilterValue['value'];
		} else {
			filterValue = value.selectedOptionValues;
		}

		let operator: FilterOperator = FilterOperator.Is;

		if (value.isNotSetApplied && value.selectedOptionValues.length === 0) {
			operator = FilterOperator.isNotSet;
			filterValue = null;
		} else if (value.isSetApplied && value.selectedOptionValues.length === 0) {
			operator = FilterOperator.isSet;
			filterValue = null;
		} else if (value.isInclude) {
			operator = defaultOperator;
		} else {
			operator =
				FILTER_OPERATOR_TO_NEGATED_OPERATOR?.[defaultOperator] ??
				FilterOperator.IsNot;
		}

		newFilters.push({
			filterType,
			operator,
			value: filterValue,
			isNotSetApplied: value.isNotSetApplied,
			isSetApplied: value.isSetApplied,
		});
	});

	return newFilters;
}

export async function filterValueToLegacyFilter(
	filters: FilterValue[]
): Promise<Partial<Record<LegacyFilterValue, IFilterSelection>>> {
	// integrations and schemas have changed between legacy and filters v3
	// for Filters v3 we use the entity ID, but legacy filters use the entity name
	// this method does the correct translation to store the backwards compatible filters in views
	const integrations = await getFilterItems(FilterOptionType.INTEGRATION);
	const schemas = await getFilterItems(FilterOptionType.SCHEMA);

	return filters.reduce(
		(acc, filter) => {
			const legacyFilterValuePair = Object.entries(
				LEGACY_FILTERS_TO_NEW_FILTERS
			).find(([, value]) => value === filter.filterType);

			const legacyFilterValue: LegacyFilterValue =
				(legacyFilterValuePair?.[0] ?? filter.filterType) as LegacyFilterValue;

			const filterValues = getValueAsArray(filter);
			const selectedOptionValues: (string | boolean)[] = [];
			if (filter.filterType === FilterOptionType.INTEGRATION) {
				filterValues.forEach((value) => {
					const integration = integrations.find((i) => i.value === value);
					if (integration) {
						selectedOptionValues.push(integration?.label?.toLowerCase());
					}
				});
			} else if (filter.filterType === FilterOptionType.SCHEMA) {
				filterValues.forEach((value) => {
					const schema = schemas.find((i) => i.value === value);
					if (schema) {
						selectedOptionValues.push(schema?.label?.toLowerCase());
					}
				});
			} else if (filter.operator !== FilterOperator.isNotSet) {
				selectedOptionValues.push(...(filterValues as (string | boolean)[]));
			}

			return {
				...acc,
				[legacyFilterValue]: {
					isInclude:
						filter.operator !== FilterOperator.IsNot &&
						filter.operator !== FilterOperator.DoesNotContain &&
						filter.operator !== FilterOperator.isNotSet,
					selectedOptionValues,
					isNotSetApplied: filter.isNotSetApplied,
					isSetApplied: filter.isSetApplied,
				},
			};
		},
		{} as Partial<Record<LegacyFilterValue, IFilterSelection>>
	);
}

export function filterExcessDividers(
	options: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[]
): (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[] {
	return options.reduce(
		(acc, option, index) => {
			// Trim separators from start / end
			if (option === FILTER_OPTIONS_DIVIDER && index === 0) return acc;
			if (option === FILTER_OPTIONS_DIVIDER && index === options.length - 1)
				return acc;

			// Trim double separators looking behind
			const prev = options[index - 1];
			if (
				prev === FILTER_OPTIONS_DIVIDER &&
				option === FILTER_OPTIONS_DIVIDER
			) {
				return acc;
			}

			// Otherwise, continue
			return [...acc, option];
		},
		[] as (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[]
	);
}

export function parseFilterValuesFromLocalStorage(
	preferencesLocalStorageKey: string
): Array<FilterValue> {
	try {
		const preferences: FilterValue[] = JSON.parse(
			localStorage.getItem(preferencesLocalStorageKey) ?? '[]'
		);

		return preferences
			?.map((preference) => {
				// we used to store the entire FilterOption object into local storage. We now changed FilterValue to only have a reference to the FilterOption.type instead (as FilterValue.filterType).
				const legacyFilterType = preference as unknown as {
					option: { type: FilterOptionType };
				};

				const filterValue = {
					...preference,
					filterType: preference.filterType ?? legacyFilterType.option.type,
				};

				if (!Object.values(FilterOptionType).includes(filterValue.filterType)) {
					// in case the filterType saved does not exist anymore (changed its definition / removed), we should just ignore this entry and log an error
					captureError(
						new Error(
							`Invalid filterType in preferencesLocalStorage: ${filterValue.filterType}`
						)
					);
					return null;
				}

				return filterValue;
			})
			.filter(Boolean) as Array<FilterValue>;
	} catch (e) {
		captureError(e);
	}

	return [];
}
