import { ISecodaEntity } from '../../types';
import { getDefaultCreateFn } from '../base/useCreateBaseModel';
import { getDefaultUpdateFn } from '../base/useUpdateBaseModel';
import { SECODA_ENTITIES_NAMESPACE } from './constants';

export * from './constants';
export { fetchSecodaEntities } from './fetchSecodaEntities';
export { default as useBulkDeleteSecodaEntities } from './useBulkDeleteSecodaEntities';
export { default as useBulkUpdateSecodaEntities } from './useBulkUpdateSecodaEntities';
export { default as useCreateSecodaEntity } from './useCreateSecodaEntity';
export { default as useDeleteSecodaEntity } from './useDeleteSecodaEntity';
export { default as usePropagateSecodaEntityMetadata } from './usePropagateSecodaEntityMetadata';
export { default as useSecodaEntity } from './useSecodaEntity';
export { default as useSecodaEntityInfiniteList } from './useSecodaEntityInfiniteList';
export { default as useSecodaEntityList } from './useSecodaEntityList';
export { default as useUpdateSecodaEntity } from './useUpdateSecodaEntity';

export const createSecodaEntity = getDefaultCreateFn<ISecodaEntity>(
	SECODA_ENTITIES_NAMESPACE
);
export const updateSecodaEntity = getDefaultUpdateFn<ISecodaEntity>(
	SECODA_ENTITIES_NAMESPACE
);
