import { Box, createStyles } from '@mantine/core';
import { Filter } from '@repo/api-codegen';
import { useMemo } from 'react';
import { useSchemaList } from '../../../api';
import { useDatabaseList } from '../../../api/hooks/database';
import { useCatalogList } from '../../../api/hooks/resourceCatalog';
import { TableV2 } from '../../../components/TableV2';
import { useDataQualityScoreColumns } from '../../../components/TableV2/SecodaEntity.hooks';
import { EntityType } from '../../../lib/types';
import {
	useActions,
	useColumns,
} from '../../TeamCatalogPage/TeamCatalogPage.hooks';

interface ITablesTabProps {
	databaseId: string;
	integrationId: string;
}

const useStyles = createStyles({
	tableWrapper: {
		height: '100%',
	},
});

function TablesTab({ databaseId, integrationId }: ITablesTabProps) {
	const { classes } = useStyles();

	const defaultColumns = useColumns();
	const dataQualityColumns = useDataQualityScoreColumns({
		catalogType: EntityType.table,
	});

	const columns = useMemo(
		() => [...defaultColumns, ...dataQualityColumns],
		[dataQualityColumns, defaultColumns]
	);

	const { data: databaseList, isLoading: databaseListLoading } =
		useDatabaseList({
			filters: {
				integration_id: integrationId,
			},
		});

	const { data: schemaList, isLoading: schemaListLoading } = useSchemaList({
		filters: {
			parent_id: databaseId,
		},
	});

	const actions = useActions();

	if (databaseListLoading || schemaListLoading) {
		return null;
	}

	const operands: Filter[] = [];

	// If this is the only database on the integration,
	// we should filter by `integration_id` as it will be faster.
	if (databaseList?.count === 1) {
		operands.push({
			operands: [],
			field: 'integration_id',
			operator: 'exact',
			value: integrationId,
		});
		// If there are multiple databases on the integration, we should filter by
		// the schemas. This is slower and a less common case.
	} else {
		operands.push({
			operands: [],
			field: 'parent_id',
			operator: 'in',
			value: schemaList?.results.map((schema) => schema.id),
		});
	}

	return (
		<Box className={classes.tableWrapper}>
			<TableV2
				pluralTypeString="tables"
				withSearch
				withInteractiveHeader
				withCsvExport
				withCheckbox
				withActions={actions}
				columns={columns}
				usePaginationList={useCatalogList}
				columnVisibility={{
					catalogType: EntityType.table,
					catalogServerType: EntityType.table,
				}}
				defaultRequiredSearchParams={{
					entity_type: EntityType.table,
				}}
				defaultRequiredCatalogFilters={{
					operands,
				}}
			/>
		</Box>
	);
}

export default TablesTab;
