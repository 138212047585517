import {
	Divider,
	Global,
	Skeleton,
	Stack,
	useMantineTheme,
} from '@mantine/core';
import { Text } from '@repo/foundations';
import moment from 'moment';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import {
	useDeleteQuestion,
	useQuestion,
	useQuestionReplyList,
	useUpdateQuestion,
} from '../../../api';
import { useQuestionPermissions } from '../../../api/hooks/question/permissions';
import { openDeleteConfirmModal } from '../../../components/ModalManager';
import { QuestionReply } from './Reply';
import { QuestionNewReply } from './ReplyNew';
import { ReplyWrapper } from './ReplyWrapper';

export interface IIQuestionReplyListProps {
	id: string;
}

export function QuestionLayout({ id }: IIQuestionReplyListProps) {
	const theme = useMantineTheme();
	const navigate = useNavigate();

	const { data: questionData } = useQuestion({ id });
	const queryParams = { filters: { question_id: id } };

	const { data: repliesData, isLoading } = useQuestionReplyList({
		...queryParams,
		options: { suspense: false },
	});

	const question = questionData;
	const replies = repliesData?.results;
	const acceptedReply = replies?.find((f) => f.accepted_answer);
	const otherReplies = replies?.filter((f) => !f.accepted_answer) || [];

	const { canReply } = useQuestionPermissions(id, 'question');

	const { mutateAsync: updateQuestion } = useUpdateQuestion({});

	const { mutateAsync: deleteQuestion } = useDeleteQuestion({
		options: {
			onSuccess: () => {
				navigate('/questions/');
			},
		},
	});

	const handleDelete = useCallback(() => {
		if (!question) return;
		openDeleteConfirmModal({
			title: 'Please confirm deletion',
			description: 'Are you sure you want to delete this question?',
			onConfirm: () => deleteQuestion({ id: question.id }),
		});
	}, [deleteQuestion, question]);

	if (isLoading || !replies || !question) {
		return <Skeleton />;
	}

	const dividerText = (count: number, hasAcceptedAnswer = false) => {
		if (count === 0) {
			return 'No replies yet';
		}
		if (count === 1) {
			return `1 ${hasAcceptedAnswer ? 'other reply' : 'reply'}`;
		}
		return `${count} ${hasAcceptedAnswer ? 'other replies' : 'replies'}`;
	};

	return (
		<>
			<Global
				styles={() => ({
					'.heading-actions, .block-menu-trigger': {
						display: 'none !important',
					},
				})}
			/>
			<ReplyWrapper
				entity={question}
				accepted={false}
				handleDelete={handleDelete}
				type="question"
				handleUpdate={(values) =>
					updateQuestion({ data: { id: question.id, ...values } })
				}
			/>
			{acceptedReply && (
				<QuestionReply id={acceptedReply.id} key={acceptedReply.id} accepted />
			)}
			{otherReplies?.length > 0 && (
				<Stack my={theme.spacing.lg} sx={{ gap: theme.spacing.sm }}>
					<Divider />
					<Text size="xs" color="text/secondary/default">
						{dividerText(otherReplies.length, !!acceptedReply)}
					</Text>
				</Stack>
			)}
			{otherReplies
				?.sort((a, b) => moment(a.created_at).diff(moment(b.created_at)))
				.map((questionReply) => (
					<QuestionReply
						id={questionReply.id}
						key={questionReply.id}
						accepted={false}
					/>
				))}
			{canReply && <QuestionNewReply id={id} />}
		</>
	);
}
