import { Group, Stack } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Icon, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { find, includes, isNil, startCase } from 'lodash-es';
import { useMemo } from 'react';
import type { IUser } from '../../../../api';
import { useIntegrationList, useUserList } from '../../../../api';
import type { INotification } from '../../../../api/types/models/notification';
import { SecodaEntityIcon } from '../../../../components/SecodaEntity';
import { UserAvatar } from '../../../../components/UserAvatar';
import { buildResourceUrl } from '../../../../utils/navigationUtils';
import { useStyles } from './styles';

interface IResourceOwnerAssignedContentProps {
	selected: INotification;
}

function ResourceOwnerAssignedContent({
	selected,
}: IResourceOwnerAssignedContentProps) {
	const { classes, cx } = useStyles();

	const navigate = useNavigate();

	const { data: users } = useUserList({});
	const { data: integrations } = useIntegrationList({});

	const user: IUser | undefined = useMemo(() => {
		if (
			includes(
				['dictionary_term', 'metric', 'document', 'question'],
				selected.entity?.entity_type
			)
		) {
			return find(users?.results, {
				full_name: selected.content,
			});
		}
		return find(users?.results, { email: selected.content });
	}, [selected.content, selected.entity?.entity_type, users?.results]);

	const integration = useMemo(
		() =>
			selected.entity
				? find(integrations?.results, {
						id: selected.entity?.integration,
					})
				: null,
		[integrations?.results, selected.entity]
	);

	const handleNavigateToUser = () => {
		if (!isNil(user)) {
			navigate(`/user/${user.id}`);
		}
	};

	const handleNavigateToResource = () => {
		navigate(
			buildResourceUrl(
				selected.entity || {
					id: selected.resource_id,
					entity_type: selected.resource_type,
				}
			)
		);
	};

	return (
		<Stack spacing="lg">
			{!isNil(user) && (
				<Group spacing="xs" align="flex-start">
					<Group className={classes.leftCol} spacing="xs">
						<Icon name="userPlus" />
						Owner
					</Group>
					<Group
						className={cx(classes.rightCol, classes.cursorPointer)}
						spacing="xs"
						onClick={handleNavigateToUser}
					>
						<UserAvatar user={user} size="lg" />
						<Stack spacing={0}>
							<Text className={classes.label}>{user.display_name}</Text>
							<Text className={classes.subLabel}>{`${startCase(user.role)} • ${
								user.email
							}`}</Text>
						</Stack>
					</Group>
				</Group>
			)}
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="file" />
					Resource
				</Group>
				<Group
					className={cx(classes.rightCol, classes.cursorPointer)}
					spacing="xs"
					onClick={handleNavigateToResource}
				>
					<SecodaEntityIcon entity={selected.entity} />
					<Stack spacing={0}>
						<Text className={classes.label}>
							{selected.entity?.title || selected.resource_title}
						</Text>
						{!includes(
							['dictionary_term', 'document', 'question'],
							selected.entity?.entity_type
						) &&
							!isNil(integration) && (
								<Text className={classes.subLabel}>{`${startCase(
									integration.name
								)} / ... / ${
									selected.entity?.title || selected.resource_title
								}`}</Text>
							)}
					</Stack>
				</Group>
			</Group>
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="layoutGrid" />
					Resource type
				</Group>
				<Group className={classes.rightCol} spacing="xs" noWrap>
					<Stack spacing={0}>
						<Text className={classes.soleLabel}>
							{startCase(
								selected.entity?.native_type ||
									selected.entity?.entity_type ||
									selected.resource_type
							)}
						</Text>
					</Stack>
				</Group>
			</Group>
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="clock" />
					Updated time
				</Group>
				<Group className={classes.rightCol} spacing="xs" noWrap>
					<Stack spacing={0}>
						<Text className={classes.soleLabel}>
							{dayjs(selected.created_at).format('MMMM D, YYYY h:mm A')}
						</Text>
					</Stack>
				</Group>
			</Group>
		</Stack>
	);
}

export default ResourceOwnerAssignedContent;
