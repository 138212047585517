import { Center, createStyles, Loader, MantineTheme } from '@mantine/core';

import { showNotification } from '@mantine/notifications';
import { IconButton, ListBox } from '@repo/foundations';
import { IconButtonProps } from '@repo/foundations/components/Buttons/IconButton';
import { size } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import {
	useSearch,
	type ISecodaEntity,
	type SearchResult,
} from '../../../../api';
import {
	invalidateResourceRelationList,
	useCreateResourceRelation,
} from '../../../../api/hooks/relations';
import type { SecodaEntity } from '../../../../lib/models';
import { EntityType } from '../../../../lib/types';
import { ButtonDetails, EmptyState } from '../../../EmptyState';
import RelatedResourceItem from './RelatedResourceItem';

interface RelatedResourceDropdownProps extends Partial<IconButtonProps> {
	entity: SecodaEntity | ISecodaEntity;
	relatedEntityIds: string[];
}

const useStyles = createStyles((theme: MantineTheme) => ({
	emptyState: {
		padding: theme.spacing['2xs'],
	},
}));

export function RelatedResourceCreateMenu({
	entity,
	relatedEntityIds,
	...iconButtonProps
}: RelatedResourceDropdownProps) {
	const { mutateAsync: createResourceRelation } = useCreateResourceRelation({
		options: {
			onSuccess: () => {
				showNotification({
					message: 'Related resource added.',
					color: 'green',
				});
				invalidateResourceRelationList(entity.id);
			},
			onError: () => {
				showNotification({
					message: 'Failed to add a related resource',
					color: 'red',
				});
			},
		},
	});

	const handleCreateResourceRelation = useCallback(
		(selected: SearchResult) => {
			createResourceRelation({
				data: {
					from_entity: entity.id,
					to_entity: selected.id,
				},
			});
		},
		[createResourceRelation, entity.id]
	);

	const [opened, setOpened] = useState<boolean>(false);

	const [searchTerm, setSearchTerm] = useState('');
	const { classes, theme } = useStyles();

	const { isFetching, data } = useSearch({
		searchTerm: searchTerm,
		filterV2: {
			operator: 'and',
			operands: [
				{
					field: 'entity_type',
					operator: 'in',
					value: Object.values(EntityType).filter(
						(el) =>
							![
								EntityType.user,
								EntityType.user_group,
								EntityType.question_reply,
								EntityType.event_property,
								EntityType.collection,
							].includes(el)
					),
					operands: [],
				},
				{
					operator: 'not',
					operands: [
						{
							operands: [],
							operator: 'in',
							field: 'id',
							value: [...relatedEntityIds, entity.id],
						},
					],
				},
			],
		},
		options: {
			select: (data) => data.results,
		},
	});

	const emptyButtons: ButtonDetails[] = useMemo(
		() => [
			{
				name: 'Clear search',
				action: () => setSearchTerm(''),
				isPrimary: false,
				size: 'sm',
			},
		],
		[setSearchTerm]
	);

	const height =
		size(data) > 6 ? theme.other.space[60] : size(data) * theme.other.space[12];

	const handleOpenChange = useCallback(
		(newOpened: boolean) => {
			setSearchTerm('');
			setOpened(newOpened);
		},
		[setSearchTerm]
	);

	return (
		<ListBox
			opened={opened}
			onOpenChange={handleOpenChange}
			closeOnEscape
			placement="bottom-end"
		>
			<ListBox.Target>
				<IconButton
					iconName="plus"
					variant="tertiary"
					tooltip="Add related resource"
					{...iconButtonProps}
				/>
			</ListBox.Target>
			<ListBox.ItemsDropdown
				usePortal
				search={{
					onChange: setSearchTerm,
					value: searchTerm,
					placeholder: 'Filter by...',
				}}
				items={data}
				renderItem={(item, getProps) => (
					<RelatedResourceItem
						item={item}
						onClick={handleCreateResourceRelation}
						isViewerUser={false}
						key={item.id}
						getProps={getProps}
					/>
				)}
			>
				{isFetching ? (
					<Center h={height} w={theme.other.space[60]}>
						<Loader size="sm" />
					</Center>
				) : (
					size(data) === 0 && (
						<EmptyState
							iconName="search"
							title="No results found"
							description="No resources or invalid search"
							buttons={emptyButtons}
							includeGoBack={false}
							size="sm"
							width={theme.other.space[60]}
							className={classes.emptyState}
						/>
					)
				)}
			</ListBox.ItemsDropdown>
		</ListBox>
	);
}
