/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createStyles } from '@mantine/core';
import { useCallback, useEffect } from 'react';
import {
	queryClient,
	questionsQueryKeyFactory,
	useAuthUser,
	useQuestion,
	useUpdateQuestion,
} from '../../api';

import { DOCUMENTATION_WIDTH } from '../../components/Documentation/constants.ts';
import EntityPageLayout from '../../components/EntityPageLayout';
import { useFullWidthToggle } from '../../hooks/useFullWidthToggle.ts';
import { trackEvent } from '../../utils/analytics';
import { useCurrentEntityId } from '../../utils/hook/useCurrentEntityId';
import type { DjangoValueType } from '../TemplatePage/types';
import { QuestionLayout } from './components/Question';

export interface IDocumentPageProps {
	id?: string;
}

interface IStyleParams {
	shouldSetFullWidth: boolean;
}

const useStyles = createStyles<string, IStyleParams>(
	(_theme, { shouldSetFullWidth }) => ({
		contentWrapper: {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
		},
		content: {
			maxWidth: shouldSetFullWidth ? '100%' : `${DOCUMENTATION_WIDTH}px`,
			padding: 0,
		},
	})
);

function QuestionPage({ id: passId }: IDocumentPageProps) {
	const id = (useCurrentEntityId() || passId) as string;
	const { user, workspace } = useAuthUser();
	const { shouldSetFullWidth } = useFullWidthToggle(id);
	const { classes } = useStyles({ shouldSetFullWidth });

	useEffect(() => {
		if (id) {
			trackEvent(
				'question/open',
				{
					id,
				},
				user,
				workspace
			);
		}
	});

	const { mutateAsync: updateQuestion } = useUpdateQuestion({
		options: {
			onSuccess: () => {
				// Refresh the question list page after updating the question.
				queryClient.invalidateQueries(questionsQueryKeyFactory.list());
			},
		},
	});

	const { data: question } = useQuestion({
		id,
		options: {
			useErrorBoundary: true,
		},
	});

	const handleUpdate = useCallback(
		async (
			key: string,
			value: DjangoValueType,
			saveRemotely: boolean = true
		) => {
			if (!question) return;
			if (!saveRemotely) {
				queryClient.setQueryData(questionsQueryKeyFactory.byId(id), {
					...question,
					[key]: value,
				});
				return;
			}
			updateQuestion({ data: { id: question.id, [key]: value } });
			trackEvent(
				'question/update',
				{
					id: question.id,
				},
				user,
				workspace
			);
		},
		[id, question, updateQuestion, user, workspace]
	);

	if (!question) {
		return null;
	}

	let source;
	// TODO: This should be moved to the integration property.
	if (question.native_type === 'Jira_question') {
		source = 'Jira';
	} else if (question.native_type === 'slack_thread') {
		source = 'Slack';
	} else {
		source = 'Secoda';
	}

	return (
		<EntityPageLayout
			name="Question"
			entity={question}
			updateEntity={handleUpdate}
			// Do not let the user modify owners, as these track who created the
			// question.
			withOwnerSelector={false}
			withCollectionSelector
			withTagSelector
			withCustomPropertyEditors
			withRelatedResourceSelector
			withTeamSelector
			withVerifiedSelector
			classNames={{
				contentWrapper: classes.contentWrapper,
				content: classes.content,
			}}
			// Question specific properties.
			withAssignedToSelector
			withStatusSelector
			withPrioritySelector
			withSource={source}
		>
			<QuestionLayout id={id} />
		</EntityPageLayout>
	);
}

export default QuestionPage;
