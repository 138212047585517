import { size } from 'lodash-es';
import { useCallback } from 'react';
import {
	ISecodaEntity,
	queryClient,
	secodaEntitiesQueryKeyFactory,
	useAuthUser,
	useSecodaEntityInfiniteList,
} from '../../api';
import { FetchModelInfiniteListHook } from '../../api/factories/types';
import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';
import type { SuccessMessage } from '../../api/hooks/secodaEntity/useBulkUpdateSecodaEntities';
import useBulkUpdateSecodaEntities from '../../api/hooks/secodaEntity/useBulkUpdateSecodaEntities';
import entityDrawerStore from '../../components/EntityDrawer/store';
import { TableV2 } from '../../components/TableV2';
import { EntityType } from '../../lib/types';
import { useColumns } from './TrashPage.hooks';

export interface ITrashEntityTableProps {
	onRestoreMessage: SuccessMessage;
	restoreData: Record<string, string | boolean | null>;
}

export function TrashEntityTable({
	onRestoreMessage = { title: '', message: '' },
	restoreData = {},
}: ITrashEntityTableProps) {
	const columns = useColumns();
	const { isEditorOrAdminUser } = useAuthUser();

	const { mutateAsync: updateBulkSecodaEntity } =
		useBulkUpdateSecodaEntities(onRestoreMessage);

	const handleRestore = useCallback(
		async (selectedEntities: ISecodaEntity[], clearSelected: VoidFunction) => {
			if (selectedEntities.length === 0) {
				return;
			}
			const data = selectedEntities.map((item) => ({
				id: item.id,
				data: restoreData,
			}));
			const requestData = {
				data,
			};
			await updateBulkSecodaEntity(requestData);
			queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.allLists());
			queryClient.invalidateQueries(secodaEntitiesQueryKeyFactory.allLists());
			clearSelected();
		},
		[restoreData, updateBulkSecodaEntity]
	);

	const handleNavigate = useCallback(
		async ({ record }: { record: ISecodaEntity }) => {
			await entityDrawerStore.openEntityDrawerById(
				isEditorOrAdminUser,
				record.id,
				record.entity_type,
				undefined,
				{ archived: true }
			);
		},
		[isEditorOrAdminUser]
	);

	return (
		<TableV2<ISecodaEntity>
			pluralTypeString="resources"
			withQuickActions={['actions::restore']}
			withInfiniteScroll
			withFilters={false}
			withCheckbox
			onCellClick={handleNavigate}
			withActions={[
				{
					id: 'actions::restore',
					title: 'Restore',
					name: 'Restore',
					iconName: 'arrowsSplit' as const,
					hotkey: '/sp',
					type: EntityType.all,
					team: undefined,
					category: 'actions',
					show: (selected: ISecodaEntity[]) => size(selected) >= 1,
					onClick: handleRestore,
				},
			]}
			// Only include read-only columns.
			columns={columns.filter((column) =>
				['title', 'type', 'parent', 'updated_at'].includes(column.accessor)
			)}
			defaultRequiredSearchParams={{
				archived: true,
			}}
			usePaginationList={
				useSecodaEntityInfiniteList as FetchModelInfiniteListHook<ISecodaEntity>
			}
		/>
	);
}
