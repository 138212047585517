import { Box, createStyles, getStylesRef, Group } from '@mantine/core';
import { SelectablePropertyItem } from '@repo/common/components/ItemIcon/types';
import MultiSelectorTarget from '@repo/common/components/MultiSelector/MultiSelectorTarget';
import { Icon, ListBox, Text } from '@repo/foundations';
import { useCallback, useMemo, useState } from 'react';
import { AddGlossarySynonym } from '../InlineGlossarySynonyms/AddGlossarySynonym';
import { GlossarySynonymPill } from '../InlineGlossarySynonyms/GlossarySynonymPill';

const useStyles = createStyles((theme) => ({
	dropdown: {
		paddingTop: theme.spacing['2xs'],
		minWidth: 240,
	},
	item: {
		gap: theme.spacing['2xs'],
		cursor: 'pointer',

		[`&:hover .${getStylesRef('deleteButton')}`]: {
			opacity: 1,
		},
	},
	deleteButton: {
		ref: getStylesRef('deleteButton'),
		opacity: 0,
	},
}));

export interface SynonymsSelectorProps {
	synonyms: string[];
	onChange?: (synonyms: string[]) => void;
	initialOpened?: boolean;
}

export function SynonymsSelector({
	synonyms,
	onChange,
	initialOpened = false,
}: SynonymsSelectorProps) {
	const { classes } = useStyles();
	const [opened, setOpened] = useState(initialOpened);

	const selected = useMemo(
		() =>
			(synonyms ?? []).map(
				(synonym) =>
					({
						label: synonym,
						value: synonym,
					}) as SelectablePropertyItem
			),
		[synonyms]
	);

	const handleOnAdd = useCallback(
		async (synonym: string) => {
			const newSynonyms = [...synonyms, synonym];
			await onChange?.(newSynonyms);
		},
		[onChange, synonyms]
	);

	const handleOnRemove = useCallback(
		(synonym: string) => async () => {
			const newSynonyms = synonyms.filter((s) => s !== synonym);
			await onChange?.(newSynonyms);
		},
		[synonyms, onChange]
	);

	return (
		<ListBox disabled={!onChange} opened={opened} onOpenChange={setOpened}>
			<ListBox.Target>
				<MultiSelectorTarget
					selected={selected}
					iconType="none"
					property="synonym"
					propertyLabel="Synonyms"
					isMenuItemBadge={false}
					isViewerUser={!onChange}
					permittedId={''}
					readOnly={!onChange}
				/>
			</ListBox.Target>
			<ListBox.Dropdown className={classes.dropdown}>
				{synonyms.map((synonym, index) => (
					<ListBox.Item
						className={classes.item}
						// eslint-disable-next-line react/no-array-index-key
						key={`item-${index}`}
						rightSection={<Icon className={classes.deleteButton} name="x" />}
						onClick={handleOnRemove(synonym)}
					>
						<Box style={{ flex: 1, display: 'inline-block' }}>
							<GlossarySynonymPill synonym={synonym} />
						</Box>
					</ListBox.Item>
				))}
				<ListBox.Divider />
				<AddGlossarySynonym onAdd={handleOnAdd}>
					<ListBox.Item>
						<Group spacing="2xs">
							<Icon name="plus" />
							<Text size="sm">Add synonym...</Text>
						</Group>
					</ListBox.Item>
				</AddGlossarySynonym>
			</ListBox.Dropdown>
		</ListBox>
	);
}
