import { Group, createStyles, getStylesRef } from '@mantine/core';
import { Icon, ListBox, Text } from '@repo/foundations';
import { FilterItem, FilterOption } from './types';

const useStyles = createStyles(() => ({
	wrapper: {
		[`&:hover .${getStylesRef('checkbox')}`]: {
			visibility: 'visible',
		},
	},
	checkbox: {
		ref: getStylesRef('checkbox'),
		visibility: 'hidden',
	},
}));

interface NestedFilterMenuItemProps {
	item: FilterItem;
	option: FilterOption;
	onClick: (close: boolean) => void;
	className?: string;
	getProps: (userProps: React.HTMLProps<HTMLElement>) => any;
}

export function NestedFilterMenuItem({
	item,
	option,
	onClick,
	className,
	getProps,
}: NestedFilterMenuItemProps) {
	const { classes } = useStyles();

	return (
		<ListBox.Item {...getProps({ onClick: () => onClick(true), className })}>
			<Group className={classes.wrapper}>
				<Group spacing="4xs">
					<Text color="text/secondary/default" size="sm">
						{option.label}
					</Text>
					<Icon name="chevronRight" color="icon/secondary/default" />
					<Group spacing="2xs">
						{item.icon}
						{option.filterDropdownConfig.renderMenuItem ? (
							option.filterDropdownConfig.renderMenuItem(item)
						) : (
							<Text color="text/primary/default" size="sm">
								{item.label}
							</Text>
						)}
					</Group>
				</Group>
			</Group>
		</ListBox.Item>
	);
}
