import type { MantineTheme } from '@mantine/core';
import { Center, createStyles, Image } from '@mantine/core';
import { IconEmoji } from '@repo/common/components/IconEmoji/IconEmoji';
import { includes, isNil } from 'lodash-es';
import { useAuthUser, useUser, type ISecodaEntity } from '../../../api';
import type { SecodaEntity } from '../../../lib/models';
import { EntityType } from '../../../lib/types';
import { getIconByEntityType } from '../../../utils/integrationLogo';
import IntegrationLogo from '../../IntegrationLogo';
import { SecodaMinimalLogo } from '../../SecodaMinimalLogo';
import { UserAvatar } from '../../UserAvatar';

export type ISecodaEntityIcon = Pick<
	SecodaEntity | ISecodaEntity,
	'entity_type' | 'icon' | 'integration' | 'id' | 'title'
>;

interface ISecodaEntityIconProps {
	entity?: ISecodaEntityIcon;
	size?: number;
	inline?: boolean;
	inverseIconColor?: boolean;
}

const DEFAULT_ICONS = {
	[EntityType.collection]: 'iconName:folder iconColor:#4a4a4a',
	[EntityType.document]: 'iconName:file iconColor:#4a4a4a',
	[EntityType.dictionary_term]: 'iconName:book iconColor:#4a4a4a',
	[EntityType.glossary]: 'iconName:book iconColor:#4a4a4a',
};

const useStyles = createStyles(
	(theme: MantineTheme, { size }: { size: number }) => ({
		center: {
			minWidth: size,
			minHeight: size,
			borderRadius: 1000,
		},
		emoji: {
			fontSize: size - 4,
			lineHeight: `${size - 4}px`,
		},
	})
);

export function SecodaEntityIcon({
	entity,
	inline = false,
	size = 36,
	inverseIconColor = false,
}: ISecodaEntityIconProps) {
	const { classes, theme } = useStyles({
		size,
	});

	const { workspace } = useAuthUser();

	const { data: user } = useUser({
		id: entity?.id ?? '',
		options: {
			enabled: !isNil(entity?.id) && entity?.entity_type === EntityType.user,
		},
	});

	if (isNil(entity)) {
		return workspace.logo ? (
			<Image width={size} src={workspace.logo} />
		) : (
			<SecodaMinimalLogo width={size} height={size} />
		);
	}

	if (entity.entity_type === EntityType.user) {
		return (
			<UserAvatar
				size={size}
				user={{
					id: user?.id || '',
					disabled: user?.disabled || false,
					display_name: entity?.title || 'Untitled',
					profile_picture: user?.profile_picture || '',
					first_name: user?.first_name || '',
					last_name: user?.last_name || '',
				}}
			/>
		);
	}

	if (includes([EntityType.metric, EntityType.question], entity.entity_type)) {
		const { icon: Icon, backgroundColor } = getIconByEntityType(
			entity.entity_type
		);
		return (
			<Center
				className={classes.center}
				style={{
					backgroundColor: inverseIconColor ? 'transparent' : backgroundColor,
				}}
				inline={inline}
			>
				{Icon && (
					<Icon
						size={size - 4}
						color={theme.other.getColor(
							inverseIconColor ? 'icon/primary/default' : 'icon/inverse/default'
						)}
					/>
				)}
			</Center>
		);
	}

	if (
		entity.entity_type === EntityType.collection ||
		entity.entity_type === EntityType.document ||
		entity.entity_type === EntityType.dictionary_term
	) {
		return (
			<Center className={classes.center} inline={inline}>
				<IconEmoji
					value={entity.icon || DEFAULT_ICONS[entity.entity_type]}
					size={size}
					className={classes.emoji}
				/>
			</Center>
		);
	}

	return (
		<Center className={classes.center} inline={inline}>
			<IntegrationLogo
				size={size - 4}
				entityType={entity.entity_type}
				integrationId={entity.integration}
			/>
		</Center>
	);
}
