import { createStyles, Group, Loader, Tabs } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';

import { useRetrieveQuality } from '@repo/api-codegen';
import TabsList, { TabItem } from '@repo/common/components/TabsList';
import { getScoreClassification } from '@repo/common/utils/dataQuality';
import type { ISecodaEntity } from '../../../api';
import {
	useAuthUser,
	useDataQualityAccess,
	useIntegration,
	useIntegrationSupport,
} from '../../../api';
import { useFeatureAccess } from '../../../api/hooks/workspace/useFeatureAccess';
import { DataQualityBadge } from '../../../components/DataQualityScore/DataQualityBadge';
import Documentation from '../../../components/Documentation';
import EntityDiscussions from '../../../components/EntityDiscussions';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { LineageGraphWrapper } from '../../../components/LineageGraph/LineageGraph';
import TableDataPreview from '../../../components/Preview/TableDataPreview';
import { QueryList } from '../../../components/QueryList/QueryList';
import { trackEvent } from '../../../utils/analytics';
import { useTabNavigate } from '../../../utils/hook/useTabNavigate';
import { withParanthesis } from '../../../utils/shared.utils';
import { TestsProvider } from '../../TablePage/Tests';
import ColumnsTab from './ColumnsTab';
import { DataQualityTab } from './DataQualityTab';
import IncidentsTab from './IncidentsTab';
import MonitorsTab from './MonitorsTab';
import type { TableEntityTabsStore } from './TableEntityTabsStore';

const useStyles = createStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	panel: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
}));

export interface ITableEntityTabsProps {
	table: ISecodaEntity;
	tableEntityTabsStore: TableEntityTabsStore;
	tab: string | undefined;
}

function TabLoader() {
	return <Loader size={12} />;
}

function TableEntityTabs({
	table,
	tableEntityTabsStore: store,
	tab = 'columns',
}: ITableEntityTabsProps) {
	const { classes } = useStyles();
	const { workspace, user } = useAuthUser();
	const { monitoringAccess } = useFeatureAccess();
	const dqsEnabled = useDataQualityAccess();
	const canShowDataQualityScore =
		dqsEnabled && table?.entity_type?.toLowerCase() === 'table';
	const { data: dataQuality, isLoading: dataQualityLoading } =
		useRetrieveQuality(
			{
				pathParams: {
					entityId: table.id,
				},
			},
			{
				enabled: canShowDataQualityScore,
				refetchOnMount: 'always',
			}
		);
	const isDataQualityScoreEnabled = !dataQualityLoading && dataQuality !== null;

	const isLineageDisabled = store.isLineageLoading;

	const { data: integrationSupport } = useIntegrationSupport();
	const { data: integration } = useIntegration({ id: table.integration ?? '' });

	const integrationKeys = integrationSupport
		? Object.keys(integrationSupport)
		: [];
	const isMonitoringEnabled =
		Boolean(workspace?.monitoring_enabled) &&
		Boolean(monitoringAccess) &&
		integrationKeys.includes(integration?.type ?? '');

	const previewActive = !['looker', 'powerbi', 'tableau', 'mode'].includes(
		integration?.type ?? ''
	);

	const testsActive = (store?.testCount ?? 0) > 0;
	const isTestsDisabled = store.isTestLoading;
	const showTestsTab = store.isTestLoading || testsActive;

	const queriesActive = (store?.queryCount ?? 0) > 0;
	const showQueriesTab = store.isQueryLoading || queriesActive;

	const incidentsActive = (store?.incidentsCount ?? 0) > 0;
	const showIncidentsTab = isMonitoringEnabled && incidentsActive;

	const discussionsCount = withParanthesis(store.discussionCount);

	const navigateToTab = useTabNavigate();

	const handleTabChange = (updatedTab: string) => {
		if (updatedTab === 'lineage') {
			trackEvent('table/lineage/open', {}, user, workspace);
		}
		if (updatedTab === 'quality') {
			trackEvent('dqs/resource_tab/open', {}, user, workspace);
		}
		navigateToTab(updatedTab);
	};

	const tabsList: TabItem[] = [
		{
			value: 'columns',
			icon: store.isColumnLoading && <TabLoader />,
			label: 'Columns',
		},
		{
			value: 'lineage',
			icon: store.isLineageLoading && <TabLoader />,
			disabled: isLineageDisabled,
			label: 'Lineage',
		},
		{
			value: 'monitors',
			label: 'Monitors',
			hidden: !isMonitoringEnabled,
		},
		{
			value: 'incidents',
			label: 'Incidents',
			hidden: !showIncidentsTab,
		},
		{
			value: 'tests',
			disabled: isTestsDisabled,
			icon: store.isTestLoading && <TabLoader />,
			label: 'Tests',
			hidden: !showTestsTab,
		},
		{
			value: 'queries',
			label: 'Queries',
			hidden: !showQueriesTab,
		},
		{
			value: 'preview',
			hidden: !previewActive,
			label: 'Preview',
		},
		{
			value: 'discussions',
			icon: store.isDiscussionLoading && <TabLoader />,
			label: `Questions ${discussionsCount}`,
		},
		{
			value: 'documentation',
			label: 'Documentation',
		},
		...(canShowDataQualityScore && isDataQualityScoreEnabled
			? [
					{
						value: 'quality',
						label: (
							<Group spacing="xs" noWrap>
								<span>Quality</span>
								<DataQualityBadge
									classification={getScoreClassification(dataQuality?.total)}
									my={0}
								/>
							</Group>
						),
					},
				]
			: []),
	];

	return (
		<Tabs
			color="dark"
			classNames={{
				root: classes.root,
				panel: classes.panel,
			}}
			value={tab}
			onTabChange={handleTabChange}
		>
			<TabsList tabs={tabsList} />
			<Tabs.Panel value="lineage" pt="sm">
				<LineageGraphWrapper
					id={table.id}
					type={table.native_type}
					store={store}
				/>
			</Tabs.Panel>

			<Tabs.Panel value="columns" pt="sm">
				<ColumnsTab table={table} store={store} />
			</Tabs.Panel>

			<Tabs.Panel value="monitors" pt="sm">
				<MonitorsTab
					integrationId={table.integration as string}
					tableId={table.id}
				/>
			</Tabs.Panel>
			<Tabs.Panel value="incidents" pt="sm">
				<IncidentsTab
					tableId={table.id}
					onTotalRowCountChange={store.setIncidentsCount}
				/>
			</Tabs.Panel>

			{previewActive && tab === 'preview' && (
				<Tabs.Panel value="preview" pt="sm">
					<TableDataPreview tableKey={table.id} />
				</Tabs.Panel>
			)}

			<Tabs.Panel value="tests" pt="sm">
				<ErrorBoundary onError={() => store.setTestCount(0)}>
					<Suspense fallback={<Loader />}>
						<TestsProvider entity={table} store={store} />
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>

			<Tabs.Panel value="queries" pt="sm">
				<ErrorBoundary onError={() => store.setQueryCount(0)}>
					<Suspense fallback={<Loader />}>
						<QueryList
							entity={table as unknown as ISecodaEntity}
							store={store}
						/>
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>

			<Tabs.Panel value="discussions" pt="sm">
				<ErrorBoundary onError={() => store.setDiscussionCount(0)}>
					<Suspense fallback={<Loader />}>
						<EntityDiscussions entity={table} store={store} />
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>

			<Tabs.Panel value="documentation" pt="sm">
				<Documentation entity={table} />
			</Tabs.Panel>

			{canShowDataQualityScore && isDataQualityScoreEnabled && (
				<Tabs.Panel value="quality" pt="sm">
					<DataQualityTab entity={table} />
				</Tabs.Panel>
			)}
		</Tabs>
	);
}

export default observer(TableEntityTabs);
