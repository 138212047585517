import { ActionIcon } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { CatalogProperty } from '../../api';
import { EntityType } from '../../lib/types';
import { openModal } from '../ModalManager';
import { TagOptionsModal } from './TagOptionsModal';

export type CustomPropertyCatalogProperty = CatalogProperty & {
	entity_types: EntityType[];
	value_type: string;
};

interface TagOptionsButtonProps {
	property: CustomPropertyCatalogProperty | undefined;
	customPropertyId: string | undefined;
}

export function TagOptionsButton({
	property,
	customPropertyId,
}: TagOptionsButtonProps) {
	if (!property) {
		return null;
	}

	if (property.value_type !== 'select') {
		return null;
	}

	const handleClick = () => {
		openModal({
			title: 'Manage options',
			children: (
				<TagOptionsModal
					customPropertyId={customPropertyId}
					propertyLabel={property.value}
				/>
			),
		});
	};

	return (
		<ActionIcon onClick={handleClick} size="xs">
			<Icon name="tag" />
		</ActionIcon>
	);
}
