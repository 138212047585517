import { Group, Stack, useMantineTheme } from '@mantine/core';
import { Text } from '@repo/foundations';
import { chartColors } from '@repo/theme/primitives';
import { IconMinus } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { uniq } from 'lodash-es';
import { useMemo } from 'react';
import { MetricNumericFormat } from '../../../../../../api';
import { QueryResults } from '../../../../../../lib/types';
import { MetricWidgetTooltip } from '../../../../../../pages/AnalyticsPage/components/MetricWidgetLineChart/MetricWidgetTooltip';
import { LineChart } from '../../../../../../utils/charts/LineChart';
import {
	arrayToObjects,
	formatMetricNumber,
	parseValueToNumber,
} from '../../../../../../utils/metrics';
import { parseUnknownDateTime } from '../../../../../../utils/time';
import { EmptyState } from '../../../../../EmptyState';

const MAX_SERIES = 10;

export interface MetricChartProps {
	results: QueryResults;
	xAxis?: string;
	yAxis?: string;
	dimension?: string;
	numericFormat?: MetricNumericFormat;
}

export function MetricChart({
	results,
	xAxis,
	yAxis,
	dimension,
	numericFormat,
}: MetricChartProps) {
	const theme = useMantineTheme();

	const { data, channels, tooltipRenderer } = useMemo(() => {
		const cleanValues =
			xAxis && yAxis
				? arrayToObjects(results ?? [])
						.filter((t) => parseValueToNumber(t[xAxis])[1])
						.map((t) => ({
							x: parseUnknownDateTime(t[xAxis])?.toDate(),
							y: parseValueToNumber(t[yAxis])[0] as number,
							series: dimension ? String(t?.[dimension]) : yAxis,
						}))
						.sort((a, b) => (a.x && b.x ? a.x?.getTime() - b.x?.getTime() : 0))
				: [];

		const result: ({ x: Date | undefined } & Record<string, unknown>)[] = [];
		const seriesSet = uniq(cleanValues.map((item) => item.series)).slice(
			0,
			MAX_SERIES
		);

		cleanValues.forEach((item) => {
			if (!seriesSet.includes(item.series)) {
				// if row is not part of the top series, skip it
				return;
			}

			let existingEntry = result.find(
				(entry) => entry.x?.getTime() === item.x?.getTime()
			);

			if (!existingEntry) {
				existingEntry = { x: item.x };
				seriesSet.forEach((serie) => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-expect-error
					existingEntry[serie] = null;
				});
				result.push(existingEntry);
			}

			existingEntry[item.series] = item.y;
		});

		const channelsResult = seriesSet.map((s, index) => ({
			x: 'x',
			y: s,
			label: s,
			color:
				seriesSet.length > 1
					? chartColors[index % chartColors.length]
					: theme.other.getColor('fill/info/default'),
		}));

		function tooltipRendererInternal(dataIndex: number) {
			const tooltipData = data[dataIndex];

			const title = dayjs.utc(tooltipData.x).format('MMM D, YYYY [at] h:mm A');
			const items = seriesSet.map((s, index) => ({
				label: s,
				value: formatMetricNumber(
					(tooltipData[s] ?? 0) as number,
					numericFormat ?? 'number',
					0
				),
				color:
					seriesSet.length > 1
						? chartColors[index % chartColors.length]
						: theme.other.getColor('fill/info/default'),
			}));

			return <MetricWidgetTooltip title={title} items={items} />;
		}

		return {
			data: result,
			series: seriesSet,
			channels: channelsResult,
			tooltipRenderer: tooltipRendererInternal,
		};
	}, [xAxis, yAxis, results, dimension, theme, numericFormat]);

	if (!data.length) {
		return (
			<EmptyState
				iconName="chartHistogram"
				title="Preview query"
				description="Configure the X-axis and Y-axis fields after the query has been executed"
				includeGoBack={false}
				size="sm"
			/>
		);
	}

	return (
		<Stack spacing="md">
			<LineChart
				data={data}
				channels={channels}
				labelX="Date"
				height={360}
				xAxis={{
					visible: true,
				}}
				yAxis={{
					formatter: (value) =>
						formatMetricNumber(value as number, numericFormat ?? 'number', 0),
					grid: true,
					maxTicks: 5,
				}}
				tooltipRenderer={tooltipRenderer}
			/>
			<Group position="center" align="center">
				{channels.map((channel) => (
					<Group key={channel.y} spacing="4xs">
						<IconMinus color={channel.color} width={16} height={16} />
						<Text size="xs" color="text/secondary/default">
							{channel.label}
						</Text>
					</Group>
				))}
			</Group>
		</Stack>
	);
}
