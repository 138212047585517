/* eslint-disable no-bitwise */
import {
	Box,
	// NOTE: we would like to avoid using `@mantine/core/Button` in favour of `@repo/foundations/Button`
	// eslint-disable-next-line no-restricted-imports
	Button,
	Center,
	createStyles,
	Group,
} from '@mantine/core';
import { Icon } from '@repo/foundations';
import { ColorNames } from '@repo/theme/utils.ts';
import { useUpdateEffect } from 'ahooks';
import { countBy } from 'lodash-es';
import * as monaco from 'monaco-editor';
import { LanguageIdEnum, setupLanguageFeatures } from 'monaco-sql-languages';
import { useCallback, useEffect, useRef, useState } from 'react';
import { hashCode } from '../../../utils/utils';
import { DataDisplayTable } from '../../DataDisplayTable';
import { EmptyState } from '../../EmptyState';
import { ExpandCollapse } from '../../ExpandCollapse/ExpandCollapse.tsx';
import './languageSetup.ts';
import { completionService } from './languageSetup.ts';
import { getEditorOptions } from './SqlEditor.helpers';
import { getMonacoTheme } from './SqlEditorTheme/monacoTheme.ts';

const LINE_HEIGHT = 18;
const DEFAULT_MIN_HEIGHT = 274;
export const BOTTOM_WRAPPER_PADDING = 26;

export interface ISqlEditorProps {
	results?: unknown[][];
	className?: string;
	autoCompleteIntegrationId?: string;
	autoCompleteIntegrationType?: string;
	minHeight?: number;
	classNames?: {
		root?: string;
		editor?: string;
		expandCollapseButton?: string;
	};
	defaultValue?: string;
	onChange?: (value: string) => void;
	onExecute?: () => void;
	withActions?: JSX.Element;
	lineNumbers?: boolean;
	readOnly?: boolean;
	collapseHeight?: number;
	collapseBackgroundColor?: ColorNames;
}

const useStyles = createStyles(
	(theme, { minHeight }: { minHeight: number }) => ({
		wrapper: {
			position: 'relative',
			padding: 8,
			minHeight: minHeight + BOTTOM_WRAPPER_PADDING,
		},
		editor: {
			'.monaco-editor .view-overlays .current-line-exact': {
				border: 'none',
				backgroundColor: theme.other.getColor('surface/emphasis/default'),
			},
		},
	})
);

export default function SqlEditor({
	results = [],
	className,
	classNames,
	defaultValue = '',
	autoCompleteIntegrationId,
	autoCompleteIntegrationType,
	lineNumbers = false,
	readOnly = false,
	withActions,
	minHeight = DEFAULT_MIN_HEIGHT,
	onChange,
	onExecute,
	collapseHeight,
	collapseBackgroundColor,
}: ISqlEditorProps) {
	const hostRef = useRef<HTMLDivElement>(null);
	const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();

	const { classes, cx, theme } = useStyles({ minHeight });

	const [query, setQuery] = useState(defaultValue);
	const [tab, setTab] = useState<'query' | 'preview'>(
		results.length > 0 ? 'preview' : 'query'
	);
	const [height, setHeight] = useState(minHeight);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (results.length > 0) {
			setTab('preview');
		}
	}, [results.length]);

	useUpdateEffect(() => {
		onChange?.(query);
	}, [query]);

	useEffect(
		() => () => {
			editorRef.current?.dispose();
			editorRef.current = undefined;
		},
		[]
	);

	const updateHeight = useCallback(
		(lineCount: number) => {
			setHeight(lineCount ? lineCount * LINE_HEIGHT : minHeight);
		},
		[minHeight]
	);

	useEffect(() => {
		const lineCount = query?.split('\n').length ?? 0;
		if (lineCount) {
			updateHeight(lineCount);
		}
	}, [updateHeight, query]);

	useEffect(() => {
		if (hostRef.current && !editorRef.current) {
			setupLanguageFeatures(LanguageIdEnum.MYSQL, {
				completionItems: {
					enable: true,
					completionService: completionService(
						autoCompleteIntegrationId,
						autoCompleteIntegrationType
					),
				},
			});

			monaco.editor.defineTheme('sqlTheme', getMonacoTheme(theme));
			editorRef.current = monaco.editor.create(hostRef.current, {
				language: LanguageIdEnum.MYSQL,
				theme: 'sqlTheme',
				...getEditorOptions(lineNumbers, readOnly, false),
				glyphMargin: countBy(query)?.['\n'] > 20,
				value: defaultValue,
			});
			editorRef.current.onDidChangeModelContent(() => {
				setQuery(editorRef?.current?.getValue() ?? '');
				const lineCount = editorRef?.current?.getModel()?.getLineCount();
				if (lineCount) {
					updateHeight(lineCount);
				}
			});
			editorRef.current.addAction({
				id: 'runQuery',
				label: 'Run Query',
				precondition: 'editorTextFocus',
				keybindings: [
					monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
					monaco.KeyMod.WinCtrl | monaco.KeyCode.Enter,
					monaco.KeyMod.Shift | monaco.KeyCode.Enter,
				],
				run: () => {
					if (onExecute) {
						onExecute();
					}
				},
			});
			setLoaded(true);
		}
	}, [
		autoCompleteIntegrationId,
		autoCompleteIntegrationType,
		defaultValue,
		lineNumbers,
		onExecute,
		query,
		readOnly,
		theme,
		updateHeight,
	]);

	// If the editor is read-only and the default value changes, update the editor.
	useEffect(() => {
		if (editorRef.current && readOnly) {
			editorRef.current.setValue(defaultValue);
		}
	}, [defaultValue, readOnly]);

	const autoGrow = !!collapseHeight;
	const editorHeight = autoGrow ? Math.max(height, minHeight) : '100%';

	const key = hashCode(JSON.stringify(results));

	return (
		<Box className={cx(classes.wrapper, className, classNames?.root)}>
			{withActions && (
				<Group sx={{ justifyContent: 'space-between' }} mb={16}>
					<Group spacing={2}>
						<Button
							data-testid="preview-sql-editor-button"
							onClick={() => setTab('preview')}
							bg={
								tab === 'preview'
									? theme.other.getColor('fill/transparent/active')
									: theme.other.getColor('fill/transparent/default')
							}
							leftIcon={<Icon name="table" />}
							variant="subtle"
							size="md"
						>
							Preview
						</Button>
						<Button
							data-testid="query-sql-editor-button"
							onClick={() => setTab('query')}
							bg={
								tab === 'query'
									? theme.other.getColor('fill/transparent/active')
									: theme.other.getColor('fill/transparent/default')
							}
							leftIcon={<Icon name="code" />}
							variant="subtle"
							size="md"
						>
							Query
						</Button>
					</Group>
					{withActions}
				</Group>
			)}
			<Box
				data-testid="query-sql-editor"
				display={tab === 'query' ? 'block' : 'none'}
			>
				<ExpandCollapse
					enabled={loaded && autoGrow && collapseHeight >= minHeight}
					maxHeight={Math.max(collapseHeight ?? minHeight, minHeight)}
					classNames={{
						button: classNames?.expandCollapseButton,
					}}
					color={collapseBackgroundColor}
				>
					<div
						ref={hostRef}
						className={cx(classes.editor, classNames?.editor)}
						style={{
							height: loaded ? editorHeight : 0,
							minHeight: loaded ? minHeight : 0,
							width: '100%',
						}}
					/>
				</ExpandCollapse>
			</Box>
			<Box key={key} display={tab === 'preview' ? 'block' : 'none'}>
				{/* We check for results.length <= 1 because the first row is the column names. */}
				{results.length <= 1 && (
					<Center data-testid="sql-editor-preview-empty-state" py={60}>
						<EmptyState
							size="lg"
							iconName="tableRow"
							title="No data found"
							description="This query returned no rows. Try adjusting the query"
							includeGoBack={false}
						/>
					</Center>
				)}
				{results.length > 0 && <DataDisplayTable results={results} />}
			</Box>
		</Box>
	);
}
