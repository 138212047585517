import { Drawer, Group, SimpleGrid, Stack, TextInput } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { Text } from '@repo/foundations';
import dayjs from 'dayjs';
import Fuse from 'fuse.js';
import {
	countBy,
	flatMap,
	groupBy,
	isEmpty,
	isNil,
	keys,
	map,
	values,
} from 'lodash-es';
import { useMemo, useState } from 'react';

import { useGetInTrialWorkspaces } from '@repo/api-codegen';
import { Icon } from '@repo/foundations';
import { Navigate } from 'react-router';
import { useInternalIntegrationStatuses } from '../../../api/hooks/internalIntegrationStatus';
import type {
	IInternalDatabuilderJobStatus,
	JobStatus,
} from '../../../api/types/models/internalIntegrationStatus';
import { FullWidthLoadingSpinner } from '../../../components/LoadingSpinner';
import { DAYS_LOOKBACK } from './constants';
import { IntegrationCard } from './IntegrationCard';
import { useStyles } from './IntegrationsStatusPage.styles';
import { IntegrationStatusPageFilters } from './IntegrationsStatusPageFilters';
import { JobMetadataDrawer } from './JobMetadataDrawer';
import { StatusCountCard } from './StatusCountCard';
import {
	isActiveFailing,
	isFailingWithNewError,
	isRecentlyResolved,
} from './utils';

function IntegrationStatusesPage() {
	const { classes, theme } = useStyles();
	const [searchTerm, setSearchTerm] = useInputState('');

	const [jobStatus, setJobStatus] = useState<JobStatus>('all');
	const [lastNumDays, setLastNumDays] = useState<number>(DAYS_LOOKBACK);
	const [hideSuccessfulJobs, setHideSuccessfulJobs] = useState(false);
	const [showRuntimeChart, setShowRuntimeChart] = useState(false);
	const [runOnAWSBatchOnly, setRunOnAWSBatchOnly] = useState(false);
	const [groupByWorkspace, setGroupByWorkspace] = useState(false);

	const [jobMetadata, setJobMetadata] =
		useState<IInternalDatabuilderJobStatus>();

	const { data: internalIntegrationStatuses, isLoading } =
		useInternalIntegrationStatuses();

	const { data: inTrialWorkspaces } = useGetInTrialWorkspaces({});

	const [ticketTitles, setTicketTitles] = useState<{ [key: string]: string[] }>(
		{}
	);
	const data = useMemo(() => {
		const filteredData = map(internalIntegrationStatuses, (status) => {
			if (jobStatus === 'activeFail') {
				return {
					...status,
					latest_k_jobs: isActiveFailing(status.latest_k_jobs, lastNumDays)
						? status.latest_k_jobs
						: [],
				};
			}
			if (jobStatus === 'failingWithNewError') {
				return {
					...status,
					latest_k_jobs: isFailingWithNewError(
						status.latest_k_jobs,
						lastNumDays
					)
						? status.latest_k_jobs
						: [],
				};
			}
			if (jobStatus === 'recentlyResolved') {
				return {
					...status,
					latest_k_jobs: isRecentlyResolved(status.latest_k_jobs, lastNumDays)
						? status.latest_k_jobs
						: [],
				};
			}
			if (jobStatus === 'ticketCreated') {
				return {
					...status,
					latest_k_jobs: status.tickets.length > 0 ? status.latest_k_jobs : [],
				};
			}
			if (jobStatus === 'inTrial') {
				return {
					...status,
					latest_k_jobs: inTrialWorkspaces?.includes(status.workspace_id)
						? status.latest_k_jobs
						: [],
				};
			}
			const filteredJobs = status.latest_k_jobs.filter((job) => {
				if (runOnAWSBatchOnly && !job.aws_batch_job_id) {
					return false;
				}

				if (hideSuccessfulJobs && job.status === 'succeeded') {
					return false;
				}

				if (jobStatus && jobStatus !== 'all' && job.status !== jobStatus) {
					return false;
				}
				if (lastNumDays !== DAYS_LOOKBACK) {
					const jobDate = dayjs(job.created_at);
					const minutesDifference = dayjs().diff(jobDate, 'minute');
					return minutesDifference <= lastNumDays * 24 * 60;
				}

				return true;
			});
			return {
				...status,
				latest_k_jobs: filteredJobs,
			};
		}).filter((status) => status.latest_k_jobs.length > 0);

		if (searchTerm === '') {
			return filteredData;
		}

		const options = {
			keys: ['integration_type', 'workspace_name'],
			threshold: 0.3,
		};
		const fuse = new Fuse(filteredData ?? [], options);
		return map(fuse.search(searchTerm), 'item');
	}, [
		internalIntegrationStatuses,
		searchTerm,
		jobStatus,
		inTrialWorkspaces,
		hideSuccessfulJobs,
		lastNumDays,
		runOnAWSBatchOnly,
	]);

	const groupedStatuses = useMemo(
		() => groupBy(data, groupByWorkspace ? 'workspace_id' : 'integration_type'),
		[data, groupByWorkspace]
	);
	if (keys(ticketTitles).length === 0 && !isEmpty(groupedStatuses)) {
		const curTitles: { [key: string]: string[] } = {};
		values(groupedStatuses).forEach((statuses) =>
			statuses.forEach(
				(status) => (curTitles[status.integration_id] = status.tickets)
			)
		);
		setTicketTitles(curTitles);
	}

	const {
		activeFailCount,
		danglingCount,
		runningCount,
		failingWithNewErrorCount,
	} = useMemo(() => {
		if (
			isNil(internalIntegrationStatuses) ||
			isEmpty(internalIntegrationStatuses)
		) {
			return {
				activeFailCount: 0,
				danglingCount: 0,
				runningCount: 0,
				failingWithNewErrorCount: 0,
			};
		}

		const twentyFourHoursAgo = dayjs().subtract(24, 'hour');
		const counts = countBy(
			flatMap(internalIntegrationStatuses, (integration) =>
				integration.latest_k_jobs
					.filter((job) => dayjs(job.created_at).isAfter(twentyFourHoursAgo))
					.map((job) => job.status)
			)
		);

		return {
			activeFailCount: internalIntegrationStatuses.filter((integration) =>
				isActiveFailing(integration.latest_k_jobs, 1)
			).length,
			danglingCount: counts.dangling || 0,
			runningCount: counts.running || 0,
			failingWithNewErrorCount: internalIntegrationStatuses.filter(
				(integration) => isFailingWithNewError(integration.latest_k_jobs, 1)
			).length,
		};
	}, [internalIntegrationStatuses]);

	if (isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	if (isNil(data)) {
		return <Navigate to="/" />;
	}

	return (
		<Stack spacing={theme.spacing['3xl']}>
			<SimpleGrid cols={4} spacing="xl" verticalSpacing="xl">
				<StatusCountCard
					label="actively failing integrations"
					count={activeFailCount}
					onClick={() => {
						setJobStatus('activeFail');
						setLastNumDays(1);
					}}
				/>
				<StatusCountCard
					label="dangling extractions"
					count={danglingCount}
					onClick={() => {
						setJobStatus('dangling');
						setLastNumDays(1);
					}}
				/>
				<StatusCountCard
					label="integrations failing with new errors"
					count={failingWithNewErrorCount}
					onClick={() => {
						setJobStatus('failingWithNewError');
						setLastNumDays(1);
					}}
				/>
				<StatusCountCard
					label="running extractions"
					count={runningCount}
					onClick={() => {
						setJobStatus('running');
						setLastNumDays(1);
					}}
				/>
			</SimpleGrid>
			<Stack>
				<Group>
					<TextInput
						classNames={{ root: classes.inputRoot, input: classes.input }}
						icon={<Icon name="search" color="icon/secondary/default" />}
						placeholder="Search for integrations or workspaces"
						value={searchTerm}
						onChange={setSearchTerm}
					/>
					<IntegrationStatusPageFilters
						lastNumDays={lastNumDays}
						setLastNumDays={setLastNumDays}
						hideSuccessfulJobs={hideSuccessfulJobs}
						setHideSuccessfulJobs={setHideSuccessfulJobs}
						showRuntimeChart={showRuntimeChart}
						setShowRuntimeChart={setShowRuntimeChart}
						jobStatus={jobStatus}
						setJobStatus={setJobStatus}
						runOnAWSBatchOnly={runOnAWSBatchOnly}
						setRunOnAWSBatchOnly={setRunOnAWSBatchOnly}
						groupByWorkspace={groupByWorkspace}
						setGroupByWorkspace={setGroupByWorkspace}
					/>
				</Group>
				<Stack spacing={theme.spacing['3xl']}>
					{keys(groupedStatuses)
						.sort()
						.map((type) => (
							<IntegrationCard
								key={type}
								type={groupByWorkspace ? null : type}
								statuses={groupedStatuses[type].sort((status1, status2) =>
									groupByWorkspace
										? status1.integration_name.localeCompare(
												status2.integration_name
											)
										: status1.workspace_name.localeCompare(
												status2.workspace_name
											)
								)}
								setJobMetadata={setJobMetadata}
								ticketTitles={ticketTitles}
								setTicketTitles={setTicketTitles}
							/>
						))}
				</Stack>
			</Stack>
			<Drawer
				opened={!isNil(jobMetadata)}
				onClose={() => setJobMetadata(undefined)}
				title={
					<Text size="md" weight="bold">
						Databuilder Job Metadata
					</Text>
				}
				position="right"
				size="xl"
			>
				<JobMetadataDrawer jobMetadata={jobMetadata} />
			</Drawer>
		</Stack>
	);
}

export default IntegrationStatusesPage;
