import { filter } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import {
	queryClient,
	useCollectionListAll,
	useUpdateCollection,
	type ICollection,
} from '../../api';
import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';
import { BulkUpdateData } from '../../api/hooks/secodaEntity/useBulkUpdateSecodaEntities';
import { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';
import {
	onClickGenericAction,
	useGenericActions,
	useGenericColumns,
} from '../../components/TableV2/SecodaEntity.hooks';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { EntityType } from '../../lib/types';

export const useColumns = (): ExtendedDataTableColumn<ICollection>[] => {
	const genericColumns = useGenericColumns({});

	const columns: ExtendedDataTableColumn<ICollection>[] = useMemo(
		() => [
			...filter(genericColumns, (column) => column.accessor !== 'collections'),
		],
		[genericColumns]
	);

	return columns;
};

export const useActions = () => {
	const genericActions = useGenericActions() as ICommandListItem<ICollection>[];

	const { mutateAsync: updateEntity } = useUpdateCollection({});

	const { data: collectionData } = useCollectionListAll();

	const collections = useMemo(
		() =>
			collectionData?.results?.map((collection) => ({
				label: collection.title || 'Untitled',
				value: collection.id,
				icon: collection.icon,
			})) ?? [],
		[collectionData?.results]
	);

	// We don't use the native bulk update here because there is middleware
	// needed to properly handle moving parents.
	const emulateBulkUpdate = useCallback(
		async (
			{ data }: { data: BulkUpdateData },
			{ onSuccess }: { onSuccess: () => void }
		) => {
			await Promise.all(
				data.map((item) =>
					updateEntity({ data: { id: item.id, ...item.data } }, {})
				)
			);
			await onSuccess();
			queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.allLists());
		},
		[updateEntity]
	);

	const parentAction = useMemo(
		() => ({
			id: 'actions::collections',
			name: 'Set parent',
			title: 'Set parent',
			iconName: 'arrowUp',
			hotkey: '/sp',
			type: EntityType.all,
			team: undefined,
			category: 'actions',
			show: true,
			onClick: onClickGenericAction(
				'collections',
				false,
				emulateBulkUpdate as never,
				collections
			),
		}),
		[collections, emulateBulkUpdate]
	) as ICommandListItem<ICollection>;

	const actions = useMemo(
		() => [
			parentAction,
			...genericActions.filter(
				(action) => action.id !== 'actions::collections'
			),
		],
		[genericActions, parentAction]
	);
	return actions;
};
