import { Checkbox, Divider, ScrollArea, Stack } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useInputState } from '@mantine/hooks';
import { Text } from '@repo/foundations';
import { sortBy, upperFirst } from 'lodash-es';
import { useCallback } from 'react';
import { IIntegration, IUser } from '../../../../../api';
import useFuseSearch from '../../../../../hooks/useFuseSearch.ts';
import SearchBox from '../../../../SearchBox/SearchBox.tsx';
import { MultiGroupSelector } from '../../../../Settings/Selectors/MultiGroupSelector.tsx';
import { PreferenceSection } from './PreferenceSection';
import { useStyles } from './PreferencesPanel.styles';

interface QueryPermissionsProps {
	integration: IIntegration;
	activeUsers: IUser[];
	activeAccessGroups: string[];
	form: UseFormReturnType<
		Record<string, boolean | undefined>,
		(
			values: Record<string, boolean | undefined>
		) => Record<string, boolean | undefined>
	>;
	handleUserChange: (field: string, checked: boolean) => void;
	handleGroupAccessChange: (new_groups: string[]) => void;
}

export function QueryPermissions({
	integration,
	activeUsers,
	form,
	handleUserChange,
	activeAccessGroups,
	handleGroupAccessChange,
}: QueryPermissionsProps) {
	const { classes, theme } = useStyles();

	const [searchTerm, setSearchTerm] = useInputState('');

	const filteredUsers = useFuseSearch({
		term: searchTerm,
		items: activeUsers,
		keys: ['email'],
		options: { threshold: 0.3 },
	});

	const handleSearch = useCallback(
		(newSearchTerm: string) => {
			setSearchTerm(newSearchTerm);
		},
		[setSearchTerm]
	);

	return (
		<>
			<Divider />
			<PreferenceSection
				header="Query Permissions"
				subheader={`Allow specific editors or groups to query data for ${upperFirst(integration.type)}.`}
			>
				<Stack>
					<Text size="md">Editors</Text>
					<SearchBox
						variant="tertiary"
						placeholder="Search"
						onSearch={handleSearch}
					/>
					{
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore poorly typed component by Mantine
						<ScrollArea.Autosize mah={theme.other.space[100]} type="auto">
							<Stack className={classes.formWrapper}>
								{sortBy(filteredUsers, 'email').map((user) => (
									<Checkbox
										key={user.id}
										label={user.email}
										checked={form.values[`${user.id}_user`]}
										onChange={(event) => {
											handleUserChange(
												`${user.id}_user`,
												event.currentTarget.checked
											);
										}}
									/>
								))}
							</Stack>
						</ScrollArea.Autosize>
					}
				</Stack>
				<Stack>
					<Text size="md" pt={theme.spacing.md}>
						Groups
					</Text>
					<MultiGroupSelector
						value={activeAccessGroups}
						setValue={handleGroupAccessChange}
					/>
				</Stack>
			</PreferenceSection>
		</>
	);
}
