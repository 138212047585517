/**
 * @fileoverview Dark & light themes for monaco-editor
 *
 * @see https://microsoft.github.io/monaco-editor/playground.html#customizing-the-appearence-tokens-and-colors
 * @see https://github.com/microsoft/vscode/blob/1a58734121fed4606cbb804dc03b286fc7bb9c0a/src/vs/editor/standalone/common/themes.ts
 */

import { MantineTheme } from '@mantine/core';
import type { editor } from 'monaco-editor';
import { rgbToHex } from '../../../../utils/color';

function token(tokenName: string, color: string) {
	return { token: tokenName, foreground: color };
}

export function getMonacoTheme(
	theme: MantineTheme
): editor.IStandaloneThemeData {
	return {
		base: theme.colorScheme === 'dark' ? 'vs-dark' : 'vs',
		colors: {
			'editor.background': rgbToHex(
				theme.other.getColor('surface/secondary/default')
			),
			'editor.foreground': rgbToHex(
				theme.other.getColor('text/primary/default')
			),
			'editorLineNumber.foreground': rgbToHex(
				theme.other.getColor('text/secondary/default')
			),
			'editorLineNumber.activeForeground': rgbToHex(
				theme.other.getColor('text/primary/default')
			),
			'editorCursor.foreground': rgbToHex(
				theme.other.getColor('fill/emphasis/default')
			),
		},
		inherit: false,
		rules: [
			token(
				'attribute.name',
				rgbToHex(theme.other.getColor('text/code/attribute_name'))
			),
			token(
				'attribute.value',
				rgbToHex(theme.other.getColor('text/code/attribute_value'))
			),
			token('comment', rgbToHex(theme.other.getColor('text/code/comment'))),
			token('delimiter', rgbToHex(theme.other.getColor('text/code/delimiter'))),
			token('function', rgbToHex(theme.other.getColor('text/code/function'))),
			token(
				'identifier',
				rgbToHex(theme.other.getColor('text/code/identifier'))
			),
			token(
				'identifier.function',
				rgbToHex(theme.other.getColor('text/code/identifier_function'))
			),
			token('keyword', rgbToHex(theme.other.getColor('text/code/keyword'))),
			token('number', rgbToHex(theme.other.getColor('text/code/number'))),
			token('operator', rgbToHex(theme.other.getColor('text/code/operator'))),
			token('tag', rgbToHex(theme.other.getColor('text/code/tag'))),
			token('type', rgbToHex(theme.other.getColor('text/code/type'))),
			token(
				'type.identifier',
				rgbToHex(theme.other.getColor('text/code/type_identifier'))
			),
			token('string', rgbToHex(theme.other.getColor('text/code/string'))),
			token('predefined', rgbToHex(theme.other.getColor('text/code/operator'))),
		],
	};
}
