/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { createStyles, Stack } from '@mantine/core';
import { useCallback, useEffect } from 'react';
import type { IMetric } from '../../api';
import {
	useAuthUser,
	useMetric,
	useUpdateMetric,
	useWorkspace,
} from '../../api';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';

import { useNavigate } from 'react-router';
import EntityPageLayout from '../../components/EntityPageLayout';
import SqlCharts from '../../components/Sql/SqlCharts';
import { useFullWidthToggle } from '../../hooks/useFullWidthToggle.ts';
import { EntityType } from '../../lib/types';
import { trackEvent } from '../../utils/analytics';
import { isViewerOfEntity } from '../../utils/authorization/roles';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';
import { MetricSidebarSection } from './MetricSidebarSection';

const useStyles = createStyles(
	(theme, { shouldSetFullWidth }: { shouldSetFullWidth: boolean }) => ({
		content: {
			maxWidth: shouldSetFullWidth ? '100%' : theme.other.space[240],
			margin: '0 auto',
		},
	})
);

export default function MetricPage() {
	const id = useParamsIdSuffixUuid();
	const { workspace } = useWorkspace();
	const { user, isViewerOrGuestUser } = useAuthUser();
	const { shouldSetFullWidth } = useFullWidthToggle(id);
	const { classes, theme } = useStyles({ shouldSetFullWidth });
	const navigate = useNavigate();

	useEffect(() => {
		if (workspace.migrated_glossary) {
			trackEvent('metric/redirect_to_glossary', { id }, user, workspace);
			navigate(`/glossary/${id}`, { replace: true });
		}
	}, [id, navigate, user, workspace]);

	const { data: metric } = useMetric({
		id,
		options: {
			useErrorBoundary: true,
			suspense: true,
		},
	});

	useEffect(() => {
		trackEvent('metric/view', {}, user, workspace);
	}, [user, workspace]);

	const { mutateAsync } = useUpdateMetric({});

	const editorOfEntity = metric ? !isViewerOfEntity(user, metric) : false;

	const updateMetric = useCallback(
		async (entries: Record<string, DjangoValueType>) => {
			if (!metric) {
				return;
			}

			trackEvent(
				'metric/update',
				{
					id: metric.id,
				},
				user,
				workspace
			);

			const updated: Partial<IMetric> = Object.entries(entries).reduce(
				(acc, [key, value]) => ({
					...acc,
					[key]: value,
				}),
				{}
			) as Partial<IMetric>;

			await mutateAsync({ data: { id, ...updated } });
		},
		[id, metric, mutateAsync, user, workspace]
	);

	const handleSidebarChange = useCallback(
		async (key: string, value: DjangoValueType) => {
			await updateMetric({ [key]: value });
		},
		[updateMetric]
	);

	const handleCurrySidebarChange = useCallback(
		(key: string) => (value: DjangoValueType) =>
			handleSidebarChange(key, value),
		[handleSidebarChange]
	);

	if (!metric) {
		return null;
	}

	return (
		<EntityPageLayout
			name="Metric"
			withDescription
			isReadOnlyTitle={isViewerOrGuestUser}
			entity={metric}
			updateEntity={handleSidebarChange}
			classNames={{
				content: classes.content,
			}}
			withMetricSection={
				<MetricSidebarSection
					metric={metric}
					onChange={handleCurrySidebarChange}
				/>
			}
			{...getEntityPageSidebarProps(EntityType.metric)}
		>
			<Stack mb={theme.other.space[32]}>
				<SqlCharts
					metric={metric}
					canEdit={editorOfEntity}
					onChange={updateMetric}
				/>
			</Stack>
		</EntityPageLayout>
	);
}
