import {
	Card,
	createStyles,
	Grid,
	Group,
	Overlay,
	Stack,
	Tooltip,
} from '@mantine/core';
import {
	useRetrieveAggregateQuality,
	useRetrieveTimeseries,
} from '@repo/api-codegen';
import { formatScore } from '@repo/common/utils/dataQuality';
import { Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { memo } from 'react';
import { IMetricWidget, IReport } from '../../api';
import { DataQualityTimeseriesChartLoading } from '../../components/DataQualityScore/DataQualityTimeseriesChart/DataQualityTimeseriesChartLoading';
import { DataQualityTimeseriesChartPreview } from '../../components/DataQualityScore/DataQualityTimeseriesChart/DataQualityTimeseriesChartPreview';
import { QualityScoreRing } from '../../components/DataQualityScore/QualityScoreRing';
import { SecodaMinimalLogo } from '../../components/SecodaMinimalLogo';
import { UserAvatar } from '../../components/UserAvatar';
import { MetricWidgetContent } from '../AnalyticsPage/components/MetricWidgetContent';
import {
	PREVIEW_AREA_HEIGHT,
	WIDGET_PREVIEW_HEIGHT,
	WIDGET_SIZE_MAP,
} from '../AnalyticsPage/constants';

interface IReportCardRendererProps {
	data: IReport;
}

const useStyles = createStyles((theme) => ({
	badge: {
		width: 'fit-content',
		margin: 0,
		padding: theme.spacing.sm,
	},
	preview: {
		height: `${PREVIEW_AREA_HEIGHT}px`,
		borderRadius: theme.radius.md,
		overflowY: 'hidden',
	},
	widget: {
		minHeight: `${WIDGET_PREVIEW_HEIGHT}px`,
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		borderRadius: theme.radius.md,
	},
}));

function ReportCardRenderer({ data }: IReportCardRendererProps) {
	const { classes, theme } = useStyles();

	const { data: dqs } = useRetrieveAggregateQuality(
		{},
		{ enabled: data.type === 'data_quality' }
	);
	const { data: timeseries, isLoading: isTimeseriesLoading } =
		useRetrieveTimeseries(
			{
				queryParams: {
					team_id: '',
					from_date: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
					to_date: dayjs().format('YYYY-MM-DD'),
				},
			},
			{
				enabled: data.type === 'data_quality',
				select: (response: { datapoints: any[] }) =>
					response.datapoints.map((datapoint) => ({
						date: dayjs(datapoint.timestamp).toDate(),
						score: formatScore(datapoint.total),
					})),
			}
		);

	const ownerLogo = data.owner ? (
		<UserAvatar
			size="xxs"
			user={data.owner}
			tooltip={`Created by  ${data.owner.display_name}`}
		/>
	) : (
		<Tooltip position="top" label="Created by Secoda">
			<SecodaMinimalLogo width="16" height="16" />
		</Tooltip>
	);

	return (
		<Stack pb={theme.spacing.sm}>
			<Card className={classes.preview}>
				{data.type === 'data_quality' && (
					<Grid gutter="xs">
						<Grid.Col span="content">
							<Card className={classes.widget}>
								<QualityScoreRing
									size="sm"
									score={dqs?.total || 0}
									displayBadge={false}
								/>
							</Card>
						</Grid.Col>
						<Grid.Col span="auto">
							<Card className={classes.widget}>
								{isTimeseriesLoading || !timeseries ? (
									<DataQualityTimeseriesChartLoading
										width="100%"
										height={WIDGET_PREVIEW_HEIGHT}
									/>
								) : (
									<DataQualityTimeseriesChartPreview
										data={timeseries}
										width="100%"
										height={WIDGET_PREVIEW_HEIGHT}
									/>
								)}
							</Card>
						</Grid.Col>
					</Grid>
				)}
				{data.type === 'analytics' && (
					<Grid gutter="xs">
						{data.widgets.map((widget) => (
							<Grid.Col
								key={widget.id}
								span={Number(WIDGET_SIZE_MAP[widget.size])}
							>
								<Card className={classes.widget}>
									<MetricWidgetContent
										source="preview"
										metricWidget={widget as IMetricWidget}
									/>
								</Card>
								<Overlay
									className={classes.preview}
									gradient="linear-gradient(to top, rgba(256, 256, 256, 0.15) 0%, rgba(256, 256, 256, 0) 30%)"
								/>
							</Grid.Col>
						))}
					</Grid>
				)}
			</Card>
			<Group position="apart" pt="sm" pl="sm" pr="xs">
				<Text size="sm" weight="bold">
					{data.name}
				</Text>
				{ownerLogo}
			</Group>
		</Stack>
	);
}

export default memo(ReportCardRenderer);
