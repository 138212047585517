import { createStyles, MantineTheme } from '@mantine/core';

export const usePropertyStyles = createStyles((theme: MantineTheme) => ({
	leftCol: {
		width: theme.other.space[32],
		padding: `${theme.spacing['3xs']} 0`,
		flexShrink: 0,
	},
	leftColForceInline: {
		flex: 10000000,
	},
	leftColWide: {
		flexBasis: theme.other.space[40],
	},
	rightCol: {
		flexGrow: 1,
		alignSelf: 'stretch',
	},
	label: {
		fontSize: theme.other.typography.text.sm,
		fontWeight: theme.other.typography.weight.regular,
		lineHeight: theme.other.typography.lineHeight.text.sm,
		color: theme.other.getColor('text/secondary/default'),
	},
}));
