import { UseFloatingReturn, UseInteractionsReturn } from '@floating-ui/react';
import { createContext, useContext } from 'react';

export interface ListBoxContextType {
	activeIndex: number | null;
	setActiveIndex: (index: number | null) => void;
	getItemProps: UseInteractionsReturn['getItemProps'];
	getReferenceProps: UseInteractionsReturn['getReferenceProps'];
	getFloatingProps: UseInteractionsReturn['getFloatingProps'];
	refs: UseFloatingReturn['refs'];
	targetId: string;
	dropdownId: string;
	opened: boolean;
	floatingStyles: UseFloatingReturn['floatingStyles'];
	floatingContext: UseFloatingReturn['context'];
	onKeyDown?: (event: React.KeyboardEvent<HTMLElement> | KeyboardEvent) => void;
	listRef: React.MutableRefObject<Array<HTMLButtonElement | null>>;
	disabled?: boolean;
}

export const ListBoxContext = createContext<ListBoxContextType>(
	{} as ListBoxContextType
);

export function useListBoxContext() {
	return useContext(ListBoxContext);
}
