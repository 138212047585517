import { Stack } from '@mantine/core';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import type { IReport } from '../../api';
import { useReportList } from '../../api';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import { EmptyState } from '../../components/EmptyState';
import { UpgradeButton } from '../../components/Settings/UpgradeButton';
import { TableV2 } from '../../components/TableV2';
import { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import { useActions, useColumns } from './AnalyticsListPage.hooks';

function AnalyticsListPage() {
	const { classes } = useStyles();

	const navigate = useNavigate();

	const columns = useColumns();
	const actions = useActions();
	const { analyticsAccess } = useFeatureAccess();

	const handleClick = useCallback(
		(id: string) => {
			navigate(`/analytics/${id}`);
		},
		[navigate]
	);

	if (!analyticsAccess) {
		return (
			<EmptyState
				illustrationName="upgrade"
				title="Upgrade to access Analytics"
				description="Understand workspace usage, important KPIs, and more with analytics."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
				withActions={
					<UpgradeButton
						tooltip="Upgrade to access Analytics"
						feature="Analytics"
						size="md"
					/>
				}
			/>
		);
	}

	return (
		<Stack className={classes.wrapper}>
			<TableV2<IReport>
				columnVisibility={{
					catalogServerType: 'reports',
					catalogType: 'report',
				}}
				pluralTypeString="reports"
				withCheckbox={true}
				withInteractiveHeader
				withSearch
				withFilters={false}
				defaultSort={null}
				withCsvExport
				columns={columns}
				withActions={actions}
				withQuickActions={['actions::delete']}
				usePaginationList={useReportList}
				defaultRequiredSearchParams={{
					type: 'analytics',
				}}
				onCellClick={(cell: {
					column: ExtendedDataTableColumn<IReport>;
					record: { id: string };
				}) => {
					if (cell.column.navigate !== false) {
						handleClick(cell.record.id);
					}
				}}
			/>
		</Stack>
	);
}

export default AnalyticsListPage;
