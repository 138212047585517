import { IIntegration } from '../../../../../api';
import type { IntegrationSpec } from '../../../../../interfaces/IntegrationSpec';
import { BuiltInConnectionStep } from './BuiltInConnectionStep';
import { MarketplaceConnectionStep } from './MarketplaceConnectionStep';

interface ConnectionStepProps {
	integrationSpec: IntegrationSpec;
	integration?: IIntegration;
	setIntegration?: (integration: IIntegration) => void;
	nextStep?: () => void;
	hideTitle?: boolean;
}

export function ConnectionStep(props: ConnectionStepProps) {
	const { integrationSpec, ...others } = props;

	if (integrationSpec.type === 'builtin') {
		return <BuiltInConnectionStep spec={integrationSpec.value} {...others} />;
	}

	return <MarketplaceConnectionStep spec={integrationSpec.value} {...others} />;
}
