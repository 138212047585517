import type { AnyObjectSchema } from 'yup';
import * as Yup from 'yup';
import { DOCS_INTEGRATION_BASE } from './integrations.constants';
import { CERTIFICATE_PLACEHOLDER } from './integrations.utils';

export const integrationSchemas: Record<string, AnyObjectSchema> = {
	airflow: Yup.object().shape({
		_group: Yup.string().oneOf(['Astronomer', 'API', 'Plugin']).default('API'),
		host: Yup.string()
			.optional()
			.meta({
				groups: ['Astronomer', 'API'],
				description:
					'Secoda uses the Airflow REST API to ingest metadata which is only available with v2.1+. Ensure basic_auth is enabled for your instance in airflow.cfg',
			}),
		api_key: Yup.string()
			.optional()
			.meta({
				htmlType: 'password',
				groups: ['Astronomer'],
			}),
		user: Yup.string()
			.optional()
			.meta({
				groups: ['API'],
			}),
		password: Yup.string()
			.optional()
			.meta({
				htmlType: 'password',
				groups: ['API'],
			}),
	}),
	amplitude: Yup.object().shape({
		api_key: Yup.string().required('Required').meta({
			label: 'API key',
			description:
				'An API Key can be found in your settings panel on Amplitude',
		}),
		api_secret: Yup.string().required('Required').meta({
			label: 'API secret',
			description:
				'An API Secret (Secret Key) can be found in your settings panel on Amplitude',
		}),
		host: Yup.string().required('Optional').meta({
			label: 'Host',
			description:
				'Should be either amplitude.com (standard) or analytics.eu.amplitude.com (for EU)',
		}),
	}),
	athena: Yup.object().shape({}),
	bigquery: Yup.object().shape({
		_group: Yup.string().oneOf(['Credentials', 'OAuth']).default('Credentials'),
		project: Yup.string()
			.required()
			.meta({
				groups: ['Credentials', 'OAuth'],
				description:
					'The Google Cloud project where your BigQuery database resides.',
			}),
		project_location: Yup.string()
			.optional()
			.meta({
				groups: ['Credentials', 'OAuth'],
				label: 'Location',
				description:
					'The location of your BigQuery data. If left blank, it will be automatically detected.',
			}),
		credentials: Yup.string()
			.required()
			.meta({
				htmlType: 'password',
				groups: ['Credentials'],
				description:
					'Create a service account with the permissions in the docs and upload the JSON file.',
			}),
	}),
	census: Yup.object().shape({
		host: Yup.string().required('Required').meta({
			description:
				'The host of your Census instance. Options can be found https://developers.getcensus.com/api-reference/introduction/overview#base-urls',
		}),
		api_key: Yup.string().required('Required').meta({
			label: 'API key',
			htmlType: 'password',
			description:
				'The API key for your Census account. Documentation can be found at https://developers.getcensus.com/api-reference/introduction/authorization#using-bearer-tokens-with-workspace-apis',
		}),
	}),
	cluvio: Yup.object().shape({
		user: Yup.string().required('Required'),
		password: Yup.string().required('Required').meta({
			htmlType: 'password',
		}),
	}),
	coalesce: Yup.object().shape({
		host: Yup.string().required('Required').meta({
			description:
				'The URL of your Coalesce organization. For example, https://app.coalescesoftware.io',
		}),
		api_key: Yup.string().required('Required').meta({
			htmlType: 'password',
			label: 'API key',
		}),
		environment_id: Yup.string().required('Required').meta({
			description: 'The ID of your Deploy Environment.',
		}),
	}),
	confluence: Yup.object().shape({
		domain_name: Yup.string()
			.required('Required')
			.meta({
				label: 'Domain name',
				description:
					'The domain name of your Confluence instance. Enter it in the form {domain-name}.atlassian.net',
			})
			.matches(
				/^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]+[a-zA-Z0-9-_]*$/,
				'Invalid domain name'
			),
		user_email: Yup.string().required('Required').meta({
			label: 'User email',
			description: 'All spaces visible to this user will be imported',
		}),
		api_token: Yup.string().required('Required').meta({
			htmlType: 'password',
			label: 'API token',
		}),
	}),
	cosmos: Yup.object().shape({
		_group: Yup.string().oneOf(['NoSQL', 'Tables']).default('NoSQL'),
		endpoint: Yup.string().meta({
			description: 'The endpoint of your Azure Cosmos DB instance',
			groups: ['NoSQL'],
		}),
		key: Yup.string().meta({
			htmlType: 'password',
			groups: ['NoSQL'],
		}),
		connection_string: Yup.string().meta({
			htmlType: 'password',
			groups: ['Tables'],
		}),
		ssh_tunnel: Yup.string()
			.optional()
			.meta({
				groups: ['NoSQL', 'Tables'],
			}),
	}),
	cyera: Yup.object().shape({
		client_id: Yup.string().required('Required').meta({
			htmlType: 'text',
			label: 'Client ID',
		}),
		secret_key: Yup.string().required('Required').meta({
			htmlType: 'password',
			label: 'Secret key',
		}),
	}),
	dagster: Yup.object().shape({
		webserver: Yup.string()
			.required('Required')
			.meta({
				htmlType: 'text',
				description:
					'Your link in the form of: "https://server-address", including the deployment name if using dagster cloud',
			})
			.matches(
				/^https?:\/\/[^\s/$?#]+(?:\/[A-Za-z0-9\-_]+)?$/,
				"Must be in format 'https://{your-server}', and can only contain letters, numbers, periods, underscores, and hyphens"
			),
		api_key: Yup.string().meta({
			label: 'User token',
			description: 'Token only needed for verification if using Dagster cloud',
			htmlType: 'password',
		}),
	}),
	databricks: Yup.object().shape({
		host: Yup.string().required('Required').meta({
			description:
				'The URL of your Databricks instance. For example, https://{instance-id}.cloud.databricks.com.',
		}),
		databricks_workspace_id: Yup.string().required('Required').meta({
			label: 'Workspace ID',
			description:
				'The numerical id of your workspace, located in the url of your Databricks instance, after the "/?o=". https://{instance-id}.cloud.databricks.com/?o={workspace-id}',
		}),
		access_token: Yup.string().required('Required').meta({
			htmlType: 'password',
			description:
				'Generate a personal access token from your Databricks account settings.',
		}),
		warehouse_id: Yup.string().optional().meta({
			description:
				'The ID of the warehouse you want to connect to. If not provided, the default warehouse will be used.',
		}),
		cluster_id: Yup.string().optional().meta({
			description:
				'The ID of the cluster you want to connect to. If not provided, the default cluster will be used.',
		}),
	}),
	dataplex: Yup.object().shape({
		project: Yup.string().required('Required').meta({
			description:
				'The Google Cloud project where your Dataplex instance resides.',
		}),
		credentials: Yup.string().required('Required').meta({
			htmlType: 'password',
			description:
				'Create a service account with the permissions in the docs and upload the JSON file.',
		}),
	}),
	dbt: Yup.object().shape({
		api_base: Yup.string()
			.required('Required')
			.meta({
				label: 'Base URL',
				description:
					'Base URL will vary depending on region, https://cloud.getdbt.com or https://{account prefix}.us1.dbt.com for US, https://emea.dbt.com for EU, and https://au.dbt.com for AU.',
				htmlType: 'text',
			})
			.matches(
				/^https:\/\/(cloud\.getdbt\.com|emea\.dbt\.com|au\.dbt\.com|[A-Za-z0-9]+\.us1\.dbt\.com)$/,
				'Must be one of the following: https://cloud.getdbt.com, https://emea.dbt.com, https://au.dbt.com, or https://{account prefix}.us1.dbt.com'
			)
			.default('https://cloud.getdbt.com'),
		account_id: Yup.string().required('Required').meta({
			description:
				'For the url https://cloud.getdbt.com/#/accounts/12631/projects/28649/dashboard, the account ID is 12631',
		}),
		api_key: Yup.string().required('Required').meta({
			label: 'API key',
			htmlType: 'password',
			description:
				'Ensure the dbt API is enabled for your project and use the API key in the Profile settings',
		}),
	}),
	dbt_core: Yup.object().shape({
		role_arn: Yup.string().optional().meta({
			label: 'AWS role ARN',
			group: 'S3 Role',
		}),
		external_id: Yup.string().optional().meta({
			label: 'External ID',
			description:
				'On the AWS cross account role check "Require external ID" and copy this value into the field',
			group: 'S3 Role',
			disabled: true,
		}),
		region: Yup.string()
			.optional()
			.meta({
				description: 'The AWS or S3-compatible region',
				groups: ['S3 Access Key', 'S3 Role'],
			}),
		connection_string: Yup.string()
			.optional()
			.meta({
				description:
					'This is the connection string in this format DefaultEndpointsProtocol=https;AccountName=storagesample;AccountKey=<account-key>',
				groups: ['Azure Blob Storage'],
			}),
		container_name: Yup.string()
			.optional()
			.meta({
				description:
					'This is the name of the container in your Azure Blob Storage account.',
				groups: ['Azure Blob Storage'],
			}),
		bucket_name: Yup.string()
			.optional()
			.meta({ groups: ['S3 Access Key', 'S3 Role'] }),
		access_key_id: Yup.string().optional().meta({
			label: 'Access key ID',
			group: 'S3 Access Key',
			htmlType: 'password',
			description:
				'Only required if this instance does not have implicit role access to the bucket',
		}),
		secret_access_key: Yup.string().optional().meta({
			htmlType: 'password',
			description:
				'Requires the following S3-compatible permissions: s3:GetObject, s3:PutObject, s3:PutObjectAcl, s3:ListBucket',
			group: 'S3 Access Key',
		}),
		s3_endpoint: Yup.string()
			.optional()
			.matches(
				/https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
				'Must start with https and be a valid url'
			)
			.meta({
				group: 'S3 Access Key',
				description:
					'(Optional) The S3-compatible endpoint for this bucket. Leave blank if using AWS. If using GCP, this value should be https://storage.googleapis.com',
			}),
		manifest_file: Yup.string().optional().meta({
			htmlType: 'file',
			secodaFileType: 'manifest',
			description:
				'Upload the manifest file directly instead of inputting bucket credentials',
			group: 'File upload',
		}),
		run_results_file: Yup.string().optional().meta({
			htmlType: 'file',
			secodaFileType: 'run_results',
			description: 'Upload the run results file directly',
			group: 'File upload',
		}),
	}),
	fivetran: Yup.object().shape({
		api_key: Yup.string().required('Required').meta({
			label: 'API key',
			htmlType: 'password',
			description:
				'An API Key can be generated from your settings panel on Fivetran',
		}),
		api_secret: Yup.string().required('Required').meta({
			label: 'API secret',
			htmlType: 'password',
			description:
				'An API Secret can be generated from your settings panel on Fivetran',
		}),
	}),
	git: Yup.object().shape({
		repo_url: Yup.string().required('Required').meta({
			label: 'Repo URL',
			description: 'https URL to your git repository',
		}),
		git_username: Yup.string().required('Required').meta({
			label: 'git username',
			description: 'Your git username',
		}),
		git_password: Yup.string().required('Required').meta({
			label: 'git password',
			type: 'password',
			htmlType: 'password',
			description: 'Your git password',
		}),
		branch: Yup.string()
			.meta({
				description:
					'The branch to pull from i.e. `master` or `main` (default: `main`)',
			})
			.default('main'),
	}),
	glue: Yup.object().shape({
		_group: Yup.string().oneOf(['Role', 'Access Key']).default('Role'),
		role_region: Yup.string().optional().meta({
			label: 'AWS region',
			group: 'Role',
		}),
		role_arn: Yup.string().optional().meta({
			label: 'AWS role ARN',
			group: 'Role',
		}),
		role_external_id: Yup.string().optional().meta({
			label: 'External ID',
			description:
				'On the AWS cross account role check "Require external ID" and copy this value into the field',
			group: 'Role',
			disabled: true,
		}),
		role_query_result_location: Yup.string().optional().meta({
			label: 'Query result location',
			description:
				'The S3 location where query results will be stored, i.e, s3://secoda/glue/query_results',
			group: 'Role',
		}),
		region: Yup.string().optional().meta({
			label: 'AWS region',
			group: 'Access Key',
		}),
		access_key_id: Yup.string().optional().meta({
			label: 'Access key ID',
			htmlType: 'password',
			group: 'Access Key',
		}),
		secret_access_key: Yup.string()
			.optional()
			.meta({ htmlType: 'password', group: 'Access Key' }),
		query_result_location: Yup.string().optional().meta({
			description:
				'The S3 location where query results will be stored, i.e, s3://secoda/glue/query_results',
			group: 'Access Key',
		}),
		workgroup: Yup.string()
			.optional()
			.meta({
				groups: ['Role', 'Access Key'],
			}),
		// TODO: add dropdown menu to IntegrationForm
		encryption_option: Yup.string()
			.oneOf(
				['SSE_S3', 'SSE_KMS', 'CSE_KMS'],
				'Encryption option must be one of SSE_S3, SSE_KMS, or CSE_KMS'
			)
			.optional()
			.meta({
				description: 'Must be either SSE_S3, SSE_KMS, or CSE_KMS',
				groups: ['Role', 'Access Key'],
			}),
		kms_key: Yup.string()
			.optional()
			.meta({
				groups: ['Role', 'Access Key'],
				htmlType: 'password',
			})
			.when('encryption_option', {
				is: (value: string) => value === 'SSE_KMS' || value === 'CSE_KMS',
				then: Yup.string().required('Required'),
			}),
	}),
	looker_studio: Yup.object().shape({
		_group: Yup.string().oneOf(['Cookie', 'OAuth']).default('Cookie'),
		cookie: Yup.string().optional().meta({
			htmlType: 'password',
			group: 'Cookie', // Marking this as "option", shows a segmented form with multiple options.
			description: 'Cookie obtained from the Looker Studio App.',
		}),
	}),
	great_expectations: Yup.object().shape({
		project: Yup.string().meta({
			description:
				'The Google Cloud project where your Google Cloud Storage bucket resides.',
			group: 'GCS',
		}),
		bucket: Yup.string().meta({
			description:
				'The name of the bucket where Great Expectation stores the Expectation and Validation files.',
			group: 'GCS',
		}),
		validations_store_prefix: Yup.string().meta({
			description: 'The name of the folder where Validation files are stored.',
			group: 'GCS',
		}),
		credentials: Yup.string().meta({
			htmlType: 'password',
			description:
				'The credentials (JSON) that Secoda will use to load pull Expectation and Validation files. Create a service account just for Secoda to use.',
			group: 'GCS',
		}),
		configuration_file: Yup.string().meta({
			htmlType: 'file',
			group: 'GCS',
			description:
				'The project config which is typically named great_expectations.yml',
		}),
		region: Yup.string()
			.optional()
			.meta({ description: 'The AWS region', group: 'AWS Key' }),
		s3_bucket: Yup.string().optional().meta({ group: 'AWS Key' }),
		s3_validations_store_prefix: Yup.string().meta({
			description: 'The name of the folder where Validation files are stored.',
			group: 'AWS Key',
		}),
		access_key_id: Yup.string().optional().meta({
			group: 'AWS Key',
			htmlType: 'password',
			description:
				'Only required if this instance does not have implicit role access to the bucket',
		}),
		secret_access_key: Yup.string().optional().meta({
			htmlType: 'password',
			description:
				'Requires the following permissions: s3:GetObject, s3:PutObject, s3:PutObjectAcl, s3:ListBucket',
			group: 'AWS Key',
		}),
		s3_configuration_file: Yup.string().meta({
			htmlType: 'file',
			group: 'AWS Key',
			description:
				'The project config which is typically named great_expectations.yml',
		}),
		role_region: Yup.string().optional().meta({
			label: 'AWS region',
			group: 'AWS Role',
		}),
		role_arn: Yup.string().optional().meta({
			label: 'AWS role ARN',
			group: 'AWS Role',
		}),
		role_bucket: Yup.string().optional().meta({
			label: 'AWS bucket',
			group: 'AWS Role',
		}),
		role_validations_store_prefix: Yup.string().meta({
			label: 'Validations store prefix',
			description: 'The name of the folder where Validation files are stored.',
			group: 'AWS Role',
		}),
		role_external_id: Yup.string().optional().meta({
			label: 'External ID',
			description:
				'On the AWS cross account role check "Require external ID" and copy this value into the field',
			group: 'AWS Role',
			disabled: true,
		}),
		role_configuration_file: Yup.string().meta({
			htmlType: 'file',
			group: 'AWS Role',
			description:
				'The project config which is typically named great_expectations.yml',
		}),
	}),
	hashboard: Yup.object().shape({
		project_id: Yup.string().required('Required').meta({
			label: 'Project ID',
			description: 'The ID of your project in hashboard',
		}),
		api_token: Yup.string().required('Required').meta({
			htmlType: 'password',
			label: 'API token',
			description: 'The API token for your hashboard account',
		}),
	}),
	hex: Yup.object().shape({
		workspace_id: Yup.string().optional().meta({
			label: 'Workspace ID',
			description: 'The ID of your workspace in Hex',
		}),
		access_token: Yup.string().required('Required').meta({
			htmlType: 'password',
			label: 'Access token',
			description: 'The Personal/Workspace access token for your Hex account',
		}),
	}),
	hightouch: Yup.object().shape(
		{
			api_key: Yup.string().required('Required').meta({
				label: 'API key',
				htmlType: 'password',
				description: 'The secret API key for your HighTouch account',
			}),
		},
		[['cookie', 'api_key']]
	),
	hive: Yup.object().shape({
		_group: Yup.string().oneOf(['Direct', 'MySQL']).default('Direct'),

		host: Yup.string()
			.required('Required')
			.meta({
				description: 'The host of your Hive or metastore instance.',
				groups: ['Direct', 'MySQL'],
			}),
		database: Yup.string()
			.required('Required')
			.meta({
				description: 'Metastore database name.',
				groups: ['MySQL'],
			}),
		port: Yup.string()
			.required('Required')
			.meta({
				htmlType: 'port',
				groups: ['Direct', 'MySQL'],
				description:
					'The port of your Hive or metastore instance. The default port is 10000.',
			}),
		user: Yup.string()
			.optional()
			.meta({
				label: 'User',
				groups: ['Direct', 'MySQL'],
				description:
					'The user for your Hive instance or metastore. This user must have access to the database you are connecting to.',
			}),
		password: Yup.string()
			.optional()
			.meta({
				htmlType: 'password',
				groups: ['Direct', 'MySQL'],
				description:
					'The password for your Hive instance or metastore. This user must have access to the database you are connecting to.',
			}),
		ssh_tunnel: Yup.string()
			.optional()
			.meta({
				groups: ['Direct', 'MySQL'],
			}),
	}),
	jira: Yup.object().shape({
		project_url: Yup.string()
			.required('Required')
			.meta({
				htmlType: 'text',
				description:
					'Your link in the form of: "https://your-domain.atlassian.net/"',
			})
			.matches(
				/https:\/\/[^.]+\.atlassian\.net\//,
				"Must be in format 'https://{your-domain}.atlassian.net/'"
			),
		project_name: Yup.string().required('Required').meta({
			htmlType: 'text',
			description: 'Enter the name of your Jira project',
		}),
		project_key: Yup.string().required('Required').meta({
			htmlType: 'text',
			description: 'Enter the key of your Jira project',
		}),
		jira_api_token: Yup.string().required('Required').meta({
			label: 'API token',
			description: 'Enter your Jira API token',
			htmlType: 'password',
		}),
		account: Yup.string().required('Required').meta({
			label: 'Account email',
			htmlType: 'text',
		}),
		org_id: Yup.string().meta({
			label: 'Organization ID',
			description: 'Enter your Atlassian Organization ID',
			htmlType: 'text',
		}),
		org_admin_api_key: Yup.string().meta({
			label: 'Organization admin key',
			description: 'Enter your Atlassian Organization Admin API key',
			htmlType: 'password',
		}),
		jql_filter: Yup.string().meta({
			label: 'Issues filter',
			description: 'Enter your issues filter in JQL format',
			htmlType: 'text',
		}),
		webhook_secret: Yup.string().meta({
			label: 'Webhook secret',
			description: 'Enter the secret token for your Jira webhook',
			htmlType: 'password',
		}),
	}),
	lightdash: Yup.object().shape({
		host: Yup.string().required('Required').meta({
			description: 'For example https://lightdash.secoda.co.',
		}),
		access_token: Yup.string().required('Required').meta({
			label: 'Access token',
			htmlType: 'password',
			description:
				'Create an access token following this guide https://docs.lightdash.com/references/personal_tokens.',
		}),
	}),
	looker: Yup.object().shape({
		host: Yup.string()
			.required('Required')
			.meta({
				description:
					'The host parameter matches your Looker site url, similar to https://{your-domain}.cloud.looker.com',
			})
			.meta({ htmlType: 'text' }),
		client_id: Yup.string().required('Required').meta({
			label: 'Client ID',
			htmlType: 'password',
			description:
				'Create API3 credentials in the users page of the Administrator section on the Looker instance',
		}),
		client_secret: Yup.string()
			.required('Required')
			.meta({ htmlType: 'password' }),
	}),
	pagerduty: Yup.object().shape({
		routing_key: Yup.string().required('Required').meta({
			label: 'Integration key',
			htmlType: 'password',
		}),
	}),
	matillion: Yup.object().shape({
		host: Yup.string().required('Required').meta({
			description: 'The URL of your Matillion instance.',
			placeholder: 'http://18.212.240.164/',
		}),
		user: Yup.string().required('Required').meta({
			description: 'The username of your Matillion account.',
		}),
		password: Yup.string().required('Required').meta({
			htmlType: 'password',
			description: 'The password of your Matillion account.',
		}),
	}),
	metabase: Yup.object().shape(
		{
			host: Yup.string().required('Required').meta({
				description:
					'The host should appear as https://company.metabaseapp.com',
				htmlType: 'text',
			}),
			user: Yup.string().meta({
				description: 'Connect using the username of a Metabase administrator',
			}),
			password: Yup.string().meta({
				htmlType: 'password',
				description: 'Connect using the password of a Metabase administrator',
			}),
			session_id: Yup.string().meta({
				label: 'Session ID',
				description:
					'This field is optional and can be used instead of a password',
			}),
			set_google_authorization_header: Yup.boolean().meta({
				toggleController: 'set_google_authorization_header',
				label: 'Require Google authentication',
			}),
			google_service_account_client_email: Yup.string().optional().meta({
				toggleGroup: 'set_google_authorization_header',
				label: 'Client email',
				description:
					'The Google Cloud service account client email to connect to the service account',
			}),
			google_service_account_token_uri: Yup.string().optional().meta({
				toggleGroup: 'set_google_authorization_header',
				label: 'Token URI',
				description:
					'The Google Cloud service account token uri to connect to the service account',
			}),
			google_service_account_private_key: Yup.string().optional().meta({
				toggleGroup: 'set_google_authorization_header',
				label: 'Private key',
				description:
					'The Google Cloud service account private key to connect to the service account',
			}),
			google_cloud_platform_audience: Yup.string().optional().meta({
				toggleGroup: 'set_google_authorization_header',
				label: 'Audience',
				description:
					'The client ID of the Google Identity Aware Proxy to connect to the service account',
			}),
			ssh_tunnel: Yup.string().optional(),
		},
		[
			[
				'set_google_authorization_header',
				'google_service_account_client_email',
			],
			['set_google_authorization_header', 'google_service_account_token_uri'],
			['set_google_authorization_header', 'google_service_account_private_key'],
			['set_google_authorization_header', 'google_cloud_platform_audience'],
		]
	),
	mixpanel: Yup.object().shape({
		username: Yup.string().required('Required').meta({
			htmlType: 'text',
		}),
		secret: Yup.string().required('Required').meta({
			htmlType: 'password',
		}),
	}),
	mode: Yup.object().shape({
		organization: Yup.string().required('Required'),
		access_token: Yup.string().required('Required').meta({
			description: 'API Access Token created in Mode Settings',
			htmlType: 'password',
		}),
		password: Yup.string().required('Required').meta({
			htmlType: 'password',
		}),
	}),
	motherduck: Yup.object().shape({
		access_token: Yup.string().required('Required').meta({
			label: 'Access token',
			htmlType: 'password',
			description:
				'Access tokens can be generated at https://app.motherduck.com/settings/general',
		}),
	}),
	mongodb: Yup.object().shape({
		_group: Yup.string()
			.oneOf(['Password', 'Certificate', 'Password and Certificate'])
			.default('Password'),
		uri: Yup.string()
			.required('Required')
			.meta({
				groups: ['Password', 'Password and Certificate'],
				htmlType: 'password',
				label: 'URI',
				description: 'The MongoDB connection string with credentials',
			}),
		tls_uri: Yup.string()
			.required('Required')
			.meta({
				groups: ['Certificate', 'Password and Certificate'],
				htmlType: 'text',
				label: 'URI',
				description: 'The MongoDB connection string',
			}),
		pem_bundle: Yup.string()
			.optional()
			.meta({
				groups: ['Certificate', 'Password and Certificate'],
				label: 'PEM bundle',
				htmlType: 'textarea',
				description: 'MongoDB PEM bundle',
				placeholder: CERTIFICATE_PLACEHOLDER,
			}),
		cluster_name: Yup.string()
			.required('Required')
			.meta({
				groups: ['Password', 'Certificate', 'Password and Certificate'],
				htmlType: 'text',
				description: 'The name of the MongoDB cluster',
				label: 'Cluster Name',
			}),
		example_ids: Yup.string()
			.optional()
			.meta({
				groups: ['Password', 'Certificate', 'Password and Certificate'],
				htmlType: 'text',
				description:
					'Comma separated list of example document Ids to use to extract fields in a collection. Maximum: 10 (Optional)',
				label: 'Example IDs',
			}),
		disable_stable_api: Yup.boolean()
			.optional()
			.meta({
				groups: ['Password', 'Certificate', 'Password and Certificate'],
				label: 'Disable Stable API',
				toggleController: 'disable_stable_api',
				description:
					'Disable Stable API that is offered by MongoDB verion 5.0+.',
			}),
		ssh_tunnel: Yup.string()
			.optional()
			.meta({
				groups: ['Password', 'Certificate', 'Password and Certificate'],
			}),
	}),
	monte_carlo: Yup.object().shape({
		api_key_id: Yup.string().required('Required').meta({
			label: 'API key ID',
		}),
		api_key_secret: Yup.string().required('Required').meta({
			label: 'API key secret',
			htmlType: 'password',
		}),
	}),
	ms_teams: Yup.object().shape({
		incoming_webhook_url: Yup.string().required('Required').meta({
			description:
				'Enter the incoming webhook url the Microsoft Teams channel you want to send notification to',
			htmlType: 'password',
		}),
	}),
	mssql: Yup.object().shape({
		_group: Yup.string()
			.oneOf(['Direct', 'Active Directory'])
			.default('Direct'),
		host: Yup.string()
			.required('Required')
			.meta({
				groups: ['Direct', 'Active Directory'],
			}),
		port: Yup.number()
			.required('Required')
			.default(1433)
			.meta({
				groups: ['Direct', 'Active Directory'],
			}),
		user: Yup.string()
			.required('Required')
			.meta({
				groups: ['Direct', 'Active Directory'],
			}),
		database: Yup.string()
			.required('Required')
			.meta({
				groups: ['Direct', 'Active Directory'],
			}),
		password: Yup.string()
			.required('Required')
			.meta({
				htmlType: 'password',
				groups: ['Direct', 'Active Directory'],
			}),
		ssh_tunnel: Yup.string()
			.optional()
			.meta({
				groups: ['Direct', 'Active Directory'],
			}),
		legacy_authentication: Yup.bool()
			.default(true)
			.meta({
				groups: ['Active Directory'],
				label: 'Legacy authentication',
				toggleController: 'legacy_authentication',
				description:
					'SQL Server 2017 and older may require legacy authentication.',
			}),
	}),
	mysql: Yup.object().shape({
		host: Yup.string().required('Required'),
		port: Yup.number().required('Required').default(3306),
		database: Yup.string().required('Required'),
		user: Yup.string().required('Required'),
		password: Yup.string().required('Required').meta({
			htmlType: 'password',
		}),
		ssl: Yup.boolean()
			.optional()
			.meta({ toggleController: 'ssl', label: 'SSL (Optional)' }),
		client_key: Yup.string().optional().meta({
			toggleGroup: 'ssl',
			htmlType: 'textarea',
			description: 'Proof that the client certificate is sent by the owner.',
			placeholder: CERTIFICATE_PLACEHOLDER,
		}),
		client_certificate: Yup.string().optional().meta({
			toggleGroup: 'ssl',
			htmlType: 'textarea',
			description: 'Sent to the MySQL server to prove the client identity.',
			placeholder: CERTIFICATE_PLACEHOLDER,
		}),
		certificate_authority: Yup.string().optional().meta({
			toggleGroup: 'ssl',
			htmlType: 'textarea',
			description:
				'Used to check that the postgres server certificate is signed by a trusted certificate authority.',
			placeholder: CERTIFICATE_PLACEHOLDER,
		}),
		ssh_tunnel: Yup.string().optional(),
	}),
	oracle: Yup.object().shape({
		_group: Yup.string().oneOf(['On-Prem', 'Cloud', 'mTLS']).default('On-Prem'),
		host: Yup.string().optional().meta({
			group: 'On-Prem',
		}),
		port: Yup.number().optional().default(1521).meta({
			group: 'On-Prem',
		}),
		database: Yup.string().optional().meta({
			group: 'On-Prem',
		}),
		dsn: Yup.string()
			.optional()
			.meta({
				groups: ['Cloud', 'mTLS'],
			}),
		user: Yup.string()
			.optional()
			.meta({
				groups: ['On-Prem', 'Cloud', 'mTLS'],
			}),
		password: Yup.string()
			.optional()
			.meta({
				htmlType: 'password',
				groups: ['On-Prem', 'Cloud', 'mTLS'],
			}),
		ssh_tunnel: Yup.string()
			.optional()
			.meta({
				groups: ['On-Prem'],
			}),
		wallet_password: Yup.string()
			.optional()
			.meta({
				htmlType: 'password',
				groups: ['mTLS'],
			}),
		wallet_file: Yup.string().optional().meta({
			htmlType: 'file',
			secodaFileType: 'wallet',
			description:
				'mTLS connection requires a wallet file. Please upload the wallet file.',
			group: 'mTLS',
		}),
	}),
	polytomic: Yup.object().shape({
		api_key: Yup.string().required('Required').meta({
			label: 'API key',
			htmlType: 'password',
			description:
				'An API Key can be generated from your user settings panel on Polytomic',
		}),
	}),
	postgres: Yup.object().shape({
		host: Yup.string().required('Required'),
		port: Yup.number().required('Required').default(5432),
		database: Yup.string().required('Required'),
		user: Yup.string().required('Required'),
		password: Yup.string().required('Required').meta({
			htmlType: 'password',
		}),
		ssl: Yup.boolean()
			.optional()
			.meta({ toggleController: 'ssl', label: 'SSL (Optional)' }),
		client_key: Yup.string().optional().meta({
			toggleGroup: 'ssl',
			htmlType: 'textarea',
			description: 'Proof that the client certificate is sent by the owner.',
			placeholder: CERTIFICATE_PLACEHOLDER,
		}),
		client_certificate: Yup.string().optional().meta({
			toggleGroup: 'ssl',
			htmlType: 'textarea',
			description: 'Sent to the Postgres server to prove the client identity.',
			placeholder: CERTIFICATE_PLACEHOLDER,
		}),
		certificate_authority: Yup.string().optional().meta({
			toggleGroup: 'ssl',
			htmlType: 'textarea',
			description:
				'Used to check that the postgres server certificate is signed by a trusted certificate authority.',
			placeholder: CERTIFICATE_PLACEHOLDER,
		}),
		ssh_tunnel: Yup.string().optional(),
	}),
	powerbi: Yup.object().shape({
		_group: Yup.string().oneOf(['OAuth', 'Managed']).default('OAuth'),
		tenant_id: Yup.string()
			.when('_group', {
				is: 'Managed',
				then: Yup.string().required('Required'),
			})
			.meta({
				description: 'Azure AD tenant id',
				label: 'Tenant ID',
				group: 'Managed',
			}),
		client_id: Yup.string()
			.when('_group', {
				is: 'Managed',
				then: Yup.string().required('Required'),
			})
			.meta({
				description: 'Azure AD app registrations app (client) id',
				htmlType: 'password',
				label: 'Client ID',
				group: 'Managed',
			}),
		client_secret: Yup.string()
			.when('_group', {
				is: 'Managed',
				then: Yup.string().required('Required'),
			})
			.meta({
				description: 'Azure AD app registrations app (client) secret',
				htmlType: 'password',
				group: 'Managed',
			}),
	}),
	preset: Yup.object().shape({
		username: Yup.string().required('Required').meta({
			htmlType: 'text',
			label: 'API token name',
			description:
				'API Token Name. You can obtain it at Manage User Settings > API Keys',
		}),
		password: Yup.string().required('Required').optional().meta({
			htmlType: 'password',
			label: 'API token secret',
			description:
				'API Token Secret. You can obtain it at Manage User Settings > API Keys',
		}),
	}),
	quicksight: Yup.object().shape({
		account_id: Yup.string().required('Required'),
		region: Yup.string().optional().meta({
			label: 'AWS region',
		}),
		access_key_id: Yup.string()
			.optional()
			.meta({ label: 'AWS access key', htmlType: 'password' }),
		secret_access_key: Yup.string().optional().meta({ htmlType: 'password' }),
	}),
	redash: Yup.object().shape({
		host: Yup.string().required('Required').meta({ htmlType: 'text' }),
		api_version: Yup.number().required('Required').meta({
			label: 'API version',
		}),
		access_token: Yup.string().required('Required').meta({
			htmlType: 'password',
			description: 'API Access Token created in Redash Settings',
		}),
	}),
	redshift: Yup.object().shape({
		host: Yup.string().required('Required').meta({ htmlType: 'text' }),
		port: Yup.number().required('Required'),
		database: Yup.string().required('Required'),
		user: Yup.string().required('Required'),
		password: Yup.string().required('Required').meta({
			htmlType: 'password',
		}),
		ssh_tunnel: Yup.string().optional(),
	}),
	retool: Yup.object().shape({
		cookie: Yup.string().required('Required').meta({
			htmlType: 'password',
		}),
		subdomain: Yup.string()
			.optional()
			.meta({
				description:
					'The subdomain of your Retool instance, i.e. secoda.retool.com',
			})
			.matches(
				/^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]+[a-zA-Z0-9-_]*$/,
				'Invalid domain name'
			),
	}),
	salesforce: Yup.object().shape({
		_group: Yup.string().oneOf(['Password', 'OAuth']).default('Password'),
		host: Yup.string()
			.required('Required')
			.meta({
				description:
					'URL of your Salesforce instance, for example https://{your-domain}.my.salesforce.com',
				htmlType: 'text',
				groups: ['Password', 'OAuth'],
			}),
		consumer_key: Yup.string()
			.required('Required')
			.meta({
				label: 'Consumer key',
				htmlType: 'password',
				groups: ['Password', 'OAuth'],
			}),
		consumer_secret: Yup.string()
			.required('Required')
			.meta({
				label: 'Consumer secret',
				htmlType: 'password',
				groups: ['Password', 'OAuth'],
			}),
		username: Yup.string()
			.when('_group', {
				is: 'Password',
				then: Yup.string().required('Required'),
			})
			.meta({
				group: 'Password',
			}),
		password: Yup.string()
			.when('_group', {
				is: 'Password',
				then: Yup.string().required('Required'),
			})
			.meta({
				htmlType: 'password',
				group: 'Password',
			}),
	}),
	sigma: Yup.object().shape({
		host: Yup.string().required('Required').meta({
			description:
				'The host parameter is https://api.sigmacomputing.com for GCP organizations and https://aws-api.sigmacomputing.com for AWS organizations.',
		}),
		client_id: Yup.string().required('Required').meta({
			label: 'Client ID',
			htmlType: 'password',
			description:
				'Create a client secret on the API & Embed Secrets page in the Admin section of Sigma.',
		}),
		client_secret: Yup.string()
			.required('Required')
			.meta({ htmlType: 'password' }),
	}),
	singlestore: Yup.object().shape({
		host: Yup.string().required('Required').meta({
			htmlType: 'text',
			label: 'Host',
			description: 'The host of your SingleStore instance',
		}),
		port: Yup.number().required('Required').default(3306).meta({
			htmlType: 'number',
			label: 'Port',
		}),
		user: Yup.string().required('Required').meta({
			htmlType: 'text',
			label: 'Username',
		}),
		password: Yup.string().required('Required').meta({
			htmlType: 'password',
			label: 'Password',
		}),
		database: Yup.string().optional(),
		ssl: Yup.boolean()
			.optional()
			.meta({ toggleController: 'ssl', label: 'SSL (Optional)' }),
		pem_bundle: Yup.string().optional().meta({
			toggleGroup: 'ssl',
			label: 'PEM bundle',
			htmlType: 'textarea',
			description: 'SingleStore PEM bundle',
			placeholder: CERTIFICATE_PLACEHOLDER,
		}),
	}),
	snowflake: Yup.object().shape({
		_group: Yup.string()
			.oneOf(['Username & Password', 'Key Pair'])
			.default('Username & Password'),
		account: Yup.string()
			.required('Required')
			.meta({
				groups: ['Key Pair', 'Username & Password'],
			}),
		warehouse: Yup.string()
			.required('Required')
			.meta({
				groups: ['Key Pair', 'Username & Password'],
			}),
		user: Yup.string()
			.required('Required')
			.meta({
				groups: ['Key Pair', 'Username & Password'],
			}),
		password: Yup.string()
			.required()
			.meta({
				htmlType: 'password',
				groups: ['Username & Password'],
			}),
		private_key: Yup.string()
			.required()
			.meta({
				htmlType: 'password',
				groups: ['Key Pair'],
				description: 'Base64 encoded private key',
			}),
		passphrase: Yup.string().meta({
			htmlType: 'password',
			groups: ['Key Pair'],
		}),
		snowflake_role: Yup.string()
			.optional()
			.meta({
				groups: ['Key Pair', 'Username & Password'],
				label: 'Role',
			}),
	}),
	ssrs: Yup.object().shape({
		host: Yup.string().required('Required').meta({
			description:
				'The host parameter is the Web Portal URL of your SSRS instance. For example, https://ssrs.yourcompany.com/reports',
		}),
		username: Yup.string().required('Required').meta({
			label: 'Username',
		}),
		password: Yup.string().required('Required').meta({ htmlType: 'password' }),
		ssh_tunnel: Yup.string().optional(),
	}),
	stitch: Yup.object().shape({
		client_id: Yup.string().required('Required').meta({
			label: 'Client ID',
			description:
				'The unique identifier for your Stitch account. This can be found in the URL of your Stitch account',
		}),
		api_key: Yup.string().required('Required').meta({
			label: 'API key',
			htmlType: 'password',
			description:
				'An API Key can be generated from your settings panel on Stitch',
		}),
	}),
	superset: Yup.object().shape({
		host: Yup.string().required('Required').meta({
			htmlType: 'text',
			label: 'Host',
			description:
				'The host of your Superset instance (e.g. http://98.89.21.12:8088)',
		}),
		username: Yup.string().required('Required').meta({
			htmlType: 'text',
			label: 'Username',
			description: 'The username for the Superset user',
		}),
		password: Yup.string().optional().meta({
			htmlType: 'password',
			label: 'Password',
			description: 'The password for the Superset user',
		}),
	}),
	synapse: Yup.object().shape({
		host: Yup.string().required('Required').meta({
			description: 'The host of your Azure Synapse instance',
		}),
		port: Yup.string().required('Required').meta({
			htmlType: 'port',
			description:
				'The port of your Azure Synapse instance. The default port is 1433',
		}),
		database: Yup.string().required('Required').meta({
			label: 'Database',
			description: 'The database you are connecting to',
		}),
		user: Yup.string().required('Required').meta({
			label: 'User',
			description:
				'The user for your Azure Synapse instance. This user must have access to the database you are connecting to.',
		}),
		password: Yup.string().required('Required').meta({
			htmlType: 'password',
			description:
				'The password for your Azure Synapse instance. This user must have access to the database you are connecting to.',
		}),
		ssh_tunnel: Yup.string().optional(),
	}),
	tableau: Yup.object().shape({
		host: Yup.string()
			.required('Required')
			.meta({
				description:
					'If the Tableau URL is https://prod-useast-b.online.tableau.com/#/site/secoda/home, the host is https://prod-useast-b.online.tableau.com and the site name is secoda',
			})
			.meta({ htmlType: 'text' }),
		site_name: Yup.string().meta({
			description: 'Tableau Online will require the site name',
		}),
		api_version: Yup.string().required('Required').meta({
			label: 'API version',
			description:
				'API versions found at https://help.tableau.com/current/api/rest_api/en-us/REST/rest_api_concepts_versions.htm',
		}),
		access_token_name: Yup.string().required('Required').meta({
			description: 'API Token Name created in Tableau Settings',
			htmlType: 'password',
		}),
		access_token_secret: Yup.string().required('Required').meta({
			description: 'API Secret Token created in Tableau Settings',
			htmlType: 'password',
		}),
	}),
	thoughtspot: Yup.object().shape({
		host: Yup.string().required('Required').meta({
			htmlType: 'text',
			label: 'Host',
			description:
				'The host of your ThoughtSpot instance, i.e. if the url on the home page is https://my2.thoughtspot.cloud/#/, then the host is my2.thoughtspot.cloud',
		}),
		username: Yup.string().required('Required').meta({
			htmlType: 'text',
			label: 'Username',
		}),
		password: Yup.string().optional().meta({
			htmlType: 'password',
			label: 'Password',
			description:
				'The password for the ThoughtSpot user. Required if trusted authentication is disabled.',
		}),
		secret_key: Yup.string().optional().meta({
			htmlType: 'text',
			label: 'Secret key',
			description:
				'Unique secret key associated with the organization on ThoughtSpot you are connecting Secoda to. Required if trusted authentication is enabled.',
		}),
		org_id: Yup.number().optional().meta({
			htmlType: 'text',
			description:
				'The unique identifier for an org if the Orgs feature on ThoughtSpot is enabled (Optional)',
			label: 'Organization ID',
		}),
		enabled_trusted_authentication: Yup.boolean().meta({
			toggleController: 'enabled_trusted_authentication',
			label: 'Enabled Trusted Authentication',
			description:
				'Indicator if trusted authentication is enabled on your ThoughtSpot instance.',
		}),
	}),
	trino: Yup.object().shape({
		host: Yup.string().required('Required'),
		port: Yup.number().required('Required'),
		user: Yup.string().required('Required'),
		jwt_token: Yup.string().meta({
			label: 'JWT token',
			htmlType: 'password',
		}),
		password: Yup.string().meta({
			htmlType: 'password',
		}),
		query_history_config: Yup.string()
			.optional()
			.meta({
				label: 'Query history config',
				description: `See ${DOCS_INTEGRATION_BASE}query-engines/trino#optional-query-history-config for the format `,
			})
			.matches(
				/\{\s*"query_table"\s*:\s*"[a-zA-Z0-9_]+\.[a-zA-Z0-9_]+\.[a-zA-Z0-9_]+",\s*"start_time_column"\s*:\s*"[^"]*",\s*"end_time_column"\s*:\s*"[^"]*",\s*"query_column"\s*:\s*"[^"]*",\s*"user_column"\s*:\s*"[^"]*"\s*\}/,
				'Must match the config here https://docs.secoda.co/integrations/query-engines/trino#optional-query-history-config'
			),
		ssh_tunnel: Yup.string().optional(),
	}),
	kafka: Yup.object().shape({
		bootstrap_server: Yup.string().required('Required').meta({
			description: 'Bootstrap server as <host>:<port>',
		}),
		username: Yup.string().required('Required').meta({
			description: 'Username for the connection',
		}),
		password: Yup.string().required('Required').meta({
			description: 'Password for the user to connect to this cluster',
			htmlType: 'password',
		}),
		schema_registry_url: Yup.string().optional().meta({
			label: 'Schema Registry URL',
			description: 'URL for the Schema Registry',
		}),
		schema_registry_username: Yup.string().optional().meta({
			label: 'Schema Registry username',
			description: 'Username for the Schema Registry',
		}),
		schema_registry_password: Yup.string().optional().meta({
			label: 'Schema Registry password',
			description: 'Password for the Schema Registry',
			htmlType: 'password',
		}),
	}),
	confluent_cloud: Yup.object().shape({
		bootstrap_server: Yup.string().required('Required').meta({
			description: 'Bootstrap server as <host>:<port> from Cluster Settings',
		}),
		username: Yup.string().required('Required').meta({
			description: 'API Key ID for this cluster will be used as username',
		}),
		password: Yup.string().required('Required').meta({
			description: 'API Key Secret for this cluster will be used as password',
			htmlType: 'password',
		}),
		cluster_id: Yup.string().required('Required').meta({
			label: 'Cluster ID',
			description: 'Cluster ID from the Cluster Settings',
		}),
		env_id: Yup.string().required('Required').meta({
			label: 'Environment ID',
			description: 'Environment ID for the environment for this cluster',
		}),
		schema_registry_url: Yup.string().optional().meta({
			label: 'Schema Registry URL',
			description: 'URL for the Schema Registry',
		}),
		schema_registry_username: Yup.string().optional().meta({
			label: 'Schema Registry username',
			description: 'Username for the Schema Registry',
		}),
		schema_registry_password: Yup.string().optional().meta({
			label: 'Schema Registry password',
			description: 'Password for the Schema Registry',
			htmlType: 'password',
		}),
	}),
	gcs: Yup.object().shape({
		region: Yup.string().optional().meta({
			description: 'The GCP region',
		}),
		access_key_id: Yup.string().required('Required').meta({
			label: 'Access Key ID',
			htmlType: 'password',
			description:
				'Only required if this instance does not have implicit role access to the bucket',
		}),
		secret_access_key: Yup.string().required('Required').meta({
			htmlType: 'password',
			description: 'Requires the permission set to Storage Object Viewer',
		}),
		bucket_name: Yup.string().required('Required').meta({
			label: 'Bucket Name',
			description: 'Name of the GCS bucket',
		}),
	}),
	s3: Yup.object().shape({
		access_key_id: Yup.string()
			.optional()
			.meta({
				label: 'Access key ID',
				htmlType: 'password',
				description: 'Access Key of the IAM User',
				groups: ['Access Key'],
			}),
		secret_access_key: Yup.string()
			.optional()
			.meta({
				label: 'Secret access key',
				htmlType: 'password',
				description: 'Secret Access Key of the IAM User',
				groups: ['Access Key'],
			}),
		bucket_name: Yup.string()
			.optional()
			.meta({
				label: 'Bucket name',
				description: 'Name of the S3 bucket',
				groups: ['Role', 'Access Key'],
			}),
		search_path: Yup.string()
			.required()
			.meta({
				label: 'Search path',
				description:
					'The path to search for files in the bucket. For everything, use "/"',
				groups: ['Role', 'Access Key'],
			}),
		region: Yup.string()
			.optional()
			.meta({
				description: 'The AWS or S3-compatible region',
				groups: ['Role', 'Access Key'],
			}),
		role_arn: Yup.string()
			.optional()
			.meta({
				label: 'AWS role ARN',
				groups: ['Role'],
			}),
		external_id: Yup.string()
			.optional()
			.meta({
				label: 'External ID',
				description:
					'On the AWS cross account role check "Require external ID" and copy this value into the field',
				groups: ['Role'],
				disabled: true,
			}),
	}),
	airbyte: Yup.object().shape({
		server_url: Yup.string().required('Required').meta({
			description: 'Your Airbyte Server URL (e.g. https://api.airbyte.com)',
			label: 'Server URL',
		}),
		dashboard_url: Yup.string().required('Required').meta({
			description:
				'Your Airbyte Dashboard URL (e.g. https://cloud.airbyte.com)',
			label: 'Dashboard URL',
		}),
		client_id: Yup.string().required('Required').meta({
			label: 'Client ID',
			htmlType: 'password',
		}),
		client_secret: Yup.string().required('Required').meta({
			label: 'Client Secret',
			htmlType: 'password',
		}),
	}),
};

type IntegrationSchemas = typeof integrationSchemas;
export type IntegrationSchemaKeys = keyof IntegrationSchemas;
