import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash-es';

dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

export const getEpoch = (datetime: string) =>
	Math.ceil(new Date(datetime).getTime() / 1000);

export const humanReadableTimeElapsed = (seconds: number): string => {
	const minute = 60;
	const hour = 60 * minute;
	const day = 24 * hour;

	const days = Math.floor(seconds / day);
	seconds %= day;
	const hours = Math.floor(seconds / hour);
	seconds %= hour;
	const minutes = Math.floor(seconds / minute);
	seconds = Math.floor(seconds % minute);

	return `${days > 0 ? `${days}d ` : ''}${hours > 0 ? `${hours}h ` : ''}${
		minutes > 0 ? `${minutes}m ` : ''
	}${seconds}s`;
};

/**
 * Formats a datetime string to a short or full time difference.
 * Examples:
 * - Short format (default): 2 seconds ago -> 2s ago
 * - Full format: 2 seconds ago -> 2 seconds ago
 */
export const formatShortTimeDifference = (
	datetime: string,
	useFullText = false
) => {
	const now = dayjs();
	const targetDate = dayjs(datetime);
	const diffInSeconds = now.diff(targetDate, 'seconds');

	if (Math.abs(diffInSeconds) < 10) {
		return 'Just now';
	}

	const shortFormat = {
		future: 'in %s',
		past: '%s ago',
		s: '%ds',
		m: '1m',
		mm: '%dm',
		h: '1h',
		hh: '%dh',
		d: '1d',
		dd: '%dd',
		M: '1 month',
		MM: '%d months',
		y: '1yr',
		yy: '%dyr',
	};

	const fullFormat = {
		future: 'in %s',
		past: '%s ago',
		s: 'a few seconds',
		m: 'a minute',
		mm: '%d minutes',
		h: 'an hour',
		hh: '%d hours',
		d: 'a day',
		dd: '%d days',
		M: 'a month',
		MM: '%d months',
		y: 'a year',
		yy: '%d years',
	};

	dayjs.updateLocale('en', {
		relativeTime: useFullText ? fullFormat : shortFormat,
	});

	return targetDate.fromNow();
};

export function parseUnknownDateTime(unknownString: string) {
	const formatsToTry = [
		'YYYY-MM-DDTHH:mm:ss.SSSZ', // RFC 3339
		'YYYY-MM-DDTHH:mm:ssZ', // ISO 8601
		'YYYY-MM-DDTHH:mm:ss',
		'YYYY-MM-DD HH:mm:ss',
		'YYYY-MM-DD HH:mm',
		'YYYY-MM HH:mm',
		'YYYY-MM-DD',
		'YYYY-MM',
		'ddd, DD MMM YYYY HH:mm:ss ZZ', // RFC 2822
		'ddd, DD MMM YYYY HH:mm:ss Z',
		'DD MMM YYYY HH:mm:ss ZZ',
		'DD MMM YYYY HH:mm:ss Z',
		'DD MMM YYYY HH:mm:ss',
		'DD MMM YYYY HH:mm',
		'DD MMM YYYY',
		'DD/MM/YYYY HH:mm:ss',
		'YYYY/MM/DD HH:mm:ss',
		'MM-YYYY',
		'YYYY HH:mm',
		'YYYY',
		'MM-DD-YYYYTHH:mm:ss',
		'MM-DD-YYYY HH:mm:ss',
		'MM-DD-YYYY',
		'MM-YYYYTHH:mm:ss',
		'MM-YYYY HH:mm:ss',
		'YYYYTHH:mm:ss',
		'YYYY HH:mm:ss',
		'HH:mm:ss',
		'HH:mm',
		'h:mm a', // 12-hour format with AM/PM
		'h:mm A', // 12-hour format with AM/PM
		'h:mm:ss A', // 12-hour format with AM/PM and seconds
		'h:mm:ss a', // 12-hour format with AM/PM and seconds
	];

	let parsedDateTime = null;

	if (isNil(parsedDateTime)) {
		// eslint-disable-next-line no-restricted-syntax
		for (const format of formatsToTry) {
			const parsed = dayjs(unknownString, format, true);
			if (parsed.isValid()) {
				parsedDateTime = parsed;
				break;
			}
		}
	}

	if (dayjs(unknownString).isValid()) {
		parsedDateTime = dayjs(unknownString);
	}

	return parsedDateTime;
}

export function formatDateTime(date?: string | null) {
	if (!date) {
		return '';
	}

	const now = dayjs();

	if (now.diff(date, 'minute') < 60) {
		return dayjs(date).fromNow();
	} else if (dayjs(date).isToday()) {
		return `Today at ${dayjs(date).format('h:mm A')}`;
	} else if (dayjs(date).isYesterday()) {
		return `Yesterday at ${dayjs(date).format('h:mm A')}`;
	} else {
		return dayjs(date).format('MMMM D, YYYY [at] h:mm A');
	}
}

export function formatEntityDateTime(time: string) {
	return dayjs(time).format('MMM D, YYYY h:mm A');
}

/**
 * Checks if a datetime string is before right now.
 *
 * @param {string} datetime - The datetime string to check.
 * @returns {boolean} - Returns true if the datetime is before right now, otherwise false.
 */
export const isBeforeRightNow = (datetime: string) => {
	const date = dayjs(datetime);
	return date.isBefore(dayjs());
};
