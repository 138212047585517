import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import {
	AutomationCondition,
	AutomationField,
	AutomationFilterOperator,
} from '../../../../../api';

export const AUTOMATION_FILTER_SET_OPERATORS: SelectablePropertyItem[] = [
	{
		label: 'And',
		value: AutomationFilterOperator.AND,
	},
	{
		label: 'Or',
		value: AutomationFilterOperator.OR,
	},
];

export const AUTOMATION_FILTER_OPERATORS: SelectablePropertyItem[] = [
	{
		label: 'all',
		value: AutomationFilterOperator.AND,
	},
	{
		label: 'any',
		value: AutomationFilterOperator.OR,
	},
];

export const AUTOMATION_CONDITION_DISPLAY_MAP: {
	[key in AutomationCondition]: string;
} = {
	[AutomationCondition.IS]: 'is',
	[AutomationCondition.IS_NOT]: 'is not',
	[AutomationCondition.CONTAINS]: 'contains',
	[AutomationCondition.DOES_NOT_CONTAIN]: 'does not contain',
	[AutomationCondition.STARTS_WITH]: 'starts with',
	[AutomationCondition.ENDS_WITH]: 'ends with',
	[AutomationCondition.IS_SET]: 'is set',
	[AutomationCondition.IS_NOT_SET]: 'is not set',
	[AutomationCondition.GTE]: 'is on or after',
	[AutomationCondition.LTE]: 'is on or before',
};

export const AUTOMATION_FIELD_OPTIONS: SelectablePropertyItem[] = [
	{
		label: 'Owner',
		value: AutomationField.OWNERS,
	},
	{
		label: 'Tag',
		value: AutomationField.TAGS,
	},
	{
		label: 'Team',
		value: AutomationField.TEAMS,
	},
	{
		label: 'Collection',
		value: AutomationField.COLLECTIONS,
	},
	{
		label: 'Governance',
		value: AutomationField.PII,
	},
	{
		label: 'Verification',
		value: AutomationField.VERIFIED,
	},
	{
		label: 'Documentation',
		value: AutomationField.DEFINITION,
	},
	{
		label: 'Description',
		value: AutomationField.DESCRIPTION,
	},
	{
		label: 'Title',
		value: AutomationField.TITLE,
	},
	{
		label: 'Database',
		value: AutomationField.DATABASE,
	},
	{
		label: 'Schema',
		value: AutomationField.SCHEMA,
	},
	{
		label: 'Table',
		value: AutomationField.TABLE,
	},
	{
		label: 'Type',
		value: AutomationField.NATIVE_TYPE,
	},
	{
		label: 'Integration',
		value: AutomationField.INTEGRATION_ID,
	},
	{
		label: 'Source',
		value: AutomationField.SOURCES,
	},
	{
		label: 'Slack channel',
		value: AutomationField.SLACK_CHANNELS,
	},
	{
		label: 'Created time',
		value: AutomationField.CREATED_AT,
	},
	{
		label: 'External updated time',
		value: AutomationField.EXTERNAL_UPDATED_AT,
	},
	{
		label: 'Popularity',
		value: AutomationField.EXTERNAL_USAGE,
		description: 'Number of views or queries',
	},
	{
		label: 'Status',
		value: AutomationField.PUBLISHED,
	},
	{
		label: 'Quality',
		value: AutomationField.QUALITY,
	},
	{
		label: 'Quality - Accuracy',
		value: AutomationField.QUALITY_ACCURACY,
	},
	{
		label: 'Quality - Reliability',
		value: AutomationField.QUALITY_RELIABILITY,
	},
	{
		label: 'Quality - Usability',
		value: AutomationField.QUALITY_USABILITY,
	},
	{
		label: 'Quality - Stewardship',
		value: AutomationField.QUALITY_STEWARDSHIP,
	},
];
