import type { FilterView } from '@repo/common/components/Filter/types';
import type { ISearchView } from '../../../api';
import type { User } from '../../../lib/models';

export function convertISearchViewToFilterView(
	view: ISearchView,
	user?: User
): FilterView {
	return {
		label: view.name,
		value: view.id,
		filters: view.selected_filters,
		isOwner: view.owner_id === user?.id,
		teams: view.teams,
	};
}
