import type { MantineTheme } from '@mantine/core';
import {
	ActionIcon,
	// eslint-disable-next-line no-restricted-imports
	Badge,
	createStyles,
	Group,
	Menu,
	Stack,
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { Icon, Text } from '@repo/foundations';
import { typography } from '@repo/theme/primitives';
import type { SecodaThemeShades } from '@repo/theme/types';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import { useNavigate } from '../../hooks/useNavigate';
import { pickColor } from '../../utils';
import ItemIcon, { ItemIconType } from '../ItemIcon';
import type { SelectablePropertyItem } from './types';

export interface ISingleSelectorItemProps {
	isSelected?: boolean;
	iconType: ItemIconType;
	item: SelectablePropertyItem;
	isViewerUser: boolean;
	onClick: (item: SelectablePropertyItem) => void;
	displayIcon?: boolean;
}

const useStyles = createStyles(
	(
		theme: MantineTheme,
		{
			badgeColor,
			textColor,
		}: { badgeColor: SecodaThemeShades; textColor: SecodaThemeShades }
	) => ({
		badgeInner: {
			fontSize: theme.fontSizes.sm,
			fontWeight: typography.weight.regular,
			color: theme.other.getColor('text/primary/default'),
			lineHeight: '20px',
		},
		actionIcon: {
			backgroundColor: theme.other.getColor('fill/transparent/default'),
			width: 20,
			height: 20,
			padding: 0,
			'&:hover': {
				backgroundColor: theme.other.getColor('fill/transparent/hover'),
			},
			'&:active': {
				backgroundColor: theme.other.getColor('fill/transparent/active'),
			},
		},
		stack: {
			gap: 0,
		},
		badge: {
			margin: 0,
			backgroundColor: theme.other.getColor(badgeColor),
			color: theme.other.getColor(textColor),
			borderRadius: theme.radius.sm,
			padding: `${theme.spacing['4xs']} ${theme.spacing['2xs']}`,
			fontSize: theme.fontSizes.sm,
		},
	})
);

function SingleSelectorItem({
	isSelected = false,
	iconType,
	item,
	isViewerUser,
	onClick,
	displayIcon = true,
}: ISingleSelectorItemProps) {
	const { hovered, ref } = useHover<HTMLButtonElement>();

	const navigate = useNavigate();

	const { theme, classes } = useStyles({
		badgeColor: item.color?.includes('/') ? item.color : 'fill/primary/default',
		textColor: item.textColor?.includes('/')
			? item.textColor
			: 'text/primary/default',
	});

	let color: string;

	if (iconType === 'avatar') {
		color = pickColor(item.label);
	} else if (iconType === 'tag') {
		color = item.color || theme.other.getColor('icon/brand/default');
	} else if (iconType === 'tabler') {
		color = item?.color?.includes('/')
			? theme.other.getColor(item.color)
			: theme.other.getColor('icon/brand/default');
	} else {
		color =
			(item.color as string) || theme.other.getColor('icon/brand/default');
	}

	const RightSection = useMemo(
		() =>
			!isViewerUser && !isNil(item.navigateTo) && hovered ? (
				<ActionIcon
					className={classes.actionIcon}
					size={20}
					onClick={(event) => {
						event.stopPropagation();
						navigate(item.navigateTo as string);
					}}
				>
					<Icon name="chevronRight" />
				</ActionIcon>
			) : null,
		[isViewerUser, item.navigateTo, hovered, classes.actionIcon, navigate]
	);

	const handleOnClick = () => {
		if (isViewerUser && !isNil(item.navigateTo)) {
			navigate(item.navigateTo);
		} else {
			onClick(item);
			if (item.onClick) {
				item.onClick(item.value);
			}
		}
	};

	return (
		<Menu.Item ref={ref} rightSection={RightSection} onClick={handleOnClick}>
			<Group position="apart" noWrap>
				<Group spacing={4} noWrap>
					{displayIcon && item.iconName && (
						<Icon name={item.iconName} style={{ color }} />
					)}
					{displayIcon && !item.iconName && (
						<ItemIcon
							type={iconType}
							item={item}
							color={color as SecodaThemeShades} // Type asserting here as we're internally handling the string colors
						/>
					)}
					<Stack className={classes.stack}>
						{iconType === 'badge' ? (
							<Badge size="xs" className={classes.badge}>
								{item.strikeThrough ? (
									<Text size="sm" td="line-through">
										{item.label}
									</Text>
								) : (
									<Text size="sm">{item.label}</Text>
								)}
							</Badge>
						) : item.strikeThrough ? (
							<Text size="sm" td="line-through">
								{item.label}
							</Text>
						) : (
							<Text size="sm">{item.label}</Text>
						)}
						{item.description && (
							<Text color="text/secondary/default" size="xs">
								{item.description}
							</Text>
						)}
					</Stack>
				</Group>
				{isSelected && <Icon name="check" />}
			</Group>
		</Menu.Item>
	);
}

export default SingleSelectorItem;
