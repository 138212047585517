import SingleSelector from '@repo/common/components/SingleSelector/SingleSelector';
import { Button, Icon } from '@repo/foundations';
import { uuidv4 } from 'lib0/random';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useIntegrationList } from '../../api';
import { useAutomationStore } from '../../pages/AutomationPage/context';
import { isValidEnumValue } from '../../utils/enumUtils';
import {
	AutomationActionCardType,
	AutomationActionMenuValue,
	automationActionMenuOptions,
} from './constants';
import { convertAutomationActionMenuValueToCardOptions } from './utils';

function AddAutomationActionButton() {
	const store = useAutomationStore();
	const { data: integrations } = useIntegrationList({});

	const actionMenuOptions = useMemo(() => {
		const currentCards = store.cards;

		return automationActionMenuOptions.filter((menuOption) => {
			if (isValidEnumValue(AutomationActionMenuValue, menuOption.value)) {
				const cardOption = convertAutomationActionMenuValueToCardOptions(
					menuOption.value
				);

				if (menuOption.value === AutomationActionMenuValue.FILTER_RESOURCES) {
					return true;
				}

				// Only display edit resource card if a filter resource card is already present
				if (
					[
						AutomationActionMenuValue.EDIT_RESOURCES,
						AutomationActionMenuValue.PROPAGATE_METADATA,
					].includes(menuOption.value) &&
					!currentCards.some(
						(card) => card.type === AutomationActionCardType.FILTER_RESOURCES
					)
				) {
					return false;
				}

				// Only display send slack message card if slack integration is present
				if (
					menuOption.value === AutomationActionMenuValue.SEND_SLACK_MESSAGE &&
					!integrations?.results.some(
						(integration) => integration.type === 'slack'
					)
				) {
					return false;
				}

				if (
					currentCards.some((card) =>
						cardOption.some((option) => card.type === option.type)
					)
				) {
					return false;
				}
			}

			return true;
		});
	}, [store.cards, integrations]);

	const handleOnClick = async (
		automationActionMenuValue: AutomationActionMenuValue
	) => {
		const cardOptions = convertAutomationActionMenuValueToCardOptions(
			automationActionMenuValue
		);

		if (
			automationActionMenuValue === AutomationActionMenuValue.FILTER_RESOURCES
		) {
			store.addFilterResourceCard();
		} else {
			cardOptions.forEach((cardOption) => {
				store.addCard({
					id: uuidv4(),
					titleIcon: <Icon name={cardOption.iconName} />,
					type: cardOption.type,
				});
			});
		}
	};

	const target = <Button leftIconName="plus">Add action</Button>;

	return (
		<SingleSelector
			target={target}
			isViewerUser={false}
			searchable={false}
			options={actionMenuOptions}
			property="actions"
			iconType="tabler"
			supportSelected={false}
			onChange={handleOnClick}
		/>
	);
}

export default observer(AddAutomationActionButton);
