import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	workspacesListSectionWrapper: {
		maxHeight: '70vh',
		gap: 0,
		padding: theme.other.space[1],
	},
	sectionWrapper: {
		gap: 0,
		padding: theme.other.space[1],
	},
	userList: {
		maxHeight: '30vh',
		overflowY: 'scroll',
	},
	itemWrapper: {
		padding: `${theme.spacing['3xs']} ${theme.spacing.xs}}`,
	},
	menuRowButton: {
		padding: `0 ${rem(theme.other.space[2])}`,
		height: theme.other.height.sm,
		borderRadius: theme.radius.sm,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/primary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/primary/selected'),
		},
	},
	workspace: {
		padding: '8px',
		position: 'relative',
		borderRadius: theme.radius.sm,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.colors.gray[0],
		},
	},
}));
