import { Popover, PopoverProps, createStyles } from '@mantine/core';
import {
	useCallback,
	useState,
	type PropsWithChildren,
	type ReactNode,
} from 'react';

const useStyles = createStyles((theme) => ({
	dropdown: {
		boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.12)',
		border: `${theme.other.borderWidth.xs}px solid ${theme.other.getColor('border/primary/default')}`,
	},
}));

interface HoverCardProps {
	target: ReactNode;
	position?: PopoverProps['position'];
	hidden?: boolean;
	withinPortal?: boolean;
	initialOpen?: boolean;
}

export function HoverCard({
	target,
	children,
	position,
	hidden = false,
	initialOpen = false,
	withinPortal,
	...other
}: PropsWithChildren<HoverCardProps> & Partial<PopoverProps>) {
	const { classes } = useStyles();
	const [open, setOpen] = useState(initialOpen);

	const handleMouseEnter = useCallback(() => {
		setOpen(true);
	}, []);

	const handleMouseLeave = useCallback(() => {
		setOpen(false);
	}, []);

	return (
		<Popover
			withinPortal={withinPortal}
			opened={open}
			classNames={classes}
			position={position}
			{...other}
		>
			<Popover.Target>
				<div
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					onClick={!hidden ? handleMouseLeave : undefined}
				>
					{target}
				</div>
			</Popover.Target>
			{!hidden && (
				<Popover.Dropdown
					p={0}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					{children}
				</Popover.Dropdown>
			)}
		</Popover>
	);
}
