import type { MantineNumberSize } from '@mantine/core';
import { Center, createStyles, Group, Image, Stack } from '@mantine/core';
import { Button, Icon, IconNames, Text } from '@repo/foundations';
import { Illustration, IllustrationNames } from '../Illustration';

export type EmptyStateSize = 'sm' | 'lg';

export interface ButtonDetails {
	name: string;
	action: () => void;
	size: 'sm' | 'md' | 'lg';
	isPrimary: boolean;
	loading?: boolean;
	leftIconName?: IconNames;
	rightIconName?: IconNames;
}

export interface EmptyStateProps {
	title: string;
	description: string;
	buttons?: ButtonDetails[];
	includeGoBack: boolean;
	iconName?: IconNames;
	illustrationName?: IllustrationNames;
	imgSrc?: string;
	size: EmptyStateSize;
	stateHeight?: number | string;
	imgWidth?: number | string;
	descWidth?: MantineNumberSize;
	className?: string;
	classNames?: Partial<Record<'root' | 'image', string>>;
	withGames?: boolean;
	withActions?: JSX.Element | null;
	width?: MantineNumberSize;
	handleBackButtonClick?: () => void;
}

const useStyles = createStyles(
	(
		theme,
		{
			stateHeight,
			width,
		}: {
			stateHeight?: number | string;
			width?: MantineNumberSize;
		}
	) => ({
		root: {
			marginBottom: 24,
			height: stateHeight,
			width,
			margin: 'auto',
		},
		box: {
			marginBottom: 16,
		},
		gameLink: {
			fontSize: 10,
		},
	})
);

function EmptyState({
	className,
	classNames,
	title,
	description,
	buttons = [],
	includeGoBack,
	iconName,
	illustrationName,
	size,
	imgSrc,
	imgWidth = 120,
	descWidth,
	stateHeight,
	withGames,
	withActions,
	width,
	handleBackButtonClick,
}: EmptyStateProps): JSX.Element {
	const { classes, cx, theme } = useStyles({ stateHeight, width });

	// Replace trailing periods in the description with nothing if it's not three periods.
	let formattedDescription = description;
	if (description.endsWith('.') && !description.endsWith('...')) {
		formattedDescription = description.slice(0, -1);
	}

	return (
		<Center
			data-testid="empty-state"
			className={cx(classes.root, className, classNames?.root)}
		>
			<Stack align="center" py={theme.spacing.lg}>
				{iconName && (
					<Icon name={iconName} size="lg" color="icon/secondary/default" />
				)}
				{illustrationName && <Illustration name={illustrationName} />}
				{imgSrc && (
					<Image src={imgSrc} width={imgWidth} className={classNames?.image} />
				)}
				<Stack maw={descWidth ?? theme.other.space[90]} spacing="xs">
					<Text
						weight={size === 'lg' ? 'bold' : 'semibold'}
						size={size === 'lg' ? 'lg' : 'md'}
						ta="center"
					>
						{title}
					</Text>
					<Text color="text/secondary/default" size="md" ta="center">
						{formattedDescription}
					</Text>
				</Stack>
				{withActions && <Group position="center">{withActions}</Group>}
				{(includeGoBack || buttons.length > 0) && (
					<Group position="center" noWrap>
						{buttons.map((button) => (
							<Button
								key={button.name}
								onClick={button.action}
								size={button.size}
								variant={button.isPrimary ? 'primary' : 'default'}
								loading={button.loading ?? false}
								leftIconName={button.leftIconName}
								rightIconName={button.rightIconName}
							>
								{button.name}
							</Button>
						))}
						{includeGoBack &&
							buttons.every((button) => button.name !== 'Go back') &&
							!!handleBackButtonClick && (
								<Button onClick={handleBackButtonClick} variant="default">
									Go back
								</Button>
							)}
					</Group>
				)}
			</Stack>
		</Center>
	);
}

export { EmptyState };
