import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import type { TabsValue } from '@mantine/core';
import {
	Box,
	Center,
	Popover,
	Tabs,
	// eslint-disable-next-line no-restricted-imports
	Text,
	UnstyledButton,
	createStyles,
	useMantineColorScheme,
} from '@mantine/core';
import { IconNames } from '@repo/foundations';
import { Icon } from '@repo/foundations/components/Icon/Icon';
import { useCallback, useState } from 'react';
import { IconSelector } from '../IconSelector/IconSelector';
import TabsList, { TabItem } from '../TabsList';

export type IconEmojiSelectorValue =
	| { iconName: IconNames; iconColor: string }
	| { emoji: string };

export function isEmoji(
	value: IconEmojiSelectorValue
): value is { emoji: string } {
	return typeof value === 'object' && value !== null && 'emoji' in value;
}

export function isTablerIcon(
	value: IconEmojiSelectorValue
): value is { iconName: IconNames; iconColor: string } {
	return typeof value === 'object' && value !== null && 'iconName' in value;
}

export const stringToIconEmojiSelectorValue = (
	value: string
): IconEmojiSelectorValue => {
	if (!value) {
		return { emoji: '💼' };
	}
	if (typeof value === 'string') {
		if (value.startsWith('emoji:')) {
			const emoji = value.split('emoji:')[1];
			return { emoji };
		}
		if (value.startsWith('iconName:')) {
			const iconNameMatch = value.match(/iconName:([^ ]+)/);
			const iconColorMatch = value.match(/iconColor:([^ ]+)/);

			const iconName = iconNameMatch
				? (iconNameMatch[1] as IconNames)
				: 'briefcase';
			const iconColor = iconColorMatch ? iconColorMatch[1] : '#000000';

			return { iconName, iconColor };
		}
		return { emoji: value };
	}
	return { emoji: '💼' };
};

export const iconEmojiSelectorValueToString = (
	value: IconEmojiSelectorValue
): string => {
	if (isEmoji(value)) {
		return `emoji:${value.emoji}`;
	} else {
		return `iconName:${value.iconName} iconColor:${value.iconColor}`;
	}
};

export interface IconEmojiSelectorProps {
	value?: string;
	onChange: (value: string) => void;
	size?: number;
	defaultOpened?: boolean;
}

const useStyles = createStyles(
	(
		theme,
		{ size, selectedIconColor }: { size: number; selectedIconColor: string }
	) => ({
		center: {
			minWidth: size,
			minHeight: size,
		},
		emoji: {
			fontSize: size - 4, // emoji needs to be 4px smaller than the icon to match height
			display: 'inline-block',
		},
		popoverDropdown: {
			boxShadow: theme.shadows.md,
			borderRadius: theme.radius.md,
		},
		icon: {
			color: selectedIconColor,
			width: size,
			height: size,
			position: 'relative',
		},
	})
);

function IconEmojiSelector({
	value = '💼',
	onChange,
	size = 32,
	defaultOpened = false,
}: IconEmojiSelectorProps) {
	const [activeTab, setActiveTab] = useState<TabsValue>('emoji');
	const [emojiIconValue, setEmojiIconValue] = useState<IconEmojiSelectorValue>(
		stringToIconEmojiSelectorValue(value)
	);
	const { colorScheme } = useMantineColorScheme();

	const tabsList: TabItem[] = [
		{
			value: 'emoji',
			label: 'Emojis',
		},
		{
			value: 'icon',
			label: 'Icons',
		},
	];

	const handleEmojiSelect = useCallback(
		(selectedEmoji: { native: string }) => {
			setEmojiIconValue({ emoji: selectedEmoji.native });
			onChange(iconEmojiSelectorValueToString({ emoji: selectedEmoji.native }));
		},
		[onChange]
	);

	const handleIconSelect = useCallback(
		({ iconName, iconColor }: { iconName: IconNames; iconColor: string }) => {
			setEmojiIconValue({ iconName, iconColor });
			onChange(iconEmojiSelectorValueToString({ iconName, iconColor }));
		},
		[onChange]
	);

	const selectedEmoji = isEmoji(emojiIconValue) ? emojiIconValue.emoji : null;
	const selectedIcon: IconNames = isTablerIcon(emojiIconValue)
		? emojiIconValue.iconName
		: 'briefcase';
	const selectedIconColor =
		isTablerIcon(emojiIconValue) && emojiIconValue.iconColor
			? emojiIconValue.iconColor
			: '#4a4a4a';

	const { classes } = useStyles({ size, selectedIconColor });

	return (
		<Popover
			defaultOpened={defaultOpened}
			position="bottom"
			withinPortal
			classNames={{ dropdown: classes.popoverDropdown }}
		>
			<Popover.Target>
				<UnstyledButton>
					<Center className={classes.center} inline>
						{isEmoji(emojiIconValue) ? (
							<Text className={classes.emoji}>{selectedEmoji}</Text>
						) : (
							<Icon
								name={selectedIcon}
								className={classes.icon}
								iconWidth={size}
								iconHeight={size}
								iconPadding={0}
							/>
						)}
					</Center>
				</UnstyledButton>
			</Popover.Target>
			<Popover.Dropdown>
				<Box>
					<Tabs color="dark" value={activeTab} onTabChange={setActiveTab}>
						<TabsList tabs={tabsList} />

						<Tabs.Panel value="emoji" pt="sm">
							<Picker
								data={data}
								autoFocus
								onEmojiSelect={handleEmojiSelect}
								searchable
								size="small"
								previewEmoji={selectedEmoji}
								theme={colorScheme}
							/>
						</Tabs.Panel>

						<Tabs.Panel value="icon" pt="sm">
							<IconSelector
								value={{ iconName: selectedIcon, iconColor: selectedIconColor }}
								onSelect={handleIconSelect}
							/>
						</Tabs.Panel>
					</Tabs>
				</Box>
			</Popover.Dropdown>
		</Popover>
	);
}

export default IconEmojiSelector;
