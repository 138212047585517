import { escapeRegExp } from 'lodash-es';
import { ENTITY_PAGE_PATHS } from '../../../../SecodaAppShell/SecodaNavBar/constants';
import { parseDomain } from './domains';

// @ts-expect-error TS(2339): Property 'env' does not exist on type 'Window & ty... Remove this comment to see the full error message
const env = typeof window !== 'undefined' ? window.env : process.env;

export function cdnPath(path: string): string {
	return `${env.CDN_URL}${path}`;
}

export function isInternalUrl(href: string) {
	if (href[0] === '/') {
		return true;
	}
	const outline =
		typeof window !== 'undefined'
			? parseDomain(window.location.href)
			: undefined;
	const parsed = parseDomain(href);

	if (
		parsed &&
		outline &&
		parsed.subdomain === outline.subdomain &&
		parsed.domain === outline.domain &&
		parsed.tld === outline.tld
	) {
		return true;
	}

	return false;
}

/**
 * Returns true if the given string is a url.
 *
 * @param text The url to check.
 * @param options Parsing options.
 * @returns True if a url, false otherwise.
 */
export function isUrl(text: string, options?: { requireHostname: boolean }) {
	if (text.match(/\n/)) {
		return false;
	}

	try {
		const url = new URL(text);
		// eslint-disable-next-line no-script-url
		const blockedProtocols = ['javascript:', 'file:', 'vbscript:', 'data:'];

		if (blockedProtocols.includes(url.protocol)) {
			return false;
		}
		if (url.hostname) {
			return true;
		}

		return (
			url.protocol !== '' &&
			(url.pathname.startsWith('//') || url.pathname.startsWith('http')) &&
			!options?.requireHostname
		);
	} catch (err) {
		return false;
	}
}

/**
 * For use in the editor, this function will ensure that a url is
 * potentially valid, and filter out unsupported and malicious protocols.
 *
 * @param url The url to sanitize
 * @returns The sanitized href
 */
export function sanitizeUrl(url: string | null | undefined) {
	if (!url) {
		return undefined;
	}

	if (
		!isUrl(url, { requireHostname: false }) &&
		!url.startsWith('/') &&
		!url.startsWith('#') &&
		!url.startsWith('mailto:') &&
		!url.startsWith('sms:') &&
		!url.startsWith('fax:') &&
		!url.startsWith('tel:')
	) {
		return `https://${url}`;
	}
	return url;
}

export function urlRegex(url: string | null | undefined): RegExp | undefined {
	if (!url || !isUrl(url)) {
		return undefined;
	}

	const urlObj = new URL(sanitizeUrl(url) as string);

	return new RegExp(escapeRegExp(`${urlObj.protocol}//${urlObj.host}`));
}

export function isResourceUrl(url: string | null) {
	if (!url || !url.length) {
		return false;
	}

	if (isInternalUrl(url)) {
		const match = ENTITY_PAGE_PATHS.some((path) => {
			const regexExpr = `${path}/([^ ]*)?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$`;

			return url.match(regexExpr);
		});

		return match ? url : null;
	}

	return false;
}
