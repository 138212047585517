import { observer } from 'mobx-react-lite';
import { CommentStoreProvider } from '../../components/Comment/context';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { IGlossaryTermPageProps } from './GlossaryTermPage';
import GlossaryTermPage from './GlossaryTermPage';

function GlossaryTermPageWithCommentStore({
	id: propsId,
}: IGlossaryTermPageProps) {
	const paramsId = useParamsIdSuffixUuid();
	const id = propsId || paramsId;

	return (
		<CommentStoreProvider>
			<GlossaryTermPage id={id} />
		</CommentStoreProvider>
	);
}

export default observer(GlossaryTermPageWithCommentStore);
