// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// NOTE: this has to be a JS file because @observablehq/plot`s typings are all messed up and extending Plot.Mark in TS is just not happening
import * as Plot from '@observablehq/plot';
import ReactDOM from 'react-dom';
import { anchorX, anchorY, applyFrameAnchor } from './common.js';

let TOOLTIP_CONTAINER = null;

class Tooltip extends Plot.Mark {
	tooltipRenderer = null;

	constructor(data, options = {}) {
		const { x, y, tooltipRenderer } = options;
		super(
			data,
			{
				x: {
					value: x,
					scale: 'x',
					optional: true,
				},
				y: {
					value: y,
					scale: 'y',
					optional: true,
				},
			},
			options
		);

		this.tooltipRenderer = tooltipRenderer;
	}

	render(index, _, values, dimensions, context) {
		this.cleanUp(context);

		// set styles for parent node
		if (
			context.ownerSVGElement &&
			context.ownerSVGElement.parentNode &&
			this.tooltipRenderer
		) {
			// eslint-disable-next-line no-param-reassign
			context.ownerSVGElement.parentNode.style.position = 'relative';

			const boundingRect =
				context.ownerSVGElement.parentNode.getBoundingClientRect();

			const [cx, cy] = applyFrameAnchor({ frameAnchor: 'middle' }, dimensions);
			const px = anchorX(values, cx);
			const py = anchorY(values, cy);

			// just a side-effect. this mark doesn't render anything into the SVG
			const reactElement = this.tooltipRenderer({
				x: px(index) + boundingRect.left,
				y: py(index) + boundingRect.top,
				dataIndex: index,
			});

			if (reactElement === null) {
				return null;
			}

			TOOLTIP_CONTAINER = document.createElement('div');
			context.ownerSVGElement.parentNode.appendChild(TOOLTIP_CONTAINER);
			ReactDOM.render(reactElement, TOOLTIP_CONTAINER);
		}

		return null;
	}

	// eslint-disable-next-line class-methods-use-this
	cleanUp(context) {
		if (
			context.ownerSVGElement &&
			context.ownerSVGElement.parentNode &&
			TOOLTIP_CONTAINER
		) {
			ReactDOM.unmountComponentAtNode(TOOLTIP_CONTAINER);
			TOOLTIP_CONTAINER.remove();
		}
	}
}

export function tooltip(data, options = {}) {
	return new Tooltip(data, options);
}
