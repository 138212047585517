import { createStyles, Stack } from '@mantine/core';
import { FilterOptionType } from '@repo/common/components/Filter/types.ts';
import { debounce } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import type { TimeRange } from '../../api';
import {
	useMeasurementsChartData,
	useMonitor,
	useUpdateMonitor,
} from '../../api';
import { useMonitorTarget } from '../../api/hooks/monitoring/useMonitorTarget.ts';
import { AIAssistantSidebar } from '../../components/AIAssistant/AIAssistantSidebar/AIAssistantSidebar.tsx';
import { DOCUMENTATION_WIDTH } from '../../components/Documentation/constants.ts';
import { EntityPageDescription } from '../../components/EntityPageLayout';
import EntityPageTitle from '../../components/EntityPageLayout/EntityPageTitle';
import {
	SearchFilterV2Store,
	SearchFilterV2StoreContext,
} from '../../components/Filter/store.ts';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../../components/PageLayout';
import {
	RightSidebarMode,
	rightSidebarStore,
	RightSidebarWrapper,
} from '../../components/RightSidebar';
import { useFeatureFlags } from '../../utils/featureFlags';
import MeasurementChart from './components/MeasurementsChart';
import MonitorErrorDrawer from './components/MonitorErrorDrawer';
import MeasurementsTable from './containers/MeasurementsTable';
import MonitorConfigurationSidebar from './containers/MonitorConfigurationSidebar';
import MonitorPageNavbar from './containers/MonitorPageNavbar';
import { getMonitorMetricTypeInformation } from './utils';
import IncidentDrawer from './v2/IncidentDrawer';
import { IncidentDrawerProvider } from './v2/IncidentDrawerContext';
import V2ChartWrapper from './v2/V2ChartWrapper.tsx';
import V2MeasurementsTable from './v2/V2MeasurementsTable.tsx';

export const useMonitorPageStyles = createStyles((theme) => ({
	contentLayout: {
		margin: '0 auto',
		width: '100%',
		maxWidth: DOCUMENTATION_WIDTH,
		alignItems: 'left',
	},
}));

export interface IMonitorPageProps {
	id?: string;
}

function MonitorPage({ id: propsId }: IMonitorPageProps) {
	const { classes } = useMonitorPageStyles();
	const { id: paramsId } = useParams();
	const id = propsId || paramsId;

	const [timeRange, setTimeRange] = React.useState<TimeRange>('7');
	const [errorDrawerOpen, setErrorDrawerOpen] = useState<boolean>(false);
	const { monitoringV2 } = useFeatureFlags();

	const toggleErrorDrawer = () => {
		setErrorDrawerOpen((prevState) => !prevState);
	};

	const { data: monitor } = useMonitor({
		id: id as string, // 'id' cannot be undefined here as enabled blocks it
		options: {
			enabled: Boolean(id),
		},
	});

	const { mutateAsync: updateMonitor } = useUpdateMonitor({});

	const { data: targetEntity } = useMonitorTarget({
		monitorId: id as string,
		options: {
			enabled: Boolean(id),
		},
	});

	const { data: measurements } = useMeasurementsChartData({
		monitorId: monitor?.id,
		timeRange,
	});

	const { description, label: chartTitle } = getMonitorMetricTypeInformation(
		monitor?.metric_type,
		monitor?.description
	);

	// Re-structure the monitor name as a Entity for use in EntityPageTitle
	const entityTitle = {
		title: (monitor?.name ?? '') as string,
		title_cased: (monitor?.name ?? '') as string,
	};

	// Debounce handleRename since it's called for each keystroke. We don't want to fire off
	// a save call for each keystroke, but wait 500ms before saving
	const handleRename = debounce(async (value: string) => {
		if (!monitor?.id) {
			return;
		}

		await updateMonitor({
			data: {
				id: monitor.id,
				name: value,
			},
		});
	}, 500);

	async function handleChangeDescription(value: string) {
		if (!monitor?.id) {
			return;
		}

		await updateMonitor({
			data: {
				id: monitor.id,
				description: value,
			},
		});
	}

	const measurementFilterStore = useMemo(
		() =>
			new SearchFilterV2Store([
				FilterOptionType.HAS_INCIDENT,
				FilterOptionType.RUN_DATE,
			]),
		[]
	);
	return (
		<IncidentDrawerProvider>
			<SearchFilterV2StoreContext.Provider value={measurementFilterStore}>
				<PageLayoutOuterWrapper>
					<Helmet>
						<title>{monitor?.name || 'Monitor'}</title>
					</Helmet>
					<PageLayoutWrapper name="monitor">
						<PageLayoutContentWrapper name="monitor">
							<MonitorPageNavbar
								monitor={monitor}
								targetEntity={targetEntity}
							/>
							<PageLayoutContent>
								<Stack className={classes.contentLayout}>
									{monitor && (
										<>
											<EntityPageTitle
												entity={entityTitle}
												onChange={handleRename}
												placeholder="Enter Monitor Name"
												isReadOnly={false}
												icon={undefined}
											/>
											<EntityPageDescription
												entityId={undefined}
												// eslint-disable-next-line react/jsx-no-bind
												onChange={handleChangeDescription}
												description={description}
											/>
										</>
									)}
									{monitor &&
										(monitoringV2 ? (
											<>
												<V2ChartWrapper monitor={monitor} />
												<V2MeasurementsTable monitor={monitor} />
											</>
										) : (
											<>
												<MeasurementsTable monitorId={monitor?.id} />
												<MeasurementChart
													key={`${chartTitle}-${timeRange}`}
													timeRange={timeRange}
													onTimeRangeChange={setTimeRange}
													title={chartTitle}
													values={measurements ?? []}
													learningMode={monitor?.status === 'learning'}
												/>
											</>
										))}
								</Stack>
							</PageLayoutContent>
						</PageLayoutContentWrapper>
						<IncidentDrawer targetEntity={targetEntity} />
						<MonitorErrorDrawer
							monitor={monitor}
							open={errorDrawerOpen}
							onClose={toggleErrorDrawer}
						/>
						<RightSidebarWrapper>
							{rightSidebarStore.mode === RightSidebarMode.INFO && (
								<MonitorConfigurationSidebar
									monitorId={monitor?.id}
									targetEntity={targetEntity}
								/>
							)}
							{rightSidebarStore.mode === RightSidebarMode.AI && (
								<AIAssistantSidebar />
							)}
						</RightSidebarWrapper>
					</PageLayoutWrapper>
				</PageLayoutOuterWrapper>
			</SearchFilterV2StoreContext.Provider>
		</IncidentDrawerProvider>
	);
}

export default observer(MonitorPage);
