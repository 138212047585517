import { indexOf } from 'lodash-es';
import { DATA_QUALITY_TOP_KEYS } from '../constants';
import type { DataQualityBreakdownItem } from '../types';
import { DataQualityBreakdownBody } from './DataQualityBreakdownBody';
import { DataQualityBreakdownItem as DataQualityBreakdownItemComp } from './DataQualityBreakdownItem';

interface DataQualityBreakdownProps {
	items: DataQualityBreakdownItem[];
	onReportPage?: boolean;
}

export function DataQualityBreakdown({
	items,
	onReportPage = false,
}: DataQualityBreakdownProps) {
	return (
		<DataQualityBreakdownBody>
			{items
				.sort(
					(a, b) =>
						indexOf(DATA_QUALITY_TOP_KEYS, a.key) -
						indexOf(DATA_QUALITY_TOP_KEYS, b.key)
				)
				.map((item) => (
					<DataQualityBreakdownItemComp
						key={item.key}
						item={item}
						onReportPage={onReportPage}
					/>
				))}
		</DataQualityBreakdownBody>
	);
}
