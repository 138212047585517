import { Box, createStyles, getStylesRef } from '@mantine/core';
import { IconButton } from '@repo/foundations';
import { useCallback } from 'react';
import {
	IMetric,
	useAuthUser,
	useUpdateMetric,
	useWorkspace,
} from '../../../../../../api';
import { useGetOrCreateMetric } from '../../../../../../api/hooks/metricTerm/useGetOrCreateMetric';
import { trackEvent } from '../../../../../../utils/analytics';
import { useFeatureFlags } from '../../../../../../utils/featureFlags';
import { entityIdFromCurrentPath } from '../../../../../../utils/shared.utils';
import SqlCharts from '../../../../../Sql/SqlCharts';
import {
	QueryBlockContainer,
	QueryBlockContainerProps,
} from '../QueryBlockContainer/QueryBlockContainer';

const useStyles = createStyles((theme) => ({
	container: {
		position: 'relative',

		[`&:hover .${getStylesRef('button')}`]: {
			display: 'block',
		},
	},
	deleteButton: {
		display: 'none',
		position: 'absolute',
		top: -(theme.other.space[6] / 3),
		right: -(theme.other.space[6] / 3),
		ref: getStylesRef('button'),
	},
}));

export interface MetricContainerProps
	extends Pick<
		QueryBlockContainerProps,
		'onDuplicate' | 'onCopyLink' | 'onAddGraph'
	> {
	id: string;
	// integrationId is only used at the creation of a new query block
	integrationId?: string;
	readOnly: boolean;
	withQuery?: boolean;
	withChart?: boolean;
	onDelete: VoidFunction;
	isSelected?: boolean;
}

export function MetricContainer({
	id,
	integrationId,
	readOnly,
	withQuery,
	withChart,
	onDelete,
	isSelected,
	onDuplicate,
	onCopyLink,
	onAddGraph,
}: MetricContainerProps) {
	const { classes } = useStyles();
	const { workspace } = useWorkspace();
	const { user } = useAuthUser();
	const { queryChartBlocks } = useFeatureFlags();
	const { data: metric } = useGetOrCreateMetric({
		id,
		integration: !!integrationId ? integrationId : undefined,
		hidden: true,
		parent: entityIdFromCurrentPath(),
	});

	const { mutateAsync } = useUpdateMetric({});

	const updateMetric = useCallback(
		async (entries: Partial<IMetric>) => {
			if (!metric) {
				return;
			}

			trackEvent(
				'metric/embedded/update',
				{
					id: metric.id,
				},
				user,
				workspace
			);
			const updated = Object.entries(entries).reduce(
				(acc, [key, value]) => ({
					...acc,
					[key]: value,
				}),
				{}
			);
			await mutateAsync({ data: { id, ...updated }, optimisticUpdate: false });
		},
		[id, metric, mutateAsync, user, workspace]
	);

	if (!metric) {
		return null;
	}

	if (queryChartBlocks && withQuery && !withChart) {
		return (
			<QueryBlockContainer
				readOnly={readOnly}
				metric={metric}
				onChange={updateMetric}
				onDelete={onDelete}
				onDuplicate={onDuplicate}
				onCopyLink={onCopyLink}
				onAddGraph={onAddGraph}
			/>
		);
	}

	return (
		<Box className={classes.container}>
			<SqlCharts
				metric={metric}
				withQuery={withQuery}
				withChart={withChart}
				isSelected={isSelected}
				canEdit={!readOnly}
				onChange={updateMetric}
			/>
			{!readOnly && (
				<IconButton
					iconName="trash"
					size="sm"
					onClick={onDelete}
					className={classes.deleteButton}
				/>
			)}
		</Box>
	);
}
