import { Group, Stack } from '@mantine/core';
import { LiteWorkspace, useApiSuperuserGetWorkspace } from '@repo/api-codegen';
import { Button, Icon, ListBox, Text } from '@repo/foundations';
import { useState } from 'react';
import { WorkspaceLogo } from '../../../components/SecodaAppShell/SideBar/WorkspaceSelector/WorkspaceLogo';

interface SuperuserWorkspaceSelectorProps {
	workspace?: LiteWorkspace;
	selected?: LiteWorkspace;
	setSelected: (workspace?: LiteWorkspace) => void;
}

export function SuperuserWorkspaceSelector({
	workspace,
	selected,
	setSelected,
}: SuperuserWorkspaceSelectorProps) {
	const [opened, setOpened] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const { data: workspaces } = useApiSuperuserGetWorkspace(
		{
			queryParams: {
				search_term: searchTerm,
			},
		},
		{
			select: (data) => {
				if (selected) {
					return [selected, ...data.filter((i) => i.id !== selected.id)];
				}
				return data;
			},
		}
	);

	return (
		<ListBox
			opened={opened}
			onOpenChange={(newOpened) => {
				setSearchTerm('');
				setOpened(newOpened);
			}}
			closeOnEscape
			placement="bottom-start"
		>
			<ListBox.Target>
				<Button onClick={() => setOpened(!opened)} h={35}>
					{selected ? (
						<Group>
							<WorkspaceLogo
								logo={selected?.logo || '/images/logos/cfsecoda.webp'}
								size={20}
							/>
							{selected.name}
						</Group>
					) : (
						'Choose workspace'
					)}
				</Button>
			</ListBox.Target>
			<ListBox.ItemsDropdown
				usePortal
				search={{
					onChange: setSearchTerm,
					value: searchTerm,
					placeholder: 'Search workspace...',
				}}
				items={workspaces}
				renderItem={(item) => (
					<ListBox.Item
						rightSection={selected?.id === item?.id && <Icon name="check" />}
						key={item.id}
						onClick={() => {
							setSelected(selected?.id === item?.id ? undefined : item);
							setOpened(false);
						}}
					>
						<Group>
							<WorkspaceLogo
								logo={workspace?.logo || '/images/logos/cfsecoda.webp'}
								size={20}
							/>
							<Stack spacing={0}>
								<Text size="sm" weight="bold">
									{' '}
									{item.name}
								</Text>
								<Text size="sm">{item.id}</Text>
							</Stack>
						</Group>
					</ListBox.Item>
				)}
			/>
		</ListBox>
	);
}
