import { Card, createStyles, Group, Stack, ThemeIcon } from '@mantine/core';
import { Text } from '@repo/foundations';
import { IconCheck, IconX } from '@tabler/icons-react';
import moment from 'moment';
import type { ILineageTableTest } from '../../../api';
import { EntityType } from '../../../lib/types';
import IntegrationLogo from '../../IntegrationLogo';
import { isTestRunSuccessful } from '../EntityNode/EntityNodeHeader/utils';
import LineageListItemDetails from '../Legacy/LineageListItemDetails';
import LineageListItemTitle from '../Legacy/LineageListItemTitle';

interface ITestRunItemProps {
	test: ILineageTableTest;
}

const useStyles = createStyles({
	group: {
		width: '100%',
	},
});

function TestRunItem({ test }: ITestRunItemProps) {
	const { classes } = useStyles();

	const getTestColor = (status: string) =>
		isTestRunSuccessful(status) ? 'green' : 'red';

	const getTestIcon = (status: string) =>
		isTestRunSuccessful(status) ? <IconCheck size={12} /> : <IconX size={12} />;

	return (
		<Card withBorder>
			<Group className={classes.group} position="apart">
				<Group>
					<IntegrationLogo
						entityType={EntityType.test}
						integrationId={test.integration || test.integration_id}
						width={20}
						height={20}
					/>
					<Stack spacing={0}>
						<LineageListItemTitle title={test.title} length={64} />
						<LineageListItemDetails
							entityType={EntityType.test}
							metadata={
								test.suite_title ? { suite_title: test.suite_title } : {}
							}
						/>
					</Stack>
				</Group>
				<Group position="apart">
					<Text size="xs" color="text/secondary/default">
						{test?.latest_run?.run_created_at
							? moment(test.latest_run.run_created_at).fromNow()
							: 'N/A'}
					</Text>
					{test?.latest_run?.status && (
						<ThemeIcon
							size="xs"
							variant="light"
							radius="sm"
							color={getTestColor(test.latest_run.status)}
						>
							{getTestIcon(test?.latest_run?.status)}
						</ThemeIcon>
					)}
				</Group>
			</Group>
		</Card>
	);
}

export default TestRunItem;
