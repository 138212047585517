import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import { IUserGroup } from '../../api';
import type { EntityType } from '../types';
import { BaseModel } from './base';

export class UserGroup extends BaseModel {
	// @ts-expect-error TS(2564): Property 'id' has no initializer and is not defini... Remove this comment to see the full error message
	id: string;

	// @ts-expect-error TS(2564): Property 'users' has no initializer and is not def... Remove this comment to see the full error message
	users: string[];

	// @ts-expect-error TS(2564): Property 'name' has no initializer and is not defi... Remove this comment to see the full error message
	name: string;

	// @ts-expect-error TS(2564): Property 'description' has no initializer and is n... Remove this comment to see the full error message
	description: string;

	// @ts-expect-error TS(2564): Property 'icon' has no initializer and is not defi... Remove this comment to see the full error message
	icon: string;

	// @ts-expect-error TS(2564): Property 'snowflake_role' has no initializer and i... Remove this comment to see the full error message
	snowflake_role: string;

	constructor(obj: UserGroup | IUserGroup) {
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get namespace() {
		return ['auth', 'group'];
	}

	static get fields() {
		return BaseModel.fields.concat([
			'name',
			'description',
			'users',
			'icon',
			'snowflake_role',
		]);
	}

	async tabs() {
		const { data } = await axios.get<{ [key in EntityType]?: number }>(
			`${this.apiPath}tabs`
		);
		return data;
	}
}
