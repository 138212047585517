import { useNavigate, useParams } from 'react-router';

import { Stack } from '@mantine/core';
import { useCallback, useEffect, useMemo } from 'react';
import type { IGlossaryTerm } from '../../api';
import { useAuthUser } from '../../api';
import type {
	FetchModelInfiniteListHook,
	FetchModelList,
} from '../../api/factories/types';
import {
	fetchCatalogList,
	useCatalogInfiniteList,
} from '../../api/hooks/resourceCatalog';
import { useTeamEmptyState } from '../../api/hooks/team/emptyStates';
import { EducationModal } from '../../components/EducationModal/EducationModal';
import type { ButtonDetails } from '../../components/EmptyState';
import { EmptyState } from '../../components/EmptyState';
import { entityModalStore } from '../../components/EntityModal/store';
import { TableV2 } from '../../components/TableV2';
import type { OnCellClickHandlerParams } from '../../components/TableV2/types';
import { useEntityBreadcrumbTeam } from '../../hooks/useEntityBreadcrumbs';
import { EntityType } from '../../lib/types';
import { useHomepageTeam } from '../HomePage/hooks/useHomepageTeam';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import { useActions, useColumns } from './TeamGlossaryTermsPage.hooks';

export default function TeamGlossaryTermsPage() {
	const { teamId } = useParams();
	const { user, isAdminUser, isViewerOrGuestUser } = useAuthUser();
	const { isTeamEditorUser } = useHomepageTeam();

	const { classes } = useStyles();

	const columns = useColumns();
	const actions = useActions();

	const navigate = useNavigate();

	const { setTeamBreadcrumb } = useEntityBreadcrumbTeam();

	useEffect(() => {
		// If the user opens a entity page from this context we want the team
		// breadcrumb to match the team of this page.
		if (teamId) {
			setTeamBreadcrumb(teamId);
		}
	}, [setTeamBreadcrumb, teamId]);

	const handleCreateGlossaryTermClick = useCallback(async () => {
		entityModalStore.open({
			type: EntityType.glossary,
			teamId,
			metadata: {
				owners: [user.id],
			},
		});
	}, [teamId, user.id]);

	const emptyState = useTeamEmptyState(teamId as string, 'glossary', {
		enabled: !isViewerOrGuestUser,
	});

	const handleCellClick = useCallback(
		({ column, record }: OnCellClickHandlerParams<IGlossaryTerm>) => {
			if (column.navigate !== false) {
				navigate(`/glossary/${record.id}`);
			}
		},
		[navigate]
	);

	const buttons: ButtonDetails[] = useMemo(
		() => [
			{
				name: 'Learn more',
				action: () => {
					window.open('https://docs.secoda.co/features/glossary', '_blank');
				},
				isPrimary: false,
				size: 'md',
				leftIconName: 'externalLink',
			},
			...(isTeamEditorUser || isAdminUser
				? [
						{
							name: 'Create glossary term',
							action: handleCreateGlossaryTermClick,
							isPrimary: true,
							size: 'md',
						} as ButtonDetails,
					]
				: []),
		],
		[handleCreateGlossaryTermClick, isAdminUser, isTeamEditorUser]
	);

	if (!isViewerOrGuestUser) {
		if (emptyState?.is_empty) {
			return (
				<>
					<EmptyState
						illustrationName="glossary"
						title="Welcome to Glossary"
						description="Keep a consistent set of shared data terms across your team with the glossary."
						includeGoBack={false}
						buttons={buttons}
						stateHeight="80vh"
						size="lg"
					/>
					<EducationModal modalKey="education-modal-of-glossary-terms" />
				</>
			);
		}
	}

	return (
		<Stack className={classes.wrapper}>
			<TableV2<IGlossaryTerm>
				withCustomProperties={EntityType.glossary}
				pluralTypeString="terms"
				withCsvExport
				withInfiniteScroll
				withQuickActions={[
					'actions::sidebar',
					'actions::ai',
					'actions::verified',
					'actions::delete',
				]}
				usePaginationList={
					useCatalogInfiniteList as FetchModelInfiniteListHook<IGlossaryTerm>
				}
				fetchPaginationList={
					fetchCatalogList as unknown as FetchModelList<IGlossaryTerm>
				}
				withCheckbox
				withInteractiveHeader
				withSearch
				columns={columns}
				withActions={actions}
				onCellClick={handleCellClick}
				columnVisibility={{
					catalogType: EntityType.glossary,
					catalogServerType: EntityType.glossary,
				}}
				nestingFilter="parent_id"
				defaultRequiredSearchParamsNesting={{
					entity_type: EntityType.glossary,
					calculate_children_count: true,
				}}
				// Don't show nested terms at the root level.
				defaultRequiredCatalogFilters={{
					operands: [
						{
							operator: 'not',
							field: 'parent_id',
							operands: [],
						},
					],
				}}
				defaultRequiredSearchParams={{
					team_id: teamId,
					entity_type: EntityType.glossary,
					calculate_children_count: true,
				}}
			/>
			<EducationModal modalKey="education-modal-of-glossary-terms" />
		</Stack>
	);
}
