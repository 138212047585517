import { createStyles, Group, Stack, Tabs } from '@mantine/core';
import { PlainButton, Text } from '@repo/foundations';
import { useState } from 'react';
import { IIntegration } from '../../../../../api';
import { IntegrationSpec } from '../../../../../interfaces/IntegrationSpec';
import {
	formatShortTimeDifference,
	isBeforeRightNow,
} from '../../../../../utils/time';
import { SyncTable } from './SyncTable';

interface SyncPanelProps {
	integration: IIntegration;
	spec: IntegrationSpec;
	navigateToSchedule: () => void;
}

const useStyles = createStyles((theme) => ({
	panel: {
		marginTop: theme.spacing['2xl'],
	},
}));

export function SyncPanel({
	integration,
	spec,
	navigateToSchedule,
}: SyncPanelProps) {
	const { classes, theme } = useStyles();

	const [activeTab, setActiveTab] = useState<string | null>('PULL');

	const renderSubHeader = () => {
		const nextRun =
			activeTab === 'pull'
				? integration.scheduled_extractions_next_run
				: integration.scheduled_push_metadata_next_run;

		if (nextRun && !isBeforeRightNow(nextRun)) {
			return (
				<Group spacing={theme.spacing['2xs']} noWrap>
					<Text size="sm" weight="semibold">
						{`Next ${activeTab === 'pull' ? 'pull' : 'sync'} will run ${formatShortTimeDifference(nextRun, true)}`}
					</Text>
					<PlainButton onClick={navigateToSchedule}>
						Update schedule
					</PlainButton>
				</Group>
			);
		}

		return null;
	};

	const renderContent = () => {
		if (spec.value.disablePullMetadata) {
			return <SyncTable integration={integration} type="PUSH" />;
		} else if (spec.value.supportPushMetadata) {
			return (
				<Tabs
					classNames={classes}
					color="dark"
					defaultValue="pull"
					value={activeTab}
					onTabChange={setActiveTab}
				>
					<Tabs.List grow>
						<Tabs.Tab value="PULL">Pull</Tabs.Tab>
						<Tabs.Tab value="PUSH">Push</Tabs.Tab>
					</Tabs.List>
					<Tabs.Panel value="PULL">
						<SyncTable integration={integration} type="PULL" />
					</Tabs.Panel>
					<Tabs.Panel value="PUSH">
						<SyncTable integration={integration} type="PUSH" />
					</Tabs.Panel>
				</Tabs>
			);
		}
		return <SyncTable integration={integration} type="PULL" />;
	};

	return (
		<Stack>
			{renderSubHeader()}
			{renderContent()}
		</Stack>
	);
}
