import { ESQueryModel, useApiGetQueries } from '@repo/api-codegen';
import { useMemo } from 'react';
import { ApiCatalogSort } from '../../api';
import {
	AverageRunTimeRender,
	ReadOnlyQueryFrequentUsersRender,
	SQLRender,
	TotalRunsRender,
	TotalRunTimeRender,
} from '../../components/TableV2/render.tsx';
import { ExtendedDataTableColumn } from '../../components/TableV2/types.ts';

export const useWorkspaceQueryList = (lookBackDays: number) =>
	function (props: {
		page?: number | undefined;
		filters?: { search_term?: string; sort?: ApiCatalogSort };
	}) {
		const { page, filters } = props;

		return useApiGetQueries({
			queryParams: {
				page: page || 1,
				search_term: filters?.search_term,
				page_size: 50,
				lookback_days: lookBackDays,
				sort_by: JSON.stringify(filters?.sort) || JSON.stringify({}),
			},
		});
	};

export const useColumns = (): ExtendedDataTableColumn<ESQueryModel>[] =>
	useMemo(
		() => [
			{
				accessor: 'sql',
				title: 'SQL',
				width: 200,
				render: SQLRender,
				sortable: false,
			},
			{
				accessor: 'frequent_users',
				title: 'Users',
				width: 40,
				render: ReadOnlyQueryFrequentUsersRender,
				sortable: false,
			},
			{
				accessor: 'average_runtime',
				title: 'Average runtime',
				width: 40,
				render: AverageRunTimeRender,
				sortable: true,
			},
			{
				accessor: 'total_runtime',
				title: 'Total runtime',
				width: 40,
				render: TotalRunTimeRender,
				sortable: true,
			},
			{
				accessor: 'total_runs',
				title: 'Total runs',
				width: 40,
				render: TotalRunsRender,
				sortable: true,
			},
		],
		[]
	);

export const useActions = () => [];
