import { IconButton } from '@repo/foundations';
import { ISecodaEntity, useAuthUser } from '../../api';
import type { SecodaEntity } from '../../lib/models';
import { trackEvent } from '../../utils/analytics';

export interface IEntityLinkProps {
	entity: SecodaEntity | ISecodaEntity;
}

function EntityLink({ entity }: IEntityLinkProps) {
	const { user, workspace } = useAuthUser();
	const onClick = () => {
		trackEvent(`${entity.entity_type}/link/click`, {}, user, workspace!);
		window.open(entity.url, '_blank', 'noopener,noreferrer');
	};

	return (
		<IconButton
			tooltip={`Open ${entity.title}`}
			onClick={onClick}
			iconName="externalLink"
			variant="tertiary"
		/>
	);
}

export default EntityLink;
