import { toggleMark } from 'prosemirror-commands';
import type { InputRule } from 'prosemirror-inputrules';
import type { MarkSpec } from 'prosemirror-model';
import type { Command } from 'prosemirror-state';
import markInputRule from '../lib/markInputRule';
import Mark, { MarkOptions } from './Mark';

export default class Italic extends Mark {
	get name() {
		return 'em';
	}

	get schema(): MarkSpec {
		return {
			parseDOM: [{ tag: 'i' }, { tag: 'em' }],
			toDOM: () => ['em'],
		};
	}

	inputRules({ type }: MarkOptions): InputRule[] {
		return [
			markInputRule(/(?:^|[\s])(_([^_]+)_)$/, type),
			markInputRule(/(?:^|[^*])(\*([^*]+)\*)$/, type),
		];
	}

	keys({ type }: MarkOptions): Record<string, Command> {
		return {
			'Mod-i': toggleMark(type),
			'Mod-I': toggleMark(type),
		};
	}

	toMarkdown() {
		return {
			open: '*',
			close: '*',
			mixable: true,
			expelEnclosingWhitespace: true,
		};
	}

	parseMarkdown() {
		return { mark: 'em' };
	}
}
