import { Group, Image, Menu, Tooltip, useMantineTheme } from '@mantine/core';
import { useGetPersonas } from '@repo/api-codegen';
import { useCallback, useState } from 'react';

import { Button, Icon, IconButton, Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarSidesheetControlsWrapper,
	NavBarWrapper,
} from '.';
import { useActiveAiPersona } from '../../../api/hooks/ai/useActiveAiPersona';
import { useFeatureFlags } from '../../../utils/featureFlags';
import AIShareButton from '../../AIAssistant/AIShareButton';
import { rightSidebarStore } from '../../RightSidebar';

function AIPageNavBar() {
	const navigate = useNavigate();
	const theme = useMantineTheme();
	const { data: personas } = useGetPersonas({});
	const { aiPersonas } = useFeatureFlags();

	const { collapsed, setCollapsed } = rightSidebarStore;
	const { setActiveAiPersonaId, activeAiPersonaId } = useActiveAiPersona();

	const [activePersona, setActivePersona] = useState(
		personas?.find((persona) => persona.id === activeAiPersonaId)
	);

	const handleNewConversation = useCallback(() => {
		navigate('/ai/new');
	}, [navigate]);

	const handleSetActivePersonaId = (id: string) => () => {
		setActiveAiPersonaId(id);
		setActivePersona(personas?.find((persona) => persona.id === id));
	};

	const location = useLocation();

	const AIPromptId = () => {
		// remove any trailing slashes
		const path = location.pathname.replace(/\/$/, '');

		if (path === '/ai/new') {
			return '';
		}

		const parts = path.split('/');
		return parts[parts.length - 1];
	};

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				{aiPersonas && (
					<Menu position="bottom-start">
						<Menu.Target>
							<Button variant="tertiary">
								<Group spacing={theme.spacing.xs}>
									<Image
										src={
											activePersona?.avatar_url || '/images/logos/cfsecoda.webp'
										}
										width={16}
										height={16}
										radius={theme.other.space[1]}
									/>
									{!!activePersona && (
										<Text>{activePersona.name || 'New Agent'}</Text>
									)}
									{!activePersona && <Text>Secoda AI</Text>}
									<Icon name="chevronDown" color="icon/secondary/default" />
								</Group>
							</Button>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item
								onClick={handleSetActivePersonaId('')}
								data-testid="ai-persona-all"
							>
								<Tooltip
									label="Default Secoda AI"
									position="right"
									multiline
									w={220}
								>
									<Group spacing={theme.spacing.xs}>
										<Image
											src={'/images/logos/cfsecoda.webp'}
											width={24}
											height={24}
											radius={theme.other.space[1]}
										/>
										<Text>Secoda AI</Text>
									</Group>
								</Tooltip>
							</Menu.Item>
							{personas?.map((persona) => (
								<Menu.Item
									key={persona.id}
									onClick={handleSetActivePersonaId(persona.id || '')}
								>
									<Tooltip
										label={persona.description}
										position="right"
										multiline
										w={220}
									>
										<Group spacing={theme.spacing.xs}>
											<Image
												src={
													persona.avatar_url || '/images/logos/cfsecoda.webp'
												}
												width={24}
												height={24}
												radius={theme.other.space[1]}
											/>
											<Text>{persona.name || 'New Agent'}</Text>
										</Group>
									</Tooltip>
								</Menu.Item>
							))}
						</Menu.Dropdown>
					</Menu>
				)}
				{!aiPersonas && <Button variant="tertiary">Secoda AI</Button>}
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				<AIShareButton promptId={AIPromptId()} key={AIPromptId()} />
				<Button onClick={handleNewConversation} variant="primary">
					New chat
				</Button>
			</NavBarRightSideWrapper>
			<NavBarSidesheetControlsWrapper>
				<IconButton
					iconName="history"
					variant="tertiary"
					tooltip={collapsed ? 'Show history' : 'Hide history'}
					highlight={!collapsed || false}
					onClick={() => setCollapsed(!collapsed)}
					data-testid="ai-history-toggle"
				/>
			</NavBarSidesheetControlsWrapper>
		</NavBarWrapper>
	);
}

export default observer(AIPageNavBar);
