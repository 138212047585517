import { Box, createStyles, Group, Loader, Stack } from '@mantine/core';
import type {
	AlterEntityPermissionRequest,
	EntityPermissionOrMembershipOut,
	GroupedPermissionsResponse,
} from '@repo/api-codegen';
import {
	apiQueryKey,
	useApiAlterEntityPermission,
	useApiGetEntityGroupedPermissions,
} from '@repo/api-codegen';
import { sortBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { ISecodaEntity, queryClient, useAuthUser } from '../../api';
import { useEntityMyPermission } from '../../api/hooks/secodaEntity/permissions';
import type { SecodaEntity } from '../../lib/models';
import { getTeamMembershipOrEntityPermisisonDisplayName } from '../../utils/userUtils';
import { InviteEntityPermissionForm } from '../InviteEntityPermissionForm/InviteEntityPermissionForm';
import { UserAvatar } from '../UserAvatar';
import { EntityPermissionRow } from './EntityPermissionRow';
import { EntityPermissionSourceRow } from './EntityPermissionSourceRow';

type IContentProps = { model: SecodaEntity | ISecodaEntity; readOnly: boolean };

const useStyles = createStyles((theme) => ({
	disclaimer: {
		cursor: 'pointer',
		backgroundColor: theme.other.getColor('surface/secondary/hover'),
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing.xs,
		fontSize: theme.fontSizes.xs,
		borderRadius: theme.radius.xs,
		padding: theme.spacing.xs,
		marginTop: theme.spacing.xs,
		marginBottom: theme.spacing.sm,
	},
}));

export function Content({ model, readOnly }: IContentProps) {
	const { classes } = useStyles();

	const { data: groupedPermissions } = useApiGetEntityGroupedPermissions({
		pathParams: {
			entityId: model.id,
		},
	});

	const { data: myPermission } = useEntityMyPermission(model.id, {
		suspense: false,
	});

	const [searchValue, setSearchValue] = useState('');

	const [filteredGroupedPermissions, setFilteredGroupedPermissions] = useState<
		GroupedPermissionsResponse[]
	>([]);

	useEffect(() => {
		const filteredResult = (groupedPermissions || [])
			.map((group) => ({
				...group,
				permissions: sortBy(
					group.permissions.filter((permission) =>
						getTeamMembershipOrEntityPermisisonDisplayName(permission)
							.toLowerCase()
							.includes(searchValue.toLowerCase())
					),
					[
						// Users first, then groups
						(membership) => {
							if (membership.user) return 1;
							return 2;
						},
						// Alphabetical
						(permission) =>
							getTeamMembershipOrEntityPermisisonDisplayName(
								permission
							).toLowerCase(),
					]
				),
			}))
			.filter((group) => group.permissions.length > 0);

		setFilteredGroupedPermissions(filteredResult);
	}, [searchValue, groupedPermissions]);

	const { isAdminUser, user } = useAuthUser();

	const { mutateAsync: apiAlterEntityPermission } =
		useApiAlterEntityPermission();

	const notEditable = (permission: EntityPermissionOrMembershipOut) =>
		readOnly || (!isAdminUser && permission.user?.id === user.id);

	if (!groupedPermissions) {
		return (
			<Group position="center" p={24}>
				<Loader />
			</Group>
		);
	}

	const handleNavigateToDocs = () => {
		window.open(
			'https://docs.secoda.co/user-management/roles/sharing-resources',
			'_blank'
		);
	};

	const alterEntityPermission = async (
		request: AlterEntityPermissionRequest
	) => {
		await apiAlterEntityPermission({
			pathParams: {
				entityId: model.id,
			},
			body: request,
		});
		await queryClient.invalidateQueries(
			apiQueryKey('resource/all/{entity_id}/permissions_v2', {
				entity_id: model.id,
			})
		);
	};

	return (
		<Stack px="lg" spacing="sm">
			{!readOnly && (
				<InviteEntityPermissionForm
					entityId={model.id}
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					placeholder="Search for or add new users or groups..."
				/>
			)}

			<Stack spacing={0}>
				<Box onClick={handleNavigateToDocs} className={classes.disclaimer}>
					<UserAvatar user={user} size="xxs" />
					{myPermission?.read &&
						!myPermission?.write &&
						'You have read access to this page'}
					{myPermission?.read &&
						myPermission?.write &&
						'You have full access to this page'}
				</Box>
				{filteredGroupedPermissions.map((group) => (
					<Stack
						key={`${group.source}-${group.source_entity?.id}-${group.source_team?.id}`}
						spacing={0}
					>
						<EntityPermissionSourceRow group={group} />
						{group.permissions.map((permission) => (
							<EntityPermissionRow
								key={`${permission.group?.id}-${permission.user?.id}`}
								permission={permission}
								readOnly={notEditable(permission)}
								alterEntityPermission={alterEntityPermission}
							/>
						))}
					</Stack>
				))}
			</Stack>
		</Stack>
	);
}
