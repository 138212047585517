import { showNotification } from '@mantine/notifications';
import { IconButton } from '@repo/foundations';
import type { ISecodaEntity } from '../../../../api';
import { useDeleteResourceRelation } from '../../../../api/hooks/relations';
import { EntityDisplay } from '../EntityDisplay/EntityDisplay';

interface IRelatedEntityProps {
	entityId?: string;
	relatedEntity: ISecodaEntity;
	isEditorOfEntity: boolean;
}

export function RelatedEntity({
	entityId,
	relatedEntity,
	isEditorOfEntity,
}: IRelatedEntityProps) {
	const { mutateAsync: deleteRelation } = useDeleteResourceRelation({
		onSuccess: () => {
			showNotification({
				message: 'Resource relation removed',
				color: 'green',
			});
		},
		onError: () => {
			showNotification({
				message: 'Error deleting resource relation',
				color: 'red',
			});
		},
	});

	const handleDeleteClick = async (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		await deleteRelation({
			fromEntityId: entityId || '',
			toEntityId: relatedEntity.id,
		});
	};

	return (
		<EntityDisplay
			entity={relatedEntity}
			hoverButton={
				isEditorOfEntity && (
					<IconButton
						iconName="x"
						variant="tertiary"
						onClick={handleDeleteClick}
					/>
				)
			}
		/>
	);
}
