import { Checkbox, Group, Menu, Radio, useMantineTheme } from '@mantine/core';
import { Text } from '@repo/foundations';
import type { TablerIconsProps } from '@tabler/icons-react';
import { noop } from 'lodash-es';

interface IFilterMenuDropdownItemProps {
	label: string;
	icon?: (props: TablerIconsProps) => JSX.Element;
	checked: boolean;
	isRadio: boolean;
	onClick: () => void;
}

export function FilterMenuDropdownItem({
	label,
	icon,
	checked,
	isRadio,
	onClick,
}: IFilterMenuDropdownItemProps) {
	const theme = useMantineTheme();
	const Icon = icon;

	return (
		<Menu.Item
			icon={
				isRadio ? (
					<Radio checked={checked} onChange={noop} />
				) : (
					<Checkbox checked={checked} onChange={noop} />
				)
			}
			onClick={onClick}
		>
			<Group spacing="xs">
				{Icon && (
					<Icon color={theme.other.getColor('icon/secondary/default')} />
				)}
				<Text size="sm">{label}</Text>
			</Group>
		</Menu.Item>
	);
}
