import { MenuItem } from '@repo/secoda-editor';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'fuzz... Remove this comment to see the full error message
import FuzzySearch from 'fuzzy-search';
import gemojies from 'gemoji';
import { useCallback, useMemo } from 'react';
import { CommandMenu, CommandMenuProps } from './Toolbar/CommandMenu';
import { CommandMenuItem } from './Toolbar/CommandMenuItem';

interface EmojiMenuItem extends MenuItem {
	name: string;
	title: string;
	emoji: string;
	description: string;
	attrs: { markup: string; 'data-name': string };
}

const searcher = new FuzzySearch<{
	names: string[];
	description: string;
	emoji: string;
}>(gemojies, ['names'], {
	caseSensitive: true,
	sort: true,
});

type EmojiMenuProps = Omit<
	CommandMenuProps<EmojiMenuItem>,
	'renderMenuItem' | 'items' | 'id'
>;

export function EmojiMenu({ ...props }: EmojiMenuProps) {
	const { search } = props;

	const items = useMemo(() => {
		const n = (search ?? '').toLowerCase();
		// @ts-expect-error TS(7006): Parameter 'item' implicitly has an 'any' type.
		const result = searcher.search(n).map((item) => {
			const { description, names } = item;
			// eslint-disable-next-line prefer-destructuring
			const name = names[0];
			return {
				...item,
				name: 'emoji',
				title: name,
				description,
				attrs: { markup: name, 'data-name': name },
			};
		});

		return result.slice(0, 10);
	}, [search]);

	const renderMenuItem = useCallback(
		(item: MenuItem, _index: number, options: Record<string, unknown>) => {
			const emojiItem = item as EmojiMenuItem;

			return (
				<CommandMenuItem
					key={_index}
					// eslint-disable-next-line react/no-unstable-nested-components, react/jsx-no-useless-fragment
					icon={() => <>{emojiItem.emoji}</>}
					title={emojiItem.description}
					{...options}
				>
					{item.title}
				</CommandMenuItem>
			);
		},
		[]
	);

	return (
		<CommandMenu
			{...props}
			id="emoji-menu-container"
			trigger=":"
			renderMenuItem={renderMenuItem}
			items={items}
		/>
	);
}
