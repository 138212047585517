import { createStyles, Tabs } from '@mantine/core';
import { useState } from 'react';
import { IIntegration } from '../../../../../api';
import { IntegrationSpec } from '../../../../../interfaces/IntegrationSpec';
import { ScheduleInput } from './ScheduleInput';

interface SchedulePanelProps {
	integration: IIntegration;
	spec: IntegrationSpec;
}

const useStyles = createStyles((theme) => ({
	panel: {
		marginTop: theme.spacing['2xl'],
	},
}));

export function SchedulePanel({ integration, spec }: SchedulePanelProps) {
	const { classes, theme } = useStyles();

	const [activeTab, setActiveTab] = useState<string | null>('PULL');

	if (spec.value.supportPushMetadata) {
		return (
			<Tabs
				classNames={classes}
				color="dark"
				defaultValue="pull"
				value={activeTab}
				onTabChange={setActiveTab}
			>
				<Tabs.List grow>
					<Tabs.Tab value="PULL">Pull</Tabs.Tab>
					<Tabs.Tab value="PUSH">Push</Tabs.Tab>
				</Tabs.List>
				<Tabs.Panel value="PULL">
					<ScheduleInput integration={integration} type="PULL" />
				</Tabs.Panel>
				<Tabs.Panel value="PUSH">
					<ScheduleInput integration={integration} type="PUSH" />
				</Tabs.Panel>
			</Tabs>
		);
	}

	return <ScheduleInput integration={integration} type="PULL" />;
}
