import type { DefaultProps, MantineTheme } from '@mantine/core';
import {
	Avatar,
	Box,
	Flex,
	Group,
	Stack,
	createStyles,
	useMantineTheme,
} from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';
import {
	Badge,
	Button,
	type ButtonVariants,
	Icon,
	type IconNames,
	Text,
} from '@repo/foundations';
import type { TablerIconsProps } from '@tabler/icons-react';
import { capitalize, isEmpty, isNil, size, slice } from 'lodash-es';
import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef, memo, useCallback, useMemo } from 'react';
import { useNavigate } from '../../hooks/useNavigate';
import {
	getUserInitialsFromName,
	pickColorType,
	pluralize,
	truncateWithEllipsis,
} from '../../utils';
import GroupIcon from '../GroupIcon/GroupIcon';
import { IconEmoji } from '../IconEmoji/IconEmoji';
import { ItemIconType } from '../ItemIcon';
import RichTooltip from '../RichTooltip/RichTooltip';
import { SelectablePropertyType } from '../SelectableProperty/types';
import type { SelectablePropertyItem } from '../SingleSelector/types';
import TagIcon from '../TagIcon/TagIcon';
import { colorGroupToFillMap } from '../UserAvatar/helpers';
import MultiTargetButton from './MultiTargetButton';

const ICON_HEIGHT = 16;
const TRUNCATE_LENGTH = 20;

interface IMultiSelectorTargetProps extends DefaultProps {
	placeholder?: string | null;
	placeholderIconName?: IconNames;
	variant?: ButtonVariants;
	inheritSelected?: SelectablePropertyItem[];
	selected: SelectablePropertyItem[];
	property: SelectablePropertyType;
	propertyLabel?: string;
	iconType: ItemIconType;
	isMenuItemBadge: boolean;
	hideOnEmpty?: boolean;
	isViewerUser: boolean;
	permittedId: string;
	marginLeft?: string;
	readOnly?: boolean;
	withDescriptionTooltip?: boolean;
	onTargetClick?: () => void;
}

interface IStyleParams {
	variant?: ButtonVariants;
	readOnly?: boolean;
	hideOnEmpty?: boolean;
	empty?: boolean;
}

const useStyles = createStyles(
	(
		theme: MantineTheme,
		{ variant, readOnly, hideOnEmpty, empty }: IStyleParams
	) => ({
		avatarLabel: {
			fontSize: '0.6rem',
			lineHeight: theme.fontSizes.xs,
		},
		buttonLabel: {
			'.mantine-Avatar-root': {
				outlineColor: 'white',
			},
			'&:hover': {
				'.mantine-Avatar-root': {
					outlineColor: readOnly
						? 'none'
						: theme.other.getColor('surface/primary/active'),
				},
			},
		},
		badgeInner: {
			color: theme.colors.gray[9],
			fontSize: theme.fontSizes.sm,
			fontWeight: theme.other.typography.weight.regular,
			lineHeight: `${ICON_HEIGHT}px`,
		},
		emoji: {
			fontSize: ICON_HEIGHT,
			lineHeight: `${ICON_HEIGHT}px`,
		},
		content: {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
		},
		placeholder: {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			color:
				variant === 'primary'
					? theme.other.getColor('text/brand-on-fill/default')
					: theme.other.getColor('text/secondary/default'),
		},
		noShrink: {
			flexShrink: 0,
			display: 'flex',
			alignItems: 'center',
		},
		button: {
			visibility: hideOnEmpty && empty ? 'hidden' : 'visible',
			'&:hover': {
				visibility: 'visible',
			},
		},
	})
);

const LeftSection = memo(
	({
		item,
		iconType,
		classes,
	}: {
		item: SelectablePropertyItem;
		iconType: ItemIconType;
		classes: Record<string, string>;
	}) => {
		const theme = useMantineTheme();

		if (iconType === 'avatar') {
			const colorGroup = pickColorType(item.label);
			const { fillStart, fillEnd, textColor } = colorGroupToFillMap(colorGroup);

			if (item.navigateTo && item.navigateTo.includes('/group/')) {
				return <GroupIcon item={item} />;
			}

			return (
				<Avatar
					radius={`${ICON_HEIGHT}px`}
					miw={`${ICON_HEIGHT}px`}
					mih={`${ICON_HEIGHT}px`}
					h={`${ICON_HEIGHT}px`}
					w={`${ICON_HEIGHT}px`}
					size="sm"
					src={item.icon as string}
					classNames={{
						placeholder: classes.avatarLabel,
					}}
					sx={{
						color: textColor,
						backgroundImage: theme.fn.gradient({
							from: fillStart,
							to: fillEnd,
							deg: 180,
						}),
					}}
				>
					{(item.icon as ReactNode) ||
						slice(getUserInitialsFromName(item.label), 0, 1)}
				</Avatar>
			);
		}
		if (iconType === 'tag') {
			return (
				<TagIcon
					color={item.color || theme.other.getColor('icon/brand/default')}
				/>
			);
		}

		if (iconType === 'tabler') {
			const Icon = item.icon as (props: TablerIconsProps) => JSX.Element;

			return <Icon size="md" color={item.color} />;
		}

		if (typeof item.icon === 'string') {
			return (
				<IconEmoji
					value={item.icon as string}
					className={classes.emoji}
					padding={0}
				/>
			);
		}

		return <Text className={classes.emoji}>{item.icon as ReactNode}</Text>;
	}
);

LeftSection.displayName = 'LeftSection';

const SingleMultiSelectorTarget = memo(
	({
		item,
		variant,
		isMenuItemBadge,
		iconType,
		truncate = true,
		showLabelDetails = false,
		onTargetClick,
	}: {
		item: SelectablePropertyItem;
		variant: ButtonVariants;
		isMenuItemBadge: boolean;
		iconType: ItemIconType;
		truncate?: boolean;
		showLabelDetails?: boolean;
		onTargetClick?: () => void;
	}) => {
		const { classes, theme } = useStyles({
			variant,
			readOnly: true,
		});

		const leftSection = useMemo(
			() =>
				iconType !== 'none' ? (
					<LeftSection item={item} iconType={iconType} classes={classes} />
				) : null,
			[classes, iconType, item]
		);

		const label = truncate
			? truncateWithEllipsis(item.label, TRUNCATE_LENGTH)
			: item.label;

		if (isMenuItemBadge && variant === 'tertiary') {
			return (
				<Flex onClick={onTargetClick}>
					<Badge
						classNames={{
							inner: classes.badgeInner,
						}}
						style={{
							backgroundColor: theme.other.getColor(
								'fill/transparent-secondary/default'
							),
						}}
						leftSection={leftSection}
						my={0}
					>
						{label}
					</Badge>
				</Flex>
			);
		}

		return (
			<Group spacing="3xs" noWrap align="center" onClick={onTargetClick}>
				{!isNil(leftSection) && (
					<Box className={classes.noShrink}>{leftSection}</Box>
				)}
				<Stack spacing={0}>
					<Text
						className={classes.content}
						td={item?.strikeThrough ? 'line-through' : undefined}
						size="sm"
						color={
							variant === 'primary'
								? 'text/brand-on-fill/default'
								: 'text/primary/default'
						}
					>
						{label}
					</Text>
					{showLabelDetails && item.labelDetails && (
						<Text size="xs" color="text/secondary/default" lineClamp={1}>
							{item.labelDetails}
						</Text>
					)}
				</Stack>
			</Group>
		);
	}
);
SingleMultiSelectorTarget.displayName = 'SingleMultiSelectorTarget';

const MultiSelectorTarget = forwardRef(
	(
		{
			placeholder = 'Empty',
			propertyLabel,
			placeholderIconName,
			variant = 'tertiary',
			selected: withoutInheritSelected,
			inheritSelected = [],
			property,
			iconType,
			isMenuItemBadge,
			isViewerUser,
			permittedId,
			readOnly = false,
			hideOnEmpty = false,
			marginLeft,
			w,
			withDescriptionTooltip = false,
			onTargetClick,
			...others
		}: IMultiSelectorTargetProps,
		forwardedRef: ForwardedRef<HTMLButtonElement | HTMLDivElement>
	) => {
		const empty =
			isEmpty(withoutInheritSelected) || isNil(withoutInheritSelected);

		const { classes, theme } = useStyles({
			variant,
			readOnly,
			hideOnEmpty,
			empty,
		});

		const ref = useMergedRef(forwardedRef);

		const navigate = useNavigate();

		const selected = useMemo(
			() => [
				...inheritSelected,
				...withoutInheritSelected.filter(
					(item) =>
						!inheritSelected.find(
							(inheritedItem) => inheritedItem.value === item.value
						)
				),
			],
			[inheritSelected, withoutInheritSelected]
		);

		const handleOnClick = useCallback(() => {
			if (isViewerUser) {
				if (
					size(selected) === 1 &&
					!isNil(selected[0].navigateTo) &&
					size(permittedId) === 0
				) {
					navigate(selected[0].navigateTo);
				}
			}
		}, [isViewerUser, navigate, permittedId, selected]);

		const leftSection = useMemo(() => {
			if (isEmpty(selected)) {
				if (placeholderIconName) {
					return <Icon name={placeholderIconName} size="md" />;
				}
				return null;
			}

			if (size(selected) === 1) {
				return (
					<LeftSection
						item={selected[0]}
						iconType={iconType}
						classes={classes}
					/>
				);
			}
		}, [classes, iconType, placeholderIconName, selected]);

		const showTooltip = useMemo(
			() =>
				selected.length > 1 ||
				(selected.length === 1 && selected[0].label.length > TRUNCATE_LENGTH),
			[selected]
		);

		const tooltipBody = useMemo(
			() => (
				<Stack spacing={'xs'}>
					{selected.map((el) => (
						<SingleMultiSelectorTarget
							iconType={iconType}
							key={`${el.label}${el.value}`}
							item={el}
							variant={variant}
							isMenuItemBadge={isMenuItemBadge}
							truncate={false}
							showLabelDetails
							onTargetClick={onTargetClick}
						/>
					))}
				</Stack>
			),
			[selected, iconType, variant, isMenuItemBadge, onTargetClick]
		);

		const multiTargetSx = useMemo(
			() =>
				iconType === 'tag'
					? {
							backgroundColor:
								variant === 'tertiary'
									? theme.other.getColor('surface/primary/active')
									: undefined,
							paddingLeft: theme.spacing.xs,
							paddingRight: theme.spacing.xs,
							borderRadius: theme.radius.md,
							paddingTop: 4,
							paddingBottom: 4,
						}
					: undefined,
			[iconType, variant, theme]
		);

		const buttonClassNames = useMemo(
			() => ({
				label: classes.buttonLabel,
			}),
			[classes]
		);

		return (
			<RichTooltip
				hidden={!showTooltip}
				title={pluralize(capitalize(propertyLabel ?? property))}
				body={tooltipBody}
			>
				<Button
					p={0}
					w={w}
					size="md"
					ml={marginLeft}
					onClick={handleOnClick}
					variant={variant}
					ref={ref as ForwardedRef<HTMLButtonElement>}
					className={classes.button}
					classNames={buttonClassNames}
					{...others}
				>
					{size(selected) === 0 && (
						<Group
							aria-label="placeholder"
							spacing="3xs"
							noWrap
							onClick={onTargetClick}
						>
							{!isNil(leftSection) && (
								<Box className={classes.noShrink}>{leftSection}</Box>
							)}
							{placeholder && (
								<Text className={classes.placeholder} size="sm">
									{placeholder}
								</Text>
							)}
						</Group>
					)}
					{size(selected) === 1 && (
						<RichTooltip
							title={selected[0].label}
							body={selected[0].description}
							position="bottom"
							hidden={!withDescriptionTooltip || !selected[0].description}
						>
							<SingleMultiSelectorTarget
								iconType={iconType}
								item={selected[0]}
								variant={variant}
								isMenuItemBadge={isMenuItemBadge}
								onTargetClick={onTargetClick}
								truncate
							/>
						</RichTooltip>
					)}
					{size(selected) > 1 && (
						<Box sx={multiTargetSx}>
							<MultiTargetButton
								variant={variant}
								selected={selected}
								property={property}
								propertyLabel={propertyLabel}
								iconType={iconType}
								onTargetClick={onTargetClick}
							/>
						</Box>
					)}
				</Button>
			</RichTooltip>
		);
	}
);

MultiSelectorTarget.displayName = 'MultiSelectorTarget';

export default MultiSelectorTarget;
