import { TextInput, TextInputProps, createStyles } from '@mantine/core';
import { ListBox } from './ListBox';

const useStyles = createStyles((theme) => ({
	wrapper: {
		borderRadius: 0,
		padding: 0,

		':focus, :hover, :active, &[data-hovered]': {
			background: 'none',
		},
	},
	input: {
		padding: `${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.sm}`,
		outline: 'none',
		border: 0,

		':focus': {
			boxShadow: 'none',
			borderColor: theme.other.getColor('border/secondary/default'),
		},
	},
	divider: {
		marginTop: 0,
	},
}));

interface ListBoxSearchProps extends Omit<TextInputProps, 'onChange'> {
	placeholder?: string;
	onChange?: (value: string) => void;
}

export function ListBoxSearch({
	placeholder,
	value,
	onChange,
	onKeyDown,
	...textInputProps
}: ListBoxSearchProps) {
	const { classes } = useStyles();

	return (
		<>
			<TextInput
				{...textInputProps}
				placeholder={placeholder}
				variant="unstyled"
				autoFocus
				classNames={{
					input: classes.input,
				}}
				value={value ?? ''}
				onChange={(event) => onChange?.(event.currentTarget.value)}
				onKeyDown={onKeyDown}
			/>
			<ListBox.Divider className={classes.divider} />
		</>
	);
}
