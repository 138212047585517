import { Group, Menu, Stack } from '@mantine/core';
import CollapsableStack from '@repo/common/components/CollapsableStack';
import { IMarketplaceIntegrationSpecVersion } from '@repo/common/models/marketplace';
import { Button, Icon, IconButton, Text } from '@repo/foundations';
import { useNavigate } from 'react-router';

import { useDeleteMarketplaceIntegrationSpecWithConfirmation } from '../../api/hooks/marketplace';
import { StaticProperty } from '../../components/EntityPageLayout/EntityPropertySidebar';
import { RightSidebarWrapper } from '../../components/RightSidebar';
import {
	publishStatusBadgeBackgroundColor,
	publishStatusBadgeColor,
	publishStatusBadgeLabel,
} from '../../utils/marketplaceUtils';

export interface MarketplaceIntegrationSpecSidebarProps {
	latestVersion: IMarketplaceIntegrationSpecVersion;
	specId: string;
	totalInstallations: number;
}

function MarketplaceIntegrationSpecSidebar({
	latestVersion,
	specId,
	totalInstallations,
}: MarketplaceIntegrationSpecSidebarProps) {
	const navigate = useNavigate();

	const { apiDeleteSpec } =
		useDeleteMarketplaceIntegrationSpecWithConfirmation();

	const createNewVersion = () => {
		navigate(`/marketplace-integration/${specId}/new-version`);
	};

	const deleteSpec = async () => {
		apiDeleteSpec(specId, () => {
			navigate('/integrations/browse');
		});
	};
	return (
		<RightSidebarWrapper>
			<Stack px="xl" py="md">
				<Group position="right" spacing="xs">
					<Menu position="bottom-end">
						<Menu.Target>
							<IconButton iconName="dots" />
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item
								icon={<Icon name="trash" color="text/critical/default" />}
								onClick={deleteSpec}
							>
								<Text size="sm" color="text/critical/default">
									Delete integration
								</Text>
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
					<Button onClick={createNewVersion}>Create new version</Button>
				</Group>
			</Stack>
			<CollapsableStack groupName="Overview" withDivider={false}>
				<Stack spacing="xs">
					<StaticProperty
						type="badge"
						label="Status"
						value={{
							label: publishStatusBadgeLabel(latestVersion.publish_status),
							color: publishStatusBadgeColor(latestVersion.publish_status),
							fill: publishStatusBadgeBackgroundColor(
								latestVersion.publish_status
							),
							value: latestVersion.publish_status,
						}}
					/>

					<StaticProperty
						type="text"
						label="Latest version"
						value={String(latestVersion.version_number)}
					/>
					<StaticProperty
						type="text"
						label="Total installs"
						value={String(totalInstallations)}
					/>
				</Stack>
			</CollapsableStack>
		</RightSidebarWrapper>
	);
}

export default MarketplaceIntegrationSpecSidebar;
