import type { SecodaEntity } from '../../lib/models';
import { EntityType } from '../../lib/types';

export function nFormatter(num: any, digits: any) {
	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'K' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'B' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'P' },
		{ value: 1e18, symbol: 'E' },
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	const item = lookup
		.slice()
		.reverse()
		.find((it: any) => num >= it.value);
	return item
		? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
		: '0';
}

export function formatBytes(bytes: number, decimals: number = 2): string {
	if (bytes === 0) return '0 Byte';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export const toTitleCase = (str: string) =>
	str.replace(
		/\w\S*/g,
		(txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
	);

export const getColumnTitle = (entity: SecodaEntity) =>
	entity.entity_type === EntityType.column
		? `${entity.search_metadata?.table}.${entity.title}`
		: entity.title;
