import { Drawer } from '@mantine/core';
import { Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';
import { ISecodaEntity } from '../../api';
import type { SecodaEntity } from '../../lib/models';
import { ErrorBoundary } from '../ErrorBoundary';
import { SmallLoadingSpinner } from '../LoadingSpinner';
import ActivityLog from './ActivityLog';

interface IActivityLogDrawerProps {
	entity?: SecodaEntity | ISecodaEntity;
	onClose: () => void;
	open: boolean;
}

function ActivityLogDrawer({ entity, open, onClose }: IActivityLogDrawerProps) {
	return (
		<Drawer
			position="right"
			opened={open}
			onClose={onClose}
			title={
				<Text size="md" weight="bold">
					Activity Log
				</Text>
			}
			padding="xl"
			size="xl"
		>
			{open && (
				<ErrorBoundary>
					<Suspense fallback={<SmallLoadingSpinner />}>
						<ActivityLog entity={entity} />
					</Suspense>
				</ErrorBoundary>
			)}
		</Drawer>
	);
}

export default observer(ActivityLogDrawer);
