import { getDefaultZIndex, MantineTheme, Modal } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { ButtonTones, ButtonVariants, Text } from '@repo/foundations';
import { noop } from 'lodash-es';
import { useCallback, useReducer, useRef } from 'react';
import { ConfirmationModal } from './ConfirmationModal';
import type {
	ConfirmLabels,
	ModalsContextProps,
	ModalSettings,
	OpenConfirmModal,
} from './context';
import { ModalsContext } from './context';
import { useModalsEvents } from './events';
import { modalsReducer } from './reducer';

export interface ModalsProviderProps {
	/** Your app */
	children: React.ReactNode;

	/** Shared Modal component props, applied for every modal */
	modalProps?: ModalSettings;

	/** Confirm modal labels */
	labels?: ConfirmLabels;
}

function separateConfirmModalProps(props: OpenConfirmModal) {
	if (!props) {
		return { confirmProps: {}, modalProps: {} };
	}

	const {
		id,
		children,
		onCancel,
		onConfirm,
		closeOnConfirm,
		closeOnCancel,
		cancelProps,
		confirmProps,
		groupProps,
		labels,
		...others
	} = props;

	return {
		confirmProps: {
			id,
			children,
			onCancel,
			onConfirm,
			closeOnConfirm,
			closeOnCancel,
			cancelProps,
			confirmProps,
			groupProps,
			labels,
		},
		modalProps: {
			id,
			...others,
		},
	};
}

const getModalStyles = (theme: MantineTheme) => ({
	header: {
		background: theme.other.getColor('surface/secondary/default'),
		borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		padding: `${theme.spacing.sm} ${theme.spacing.md}`,
		fontWeight: 600,
	},
	content: {
		borderRadius: theme.radius.lg,
	},
	body: {
		padding: theme.spacing.md,
		'&:not(:only-child)': {
			paddingTop: theme.spacing.md,
		},
	},
	inner: {},
});

export function ModalManagerProvider({
	children,
	modalProps,
	labels,
}: ModalsProviderProps) {
	const [state, dispatch] = useReducer(modalsReducer, {
		modals: [],
		current: null,
	});
	const stateRef = useRef(state);
	stateRef.current = state;

	const closeAll = useCallback(
		(canceled?: boolean) => {
			dispatch({ type: 'CLOSE_ALL', canceled });
		},
		[dispatch]
	);

	const openModal = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		({ modalId, title, children, ...props }: ModalSettings) => {
			const id = modalId || randomId();
			dispatch({
				type: 'OPEN',
				modal: {
					id,
					type: 'content',
					props: {
						title,
						children,
						styles: getModalStyles,
						...props,
					},
				},
			});
			return id;
		},
		[dispatch]
	);

	const openConfirmModal = useCallback(
		({
			modalId,
			title,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			children,
			confirmLabel = 'Confirm',
			cancelLabel = 'Cancel',
			variant = 'primary',
			tone = 'default',
			onConfirm,
			...props
		}: OpenConfirmModal) => {
			const id = modalId || randomId();
			dispatch({
				type: 'OPEN',
				modal: {
					id,
					type: 'confirm',
					props: {
						title,
						children,
						labels: { confirm: confirmLabel, cancel: cancelLabel },
						confirmProps: {
							variant: variant as ButtonVariants,
							tone: tone as ButtonTones,
						},
						onCancel: noop,
						onConfirm,
						styles: getModalStyles,
						...props,
					},
				},
			});
			return id;
		},
		[dispatch]
	);

	const closeModal = useCallback(
		(id: string, canceled?: boolean) => {
			dispatch({ type: 'CLOSE', modalId: id, canceled });
		},
		[dispatch]
	);

	const openDeleteConfirmModal = useCallback(
		({
			modalId,
			title,
			description = "You can't undo this action.",
			confirmLabel = 'Delete',
			cancelLabel = 'Cancel',
			onConfirm,
			...props
		}: OpenConfirmModal) => {
			openConfirmModal({
				modalId,
				title,
				children:
					typeof description === 'string' ? (
						<Text size="sm">{description}</Text>
					) : (
						description
					),
				confirmLabel,
				cancelLabel,
				onConfirm,
				variant: 'primary',
				tone: 'critical',
				...props,
			});
		},
		[openConfirmModal]
	);

	useModalsEvents({
		openModal,
		openConfirmModal,
		openDeleteConfirmModal,
		closeModal,
		closeAllModals: closeAll,
	});

	// eslint-disable-next-line react/jsx-no-constructed-context-values
	const ctx: ModalsContextProps = {
		modals: state.modals,
		openConfirmModal,
		closeModal,
		closeAll,
	};

	const getCurrentModal = () => {
		const currentModal = stateRef.current.current;
		switch (currentModal?.type) {
			case 'confirm': {
				const {
					modalProps: separatedModalProps,
					confirmProps: separatedConfirmProps,
				} = separateConfirmModalProps(currentModal.props);

				return {
					modalProps: separatedModalProps,
					content: (
						<ConfirmationModal
							{...separatedConfirmProps}
							id={currentModal.id}
							labels={currentModal.props.labels || labels}
						/>
					),
				};
			}
			case 'content': {
				const { children: currentModalChildren, ...rest } = currentModal.props;

				return {
					modalProps: rest,
					// eslint-disable-next-line react/jsx-no-useless-fragment
					content: <>{currentModalChildren}</>,
				};
			}
			default: {
				return {
					modalProps: {},
					content: null,
				};
			}
		}
	};

	const { modalProps: currentModalProps, content } = getCurrentModal();

	return (
		<ModalsContext.Provider value={ctx}>
			<Modal
				zIndex={getDefaultZIndex('modal') + 1}
				{...modalProps}
				{...currentModalProps}
				opened={state.modals.length > 0}
				onClose={() => closeModal(state.current!.id)}
			>
				{content}
			</Modal>

			{children}
		</ModalsContext.Provider>
	);
}
