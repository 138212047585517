import { useQuestion, useQuestionReply } from '.';
import { useAuthUser } from '../authUser';

export function useQuestionPermissions(
	id: string,
	type: 'question' | 'reply' = 'reply'
) {
	const { user, isEditorOrAdminUser, isAdminUser } = useAuthUser();

	const { data: questionReplyEntity } = useQuestionReply({
		id,
		options: {
			enabled: type === 'reply',
		},
	});

	const { data: questionEntity } = useQuestion({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		id: type === 'reply' ? questionReplyEntity!.parent! : id,
		options: {
			enabled: type === 'question' || Boolean(questionReplyEntity?.parent),
		},
	});

	const entity = type === 'question' ? questionEntity : questionReplyEntity;

	const isOwner = entity?.owners.includes(user.id);
	const isQuestionOwner = questionEntity?.owners.includes(user.id);
	const isAssignedTo = entity?.assigned_to === user.id;

	return {
		canDelete: isAdminUser || isOwner,
		canEdit: isOwner,
		canReact: true,
		canReply: true,
		canAccept: isAdminUser || isAssignedTo || isQuestionOwner,
	};
}
