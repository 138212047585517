import { Divider, Skeleton, Stack } from '@mantine/core';
import { Incident } from '../../../api';
import { CommentStoreProvider } from '../../../components/Comment/context';
import IncidentActivity from '../../MonitorPage/v2/IncidentDrawer/IncidentActivity';
import IncidentDownstreamResources from '../../MonitorPage/v2/IncidentDrawer/IncidentDownstreamResources';
import IncidentMeasurementChart from '../../MonitorPage/v2/IncidentDrawer/IncidentMeasurmentChart';

interface V2IncidentContentProps {
	incident?: Incident;
}

function V2IncidentContent({ incident }: V2IncidentContentProps) {
	return (
		<Stack>
			{!incident && <Skeleton h={400} />}
			{incident && <IncidentMeasurementChart incident={incident} />}
			<Divider />
			{incident && <IncidentDownstreamResources incident={incident} />}
			<Divider />
			<CommentStoreProvider>
				{incident && <IncidentActivity incident={incident} />}
			</CommentStoreProvider>
		</Stack>
	);
}

export default V2IncidentContent;
