import { useDebounceFn } from 'ahooks';
import produce from 'immer';
import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import type { IComment } from '../../api';
import {
	commentsQueryKeyFactory,
	queryClient,
	useAuthUser,
	useCommentList,
	useCreateComment,
	useDeleteComment,
	useDocument,
	useUpdateComment,
	useUpdateDocument,
} from '../../api';
import { CommentSidebar } from '../../components/Comment/CommentSidebar';
import { useCommentStoreContext } from '../../components/Comment/context';
import EditorWithComments from '../../components/Comment/EditorWithComments';
import EntityPageActions from '../../components/EntityPageLayout/EntityPageActions';
import EntityPageNavBar from '../../components/EntityPageLayout/EntityPageNavBar';
import EntityPageToggles from '../../components/EntityPageLayout/EntityPageToggles';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../../components/PageLayout';
import { EntityType } from '../../lib/types';
import type { WithOnlyIdRequired } from '../../lib/typescript';
import { trackEvent } from '../../utils/analytics';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';

export interface IDocumentPageProps {
	id?: string;
}

function DocumentPage({ id: propsId }: IDocumentPageProps) {
	const paramsId = useParamsIdSuffixUuid();
	const id = propsId || paramsId;

	const { user, workspace } = useAuthUser();

	const { data: entity } = useDocument({
		id,
		options: {
			useErrorBoundary: true,
			onSuccess: () => {
				trackEvent(
					'document/open',
					{
						id,
					},
					user,
					workspace
				);
			},
			cacheTime: 1000,
			suspense: true,
		},
	});

	const { mutateAsync } = useUpdateDocument({
		options: {
			onSuccess: () => {
				trackEvent(
					'document/update',
					{
						id,
					},
					user,
					workspace
				);
			},
		},
	});

	const { run: updateDocument } = useDebounceFn(
		async (key: string, value: DjangoValueType) => {
			await mutateAsync({ data: { id, [key]: value } });
		},
		{ wait: 350 }
	);

	const handleEntityChange = useCallback(
		(key: string) =>
			async (value: DjangoValueType, saveRemotely = true) => {
				updateDocument(key, value);
				trackEvent(`document/${key}/update`, { id }, user, workspace);
			},
		[id, updateDocument, user, workspace]
	);
	// ==================

	// ==== COMMENTS ====
	const {
		handleProseMirrorRemoveComment,
		setCommentIds,
		reorderComments,
		setPlaceholderVisible,
		setUploadingEditorID,
	} = useCommentStoreContext();

	const [removedCommentID, setRemovedCommentID] = useState<string | null>(null);

	const { data: commentThreads } = useCommentList({
		filters: { entity_id: id },
		options: {
			refetchOnMount: 'always',
			select: (data) =>
				produce({}, (draftState: { [rootID: string]: IComment[] }) => {
					data?.results.forEach((comment) => {
						const { root } = comment;
						if (!root) {
							return;
						}
						if (!draftState[root]) {
							draftState[root] = [comment];
						} else {
							draftState[root].push(comment);
							draftState[root].sort((a, b) =>
								a.created_at < b.created_at ? -1 : 1
							);
						}
					});
				}) as { [rootID: string]: IComment[] },
			onSuccess(data) {
				setCommentIds(Object.keys(data) as string[]);
				setPlaceholderVisible(false);
				setUploadingEditorID('');
				reorderComments();
			},
		},
	});

	const invalidateCommentQuery = () => {
		queryClient.invalidateQueries(
			commentsQueryKeyFactory.list(1, { entity_id: id })
		);
	};

	const { mutateAsync: createComment } = useCreateComment({
		options: {
			onSuccess: invalidateCommentQuery,
		},
	});

	const { mutateAsync: updateComment } = useUpdateComment({
		options: {
			onSuccess: invalidateCommentQuery,
		},
	});
	const { mutateAsync: deleteComment } = useDeleteComment({
		options: {
			onSuccess: () => {
				invalidateCommentQuery();
				if (removedCommentID) {
					handleProseMirrorRemoveComment(removedCommentID);
					setRemovedCommentID(null);
				}
			},
		},
	});

	const handleCreateComment = useCallback(
		async (data: Partial<Omit<IComment, 'id'>>): Promise<IComment> =>
			createComment({
				data: {
					entity: id,
					...data,
				},
			}),
		[createComment, id]
	);

	const handleUpdateComment = useCallback(
		async (data: WithOnlyIdRequired<IComment>) => {
			await updateComment({
				data,
			});
		},
		[updateComment]
	);

	const handleDeleteComment = useCallback(
		async (commentID: string) => {
			setRemovedCommentID(commentID);
			await deleteComment({ id: commentID });
		},
		[deleteComment]
	);

	// ==================
	if (!entity) {
		return null;
	}

	const source = entity.native_type === 'page' ? 'Confluence' : 'Secoda';

	return (
		<PageLayoutOuterWrapper key={entity.id}>
			<Helmet>
				<title>{entity?.title ?? 'Document'}</title>
			</Helmet>
			<PageLayoutWrapper name="document">
				<PageLayoutContentWrapper name="document">
					{entity && (
						<EntityPageNavBar
							entity={entity}
							actions={<EntityPageActions entity={entity} />}
							toggles={<EntityPageToggles entity={entity} withComment />}
						/>
					)}
					<PageLayoutContent>
						<EditorWithComments
							entity={entity}
							commentThreads={commentThreads || {}}
							handleCreateComment={handleCreateComment}
							handleUpdateComment={handleUpdateComment}
							handleDeleteComment={handleDeleteComment}
							handleEntityChange={handleEntityChange}
							entityType="document"
						/>
					</PageLayoutContent>
				</PageLayoutContentWrapper>
				<CommentSidebar
					entity={entity}
					commentThreads={commentThreads || {}}
					handleCreateComment={handleCreateComment}
					handleUpdateComment={handleUpdateComment}
					handleDeleteComment={handleDeleteComment}
					updateEntity={handleEntityChange}
					source={source}
					entityType={EntityType.document}
				/>
			</PageLayoutWrapper>
		</PageLayoutOuterWrapper>
	);
}

// Note: Don't make DocumentPage an observer, it causes a lot of reactions.
export default DocumentPage;
