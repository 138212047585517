import { Box } from '@mantine/core';
import { ReactElement } from 'react';
import Error403 from '../Error/Error403';
import Error404 from '../Error/Error404';
import Error500 from '../Error/Error500';
import ErrorUnknown from '../Error/ErrorUnknown';

export function setWithExpiry(key: string, value: string, ttl: number) {
	const item = {
		value: value,
		expiry: new Date().getTime() + ttl,
	};
	localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string) {
	const itemString = window.localStorage.getItem(key);
	if (!itemString) return null;

	const item = JSON.parse(itemString);
	const isExpired = new Date().getTime() > item.expiry;

	if (isExpired) {
		localStorage.removeItem(key);
		return null;
	}

	return item.value;
}

export function errorFallbackRenderer(error: Error): ReactElement {
	const chunkFailedMessage = /Loading chunk [\d]+ failed/;
	const fetchFailedMessage = 'Failed to fetch dynamically imported module';
	const moduleFailedMessage =
		'Expected a JavaScript module script but the server responded';
	if (
		error?.message &&
		(chunkFailedMessage.test(error.message) ||
			error.message?.includes(fetchFailedMessage) ||
			error.message?.includes(moduleFailedMessage))
	) {
		if (!getWithExpiry('chunk_failed')) {
			setWithExpiry('chunk_failed', 'true', 10000);
			window.location.reload();
		}
	}

	const content = () => {
		if (error?.message.includes('404')) {
			return <Error404 />;
		}
		if (error?.message.includes('403')) {
			return <Error403 />;
		}
		if (error?.message.includes('500')) {
			return <Error500 />;
		}

		return <ErrorUnknown />;
	};

	return (
		<Box
			sx={{
				display: 'inline-flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				height: '90vh',
			}}
		>
			{content()}
		</Box>
	);
}
