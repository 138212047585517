import { Menu, Tooltip } from '@mantine/core';
import { IconButton } from '@repo/foundations';
import { DataTableColumn } from '@repo/mantine-datatable';
import { useCallback, useMemo, useRef } from 'react';
import { queryClient, useAuthUser, type Catalog } from '../../../api';
import { CUSTOM_PROPERTY_COLUMN_PREFIX } from '../../../constants';
import { trackEvent } from '../../../utils/analytics';
import type { ColumnName } from '../helpers';
import type { UseColumnDefsArgs } from '../hooks/useColumnDefs';

import { catalogQueryKeyFactory } from '../../../api/hooks/catalog/constants';
import { STICKY_COLUMNS } from '../../TableV2/constants';
import { ExtendedDataTableColumn } from '../../TableV2/types';
import { useColumnDefs } from '../hooks/useColumnDefs';
import MenuList from './MenuList';

interface ICustomizeColumnsPanelProps<T>
	extends Pick<UseColumnDefsArgs<T>, 'catalogType' | 'catalogServerType'> {
	defaultColumns: DataTableColumn<T>[];
	entityId?: null | string;
	onChangeVisibility?: (columnName: string, isVisible: boolean) => void;
	onChangeOrder?: (catalog: Catalog) => void;
}

function CustomizeColumnsPanel<T>({
	defaultColumns,
	catalogType,
	catalogServerType,
	entityId = null,
	onChangeVisibility,
	onChangeOrder,
}: ICustomizeColumnsPanelProps<T>) {
	const { catalog, onColumnReorder, onColumnVisibilityChange } = useColumnDefs({
		defaultColumns,
		catalogServerType,
		catalogType,
		entityId,
	});

	const inputRef = useRef<null | string>(null);
	const existingColumnNameRef = useRef<null | string>(null);

	const { user, workspace } = useAuthUser();

	const updateRef = useCallback((input: string) => {
		const columnName = input.replace(CUSTOM_PROPERTY_COLUMN_PREFIX, '');

		existingColumnNameRef.current = columnName;
		inputRef.current = columnName;
	}, []);

	const handleColumnOrderChange = useCallback(
		async (columnName: string, toIndex: number) => {
			const reorderedCatalog = await onColumnReorder(columnName, toIndex + 1);

			if (reorderedCatalog) {
				onChangeOrder?.(reorderedCatalog);
				queryClient.invalidateQueries(catalogQueryKeyFactory.all());
			}

			trackEvent('catalog/customize/reorder', {}, user, workspace);
		},
		[onChangeOrder, onColumnReorder, user, workspace]
	);

	const handleVisibilityChange = useCallback(
		async (columnName: ColumnName, isVisible: boolean) => {
			await onColumnVisibilityChange(columnName, isVisible);
			onChangeVisibility?.(columnName, isVisible);
			queryClient.invalidateQueries(catalogQueryKeyFactory.all());

			trackEvent(
				'catalog/customize/toggle_column',
				{
					column: columnName,
				},
				user,
				workspace
			);
		},
		[onChangeVisibility, onColumnVisibilityChange, user, workspace]
	);

	const handleSettingsClick = useCallback(
		(columnName: string) => () => {
			updateRef(columnName);
		},
		[updateRef]
	);

	const propertiesToDisplay = useMemo(
		() =>
			catalog?.properties
				.filter((p) => !STICKY_COLUMNS.includes(p.value))
				.filter(
					(p) =>
						// Ensure that the custom property is not already displayed.
						p.value.startsWith(CUSTOM_PROPERTY_COLUMN_PREFIX) ||
						defaultColumns.find(
							(c) =>
								c.accessor === p.value ||
								(c as ExtendedDataTableColumn<T>).esAccessor === p.value
						)
				) ?? [],
		[catalog?.properties, defaultColumns]
	);

	return (
		<Menu width={250} position="bottom-end" withinPortal>
			<Menu.Target>
				<Tooltip label="Rearrange columns">
					<IconButton
						data-testid="customize-columns-button"
						iconName="adjustmentsHorizontal"
						variant="tertiary"
					/>
				</Tooltip>
			</Menu.Target>
			<Menu.Dropdown>
				<MenuList
					rowHeight={32}
					disableDragging={false}
					catalogProperties={propertiesToDisplay}
					catalogType={catalogType}
					onColumnOrderChange={handleColumnOrderChange}
					onVisibilityChange={handleVisibilityChange}
					onSettingsClick={handleSettingsClick}
				/>
			</Menu.Dropdown>
		</Menu>
	);
}

export default CustomizeColumnsPanel;
