import { Box } from '@mantine/core';
import { KeyboardShortcut } from '@repo/common/components/KeyboardShortcut/KeyboardShortcut';
import { MenuItem } from '@repo/secoda-editor';
import { ForwardedRef, forwardRef, HTMLProps, PropsWithChildren } from 'react';
import { useStyles } from './CommandMenuItem.styles';

interface CommandMenuItemProps
	extends Omit<HTMLProps<HTMLButtonElement>, 'ref' | 'onClick'> {
	icon?: MenuItem['icon'];
	ref?: React.Ref<HTMLButtonElement>;
	shortcutKeys?: string[];
}

export const CommandMenuItem = forwardRef(
	(
		{
			icon,
			shortcutKeys,
			children,
			...props
		}: PropsWithChildren<CommandMenuItemProps>,
		ref: ForwardedRef<HTMLButtonElement>
	) => {
		const { classes } = useStyles();

		const Icon = icon;

		return (
			<Box
				{...props}
				ref={ref}
				component="button"
				type="button"
				role="option"
				className={classes.item}
			>
				{Icon && (
					<div className={classes.itemIcon}>
						<Icon />
					</div>
				)}
				{children && <div className={classes.itemLabel}>{children}</div>}
				{shortcutKeys && <KeyboardShortcut keys={shortcutKeys} />}
			</Box>
		);
	}
);
CommandMenuItem.displayName = 'CommandMenuItem';
