import { ExistDict, useApiGetTeamEmptyState } from '@repo/api-codegen';
import type { UseQueryOptionsArgs } from '../../factories/types';

export function useTeamEmptyState(
	teamId: string,
	sectionId: keyof ExistDict,
	options: UseQueryOptionsArgs = {}
) {
	return (
		useApiGetTeamEmptyState(
			{
				pathParams: {
					teamId,
				},
			},
			{
				...options,
			}
		).data?.exist_dict[sectionId] ?? { is_empty: false }
	);
}

export const useTeamEmptyStates = (
	teamId: string,
	options: UseQueryOptionsArgs = {}
) =>
	useApiGetTeamEmptyState(
		{
			pathParams: {
				teamId,
			},
		},
		{
			...options,
		}
	);
