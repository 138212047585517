import { Drawer, Stack } from '@mantine/core';
import { Badge, BadgeVariant, Text } from '@repo/foundations';
import { useAutomationJob } from '../../api/hooks/automationJob';
import { AutomationJob } from '../../api/types/models/automation/automationJob';
import { useFeatureFlags } from '../../utils/featureFlags';
import { DateRenderer } from '../CatalogView/TableView/DateRenderer.tsx';
import { ErrorLogs } from '../ErrorLogs/ErrorLogs';
import { AutomationHistoryDetailsTable } from './AutomationHistoryDetailTable';
import { AUTOMATION_JOB_STATUS } from './utils.ts';

interface IAutomationJobDrawerProps {
	automationJob: AutomationJob;
	opened: boolean;
	onClose: () => void;
}

export function AutomationJobDrawer({
	automationJob,
	opened,
	onClose,
}: IAutomationJobDrawerProps) {
	const { automationHistory } = useFeatureFlags();

	const rolledBackJob = useAutomationJob({
		id: automationJob.job_rolled_back ?? '',
		options: {
			enabled: !!automationJob.is_rollback && !automationJob.error_message,
		},
	});

	let variant: BadgeVariant = 'info';
	if (automationJob.status === AUTOMATION_JOB_STATUS.SUCCESS) {
		variant = 'success';
	}
	if (automationJob.status === AUTOMATION_JOB_STATUS.ERROR) {
		variant = 'critical';
	}

	return (
		<Drawer
			opened={opened}
			onClose={onClose}
			position="right"
			title={
				<Text size="md" weight="bold">
					Status
				</Text>
			}
		>
			<Stack w="100%">
				<Text size="sm" weight="regular">
					<Badge variant={variant} radius="sm">
						{automationJob.status}
					</Badge>
				</Text>
				{automationJob.error_message && (
					<ErrorLogs title="Error" logs={[automationJob.error_message]} />
				)}
				{rolledBackJob && rolledBackJob.isSuccess && rolledBackJob.data && (
					<Text size={'md'}>
						Successfully rolled back changes to job executed at{' '}
						<DateRenderer size={'md'} value={rolledBackJob.data.started_at} />
					</Text>
				)}
				{!automationJob.error_message && automationHistory && (
					<AutomationHistoryDetailsTable automationJobId={automationJob.id} />
				)}
			</Stack>
		</Drawer>
	);
}
