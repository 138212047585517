import { useState } from 'react';
import { IApiListResponse } from '../../api';
import { useAutomationJobList } from '../../api/hooks/automationJob';
import { AutomationJob } from '../../api/types/models/automation/automationJob';
import { TableV2 } from '../TableV2';
import { AutomationJobDrawer } from './AutomationJobDrawer';
import { useColumns } from './hooks';
import { AUTOMATION_JOB_STATUS } from './utils.ts';
interface AutomationHistoryTableProps {
	automationId: string;
}

export function AutomationHistoryTable({
	automationId,
}: AutomationHistoryTableProps) {
	const [jobDetailShown, setJobDetailShown] = useState(false);
	const [currentAutomationJob, setCurrentAutomationJob] =
		useState<AutomationJob | null>(null);
	const columns = useColumns();

	return (
		<>
			<TableV2
				onRowClick={(automationJob: AutomationJob) => {
					setJobDetailShown(true);
					setCurrentAutomationJob(automationJob);
				}}
				columns={columns}
				usePaginationList={useAutomationJobList}
				usePaginationListOptions={{
					select: (response: IApiListResponse<AutomationJob>) => {
						const reverted: Record<string, boolean> = {};
						response.results.forEach((job) => {
							if (job.is_rollback && job.job_rolled_back) {
								reverted[job.job_rolled_back] = true;
							}
							if (reverted[job.id]) job.status = AUTOMATION_JOB_STATUS.REVERTED;
						});
						return response;
					},
				}}
				defaultRequiredSearchParams={{
					automation: automationId,
				}}
				columnVisibility={{
					catalogType: 'automation_jobs',
					catalogServerType: 'automation_jobs',
				}}
				withInteractiveHeader={false}
				withFilters={false}
			/>
			{currentAutomationJob && (
				<AutomationJobDrawer
					automationJob={currentAutomationJob}
					opened={jobDetailShown}
					onClose={() => {
						setJobDetailShown(false);
						setCurrentAutomationJob(null);
					}}
				/>
			)}
		</>
	);
}
