import { Center, createStyles, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { GroupSetting } from '@repo/api-codegen';
import { Button, Icon, Text, Title } from '@repo/foundations';
import { useCallback, useMemo } from 'react';
import {
	IIntegration,
	useUpdateIntegrationGroupSettings,
} from '../../../../../api';
import { IntegrationSpec } from '../../../../../interfaces/IntegrationSpec.ts';
import { SelectIntegrationGroupsPanel } from '../../../components/SelectIntegrationGroupsPanel/SelectIntegrationGroupsPanel.tsx';

const useStyles = createStyles((theme) => ({
	treeWrapper: {
		position: 'relative',
		padding: `${theme.spacing.xs} ${theme.spacing.md}`,
		overflowY: 'hidden',
		maxHeight: '50vh',
	},
}));

interface SelectStepProps {
	integration: IIntegration;
	integrationSpec: IntegrationSpec;
	nextStep?: () => void;
}

export function SelectStep({
	integration,
	integrationSpec,
	nextStep,
}: SelectStepProps) {
	const { classes, theme } = useStyles();

	const { mutateAsync: apiUpdateIntegrationGroupSettings } =
		useUpdateIntegrationGroupSettings(integration.id);

	const updateGroupSettings = useCallback(
		async (newSettings: Record<string, GroupSetting>) => {
			try {
				await apiUpdateIntegrationGroupSettings({
					group_settings: newSettings,
				});
			} catch {
				showNotification({
					message: 'Unable to update group settings',
					color: 'red',
					icon: <Icon name="x" />,
				});
			}
		},
		[apiUpdateIntegrationGroupSettings, nextStep]
	);

	const goNext = useCallback(() => {
		nextStep?.();
	}, []);

	const subtitle = useMemo(() => {
		if (integrationSpec.type === 'builtin') {
			return `Confirm the ${integrationSpec.value.schemaSupport ? 'schemas' : 'folders'} you'd like to include in the sync`;
		}
		return '';
	}, []);

	return (
		<>
			<Stack spacing="xs">
				<Title size="xl">Import</Title>
				<Text size="sm">{subtitle}</Text>
			</Stack>
			<Stack spacing="sm" h="100%" className={classes.treeWrapper}>
				<SelectIntegrationGroupsPanel
					integration={integration}
					spec={integrationSpec}
					classes={classes}
					refreshOnLoad={true}
				/>
			</Stack>
			<Center>
				<Button variant="primary" size="md" onClick={goNext}>
					{nextStep ? 'Continue to sync' : 'Save'}
				</Button>
			</Center>
		</>
	);
}
