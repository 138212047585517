import { ActionIcon, createStyles, Group } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { ITest } from '../../../api';
import { snakeCaseToTitleCase } from '../../../utils/shared.utils';

const useStyles = createStyles((theme) => ({
	text: {
		overflow: 'hidden',
		lineHeight: `${theme.other.space[4]}px`,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		maxWidth: '250px',
	},
}));

interface IEntityDrawerActionBarProps {
	title?: string;
	test: ITest;
	onClose: () => void;
}

function EntityDrawerActionBar({
	title,
	test,
	onClose,
}: IEntityDrawerActionBarProps) {
	const { classes } = useStyles();
	const handleOpenTest = () => {
		window.open(test.url, '_blank');
	};

	return (
		<Group position={title ? 'apart' : 'right'} noWrap p="md">
			{title && (
				<Text size="md" weight="bold" className={classes.text}>
					{snakeCaseToTitleCase(title)}
				</Text>
			)}
			<Group spacing={4}>
				<ActionIcon size="xs" onClick={handleOpenTest}>
					<Icon name="externalLink" />
				</ActionIcon>
				<ActionIcon size="xs" onClick={onClose}>
					<Icon name="x" />
				</ActionIcon>
			</Group>
		</Group>
	);
}

export default EntityDrawerActionBar;
