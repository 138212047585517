import { observer } from 'mobx-react-lite';
import { IComment, ISecodaEntity } from '../../api';
import { EntityType } from '../../lib/types';
import type { WithOnlyIdRequired } from '../../lib/typescript';
import type { DjangoValueType } from '../../pages/TemplatePage/types';
import { AIAssistantSidebar } from '../AIAssistant/AIAssistantSidebar/AIAssistantSidebar';
import { getEntityPageSidebarProps } from '../EntityDrawer/utils';
import EntityPageSidebar from '../EntityPageLayout/EntityPageSidebar';
import type { SidebarEntityKeys } from '../EntityPageLayout/types';
import {
	RightSidebarMode,
	RightSidebarWrapper,
	rightSidebarStore,
} from '../RightSidebar';
import FixedCommentSidebar from './FixedCommentSidebar/FixedCommentSidebar';

interface CommentSidebarProps {
	entity: ISecodaEntity;
	commentThreads: { [rootID: string]: IComment[] };
	handleCreateComment: (
		data: Partial<Omit<IComment, 'id'>>
	) => Promise<IComment>;
	handleUpdateComment: (data: WithOnlyIdRequired<IComment>) => Promise<void>;
	handleDeleteComment: (commentId: string) => Promise<void>;
	updateEntity: (
		key: SidebarEntityKeys,
		value: DjangoValueType,
		saveRemotely?: boolean
	) => void;
	source?: 'Secoda' | 'Confluence';
	entityType: EntityType;
}

export const CommentSidebar = observer(
	({
		entity,
		commentThreads,
		handleCreateComment,
		handleUpdateComment,
		handleDeleteComment,
		updateEntity,
		source = undefined,
		entityType,
	}: CommentSidebarProps) => (
		<RightSidebarWrapper>
			{rightSidebarStore.mode === RightSidebarMode.INFO && (
				<EntityPageSidebar
					entity={entity}
					updateEntity={updateEntity}
					withSource={source}
					{...getEntityPageSidebarProps(entityType)}
				/>
			)}
			{rightSidebarStore.mode === RightSidebarMode.COMMENT && (
				<FixedCommentSidebar
					commentThreads={commentThreads || {}}
					handleCreateComment={handleCreateComment}
					handleUpdateComment={handleUpdateComment}
					handleDeleteComment={handleDeleteComment}
				/>
			)}
			{rightSidebarStore.mode === RightSidebarMode.AI && <AIAssistantSidebar />}
		</RightSidebarWrapper>
	)
);
