import type { MantineNumberSize } from '@mantine/core';
import { Box, useMantineTheme } from '@mantine/core';
import * as Plot from '@observablehq/plot';
import dayjs from 'dayjs';
import { svg } from 'htl';
import { uniq } from 'lodash-es';
import { memo, useEffect, useRef, useState } from 'react';

export interface DataQualityTimeseriesChartPreviewProps {
	data: Array<{ date: Date; score: number }>;
	width?: MantineNumberSize;
	height?: MantineNumberSize;
}

export const DataQualityTimeseriesChartPreview = memo(
	({ data, width, height }: DataQualityTimeseriesChartPreviewProps) => {
		const theme = useMantineTheme();
		const containerRef = useRef<HTMLDivElement>(null);
		const [chartWidth, setChartWidth] = useState<number | undefined>();

		useEffect(() => {
			if (containerRef.current) {
				setChartWidth(containerRef.current.clientWidth);
			}
		}, [containerRef.current?.clientWidth]);

		const isCrossingYears =
			uniq(data.map(({ date }) => dayjs(date).year())).length > 1;
		const dateFormat = isCrossingYears ? 'MMM D, YYYY' : 'MMM D';

		useEffect(() => {
			const plot = Plot.plot({
				width: chartWidth,
				height: typeof height === 'number' ? height : undefined,
				marginTop: 10,
				marginBottom: 10,
				x: {
					axis: null,
				},
				y: {
					axis: null,
					domain: [0, 100],
				},
				color: {
					domain: [0, 100],
					type: 'linear',
				},
				marks: [
					Plot.line(data, {
						x: 'date',
						y: 'score',
						stroke: 'url(#gradient)',
					}),
					function GradientPlot(_index, { y }) {
						return svg`<defs>
						<linearGradient id="gradient" gradientUnits="userSpaceOnUse"
							x1=0 x2=0 y1=${y?.(0)} y2=${y?.(100)}>
								<stop offset=0% stop-color=${theme.other.getColor('fill/critical/default')} />
								<stop offset=50% stop-color=${theme.other.getColor('fill/warning/default')} />
								<stop offset=100% stop-color=${theme.other.getColor('fill/info/default')} />`;
					},
				],
			});
			containerRef.current?.appendChild(plot);
			return () => plot.remove();
		}, [containerRef.current]);

		return <Box w={width} h={height} ref={containerRef} />;
	}
);
DataQualityTimeseriesChartPreview.displayName =
	'DataQualityTimeseriesChartPreview';
