import { Skeleton } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useRef } from 'react';
import {
	IQuestionReply,
	questionReplyQueryKeyFactory,
	questionsQueryKeyFactory,
	useDeleteQuestionReply,
	useQuestionReply,
	useUpdateQuestionReply,
} from '../../../api';
import { ReplyWrapper } from './ReplyWrapper';

export interface IQuestionReplyProps {
	id: string;
	accepted: boolean;
}

export function QuestionReply({ id, accepted }: IQuestionReplyProps) {
	const questionReplyRef = useRef<IQuestionReply | null>(null);

	const { data: questionReply, isLoading } = useQuestionReply({
		id,
		options: {
			suspense: false,
			onSuccess: (data) => {
				questionReplyRef.current = data;
			},
		},
	});

	const queryClient = useQueryClient();
	const { mutateAsync: updateQuestionReply } = useUpdateQuestionReply({
		options: {
			onSuccess: async () => {
				queryClient.invalidateQueries(
					questionReplyQueryKeyFactory.list(1, {
						question_id: questionReplyRef.current?.parent,
					})
				);
				queryClient.invalidateQueries(
					questionsQueryKeyFactory.byId(
						questionReplyRef.current?.parent as string
					)
				);
			},
		},
	});

	const { mutateAsync: deleteQuestionReply } = useDeleteQuestionReply({
		options: {
			onSuccess: async () => {
				queryClient.invalidateQueries(
					questionReplyQueryKeyFactory.list(1, {
						question_id: questionReplyRef.current?.parent,
					})
				);
			},
		},
	});

	const handleUpdate = useCallback(
		(values: Record<string, unknown>) => {
			if (!questionReply) return;
			updateQuestionReply({ data: { id: questionReply.id, ...values } });
		},
		[questionReply, updateQuestionReply]
	);

	const handleDelete = useCallback(() => {
		if (!questionReply) return;
		deleteQuestionReply({ id: questionReply.id });
	}, [deleteQuestionReply, questionReply]);

	if (isLoading || !questionReply) {
		return <Skeleton />;
	}

	return (
		<ReplyWrapper
			entity={questionReply}
			accepted={accepted}
			handleUpdate={handleUpdate}
			handleDelete={handleDelete}
			type="reply"
		/>
	);
}

export default QuestionReply;
