import { hashQueryKey } from '@tanstack/react-query';
import Fuse from 'fuse.js';
import { compact, find, flatten, map, uniqBy } from 'lodash-es';
import type { QueryKeyFactory } from '../factories';
import queryClient from '../queryClient';
import type { IBaseModel } from '../types';

const modelsListCache = new Map<string, IBaseModel | undefined>();
const fuzzySearchCache = new Map<string, IBaseModel[]>();

/**
 * Returns a list of models from all pages of the query cache.
 *
 * @param queryKeyFactory Query key factory to fetch the appropriate cache of the models
 * @returns A list of models from the query cache or an empty array
 */
export const getItemsFromListCache = <TModel extends IBaseModel>(
	queryKeyFactory: QueryKeyFactory
): TModel[] => {
	const queryKey = queryKeyFactory.allLists();
	const queryCaches = queryClient.getQueryCache().findAll(queryKey);

	const cacheData = compact(map(queryCaches, 'state.data'));
	const items = compact(map(cacheData, 'results'));

	return uniqBy(flatten(items), 'id');
};

/**
 * Returns a model from the query cache.
 * The model is cached based on it's namespace and id to prevent unnecessary refetches.
 *
 * @param queryKeyFactory Query key factory to fetch the appropriate cache of the models
 * @param id Id of the model to filter from the list cache
 * @returns A model from the query cache or undefined
 */
export const filterFromListCacheById = <TModel extends IBaseModel>(
	queryKeyFactory: QueryKeyFactory,
	id: string
): TModel | undefined => {
	const cacheKey = hashQueryKey([queryKeyFactory.namespace, id]);

	if (modelsListCache.has(cacheKey)) {
		return modelsListCache.get(cacheKey) as TModel;
	}

	const items = getItemsFromListCache<TModel>(queryKeyFactory);
	const item = find(items, ['id', id]);

	if (item) {
		modelsListCache.set(cacheKey, item);
	}

	return item;
};

/**
 * Returns a list of models from the query cache.
 * The list is cached based on it's namespace and ids to prevent unnecessary refetches.
 *
 * @param queryKeyFactory A query key factory to fetch the appropriate cache of the models
 * @param ids Ids of the models to filter from the list cache
 * @returns A list of models from the query cache or an empty array
 */
export const filterFromListCacheByIds = <TModel extends IBaseModel>(
	queryKeyFactory: QueryKeyFactory,
	ids: string[]
): TModel[] => {
	const items = map(ids, (id) =>
		filterFromListCacheById<TModel>(queryKeyFactory, id)
	);

	return compact(items);
};

/**
/**
 * Returns a filtered list of models from the query cache after the fuzzy search.
 * The list is cached to prevent unnecessary refetches.
 *
 * @param queryKeyFactory Query key factory to fetch the appropriate queryKey
 * @param searchTerm Search term to filter the list by
 * @param keys Keys to search in from the model
 * @returns Filtered list of models from the query cache or an empty array
 */
export const fuzzySearchInListCache = <TModel extends IBaseModel>(
	queryKeyFactory: QueryKeyFactory,
	searchTerm: string,
	keys: Fuse.FuseOptionKey<TModel>[] = []
): TModel[] => {
	const cacheKey = hashQueryKey([
		queryKeyFactory.namespace,
		searchTerm,
		...keys,
	]);

	if (fuzzySearchCache.has(cacheKey)) {
		return fuzzySearchCache.get(cacheKey) as TModel[];
	}

	const items = getItemsFromListCache<TModel>(queryKeyFactory);

	if (!items) {
		fuzzySearchCache.set(cacheKey, []);
		return [];
	}

	if (searchTerm === '') {
		fuzzySearchCache.set(cacheKey, items);
		return items;
	}

	const fuse = new Fuse<TModel>(items, { keys });
	const searchResults = map(fuse.search(searchTerm), 'item');

	fuzzySearchCache.set(cacheKey, searchResults);

	return searchResults;
};
