import { createStyles } from '@mantine/core';
import CollapsableStack from '@repo/common/components/CollapsableStack';
import { Text } from '@repo/foundations';
import { Incident } from '../../../../api';
import { useLineageList } from '../../../../api/hooks/lineage/useLineage';
import DownstreamResourcesTable from '../../../IncidentPage/containers/DownstreamResourcesTable';

interface IncidentDownstreamResourcesProps {
	incident: Incident;
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		padding: '0',
	},
	title: {
		fontSize: theme.other.typography.title.md,
	},
}));

function IncidentDownstreamResources({
	incident,
}: IncidentDownstreamResourcesProps) {
	const { classes } = useStyles();

	const { data: lineageData } = useLineageList({
		filters: {
			id: incident.target,
			depth: 3,
			direction: 'DOWNSTREAM',
		},
	});

	return (
		<CollapsableStack
			collapsedText={lineageData?.count?.toString()}
			defaultOpened={false}
			alwaysShowCollapsedText={true}
			groupName={'Downstream Resources'}
			className={classes.wrapper}
			classNames={{ title: classes.title }}
			withDivider={false}
		>
			{lineageData?.count === 0 ? (
				<Text size="sm" color="text/secondary/default">
					No downstream resources were affected.
				</Text>
			) : (
				<DownstreamResourcesTable targetId={incident.target} />
			)}
		</CollapsableStack>
	);
}

export default IncidentDownstreamResources;
