/* eslint-disable prefer-destructuring */
import { MarkdownPluginOptions } from '@repo/secoda-editor/types';
import type MarkdownIt from 'markdown-it';
import Token from 'markdown-it/lib/token';

const TABLE_EXTENSIONS = ['table', 'tr', 'td', 'th'];

export default function markdownTables(
	md: MarkdownIt,
	options?: MarkdownPluginOptions
): void {
	const { disabledExtensions = [] } = options || {};
	const requiredTableExtensionsDisabled = TABLE_EXTENSIONS.some((ext) =>
		disabledExtensions.includes(ext)
	);

	// this plugin requires markdown-it-attrs to have run before it (aka curly_attributes)
	// https://github.com/arve0/markdown-it-attrs/blob/a77066ccc84c37786f218a618c2bc597f5def1c8/index.js#L40C36-L40C52
	md.core.ruler.after('curly_attributes', 'tables-pm', (state) => {
		const { tokens } = state;
		let inside = false;

		for (let i = tokens.length - 1; i > 0; i -= 1) {
			// if any of the required table extensions are disabled, we need to replace the table by a paragraph
			if (requiredTableExtensionsDisabled) {
				// wrap table with paragraph
				if (tokens[i].type === 'table_open') {
					tokens.splice(i, 1, new Token('paragraph_open', 'p', 1));
				}

				// wrap table with paragraph
				if (tokens[i].type === 'table_close') {
					tokens.splice(i, 1, new Token('paragraph_close', 'p', -1));
				}

				// add a <br> separating each row
				if (tokens[i].type === 'tr_open' && tokens[i - 1].type === 'tr_close') {
					tokens.splice(i + 1, 0, new Token('br', 'br', 1));
				}

				// add a <br> separating the header from the body
				if (
					tokens[i].type === 'tbody_open' &&
					tokens[i - 1].type === 'thead_close'
				) {
					tokens.splice(i + 1, 0, new Token('br', 'br', 1));
				}

				// add a | separating each cell
				if (
					(tokens[i].type === 'th_open' && tokens[i - 1].type === 'th_close') ||
					(tokens[i].type === 'td_open' && tokens[i - 1].type === 'td_close')
				) {
					const pipeToken = new Token('text', '', 0);
					pipeToken.level = 1;
					pipeToken.content = ' | ';
					tokens.splice(i + 1, 0, pipeToken);
				}

				if (
					[
						'thead_open',
						'thead_close',
						'th_open',
						'th_close',
						'tbody_open',
						'tbody_close',
						'tr_open',
						'tr_close',
						'td_open',
						'td_close',
					].includes(tokens[i].type)
				) {
					tokens.splice(i, 1);
				}
			} else {
				if (inside) {
					tokens[i].level -= 1;
				}

				// Filter out incompatible tokens from markdown-it that we don't need
				// in prosemirror. thead/tbody do nothing.
				if (
					['thead_open', 'thead_close', 'tbody_open', 'tbody_close'].includes(
						tokens[i].type
					)
				) {
					inside = !inside;
					tokens.splice(i, 1);
				}

				if (['th_open', 'td_open'].includes(tokens[i].type)) {
					// Markdown-it table parser does not return paragraphs inside the cells
					// but prosemirror requires them, so we add 'em in here.
					tokens.splice(i + 1, 0, new Token('paragraph_open', 'p', 1));

					// Markdown-it table parser stores alignment as html styles, convert
					// to a simple string here
					const tokenAttrs = tokens[i].attrs;
					if (tokenAttrs) {
						const style = tokenAttrs[0][1];
						tokens[i].attrSet('alignment', style.split(':')[1]);
					}
				}

				if (['th_close', 'td_close'].includes(tokens[i].type)) {
					tokens.splice(i, 0, new Token('paragraph_close', 'p', -1));
				}
			}
		}

		return false;
	});
}
