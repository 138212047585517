import { Divider, Input, Menu, Stack } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { includes, isEmpty, map, size } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type { ChangeEvent } from 'react';
import { useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import type {
	FilterMenuItem,
	FilterMenuOption,
	FilterValue,
} from '../../pages/SearchPage/FilterCarousel/FilterCarousel.constants';
import {
	filterByTerm,
	filterForUnselected,
} from '../../pages/SearchPage/FilterCarousel/FilterDropdown/utils';
import useStyles from './FilterMenu.styles';
import {
	FilterMenuDropdownHeader,
	FilterMenuDropdownItem,
} from './FilterMenuDropdown';
import { FilterMenuTarget } from './FilterMenuTarget';
import type { FilterMenuTargetBorderStyle } from './FilterMenuTarget/FilterMenuTarget.styles';
import { formatTargetDisplay } from './utils';

export interface IFilterMenuProps {
	filterMenuItem: FilterMenuItem;
	setIsInclude: (filterValue: FilterValue, isInclude: boolean) => void;
	toggleSelectedOptions: (
		filterValue: FilterValue,
		filterOption: FilterMenuOption
	) => void;
	onDangerClick?: () => void;
	borderStyle?: FilterMenuTargetBorderStyle;
	isRadio?: boolean;
	showInclusionDropdown?: boolean;
	showSearch?: boolean;
}

const FilterMenu = observer(
	({
		filterMenuItem,
		setIsInclude,
		toggleSelectedOptions,
		onDangerClick = () => {},
		borderStyle = 'solid',
		isRadio = false,
		showInclusionDropdown = false,
		showSearch = true,
	}: IFilterMenuProps) => {
		const [opened, setOpened] = useState(false);

		const [term, setTerm] = useState('');

		const { classes, theme } = useStyles();

		const LeftIcon = filterMenuItem.icon;

		const itemContent = (index: number, option: FilterMenuOption) => (
			<FilterMenuDropdownItem
				key={index}
				label={option.label}
				icon={option.icon}
				checked={includes(
					map(filterMenuItem.selectedOptions, 'value'),
					option.value
				)}
				isRadio={isRadio}
				onClick={() => toggleSelectedOptions(filterMenuItem.value, option)}
			/>
		);

		const selectedFilteredOptions = useMemo(
			() => filterByTerm(filterMenuItem.selectedOptions, term),
			[filterMenuItem.selectedOptions, term]
		);

		const unselectedFilteredOptions = useMemo(
			() =>
				filterByTerm(
					filterForUnselected(
						filterMenuItem.options,
						filterMenuItem.selectedOptions
					),
					term
				),
			[filterMenuItem.options, filterMenuItem.selectedOptions, term]
		);

		const hasSelectedFilteredOptions = useMemo(
			() => !isEmpty(selectedFilteredOptions),
			[selectedFilteredOptions]
		);

		const hasUnselectedFilteredOptions = useMemo(
			() => !isEmpty(unselectedFilteredOptions),
			[unselectedFilteredOptions]
		);

		const onInclusiveClick = () => {
			setIsInclude(filterMenuItem.value, true);
		};

		const onExclusiveClick = () => {
			setIsInclude(filterMenuItem.value, false);
		};

		const handleOnTermChange = (e: ChangeEvent<HTMLInputElement>) => {
			setTerm(e.currentTarget.value);
		};

		return (
			<Menu
				classNames={{
					dropdown: classes.dropdown,
				}}
				width={theme.other.width.sm}
				position="bottom-start"
				opened={opened}
				onChange={setOpened}
				closeOnItemClick={false}
				withinPortal
			>
				<Menu.Target>
					<FilterMenuTarget
						variant={
							size(filterMenuItem.selectedOptions) === 0
								? 'secondary'
								: 'primary'
						}
						borderStyle={borderStyle}
						leftIcon={<LeftIcon size={theme.other.iconSize.sm} />}
						rightIcon={<IconChevronDown size={theme.other.iconSize.sm} />}
					>
						{formatTargetDisplay(
							filterMenuItem.label,
							filterMenuItem.isInclude,
							filterMenuItem.selectedOptions
						)}
					</FilterMenuTarget>
				</Menu.Target>
				<Menu.Dropdown>
					<Stack spacing={0}>
						{showInclusionDropdown && (
							<FilterMenuDropdownHeader
								label={filterMenuItem.label}
								isInclusive={filterMenuItem.isInclude}
								onInclusiveClick={onInclusiveClick}
								onExclusiveClick={onExclusiveClick}
								onDangerClick={onDangerClick}
							/>
						)}
						{showSearch && (
							<Input
								autoFocus
								height={theme.other.height.sm}
								mb={10}
								value={term}
								placeholder={`Search ${filterMenuItem.label.toLowerCase()}`}
								onChange={handleOnTermChange}
							/>
						)}

						{(hasSelectedFilteredOptions || hasUnselectedFilteredOptions) && (
							<>
								{map(selectedFilteredOptions, (option, index) => (
									<FilterMenuDropdownItem
										key={index}
										label={option.label}
										icon={option.icon}
										checked={includes(
											map(filterMenuItem.selectedOptions, 'value'),
											option.value
										)}
										isRadio={isRadio}
										onClick={() =>
											toggleSelectedOptions(filterMenuItem.value, option)
										}
									/>
								))}
								{hasSelectedFilteredOptions && hasUnselectedFilteredOptions && (
									<Divider my={10} />
								)}
								{hasUnselectedFilteredOptions && (
									<Virtuoso
										style={{
											height:
												size(unselectedFilteredOptions) > 8
													? 256
													: size(unselectedFilteredOptions) * 32,
										}}
										data={unselectedFilteredOptions}
										totalCount={size(unselectedFilteredOptions)}
										itemContent={itemContent}
									/>
								)}
							</>
						)}
					</Stack>
				</Menu.Dropdown>
			</Menu>
		);
	}
);

export default FilterMenu;
