import { Group } from '@mantine/core';
import type { TeamOut } from '@repo/api-codegen';
import { useApiListTeams } from '@repo/api-codegen';
import { Navigate } from 'react-router';
import { EmptyState } from '../../components/EmptyState';
import { LEGACY_NON_TEAM_PATHS } from './constants';

function LegacyNonTeamPage({
	oldPath,
}: {
	oldPath: (typeof LEGACY_NON_TEAM_PATHS)[number];
}) {
	const { data: teams } = useApiListTeams(
		{
			queryParams: {
				only_joined: true,
			},
		},
		{
			suspense: false,
			select: (res) => res.results,
		}
	);

	if (!teams) {
		return null;
	}

	// Grab the first team user has joined, favor default team
	const team: TeamOut | undefined = teams.sort(
		(t1, t2) => +t2.is_default_team - +t1.is_default_team
	)[0];

	if (!team) {
		return (
			<Group w="100%" mih="50vh" position="center">
				<EmptyState
					imgSrc="/images/empty-state/alert.svg"
					title="Not found"
					description="This page no longer exist."
					includeGoBack={false}
					size="sm"
				/>
			</Group>
		);
	}

	return <Navigate to={`/teams/${team.id}${oldPath}`} replace />;
}

export default LegacyNonTeamPage;
