import { Center, Group, Select, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button, Text, Title } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import { isString } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { parse } from 'yaml';
import { IIntegration, useIntegrationList } from '../../../../../api';
import { Integration } from '../../../../../lib/models';
import { IntegrationSelectItem } from './IntegrationSelectItem';

interface MappingStepProps {
	integration: IIntegration;
	nextStep: () => void;
}

export function MappingStep({ integration, nextStep }: MappingStepProps) {
	const { data: integrations } = useIntegrationList({});

	const [mappings, setMappings] = useState<Record<string, string>>({});

	useEffect(() => {
		if (integration && integration.properties?.mappings) {
			const parsedMappings = {};
			// @ts-expect-error TS(7006): Parameter 'mapping' implicitly has an 'any' type.
			integration.properties.mappings.forEach((mapping) => {
				// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
				parsedMappings[mapping.datasource] = mapping.integration_id;
			});

			setMappings(parsedMappings);
		}
	}, [integration.id]);

	const configuration = useMemo(() => {
		try {
			if (
				'configuration_file' in integration.credentials &&
				isString(integration.credentials.configuration_file)
			) {
				return parse(integration.credentials.configuration_file);
			}
			if (
				's3_configuration_file' in integration.credentials &&
				isString(integration.credentials.s3_configuration_file)
			) {
				return parse(integration.credentials.s3_configuration_file);
			}
			if (
				'role_configuration_file' in integration.credentials &&
				isString(integration.credentials.role_configuration_file)
			) {
				return parse(integration.credentials.role_configuration_file);
			}
			showNotification({
				id: integration.id,
				title: 'Configuration File Error',
				message: 'Configuration file is not a string.',
				color: 'red',
			});
		} catch (e) {
			showNotification({
				id: integration.id,
				title: 'Configuration File Error',
				message:
					'Please check your configuration file for Great Expectations. It is not valid YAML.',
				color: 'red',
			});
		}

		return {};
	}, [
		integration.credentials?.configuration_file,
		integration.credentials?.s3_configuration_file,
		integration.credentials?.role_configuration_file,
	]);

	const datasources = useMemo(
		() =>
			'datasources' in configuration
				? Object.keys(configuration.datasources)
				: [],
		[configuration]
	);

	const integrationOptions = useMemo(
		() =>
			integrations?.results
				?.filter((i) => i.type !== 'great_expectations')
				.map((i) => ({
					id: i.id,
					label: i.name,
					value: i.id,
					type: i.type,
				})) || [],
		[integrations]
	);

	const updateMappingSettings = useCallback(async () => {
		// @ts-expect-error TS(2345): Argument of type '{ id: string; }' is not assignab... Remove this comment to see the full error message
		const currentIntegration = await new Integration({
			id: integration.id,
		}).sync();
		currentIntegration.properties = {
			mappings: Object.keys(mappings).map((datasource) => ({
				integration_id: mappings[datasource],
				datasource,
			})),
		};

		currentIntegration.save(['properties']).catch(() => {
			showNotification({
				message: 'Could not map datasources',
				color: 'red',
			});
		});

		nextStep();
	}, [integration.id, mappings, nextStep]);

	const handleOnItemChange = useCallback((id: string, datasource: string) => {
		setMappings((prevMappings) => ({ ...prevMappings, [datasource]: id }));
	}, []);

	return (
		<>
			<Title size="xl">Mapping</Title>
			<Stack spacing={'sm'}>
				<Group position="apart" grow>
					<Title size={'md'}>Datasource</Title>
					<Title size={'md'}>Integration</Title>
				</Group>
				<Stack mah={space[120]}>
					{datasources.map((datasource) => (
						<Group position="apart" grow key={datasource}>
							<Text size="sm">{datasource}</Text>
							<Select
								placeholder="Select an integration"
								size="xs"
								data={integrationOptions}
								value={mappings[datasource]}
								onChange={(value: string) =>
									handleOnItemChange(value, datasource)
								}
								itemComponent={IntegrationSelectItem}
							/>
						</Group>
					))}
				</Stack>
			</Stack>
			<Center>
				<Button variant="primary" size="md" onClick={updateMappingSettings}>
					Continue to sync
				</Button>
			</Center>
		</>
	);
}
