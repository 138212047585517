import { Avatar, useMantineTheme } from '@mantine/core';
import TagIcon from '@repo/common/components/TagIcon/TagIcon';
import type { ITag } from '../../api';

interface ITagAvatarProps {
	tag: ITag;
}

export function TagAvatar({ tag }: ITagAvatarProps) {
	const theme = useMantineTheme();
	const tagColor = tag?.color ?? theme.other.getColor('icon/brand/default');
	const iconHeight = theme.other.space[3];

	return (
		<Avatar
			miw={`${iconHeight}px`}
			mih={`${iconHeight}px`}
			h={`${iconHeight}px`}
			w={`${iconHeight}px`}
		>
			<TagIcon color={tagColor} />
		</Avatar>
	);
}
