import { startCase, toLower } from 'lodash-es';

/**
 * Checks if a string is null, undefined, or consists only of whitespace characters.
 * @param str - The string to check.
 * @returns `true` if the string is null, undefined, or whitespace; otherwise, `false`.
 */
export function isNullOrWhiteSpace(str: string | null | undefined) {
	return !str || str.trim().length === 0;
}

/**
 * Checks if a string represents a color, either in hexadecimal or RGB format.
 * @param str - The string to check.
 * @returns `true` if the string represents a color; otherwise, `false`.
 */
export function isColor(str: string) {
	return str.startsWith('#') || str.startsWith('rgb');
}

/**
 * Checks if a string represents a URL starting with "https".
 * @param str - The string to check.
 * @returns `true` if the string represents a URL starting with "https"; otherwise, `false`.
 */
export function isUrl(str: string) {
	return str.startsWith('https');
}

/**
 * Formats a list of strings or objects into a human-readable list.
 * e.g. ['a', 'b', 'c'] -> 'a, b, and c'
 * e.g. [{ name: 'a' }, { name: 'b' }, { name: 'c' }] -> 'a, b, and c'
 * e.g ['a'] -> 'a'
 *
 * @param arr The list to format.
 * @param type The type of conjunction to use.
 * @returns The formatted list as a string.
 */
export function formatListWithConjunction<T>(
	arr: T[],
	type: 'and' | 'or' = 'and'
) {
	const isStringArray = arr.every((item) => typeof item === 'string');
	const listType = type === 'and' ? 'conjunction' : 'disjunction';

	const formattedList = isStringArray
		? (arr as string[])
		: arr.map((item) => JSON.stringify(item));

	const formatter = new Intl.ListFormat('en', {
		style: 'long',
		type: listType,
	});
	return formatter.format(formattedList);
}

export function sentenceCase(str: string): string {
	return startCase(toLower(str));
}

export function formatIntegrationType(type: string): string {
	const integrationTypeMapping: Record<string, string> = {
		bigquery: 'BigQuery',
		confluent: 'Confluent',
		dbt: 'dbt',
		dbt_cloud: 'dbt Cloud',
		dbt_core: 'dbt Core',
		looker_studio: 'Looker Studio',
		mongodb: 'MongoDB',
		ms_teams: 'MS Teams',
		mssql: 'MS SQL',
		mysql: 'MySQL',
		pagerduty: 'PagerDuty',
		powerbi: 'PowerBI',
		quicksight: 'QuickSight',
		s3: 'S3',
		singlestore: 'SingleStore',
		ssrs: 'SSRS',
		thoughtspot: 'ThoughtSpot',
	};

	if (integrationTypeMapping.hasOwnProperty(type)) {
		return integrationTypeMapping[type];
	}
	return startCase(type);
}

export function escapeStringRegexp(text: string) {
	if (typeof text !== 'string') {
		return text;
	}

	return text.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
}
