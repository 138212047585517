import {
	fetchAutomationHistoryList,
	useAutomationHistoryList,
} from '../../api/hooks/automationHistory';
import { TableV2 } from '../TableV2';
import { useHistoryTableColumns } from './hooks';

interface AutomationHistoryTableProps {
	automationJobId: string;
}

export function AutomationHistoryDetailsTable({
	automationJobId,
}: AutomationHistoryTableProps) {
	const columns = useHistoryTableColumns();

	return (
		<TableV2
			columns={columns}
			usePaginationList={useAutomationHistoryList}
			fetchPaginationList={fetchAutomationHistoryList}
			defaultRequiredSearchParams={{
				automation_job_id: automationJobId,
			}}
			columnVisibility={{
				catalogType: 'automation_history',
				catalogServerType: 'automation_history',
			}}
			withInteractiveHeader={false}
			withFilters={false}
			withSearch
			withInfiniteScroll={false}
		/>
	);
}
