/* eslint-disable no-console */
import { Box } from '@mantine/core';
import { FilterOperator } from '@repo/common/components/Filter/types';
import MultiSelectorTarget from '@repo/common/components/MultiSelector/MultiSelectorTarget';
import { hasProperty } from 'lib0/object';
import { get, isArray, isNil } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	ISecodaEntity,
	queryClient,
	secodaEntitiesQueryKeyFactory,
	useAuthUser,
} from '../../../api';
import { fetchSecodaEntity } from '../../../api/hooks/secodaEntity/fetchSecodaEntities';
import { isJson } from '../../../utils/utils';
import { FilterOptionType } from '../../Filter';
import { FILTER_OPTIONS_CONFIG } from '../../Filter/constants';
import { Filter } from '../../Filter/Filter';
import { SecodaEntityIcon } from '../../SecodaEntity';

interface RelatedEntitiesRenderProps {
	entity: ISecodaEntity & { related_entities?: ISecodaEntity[] };
	field?: string;
	onChange?: (entityId: string) => (value: (string | boolean)[]) => void;
}

const useMultiSelectorTargetSelected = (
	entity: ISecodaEntity & { related_entities?: ISecodaEntity[] },
	selected: string[]
) => {
	const [selectedEntities, setSelectedEntities] = useState<
		Array<{ label: string; value: string; icon: JSX.Element }>
	>([]);

	useEffect(() => {
		if (
			selected.every((id) =>
				entity.related_entities?.some((re) => re.id === id)
			)
		) {
			setSelectedEntities(
				selected.map((id) => ({
					label:
						entity.related_entities?.find((re) => re.id === id)?.title ||
						'Untitled',
					value: id,
					icon: (
						<SecodaEntityIcon
							entity={entity.related_entities?.find((re) => re.id === id)}
							size={20}
						/>
					),
				}))
			);
			return;
		}

		const abortController = new AbortController();
		const { signal } = abortController;

		const fetchEntities = async () => {
			try {
				const entities = await Promise.all(
					selected?.map(async (id) => {
						let relatedEntity = entity.related_entities?.find(
							(re) => re.id === id
						);
						if (!relatedEntity) {
							try {
								// First, try to get the entity from the query cache
								relatedEntity = queryClient.getQueryData(
									secodaEntitiesQueryKeyFactory.byId(id)
								);

								// If not in cache, fetch it
								if (!relatedEntity) {
									relatedEntity = await fetchSecodaEntity(id);
								}
							} catch (error: unknown) {
								if (error instanceof Error && error.name === 'AbortError') {
									console.error('Fetch aborted');
									return null;
								}
								console.error('Error fetching entity:', error);
								return null;
							}
						}
						return {
							label: relatedEntity.title || 'Untitled',
							value: relatedEntity.id,
							icon: <SecodaEntityIcon entity={relatedEntity} size={20} />,
						};
					}) || []
				);
				if (!signal.aborted) {
					setSelectedEntities(entities.filter((e) => e !== null));
				}
			} catch (error: unknown) {
				if (error instanceof Error && error.name !== 'AbortError') {
					console.error('Error fetching entities:', error);
				}
			}
		};

		fetchEntities();

		// eslint-disable-next-line consistent-return
		return () => {
			abortController.abort();
		};
	}, [entity.related_entities, selected]);

	return selectedEntities;
};

export default function RelatedEntitiesRender({
	entity,
	field = 'related_entities',
	onChange,
}: RelatedEntitiesRenderProps) {
	// eslint-disable-next-line no-underscore-dangle
	const _initialValue =
		(isJson(get(entity, field))
			? JSON.parse(get(entity, field))
			: get(entity, field)) ?? [];
	// eslint-disable-next-line no-underscore-dangle
	const initialValue = isArray(_initialValue)
		? _initialValue.map((e: ISecodaEntity | string) =>
				hasProperty(e, 'id') ? (e as ISecodaEntity).id : (e as string)
			)
		: [];

	const { isViewerUser } = useAuthUser();

	const [selected, setSelected] = useState(initialValue ?? []);

	const handleChange = useCallback(
		(value: (string | boolean)[]) => {
			if (value !== selected) {
				setSelected(value as string[]);
				onChange?.(entity.id)(value);
			}
		},
		[onChange, entity.id, selected]
	);

	const multiSelectorTargetSelected = useMultiSelectorTargetSelected(
		entity,
		selected
	);

	const children = useMemo(
		() => (
			<MultiSelectorTarget
				selected={multiSelectorTargetSelected}
				iconType="react-node"
				property="entity"
				propertyLabel="Related entities"
				isMenuItemBadge={false}
				isViewerUser={false}
				permittedId={''}
				readOnly
			/>
		),
		[multiSelectorTargetSelected]
	);

	const value = useMemo(
		() => ({
			filterType: FilterOptionType.RELATED,
			operator: FilterOperator.Is,
			value: selected,
		}),
		[selected]
	);

	if (isNil(onChange)) {
		return children;
	}

	if (isViewerUser) {
		return children;
	}

	return (
		<Box pos="relative">
			<Filter
				value={value}
				filterOption={FILTER_OPTIONS_CONFIG[FilterOptionType.RELATED]}
				onChange={(filterValue) => handleChange(filterValue?.value as string[])}
			>
				{children}
			</Filter>
		</Box>
	);
}
