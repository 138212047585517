import { observer } from 'mobx-react-lite';

import { isNil } from 'lodash-es';
import type { AutomationField } from '../../../../../api';
import { AutomationCondition } from '../../../../../api/types/models/automation/automationFilters';
import { isValidEnumValue } from '../../../../../utils/enumUtils';
import AutomationCardButton from '../../AutomationCardButton';
import { AUTOMATION_CONDITION_DISPLAY_MAP } from './constants';
import { useAutomationActionCardFilterStore } from './context';
import { getFilterConditionOptions } from './utils';

interface AutomationActionCardFilterConditionButtonProps {
	filterSearchKey: string;
	field?: AutomationField;
	condition?: AutomationCondition;
	handleFilterUpdate: () => void;
}

function AutomationActionCardFilterConditionButton({
	field,
	condition,
	filterSearchKey,
	handleFilterUpdate,
}: AutomationActionCardFilterConditionButtonProps) {
	const store = useAutomationActionCardFilterStore();

	// Existing filters can have their conditions updated but not their values
	// Need to update the automation with new operations when either the condition or value is changed
	const handleConditionSelection = (option: string | boolean) => {
		if (isValidEnumValue(AutomationCondition, option)) {
			store.setFilterCondition(filterSearchKey, option);
			// Set the value to an empty string if the condition is IS_SET or IS_NOT_SET
			if (
				[AutomationCondition.IS_NOT_SET, AutomationCondition.IS_SET].includes(
					option
				)
			) {
				store.setFilterValue({
					key: filterSearchKey,
					value: '',
					display: '',
				});
			}

			// Only call BE if the updated filter condition has a value
			if (!isNil(store.getFilter(filterSearchKey)?.value)) {
				handleFilterUpdate();
			}
		}
	};

	return (
		<AutomationCardButton
			buttonLabel={
				condition ? AUTOMATION_CONDITION_DISPLAY_MAP[condition] : 'Condition'
			}
			buttonOptions={
				field
					? getFilterConditionOptions(field).map((option) => ({
							label: AUTOMATION_CONDITION_DISPLAY_MAP[option],
							value: option,
						}))
					: []
			}
			onClick={handleConditionSelection}
		/>
	);
}

export default observer(AutomationActionCardFilterConditionButton);
