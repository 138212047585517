import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useIncidentList, type Incident } from '../../api';
import { TableV2 } from '../../components/TableV2';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import { useFeatureFlags } from '../../utils/featureFlags';
import { useColumns } from './IncidentList.hooks';

function IncidentList({
	defaultRequiredSearchParams,
	onTotalRowCountChange,
}: {
	defaultRequiredSearchParams?: Record<string, string>;
	onTotalRowCountChange?: (count: number) => void;
}) {
	const columns = useColumns();

	const { monitoringV2 } = useFeatureFlags();

	const navigate = useNavigate();

	const handleClick = useCallback(
		(incidentId: string, monitorId: string) => {
			navigate(
				monitoringV2
					? `/monitor/${monitorId}?incident=${incidentId}`
					: `/incident/${incidentId}`
			);
		},
		[navigate, monitoringV2]
	);

	return (
		<TableV2<Incident>
			columnVisibility={{
				catalogServerType: 'incidents',
				catalogType: 'incident',
			}}
			pluralTypeString="incidents"
			onTotalRowCountChange={onTotalRowCountChange}
			withCheckbox={false}
			withInteractiveHeader
			withSearch
			withFilters={false}
			defaultSort={null}
			withCsvExport
			columns={columns}
			usePaginationList={useIncidentList}
			defaultRequiredSearchParams={defaultRequiredSearchParams}
			onCellClick={(cell: {
				column: ExtendedDataTableColumn<Incident>;
				record: { id: string; monitor: string };
			}) => {
				if (cell.column.navigate !== false) {
					handleClick(cell.record.id, cell.record.monitor);
				}
			}}
		/>
	);
}

export default observer(IncidentList);
