import {
	FullWidthLoadingSpinner,
	FullWidthLoadingSpinnerProps,
} from './FullWidthLoadingSpinner';

export interface SmallLoadingSpinnerProps extends FullWidthLoadingSpinnerProps {
	mr?: number;
	ml?: number;
	mt?: number;
	size?: 'sm' | 'xs';
}
export function SmallLoadingSpinner({
	mr,
	ml,
	mt,
	size,
	...rest
}: SmallLoadingSpinnerProps) {
	return (
		<FullWidthLoadingSpinner
			mt={mt ?? 0}
			mr={mr ?? 0}
			ml={ml ?? 0}
			thickness="2px"
			speed="0.65s"
			size={size ?? 'sm'}
			{...rest}
		/>
	);
}
