/* eslint-disable prefer-destructuring */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useWorkspace } from '../api';
import {
	ENTERPRISE_PLAN,
	FREE_PLAN,
	workspacePlans as plans,
	TRIAL_PLAN,
} from '../data/plans';
import type { Plan } from '../interfaces/Plan';
import { useFeatureFlags } from '../utils/featureFlags';

const DEFAULT_BILLING_PLAN = import.meta.env.VITE_DEFAULT_BILLING_PLAN;
const TRIAL_LENGTH = 14; // In day(s).

export const getPlanDetails = (
	workspacePlanId: string | null,
	trialStartedAt: moment.MomentInput,
	defaultPlanId: string | undefined,
	skipDefaultBillingPlan?: boolean
) => {
	let plan: Plan | undefined;
	let daysLeftInTrial = 0;
	let paywall = false;
	let trial = false;

	if (
		!skipDefaultBillingPlan &&
		defaultPlanId?.toLowerCase() === ENTERPRISE_PLAN.id?.toLowerCase()
	) {
		// The DEFAULT_BILLING_PLAN takes precedence over the workspace plan.
		plan = ENTERPRISE_PLAN;
	} else if (
		workspacePlanId &&
		// This check should not be required, but for now, it is. This is
		// because retool always adds 'trial' as plan. This is not necessary,
		// and should be implied from the trialStartedAt.
		workspacePlanId?.toLowerCase() !== TRIAL_PLAN.id?.toLowerCase()
	) {
		plan =
			plans.find((p) => p.all_plans.includes(workspacePlanId)) ||
			plans.find(
				(p) => p.id?.toLowerCase() === workspacePlanId?.toLowerCase()
			) ||
			FREE_PLAN;
	} else {
		// If no plan has been set, check if there are trial days left.
		const difference = Math.abs(moment(trialStartedAt).diff(moment(), 'days'));
		daysLeftInTrial = TRIAL_LENGTH - difference;
		if (daysLeftInTrial <= 0) {
			plan = undefined;
			paywall = true;
			trial = false;
		} else {
			plan = TRIAL_PLAN;
			paywall = false;
			trial = true;
		}
	}

	return {
		plan,
		daysLeftInTrial,
		paywall,
		trial,
		isPayingCustomer:
			!trial &&
			!paywall &&
			!!plan?.id &&
			plan?.id !== FREE_PLAN.id &&
			plan?.id !== TRIAL_PLAN.id,
	};
};

export const usePlan = () => {
	const { workspace } = useWorkspace();
	const startedAt = workspace?.free_trial_started_at;
	const workspacePlanId = workspace.customer_plan_id;

	// We set this in the CI to test plans other than the enterprise plan.
	const { skipDefaultBillingPlan } = useFeatureFlags();

	// Default state is a paid plan, to avoid flickering.
	const [billingDetails, setBillingDetails] = useState({
		plan: ENTERPRISE_PLAN as Plan | undefined,
		daysLeftInTrial: 0,
		paywall: false,
		trial: false,
		isPayingCustomer: true,
	});

	useEffect(() => {
		const details = getPlanDetails(
			workspacePlanId,
			startedAt,
			DEFAULT_BILLING_PLAN,
			skipDefaultBillingPlan
		);
		setBillingDetails(details);
	}, [skipDefaultBillingPlan, startedAt, workspacePlanId]);

	return billingDetails;
};
