import type { ImageProps } from '@mantine/core';
import { Image } from '@mantine/core';
import s3Svg from '@repo/common/public/images/logos/integrations/s3.svg';
import { isNil } from 'lodash-es';
import { useIntegrationPrefetched } from '../../api';
import type { EntityType } from '../../lib/types';
import { getIconByEntityType } from '../../utils/integrationLogo';

interface IIntegrationLogo extends ImageProps {
	entityType?: EntityType;
	integrationType?: string;
	integrationId?: string;
	size?: number;
	iconOverride?: any;
}

function IntegrationLogo({
	entityType,
	integrationId,
	integrationType,
	iconOverride,
	...props
}: IIntegrationLogo) {
	const { data: integration } = useIntegrationPrefetched({
		id: integrationId!,
		options: {
			enabled: !isNil(integrationId) && integrationId !== 'secoda',
		},
	});

	let width = '32px';
	let height = '32px';

	const { width: propsWidth, height: propsHeight, size: propsSize } = props;

	if (propsSize) {
		width = `${propsSize}px`;
		height = `${propsSize}px`;
	} else if (typeof propsWidth === 'number') {
		width = `${propsWidth}px`;
		height = `${propsWidth}px`;
	} else if (typeof propsHeight === 'number') {
		width = `${propsHeight}px`;
		height = `${propsHeight}px`;
	}

	const { icon: Icon } = getIconByEntityType(
		entityType,
		integration,
		integration?.type ?? integrationType
	);

	if (iconOverride) {
		if (iconOverride === 's3.svg') {
			return (
				<Image
					{...props}
					src={s3Svg}
					width={width}
					height={height}
					fit="contain"
				/>
			);
		}
	}

	return (
		<Icon {...(props as any)} width={width} height={height} fit="contain" />
	);
}

export default IntegrationLogo;
