import { Box, Checkbox, Divider, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
	isPreviewIntegration,
	isQueryBlockIntegration,
} from '@repo/common/constants/integration/integrations.utils';
import { UserRole } from '@repo/common/enums/UserRole';
import { Button, Text } from '@repo/foundations';
import { useMemo } from 'react';
import {
	IIntegration,
	useUpdateIntegration,
	useUserGroupList,
} from '../../api';
import { useExtendedUserList } from '../../api/hooks/user/useExtendedUserList';
import { IntegrationSpec } from '../../interfaces/IntegrationSpec';
import { Setting } from '../Settings/Setting';

interface IntegrationPermissionsProps {
	integration: IIntegration;
	spec: IntegrationSpec;
}

export function IntegrationPermissions({
	integration,
	spec,
}: IntegrationPermissionsProps) {
	const { activeUsers: usersData } = useExtendedUserList();
	const editors = useMemo(
		() => (usersData || []).filter((f) => f.role === UserRole.EDITOR),
		[usersData]
	);

	const { data: groupData } = useUserGroupList({});

	const supportsPreviews =
		spec.type === 'builtin' && isPreviewIntegration(spec.value);
	const supportsQueryBlock =
		spec.type === 'builtin' && isQueryBlockIntegration(spec.value);

	const { mutateAsync: updateIntegration } = useUpdateIntegration({});

	const form = useForm({
		initialValues: {
			admin_role: integration.preview_access_roles?.includes('admin'),
			editor_role: integration.preview_access_roles?.includes('editor'),
			viewer_role: integration.preview_access_roles?.includes('viewer'),
			...usersData
				?.map((u) => ({
					[`${u.id}_user`]: integration.execute_access?.includes(u.id),
				}))
				.reduce((acc, cur) => ({ ...acc, ...cur }), {}),
			...groupData?.results
				.map((g) => ({
					[`${g.id}_group`]: integration.preview_access_groups?.includes(g.id),
				}))
				.reduce((acc, cur) => ({ ...acc, ...cur }), {}),
		},
	}) as any;

	const submitForm = async (e: React.FormEvent) => {
		e.preventDefault();
		await updateIntegration({
			data: {
				id: integration.id,
				execute_access: Object.keys(form.values)
					.filter((f) => form.values[f] === true && f.includes('_user'))
					.map((key) => key.replace('_user', '')),
				preview_access_groups: Object.keys(form.values)
					.filter((f) => form.values[f] === true && f.includes('_group'))
					.map((key) => key.replace('_group', '')),
				preview_access_roles: Object.keys(form.values)
					.filter((f) => form.values[f] === true && f.includes('_role'))
					.map((key) => key.replace('_role', '')),
			},
		});

		showNotification({
			title: 'Updated integration permissions',
			message: null,
		});
	};

	if (!supportsPreviews && !supportsQueryBlock) {
		return (
			<Box>
				<Text>This Integration has no configurable permissions.</Text>
			</Box>
		);
	}

	return (
		<Box>
			<form onSubmit={submitForm} autoComplete="off">
				{supportsPreviews && (
					<>
						<Setting
							title="Previews"
							description="Enable previews for this integration to certain roles and groups"
						/>
						<Stack spacing={8}>
							<Checkbox
								mb={4}
								name="admin_role"
								label="Admins"
								checked={form.values.admin_role}
								{...form.getInputProps('admin_role')}
							/>
							<Checkbox
								mb={4}
								name="editor_role"
								label="Editors"
								checked={form.values.editor_role}
								{...form.getInputProps('editor_role')}
							/>
							<Checkbox
								mb={4}
								name="viewer_role"
								label="Viewers"
								checked={form.values.viewer_role}
								{...form.getInputProps('viewer_role')}
							/>
							{groupData?.results.map((g) => (
								<Checkbox
									mb={4}
									key={g.id}
									name={`${g.id}_group`}
									label={g.name}
									checked={form.values[`${g.id}_group`]}
									{...form.getInputProps(`${g.id}_group`)}
								/>
							))}
						</Stack>
						<Box mt={30} />
					</>
				)}
				{supportsQueryBlock && editors?.length > 0 && (
					<>
						<Divider />
						<Setting
							title="Queries"
							description="Enable querying data for this integration to certain editors"
						/>
						{editors.map((g) => (
							<Checkbox
								mb={4}
								key={g.id}
								name={`${g.id}_user`}
								label={g.email}
								checked={form.values[`${g.id}_user`]}
								{...form.getInputProps(`${g.id}_user`)}
							/>
						))}
					</>
				)}
				<Button type="submit" size="md" mt={10}>
					Submit
				</Button>
			</form>
		</Box>
	);
}
