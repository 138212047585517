import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { IUserGroup, useUserGroupList } from '../../../api';
import { TableV2 } from '../../../components/TableV2';
import { ExtendedDataTableColumn } from '../../../components/TableV2/types';
import { useColumns } from './GroupList.hooks';

function GroupList({
	defaultRequiredSearchParams,
}: {
	defaultRequiredSearchParams?: Record<string, any>;
}) {
	const columns = useColumns();

	const navigate = useNavigate();

	const handleClick = useCallback(
		(id: string) => {
			navigate(`/group/${id}`);
		},
		[navigate]
	);

	return (
		<TableV2<IUserGroup>
			pluralTypeString="groups"
			withCheckbox={false}
			withInteractiveHeader
			withSearch
			withFilters={false}
			defaultSort={null}
			withCsvExport
			columns={columns}
			usePaginationList={useUserGroupList}
			defaultRequiredSearchParams={defaultRequiredSearchParams}
			onCellClick={(cell: {
				column: ExtendedDataTableColumn<IUserGroup>;
				record: { id: string };
			}) => {
				if (cell.column.navigate !== false) {
					handleClick(cell.record.id);
				}
			}}
		/>
	);
}

export default GroupList;
