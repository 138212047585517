import { Box, createStyles } from '@mantine/core';
import { DataQualityScore } from '@repo/api-codegen';
import { HoverCard } from '@repo/common/components/HoverCard';
import { DataQualityClassification } from '@repo/common/enums/dataQualityClassification';
import { getScoreClassification } from '@repo/common/utils/dataQuality';
import { forwardRef, type ForwardedRef } from 'react';
import { DataQualityBadge } from './DataQualityBadge';
import { DataQualityDetailsCard } from './DataQualityDetailsCard';

const useStyles = createStyles(() => ({
	badgeWrapper: {
		cursor: 'pointer',
	},
}));

export const DataQualityBadgeWithHover = forwardRef(
	({ dqs }: { dqs?: DataQualityScore }, ref: ForwardedRef<HTMLDivElement>) => {
		const { classes } = useStyles();

		if (!dqs) {
			return (
				<DataQualityBadge
					ref={ref}
					classification={DataQualityClassification.UNAVAILABLE}
				/>
			);
		}

		return (
			<HoverCard
				target={
					<Box className={classes.badgeWrapper}>
						<DataQualityBadge
							classification={getScoreClassification(dqs.total)}
						/>
					</Box>
				}
			>
				<Box w={200}>
					<DataQualityDetailsCard data={dqs} ref={ref} />
				</Box>
			</HoverCard>
		);
	}
);
DataQualityBadgeWithHover.displayName = 'DataQualityBadgeWithHover';
