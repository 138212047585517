import { Box, Card, createStyles, Group, Skeleton, Stack } from '@mantine/core';
import { QueryChart } from '@repo/api-codegen';
import { Text, Title } from '@repo/foundations';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useRef } from 'react';
import { Chart } from '../../../components/Chart';
import { ChartTooltip } from '../../../components/Chart/components/ChartTooltip.tsx';

interface IQueryGraphProps {
	data: QueryChart[] | undefined | null;
	field: 'average_runtime' | 'total_runtime' | 'total_runs';
	title: string;
	metric: number | undefined | null;
	units?: string;
}

interface GraphChartTooltipProps {
	x: number;
	y: number;
	field: string;
	time: Date;
	value: number | string | null | undefined | Date;
	units?: string;
}

const useStyles = createStyles(() => ({
	badge: {
		width: '8px',
		height: '8px',
		borderRadius: '50%',
		backgroundColor: '#46BDD2',
	},
}));

export function GraphChartTooltip({
	x,
	y,
	field,
	time,
	value,
	units,
}: GraphChartTooltipProps) {
	const { classes } = useStyles();

	const valueWithUnits = () => {
		if (typeof value === 'number') {
			const isInteger = Number.isInteger(value);
			if (isInteger) {
				return `${value} ${units ?? ''}`;
			}

			return `${value.toFixed(2)} ${units ?? ''}`;
		}
		if (value instanceof Date) {
			return dayjs(value).format('MMM D, YYYY');
		}
		return value;
	};

	return (
		<ChartTooltip x={x} y={y} p="sm">
			<Stack spacing="xs">
				<Text size="sm" color="text/secondary/default">
					{dayjs(time).format('MMM D, YYYY')}
				</Text>
				<Group position="apart">
					<Group spacing="xs">
						<Box className={classes.badge} />
						<Text size="sm" color="text/secondary/default">
							{field}
						</Text>
					</Group>
					<Text size="sm" color="text/primary/default">
						{valueWithUnits()}
					</Text>
				</Group>
			</Stack>
		</ChartTooltip>
	);
}

function QueryGraph({ data, field, title, metric, units }: IQueryGraphProps) {
	const preparedData = (data || []).map((d) => ({
		[field]: d[field],
		time: new Date(d.time ?? ''),
		series: field,
	}));
	const chartRef = useRef<HTMLDivElement>(null);

	const renderTooltip = ({
		x,
		y,
		dataIndex,
	}: {
		x: number;
		y: number;
		dataIndex: number[] | null | undefined;
	}) => {
		if (isNil(dataIndex) || !preparedData[dataIndex[0]]) {
			return null;
		}

		const { time } = preparedData[dataIndex[0]];
		const value = preparedData[dataIndex[0]][field];

		return (
			<GraphChartTooltip
				field={title}
				time={time}
				value={value}
				x={x}
				y={y}
				units={units}
			/>
		);
	};

	const roundedMetric = () => {
		const validMetric = metric || 0;

		if (units === 'ms') {
			if (validMetric < 1000) {
				return `${Math.round(validMetric * 100) / 100} ${units}`;
			} else if (validMetric < 60000) {
				return `${Math.round(validMetric / 1000)} s`;
			} else if (validMetric < 3600000) {
				return `${Math.round(validMetric / 60000)} m`;
			}
			return `${Math.round(validMetric / 3600000)} h`;
		}

		if (units) {
			return `${Math.round(validMetric * 100) / 100} ${units}`;
		}

		return `${Math.round(validMetric * 100) / 100}`;
	};

	return (
		<Card radius="md" withBorder ref={chartRef}>
			<Stack>
				{!!metric && <Title size="sm">{title}</Title>}
				{!!metric && <Title size="lg">{roundedMetric()}</Title>}

				{!metric && <Skeleton height={20} width={100} radius="lg" />}
				{!metric && <Skeleton height={30} width={30} radius="lg" />}

				{!!data && (
					<Chart
						results={preparedData}
						primary={field}
						dimension="time"
						hideLegend
						lineColor="#46BDD2"
						axisFontSize={12}
						strokeWidth={4}
						tooltipRenderer={renderTooltip}
					/>
				)}
				{!data && <Skeleton height={200} radius="lg" />}
			</Stack>
		</Card>
	);
}

export default QueryGraph;
