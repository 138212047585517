import type { LiteQueryOut, QueryListOut } from '@repo/api-codegen';
import { useApiGetCreationQuery } from '@repo/api-codegen';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import type { ISecodaEntity } from '../../api';
import type { EntityTabsStore } from '../../pages/TableEntityPage/TableEntityTabs/TableEntityTabsStore';
import { TableV2 } from '../TableV2';
import { useActions, useColumns, useEntityQueryList } from './hooks';

export interface IQueryListProps {
	entity: ISecodaEntity;
	store: EntityTabsStore;
	render?: boolean;
}

export function QueryList({ entity, store }: IQueryListProps) {
	const entityQueryList = useEntityQueryList(
		entity?.id,
		(data: QueryListOut) => {
			store.setQueryCount?.(data.count);
		}
	);

	const columns = useColumns();
	const actions = useActions();
	const navigate = useNavigate();

	useApiGetCreationQuery(
		{
			pathParams: {
				entityId: entity.id,
			},
		},
		{
			onSuccess: (data: LiteQueryOut) => {
				store.setCreationQuery?.(data);
			},
		}
	);

	const handleClick = (id: string) => {
		navigate(`/query/${id}`);
	};

	return (
		<TableV2
			pluralTypeString="queries"
			// @ts-ignore
			columns={columns}
			// @ts-ignore
			usePaginationList={entityQueryList}
			withActions={actions}
			withCheckbox={false}
			withInteractiveHeader
			withSearch
			withFilters={false}
			defaultSort={null}
			onCellClick={(cell) => {
				handleClick(cell.record.id!);
			}}
		/>
	);
}
