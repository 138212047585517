import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import { MetricNumericFormat } from '../../api';
import { authHeaders } from '../../api/common';
import type { QueryResults } from '../types';
import { SecodaEntity } from './entity';

export class Metric extends SecodaEntity {
	static CLASS_NAME = 'Metric';

	hidden: boolean | undefined;

	scheduled_delta: string | undefined;

	last_error: any | undefined;

	last_run: any | undefined;

	last_status: any | undefined;

	// @ts-expect-error TS(2564): Property 'integration' has no initializer and is n... Remove this comment to see the full error message
	integration: string;

	// @ts-expect-error TS(2564): Property 'sql' has no initializer and is not defin... Remove this comment to see the full error message
	sql: string;

	// @ts-expect-error TS(2564): Property 'results' has no initializer and is not d... Remove this comment to see the full error message
	results: QueryResults;

	primary: string | undefined;

	time: string | undefined;

	numeric_format: MetricNumericFormat | undefined;

	dimension: string | undefined;

	chart_config: any;

	// @ts-expect-error TS(2564)
	teams: string[];

	constructor(obj: Partial<Metric>) {
		// @ts-expect-error TS(2345): Argument of type 'Partial<Metric>' is not ... Remove this comment to see the full error message
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get namespace() {
		return ['metric', 'metrics'];
	}

	static get fields() {
		return SecodaEntity.fields.concat([
			'integration',
			'sql',
			'dimension',
			'results',
			'chart_config',
			'teams',
			'primary',
			'time',
		]);
	}

	async execute() {
		return axios.post(`${this.apiPath}execute/`, {}, authHeaders());
	}
}
