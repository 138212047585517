import {
	Box,
	createStyles,
	FocusTrap,
	getDefaultZIndex,
	Group,
	OptionalPortal,
	Stack,
	Tooltip,
} from '@mantine/core';
import {
	getHotkeyHandler,
	useClickOutside,
	useDisclosure,
} from '@mantine/hooks';
import { KeyboardShortcut } from '@repo/common/components/KeyboardShortcut/KeyboardShortcut';
import { Icon } from '@repo/foundations';
import { EditorDictionary } from '@repo/secoda-editor';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RichMarkdownEditor } from '..';
import { MOD_KEY, SHIFT_KEY } from '../utils/keyboard';
import Input from './Input';
import ToolbarButton from './ToolbarButton';
import Separator from './ToolbarSeparator';

const useStyles = createStyles((theme, { right }: { right: number }) => ({
	wrapper: {
		padding: theme.spacing['3xs'],
		zIndex: getDefaultZIndex('modal'),
		backgroundColor: theme.other.getColor('surface/primary/default'),
		border: `${theme.other.borderWidth.xs}px solid ${theme.other.getColor('border/primary/default')}`,
		borderRadius: theme.other.borderRadius.md,
		boxShadow: theme.shadows.md,
		position: 'fixed',
		top: 100,
		left: right - 350,

		'@media print': {
			display: 'none',
		},
	},
}));

export interface FindAndReplaceProps {
	editor: RichMarkdownEditor;
	dictionary: EditorDictionary;
	readOnly: boolean;
	replaceOpenInitialValue?: boolean;
	onClose: () => void;
	withinPortal?: boolean;
}

export function FindAndReplace({
	editor,
	dictionary,
	readOnly,
	onClose,
	withinPortal = true,
	replaceOpenInitialValue = false,
}: FindAndReplaceProps) {
	const [searchTerm, setSearchTerm] = useState('');
	const [replaceTerm, setReplaceTerm] = useState('');
	const [caseSensitive, setCaseSensitive] = useState(false);
	const [regexEnabled, setRegexEnabled] = useState(false);
	const [isReplaceOpen, { toggle: toggleReplaceOpen }] = useDisclosure(
		replaceOpenInitialValue
	);
	const editorRef = useRef<HTMLElement>(editor.view.dom);

	const parentPosition = useMemo(
		() => editorRef.current?.getBoundingClientRect(),
		[editorRef]
	);

	const { classes } = useStyles({
		right: parentPosition?.right ?? 0,
	});

	const doSearch = useCallback(
		(_searchTerm: string, _caseSensitive: boolean, _regexEnabled: boolean) => {
			editor.commands.find({
				text: _searchTerm,
				caseSensitive: _caseSensitive,
				regexEnabled: _regexEnabled,
			});
		},
		[editor]
	);

	const handleSearchTermOnChange = useCallback(
		(value: string) => {
			setSearchTerm(value);
			doSearch(value, caseSensitive, regexEnabled);
		},
		[caseSensitive, doSearch, regexEnabled]
	);

	useEffect(() => {
		if (editor.view.state.selection.from < editor.view.state.selection.to) {
			const slice = editor.view.state.doc.cut(
				editor.view.state.selection.from,
				editor.view.state.selection.to
			);
			if (!!slice.textContent) {
				handleSearchTermOnChange(slice.textContent);
			}
		}
	}, [editor, handleSearchTermOnChange]);

	const handleCaseSensitiveToggle = useCallback(() => {
		setCaseSensitive((state) => {
			const newState = !state;
			doSearch(searchTerm, newState, regexEnabled);
			return newState;
		});
	}, [doSearch, searchTerm, regexEnabled]);

	const handleRegexEnabledToggle = useCallback(() => {
		setRegexEnabled((state) => {
			const newState = !state;
			doSearch(searchTerm, caseSensitive, newState);
			return newState;
		});
	}, [caseSensitive, doSearch, searchTerm]);

	const handleSearchNext = useCallback(() => {
		editor.commands.nextSearchMatch();
	}, [editor]);

	const handleSearchPrev = useCallback(() => {
		editor.commands.prevSearchMatch();
	}, [editor]);

	const handleOnClose = useCallback(() => {
		editor.commands.clearSearch();
		onClose();
		editor.view.focus();
	}, [editor, onClose]);

	const handleReplace = useCallback(() => {
		if (readOnly) {
			return;
		}
		editor.commands.replace({ text: replaceTerm });
	}, [editor, readOnly, replaceTerm]);

	const handleReplaceAll = useCallback(() => {
		if (readOnly) {
			return;
		}
		editor.commands.replaceAll({ text: replaceTerm });
	}, [editor, readOnly, replaceTerm]);

	const ref = useClickOutside(handleOnClose);

	return (
		<OptionalPortal withinPortal={withinPortal}>
			<FocusTrap active>
				<Box className={classes.wrapper} ref={ref}>
					<Group spacing="3xs" noWrap align="flex-start">
						{!readOnly && (
							<Tooltip label={dictionary.toggleReplace} position="top">
								<ToolbarButton
									onClick={() => toggleReplaceOpen()}
									data-active={isReplaceOpen ? true : undefined}
								>
									<Icon name={isReplaceOpen ? 'chevronDown' : 'chevronRight'} />
								</ToolbarButton>
							</Tooltip>
						)}
						<Stack spacing="3xs">
							<Group spacing="3xs" noWrap>
								<Input
									data-autofocus
									value={searchTerm}
									onChange={(e) =>
										handleSearchTermOnChange(e.currentTarget.value)
									}
									placeholder={dictionary.find}
									onKeyDown={getHotkeyHandler([
										['Enter', handleSearchNext],
										['Shift+Enter', handleSearchPrev],
										['Escape', handleOnClose],
									])}
									width="150px"
									padding={readOnly ? undefined : '2xs'}
								/>
								<Separator />
								<Tooltip label={dictionary.matchCase} position="top">
									<ToolbarButton
										onClick={() => handleCaseSensitiveToggle()}
										data-active={caseSensitive ? true : undefined}
									>
										<Icon name="letterCase" />
									</ToolbarButton>
								</Tooltip>
								<Tooltip label={dictionary.enableRegex} position="top">
									<ToolbarButton
										onClick={() => handleRegexEnabledToggle()}
										data-active={regexEnabled ? true : undefined}
									>
										<Icon name="regex" />
									</ToolbarButton>
								</Tooltip>
								<Tooltip
									label={
										<KeyboardShortcut keys={[SHIFT_KEY, 'Enter']}>
											{dictionary.previous}
										</KeyboardShortcut>
									}
									position="top"
								>
									<ToolbarButton onClick={() => handleSearchPrev()}>
										<Icon name="arrowUp" />
									</ToolbarButton>
								</Tooltip>
								<Tooltip
									label={
										<KeyboardShortcut keys={['Enter']}>
											{dictionary.next}
										</KeyboardShortcut>
									}
									position="top"
								>
									<ToolbarButton onClick={() => handleSearchNext()}>
										<Icon name="arrowDown" />
									</ToolbarButton>
								</Tooltip>
								<Tooltip
									label={
										<KeyboardShortcut keys={['Esc']}>
											{dictionary.close}
										</KeyboardShortcut>
									}
									position="top"
								>
									<ToolbarButton onClick={() => handleOnClose()}>
										<Icon name="x" />
									</ToolbarButton>
								</Tooltip>
							</Group>
							{!readOnly && isReplaceOpen && (
								<Group spacing="3xs">
									<Input
										value={replaceTerm}
										onChange={(e) => setReplaceTerm(e.currentTarget.value)}
										placeholder={dictionary.replace}
										onKeyDown={getHotkeyHandler([
											['Enter', handleReplace],
											['Mod+Enter', handleReplaceAll],
											['Escape', () => toggleReplaceOpen()],
										])}
										width="150px"
										padding="2xs"
									/>
									<Separator />
									<Tooltip
										label={
											<KeyboardShortcut keys={['Enter']}>
												{dictionary.replace}
											</KeyboardShortcut>
										}
										position="top"
									>
										<ToolbarButton onClick={() => handleReplace()}>
											{dictionary.replace}
										</ToolbarButton>
									</Tooltip>
									<Tooltip
										label={
											<KeyboardShortcut keys={[MOD_KEY, 'Enter']}>
												{dictionary.replaceAll}
											</KeyboardShortcut>
										}
										position="top"
									>
										<ToolbarButton onClick={() => handleReplaceAll()}>
											{dictionary.replaceAll}
										</ToolbarButton>
									</Tooltip>
								</Group>
							)}
						</Stack>
					</Group>
				</Box>
			</FocusTrap>
		</OptionalPortal>
	);
}
