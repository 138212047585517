import { createStyles, ScrollArea, Stack, Transition } from '@mantine/core';
import { Banner } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useAIConversation, useAuthUser } from '../../api';
import { useFeatureFlags } from '../../utils/featureFlags';
import { DOCUMENTATION_WIDTH } from '../Documentation/constants';
import { AIChatConversation } from './AIChatConversation';
import AIChatEmpty from './AIChatEmpty';
import { AIPromptInput } from './AIPromptInput';
import { useAIAssistantContext } from './context';
import { AIAssistantMode } from './types';

const useStyles = createStyles(
	(theme, { isSidebar }: { isSidebar: boolean }) => ({
		wrapper: {
			width: '100%',
			height: '100%',
			paddingBottom: !isSidebar ? theme.spacing.md : undefined,
			gap: 0,
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden',
		},
		scrollAreaRoot: {
			width: '100%',
			flex: '1 1 0',
			minHeight: 0,
			overflow: 'hidden',
		},
		scrollAreaViewport: {
			width: '100%',
			height: '100%',
			'>div': {
				width: '100%',
				height: '100%',
			},
		},
		scrollInnerContent: {
			height: '100%',
		},
		scrollInnerContainer: {
			width: isSidebar
				? '100%'
				: `calc(${DOCUMENTATION_WIDTH}px + (2 * 1.5rem))`,
			margin: '0 auto',
			paddingLeft: isSidebar ? 0 : theme.spacing.xl,
			paddingRight: isSidebar ? 0 : theme.spacing.xl,
		},
		banner: {
			paddingBottom: theme.spacing.md,
		},
	})
);

export const AIChat = observer(() => {
	const store = useAIAssistantContext();
	const { aiToQuestionsAnswers } = useFeatureFlags();
	const [showFeedbackBanner, setShowFeedbackBanner] = useState(false);

	const handleNegativeFeedback = useCallback(() => {
		setShowFeedbackBanner(true);
	}, []);

	const { classes, cx } = useStyles({
		isSidebar: store.mode === AIAssistantMode.SIDEBAR,
	});

	const { user } = useAuthUser();

	const {
		isLoading,
		submitPrompt,
		submit,
		stop,
		inputPrompt,
		setInputPrompt,
		isWaitingAI,
		messages,
		viewportRef,
		rerenderKey,
		retryPrompt,
		onAttachment,
		onAttachmentRemove,
	} = useAIConversation({ id: store.id });

	const isEmpty = store.id === 'new';
	const showInput =
		isEmpty ||
		(messages && messages.length >= 1 && user?.id === messages[0]?.user_id);

	return (
		<Stack className={classes.wrapper}>
			<ScrollArea
				viewportRef={viewportRef}
				classNames={{
					root: classes.scrollAreaRoot,
					viewport: classes.scrollAreaViewport,
				}}
				type="scroll"
			>
				<div
					className={cx(
						classes.scrollInnerContainer,
						classes.scrollInnerContent
					)}
				>
					{isEmpty ? (
						<AIChatEmpty onPromptChange={submitPrompt} />
					) : (
						<AIChatConversation
							isLoading={isLoading}
							messages={messages}
							scrollAreaRef={viewportRef}
							onRetry={retryPrompt}
							onNegativeFeedback={handleNegativeFeedback}
						/>
					)}
				</div>
			</ScrollArea>
			<div className={classes.scrollInnerContainer}>
				<Transition
					mounted={showFeedbackBanner && aiToQuestionsAnswers}
					transition="slide-up"
					duration={100}
					exitDuration={0}
				>
					{(styles) => (
						<div className={classes.banner} style={styles}>
							<Banner
								tone="info"
								message="If Secoda AI isn't providing the answer you need, you can forward your question to your team. Just select the question icon on a message to get started."
								title="Not getting the answer you're looking for?"
								header
								onDismiss={() => setShowFeedbackBanner(false)}
							/>
						</div>
					)}
				</Transition>
				{showInput && (
					<AIPromptInput
						key={rerenderKey}
						value={inputPrompt}
						onChange={setInputPrompt}
						onSubmit={submit}
						isLoading={isWaitingAI}
						onStop={stop}
						onAttachment={onAttachment}
						onAttachmentRemove={onAttachmentRemove}
					/>
				)}
			</div>
		</Stack>
	);
});
