import EntitySidebarContent, {
	EntitySidebarContentProps,
} from './EntitySidebarContent';

export interface EntityPageSidebarProps extends EntitySidebarContentProps {}

function EntityPageSidebar({ ...props }: EntityPageSidebarProps) {
	return <EntitySidebarContent {...props} />;
}

export default EntityPageSidebar;
