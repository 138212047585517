import {
	Automation,
	AutomationCondition,
	AutomationField,
	AutomationFilterOperator,
	AutomationFilterSet,
} from '../../../../../api';
import { AUTOMATION_FIELD_OPTIONS } from './constants';

export const getFilterSetForOperatorUpdate = (
	option: AutomationFilterOperator,
	automation: Automation,
	newFilterSetKey: string,
	filterSetSearchKey?: string
): AutomationFilterSet[] => {
	let filterSets: AutomationFilterSet[];

	if (
		automation.filter_config?.filter_sets &&
		automation.filter_config.filter_sets.length > 0
	) {
		/**
		 * if filter sets already exist in the db, there are two scenario to consider:
		 * 1. The filter set was created when the user created the automation from v1 OR
		 * 2. The filter set was created with automation v2
		 *
		 * If the filter set has a length of 1 and there is no key on the filter set object,
		 * it is from v1, otherwise assume it is from v2
		 */

		const existingFilterSet = filterSetSearchKey
			? automation.filter_config?.filter_sets.filter(
					(set) => set.key === filterSetSearchKey
				)
			: [];

		// handle the case for v1 automation by setting the key on the filterSet and updating the operator and filters
		if (
			existingFilterSet.length === 0 &&
			automation.filter_config.filter_sets.length === 1 &&
			!automation.filter_config.filter_sets[0].key
		) {
			filterSets = [
				{
					operator: option,
					key: filterSetSearchKey ?? newFilterSetKey,
					filters: automation.filter_config.filter_sets[0].filters,
				},
			];
		} else if (existingFilterSet.length === 0) {
			// handle the case for v2 automation where a new filter set has been created
			filterSets = [
				...automation.filter_config.filter_sets,
				{
					operator: option,
					key: filterSetSearchKey ?? newFilterSetKey,
					filters: [],
				},
			];
		} else {
			// handle the case for v2 automation where an existing filter set had their filters updated
			filterSets = [
				...automation.filter_config.filter_sets.map((set) => {
					if (set.key === filterSetSearchKey) {
						return {
							...set,
							operator: option,
						};
					}

					return set;
				}),
			];
		}

		// throw an error if filter set cannot be determined
		if (filterSets.length === 0) {
			throw new Error(
				`Invalid state for filter set update for automation: ${automation.id}`
			);
		}
	} else {
		/**
		 * If there are no filter sets on the db, that means this is the first
		 * filter set the user is adding.
		 * Create the a new filterSet arr with one object inside
		 */
		filterSets = [
			{
				operator: option,
				key: filterSetSearchKey ?? newFilterSetKey,
				filters: [],
			},
		];
	}

	return filterSets;
};

export const getFilterFieldDisplayValue = (
	filterField?: AutomationField
): string | undefined => {
	const label = AUTOMATION_FIELD_OPTIONS.find(
		(option) => option.value === filterField
	)?.label;

	return label;
};

export const getFilterConditionOptions = (
	filterField: AutomationField
): AutomationCondition[] => {
	if (
		[
			AutomationField.TITLE,
			AutomationField.TITLE_FULL,
			AutomationField.DEFINITION,
			AutomationField.DESCRIPTION,
			AutomationField.DATABASE,
			AutomationField.SCHEMA,
			AutomationField.TABLE,
		].includes(filterField)
	) {
		return [
			AutomationCondition.IS,
			AutomationCondition.IS_NOT,
			AutomationCondition.CONTAINS,
			AutomationCondition.DOES_NOT_CONTAIN,
			AutomationCondition.IS_SET,
			AutomationCondition.IS_NOT_SET,
			AutomationCondition.STARTS_WITH,
			AutomationCondition.ENDS_WITH,
		];
	}

	if (
		[
			AutomationField.COLLECTIONS,
			AutomationField.OWNERS,
			AutomationField.SOURCES,
			AutomationField.SLACK_CHANNELS,
			AutomationField.TEAMS,
			AutomationField.TAGS,
		].includes(filterField)
	) {
		return [
			AutomationCondition.CONTAINS,
			AutomationCondition.DOES_NOT_CONTAIN,
			AutomationCondition.IS_SET,
			AutomationCondition.IS_NOT_SET,
		];
	}

	if (
		[
			AutomationField.PII,
			AutomationField.VERIFIED,
			AutomationField.PUBLISHED,
			AutomationField.QUALITY,
			AutomationField.QUALITY_ACCURACY,
			AutomationField.QUALITY_RELIABILITY,
			AutomationField.QUALITY_STEWARDSHIP,
			AutomationField.QUALITY_USABILITY,
		].includes(filterField)
	) {
		return [AutomationCondition.IS, AutomationCondition.IS_NOT];
	}

	if (
		[AutomationField.NATIVE_TYPE, AutomationField.INTEGRATION_ID].includes(
			filterField
		)
	) {
		return [
			AutomationCondition.IS,
			AutomationCondition.IS_NOT,
			AutomationCondition.IS_SET,
			AutomationCondition.IS_NOT_SET,
		];
	}

	if (
		[
			AutomationField.CREATED_AT,
			AutomationField.EXTERNAL_UPDATED_AT,
			AutomationField.INTERNAL_USAGE,
			AutomationField.EXTERNAL_USAGE,
		].includes(filterField)
	) {
		return [AutomationCondition.GTE, AutomationCondition.LTE];
	}

	throw new Error('Unsupport filter field has been provided');
};
