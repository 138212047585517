import { useGetWorkspaceFeatureAccess } from '@repo/api-codegen';
import { useWorkspace } from '../workspace';

export function useFeatureAccess() {
	const { workspace } = useWorkspace();

	const { data, isLoading } = useGetWorkspaceFeatureAccess(
		{},
		{
			suspense: false,
			enabled: !!workspace.id,
		}
	);

	return {
		monitoringAccess: data?.monitoring_access,
		automationsAccess: data?.automations_access,
		aiAccess: data?.ai_access,
		apiAccess: data?.api_access,
		analyticsAccess: data?.analytics_access,
		samlAccess: data?.saml_access,
		tunnelAccess: data?.tunnel_access,
		groupAccess: data?.group_access,
		dqsAccess: data?.dqs_access,
		isLoading,
	};
}
