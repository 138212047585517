import { Group, Skeleton, Stack, createStyles } from '@mantine/core';
import { Button, IconButton, Text, Title } from '@repo/foundations';
import {
	Incident,
	useIncidentStatusUpdate,
	useMeasurementsChartData,
	useMonitor,
} from '../../../../api';
import { useFeatureFlags } from '../../../../utils/featureFlags';
import { isAutomaticMonitor, metricTypeLabel } from '../../utils';
import MonitorTimeseriesChart from '../MonitorTimeseriesChart';

interface IncidentMeasurementChartProps {
	incident: Incident;
}

const useStyles = createStyles(() => ({
	title: {
		flexGrow: 1,
	},
}));

function IncidentMeasurementChart({ incident }: IncidentMeasurementChartProps) {
	const { data: measurements } = useMeasurementsChartData({
		incidentId: incident.id,
	});
	const { data: monitor } = useMonitor({
		id: incident.monitor,
		options: {
			enabled: incident && Boolean(incident.id),
		},
	});
	const { monitorAnnotations } = useFeatureFlags();

	const shouldShowMarkAsNormal =
		isAutomaticMonitor(monitor) &&
		!incident.marked_normal_at &&
		monitorAnnotations;
	const { mutateAsync: updateIncident } = useIncidentStatusUpdate();

	const { classes, theme } = useStyles();

	if (!monitor || !measurements || measurements.length === 0) {
		// Return null or some fallback UI if conditions are not met
		return <Skeleton height={400} width="100%" />;
	}

	const handleMarkAsNormalClicked = () => {
		updateIncident({
			data: {
				id: incident.id,
				monitorId: incident.monitor,
				status: 'normal',
			},
		});
	};
	return (
		<Stack spacing={0}>
			<Group spacing="xs">
				<Title size="md" pb="md" className={classes.title}>
					{metricTypeLabel(monitor.metric_type)}
				</Title>
				{(shouldShowMarkAsNormal || incident.marked_normal_at) && (
					<IconButton
						iconName="infoCircle"
						tooltip="Do these data points look correct? Mark them as normal to include the latest and similar ones in future models."
						variant="tertiary"
						tooltipProps={{
							withinPortal: true,
							w: theme.other.space[90],
							multiline: true,
						}}
					/>
				)}
				{shouldShowMarkAsNormal && (
					<Button variant="default" onClick={() => handleMarkAsNormalClicked()}>
						Mark as Normal
					</Button>
				)}

				{incident.marked_normal_at && (
					<Text color="text/secondary/default" size="sm">
						Marked as normal
					</Text>
				)}
			</Group>
			<MonitorTimeseriesChart
				data={measurements}
				metricType={monitor.metric_type}
				configuredLowerThreshold={monitor.condition_manual_min ?? undefined}
				configuredUpperThreshold={monitor.condition_manual_max ?? undefined}
				width="100%"
			/>
		</Stack>
	);
}

export default IncidentMeasurementChart;
