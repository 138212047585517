import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import { ParseSpec } from 'prosemirror-markdown';
import type { NodeSpec, Node as ProsemirrorNode } from 'prosemirror-model';
import {
	liftListItem,
	sinkListItem,
	splitListItem,
} from 'prosemirror-schema-list';
import checkboxRule from '../rules/checkboxes';
import Node, { NodeOptions } from './Node';

export default class CheckboxItem extends Node {
	get name() {
		return 'checkbox_item';
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				checked: {
					default: false,
				},
			},
			content: 'paragraph block*',
			defining: true,
			draggable: true,
			parseDOM: [
				{
					tag: `li[data-type="${this.name}"]`,
					getAttrs: (dom: HTMLElement | string) =>
						typeof dom === 'string'
							? null
							: {
									checked: dom.className.includes('checked'),
								},
				},
			],
			toDOM: (node) => {
				const input = document.createElement('input');
				input.type = 'checkbox';
				input.tabIndex = -1;
				input.addEventListener('change', this.handleChange);

				if (node.attrs.checked) {
					input.checked = true;
				}

				return [
					'li',
					{
						'data-type': this.name,
						class: node.attrs.checked ? 'checked' : undefined,
					},
					[
						'span',
						{
							contentEditable: false,
						},
						input,
					],
					['div', 0],
				];
			},
		};
	}

	get rulePlugins() {
		return [checkboxRule];
	}

	handleChange = (event: Event) => {
		const target = event.target as HTMLInputElement;
		const { view } = this.editorState;
		const { tr } = view.state;
		const { top, left } = target.getBoundingClientRect();
		const result = view.posAtCoords({ top, left });

		if (result) {
			const transaction = tr.setNodeMarkup(result.inside, undefined, {
				checked: target.checked,
			});
			view.dispatch(transaction);
		}
	};

	keys({ type }: NodeOptions) {
		return {
			Enter: splitListItem(type),
			Tab: sinkListItem(type),
			'Shift-Tab': liftListItem(type),
			'Mod-]': sinkListItem(type),
			'Mod-[': liftListItem(type),
		};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		state.write(node.attrs.checked ? '[x] ' : '[ ] ');
		state.renderContent(node);
	}

	parseMarkdown(): ParseSpec {
		return {
			block: 'checkbox_item',
			getAttrs: (tok) => ({
				checked: tok.attrGet('checked') ? true : undefined,
			}),
		};
	}
}
