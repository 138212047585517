import {
	Box,
	createStyles,
	getDefaultZIndex,
	getStylesRef,
} from '@mantine/core';
import { useDisclosure, useElementSize } from '@mantine/hooks';
import { Button } from '@repo/foundations';
import { ColorNames } from '@repo/theme/utils';
import { ReactNode, useLayoutEffect, useState } from 'react';

const useStyles = createStyles(
	(
		theme,
		{
			opened,
			maxHeight,
			color = 'surface/app/default',
		}: { opened: boolean; maxHeight: number; color?: ColorNames }
	) => ({
		wrapper: {
			position: 'relative',
			...(!opened
				? {
						maxHeight,
						overflow: 'hidden',
					}
				: {}),

			[`&:hover .${getStylesRef('button')}`]: {
				opacity: 1,
			},
		},
		overlay: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'end',
			pointerEvents: 'none',

			'&::after': !opened
				? {
						position: 'absolute',
						display: 'block',
						content: '""',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						background: `linear-gradient(transparent 60%, ${theme.other.getColor(color)} 100%);`,
						cursor: 'pointer',
						zIndex: getDefaultZIndex('app') + 1,
						pointerEvents: 'initial',
					}
				: {},
		},
		button: {
			ref: getStylesRef('button'),
			position: 'absolute',
			bottom: theme.spacing.md,
			zIndex: getDefaultZIndex('app') + 2,
			opacity: !opened ? 1 : 0,
			pointerEvents: 'initial',
			transition: 'all 100ms ease',
		},
	})
);

interface ExpandCollapseProps {
	maxHeight: number;
	children: ReactNode;
	initialOpen?: boolean;
	enabled?: boolean;
	color?: ColorNames;
	classNames?: {
		wrapper?: string;
		overlay?: string;
		button?: string;
	};
}

export function ExpandCollapse({
	maxHeight,
	children,
	initialOpen = false,
	enabled = true,
	color,
	classNames,
}: ExpandCollapseProps) {
	const [loaded, setLoaded] = useState(false);
	const [opened, { toggle, open }] = useDisclosure(initialOpen);
	const { cx, classes } = useStyles({
		opened: opened || !enabled,
		maxHeight,
		color,
	});
	const { ref, height } = useElementSize();

	useLayoutEffect(() => {
		if (!enabled || loaded || height === 0) {
			// not ready yet
			return;
		}

		if (height < maxHeight && !opened) {
			open();
		}

		setLoaded(true);
	}, [height, maxHeight, open, loaded, setLoaded, opened, enabled]);

	return (
		<Box className={cx(classes.wrapper, classNames?.wrapper)}>
			<Box ref={ref}>{children}</Box>
			{enabled && (!opened || height > maxHeight) && (
				<Box
					className={cx(classes.overlay, classNames?.overlay)}
					onClick={!opened ? toggle : undefined}
				>
					<Button
						className={cx(classes.button, classNames?.button)}
						rightIconName={opened ? 'chevronUp' : 'chevronDown'}
						onClick={toggle}
					>
						{opened ? 'Show less' : 'Show more'}
					</Button>
				</Box>
			)}
		</Box>
	);
}
