import {
	createStyles,
	// eslint-disable-next-line no-restricted-imports
	Badge as MantineBadge,
	useMantineTheme,
} from '@mantine/core';
import { ColorNames } from '@repo/theme/utils';
import { ForwardedRef, forwardRef } from 'react';

import type { BadgeProps as MantineBadgeProps } from '@mantine/core';

export type BadgeVariant =
	| 'success'
	| 'info'
	| 'default'
	| 'attention'
	| 'warning'
	| 'critical'
	| 'inverse'
	| 'ai'
	| 'transparent';

interface SecodaBadgeProps {
	children?: React.ReactNode;
	color?: ColorNames;
	variant?: BadgeVariant;
	backgroundColor?: ColorNames;
}

export type BadgeProps = SecodaBadgeProps &
	Omit<MantineBadgeProps, keyof SecodaBadgeProps>;

const useStyles = createStyles(
	(
		theme,
		{
			backgroundColor,
			color,
		}: {
			color: string;
			backgroundColor: string;
		}
	) => ({
		root: {
			background: backgroundColor,
			fontSize: theme.fontSizes.xs,
			height: theme.spacing.lg,
			borderRadius: theme.radius.sm,
			lineHeight: theme.other.typography.lineHeight.text.lg,
			marginTop: 0,
			marginBottom: 0,
			paddingTop: theme.spacing['4xs'],
			paddingRight: theme.spacing['2xs'],
			paddingBottom: theme.spacing['4xs'],
			paddingLeft: theme.spacing['2xs'],
			fontWeight: theme.other.typography.weight.regular,
			textTransform: 'capitalize',
		},
		inner: {
			color: color,
			display: 'flex',
			flexDirection: 'row',
		},
		leftSection: {
			// Negative marginLeft compensate for the padding of icons
			marginLeft: -theme.other.space[1],
			// Custom marginRight to follow the design specs for gap between icon and text
			marginRight: theme.other.space[0.5],
		},
	})
);

export const Badge = forwardRef(
	(
		{
			children,
			variant,
			color = 'text/primary/default',
			backgroundColor = 'fill/secondary/default',
			...others
		}: BadgeProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		switch (variant) {
			case undefined:
				break;
			case 'success':
				color = 'text/success/default';
				backgroundColor = 'fill/success-secondary/default';
				break;
			case 'info':
				color = 'text/info/default';
				backgroundColor = 'fill/info-secondary/default';
				break;
			case 'default':
				color = 'text/primary/default';
				backgroundColor = 'fill/secondary/default';
				break;
			case 'attention':
				color = 'text/caution/default';
				backgroundColor = 'fill/caution-secondary/default';
				break;
			case 'warning':
				color = 'text/warning/default';
				backgroundColor = 'fill/warning-secondary/default';
				break;
			case 'critical':
				color = 'text/critical/default';
				backgroundColor = 'fill/critical-secondary/default';
				break;
			case 'inverse':
				color = 'text/inverse/default';
				backgroundColor = 'fill/inverse/default';
				break;
			case 'ai':
				color = 'text/ai/default';
				backgroundColor = 'fill/ai-secondary/default';
				break;
			case 'transparent':
				color = 'text/info/default';
				backgroundColor = 'fill/transparent/default';
		}

		// Temporary check for potentially badly typed colors
		let safeColor = '';
		let safeBackgroundColor = '';
		const theme = useMantineTheme();

		try {
			safeColor = theme.other.getColor(color);
			safeBackgroundColor = theme.other.getColor(backgroundColor);
		} catch (error) {
			safeColor = theme.other.getColor('text/primary/default');
			safeBackgroundColor = theme.other.getColor('fill/secondary/default');
		}

		const { classes } = useStyles({
			backgroundColor: safeBackgroundColor,
			color: safeColor,
		});

		return (
			<MantineBadge
				ref={ref}
				{...others}
				classNames={{
					root: classes.root,
					inner: classes.inner,
					leftSection: classes.leftSection,
				}}
			>
				{children}
			</MantineBadge>
		);
	}
);
