import { Anchor, Group, List, Paper, useMantineTheme } from '@mantine/core';
import { Button, Title } from '@repo/foundations';
import { isEmpty, lowerCase } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthUser } from '../../../../api';
import { trackEvent } from '../../../../utils/analytics';

import { entityModalStore } from '../../store';

function QuestionFooter() {
	const theme = useMantineTheme();
	const { user, workspace } = useAuthUser();
	const navigate = useNavigate();

	const handleDefaultActionClick = () => {
		trackEvent('question/create', {}, user, workspace!);
		entityModalStore.createQuestion(navigate);
	};

	return (
		<>
			{!isEmpty(entityModalStore.relatedQuestions) && (
				<Paper
					bg={theme.other.getColor('surface/secondary/default')}
					ml={theme.spacing.xl}
					p="md"
					withBorder
					mb={20}
					mt={20}
				>
					<Title order={5} color="text/primary/default">
						Similar questions
					</Title>
					<List size="sm" listStyleType="none">
						{entityModalStore.relatedQuestions?.map((question) => (
							<List.Item key={question.id}>
								<Anchor
									onClick={entityModalStore.close}
									component={Link}
									to={`/questions/${question.id}`}
								>
									{question.title}
								</Anchor>
							</List.Item>
						))}
					</List>
				</Paper>
			)}
			<Group position="right">
				<Button
					onClick={handleDefaultActionClick}
					loading={entityModalStore.loading}
					variant="primary"
					size="md"
				>
					Create {lowerCase(entityModalStore.type)}
				</Button>
			</Group>
		</>
	);
}

export default observer(QuestionFooter);
