import { createStyles } from '@mantine/core';
import { px } from '@mantine/styles';

interface IQuestionLayoutStylesProps {
	accepted: boolean;
}

export const useStyles = createStyles(
	(theme, { accepted }: IQuestionLayoutStylesProps) => {
		const ACCEPTED_PADDING = accepted ? px(theme.spacing.md) : 0;
		const ACCEPTED_MARGIN = accepted ? px(theme.spacing.lg) * 2 : 0;

		return {
			wrapper: {
				flexWrap: 'nowrap',
				alignItems: 'flex-start',
				marginTop: theme.spacing.md,
				borderRadius: theme.radius.sm,
				marginLeft: ACCEPTED_MARGIN,
				padding: ACCEPTED_PADDING,
				backgroundColor: accepted ? theme.colors.gray[1] : 'transparent',
			},
			editorWrapper: {
				borderRadius: theme.radius.sm,
				border: `1px solid ${theme.colors.gray[2]}`,
				justifyContent: 'space-between',
				flexWrap: 'nowrap',
				alignItems: 'flex-start',
				flex: 1,
			},
			editorWhileEditing: {
				gap: 4,
				paddingTop: theme.spacing.xs,
				paddingLeft: theme.spacing.sm,
				paddingRight: theme.spacing.sm,
				position: 'relative',
				width: '100%',
			},
		};
	}
);
