import { Stack } from '@mantine/core';
import { FilterItemError } from '@repo/common/components/Filter/FilterItemError';
import { FilterItemSkeleton } from '@repo/common/components/Filter/FilterItemSkeleton';
import { Text } from '@repo/foundations';
import {
	ListBox,
	type ListBoxProps,
} from '@repo/foundations/components/ListBox';
import { range } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { ISecodaEntity, useMetricList } from '../../../../../api';

interface MetricPickerProps {
	onClose: () => void;
	targetRef: ListBoxProps['targetRef'];
	onSelect: (metric: ISecodaEntity) => void;
}

export function MetricPicker({
	onClose,
	targetRef,
	onSelect,
}: MetricPickerProps) {
	const [searchTerm, setSearchTerm] = useState('');
	const {
		data: metrics,
		isLoading,
		error,
	} = useMetricList({
		filters: searchTerm
			? {
					title__icontains: searchTerm,
					hidden: false,
				}
			: undefined,
	});

	const items = useMemo(
		() =>
			(metrics?.results ?? []).map((item) => ({
				children: <Text size="sm">{item.title ?? 'Untitled'}</Text>,
				onClick: () => {
					onSelect(item);
				},
			})),
		[metrics?.results, onSelect]
	);

	const handleOpenChange = useCallback(
		(open: boolean) => !open && onClose(),
		[onClose]
	);

	return (
		<ListBox opened onOpenChange={handleOpenChange} targetRef={targetRef}>
			<ListBox.ItemsDropdown
				items={items}
				search={{
					onChange: setSearchTerm,
					value: searchTerm,
					placeholder: 'Search metrics',
				}}
			>
				{isLoading && (
					<Stack spacing="2xs">
						{range(3).map((idx) => (
							<FilterItemSkeleton key={idx} />
						))}
					</Stack>
				)}
				{error && <FilterItemError>An error has ocurred</FilterItemError>}
			</ListBox.ItemsDropdown>
		</ListBox>
	);
}
