import { every, forEach, includes, size, some, values } from 'lodash-es';
import type { SearchResult } from '../../../api';
import { EntityType } from '../../../lib/types';
import { DisplayValue } from '../FilterCarousel/FilterCarousel.constants';
import type { SearchFilterStore } from '../FilterCarousel/store';

export const shouldHideEntityAvatarGroups = (
	entity: SearchResult,
	searchFilterStore: SearchFilterStore
) => {
	const shouldHide = [
		searchFilterStore.isDisplayChecked(DisplayValue.COLLECTIONS),
		searchFilterStore.isDisplayChecked(DisplayValue.OWNERS),
		searchFilterStore.isDisplayChecked(DisplayValue.TAGS),
	].every(Boolean);

	if (shouldHide) {
		return true;
	}

	const rowHidden: boolean[] = [];

	forEach(values(searchFilterStore.displays), (view) => {
		if (view.value === DisplayValue.COLLECTIONS) {
			rowHidden[0] =
				size(entity.display_metadata.collections) === 0 || !view.checked;
		} else if (view.value === DisplayValue.OWNERS) {
			rowHidden[1] =
				size(entity.display_metadata.owners) === 0 || !view.checked;
		} else if (view.value === DisplayValue.TAGS) {
			rowHidden[2] = size(entity.tags) === 0 || !view.checked;
		}
	});

	return every(rowHidden, Boolean);
};

export const shouldHideMetadata = (
	entity: SearchResult,
	searchFilterStore: SearchFilterStore
) => {
	if (searchFilterStore.isDisplayChecked(DisplayValue.METADATA)) {
		return true;
	}

	let rowHidden = true;
	if (includes([EntityType.table, EntityType.column], entity.entity_type)) {
		rowHidden = !some([
			entity.search_metadata?.database,
			entity.search_metadata?.schema,
			entity.search_metadata?.table,
		]);
	} else if (
		includes([EntityType.dashboard, EntityType.job], entity.entity_type)
	) {
		rowHidden = !entity.search_metadata?.group;
	}
	return rowHidden;
};
