import type { Transform } from '@dnd-kit/utilities';
import type { MantineTheme } from '@mantine/core';
import { integrationList as integrationInformationList } from '@repo/common/constants/integration/integrations';
import { find, includes, isNil, map, omitBy, sortBy } from 'lodash-es';
import type { WidgetSize } from '../../../../interfaces';
import { WidgetType } from '../../../../interfaces';
import type { Integration } from '../../../../lib/models';
import { iconSrcFromIntegration } from '../../../../utils/integrationLogo';
import { getIntegrationURL } from '../../../../utils/navigationUtils';
import type { HomepageWidgetType } from '../../types';
import {
	COLLECTION_WIDGETS,
	QUESTIONS_WIDGETS,
	RESOURCE_WIDGETS,
	WIDGET_SIZE_MAP,
	WIDGET_TITLE_MAP,
} from './constants';
import type {
	CollectionWidgetTypes,
	QuestionsWidgetTypes,
	ResourceWidgetTypes,
} from './types';

export const getTransition = (isDragging = false) => ({
	duration: !isDragging ? 0.25 : 0,
	easings: {
		type: 'spring',
	},
	scale: {
		duration: 0.25,
	},
	zIndex: {
		delay: isDragging ? 0 : 0.25,
	},
});

export const getAnimation = (
	transform: Transform | null,
	isDragging = false
) => ({
	x: transform?.x ?? 0,
	y: transform?.y ?? 0,
	scale: isDragging ? 1.05 : 1,
	zIndex: isDragging ? 1 : 0,
});

export const isFullSizeWidget = (size: WidgetSize) =>
	['lg', 'full'].includes(size);

export const isHalfSizeWidget = (size: WidgetSize) =>
	['sm', 'md'].includes(size);

export const getWidgetSpan = (size: WidgetSize) =>
	`span ${WIDGET_SIZE_MAP[size]}`;

export const getWidgetTitle = (widgetType: HomepageWidgetType, title = '') =>
	title || WIDGET_TITLE_MAP[widgetType];

export const getIntegrationsToDisplay = (
	integrationsList: Integration[]
): Array<{
	id: string;
	name: string;
	url: string;
	logoSrc: string;
}> => {
	const mergedIntegrationInfo = map(integrationsList, (integration) => {
		const integrationInformation = find(integrationInformationList, [
			'type',
			integration.type,
		]);

		if (integrationInformation === undefined) {
			return null;
		}

		const { url: baseUrl, catalogFilterParams } = getIntegrationURL({
			entityType: integrationInformation.entity_type,
			id: integration.id,
			level: 0,
			title: integration.name,
			teamId: integration.teams[0],
		});

		const url = `${baseUrl}?filters=${encodeURIComponent(
			JSON.stringify(catalogFilterParams)
		)}`;

		const logoSrc = iconSrcFromIntegration(integration);

		return {
			id: integration.id,
			name: integration.name,
			url,
			logoSrc,
		};
	});

	const foundIntegrations = omitBy(mergedIntegrationInfo, isNil);

	return sortBy(foundIntegrations, ['name']);
};

export const isTextWidget = (widgetType: HomepageWidgetType) =>
	widgetType === WidgetType.TEXT_BLOCK;

export const isResourceWidget = (
	widgetType: WidgetType
): widgetType is ResourceWidgetTypes => includes(RESOURCE_WIDGETS, widgetType);

export const isQuestionsWidget = (
	widgetType: WidgetType
): widgetType is QuestionsWidgetTypes =>
	includes(QUESTIONS_WIDGETS, widgetType);

export const isCollectionWidget = (
	widgetType: WidgetType
): widgetType is CollectionWidgetTypes =>
	includes(COLLECTION_WIDGETS, widgetType);

export const getResourceEmptyInfo = (widgetType: ResourceWidgetTypes) => {
	switch (widgetType) {
		case WidgetType.POPULAR_RESOURCES:
			return {
				message: 'No popular resources found yet.',
				buttonLabel: 'Browse resources',
			};
		case WidgetType.RECENTLY_ACCESSED_RESOURCES:
			return {
				message: "You haven't viewed any resources recently.",
				buttonLabel: 'Show resources',
			};
		case WidgetType.RECENTLY_EDITED_RESOURCES:
			return {
				message: "You haven't edited any resources recently.",
				buttonLabel: 'Show resources',
			};
		case WidgetType.BOOKMARKED_RESOURCES:
			return {
				message:
					"You haven't bookmarked any resources yet. Save helpful content for easy access later.",
				buttonLabel: 'Show resources',
			};
		default:
			return {
				buttonLabel: '',
				message: '',
			};
	}
};

export const getCollectionEmptyInfo = (widgetType: CollectionWidgetTypes) => {
	switch (widgetType) {
		case WidgetType.POPULAR_COLLECTIONS:
			return {
				message: 'No popular collections found yet.',
				buttonLabel: 'Browse collections',
			};
		case WidgetType.PINNED_COLLECTIONS:
			return {
				message:
					"You haven't pinned any collections yet. Pin collections to keep them at the top of your homepage",
				buttonLabel: 'Show popular',
			};
		default:
			return {
				buttonLabel: '',
				message: '',
			};
	}
};

export function getWidgetColors(
	color: string | undefined,
	theme: MantineTheme
) {
	if (isNil(color) || color === 'white') {
		return {
			background: theme.white,
			color: theme.black,
		};
	}

	if (color === 'dark') {
		return {
			background: theme.colors.dark[7],
			color: theme.white,
		};
	}

	return {
		background: theme.fn.themeColor(color, 0),
		color: theme.fn.themeColor(color, 8),
	};
}
