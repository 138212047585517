// eslint-disable-next-line no-restricted-imports
import { Badge, createStyles } from '@mantine/core';
import { useInterval } from '@mantine/hooks';
import { Button } from '@repo/foundations';
import { upperFirst } from 'lodash-es';
import {
	MouseEventHandler,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { IDatabuilderJob, useUpdateDatabuilderJob } from '../../../../../api';
import { forceFetchJobs } from '../../../../../api/hooks/databuilderJob/usePollDatabuilderJob';
import {
	formatShortTimeDifference,
	humanReadableTimeElapsed,
} from '../../../../../utils/time';
import {
	EXTRACTION_STATUS,
	getExtractionBadgeVariant,
	getExtractionLabel,
	getExtractionStatus,
} from '../../../IntegrationExtraction/utils';

interface SyncTableRowProps {
	job: IDatabuilderJob;
	setActiveExtraction: (job: IDatabuilderJob) => void;
	setExtractionPolling: (value: boolean) => void;
}

const useStyles = createStyles((theme) => ({
	badgeRoot: {
		borderRadius: theme.radius.sm,
		padding: `${theme.spacing['4xs']} ${theme.spacing['2xs']}`,
		margin: 0,
	},
}));

export function SyncTableRow({
	job,
	setActiveExtraction,
	setExtractionPolling,
}: SyncTableRowProps) {
	const status = useMemo(() => getExtractionStatus(job), [job]);
	const getJobDuration = useCallback(() => {
		if (status === EXTRACTION_STATUS.queued) {
			if (job.stages?.length === 1) {
				return 'Queued';
			}
			if (job.stages?.length > 1) {
				return humanReadableTimeElapsed(
					Math.floor((Date.now() - new Date(job.created_at).getTime()) / 1000)
				);
			}
			return 'Verifying connection';
		}
		if (status === EXTRACTION_STATUS.running) {
			return humanReadableTimeElapsed(
				Math.floor((Date.now() - new Date(job.created_at).getTime()) / 1000)
			);
		}
		return humanReadableTimeElapsed(job.runtime);
	}, [job, status]);

	const { classes } = useStyles();

	const { mutate: updateJob } = useUpdateDatabuilderJob({
		options: {
			onSuccess: () => forceFetchJobs(),
		},
	});

	const [duration, setDuration] = useState(getJobDuration());
	const { start, stop } = useInterval(() => {
		setDuration(getJobDuration());
	}, 500);

	useEffect(() => {
		if (getExtractionStatus(job) !== EXTRACTION_STATUS.running) {
			setDuration(getJobDuration());
			stop();
		} else {
			start();
		}
	}, [job, start, stop]);

	const handleRowClick = useCallback(() => {
		setActiveExtraction(job);
	}, [job, setActiveExtraction]);

	const handleTerminateJob: MouseEventHandler<HTMLButtonElement> = useCallback(
		(event) => {
			event.stopPropagation();
			updateJob({ data: { id: job.id, terminated: true } });
			setExtractionPolling(false);
		},
		[job, setExtractionPolling, updateJob]
	);

	return (
		<tr key={job.id} onClick={handleRowClick}>
			<td>{getExtractionLabel(job)}</td>
			<td>{formatShortTimeDifference(job.created_at, true)}</td>
			<td>{duration}</td>
			<td>
				<Badge
					classNames={{
						root: classes.badgeRoot,
					}}
					variant={getExtractionBadgeVariant(status)}
				>
					{upperFirst(status)}
				</Badge>
			</td>
			<td>
				{['Queued', 'Running'].includes(getExtractionStatus(job)) && (
					<Button onClick={handleTerminateJob}>Terminate</Button>
				)}
			</td>
		</tr>
	);
}
