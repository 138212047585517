import type { FilterView } from '@repo/common/components/Filter/types';
import { isNil } from 'lodash-es';
import { ISearchView, useAuthUser, useSearchViewList } from '../../../api';

export function useFilterViews(): { views: FilterView[] } {
	const { user } = useAuthUser();
	const { data: views } = useSearchViewList({
		options: {
			select: (response) =>
				(response.results ?? [])
					.map(
						(view: ISearchView) =>
							({
								is_default_for_teams: view.is_default_for_teams,
								label: view.name,
								value: view.id,
								filters: view.selected_filters,
								isOwner: view.owner_id === user.id,
								teams: view.teams,
							}) as FilterView
					)
					.sort((a, b) => {
						if (isNil(a.label)) return 1;
						return a.label.localeCompare(b.label);
					}),
		},
	});

	return {
		views: views ?? [],
	};
}
