import { createStyles, Group, Input, Loader, Stack } from '@mantine/core';
import { useDebouncedValue, useMediaQuery } from '@mantine/hooks';
import { useSuggestions } from '@repo/api-codegen';
import type { FilterView } from '@repo/common/components/Filter/types';
import { Pagination } from '@repo/common/components/Pagination';
import { Button, Icon, Text, Title } from '@repo/foundations';
import { size } from 'lodash-es';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSearch } from '../../api';
import { DOCUMENTATION_WIDTH } from '../../components/Documentation/constants';
import { SearchFilterV2StoreContext } from '../../components/Filter';
import { useFilterQueryString } from '../../components/Filter/useFilterQueryString';
import { FilterViewModal } from '../../components/Filter/Views/FilterViewModal';
import { FilterViews } from '../../components/Filter/Views/FilterViews';
import { closeAllModals, openModal } from '../../components/ModalManager';
import { useAiEnabled } from '../../hooks/useAIEnabled';
import { useUserOnboarding } from '../../hooks/useUserOnboarding';
import {
	deleteParamsFromUrl,
	getParamsFromUrl,
	setParamsInUrl,
} from '../../utils/url';
import AskAIModal from './FilterCarousel/AskAIModal/AskAIModal';
import { SearchFilterStoreContext } from './FilterCarousel/store';
import SearchResults from './SearchResults';
import { searchPageStore } from './store';
import { FilterBar } from './v2/FilterBar/FilterBar';

const useStyles = createStyles((theme) => ({
	topBar: {
		padding: `${theme.spacing.xs} 0`,
		minWidth: theme.other.space[60], // 240px
		width: '100%',
		alignSelf: 'center',
		justifyContent: 'space-between',
		flex: 1,

		[theme.fn.smallerThan('lg')]: {
			display: 'none',
		},
	},
	breadcrumbWrapper: {},
	actionsWrapper: {},
	container: {
		maxWidth: DOCUMENTATION_WIDTH, // 640px
		minWidth: theme.other.space[60], // 240px
		width: '100%',
		gap: 0,
		alignSelf: 'center',
		flex: 1,
		marginTop: -theme.other.space[9], // .topBar height

		[theme.fn.smallerThan('lg')]: {
			marginTop: theme.spacing.xs,
		},
	},
	input: {
		borderRadius: theme.radius.md,
		boxShadow: theme.shadows.sm,
	},
	iconGroup: {
		justifyContent: 'flex-end',
		transitionProperty: 'width',
		transitionTimingFunction: 'ease-in',
		transitionDuration: '250ms',
	},
}));

function SearchPage() {
	const { classes, theme } = useStyles();
	const isSmallScreen = useMediaQuery(
		`(max-width: ${theme.breakpoints.lg})`,
		false
	);

	const searchFilterStore = useContext(SearchFilterStoreContext);
	const searchFilterV2Store = useContext(SearchFilterV2StoreContext);

	const { dismissViewerOnboardingStepSearch } = useUserOnboarding();

	const { enableAi } = useAiEnabled();

	const { parsedFilters } = searchFilterStore;
	const { catalogFilter: catalogFilters, catalogSort } = searchFilterV2Store;

	const [page, setPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState(
		getParamsFromUrl().get('term') || ''
	);
	const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 350);

	const { isFetching, data } = useSearch({
		page,
		searchTerm: debouncedSearchTerm,
		filterV2: catalogFilters,
		sortV2: catalogSort,
		onSearchComplete: dismissViewerOnboardingStepSearch,
		options: {
			suspense: false,
		},
	});
	// debouncing so the spinners won't show if the search response is fast
	const [debouncedIsFetching] = useDebouncedValue(isFetching, 300);

	const { data: suggestion } = useSuggestions({
		queryParams: {
			term: debouncedSearchTerm,
		},
	});

	const setSearchTermFromSuggestion = () => {
		setSearchTerm(suggestion?.suggestion || '');
	};

	const showSuggestion =
		suggestion?.suggestion && suggestion?.suggestion !== debouncedSearchTerm;

	useEffect(() => {
		const params = getParamsFromUrl();
		const currentTerm = params.get('term') || '';
		setPage(1);
		if (searchTerm === currentTerm) {
			return;
		}
		if (debouncedSearchTerm !== '') {
			setParamsInUrl('term', debouncedSearchTerm);
		} else {
			deleteParamsFromUrl('term');
		}

		// For Secoda AI toggle logic only
		searchPageStore.setTerm(currentTerm);
	}, [debouncedSearchTerm, parsedFilters, catalogFilters, searchTerm]);

	const handleOpenFilterViewModal = useCallback(
		(view: FilterView | null) => {
			openModal({
				title: view ? 'Edit view' : 'Save view',
				children: (
					<FilterViewModal
						onClose={(newView?: FilterView | null) => {
							if (newView) {
								searchFilterV2Store.setFilterView(newView);
							}
							closeAllModals();
						}}
						view={view ?? searchFilterV2Store.view}
						selectedFilters={toJS(searchFilterV2Store.values)}
					/>
				),
			});
		},
		[searchFilterV2Store]
	);

	useFilterQueryString(searchFilterV2Store);

	const searchIcon = debouncedIsFetching ? (
		<Loader size={16} />
	) : (
		<Icon name="search" />
	);

	return (
		<Stack spacing={0} px="sm">
			{searchTerm && (
				<Helmet>
					<title>Results for {searchTerm}</title>
				</Helmet>
			)}
			{!isSmallScreen && (
				<Group className={classes.topBar}>
					<Group>
						<FilterViews
							handleEdit={handleOpenFilterViewModal}
							onChange={searchFilterV2Store.setFilterView}
							value={searchFilterV2Store.view}
							renderTarget={(view, isMenuOpen, toggle) => (
								<Button
									variant="tertiary"
									onClick={toggle}
									data-testid="search-view-button"
								>
									<Title weight={'semibold'} size="sm">
										{view ? view.label : 'All results'}
									</Title>
									<Icon
										color="icon/primary/default"
										name={'selector'}
										iconPadding={0}
									/>
								</Button>
							)}
						/>
					</Group>
					<Group position="right" className={classes.actionsWrapper}>
						{searchFilterV2Store.valuesDiffersFromViewValues && (
							<Group spacing="xs" className={classes.iconGroup}>
								<Button
									size="md"
									variant="tertiary"
									onClick={() =>
										searchFilterV2Store.setValues(
											searchFilterV2Store.viewValues
										)
									}
								>
									Cancel
								</Button>
								<Button
									size="md"
									onClick={() => handleOpenFilterViewModal(null)}
								>
									Save view
								</Button>
							</Group>
						)}
					</Group>
				</Group>
			)}
			<Stack className={classes.container}>
				<Input
					classNames={{ input: classes.input }}
					icon={searchIcon}
					variant="default"
					placeholder={
						searchFilterV2Store.view
							? `Search in ${searchFilterV2Store.view.label}`
							: 'Search company data'
					}
					rightSection={enableAi && <AskAIModal />}
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					data-testid="search-page-search-input"
				/>
				<FilterBar
					showTopLevelOperator
					showFilterViews={isSmallScreen}
					onOpenFilterViewModal={
						searchFilterV2Store.values.length > 0
							? () => handleOpenFilterViewModal(null)
							: undefined
					}
				/>
				{showSuggestion && (
					<Group position="left" mb="md" spacing="3xs">
						<Text size="sm">Did you mean: </Text>
						<Button onClick={setSearchTermFromSuggestion} variant="tertiary">
							<Text size="sm" weight="semibold" color="text/emphasis/default">
								{suggestion?.suggestion}
							</Text>
						</Button>
					</Group>
				)}

				<SearchResults
					page={page}
					pageSize={20}
					isFetching={debouncedIsFetching}
					results={data?.results || []}
					term={debouncedSearchTerm}
				/>
				{!isFetching && size(data?.results) > 0 && (
					<Pagination
						page={page}
						total={data?.total_pages || 1}
						onChange={setPage}
					/>
				)}
			</Stack>
		</Stack>
	);
}

export default observer(SearchPage);
