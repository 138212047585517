import useUrlState from '@ahooksjs/use-url-state';
import { Divider, Group, Stack } from '@mantine/core';
import {
	LiteIntegration,
	LiteWorkspace,
	useApiSuperuserGetIntegration,
} from '@repo/api-codegen';
import { Button, Title } from '@repo/foundations';
import { uuidv4 } from 'lib0/random';
import { useCallback, useEffect, useState } from 'react';
import { useIntegrationApiSpec } from '../../../api/hooks/inspectApi';
import FullWidthLoadingSpinner from '../../../components/LoadingSpinner';
import { SuperuserIntegrationSelector } from '../components/SuperuserIntegrationSelector';
import { SuperuserWorkspaceSelector } from '../components/SuperuserWorkspaceSelector';
import { InspectAPIBlock } from './InspectAPIBlock';
import InspectSQLBlock from './InspectSQLBlock';

function InspectIntegrationPage() {
	const [urlState, setUrlState] = useUrlState();
	const [workspace, setWorkspace] = useState<LiteWorkspace | undefined>(
		undefined
	);
	const [integration, setIntegration] = useState<LiteIntegration | undefined>();

	const handleSetWorkspace = (newWorkspace?: LiteWorkspace) => {
		setIntegration(undefined);
		setWorkspace(newWorkspace);
		setUrlState({ integrationId: null });
	};

	const handleSetIntegration = useCallback(
		(newIntegration?: LiteIntegration) => {
			setIntegration(newIntegration);
			setWorkspace(newIntegration?.workspace);
			setUrlState({ integrationId: newIntegration?.id });
		},
		[setUrlState]
	);

	// Grab integrationId from URL and autofill
	const { data: integrations, isLoading } = useApiSuperuserGetIntegration(
		{
			queryParams: {
				search_term: urlState.integrationId,
			},
		},
		{
			enabled: !!urlState.integrationId,
		}
	);

	useEffect(() => {
		if (integrations && urlState.integrationId) {
			const foundIntegration = integrations.find(
				(i) => i.id === urlState.integrationId
			);
			if (foundIntegration) {
				handleSetIntegration(foundIntegration);
			}
		}
	}, [urlState.integrationId, integrations, handleSetIntegration]);

	const { data: inspectApiSpec, isLoading: isLoadingApiSpec } =
		useIntegrationApiSpec(integration?.id || '', {
			enabled: !!integration,
		});
	const [blocks, setBlocks] = useState<string[]>(() => [uuidv4()]);

	if (urlState.integrationId && isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	return (
		<Stack
			mih="100vh"
			p="md"
			sx={{
				overflowX: 'scroll',
			}}
		>
			<Stack w={500}>
				<Group key="workspace">
					<Title order={5}>Workspace</Title>
					<SuperuserWorkspaceSelector
						selected={workspace}
						setSelected={handleSetWorkspace}
					/>
					{workspace && <Stack>Workspace ID: {workspace.id}</Stack>}
				</Group>
				<Group key="integration">
					<Title order={5}>Integration</Title>
					<SuperuserIntegrationSelector
						workspace={workspace}
						selected={integration}
						setSelected={handleSetIntegration}
					/>
					{integration && <Stack>Integration ID: {integration.id}</Stack>}
				</Group>
			</Stack>
			{isLoadingApiSpec && integration && <FullWidthLoadingSpinner />}
			{!isLoadingApiSpec && integration && (
				<>
					<Divider color="dark" />
					{blocks.map((block) => (
						<>
							{!inspectApiSpec ? (
								<InspectSQLBlock
									key={block}
									blockId={block}
									integration={integration}
									onDelete={() => setBlocks(blocks.filter((b) => b !== block))}
								/>
							) : (
								<InspectAPIBlock
									key={block}
									spec={inspectApiSpec}
									integrationId={integration?.id}
									onDelete={() => setBlocks(blocks.filter((b) => b !== block))}
								/>
							)}
							<Divider color="dark" />
						</>
					))}
					<Button
						size="md"
						variant="primary"
						onClick={() => setBlocks([...blocks, uuidv4()])}
					>
						New block
					</Button>
				</>
			)}
		</Stack>
	);
}

export default InspectIntegrationPage;
