import { Container, createStyles, Stack } from '@mantine/core';
import { Title } from '@repo/foundations';
import { getColor } from '@repo/theme/utils';
import { useAuthUser } from '../../../api';
import { Search } from '../../../components/Search/Search';
import { HOMEPAGE_MAX_WIDTH, HOMEPAGE_PADDING } from '../constants';
import { getBackgroundImageStyles, getGreeting } from '../utils';

interface IStyleParams {
	backgroundImage: string;
	fontColor: string;
}

interface IHomepageHeaderProps {
	image?: string;
	fontColor?: string;
}

const useStyles = createStyles(
	(theme, { backgroundImage, fontColor }: IStyleParams) => ({
		title: {
			paddingTop: '1rem',
			color: fontColor,
		},
		container: {
			marginLeft: 0,
			marginRight: 0,
			maxWidth: 'unset',
			flexGrow: 1,
			...getBackgroundImageStyles(theme, backgroundImage, true),
		},
		grow: {
			flexGrow: 1,
		},
	})
);

function HomepageHeader({ image, fontColor }: IHomepageHeaderProps) {
	const { user } = useAuthUser();

	const sanitizeFontColor = () => {
		if (fontColor === '#000000') {
			return getColor('text/primary/default');
		}
		return fontColor;
	};

	const { classes } = useStyles({
		backgroundImage: image || getColor('surface/tertiary/default'),
		fontColor: sanitizeFontColor() ?? getColor('text/primary/default'),
	});

	const title = `${getGreeting()}${user?.first_name ? ',' : ''} ${
		user.first_name
	}`;

	return (
		<Container
			px={HOMEPAGE_PADDING}
			size={HOMEPAGE_MAX_WIDTH + 2 * HOMEPAGE_PADDING}
			miw={HOMEPAGE_MAX_WIDTH + 2 * HOMEPAGE_PADDING}
			className={classes.container}
		>
			<Stack className={classes.grow} justify="center" align="center" py={64}>
				<Title order={1} className={classes.title} lineClamp={1}>
					{title}
				</Title>
				<Search />
			</Stack>
		</Container>
	);
}

export default HomepageHeader;
