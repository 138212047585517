import type { MantineThemeOverride } from '@mantine/core';

export const Divider: MantineThemeOverride['components'] = {
	Divider: {
		styles: (theme) => ({ label: { color: theme.colors.gray[2] } }),
		defaultProps: (theme) => ({
			color: theme.colors.gray[2],
			labelProps: { color: theme.colors.gray[9] },
		}),
	},
};
