import { Group } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { EntityType } from '../../../lib/types';
import { ENTITY_TYPES_MODAL_CONFIG } from '../constants';
import { entityModalStore } from '../store';
import { AssigneeSelector } from './AssigneeSelector';
import CollectionSelector from './CollectionSelector';
import { OwnerSelector } from './OwnerSelector';
import { ParentCollectionSelector } from './ParentCollectionSelector';
import { PrioritySelector } from './PrioritySelector';
import TagSelector from './TagSelector';

interface EntityModalMetadataProps {
	type?: EntityType;
}

function EntityModalMetadata({ type }: EntityModalMetadataProps) {
	const handleChange = useCallback(
		(key: string) => (value: string | string[]) => {
			entityModalStore.setMetadata(key, value);
		},
		[]
	);

	const config = ENTITY_TYPES_MODAL_CONFIG?.[type as string] ?? {};

	return (
		<Group px="xl" mb="sm">
			<Group key={entityModalStore?.template?.id}>
				{config?.withOwners && (
					<OwnerSelector
						initialValue={entityModalStore?.template?.owners}
						onChangeUserOwners={handleChange('owners')}
					/>
				)}
				{config?.withParentCollection && (
					<ParentCollectionSelector
						initialValue={entityModalStore?.template?.parent}
						onChange={handleChange('parent')}
					/>
				)}
				{config?.withCollections && (
					<CollectionSelector
						initialValue={entityModalStore?.template?.collections}
						placeholderIconName="folders"
						onChange={handleChange('collections')}
					/>
				)}
				{config?.withAssignee && (
					<AssigneeSelector
						initialValue={entityModalStore?.template?.assignee}
						onChange={handleChange}
					/>
				)}
				{config?.withPriority && (
					<PrioritySelector
						initialValue={entityModalStore?.template?.priority}
						onChange={handleChange('priority')}
					/>
				)}
				{config?.withTags && (
					<TagSelector
						initialValue={entityModalStore?.template?.tags}
						placeholderIconName="tags"
						onChange={handleChange('tags')}
					/>
				)}
			</Group>
		</Group>
	);
}

export default observer(EntityModalMetadata);
