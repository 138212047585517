/* eslint-disable no-use-before-define */
import { Checkbox, Stack } from '@mantine/core';
import { useApiGetEntityStakeholders } from '@repo/api-codegen';
import { MultiSelect } from '@repo/foundations';
import { useUpdateEffect } from 'ahooks';
import { isEmpty, uniq, uniqBy } from 'lodash-es';
import { useState } from 'react';
import { ISecodaEntity, useAuthUser } from '../../api';
import type { SecodaEntity, UserGroup } from '../../lib/models';
import { useEntityUserList } from './AnnouncementEntityRecipients.helpers';
import { useAnnouncementGlobalRecipientsList } from './AnnouncementGlobalRecipients.helpers';
import type { AnnouncementFormKeys } from './types';

export function AnnouncementEntityRecipientsGlobal({
	onChange,
}: {
	onChange: (key: AnnouncementFormKeys) => (value: string | string[]) => void;
}) {
	const { options } = useAnnouncementGlobalRecipientsList();
	return <AnnouncementEntityRecipients onChange={onChange} data={options} />;
}

export function AnnouncementEntityRecipientsEntity({
	onChange,
	entity,
}: {
	onChange: (key: AnnouncementFormKeys) => (value: string | string[]) => void;
	entity: SecodaEntity | ISecodaEntity;
}) {
	const { channels, users, groups } = useAnnouncementGlobalRecipientsList();

	const { data: stakeholders } = useApiGetEntityStakeholders({
		pathParams: {
			entityId: entity.id,
		},
	});

	const options = useEntityUserList(
		entity,
		users,
		groups,
		stakeholders || {
			downstream_slack_channels: [],
			downstream_owners: [],
			upstream_owners: [],
		},
		channels
	);

	if (!stakeholders) {
		return null;
	}

	return <AnnouncementEntityRecipients onChange={onChange} data={options} />;
}

function AnnouncementEntityRecipients({
	onChange,
	data,
}: {
	onChange: (key: AnnouncementFormKeys) => (value: string | string[]) => void;
	data: {
		value: string;
		label: string;
		type: string;
		group: string;
		original: unknown;
	}[];
}) {
	const [value, setValue] = useState<string[]>([]);
	const { user } = useAuthUser();

	useUpdateEffect(() => {
		const idsToValues = data.filter((d) => value.includes(d.value));
		const groupToUserIds = idsToValues
			.filter((d) => d.type === 'group')
			.map((d) => (d.original as unknown as UserGroup).users)
			.flat();
		const userIds = idsToValues
			.filter((d) => d.type === 'user')
			.map((d) => d.value);
		const channelsToIds = idsToValues
			.filter((d) => d.type === 'channel')
			.map((d) => d.value);

		onChange('receiver_slack_channel_ids')(uniq(channelsToIds));
		onChange('recipients')(uniq([...userIds, ...groupToUserIds, user!.id]));
	}, [value]);

	return (
		<Stack>
			<MultiSelect
				clearable
				size="md"
				value={value}
				setValue={setValue}
				required
				searchable
				label="Recipients"
				description={
					isEmpty(value) ? undefined : `${value.length} recipients selected`
				}
				placeholder="Select recipients"
				// We can use uniqBy here because it keeps the first occurrence of the duplicates.
				// We only call it at this level because we need an unfilled array to be able to show all applicable checkboxes (below).
				data={uniqBy(data, 'value')}
			/>
			{uniq(data.map((d) => d.group))
				.filter((group: string) => !group.toLowerCase().startsWith('other'))
				.map((group) => {
					const groupData = uniqBy(
						data.filter((d) => d.group === group),
						'value'
					);

					const checked = groupData.every((d) => value.includes(d.value));
					const indeterminate =
						!checked && groupData.some((d) => value.includes(d.value));

					const onClick = () => {
						setValue((prev) => {
							if (checked) {
								return prev.filter(
									(v) => !groupData.map((d) => d.value).includes(v)
								);
							}
							return uniq([...prev, ...groupData.map((d) => d.value)]);
						});
					};

					return (
						<Checkbox
							onClick={onClick}
							size="md"
							key={group}
							label={`${group} (${groupData.length})`}
							checked={checked}
							indeterminate={indeterminate}
						/>
					);
				})}
		</Stack>
	);
}
