import { createStyles, Stack } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { useKeyPress } from 'ahooks';
import { size } from 'lodash-es';
import { useMatch } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { IBaseModel, useAuthUser } from '../../../../../api';
import { closeSpotlight } from '../../../events';
import type { ICommandListItem } from '../constants';
import { handleCommandPaletteItem } from '../utils';
import CommandPaletteItem from './CommandPaletteItem';

export interface IResultsProps {
	searchResults: ICommandListItem<IBaseModel>[];
	onSearchInputChange: (event: ICommandListItem<IBaseModel>) => void;
	searchTerm: string;
	selectedItem: number;
	setSelectedItem: (number: number) => void;
}

const useStyles = createStyles((theme) => ({
	loading: {
		height: theme.headings.sizes.h1.fontSize,
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	head: {
		paddingLeft: theme.spacing.xs,
		marginLeft: theme.spacing.xs,
		marginTop: theme.spacing.xs,
		color: theme.colors.gray[6],
		fontSize: theme.fontSizes.xs,
		fontWeight: theme.other.typography.weight.semibold,
	},
	disabled: {
		padding: theme.spacing.xs,
		color: theme.other.getColor('text/primary/disabled'),
	},
}));

function CommandPaletteResults({
	searchResults,
	onSearchInputChange,
	searchTerm,
	selectedItem,
	setSelectedItem,
}: IResultsProps) {
	const navigate = useNavigate();
	const { classes } = useStyles();
	const { user } = useAuthUser();
	const urlTeam = useMatch('/teams/:teamId/*');
	const currentTeamId = urlTeam?.params.teamId ?? null;
	useKeyPress(['uparrow'], () => {
		const possibleNextItem =
			selectedItem > 0 ? selectedItem - 1 : size(searchResults) - 1;
		const nextItem =
			possibleNextItem < 0
				? possibleNextItem + searchResults.length
				: possibleNextItem;
		if (searchResults[nextItem].type === 'group') {
			if (!searchTerm) {
				setSelectedItem(
					nextItem === 0 ? searchResults.length - 1 : nextItem - 1
				);
				return;
			}
			setSelectedItem(nextItem - 1 === 0 ? searchResults.length : nextItem - 1);
			return;
		}
		setSelectedItem(nextItem);
	});

	// Loop through the results from top to bottom.
	useKeyPress(['downarrow'], () => {
		const nextItem = ((selectedItem ?? -1) + 1) % searchResults.length;
		if (searchResults[nextItem].type === 'group') {
			setSelectedItem(nextItem + 1);
			return;
		}
		setSelectedItem(nextItem);
	});

	useKeyPress(['Enter'], () => {
		let selected = selectedItem;
		if (selectedItem === undefined) {
			selected = 0;
		}
		const { type, link, id } = searchResults[selected];
		closeSpotlight('command');
		if (type === 'static' && link) {
			navigate(link);
			return;
		}
		if (type === 'OPEN') {
			navigate(`/teams/${id}`);
			return;
		}
		handleCommandPaletteItem(
			searchResults[selectedItem],
			navigate,
			user,
			currentTeamId ?? searchResults[selectedItem].team
		);
	});

	useKeyPress(['Tab'], () => {
		onSearchInputChange(searchResults[selectedItem]);
	});

	const handleClick = (entity: ICommandListItem<IBaseModel>) => {
		handleCommandPaletteItem(
			entity,
			navigate,
			user,
			currentTeamId ?? entity.team
		);
	};

	const checkIcon = (entity: ICommandListItem<IBaseModel>) => {
		if (entity.type !== 'OPEN') {
			if (entity.iconName) {
				return <Icon name={entity.iconName} color="icon/primary/default" />;
			}
		}
		if (entity.icon) {
			// In the case of "teams" these are emoji and not true icons
			return <Text>{entity.icon}</Text>;
		}
		return null;
	};

	const searchResultsList = searchResults.map(
		(entity: ICommandListItem<IBaseModel>, index: number) => {
			const isSelected = selectedItem === index;
			const isCreateBreadcrumb = entity.category === 'create' && entity?.team;
			const showHotkey = Boolean(
				entity?.hotkey && !isSelected && !isCreateBreadcrumb
			);
			const showTabSearchKey =
				(entity.category === 'create' || entity.type === 'OPEN') &&
				isSelected &&
				!isCreateBreadcrumb;

			return (
				<CommandPaletteItem
					data-testid={`command-palette-itm-${entity.id}-17308e6`}
					key={entity.id}
					icon={checkIcon(entity)}
					onClick={() => handleClick(entity)}
					entity={entity}
					showHotkey={showHotkey}
					showTabSearchKey={showTabSearchKey}
					selected={isSelected}
				/>
			);
		}
	);

	return (
		<Stack px="xs" spacing={0}>
			{searchTerm && (
				<Text className={classes.head}>
					{searchResults.length > 0
						? `Results for ${searchTerm}...`
						: `No results found for ${searchTerm}`}
				</Text>
			)}
			{searchResultsList}
		</Stack>
	);
}

export default CommandPaletteResults;
