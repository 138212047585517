import { filter } from 'lodash-es';
import { useMemo } from 'react';
import {
	DefaultContext,
	ISecodaEntity,
	UpdateRequestParams,
	useUpdateIncident,
	type Incident,
} from '../../api';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import { UpdateModelHook } from '../../api/factories/types';
import { useGenericColumns } from '../../components/TableV2/SecodaEntity.hooks';
import {
	BoldTextRender,
	MonitorResourceRender,
	RelativeTimeRender,
} from '../../components/TableV2/render';
import { IncidentStatusBadge } from '../MonitorPage/components/IncidentStatusBadge';
import { formatValue } from '../MonitorPage/utils';

export function useColumns<T extends Incident>(): ExtendedDataTableColumn<T>[] {
	const genericColumns = useGenericColumns({
		useUpdate: useUpdateIncident as unknown as UpdateModelHook<
			ISecodaEntity,
			UpdateRequestParams<ISecodaEntity>,
			DefaultContext<ISecodaEntity>,
			unknown
		>,
	}) as ExtendedDataTableColumn<T>[];

	const columns = useMemo(
		() => [
			{
				accessor: 'name',
				title: 'Name',
				render: (record) => <BoldTextRender record={record} field={'name'} />,
			},
			{
				accessor: 'monitor',
				title: 'Resource',
				render: (record) => <MonitorResourceRender record={record} />,
			},
			{
				accessor: 'status',
				title: 'Status',
				render: (record) => (
					<IncidentStatusBadge incidentStatus={record.status} />
				),
			},
			{
				accessor: 'value',
				title: 'Value',
				render: (record: Incident) =>
					formatValue(record.metric_type, parseFloat(record.value)),
			},
			{
				accessor: 'first_seen_at',
				title: 'Last run',
				render: (record) => (
					<RelativeTimeRender record={record} field={'first_seen_at'} />
				),
			},

			...filter(genericColumns, (column) =>
				['updated_at'].includes(column.accessor)
			),
		],
		[genericColumns]
	);

	return columns;
}
