import { createStyles, Flex, Menu } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { Icon, IconButton } from '@repo/foundations';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { getChromeExtensionAddedComplete } from '../../../../pages/HomePage/components/Onboarding/utils';
import { CannyChangelog } from '../../../../utils/canny/views/CannyChangelog';

const useStyles = createStyles((theme) => ({
	targetButton: {
		pointerEvents: 'auto',
		borderRadius: theme.other.borderRadius.lg,
		backgroundColor: theme.other.getColor('fill/secondary/default'),
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/secondary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/secondary/active'),
		},
	},
	menuDropdown: {
		pointerEvents: 'auto',
	},
	changeLogButton: {
		width: '100%',
	},
}));

function openLink(url: string) {
	window.open(url, '_blank');
}

export interface HelpMenuProps {
	initialOpenState?: boolean;
}

export function HelpMenu({ initialOpenState }: HelpMenuProps) {
	const { classes } = useStyles();
	const [opened, setOpened] = useState(initialOpenState || false);

	const { data: isChromeExtensionInstalled } = useQuery(
		['chrome-extension-status'],
		getChromeExtensionAddedComplete
	);

	const { boot, show } = useIntercom();

	const menuItems = [
		{
			key: 'doc',
			icon: <Icon name="documentation" color="icon/primary/default" />,
			label: 'Documentation',
			isExternalLink: true,
			onClick: () => openLink('https://docs.secoda.co'),
		},
		{
			key: 'message',
			icon: <Icon name="message" color="icon/primary/default" />,
			label: 'Message support',
			isExternalLink: false,
			onClick: async () => {
				await boot();
				show();
			},
		},
		{
			key: 'feedback',
			icon: <Icon name="bulb" color="icon/primary/default" />,
			label: 'Give feedback',
			isExternalLink: true,
			onClick: () => openLink('https://feedback.secoda.co'),
		},
		{
			key: 'divider',
			divider: true,
		},
		{
			key: 'slack',
			icon: <Icon name="brandSlack" color="icon/primary/default" />,
			label: 'Slack community',
			isExternalLink: true,
			onClick: () =>
				openLink(
					'https://join.slack.com/t/thedataleap/shared_invite/zt-204xxd2c5-41O1vCUwEOr8dSWalnCaGg'
				),
			attributes: ['data-canny-changelog'],
		},
	];

	if (!isChromeExtensionInstalled) {
		menuItems.push({
			key: 'chrome-extension',
			icon: <Icon name="brandChrome" color="icon/primary/default" />,
			label: 'Install Chrome extension',
			isExternalLink: true,
			onClick: () =>
				openLink(
					'https://chrome.google.com/webstore/detail/secoda/akcolkhleaionhppniljgglpeeohkljk'
				),
		});
	}

	const handleMenuIconClick = () => {
		setOpened((prev) => !prev);
	};

	const helpIcon = useClickOutside(() => {
		setOpened(false);
	});

	return (
		<div ref={helpIcon}>
			<Menu
				position="top-start"
				shadow="md"
				opened={opened}
				classNames={{
					dropdown: classes.menuDropdown,
				}}
			>
				<Menu.Target>
					<IconButton
						iconName="questionMark"
						tooltip="Help and resources"
						onClick={handleMenuIconClick}
						size="md"
						className={classes.targetButton}
					/>
				</Menu.Target>
				<Menu.Dropdown>
					{menuItems.map(
						({
							key,
							icon,
							label,
							isExternalLink,
							onClick,
							divider,
							attributes,
						}) =>
							divider ? (
								<Menu.Divider key={key} />
							) : (
								<Menu.Item
									key={key}
									icon={icon}
									onClick={onClick}
									{...attributes}
								>
									<Flex w="100%" justify="space-between">
										{label}
										{isExternalLink && (
											<Icon
												name="externalLink"
												color="icon/secondary/default"
											/>
										)}
									</Flex>
								</Menu.Item>
							)
					)}
					<Menu.Divider />
					<CannyChangelog
						align="bottom"
						position="right"
						className={classes.changeLogButton}
					>
						<Menu.Item
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}}
							icon={<Icon name="speakerphone" color="icon/primary/default" />}
						>
							What&apos;s new
						</Menu.Item>
					</CannyChangelog>
				</Menu.Dropdown>
			</Menu>
		</div>
	);
}
