import { EntityType } from '../../../lib/types';

export const SearchableEntities = [
	EntityType.table,
	EntityType.dashboard,
	EntityType.chart,
	EntityType.question,
	EntityType.job,
	EntityType.collection,
	EntityType.document,
	EntityType.event,
	EntityType.column,
	EntityType.user,
	EntityType.user_group,
];
