/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useParams } from 'react-router';

import { useAuthUser, useJob, useUpdateJob } from '../../api';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import EntityLogo from '../../components/EntityLogo/EntityLogo';
import EntityPageLayout from '../../components/EntityPageLayout';

import { EntityType } from '../../lib/types';
import { trackEvent } from '../../utils/analytics';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';
import JobEntityTabs from './JobEntityTabs';

export interface IJobPageProps {
	id?: string;
}

function JobPage({ id: propsId }: IJobPageProps) {
	const { tab } = useParams();
	const paramsId = useParamsIdSuffixUuid();
	const { user, workspace } = useAuthUser();

	const id = propsId || paramsId;

	const { data: job } = useJob({
		id,
		options: {
			onSettled: () => {
				trackEvent('job/open', {}, user, workspace);
			},
		},
	});

	const { mutateAsync } = useUpdateJob({});

	const updateJob = async (
		key: string,
		value: DjangoValueType,
		saveRemotely = true
	) => {
		if (saveRemotely) {
			mutateAsync({
				data: {
					id: job!.id,
					[key]: value,
				},
			});
			trackEvent('job/properties/update', {}, user, workspace!);
		}
	};

	if (!job) {
		return null;
	}

	return (
		<EntityPageLayout
			key={job.id}
			name="Job"
			isReadOnlyTitle
			entity={job}
			icon={<EntityLogo size={32} entity={job} />}
			updateEntity={updateJob}
			withDescription
			{...getEntityPageSidebarProps(EntityType.job)}
		>
			<JobEntityTabs key={job.id} job={job} tab={tab} />
		</EntityPageLayout>
	);
}

export default JobPage;
