import { Box, createStyles } from '@mantine/core';
import ReactECharts from 'echarts-for-react';
import { isEmpty, isNil, pick } from 'lodash-es';
import { useDictionaryTerm } from '../../../../../../../api';
import { getChartConfig } from '../../../../../../../utils/charts/helpers';
import { WIDGET_CONTENT_HEIGHT } from '../../../constants';
import type { IWidgetItemProps } from '../../../types';
import type { IDictionaryTermSelectorDropdownProps } from './DictionaryTermSelectorDropdown';
import EmptyChartWidget from './EmptyChartWidget';

const useStyles = createStyles((theme) => ({
	root: { height: WIDGET_CONTENT_HEIGHT, padding: theme.spacing.md },
}));

function ChartWidget({
	widget,
	onDictionaryTermChange,
}: IWidgetItemProps & IDictionaryTermSelectorDropdownProps) {
	const { classes } = useStyles();
	const { data: chartData } = useDictionaryTerm({
		//  Wont be enabled if linked_entity is null
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		id: widget.linked_entity!,
		options: {
			enabled: !isNil(widget?.linked_entity),
			select: (data) => pick(data, ['results', 'chart_config']),
			suspense: false,
		},
	});

	if (isEmpty(chartData?.results)) {
		return (
			<EmptyChartWidget
				color={widget.color}
				onDictionaryTermChange={onDictionaryTermChange}
			/>
		);
	}

	const chartConfig = getChartConfig(
		chartData?.chart_config,
		chartData?.results,
		{
			isWidget: true,
			darkMode: widget.color === 'dark',
		}
	);

	return (
		<Box
			key={`${widget.size}-${widget.linked_entity}`}
			className={classes.root}
		>
			<ReactECharts
				style={{ height: WIDGET_CONTENT_HEIGHT }}
				option={chartConfig}
			/>
		</Box>
	);
}

export default ChartWidget;
