import type { MantineSize, MantineTheme } from '@mantine/core';
import { Avatar, createStyles } from '@mantine/core';
import type { ICollection } from '../../api';
import type { Collection } from '../../lib/models';

interface ICollectionAvatarProps {
	collection: Collection | ICollection;
	size?: MantineSize;
}

const useStyles = createStyles((theme: MantineTheme) => ({
	root: {
		backgroundColor: theme.colors.gray[2],
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: theme.colors.gray[0],
	},
	placeholder: {
		fontSize: 8,
	},
}));

export function CollectionAvatar({
	collection,
	size = 'sm',
}: ICollectionAvatarProps) {
	const { classes } = useStyles();

	return (
		<Avatar
			classNames={{ root: classes.root, placeholder: classes.placeholder }}
			size={size}
		>
			{collection.icon || '🗂️'}
		</Avatar>
	);
}

export default CollectionAvatar;
