import { Center, Loader, Menu, Stack, useMantineTheme } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import SelectorSearch from '@repo/common/components/MultiSelector/SelectorSearch';
import { isNil, size } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import type { SearchResult } from '../../api';
import { fetchSecodaEntities, useSearch } from '../../api';
import type { EntityType } from '../../lib/types';
import { buildResourceUrl } from '../../utils/navigationUtils';
import { SecodaEntityIcon } from '../SecodaEntity';
import MultiResourceTarget from './MultiResourceTarget';
import SearchResultItem from './SearchResultItem';
import type { ResourceSelectorItem } from './types';

interface IMultiResourceSelectorProps {
	excludedIds: string[];
	entityTypes?: EntityType[];
	selected: string | ResourceSelectorItem[];
	onItemSelect: (selected: SearchResult) => void;
	isViewerUser: boolean;
	contentOnly?: boolean;
	searchInputPlaceholder?: string;
	readOnly?: boolean;
}

function MultiResourceSelector({
	selected,
	onItemSelect,
	isViewerUser,
	entityTypes,
	excludedIds = [],
	contentOnly = false,
	searchInputPlaceholder,
	readOnly = false,
}: IMultiResourceSelectorProps) {
	const theme = useMantineTheme();
	const [searchTerm, setSearchTerm] = useState('');
	const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 500);
	const [selectedEntity, setSelectedEntity] = useState<ResourceSelectorItem>();

	useEffect(() => {
		const getSelectedEntity = async () => {
			const { results } = await fetchSecodaEntities({
				page: 1,
				filters: {
					id: selected as string,
				},
			});

			const entity = results?.[0];

			if (entity) {
				setSelectedEntity({
					id: entity.id,
					label: entity.title_cased ?? entity.title ?? '',
					value: entity.id,
					icon: <SecodaEntityIcon entity={entity} size={20} />,
					navigateTo: buildResourceUrl({
						id: entity.id,
						entity_type: entity.entity_type,
					}),
				} as ResourceSelectorItem);
			}
		};

		getSelectedEntity();
	}, [selected]);

	const resourceFilter = (entity: SearchResult) => {
		if (excludedIds.includes(entity.id)) {
			return false;
		}

		if (
			!isNil(entityTypes) &&
			!entityTypes.includes(entity?.entity_type as unknown as EntityType)
		) {
			return false;
		}

		return true;
	};

	let filters = {};
	if (entityTypes?.length !== 0) {
		filters = {
			entity_type__in: entityTypes?.join(','),
		};
	}

	const { isFetching, data } = useSearch({
		searchTerm: debouncedSearchTerm,
		filters,
		options: {
			select: ({ results }) => results.filter(resourceFilter),
		},
	});

	const handleSetSearchTerm = useCallback((value: string) => {
		setSearchTerm(value);
	}, []);

	const itemContent = (index: number, item: SearchResult) => (
		<SearchResultItem
			key={item.id}
			item={item}
			isViewerUser={isViewerUser}
			onClick={onItemSelect}
		/>
	);

	const target = selectedEntity ? [selectedEntity] : [];

	const handleOnClose = useCallback(() => {
		setSearchTerm('');
	}, []);

	const Dropdown = (
		<Stack spacing={0}>
			{!isViewerUser && (
				<SelectorSearch
					placeholder={searchInputPlaceholder}
					searchTerm={searchTerm}
					setSearchTerm={handleSetSearchTerm}
				/>
			)}
			{size(data) > 0 && !isFetching && !isViewerUser && (
				<Virtuoso
					style={{
						height:
							size(data) > 6
								? theme.other.space[60]
								: size(data) * theme.other.space[12],
						scrollbarColor: `${theme.other.getColor('surface/tertiary/active')} transparent`,
					}}
					data={data}
					totalCount={size(data)}
					itemContent={itemContent}
				/>
			)}
			{isFetching && (
				<Center>
					<Loader size="sm" my={12} />
				</Center>
			)}
		</Stack>
	);

	// We only want to return dropdown content,
	// not the target in this case.
	// NOTE: This component should be refactored
	// so that this isn't necessary.
	if (contentOnly) {
		return Dropdown;
	}

	return (
		<Menu
			width={300}
			position="bottom-start"
			closeOnItemClick
			onClose={handleOnClose}
			withinPortal
			disabled={readOnly}
		>
			<Menu.Target>
				<MultiResourceTarget selected={target} />
			</Menu.Target>
			<Menu.Dropdown>{Dropdown}</Menu.Dropdown>
		</Menu>
	);
}

export default MultiResourceSelector;
