import { Stack } from '@mantine/core';
import { IMarketplaceIntegrationSpecVersion } from '@repo/common/models/marketplace';
import { useNavigate } from 'react-router';
import { useInternalMarketplaceIntegrationSpecVersionList } from '../../api/hooks/marketplace';

import { FetchModelListHook } from '../../api/factories/types';
import { TableV2 } from '../../components/TableV2';
import { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { useColumns } from './InternalMarketplaceListPage.hooks';

function InternalMarketplaceListPage() {
	const navigate = useNavigate();
	const columns = useColumns();

	return (
		<Stack>
			<TableV2<IMarketplaceIntegrationSpecVersion>
				onCellClick={(cell: {
					column: ExtendedDataTableColumn<IMarketplaceIntegrationSpecVersion>;
					record: IMarketplaceIntegrationSpecVersion;
				}) => {
					if (cell.column.navigate !== false) {
						navigate(
							`/internal/marketplace/review/${cell.record.spec_id}/version/${cell.record.id}`
						);
					}
				}}
				columns={columns}
				usePaginationList={
					useInternalMarketplaceIntegrationSpecVersionList as unknown as FetchModelListHook<IMarketplaceIntegrationSpecVersion>
				}
				withInteractiveHeader={false}
				withFilters={false}
			/>
		</Stack>
	);
}

export default InternalMarketplaceListPage;
