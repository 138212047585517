import { apiQueryKey } from '@repo/api-codegen';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import {
	queryClient,
	useAuthUser,
	useSecodaEntity,
	useUpdateSecodaEntity,
} from '../../api';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import EntityLogo from '../../components/EntityLogo/EntityLogo';
import EntityPageLayout from '../../components/EntityPageLayout';
import { EntityType } from '../../lib/types';
import { trackEvent } from '../../utils/analytics';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';
import TableEntityTabs from './TableEntityTabs';
import { TableEntityTabsStore } from './TableEntityTabs/TableEntityTabsStore';

export interface ITableEntityPageProps {
	id?: string;
}

function TableEntityPage({ id: propsId }: ITableEntityPageProps) {
	const { tab } = useParams();
	const { user, workspace } = useAuthUser();
	const [tableEntityTabsStore] = useState(new TableEntityTabsStore());
	const paramsId = useParamsIdSuffixUuid();

	const id = propsId || paramsId;

	const { data: tableEntity } = useSecodaEntity({
		id,
		options: {
			useErrorBoundary: true,
			onSettled: () => {
				trackEvent('table_entity/open', {}, user, workspace);
			},
		},
	});

	const { mutateAsync } = useUpdateSecodaEntity({
		options: {
			onSuccess: () => queryClient.invalidateQueries(apiQueryKey('quality')),
		},
	});

	// Debouncing this is not a good idea, because it overwrites changes, does not
	// queue them.
	const updateTableEntity = useCallback(
		async (key: string, value: DjangoValueType, saveRemotely = true) => {
			if (saveRemotely) {
				mutateAsync({
					data: {
						id: tableEntity!.id,
						[key]: value,
					},
				});
				trackEvent('table/properties/update', {}, user, workspace!);
			}
		},
		[mutateAsync, tableEntity, user, workspace]
	);

	if (!tableEntity) {
		return null;
	}

	return (
		<EntityPageLayout
			key={tableEntity.id}
			entityTabsStore={tableEntityTabsStore}
			name="Table"
			isReadOnlyTitle
			entity={tableEntity}
			icon={<EntityLogo entity={tableEntity} size={32} />}
			updateEntity={updateTableEntity}
			withDescription
			withEntityByteSize
			withEntityRowCount
			{...getEntityPageSidebarProps(EntityType.table)}
		>
			<TableEntityTabs
				key={tableEntity.id}
				tableEntityTabsStore={tableEntityTabsStore}
				table={tableEntity}
				tab={tab}
			/>
		</EntityPageLayout>
	);
}

export default TableEntityPage;
