import { Divider, Group, Loader, Menu, Modal, Stack } from '@mantine/core';
import { Button, Select, Text, TextArea, TextInput } from '@repo/foundations';
import { useState } from 'react';

import { useCreateJiraIssue, useGetJiraInformation } from '@repo/api-codegen';
import { Incident, queryClient } from '../../../../api';
import { incidentsQueryKeyFactory } from '../../../../api/hooks/monitoring/constants';
import IntegrationLogo from '../../../../components/IntegrationLogo';
import { EntityType } from '../../../../lib/types';
import { useStyles } from './styles';

export type JiraIssueCreateModalProps = {
	opened: boolean;
	handleClose: () => void;
	incident: Incident;
};

export function JiraIssueCreateModal({
	opened,
	handleClose,
	incident,
}: JiraIssueCreateModalProps) {
	const { classes } = useStyles();
	const { data: jiraInfo } = useGetJiraInformation({});

	const [issueName, setIssueName] = useState<string>('');
	const [description, setDescription] = useState('');
	const [priority, setPriority] = useState<string>('');
	const [issueType, setIssueType] = useState<string>('');
	const [assignee, setAssignee] = useState<string>('');

	const [createButtonClicked, setCreateButtonClicked] = useState(false);

	const { mutateAsync: issueCreate, isLoading: isCreateLoading } =
		useCreateJiraIssue({
			onSuccess: () => {
				queryClient.invalidateQueries(incidentsQueryKeyFactory.all());
				handleClose();
			},
		});

	const handleCreate = () => {
		setCreateButtonClicked(true);
		if (issueName && issueType) {
			issueCreate({
				body: {
					name: issueName,
					description: description,
					issue_type: issueType,
					priority: priority,
					assignee: assignee,
					assignee_group: '',
				},
				pathParams: {
					incidentId: incident.id,
				},
			});
		}
	};

	const optionMaps: {
		label: string;
		options: Record<string, string>;
		required: boolean;
		fieldState: string;
		setFieldState: (value: string) => void;
	}[] = [
		{
			label: 'Issue type',
			options: jiraInfo?.types || {},
			required: true,
			fieldState: issueType,
			setFieldState: setIssueType,
		},
		{
			label: 'Priority',
			options: jiraInfo?.priorities || {},
			required: false,
			fieldState: priority,
			setFieldState: setPriority,
		},
		{
			label: 'Assignee',
			options: jiraInfo?.users || {},
			required: false,
			fieldState: assignee,
			setFieldState: setAssignee,
		},
	];

	return (
		<Modal.Root opened={opened} onClose={handleClose} closeOnEscape>
			<Modal.Overlay />
			<Modal.Content>
				<Modal.Header className={classes.modalHeader} py={12}>
					<Modal.Title>
						<Group>
							<IntegrationLogo
								integrationType="jira"
								size={16}
								entityType={EntityType.integration}
							/>
							<Text size="md" weight="bold">
								Create issue in Jira
							</Text>
						</Group>
					</Modal.Title>
					<Modal.CloseButton onClick={handleClose} />
				</Modal.Header>
				<Divider />
				<Modal.Body pt="sm">
					<Stack>
						<TextInput
							label="Issue name"
							onChange={(e) => setIssueName(e.target.value)}
							error={
								createButtonClicked && !issueName
									? 'This field is required'
									: ''
							}
						/>
						<TextArea
							label="Description"
							optional
							onChange={(e) => setDescription(e.target.value)}
						/>
						{optionMaps.map((optionMap) => (
							<Select
								key={optionMap.label}
								label={optionMap.label}
								placeholder={`Select ${optionMap.label.toLowerCase()}`}
								data={Object.entries(optionMap.options).map(([id, name]) => ({
									value: id,
									label: name,
								}))}
								searchable
								clearable
								dropdownComponent={Menu}
								classNames={{ dropdown: classes.modalSelectDropdown }}
								withinPortal
								error={
									createButtonClicked &&
									optionMap.required &&
									!optionMap.fieldState
										? 'This field is required'
										: ''
								}
								onChange={(value) => optionMap.setFieldState(value || '')}
							/>
						))}
					</Stack>
				</Modal.Body>
				<Divider />
				<Group position="right" spacing="sm" p="md">
					<Button variant="default" onClick={handleClose}>
						Cancel
					</Button>
					{isCreateLoading ? (
						<Loader size="sm" />
					) : (
						<Button variant="primary" onClick={handleCreate}>
							Create issue
						</Button>
					)}
				</Group>
			</Modal.Content>
		</Modal.Root>
	);
}
