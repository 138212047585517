import { useCallback, useMemo } from 'react';
import { useUpdateSecodaEntity, type IGlossaryTerm } from '../../api';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';
import { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';
import {
	useGenericActions,
	useGenericColumns,
} from '../../components/TableV2/SecodaEntity.hooks';
import { SynonymsRender } from '../../components/TableV2/render';
import { WithOnlyIdRequired } from '../../lib/typescript';
import { useParentAction } from '../TableEntityPage/Actions/hooks';

export const useColumns = (): ExtendedDataTableColumn<IGlossaryTerm>[] => {
	const genericColumns = useGenericColumns({});

	const { mutateAsync: update } = useUpdateSecodaEntity({});

	const handleChange = useCallback(
		(field: keyof IGlossaryTerm) =>
			(id: IGlossaryTerm['id']) =>
			(value: string | string[] | boolean) =>
				update(
					{
						data: {
							id,
							[field]: value,
						} as WithOnlyIdRequired<IGlossaryTerm>,
					},
					{
						onSuccess: () => {},
					}
				),
		[update]
	);

	const columns: ExtendedDataTableColumn<IGlossaryTerm>[] = useMemo(
		() => [
			...genericColumns.filter(
				(column) => column.accessor !== 'verified' && column.accessor !== 'pii'
			),
			{
				accessor: 'synonyms',
				title: 'Synonyms',
				navigate: false,
				esAccessor: 'synonyms',
				render: (record: IGlossaryTerm) => (
					<SynonymsRender
						synonyms={record.synonyms}
						onChange={handleChange('synonyms')(record.id)}
					/>
				),
			},
		],
		[genericColumns, handleChange]
	);

	return columns;
};

export const useActions = () => {
	const genericActions =
		useGenericActions() as ICommandListItem<IGlossaryTerm>[];
	const parentAction = useParentAction(resourceCatalogQueryKeyFactory.all());
	const actions = useMemo(
		() => [
			parentAction,
			...genericActions.filter((action) => action.id !== 'actions::pii'),
		],
		[genericActions, parentAction]
	);
	return actions;
};
