import { typography } from '@repo/theme/primitives';

export const TITLE_ORDER_TO_SIZE_MAP: Record<
	number,
	keyof typeof typography.title
> = {
	1: 'xl',
	2: 'lg',
	3: 'md',
	4: 'sm',
	5: 'xs',
	6: 'xxs',
};
