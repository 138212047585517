import {
	useQuestion,
	useQuestionReply,
	useUpdateQuestion,
	useUpdateQuestionReply,
} from '.';
import { updateReactionArray } from '../../../components/InlineEmojiPicker/utils';

export function useToggleQuestionReaction(
	questionId: string,
	userId: string,
	type: 'question' | 'reply'
) {
	const useQuestionContext = useQuestion({
		id: questionId,
		options: {
			enabled: type === 'question',
		},
	});
	const useQuestionReplyContext = useQuestionReply({
		id: questionId,
		options: { enabled: type === 'reply' },
	});

	const useUpdateQuestionContext = useUpdateQuestion({});

	const useUpdateQuestionReplyContext = useUpdateQuestionReply({});

	const mutateAsync =
		type === 'question'
			? useUpdateQuestionContext.mutateAsync
			: useUpdateQuestionReplyContext.mutateAsync;
	const data =
		type === 'question'
			? useQuestionContext.data
			: useQuestionReplyContext.data;

	const toggleReaction = async (emoji: string) => {
		const existingReactions = data?.reactions || [];
		const reactions = updateReactionArray(existingReactions, emoji, userId);

		return mutateAsync({
			data: { id: questionId, reactions },
		});
	};

	return { toggleReaction };
}
