import { useMemo } from 'react';
import type { IMetric } from '../../api';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';
import {
	useGenericActions,
	useGenericColumns,
} from '../../components/TableV2/SecodaEntity.hooks';

export const useColumns = (): ExtendedDataTableColumn<IMetric>[] => {
	const genericColumns = useGenericColumns({});

	const columns: ExtendedDataTableColumn<IMetric>[] = useMemo(
		() => [
			...genericColumns.filter((column) => column.accessor !== 'verified'),
		],
		[genericColumns]
	);

	return columns;
};

export const useActions = () => {
	const genericActions = useGenericActions() as ICommandListItem<IMetric>[];

	const actions = useMemo(() => [...genericActions], [genericActions]);
	return actions;
};
