import { createContext, ReactNode, useContext } from 'react';

interface NavBarContextType {
	setLeftNode: (node: ReactNode) => void;
	setRightNode: (node: ReactNode) => void;
	leftNode: ReactNode | null;
	rightNode: ReactNode | null;
}

export const NavBarContext = createContext<NavBarContextType | undefined>(
	undefined
);

export const useNavBar = () => {
	const context = useContext(NavBarContext);
	if (!context) {
		return {
			setLeftNode: () => {},
			setRightNode: () => {},
			leftNode: null,
			rightNode: null,
		};
	}
	return context;
};
