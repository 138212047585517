import { IconNames } from '@repo/foundations';
import type { ColorNames } from '@repo/theme/utils';

interface Config {
	icon: IconNames;
	color: ColorNames;
	text: ColorNames;
}

const photoConfig: Config = {
	icon: 'photo',
	color: 'fill/avatar/three',
	text: 'text/avatar/three-on-fill',
};
const documentConfig: Config = {
	icon: 'fileText',
	color: 'fill/avatar/one',
	text: 'text/avatar/one-on-fill',
};
const zipConfig: Config = {
	icon: 'fileZip',
	color: 'fill/avatar/five',
	text: 'text/avatar/five-on-fill',
};
const tableConfig: Config = {
	icon: 'table',
	color: 'fill/avatar/two',
	text: 'text/avatar/two-on-fill',
};
const codeConfig: Config = {
	icon: 'code',
	color: 'fill/avatar/four',
	text: 'text/avatar/four-on-fill',
};
const defaultConfig: Config = {
	icon: 'paperclip',
	color: 'fill/avatar/six',
	text: 'icon/primary/default',
};

const FILE_EXTENSION_CONFIG: Record<string, Config> = {
	png: photoConfig,
	jpg: photoConfig,
	jpeg: photoConfig,
	gif: photoConfig,
	file: defaultConfig,
	zip: zipConfig,
	pdf: documentConfig,
	rtf: documentConfig,
	txt: documentConfig,
	sql: codeConfig,
	csv: tableConfig,
	xlsx: tableConfig,
	xls: tableConfig,
} as const;

export function getFileExtensionConfig(extension: string): Config {
	return FILE_EXTENSION_CONFIG?.[extension] ?? defaultConfig;
}
