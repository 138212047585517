/* eslint-disable consistent-return */
import { useMatch } from 'react-router';
import type { EntityType } from '../../lib/types';
import { ENTITY_TO_URL_PREFIX_MAP } from '../navigationUtils';
import { uuidRegex } from '../shared.utils';

export const useEntityDetailPageMatch = (
	allowedEntityTypes: EntityType[] = Object.keys(
		ENTITY_TO_URL_PREFIX_MAP
	) as EntityType[]
) => {
	const match = useMatch('/:pagePrefix/:id');
	const pagePrefixes = allowedEntityTypes.map(
		(entityType) => ENTITY_TO_URL_PREFIX_MAP[entityType] || ''
	);

	const uuidRegexMatch = match?.params.id?.match(uuidRegex);
	if (
		pagePrefixes.includes(match?.params.pagePrefix || '') &&
		match &&
		uuidRegexMatch
	) {
		return {
			pagePrefix: match.params.pagePrefix!,
			titleAndUUID: match.params.id!, // Might include title
			id: uuidRegexMatch[0] as string,
		};
	}

	return undefined;
};

/**
 * A hook to get the current entity id from the url
 */
export const useCurrentEntityId = (
	allowedEntityTypes: EntityType[] = Object.keys(
		ENTITY_TO_URL_PREFIX_MAP
	) as EntityType[]
): string | undefined => {
	const pageMatch = useEntityDetailPageMatch(allowedEntityTypes);
	if (pageMatch) {
		return pageMatch.id;
	}
};
