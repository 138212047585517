import { filter } from 'lodash-es';
import { useMemo } from 'react';

import { IUserGroup } from '../../../api';
import {
	BoldTextRender,
	ReadOnlyOwnersIdsRender,
} from '../../../components/TableV2/render';
import { useGenericColumns } from '../../../components/TableV2/SecodaEntity.hooks';
import { ExtendedDataTableColumn } from '../../../components/TableV2/types';

export function useColumns<
	T extends IUserGroup,
>(): ExtendedDataTableColumn<T>[] {
	const genericColumns = useGenericColumns({}) as ExtendedDataTableColumn<T>[];

	const columns = useMemo(
		() => [
			{
				accessor: 'name',
				title: 'Name',
				width: 150,
				render: (record) => <BoldTextRender record={record} field={'name'} />,
			},
			{
				accessor: 'users',
				title: 'Users',
				render: (record) => (
					<ReadOnlyOwnersIdsRender accessor="users" record={record} />
				),
				width: 200,
			},

			...filter(genericColumns, (column) =>
				['updated_at'].includes(column.accessor)
			),
		],
		[genericColumns]
	);

	return columns;
}
