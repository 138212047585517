import Dagre from '@dagrejs/dagre';
import type { Edge, Node } from 'reactflow';
import { MarkerType, Position } from 'reactflow';

import { pickColor } from '@repo/common/utils';
import { typography } from '@repo/theme/primitives';
import { random } from 'lodash-es';
import { transparentize } from 'polished';
import 'reactflow/dist/style.css';
import type { IInternalIntegrationStatus } from '../../../api/types/models/internalIntegrationStatus';

export type LineageSummaryRow = {
	from_integration_id: string;
	to_integration_id: string;
	from_native_type: string;
	to_native_type: string;
	count: number;
};
export const adjustLayout = (nodes: Node[], edges: Edge[]): Node[] => {
	if (nodes.length === 0) {
		return [];
	}

	const graph = new Dagre.graphlib.Graph({
		compound: true,
	}).setDefaultEdgeLabel(() => ({}));

	graph.setGraph({
		rankdir: 'LR',
		nodesep: 40,
		edgesep: 36,
		ranksep: 38,
	});

	nodes.forEach((node) => {
		graph.setNode(node.id, {
			width: 300,
			height: 80,
		});
		if (node.parentNode) {
			graph.setParent(node.id, node.parentNode);
		}
	});

	edges.forEach((edge) => {
		graph.setEdge(edge.source, edge.target);
	});

	Dagre.layout(graph);

	return nodes.map((node) => {
		const { x, y, width, height } = graph.node(node.id);
		const parentNode = node.parentNode ? graph.node(node.parentNode) : null;

		return {
			...node,
			position: {
				x: parentNode
					? x - parentNode.x + parentNode.width / 2 - width / 2
					: x - width / 2,
				y: parentNode
					? y - parentNode.y + parentNode.height / 2 - height / 2 + 40
					: y - height / 2,
			},
			style: {
				...node.style,
				width: parentNode ? width : width + 20,
				height: parentNode ? height : height + 20,
			},
		} as Node;
	});
};

export const integrationNode = (
	integration: IInternalIntegrationStatus
): Node => ({
	id: integration.integration_id,
	data: {
		label: integration.integration_name,
	},
	position: { x: random(1, 400), y: random(1, 400) },
	style: {
		backgroundColor: transparentize(
			0.8,
			pickColor(integration.integration_id, 1)
		),
		fontSize: 20,
		fontWeight: typography.weight.bold,
		border: '2px dashed black',
	},
});
export const integrationEntityTypeNode = (
	integration: IInternalIntegrationStatus,
	entityType: string
): Node => {
	const id = `${integration.integration_id}-${entityType}`;
	return {
		id,
		data: {
			integrationId: integration.integration_id,
			label: `${integration.integration_name} - ${entityType}`,
		},
		style: {
			backgroundColor: pickColor(integration.integration_id, 1),
		},
		position: { x: random(1, 300), y: random(1, 300) },
		className: 'light',
		sourcePosition: Position.Right,
		targetPosition: Position.Left,
		parentNode: integration.integration_id,
		extent: 'parent',
	};
};

export const lineageEdge = (row: LineageSummaryRow): Edge => {
	const edgeId = `${row.from_integration_id}-${row.from_native_type}-${row.to_integration_id}-${row.to_native_type}`;
	const fromId = `${row.from_integration_id}-${row.from_native_type}`;
	const toId = `${row.to_integration_id}-${row.to_native_type}`;
	let edge: Edge = {
		id: edgeId,
		source: fromId,
		label: `${row.count}`,
		target: toId,
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 20,
			height: 20,
			color: '#FF0072',
		},
		labelStyle: {
			fontSize: 16,
		},
		style: {
			strokeWidth: 1,
			stroke: '#FF0072',
		},
	};

	if (fromId === toId) {
		edge.type = 'selfconnecting';
		edge = {
			...edge,
			sourcePosition: Position.Right,
			targetPosition: Position.Left,
		} as Edge;
	}

	return edge;
};
export const computeInitialNodesAndEdges = ({
	integrationStatuses,
	summaryRows,
}: {
	summaryRows: LineageSummaryRow[];
	integrationStatuses: IInternalIntegrationStatus[];
}) => {
	const integrationById: Record<string, IInternalIntegrationStatus> = {};
	integrationStatuses.forEach((s) => {
		integrationById[s.integration_id] = s;
	});

	const edges: Edge[] = [];
	const integrationNodes: Node[] = [];
	const nativeTypeNodes: Node[] = [];
	const marked: Record<string, boolean> = {};
	summaryRows.forEach((row) => {
		if (
			row.from_integration_id in integrationById &&
			row.to_integration_id in integrationById
		) {
			if (!marked[row.from_integration_id]) {
				marked[row.from_integration_id] = true;
				integrationNodes.push(
					integrationNode(integrationById[row.from_integration_id])
				);
			}
			if (!marked[row.to_integration_id]) {
				marked[row.to_integration_id] = true;
				integrationNodes.push(
					integrationNode(integrationById[row.to_integration_id])
				);
			}

			const fromId = `${row.from_integration_id}-${row.from_native_type}`;
			const toId = `${row.to_integration_id}-${row.to_native_type}`;
			if (!marked[fromId]) {
				marked[fromId] = true;
				nativeTypeNodes.push(
					integrationEntityTypeNode(
						integrationById[row.from_integration_id],
						row.from_native_type
					)
				);
			}

			if (!marked[toId]) {
				marked[toId] = true;
				nativeTypeNodes.push(
					integrationEntityTypeNode(
						integrationById[row.to_integration_id],
						row.to_native_type
					)
				);
			}

			edges.push(lineageEdge(row));
		}
	});

	const nodes = adjustLayout([...integrationNodes, ...nativeTypeNodes], edges);

	return {
		nodes,
		edges,
	};
};
