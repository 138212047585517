import { createStyles, Global, Modal } from '@mantine/core';
import { Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { usePreventNavigation } from '../../hooks/usePreventNavigation';
import { EntityType } from '../../lib/types';
import { openConfirmModal } from '../ModalManager';
import EntityModalFooter from './Footer/EntityModalFooter';
import EntityModalHeader from './Header/EntityModalHeader';
import EntityModalMetadata from './Metadata/EntityModalMetadata';
import { MetadataHeader } from './Metadata/MetadataHeader';
import { entityModalStore } from './store';

const useStyles = createStyles({
	modal: {
		'.mantine-Modal-header': {
			marginBottom: 0,
		},
	},
});

function EntityModal() {
	const { classes, theme } = useStyles();
	const { enable, disable } = usePreventNavigation();

	const { opened, description, template, title } = entityModalStore;

	const templateDefinition =
		template?.entity_type_template === EntityType.dictionary_term
			? template?.description
			: template?.definition;

	const shouldPreventClosing =
		opened &&
		(description?.length > 4 || title?.length > 4) &&
		(!template || description !== templateDefinition);

	useEffect(() => {
		if (shouldPreventClosing) {
			enable();
		} else {
			disable();
		}
	}, [disable, enable, shouldPreventClosing]);

	const handleOnClose = () => {
		if (shouldPreventClosing) {
			openConfirmModal({
				title: 'You have unsaved changes',
				children: (
					<Text size="sm">
						You'll lose your changes if you close this dialog. Are you sure you
						wish to close?
					</Text>
				),
				labels: { confirm: 'Confirm', cancel: 'Cancel' },
				onConfirm: () => entityModalStore.reset(),
			});
		} else {
			entityModalStore.reset();
		}
	};

	return (
		<Modal
			className={classes.modal}
			opened={entityModalStore.opened}
			onClose={handleOnClose}
			title={<MetadataHeader />}
			size={theme.other.width.xl}
			radius={theme.radius.lg}
			closeButtonProps={{
				'aria-label': 'Close modal',
				sx: {
					top: theme.spacing.sm,
					right: theme.spacing.sm,
					position: 'absolute',
				},
			}}
			centered
		>
			<Global
				styles={() => ({
					'.heading-actions, .block-menu-trigger': {
						display: 'none !important',
					},
				})}
			/>
			<EntityModalHeader />
			<EntityModalMetadata type={entityModalStore.type} />
			<EntityModalFooter type={entityModalStore.type} />
		</Modal>
	);
}

export default observer(EntityModal);
