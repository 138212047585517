import { Fragment, Node, NodeType } from 'prosemirror-model';
import { Command, EditorState, TextSelection } from 'prosemirror-state';
import {
	addColumn,
	addRow,
	CellSelection,
	isInTable,
	selectedRect,
	tableNodeTypes,
	toggleHeader,
} from 'prosemirror-tables';
import chainTransactions from '../lib/chainTransactions';
import { getCellsInColumn, isHeaderEnabled } from '../queries/table';
import collapseSelection from './collapseSelection';

const createCell = (
	cellType: NodeType,
	cellContent: Fragment | Node | readonly Node[] | null | undefined
) =>
	cellContent
		? cellType.createChecked(null, cellContent)
		: cellType.createAndFill();

function createTableInner(
	state: EditorState,
	rowsCount: number,
	colsCount: number,
	cellContent?: Node,
	withHeaderRow = true
) {
	const types = tableNodeTypes(state.schema);
	const headerCells: Node[] = [];
	const cells: Node[] = [];
	const rows: Node[] = [];

	for (let index = 0; index < colsCount; index += 1) {
		const cell = createCell(types.cell, cellContent);

		if (cell) {
			cells.push(cell);
		}

		if (withHeaderRow) {
			const headerCell = createCell(types.header_cell, cellContent);

			if (headerCell) {
				headerCells.push(headerCell);
			}
		}
	}

	for (let index = 0; index < rowsCount; index += 1) {
		rows.push(
			types.row.createChecked(
				null,
				withHeaderRow && index === 0 ? headerCells : cells
			)
		);
	}

	return types.table.createChecked(null, rows);
}

export function createTable({
	rowsCount,
	colsCount,
}: {
	rowsCount: number;
	colsCount: number;
}): Command {
	return (state, dispatch) => {
		if (dispatch) {
			const offset = state.tr.selection.anchor + 1;
			const nodes = createTableInner(state, rowsCount, colsCount);
			const tr = state.tr.replaceSelectionWith(nodes).scrollIntoView();
			const resolvedPos = tr.doc.resolve(offset);
			tr.setSelection(TextSelection.near(resolvedPos));
			dispatch(tr);
		}
		return true;
	};
}

export function addRowAndMoveSelection({
	index,
}: {
	index?: number;
} = {}): Command {
	return (state, dispatch, view) => {
		if (!isInTable(state)) {
			return false;
		}

		const rect = selectedRect(state);
		const cells = getCellsInColumn(0)(state);

		// If the cursor is at the beginning of the first column then insert row
		// above instead of below.
		if (rect.left === 0 && view?.endOfTextblock('backward', state)) {
			const indexBefore = index !== undefined ? index - 1 : rect.top;
			dispatch?.(addRow(state.tr, rect, indexBefore));
			return true;
		}

		const indexAfter = index !== undefined ? index + 1 : rect.bottom;
		const tr = addRow(state.tr, rect, indexAfter);

		// Special case when adding row to the end of the table as the calculated
		// rect does not include the row that we just added.
		if (indexAfter !== rect.map.height) {
			const pos = cells[Math.min(cells.length - 1, indexAfter)];
			const $pos = tr.doc.resolve(pos);
			dispatch?.(tr.setSelection(TextSelection.near($pos)));
		} else {
			const $pos = tr.doc.resolve(rect.tableStart + rect.table.nodeSize);
			dispatch?.(tr.setSelection(TextSelection.near($pos)));
		}

		return true;
	};
}

/**
 * Set column attributes. Passed attributes will be merged with existing.
 *
 * @param attrs The attributes to set
 * @returns The command
 */
export function setColumnAttr({
	index,
	alignment,
}: {
	index: number;
	alignment: string;
}): Command {
	return (state, dispatch) => {
		if (dispatch) {
			const cells = getCellsInColumn(index)(state) || [];
			let transaction = state.tr;
			cells.forEach((pos) => {
				const node = state.doc.nodeAt(pos);
				transaction = transaction.setNodeMarkup(pos, undefined, {
					...node?.attrs,
					alignment,
				});
			});
			dispatch(transaction);
		}
		return true;
	};
}

/**
 * A command that safely adds a row taking into account any existing heading column at the top of
 * the table, and preventing it moving "into" the table.
 *
 * @param index The index to add the row at, if undefined the current selection is used
 * @returns The command
 */
export function addRowBefore({ index }: { index?: number }): Command {
	return (state, dispatch) => {
		if (!isInTable(state)) {
			return false;
		}

		const rect = selectedRect(state);
		const isHeaderRowEnabled = isHeaderEnabled(state, 'row', rect);
		const position = index !== undefined ? index : rect.left;

		// Special case when adding row to the beginning of the table to ensure the header does not
		// move inwards.
		const headerSpecialCase = position === 0 && isHeaderRowEnabled;

		chainTransactions(
			headerSpecialCase ? toggleHeader('row') : undefined,
			(s, d) => !!d?.(addRow(s.tr, rect, position)),
			headerSpecialCase ? toggleHeader('row') : undefined,
			collapseSelection()
		)(state, dispatch);

		return true;
	};
}

/**
 * A command that safely adds a column taking into account any existing heading column on the far
 * left of the table, and preventing it moving "into" the table.
 *
 * @param index The index to add the column at, if undefined the current selection is used
 * @returns The command
 */
export function addColumnBefore({ index }: { index?: number }): Command {
	return (state, dispatch) => {
		if (!isInTable(state)) {
			return false;
		}

		const rect = selectedRect(state);
		const isHeaderColumnEnabled = isHeaderEnabled(state, 'column', rect);
		const position = index !== undefined ? index : rect.left;

		// Special case when adding column to the beginning of the table to ensure the header does not
		// move inwards.
		const headerSpecialCase = position === 0 && isHeaderColumnEnabled;

		chainTransactions(
			headerSpecialCase ? toggleHeader('column') : undefined,
			(s, d) => !!d?.(addColumn(s.tr, rect, position)),
			headerSpecialCase ? toggleHeader('column') : undefined,
			collapseSelection()
		)(state, dispatch);

		return true;
	};
}

export function selectRow(index: number, expand = false): Command {
	return (state: EditorState, dispatch): boolean => {
		if (dispatch) {
			const rect = selectedRect(state);
			const pos = rect.map.positionAt(index, 0, rect.table);
			const $pos = state.doc.resolve(rect.tableStart + pos);
			const rowSelection =
				expand && state.selection instanceof CellSelection
					? CellSelection.rowSelection(state.selection.$anchorCell, $pos)
					: CellSelection.rowSelection($pos);
			dispatch(state.tr.setSelection(rowSelection));
			return true;
		}
		return false;
	};
}

export function selectColumn(index: number, expand = false): Command {
	return (state, dispatch): boolean => {
		if (dispatch) {
			const rect = selectedRect(state);
			const pos = rect.map.positionAt(0, index, rect.table);
			const $pos = state.doc.resolve(rect.tableStart + pos);
			const colSelection =
				expand && state.selection instanceof CellSelection
					? CellSelection.colSelection(state.selection.$anchorCell, $pos)
					: CellSelection.colSelection($pos);
			dispatch(state.tr.setSelection(colSelection));
			return true;
		}
		return false;
	};
}

export function selectTable(): Command {
	return (state, dispatch): boolean => {
		if (dispatch) {
			const rect = selectedRect(state);
			const { map } = rect.map;
			const $anchor = state.doc.resolve(rect.tableStart + map[0]);
			const $head = state.doc.resolve(rect.tableStart + map[map.length - 1]);
			const tableSelection = new CellSelection($anchor, $head);
			dispatch(state.tr.setSelection(tableSelection));
			return true;
		}
		return false;
	};
}
