import {
	createStyles,
	// eslint-disable-next-line no-restricted-imports
	MultiSelect as MantineMultiSelect,
	MultiSelectProps as MantineMultiSelectProps,
	SelectItem,
	Stack,
} from '@mantine/core';
import { useId } from '@mantine/hooks';
import { ColorNames } from '@repo/theme/utils';
import { forwardRef, ReactNode } from 'react';
import { TextInputError, TextInputHelp, TextInputLabel } from '../TextInput';
import ItemComponent from './ItemComponent';
import ValueComponent from './ValueComponent';

type MultiSelectVariant = 'default' | 'tertiary';
type MultiSelectSizes = 'sm' | 'md';

type MultiSelectStylesParams = Pick<
	MantineMultiSelectProps,
	'variant' | 'error'
>;

const useStyles = createStyles(
	(theme, { variant, error }: MultiSelectStylesParams) => {
		// Border
		let borderStyle: 'solid' | 'none' = 'solid';
		let borderWidth: number = 0.5;

		let borderColor: ColorNames = 'border/input/default';
		let focusBorderColor: ColorNames = 'border/input/active';
		let hoverBorderColor: ColorNames = 'border/input/hover';

		// Background
		let backgroundColor: ColorNames = 'surface/input/default';
		let backgroundColorDisabled: ColorNames = 'surface/primary/disabled';

		// === Tertiary don't have border
		if (variant === 'tertiary') {
			borderStyle = 'none';
			borderWidth = 0;
		}

		// === Error changes border and background colors
		if (error) {
			borderColor = 'border/critical-secondary/default';
			focusBorderColor = 'border/critical-secondary/default';
			hoverBorderColor = 'border/critical-secondary/default';

			backgroundColor = 'surface/critical/default';
			backgroundColorDisabled = 'surface/critical/default';
		}

		return {
			wrapper: {
				'&[aria-expanded=true]': {
					'.mantine-Input-wrapper': {
						overflow: 'unset !important',
						'.mantine-MultiSelect-input': {
							borderStyle,
							borderWidth,
							borderColor: theme.other.getColor(borderColor),
							backgroundColor: theme.other.getColor(backgroundColor),
							boxShadow: `0px 0px 0px 1px white, 0px 0px 0px 3px ${theme.other.getColor('border/emphasis/default')}`,
						},
					},
				},
			},
			input: {
				backgroundColor: theme.other.getColor(backgroundColor),
				borderRadius: theme.radius.sm,
				borderStyle: borderStyle,
				borderWidth: borderWidth,
				borderColor: theme.other.getColor(borderColor),
				'&:hover': {
					borderColor: theme.other.getColor(hoverBorderColor),
				},
				'&:focus, &:active': {
					borderColor: theme.other.getColor(focusBorderColor),
					boxShadow: theme.shadows.md,
				},
				'&:disabled': {
					backgroundColor: theme.other.getColor(backgroundColorDisabled),
					borderWidth: 0,
				},
			},
			required: {
				visibility: 'hidden',
			},
			values: {
				margin: 0,
				display: 'flex',
				flexWrap: 'wrap',
				gap: theme.spacing['2xs'],
				paddingTop: theme.spacing['2xs'],
				paddingBottom: theme.spacing['2xs'],
				paddingRight: theme.spacing.xs,
			},
			item: {
				paddingTop: theme.spacing['2xs'],
				paddingBottom: theme.spacing['2xs'],
				paddingLeft: theme.spacing.xs,
			},
			separatorLabel: {
				color: theme.other.getColor('text/secondary/default'),
				fontSize: theme.fontSizes.sm,
				fontWeight: theme.other.typography.weight.semibold,

				'&::after': {
					flex: 0,
				},
			},
			searchInput: {
				height: 20,
				fontSize: theme.fontSizes.sm,
				lineHeight: '20px',
				color: theme.other.getColor('text/primary/default'),
			},
		};
	}
);

export type MultiSelectProps<T extends SelectItem> = {
	// Helper props
	id?: string;
	variant?: MultiSelectVariant;
	size?: MultiSelectSizes;
	label?: string;
	help?: string | React.ReactNode;
	error?: string;
	optional?: boolean;

	// Data
	data: T[];
	value: string[];
	setValue: (xs: string[]) => void;

	// Rendering
	renderIcon?: (item: T) => ReactNode;
	renderLabel?: (item: T) => ReactNode;
	showRightSection?: boolean;
} & Omit<MantineMultiSelectProps, 'filter' | 'data'>;

function MultiSelect<T extends SelectItem = SelectItem>(
	{
		id,
		variant = 'default',
		size,
		label,
		help,
		error,
		optional = false,

		data,
		value,
		setValue,

		renderIcon,
		renderLabel = (item: T) => item.label,
		nothingFound,
		showRightSection = true,
		...rest
	}: MultiSelectProps<T>,
	ref: React.ForwardedRef<HTMLInputElement>
) {
	const uuid = useId(id);
	const { classes, theme } = useStyles({
		variant: variant,
		error: error,
	});

	return (
		<Stack w="100%" spacing={theme.spacing['3xs']}>
			<TextInputLabel label={label} optional={optional} inputId={uuid} />
			<MantineMultiSelect
				id={uuid}
				ref={ref}
				withinPortal
				dropdownPosition="bottom"
				classNames={classes}
				data={data}
				searchable
				itemComponent={forwardRef<HTMLDivElement, T>((props, ref) => (
					<ItemComponent
						ref={ref}
						label={renderLabel(props as T)}
						icon={renderIcon?.(props as T)}
						{...props}
					/>
				))}
				value={value}
				onChange={setValue}
				valueComponent={(props) => (
					<ValueComponent
						{...props}
						label={renderLabel(props)}
						icon={renderIcon?.(props)}
					/>
				)}
				nothingFound={nothingFound || 'Nothing found'}
				{...(showRightSection
					? {}
					: { rightSectionWidth: 0, rightSection: <div /> })}
				{...rest}
			/>
			<TextInputHelp help={help} error={error} />
			<TextInputError error={error} />
		</Stack>
	);
}

MultiSelect.displayName = 'MultiSelect';

export { MultiSelect };
