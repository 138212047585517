import { useQuery } from '@tanstack/react-query';
import { metricsQueryKeyFactory } from '.';
import { createMockableHook } from '../../../utils/createMockableHook';
import { apiClient, getEndpoints } from '../../common';
import { IMetric } from '../../types';

function useGetOrCreateMetricInternal({ id, ...data }: Partial<IMetric>) {
	return useQuery(
		[...metricsQueryKeyFactory.all(), 'get_or_create', id],
		async () => {
			const url = getEndpoints(metricsQueryKeyFactory.namespace).root();
			const { data: metric } = await apiClient.post<IMetric>(url, {
				id,
				...data,
			});
			return metric;
		},

		{ suspense: true }
	);
}

export const [useGetOrCreateMetric, MockUseGetOrCreateMetric] =
	createMockableHook(useGetOrCreateMetricInternal);
