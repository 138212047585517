import { useMemo } from 'react';
import { IJobTask } from '../../../api';
import {
	BoldTextRender,
	DurationRender,
	LastUpdatedRender,
	TextRender,
} from '../../../components/TableV2/render';
import { ExtendedDataTableColumn } from '../../../components/TableV2/types';

export const useColumns = (): ExtendedDataTableColumn<IJobTask>[] => {
	const columns: ExtendedDataTableColumn<IJobTask>[] = useMemo(
		() => [
			{
				title: 'Name',
				accessor: 'title',
				render: (record) => (
					<BoldTextRender<IJobTask> record={record} field={'title'} />
				),
			},
			{
				title: 'Description',
				accessor: 'description',
				render: (record) => (
					<TextRender<IJobTask> record={record} field={'description'} />
				),
			},
			{
				title: 'Started at',
				accessor: 'start_timestamp',
				render: (record) => (
					<LastUpdatedRender<IJobTask>
						record={record}
						field={'start_timestamp'}
					/>
				),
			},
			{
				title: 'Finished at',
				accessor: 'finish_timestamp',
				render: (record) => (
					<LastUpdatedRender<IJobTask>
						record={record}
						field={'finish_timestamp'}
					/>
				),
			},
			{
				title: 'Duration',
				accessor: 'duration',
				render: (record) => (
					<DurationRender<IJobTask>
						record={record}
						field1="start_timestamp"
						field2="finish_timestamp"
					/>
				),
			},
		],
		[]
	);

	return columns;
};
