import { Alert, Box, createStyles, Divider } from '@mantine/core';
import { Button } from '@repo/foundations';
import { useDebounceFn } from 'ahooks';
import { startCase } from 'lodash-es';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { queryClient, useAuthUser } from '../../api';
import {
	templatesQueryKeyFactory,
	useTemplate,
	useUpdateTemplate,
} from '../../api/hooks/templates';

import Documentation from '../../components/Documentation';
import EntityPageLayout, {
	EntityPageDescription,
} from '../../components/EntityPageLayout';
import type { SidebarEntityKeys } from '../../components/EntityPageLayout/types';
import { EntityType } from '../../lib/types';
import { trackEvent } from '../../utils/analytics';
import { resolveBaseEntityPath } from '../../utils/navigationUtils';
import type { DjangoValueType } from './types';

const ENTITY_TYPES_WITH_DESCRIPTION = [
	EntityType.dictionary_term,
	EntityType.glossary,
];

export interface ITemplatePageProps {
	id?: string;
}

const useStyles = createStyles((theme) => ({
	contentWrapper: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
	},
	content: {
		maxWidth: `${theme.other.width.lg}px`,
		padding: 0,
	},
	wrapper: {
		marginTop: '24px',
	},
	banner: {
		margin: 0,
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing.lg,
		height: `calc(${theme.spacing.lg} * 3)`,
		borderRadius: 0,
	},
}));

function TemplatePage({ id: propsId }: ITemplatePageProps) {
	const navigate = useNavigate();
	const { id: paramsId } = useParams();
	const id = propsId || paramsId;

	let [searchParams] = useSearchParams();
	const originTeamId = searchParams.get('teamId');

	const { classes } = useStyles();

	const { data: template } = useTemplate({
		id: id!,
	});

	const { user, workspace } = useAuthUser();

	const { mutateAsync } = useUpdateTemplate({});

	const { run: updateTemplate } = useDebounceFn(
		async (key: SidebarEntityKeys, value: DjangoValueType) => {
			await mutateAsync({
				data: {
					id: template!.id,
					[key]: value,
				},
			});

			queryClient.invalidateQueries(templatesQueryKeyFactory.list(1));
			queryClient.invalidateQueries(templatesQueryKeyFactory.byId(id!));

			trackEvent(
				'template/update',
				{
					id: template!.id,
				},
				user,
				workspace
			);
		},
		{ wait: 300 }
	);

	const handleDescriptionUpdate = useCallback(
		async (description: string) => {
			await updateTemplate('description', description);
		},
		[updateTemplate]
	);

	const handleOnClickBackButton = useCallback(() => {
		let url = resolveBaseEntityPath(
			template?.entity_type_template ?? EntityType.all
		);
		if (originTeamId) {
			url = `/teams/${originTeamId}${url}`;
		}
		navigate(url);
	}, [navigate, originTeamId, template?.entity_type_template]);

	if (!template) {
		return null;
	}

	return (
		<Box>
			<Alert className={classes.banner}>
				You are editing a template in{' '}
				<Button
					data-testid="template-page-banner-link"
					onClick={handleOnClickBackButton}
				>
					{startCase(template.entity_type_template.replace('_', ' '))}s
				</Button>
			</Alert>
			<EntityPageLayout
				name="Template"
				entity={template}
				updateEntity={updateTemplate}
				withActions={false}
				withSidebarToggles={false}
				withOwnerSelector
				withCollectionSelector
				withTagSelector
				withCustomPropertyEditors
				classNames={{
					contentWrapper: classes.contentWrapper,
					content: classes.content,
				}}
			>
				<Box className={classes.wrapper}>
					{ENTITY_TYPES_WITH_DESCRIPTION.includes(
						template.entity_type_template
					) && (
						<>
							<EntityPageDescription
								entityId={template.id}
								description={template?.description ?? ''}
								onChange={handleDescriptionUpdate}
								placeholder="Add description template..."
								withAi={false}
								dataTestId="rich-text-editor-description"
							/>
							<Divider />
						</>
					)}
					<Documentation
						entity={template}
						disableExtensions={['page', 'comment']}
						isTemplate
						dataTestId="rich-text-editor-definition"
					/>
				</Box>
			</EntityPageLayout>
		</Box>
	);
}

export default TemplatePage;
