import { createStyles, Group, Image } from '@mantine/core';
import { typography } from '@repo/theme/primitives';
import { Title } from '../../../../../../packages/foundations';
import { IIntegration } from '../../../api';
import { IntegrationSpec } from '../../../interfaces/IntegrationSpec';
import IntegrationLogo from '../../IntegrationLogo';

interface IntegrationTitleProps {
	integrationSpec: IntegrationSpec;
	integration?: IIntegration;
	title?: string;
	logoSize?: number;
	titleSize?: keyof typeof typography.title;
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		flexWrap: 'nowrap',
		alignItems: 'center',
		padding: `${theme.spacing['3xs']} ${theme.spacing.xs}`,
		gap: theme.spacing.xs,
	},
}));

export function IntegrationTitle({
	integrationSpec,
	integration,
	title,
	logoSize = 16,
	titleSize = 'sm',
}: IntegrationTitleProps) {
	const { classes } = useStyles();
	return (
		<Group className={classes.wrapper}>
			{integrationSpec.type === 'builtin' ? (
				<>
					<IntegrationLogo
						integrationType={integrationSpec.value.type}
						size={logoSize}
						integrationId={integration?.id}
					/>
					<Title size={titleSize}>
						{title ? title : (integration?.name ?? integrationSpec.value.name)}
					</Title>
				</>
			) : (
				<>
					<Image
						width={logoSize}
						height={logoSize}
						src={integrationSpec.value.versions[0]?.icon_url}
						fit="cover"
					/>
					<Title size="sm">
						{title ? title : integrationSpec.value.versions[0]?.name}
					</Title>
				</>
			)}
		</Group>
	);
}
