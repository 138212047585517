import { Box } from '@mantine/core';
import SingleSelector from '@repo/common/components/SingleSelector/SingleSelector';
import { useCallback, useMemo } from 'react';
import { useAuthUser } from '../../../api';
import {
	useExtendedUserList,
	useGuestUserList,
} from '../../../api/hooks/user/useExtendedUserList';
import type { User, UserGroup } from '../../../lib/models';
import { DjangoValueType } from '../../../pages/TemplatePage/types';
import { SidebarEntityKeys } from '../../EntityPageLayout/types';
import { getAssigneeAndGroupSelectorOptions } from '../../EntityPageLayout/utils';

interface AssigneeSelectorProps {
	onChange?: (key: string) => (value: string | string[]) => void;
	initialValue?: string;
}

export function AssigneeSelector({
	onChange,
	initialValue,
}: AssigneeSelectorProps) {
	const { isGuestUser } = useAuthUser();
	const { usersVisibleToGuests } = useGuestUserList({ suspense: false });
	const { activeUsers, userGroups } = useExtendedUserList();

	const handleEntityChange = useCallback(
		(key: SidebarEntityKeys) =>
			(value: DjangoValueType, saveRemotely = true) => {
				if (key === 'assigned_to')
					onChange?.('assignee')(value?.toString() ?? '');
				if (key === 'assigned_to_group')
					onChange?.('assignee_group')(value?.toString() ?? '');
			},
		[]
	);

	const visibleUsers = (isGuestUser
		? usersVisibleToGuests
		: activeUsers) as unknown as User[];

	const assignees = useMemo(
		() =>
			getAssigneeAndGroupSelectorOptions(
				visibleUsers,
				userGroups as unknown as UserGroup[],
				handleEntityChange
			),
		[visibleUsers, userGroups]
	);

	return (
		<Box maw={256}>
			<SingleSelector
				placeholder="Assignee"
				placeholderIconName="user"
				variant={initialValue ? 'default' : 'tertiary'}
				iconType="avatar"
				isViewerUser={false}
				options={assignees}
				initialSelected={initialValue}
				property="owner"
				searchable
			/>
		</Box>
	);
}
