import { setBlockType } from 'prosemirror-commands';
import type { Attrs, NodeType } from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import isNodeActive from '../queries/isNodeActive';
import { Dispatch } from '../types';

export default function toggleBlockType(
	type: NodeType,
	toggleType: NodeType,
	attrs: Attrs = {}
) {
	return (state: EditorState, dispatch?: Dispatch) => {
		const isActive = isNodeActive(type, attrs)(state);

		if (isActive) {
			return setBlockType(toggleType)(state, dispatch);
		}

		return setBlockType(type, attrs)(state, dispatch);
	};
}
