import { Accordion, Group } from '@mantine/core';
import type { PropsWithChildren, ReactNode } from 'react';
import { useAuthUser } from '../../../api';
import { trackEvent } from '../../../utils/analytics';

interface DataQualityBreakdownItemBodyProps {
	value: string;
	title: ReactNode;
	badge: ReactNode;
	disabled?: boolean;
}

export function DataQualityBreakdownItemBody({
	value,
	title,
	badge,
	children,
	disabled,
}: PropsWithChildren<DataQualityBreakdownItemBodyProps>) {
	const { workspace, user } = useAuthUser();

	return (
		<Accordion.Item
			value={value}
			onClick={() =>
				trackEvent(
					'dqs/suggestions/expand',
					{
						key: value,
						path: window.location.pathname.split('/')[1],
					},
					user,
					workspace
				)
			}
		>
			<Accordion.Control disabled={disabled}>
				<Group position="apart" spacing={0}>
					<Group spacing="xs">{title}</Group>
					{badge}
				</Group>
			</Accordion.Control>
			<Accordion.Panel>{children}</Accordion.Panel>
		</Accordion.Item>
	);
}
