// We ideally don't want to use Mantine `Button`s directly. This button uses a
// 'rightIcon` which isn't a prop for Mantine's unstyled Button and we deviate
// from some of the styles of our own `Button`.
// eslint-disable-next-line no-restricted-imports
import { Button, Group, createStyles } from '@mantine/core';
import type { ItemIconType } from '@repo/common/components/ItemIcon';
import MultiSelector from '@repo/common/components/MultiSelector/MultiSelector';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { Icon, type IconNames } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useAuthUser } from '../../../api';
import AutomationCardDatePicker from './AutomationCardDatePicker';
import AutomationCardSingleSelector from './AutomationCardSingleSelector';
import AutomationCardText from './AutomationCardText';
import { AutomationValueType } from './constants';

const useStyles = createStyles((theme) => ({
	buttonRoot: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		borderRadius: theme.radius.sm,
		padding: `${theme.spacing['4xs']} ${theme.spacing.xs} ${theme.spacing['4xs']} ${theme.spacing.xs}`,
		minHeight: '24px',
		border: 'none',
		background: theme.other.getColor('fill/emphasis-secondary/default'),
		color: theme.other.getColor('text/emphasis/default'),
		'&:hover': {
			border: 'none',
			background: theme.other.getColor('fill/emphasis-secondary/hover'),
			color: theme.other.getColor('text/emphasis/hover'),
		},
		'&:active': {
			border: 'none',
			background: theme.other.getColor('fill/emphasis-secondary/active'),
			color: theme.other.getColor('text/emphasis/active'),
		},
		width: 'auto',
		maxWidth: theme.other.space[90],
		height: 'fit-content',
	},
	selectButton: {
		display: 'flex',
		padding: `${theme.spacing['4xs']}, ${theme.spacing['2xs']}, ${theme.spacing['4xs']}, ${theme.spacing.xs}`,
		justifyContent: 'center',
		alignItems: 'center',
		background: theme.other.getColor('fill/transparent/default'),
		border: `1px solid ${theme.other.getColor('border/primary/default')}`,
		borderRadius: theme.radius.sm,
		minHeight: '24px',
	},
	buttonRightIcon: {
		marginLeft: 0,
		padding: theme.spacing['4xs'],
	},
	// Need to mimic a focused state because when the button is clicked, the actual
	// focus goes to the search input and the button loses it's focus
	highlightedButton: {
		borderRadius: theme.radius.sm,
		padding: `${theme.other.space['0.5']} ${theme.other.space[2]}`,
		border: `1px solid ${theme.other.getColor(
			'border/emphasis-secondary/hover'
		)}`,
		background: theme.other.getColor('fill/emphasis-secondary/hover'),
		color: theme.other.getColor('text/emphasis/hover'),
		minHeight: '24px',
	},
	menuDropdown: {
		minHeight: 'fit-content',
		width: '100%',
	},
	group: {
		gap: theme.spacing['3xs'],
		whiteSpace: 'normal',
		minHeight: '100%',
		overflowWrap: 'break-word',
	},
	buttonLabel: {
		padding: 0,
	},
}));

export interface MultiSelectedItem {
	value: string | boolean;
	label?: string;
	type?: string;
}

interface AutomationCardButtonProps {
	addFilter?: boolean;
	buttonLabel: string;
	buttonIconName?: IconNames;
	selectButtonLabel?: string;
	buttonOptions: SelectablePropertyItem[];
	onClick?: (value: string | boolean, label?: string) => void;
	handleMultiSelectorChange?: (items: MultiSelectedItem[]) => void;
	selectedValues?: string[] | boolean[];
	defaultOpened?: boolean;
	property?: string;
	isSelected?: boolean;
	iconType?: ItemIconType;
	type?: AutomationValueType;
	value?: string | boolean;
	useHighlight?: boolean;
	placeholder?: string;
}

function AutomationCardButton({
	addFilter = false,
	buttonLabel,
	buttonIconName,
	buttonOptions,
	defaultOpened = false,
	onClick,
	handleMultiSelectorChange,
	property,
	iconType = 'none',
	isSelected = true,
	type = AutomationValueType.DROPDOWN,
	value,
	selectButtonLabel,
	useHighlight = false,
	placeholder,
	selectedValues = [],
}: AutomationCardButtonProps) {
	const { classes } = useStyles();
	const [highlight, setHighlight] = useState(false);
	const { user } = useAuthUser();

	const buttonOnClick = () => {
		setHighlight(!highlight);
	};

	const onMultiSelectorChange = (values: (string | boolean)[]) => {
		if (handleMultiSelectorChange) {
			const items = values.map((val) => {
				const existingOption = buttonOptions.find(
					(option) => option.value === val
				) as any; // eslint-disable-line @typescript-eslint/no-explicit-any

				const label = existingOption?.label;
				const optionType = existingOption?.type;

				return { value: val, label, type: optionType };
			});

			handleMultiSelectorChange(items);
		}
	};

	const selectLabel = selectButtonLabel || 'Select';

	const targetButton = isSelected ? (
		<Button
			className={
				useHighlight && highlight
					? classes.highlightedButton
					: classes.buttonRoot
			}
			onClick={buttonOnClick}
		>
			<Group className={classes.group}>
				{buttonIconName && <Icon name={buttonIconName} />}
				{buttonLabel}
			</Group>
		</Button>
	) : (
		<Button
			className={classes.selectButton}
			rightIcon={<Icon name="chevronDown" />}
			classNames={{
				rightIcon: classes.buttonRightIcon,
			}}
		>
			{selectLabel}
		</Button>
	);

	if (type === AutomationValueType.DROPDOWN && onClick) {
		return (
			<AutomationCardSingleSelector
				targetButton={targetButton}
				buttonLabel={buttonLabel}
				addFilter={addFilter}
				buttonOptions={buttonOptions}
				onClick={onClick}
				defaultOpened={defaultOpened}
				property={property ?? ''}
				iconType={iconType}
				placeholder={placeholder}
				setHighlight={setHighlight}
			/>
		);
	}

	if (type === AutomationValueType.MULTI_SELECT_DROPDOWN) {
		return (
			<MultiSelector
				iconType="emoji"
				isViewerUser={false}
				options={buttonOptions}
				placeholder={placeholder}
				className={classes.buttonRoot}
				onChange={onMultiSelectorChange}
				initialSelected={selectedValues}
				permittedId={user.id}
				property={property ?? ''}
				isMenuItemBadge={false}
				w="auto"
			/>
		);
	}

	if (type === AutomationValueType.TEXT_INPUT && onClick) {
		return (
			<AutomationCardText
				value={value}
				onChange={onClick}
				targetButton={targetButton}
			/>
		);
	}

	if (type === AutomationValueType.DATE_INPUT && onClick) {
		return (
			<AutomationCardDatePicker
				value={value}
				onChange={onClick}
				targetButton={targetButton}
				defaultOpened={defaultOpened}
			/>
		);
	}

	throw new Error('Invalid value type was provided');
}

export default observer(AutomationCardButton);
