import { makeAutoObservable } from 'mobx';
import { Monitor } from '../../../api';
import {
	ScheduleCadence,
	ScheduleConfig,
} from '../../../api/types/models/schedule';
import { PartialWithoutId } from '../../../lib/typescript';
import { MONITOR_SPECS } from '../monitors';
import { isAnalyticsMonitor, MonitorSpec } from '../types';

export class AddMonitorStore {
	// 1. General
	monitorSpec: MonitorSpec = MONITOR_SPECS[0];

	// 2. Resource section
	integration: string | undefined = undefined;
	integrationError: string = '';

	// - Target based
	targetEntities: string[] = [];
	targetEntitiesError: string = '';

	// - Custom query
	monitorName: string = '';
	querySelect: string = '';

	monitorNameError: string = '';
	querySelectError: string = '';

	// Query where
	queryWhereVisible: boolean = false;
	queryWhere: string = '';

	// 3. Configure section
	schedule: ScheduleConfig = {
		cadence: ScheduleCadence.DAILY,
		frequency: 1,
		hour: 0,
		day: 0,
	};

	threshold: 'automatic' | 'manual' = 'automatic';
	autoSensitivity: number = 5;
	minSensitivity: number | undefined = undefined;
	maxSensitivity: number | undefined = undefined;

	minSensitivityError: string = '';
	maxSensitivityError: string = '';

	// 4. Manage section
	owners: string[] = [];
	subscribers: string[] = [];
	emailSubscribers: string[] = [];
	tempEmailSubscribers: string[] = [];

	ownersError: string = '';

	constructor(
		public initialMonitorSpec: MonitorSpec,
		public currentUser: string,
		public initialTable?: string,
		public initialIntegration?: string
	) {
		this.monitorSpec = initialMonitorSpec;
		this.owners = [currentUser];
		this.integration = initialIntegration;
		this.initialTable = initialTable;
		makeAutoObservable(this);
	}

	// 1. General
	setTargetEntities = (ids: string[]) => {
		this.targetEntities = ids;
		if (ids.length > 0) {
			this.targetEntitiesError = '';
		}
	};

	// 2. Resource selection
	setIntegration = (integration: string) => {
		this.integration = integration;
		this.integrationError = '';
		this.targetEntities = [];
	};

	// - Custom query
	setMonitorName = (name: string) => {
		this.monitorName = name;
		this.monitorNameError = '';
	};
	setQuerySelect = (query: string) => {
		this.querySelect = query;
		this.querySelectError = '';
	};

	// Query where
	setQueryWhereVisible = (visible: boolean) => {
		this.queryWhereVisible = visible;
	};

	setQueryWhere = (query: string) => {
		this.queryWhere = query;
	};

	// 3. Configure section
	setSchedule = (schedule: ScheduleConfig) => {
		this.schedule = schedule;
	};
	setThreshold = (threshold: 'automatic' | 'manual') => {
		this.threshold = threshold;
	};
	setAutoSensitivity = (sensitivity: number) => {
		this.autoSensitivity = sensitivity;
	};
	setMinSensitivity = (min: number | undefined) => {
		this.minSensitivity = min;
		this.minSensitivityError = '';
		if (
			this.maxSensitivity &&
			this.minSensitivity &&
			this.maxSensitivity > this.minSensitivity
		) {
			this.maxSensitivityError = '';
		} else if (this.maxSensitivity === undefined) {
			this.maxSensitivityError = '';
		}
	};
	setMaxSensitivity = (max: number | undefined) => {
		this.maxSensitivity = max;
		if (
			this.minSensitivity &&
			this.maxSensitivity &&
			this.maxSensitivity > this.minSensitivity
		) {
			this.maxSensitivityError = '';
		} else if (this.minSensitivity === undefined) {
			this.minSensitivityError = '';
			this.maxSensitivityError = '';
		}
	};

	// 4. Manage section
	setOwners = (owners: string[]) => {
		this.owners = owners;
		if (this.owners.length > 0) {
			this.ownersError = '';
		}
	};

	setSubscribers = (subscribers: string[]) => {
		this.subscribers = subscribers;
	};

	setEmailSubscribers = (subs: string[]) => {
		this.emailSubscribers = subs;
	};

	setTempEmailSubscribers = (subs: string[]) => {
		this.tempEmailSubscribers = subs;
	};

	get isFormValid() {
		// 1. General
		// 2. Resource selection
		if (this.integration === undefined) {
			this.integrationError = 'Please select an integration.';
		} else {
			// Data selection only get validated after integration is selected
			if (this.monitorSpec.group === 'Custom') {
				if (this.monitorName.trim() === '') {
					this.monitorNameError =
						"Monitor name can't be empty for custom SQL monitor.";
				}
				if (this.querySelect.trim() === '') {
					this.querySelectError = "SQL query can't be empty.";
				}
			} else if (['Table', 'Column'].includes(this.monitorSpec.group)) {
				if (this.targetEntities.length === 0) {
					this.targetEntitiesError = 'Please select at least one resource.';
				}
			}
		}
		// 3. Configure section
		if (this.threshold === 'manual') {
			if (
				this.minSensitivity === undefined &&
				this.maxSensitivity === undefined
			) {
				this.minSensitivityError = 'This field is required.';
				this.maxSensitivityError = 'This field is required.';
			}
			if (
				this.minSensitivity !== undefined &&
				this.maxSensitivity !== undefined &&
				this.minSensitivity > this.maxSensitivity
			) {
				this.maxSensitivityError =
					'The maximum value should be greater than the minimum value.';
			}
		}
		// 4. Manage section
		if (this.owners.length === 0) {
			this.ownersError = 'At least one owner is required.';
		}

		return !(
			this.integrationError.length ||
			this.targetEntitiesError.length ||
			this.monitorNameError.length ||
			this.querySelectError.length ||
			this.minSensitivityError.length ||
			this.maxSensitivityError.length ||
			this.ownersError
		);
	}

	get monitorsToCreate(): PartialWithoutId<Monitor>[] {
		if (!this.isFormValid) {
			return [];
		}

		const common: PartialWithoutId<Monitor> = {
			owners: this.owners,
			schedule: this.schedule,
		};

		if (this.threshold === 'automatic') {
			common['condition_auto_sensitivity'] = this.autoSensitivity;
		} else {
			common['condition_manual_min'] = this.minSensitivity;
			common['condition_manual_max'] = this.maxSensitivity;
		}

		if (this.monitorSpec.group === 'Custom') {
			return [
				{
					integration: this.integration,
					metric_type: this.monitorSpec.type.metric_type,
					metric_config: {
						query_select: this.querySelect,
					},
					name: this.monitorName,
					...common,
				},
			];
		}

		if (['Table', 'Column'].includes(this.monitorSpec.group)) {
			const metricConfig: Record<string, string> = {};
			if (this.queryWhere.trim() !== '') {
				metricConfig['query_where'] = this.queryWhere.trim();
			}
			return this.targetEntities.map((id) => ({
				target: id,
				integration: this.integration,
				metric_type: this.monitorSpec.type.metric_type,
				metric_config: metricConfig,
				...common,
			}));
		}

		if (
			this.monitorSpec.group === 'Warehouse' &&
			isAnalyticsMonitor(this.monitorSpec.type)
		) {
			const metricConfig: Record<string, string> = {
				integration_metric: this.monitorSpec.type.integration_metric,
			};
			return [
				{
					integration: this.integration,
					metric_type: this.monitorSpec.type.metric_type,
					metric_config: metricConfig,
					...common,
				},
			];
		}

		// TODO[tan]: other monitors
		return [];
	}
}
