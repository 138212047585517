import { useSetState } from '@mantine/hooks';
import { integrationList } from '@repo/common/constants/integration/integrations';
import { isNil } from 'lodash-es';
import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
	IIntegration,
	integrationsQueryKeyFactory,
	useDeleteIntegration,
	useIntegrationGroupItemsRefreshSupport,
} from '../../../api';
import { useMarketplaceIntegrationSpec } from '../../../api/hooks/marketplace';
import type { IntegrationSpec } from '../../../interfaces/IntegrationSpec';
import { openConfirmModal } from '../../ModalManager';

interface CreateIntegrationState {
	createdIntegration?: IIntegration;
	currentStep: number;
}

export enum IntegrationSteps {
	CONNECTION = 'Connection',
	IMPORT = 'Import',
	MAPPING = 'Mapping',
	SYNC = 'Sync',
}

const useCreateIntegrationConnection = () => {
	const [searchParams] = useSearchParams();

	const builtinIntegrationType = searchParams.get('type');
	const marketplaceIntegrationId = searchParams.get('selectedMarketplaceSpec');

	const navigate = useNavigate();

	const { mutateAsync: deleteIntegration } = useDeleteIntegration({
		invalidationKeys: [integrationsQueryKeyFactory.all()],
	});

	const builtinIntegrationSpec = integrationList.find(
		(integration) => integration.type === builtinIntegrationType
	);
	const { data: marketplaceIntegrationSpec } = useMarketplaceIntegrationSpec({
		id: marketplaceIntegrationId || '',
		options: {
			enabled: !isNil(marketplaceIntegrationId),
		},
	});

	const integrationSpec: IntegrationSpec = builtinIntegrationSpec
		? {
				type: 'builtin',
				value: builtinIntegrationSpec,
			}
		: {
				type: 'marketplace',
				value: marketplaceIntegrationSpec!,
			};

	const {
		data: isPreSelectGroupsSupported,
		isLoading: isRefreshSupportLoading,
	} = useIntegrationGroupItemsRefreshSupport(
		builtinIntegrationSpec?.type || 'marketplace'
	);

	const [state, setState] = useSetState<CreateIntegrationState>({
		createdIntegration: undefined,
		currentStep: 0,
	});

	const prevStep = useCallback(
		() => setState((prevState) => ({ currentStep: prevState.currentStep - 1 })),
		[setState]
	);

	const nextStep = useCallback(
		() => setState((prevState) => ({ currentStep: prevState.currentStep + 1 })),
		[setState]
	);

	const setStep = useCallback(
		(step: number) => setState({ currentStep: step }),
		[setState]
	);

	const isMappingSupported =
		builtinIntegrationSpec &&
		builtinIntegrationSpec.type === 'great_expectations';

	const stepNames = [IntegrationSteps.CONNECTION];
	if (isPreSelectGroupsSupported) stepNames.push(IntegrationSteps.IMPORT);
	if (isMappingSupported) stepNames.push(IntegrationSteps.MAPPING);
	stepNames.push(IntegrationSteps.SYNC);

	const totalSteps = stepNames.length;
	const currentStepName = stepNames[state.currentStep];
	const isLastStep = state.currentStep === totalSteps - 1;

	const confirmClose = () => {
		if (state.createdIntegration) {
			deleteIntegration({ id: state.createdIntegration.id });
		}
		navigate('/integrations');
	};

	const handleClose = useCallback(() => {
		if (!isLastStep) {
			openConfirmModal({
				title: 'Are you sure you want to exit?',
				children: 'All progress will be lost.',
				labels: { confirm: 'Exit', cancel: 'Cancel' },
				cancelProps: { size: 'md' },
				confirmProps: { variant: 'primary', tone: 'default', size: 'md' },
				onConfirm: () => confirmClose(),
			});
		} else {
			navigate('/integrations');
		}
	}, [isLastStep, navigate, state]);

	return {
		integrationSpec,
		isPreSelectGroupsSupported,
		isRefreshSupportLoading,
		...state,
		setCreatedIntegration: (integration?: IIntegration) =>
			setState({ createdIntegration: integration }),
		prevStep,
		nextStep,
		setStep,
		handleClose,
		stepNames,
		totalSteps,
		currentStepName,
		isLastStep,
	};
};

export default useCreateIntegrationConnection;
