import { includes, map } from 'lodash-es';
import type { FilterMenuOption } from '../FilterCarousel.constants';

export function filterByTerm(
	options: FilterMenuOption[],
	term: string
): FilterMenuOption[] {
	if (term === '') {
		return options;
	}
	return options.filter((option) =>
		option.label.toLowerCase().includes(term.toLowerCase())
	);
}

export function filterForUnselected(
	options: FilterMenuOption[],
	selectedOptions: FilterMenuOption[]
): FilterMenuOption[] {
	return options.filter(
		(option) => !includes(map(selectedOptions, 'value'), option.value)
	);
}
