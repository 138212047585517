import { Box, Stack, createStyles } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { IColumn } from '../../../../api';
import {
	FetchModelInfiniteListHook,
	FetchModelList,
} from '../../../../api/factories/types';
import {
	fetchCatalogList,
	useCatalogInfiniteList,
} from '../../../../api/hooks/resourceCatalog';

import { TableV2 } from '../../../../components/TableV2';
import {
	CapitalizeTextRender,
	ColumnProfileRender,
} from '../../../../components/TableV2/render';
import { useGenericColumns } from '../../../../components/TableV2/SecodaEntity.hooks';
import type {
	ExtendedDataTableColumn,
	Render,
} from '../../../../components/TableV2/types';

import { FilterOptionType } from '../../../../components/Filter';
import { ICommandListItem } from '../../../../components/Spotlight/components/CommandPalette/constants';
import { EntityType } from '../../../../lib/types';
import { useActions as useCatalogActions } from '../../../TeamCatalogPage/TeamCatalogPage.hooks';
import type { DashboardEntityTabsStore } from '../DashboardEntityTabsStore';

export function useColumns(): ExtendedDataTableColumn<IColumn>[] {
	const genericColumns = useGenericColumns({});

	const columns = useMemo(
		() => [
			{
				accessor: 'type',
				title: 'Type',
				width: 160,
				render: (record: IColumn) => (
					<CapitalizeTextRender<IColumn>
						record={record}
						field={'type'}
						field2={'native_type'}
					/>
				),
				filterOptionType: FilterOptionType.NATIVE_TYPE,
			},
			...genericColumns,
			{
				accessor: 'properties._profile.ntiles',
				title: 'Distribution',
				sortable: false,
				filtering: false,
				explicit: true,
				width: 160,
				render: ColumnProfileRender as unknown as Render<IColumn>,
			},
		],
		[genericColumns]
	);

	return columns;
}
export const useActions = () => {
	const actions = useCatalogActions() as unknown as ICommandListItem<IColumn>[];

	return actions;
};

const useStyles = createStyles(() => ({
	gridWrapper: {
		width: '100%',
		minHeight: 350,
		flexGrow: 1,
	},
	tableWrapper: {
		height: '100%',
	},
}));

interface ChartsTabProps {
	id: string;
	store: DashboardEntityTabsStore;
}

function ChartsTab({ id, store }: ChartsTabProps) {
	const columns = useColumns();
	const actions = useActions();

	const { classes } = useStyles();

	return (
		<Stack className={classes.gridWrapper}>
			<Box className={classes.tableWrapper}>
				<TableV2<IColumn>
					key={columns.length}
					withSearch
					withInteractiveHeader
					withCsvExport
					withCheckbox
					withActions={actions}
					columns={columns}
					withInfiniteScroll
					usePaginationList={
						useCatalogInfiniteList as FetchModelInfiniteListHook<IColumn>
					}
					fetchPaginationList={
						fetchCatalogList as unknown as FetchModelList<IColumn>
					}
					columnVisibility={{
						catalogType: EntityType.chart,
						catalogServerType: EntityType.chart,
					}}
					defaultRequiredCatalogFilters={{
						operands: [
							{
								operands: [],
								field: 'parent_id',
								operator: 'exact',
								value: id,
							},
						],
					}}
					defaultRequiredSearchParams={{
						entity_type: EntityType.chart,
						calculate_children_count: true,
					}}
					defaultRequiredSearchParamsNesting={{
						entity_type: EntityType.chart,
						calculate_children_count: true,
					}}
					nestingFilter="parent_id"
				/>
			</Box>
		</Stack>
	);
}

export default observer(ChartsTab);
