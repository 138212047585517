import { Menu } from '@mantine/core';
import { Icon, Switch } from '@repo/foundations';
import { ISecodaEntity } from '../../../../api';
import { useFullWidthToggle } from '../../../../hooks/useFullWidthToggle.ts';
import type { SecodaEntity } from '../../../../lib/models';

interface ToggleFullWidthActionProps {
	entity: SecodaEntity | ISecodaEntity;
}

export function ToggleFullWidthAction({ entity }: ToggleFullWidthActionProps) {
	const { shouldSetFullWidth, toggle } = useFullWidthToggle(entity.id);

	return (
		<Menu.Item
			onClick={toggle}
			closeMenuOnClick={false}
			icon={<Icon name="arrowAutofitWidth" />}
			rightSection={
				<Switch
					checked={shouldSetFullWidth}
					readOnly
					style={{ pointerEvents: 'none' }}
				/>
			}
		>
			Full width
		</Menu.Item>
	);
}
