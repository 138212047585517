import { Modal, Stack, useMantineTheme } from '@mantine/core';
import { isNil } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useSecodaEntity } from '../../../../api';
import { useLineageInfiniteList } from '../../../../api/hooks/lineage/useLineage';
import { ImpactAnalysisContent } from './ImpactAnalysisContent';
import { ImpactAnalysisFilters } from './ImpactAnalysisFilters';
import { impactAnalysisStore } from './store';

export const ImpactAnalysisModal = observer(() => {
	const theme = useMantineTheme();

	useEffect(() => {
		impactAnalysisStore.initialize();
	}, []);

	const { data: entity } = useSecodaEntity({
		id: impactAnalysisStore.id || '',
		options: {
			enabled: !isNil(impactAnalysisStore.id),
		},
	});

	const { data, hasNextPage, fetchNextPage, status } = useLineageInfiniteList({
		filters: {
			id: entity?.id || '',
			...impactAnalysisStore.queryParams,
		},
		options: {
			enabled: !isNil(entity),
			keepPreviousData: true,
		},
	});

	const handleFetchNextPage = () => {
		fetchNextPage();
	};

	const handleOnClose = () => {
		impactAnalysisStore.setOpened(false);
	};

	return (
		<Modal
			opened={impactAnalysisStore.opened}
			onClose={handleOnClose}
			title={`Impact Analysis of ${entity?.title}`}
			size={theme.other.width.lg}
			withinPortal
		>
			<Stack spacing="sm">
				<ImpactAnalysisFilters />
				<ImpactAnalysisContent
					status={status}
					entities={data?.pages.map((lineageResource) => lineageResource) || []}
					hasNextPage={hasNextPage}
					fetchNextPage={handleFetchNextPage}
				/>
			</Stack>
		</Modal>
	);
});
