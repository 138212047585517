import { MetricType } from '@repo/api-codegen';
import { useQuery } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import type { IApiListResponse } from '../../types';
import { monitorsQueryKeyFactory } from './constants';

export interface AddMonitorItem {
	id: string;
	title: string;
	title_full: string;
	type?: string;
	monitor?: string;
	schema?: string;
	database?: string;
	cluster?: string;
}

export interface UseAddMonitorItemsArgs {
	type: 'tables' | 'columns';
	id?: string;
	metricType?: MetricType;
	page?: number;
	title?: string;
}

function getParams(args: UseAddMonitorItemsArgs) {
	const { type, id, metricType, page, title } = args;
	const paramType = type === 'columns' ? 'table' : 'integration';

	return {
		[paramType]: id,
		metric_type: metricType,
		title,
		page,
	};
}

function getQueryParams({
	type,
	id,
	metricType,
	page,
	title,
}: UseAddMonitorItemsArgs) {
	const paramType = type === 'columns' ? 'table' : 'integration';

	let queryParams = {
		[paramType]: id,
		metric_type: metricType,
		page,
	};

	if (title) {
		queryParams = {
			...queryParams,
			title,
		};
	}

	return queryParams;
}

export function getUseAddMonitorItemsQueryKey(args: UseAddMonitorItemsArgs) {
	const params = getParams(args);
	return [...monitorsQueryKeyFactory.namespace, args.type, params];
}

export function useAddMonitorItems(args: UseAddMonitorItemsArgs) {
	const path = `search_${args.type}`;
	const params = getQueryParams(args);

	return useQuery({
		queryKey: getUseAddMonitorItemsQueryKey(args),
		queryFn: async ({ signal }) => {
			const url = getEndpoints([
				...monitorsQueryKeyFactory.namespace,
				path,
			]).root();

			const { data } = await apiClient.get<IApiListResponse<AddMonitorItem>>(
				url,
				{
					params,
					signal,
				}
			);

			return data;
		},
		enabled: Boolean(args?.id) && Boolean(args?.metricType),
	});
}

export type IntegrationSupport = Record<
	string,
	{ table: string[]; columns: string[] }
>;

export function useIntegrationSupport() {
	return useQuery({
		// Integration support will not change
		queryKey: ['integrationSupport'],
		queryFn: async ({ signal }) => {
			const url = getEndpoints([
				...monitorsQueryKeyFactory.namespace,
				'integration_support',
			]).root();

			const { data } = await apiClient.get<IntegrationSupport>(url, {
				signal,
			});
			return data;
		},
	});
}
