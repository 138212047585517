import { Skeleton } from '@mantine/core';
import { Suspense } from 'react';
import { useMetric } from '../../../../../../api';
import { ChartBlock, ChartBlockProps } from '../ChartBlock/ChartBlock';

export interface ChartBlockContainerProps
	extends Pick<
		ChartBlockProps,
		| 'xAxis'
		| 'yAxis'
		| 'dimension'
		| 'numericFormat'
		| 'onDuplicate'
		| 'onCopyLink'
		| 'onDelete'
		| 'onSave'
	> {
	id: string;
	onDelete: VoidFunction;
	isSelected?: boolean;
}

export function ChartBlockContainer({
	id,
	onDelete,
	isSelected,
	xAxis,
	yAxis,
	dimension,
	numericFormat,
	onDuplicate,
	onCopyLink,
	onSave,
}: ChartBlockContainerProps) {
	const { data: metric } = useMetric({ id });

	if (!metric) {
		return null;
	}

	return (
		<Suspense fallback={<Skeleton height={360} />}>
			<ChartBlock
				metric={metric}
				xAxis={xAxis ? xAxis : metric.time}
				yAxis={yAxis ? yAxis : metric.primary}
				dimension={dimension ? dimension : metric.dimension}
				numericFormat={numericFormat ? numericFormat : metric.numeric_format}
				onDuplicate={onDuplicate}
				onCopyLink={onCopyLink}
				onDelete={onDelete}
				onSave={onSave}
			/>
		</Suspense>
	);
}
