import { uuidv4 } from 'lib0/random';
import { makeAutoObservable } from 'mobx';
import type {
	AutomationCondition,
	AutomationField,
	AutomationFilter,
} from '../../../../../api';
import { AutomationFilterOperator } from '../../../../../api';
import type { AutomationValueType } from '../../constants';

interface AutomationCardFilter extends Partial<AutomationFilter> {
	searchKey: string;
	field?: AutomationField;
	value?: string | boolean;
	display?: string;
	condition?: AutomationCondition;
	valueType?: AutomationValueType;
}

interface SetFilterValueProps {
	key: string;
	value?: string;
	display?: string;
}

export class AutomationActionCardFilterStore {
	filters: AutomationCardFilter[] = [];

	filterOperator = AutomationFilterOperator.AND;

	constructor() {
		makeAutoObservable(this);
	}

	addFilter = ({
		searchKey,
		field,
		value,
		display,
		condition,
	}: Partial<AutomationCardFilter>) => {
		this.filters.push({
			searchKey: searchKey ?? uuidv4(),
			field,
			condition,
			value,
			display,
		});
	};

	deleteFilter = (key: string) => {
		const newFilters = this.filters.filter(
			(filter) => filter.searchKey !== key
		);

		this.filters = newFilters;
	};

	setFilterField = (key: string, field: AutomationField) => {
		const existingFilter = this.filters.find(
			(filter) => filter.searchKey === key
		);

		if (existingFilter) {
			existingFilter.field = field;

			// Reset condition and values if field is changed
			existingFilter.condition = undefined;
			existingFilter.value = undefined;
			existingFilter.display = undefined;
		}
	};

	getFilter = (key: string) =>
		this.filters.find((filter) => filter.searchKey === key);

	setFilterCondition = (key: string, condition: AutomationCondition) => {
		const existingFilter = this.filters.find(
			(filter) => filter.searchKey === key
		);

		if (existingFilter) {
			existingFilter.condition = condition;
		}
	};

	setFilterValue = ({ key, value, display }: SetFilterValueProps) => {
		const existingFilter = this.filters.find(
			(filter) => filter.searchKey === key
		);

		if (existingFilter) {
			existingFilter.value = value;
			existingFilter.display = display;
		}
	};

	resetFilters = () => {
		this.filters = [];
	};
}
