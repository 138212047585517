import { createStyles, Group, Skeleton } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useUnlinkJiraIssue } from '@repo/api-codegen';
import { IconButton, Text } from '@repo/foundations';
import { Incident, queryClient } from '../../../../api';
import { incidentsQueryKeyFactory } from '../../../../api/hooks/monitoring/constants';
import IntegrationLogo from '../../../../components/IntegrationLogo';

const useStyles = createStyles((theme) => ({
	itemWrapper: {
		width: '100%',
		borderRadius: theme.radius.md,
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
		cursor: 'pointer',
	},
	iconWrapper: {
		paddingLeft: theme.other.space[1],
	},
}));

export function LinkedJiraIssue({
	issueDetails,
	incidentId,
}: {
	issueDetails: Incident['jira_issue'];
	incidentId: string;
}) {
	const { ref, hovered } = useHover<HTMLDivElement>();
	const { classes } = useStyles();
	const { mutate: unlinkIssue, isLoading } = useUnlinkJiraIssue({
		onSuccess: () =>
			queryClient.invalidateQueries(incidentsQueryKeyFactory.all()),
	});

	return isLoading ? (
		<Skeleton height="90%" width="90%" />
	) : (
		<Group
			className={classes.itemWrapper}
			noWrap
			position="apart"
			ref={ref}
			onClick={() => {
				window.open(issueDetails.issue_url as string, '_blank');
			}}
		>
			<Group spacing="xs" noWrap>
				<IntegrationLogo
					className={classes.iconWrapper}
					size={16}
					integrationType={'jira'}
				/>
				<Text lineClamp={1} size="xs">
					{issueDetails.issue_name}
				</Text>
			</Group>
			<IconButton
				size="sm"
				variant="tertiary"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					unlinkIssue({ pathParams: { incidentId: incidentId } });
				}}
				iconName="x"
				style={{ visibility: hovered && !isLoading ? 'visible' : 'hidden' }}
			/>
		</Group>
	);
}
