import axios from 'axios';
import { isNil, isPlainObject } from 'lodash-es';
import { api } from '../../../network';
import { authHeaders, getBaseUrl } from '../../api/common';
import type { EntityType } from '../types';
import { BackgroundJob } from './backgroundJob';

const filterNil = (obj: object) =>
	Object.fromEntries(Object.entries(obj).filter(([, value]) => !isNil(value)));

export function convertObjectToUrlSearchParams(options: object) {
	const updateOptions = {
		...Object.fromEntries(
			Object.entries(filterNil(options)).map(([key, value]) => {
				if (
					(key === 'native_type' || key === 'native_type__exclude') &&
					Array.isArray(value)
				) {
					return [key, JSON.stringify(value)];
				}
				return [
					key,
					isPlainObject(value) ? JSON.stringify(filterNil(value)) : value,
				];
			})
		),
	};
	return new URLSearchParams(updateOptions);
}

export const search = async (options: object, highlight?: boolean) => {
	const updateOptions = convertObjectToUrlSearchParams({
		highlight: !isNil(highlight) ? String(highlight) : undefined,
		...options,
	});
	const res = await axios.get(`${api()}search/?${updateOptions.toString()}`);
	return res;
};

export const getSearchFilters = async (): Promise<
	Record<
		'databases' | 'groups' | 'schemas' | 'native_types',
		{
			label: string;
			value: string;
			entity_type?: EntityType;
		}[]
	>
> => {
	const resp = await axios.get(`${api()}search/filters/`, authHeaders());
	return resp.data;
};
export const publishAll = async () =>
	axios.post(`${api()}resource/all/publish_all/`, {}, authHeaders());

export const exportData = async (type: string) => {
	const response = await axios.get(`${api()}metadata/data/`, {
		...authHeaders(),
		params: {
			type,
		},
	});
	return new BackgroundJob(response.data);
};

export const saveBlob = (blob: Blob, filename: string) => {
	const link = document.createElement('a');
	const urlObject = URL.createObjectURL(blob);

	link.href = urlObject;
	link.download = filename;
	document.body.appendChild(link);
	link.click();

	document.body.removeChild(link);
	URL.revokeObjectURL(urlObject);
};

export const downloadFile = async (path: string, fileName = 'export.csv') => {
	const fullPath = path.startsWith('http') ? path : getBaseUrl() + path;
	const response = await axios.get(fullPath);
	saveBlob(new Blob([response.data], { type: 'text/csv' }), fileName);
};

export const downloadImportTemplate = () =>
	downloadFile('metadata/data/template_import/', 'import.csv');

export const downloadExportTemplate = () =>
	downloadFile('metadata/data/template_export/', 'export.csv');
