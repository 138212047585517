import { Center, createStyles, Loader, Stack, TextInput } from '@mantine/core';

import { useDebouncedValue, useInputState } from '@mantine/hooks';
import { size } from 'lodash-es';
import { useCallback, useMemo, useRef } from 'react';
import { Virtuoso } from 'react-virtuoso';
import type { ISecodaEntity, SearchResult } from '../../../../api';
import { useSearch } from '../../../../api';
import type { SecodaEntity } from '../../../../lib/models';

import { EntityType } from '../../../../lib/types';
import type { ButtonDetails } from '../../../EmptyState';
import { EmptyState } from '../../../EmptyState';
import SearchResultItem from '../../../MultiResourceSelector/SearchResultItem';

interface RelatedResourceDropdownProps {
	handleClick: (selected: SearchResult) => void;
	entity: SecodaEntity | ISecodaEntity;
	relatedEntityIds: string[];
	filterEntityTypes?: EntityType[];
}

const useStyles = createStyles((theme) => ({
	textInput: {
		color: theme.other.getColor('text/secondary/default'),
		backgroundColor: theme.other.getColor('surface/input/default'),
		marginBottom: theme.spacing['2xs'],
		borderRadius: theme.other.borderRadius.md,
		border: 'none',
		borderBottom: `${theme.other.borderWidth.sm} solid ${theme.other.getColor('border/primary/default')}`,
	},
	target: {
		width: '100%',
	},
}));

export function RelatedResourceDropdown({
	handleClick,
	entity,
	relatedEntityIds,
	filterEntityTypes = Object.values(EntityType),
}: RelatedResourceDropdownProps) {
	const { classes, theme } = useStyles();

	const [searchTerm, setSearchTerm] = useInputState('');
	const [debounced] = useDebouncedValue(searchTerm, 300);

	const inputRef = useRef<HTMLInputElement>(null);

	const { isFetching, data } = useSearch({
		searchTerm: debounced,
		filterV2: {
			operator: 'and',
			operands: [
				{
					field: 'entity_type',
					operator: 'in',
					value: filterEntityTypes,
					operands: [],
				},
				{
					operator: 'not',
					operands: [
						{
							operands: [],
							operator: 'in',
							field: 'id',
							value: [...relatedEntityIds, entity.id],
						},
					],
				},
			],
		},
		options: {
			select: (data) => data.results,
		},
	});

	const itemContent = useCallback(
		(_idx: number, item: SearchResult) => (
			<SearchResultItem
				key={item.id}
				item={item}
				isViewerUser={false} // We already checked in the parent component.
				onClick={handleClick}
			/>
		),
		[handleClick]
	);

	const emptyButtons: ButtonDetails[] = useMemo(
		() => [
			{
				name: 'Clear search',
				action: () => setSearchTerm(''),
				isPrimary: false,
				size: 'sm',
			},
		],
		[setSearchTerm]
	);

	const height =
		size(data) > 6 ? theme.other.space[60] : size(data) * theme.other.space[12];

	return (
		<Stack spacing={0} p={0} mx={`-${theme.other.space[2]}px`}>
			<TextInput
				autoFocus
				placeholder="Search resources"
				value={searchTerm}
				onChange={setSearchTerm}
				ref={inputRef}
				classNames={{
					input: classes.textInput,
				}}
			/>
			{size(data) > 0 && !isFetching && (
				<Virtuoso
					style={{
						height,
						scrollbarColor: `${theme.other.getColor('surface/tertiary/active')} transparent`,
					}}
					data={data}
					totalCount={size(data)}
					itemContent={itemContent}
				/>
			)}
			{isFetching && (
				<Center h={height}>
					<Loader size="sm" />
				</Center>
			)}
			{size(data) === 0 && !isFetching && (
				<EmptyState
					iconName="search"
					title="No results found"
					description="No resources or invalid search"
					buttons={emptyButtons}
					includeGoBack={false}
					size="sm"
				/>
			)}
		</Stack>
	);
}
