import { has } from 'lodash-es';
import type {
	IBaseModel,
	IDictionaryTerm,
	IGlossaryTerm,
	IUserGroup,
} from '../../api';
import type { BreadcrumbEntity } from '../../utils/breadcrumb';
import type { BaseModel, Collection, Document, Metric, User } from '../models';
import { EntityType } from '../types';

export function getEntityDisplayTitle(entity: {
	title?: string;
	title_cased?: string;
}): string {
	return entity.title_cased || entity.title || 'Untitled';
}

export function isUser(item: BaseModel): item is User {
	return has(item, 'display_name');
}

export function isUserGroup(item: IBaseModel): item is IUserGroup {
	return has(item, 'name');
}

/**
 * Type guard for checking if an entity is a Metric.
 *
 * @param entity - The entity to check.
 * @returns `true` if the entity is a Metric, `false` otherwise.
 */
export function isMetric(entity: BreadcrumbEntity): entity is Metric {
	return entity.entity_type === EntityType.metric;
}

/**
 * Type guard for checking if an entity is a Table.
 *
 * @param entity - The entity to check.
 * @returns `true` if the entity is a Table, `false` otherwise.
 */
export function isTable(entity: BreadcrumbEntity): boolean {
	return entity.entity_type === EntityType.table;
}

/**
 * Type guard for checking if an entity is a Column.
 *
 * @param entity - The entity to check.
 * @returns `true` if the entity is a Column, `false` otherwise.
 */
export function isColumn(entity: BreadcrumbEntity): boolean {
	return entity.entity_type === EntityType.column;
}

/**
 * Type guard for checking if an entity is a Dashboard.
 *
 * @param entity - The entity to check.
 * @returns `true` if the entity is a Dashboard, `false` otherwise.
 */
export function isDashboard(entity: BreadcrumbEntity): boolean {
	return entity.entity_type === EntityType.dashboard;
}

/**
 * Type guard for checking if an entity is a Chart.
 *
 * @param entity - The entity to check.
 * @returns `true` if the entity is a Chart, `false` otherwise.
 */
export function isChart(entity: BreadcrumbEntity): boolean {
	return entity.entity_type === EntityType.chart;
}

/**
 * Type guard for checking if an entity is a Job.
 *
 * @param entity - The entity to check.
 * @returns `true` if the entity is a Job, `false` otherwise.
 */
export function isJob(entity: BreadcrumbEntity): boolean {
	return entity.entity_type === EntityType.job;
}

/**
 * Type guard for checking if an entity is an Event.
 *
 * @param entity - The entity to check.
 * @returns `true` if the entity is an Event, `false` otherwise.
 */
export function isEvent(entity: BreadcrumbEntity): boolean {
	return entity.entity_type === EntityType.event;
}

/**
 * Type guard for checking if an entity is a Schema.
 *
 * @param entity - The entity to check.
 * @returns `true` if the entity is a Schema, `false` otherwise.
 */
export function isSchema(entity: BreadcrumbEntity): boolean {
	return entity.entity_type === EntityType.schema;
}

/**
 * Type guard for checking if an entity is a Database.
 * @param entity - The entity to check.
 * @returns `true` if the entity is a Database, `false` otherwise.
 */
export function isDatabase(entity: BreadcrumbEntity): boolean {
	return entity.entity_type === EntityType.database;
}

/**
 * Type guard for checking if an entity is a Glossary.
 * @param entity - The entity to check.
 * @returns `true` if the entity is a Glossary, `false` otherwise.
 */
export function isGlossary(entity: BreadcrumbEntity): entity is IGlossaryTerm {
	return entity.entity_type === EntityType.glossary;
}

/**
 * Type guard for checking if an entity is a Collection.
 *
 * @param entity - The entity to check.
 * @returns `true` if the entity is a Collection, `false` otherwise.
 */
export function isCollection(entity: BreadcrumbEntity): entity is Collection {
	return entity.entity_type === EntityType.collection;
}

/**
 * Type guard for checking if an entity is a Dictionary Term.
 *
 * @param entity - The entity to check.
 * @returns `true` if the entity is a Dictionary Term, `false` otherwise.
 */
export function isDictionaryTerm(
	entity: BreadcrumbEntity
): entity is IDictionaryTerm {
	return entity.entity_type === EntityType.dictionary_term;
}

/**
 * Type guard for checking if an entity is a Document.
 *
 * @param entity - The entity to check.
 * @returns `true` if the entity is a Document, `false` otherwise.
 */
export function isDocument(entity: BreadcrumbEntity): entity is Document {
	return entity.entity_type === EntityType.document;
}

/**
 * Type guard for checking if an entity is a Question.
 *
 * @param entity - The entity to check.
 * @returns `true` if the entity is a Question, `false` otherwise.
 */
export function isQuestion(entity: BreadcrumbEntity): entity is Document {
	return entity.entity_type === EntityType.question;
}

/**
 * Type guard for checking if an entity is a Catalog Entity.
 *
 * @param entity - The entity to check.
 * @returns `true` if the entity is a Table, `false` otherwise.
 */
export function isCatalogEntity(entity: BreadcrumbEntity): boolean {
	return (
		isDatabase(entity) ||
		isSchema(entity) ||
		isTable(entity) ||
		isColumn(entity) ||
		isDashboard(entity) ||
		isChart(entity) ||
		isEvent(entity) ||
		isJob(entity)
	);
}
