import { Box, Menu } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { useNavigate } from 'react-router';
import { queryClient } from '../../api';
import {
	templatesQueryKeyFactory,
	useCreateTemplate,
} from '../../api/hooks/templates';
import type { EntityType } from '../../lib/types';

interface TemplateNewButtonProps {
	type: EntityType;
	withForm?: boolean;
	teamId?: string | null;
}

export function TemplateNewButton({
	type,
	withForm,
	teamId,
}: TemplateNewButtonProps) {
	const navigate = useNavigate();

	const { mutateAsync: createTemplate } = useCreateTemplate({
		options: {
			onSuccess: (value) => {
				const urlParams = teamId ? `?teamId=${teamId}` : '';
				navigate(`/templates/${value.id}${urlParams}`);
				queryClient.invalidateQueries(templatesQueryKeyFactory.list(1));
			},
		},
	});

	const handleCreateTemplate = async () => {
		createTemplate({
			data: {
				published: true,
				entity_type_template: type,
				title: 'Untitled template',
			},
		});
	};

	return !withForm ? (
		<Menu.Item
			data-testid="new-template-button"
			icon={<Icon name="plus" />}
			onClick={handleCreateTemplate}
		>
			New template
		</Menu.Item>
	) : (
		<Menu position="bottom-start">
			<Menu.Target>
				<Box data-testid="new-template-button">
					<Menu.Item icon={<Icon name="plus" />}>New template</Menu.Item>
				</Box>
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Item icon={<Icon name="file" />} onClick={handleCreateTemplate}>
					Standard
					<Text size="xs" color="text/secondary/default">
						Template without required fields
					</Text>
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}
