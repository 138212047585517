import { Menu } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { useNavigate } from 'react-router';
import {
	ISecodaEntity,
	queryClient,
	secodaEntitiesQueryKeyFactory,
	useAuthUser,
	useUpdateSecodaEntity,
} from '../../../../api';
import { trackEvent } from '../../../../utils/analytics';

import { catalogQueryKeyFactory } from '../../../../api/hooks/catalog/constants';
import { resourceCatalogQueryKeyFactory } from '../../../../api/hooks/resourceCatalog/constants';
import { useCurrentTeam } from '../../../../api/hooks/team/myMemberships';
import { useEntityBreadcrumbs } from '../../../../hooks/useEntityBreadcrumbs';
import { openDeleteConfirmModal } from '../../../ModalManager';

interface DeleteActionProps {
	entity: ISecodaEntity;
}

export function DeleteAction({ entity }: DeleteActionProps) {
	const { currentTeam } = useCurrentTeam();
	const { breadcrumbs } = useEntityBreadcrumbs(entity, { includeTeam: true });
	const { user, workspace } = useAuthUser();

	const { mutateAsync: updateSecodaEntity } = useUpdateSecodaEntity({
		options: {
			onSuccess: () => {
				queryClient.invalidateQueries(
					secodaEntitiesQueryKeyFactory.byId(entity.id)
				);
				// Should be the same key as in action::delete in TableV2.
				// https://github.com/secoda/secoda/blob/8def9a5f50b930955900342ed0918c0df16d6647/apps/frontend/src/components/TableV2/SecodaEntity.hooks.tsx#L898
				queryClient.invalidateQueries(
					resourceCatalogQueryKeyFactory.allLists()
				);
			},
		},
	});

	const navigate = useNavigate();

	const handleDeleteClick = async () => {
		const entitytitle =
			entity.title_cased || entity.title || `Untitled ${entity.entity_type}`;

		openDeleteConfirmModal({
			title: `Delete ${entitytitle}`,
			description: `Are you sure you want to delete ${entitytitle}?`,
			onConfirm: async () => {
				await updateSecodaEntity({
					data: {
						id: entity.id,
						archived: true,
					},
				});

				trackEvent(
					`${entity.entity_type}/delete`,
					{
						id: entity.id,
					},
					user,
					workspace
				);

				queryClient.invalidateQueries(catalogQueryKeyFactory.allLists());

				if (currentTeam && breadcrumbs) {
					navigate(breadcrumbs[0].href);
				}
			},
		});
	};

	return (
		<Menu.Item
			icon={<Icon name="trash" />}
			onClick={handleDeleteClick}
			color="red"
		>
			Delete
		</Menu.Item>
	);
}
