import { createStyles, Group, Stack } from '@mantine/core';
import { Text, Title } from '@repo/foundations';
import { size, startCase } from 'lodash-es';
import { useMemo } from 'react';
import { EntityType } from '../../../lib/types';
import IntegrationLogo from '../../IntegrationLogo';
import { NODE_HEADER_INTEGRATION_ICON_SIZE } from '../constants';
import { getMetadataText } from '../EntityNode/EntityNodeHeader/utils';
import { lineageStore } from '../store';
import type { LineageSearchResultItem } from '../types';

interface ILineageSearchResult {
	item: LineageSearchResultItem;
	clearSearch: () => void;
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		cursor: 'pointer',
		width: theme.other.width.md,
		padding: `${theme.spacing.sm} ${theme.spacing.md}`,
		borderRadius: theme.radius.md,
		gap: theme.spacing.xs,
		flexWrap: 'nowrap',
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('surface/primary/active'),
		},
	},
	content: {
		width: `calc(${theme.other.width.md}px - ${NODE_HEADER_INTEGRATION_ICON_SIZE}px - (2 * ${theme.spacing.md}) - ${theme.spacing.xs})`,
	},
}));

function LineageSearchResult({ item, clearSearch }: ILineageSearchResult) {
	const { classes } = useStyles();

	const metadataText = useMemo(
		() => getMetadataText(item.types, item.metadata),
		[item]
	);

	const handleOnClick = () => {
		lineageStore.navigateToNode(item.nodeId);
		if (item.types.entity === EntityType.column) {
			lineageStore.toggleChildren(item.nodeId, true);
		} else {
			lineageStore.selectNode(item.nodeId);
		}
		clearSearch();
	};

	return (
		<Group
			className={classes.wrapper}
			onClick={handleOnClick}
			data-testid="lineage-search-result-card-ba984a1fa"
		>
			<IntegrationLogo
				entityType={item.types.entity}
				integrationId={item.integration.id}
				integrationType={item.integration.type}
				miw={NODE_HEADER_INTEGRATION_ICON_SIZE}
				maw={NODE_HEADER_INTEGRATION_ICON_SIZE}
			/>
			<Stack className={classes.content} spacing={0}>
				<Title
					size="sm"
					truncate
					data-testid="lineage-search-result-card-title"
				>
					{item.title}
				</Title>
				<Group spacing="3xs" noWrap>
					<Text size="xs" color="text/secondary/default">
						{startCase(item.types.native || item.types.entity)}
					</Text>
					{size(metadataText) > 0 && (
						<>
							<Text size="xs" color="text/secondary/default">
								·
							</Text>
							<Text size="xs" color="text/secondary/default">
								{metadataText}
							</Text>
						</>
					)}
				</Group>
			</Stack>
		</Group>
	);
}

export default LineageSearchResult;
