import type { TablerIconsProps } from '@tabler/icons-react';
import {
	IconArrowUpRight,
	IconBook,
	IconBrandSlack,
	IconCalendarEvent,
	IconChartHistogram,
	IconCircle,
	IconClock,
	IconDatabase,
	IconDiscountCheck,
	IconFileDescription,
	IconFolder,
	IconFolders,
	IconLayoutGrid,
	IconListDetails,
	IconListNumbers,
	IconLoader,
	IconPlugConnected,
	IconQuestionMark,
	IconSchema,
	IconShieldLock,
	IconSourceCode,
	IconTag,
	IconTargetArrow,
	IconTrendingUp,
	IconUser,
} from '@tabler/icons-react';
import { startCase } from 'lodash-es';
import type { SecodaEntity } from '../../../lib/models';
import { EntityType } from '../../../lib/types';

export type FilterMenuOption = {
	label: string;
	value: string | boolean;
	icon?: (props: TablerIconsProps) => JSX.Element;
	/**
	 * EntityType parsing
	 */
	entityType?: EntityType;
	/**
	 * Custom component rendering information
	 */
	data?: SecodaEntity;
};

export type FilterMenuItem = {
	/**
	 * Formatted display label for the filter
	 */
	label: string;
	/**
	 * Filter value, defined in FilterValue enum
	 */
	value: FilterValue;
	/**
	 * Icon
	 */
	icon: (props: TablerIconsProps) => JSX.Element;
	/**
	 * True to include, false to exclude
	 *
	 * NOTE: isInclude can be toggled at runtime
	 */
	isInclude: boolean;
	/**
	 * NOTE: Filter options will be computed/appended at runtime
	 */
	options: FilterMenuOption[];
	/**
	 * NOTE: Selected options will be computed/appended at runtime
	 */
	selectedOptions: FilterMenuOption[];
	/**
	 * Define what entity types can be filtered by this filter
	 *
	 * type = [EntityType.all] means all entity types
	 *
	 * type = [] means no entity types
	 */
	type?: EntityType[];
	/**
	 * Radio button only allows on selectedOption to be selected
	 */
	isRadio?: boolean;
	/**
	 * Custom component to be rendered instead of FilterDropdown
	 */
	component?: (props: { onClose?: () => void }) => JSX.Element;
	/**
	 * True to always display this filter in the carousel. Can't be added or removed with the "Add filter" button
	 */
	alwaysDisplayFilter?: boolean;

	withIsNotMenu?: boolean;

	alwaysHideFilter?: boolean;
};

export enum SortValue {
	RELEVANCE = 'relevance',
	POPULARITY = 'popularity',
	UPDATED_AT = 'updated_at',
	CREATED_AT = 'created_at',
}

export type SortOption = {
	label: string;
	value: SortValue;
	icon: (props: TablerIconsProps) => JSX.Element;
};

export const SORT_OPTIONS: Record<SortValue, SortOption> = {
	[SortValue.RELEVANCE]: {
		label: 'Relevance',
		value: SortValue.RELEVANCE,
		icon: IconTargetArrow,
	},
	[SortValue.POPULARITY]: {
		label: 'Popularity',
		value: SortValue.POPULARITY,
		icon: IconTrendingUp,
	},
	[SortValue.UPDATED_AT]: {
		label: 'Last modified',
		value: SortValue.UPDATED_AT,
		icon: IconClock,
	},
	[SortValue.CREATED_AT]: {
		label: 'Date created',
		value: SortValue.CREATED_AT,
		icon: IconCalendarEvent,
	},
};

export enum DisplayValue {
	COLLECTIONS = 'collections',
	OWNERS = 'owners',
	VIEWS = 'views',
	UPDATED_AT = 'updated_at',
	TAGS = 'tags',
	METADATA = 'metadata',
}

export type DisplayOption = {
	label: string;
	value: DisplayValue;
	icon: (props: TablerIconsProps) => JSX.Element;
	checked: boolean;
};

export const DEFAULT_DISPLAY_OPTIONS: Record<DisplayValue, DisplayOption> = {
	[DisplayValue.COLLECTIONS]: {
		label: 'Collection',
		value: DisplayValue.COLLECTIONS,
		icon: IconFolders,
		checked: false,
	},
	[DisplayValue.OWNERS]: {
		label: 'Owners',
		value: DisplayValue.OWNERS,
		icon: IconUser,
		checked: false,
	},
	[DisplayValue.VIEWS]: {
		label: 'Views',
		value: DisplayValue.VIEWS,
		icon: IconTrendingUp,
		checked: true,
	},
	[DisplayValue.UPDATED_AT]: {
		label: 'Last modified',
		value: DisplayValue.UPDATED_AT,
		icon: IconClock,
		checked: true,
	},
	[DisplayValue.TAGS]: {
		label: 'Tags',
		value: DisplayValue.TAGS,
		icon: IconTag,
		checked: false,
	},
	[DisplayValue.METADATA]: {
		label: 'Metadata',
		value: DisplayValue.METADATA,
		icon: IconListDetails,
		checked: true,
	},
};

export enum FilterValue {
	NATIVE_TYPE = 'native_type',
	INTEGRATION_NAME = 'integration_name',
	DATABASE = 'database',
	SCHEMA = 'schema',
	TAGS = 'tags',
	PUBLISHED = 'published',
	VERIFIED = 'verified',
	PII = 'pii',
	COLLECTIONS = 'collections',
	OWNERS = 'owners',
	SOURCES = 'sources',
	PARENT_ID = 'parent_id',
	RELATED = 'related',
	SLACK_CHANNELS = 'slack_channels__contains',
	QUESTION_STATUS = 'question_status',
	QUESTION_PRIORITY = 'question_priority',
}

export type SearchFilters = Record<FilterValue, FilterMenuItem>;

export const DEFAULT_FILTER_OPTIONS: SearchFilters = {
	[FilterValue.NATIVE_TYPE]: {
		label: 'Type',
		value: FilterValue.NATIVE_TYPE,
		icon: IconLayoutGrid,
		type: [],
		options: [
			{
				label: `${startCase(EntityType.dictionary_term)}s`,
				value: EntityType.dictionary_term,
				icon: IconBook,
				entityType: EntityType.dictionary_term,
			},
			{
				label: `${startCase(EntityType.metric)}s`,
				value: EntityType.metric,
				icon: IconChartHistogram,
				entityType: EntityType.metric,
			},
			{
				label: `${startCase(EntityType.document)}s`,
				value: EntityType.document,
				icon: IconFileDescription,
				entityType: EntityType.document,
			},
			{
				label: `${startCase(EntityType.question)}s`,
				value: EntityType.question,
				icon: IconQuestionMark,
				entityType: EntityType.question,
			},
			{
				label: `${startCase(EntityType.collection)}s`,
				value: EntityType.collection,
				icon: IconFolders,
				entityType: EntityType.collection,
			},
		],
		isRadio: false,
		isInclude: true,
		selectedOptions: [],
	},
	[FilterValue.PUBLISHED]: {
		label: 'Published',
		value: FilterValue.PUBLISHED,
		icon: IconCircle,
		type: [EntityType.all],
		options: [
			{
				label: 'Published',
				value: true,
			},
			{
				label: 'Draft',
				value: false,
			},
		],
		isRadio: true,
		isInclude: true,
		selectedOptions: [],
	},
	[FilterValue.VERIFIED]: {
		label: 'Verification',
		value: FilterValue.VERIFIED,
		icon: IconDiscountCheck,
		type: [EntityType.all],
		options: [
			{
				label: 'Verified',
				value: true,
			},
			{
				label: 'Unverified',
				value: false,
			},
		],
		isRadio: true,
		isInclude: true,
		selectedOptions: [],
	},
	[FilterValue.PII]: {
		label: 'PII',
		value: FilterValue.PII,
		icon: IconShieldLock,
		type: [EntityType.all],
		options: [
			{
				label: 'PII',
				value: true,
			},
			{
				label: 'Non-PII',
				value: false,
			},
		],
		isRadio: true,
		isInclude: true,
		selectedOptions: [],
	},
	[FilterValue.COLLECTIONS]: {
		label: 'Collection',
		value: FilterValue.COLLECTIONS,
		icon: IconFolders,
		type: [EntityType.all],
		options: [],
		isRadio: false,
		isInclude: true,
		selectedOptions: [],
	},
	[FilterValue.OWNERS]: {
		label: 'Owner',
		value: FilterValue.OWNERS,
		icon: IconUser,
		type: [EntityType.all],
		options: [],
		isRadio: false,
		isInclude: true,
		selectedOptions: [],
	},
	[FilterValue.QUESTION_STATUS]: {
		label: 'Status',
		value: FilterValue.QUESTION_STATUS,
		icon: IconLoader,
		type: [EntityType.question],
		options: [
			{
				label: 'Answered',
				value: 'ANSWERED',
			},
			{
				label: 'In review',
				value: 'IN_REVIEW',
			},
			{
				label: 'In progress',
				value: 'IN_PROGRESS',
			},
			{
				label: 'Unanswered',
				value: 'UNANSWERED',
			},
			{
				label: 'Cancelled',
				value: 'CANCELLED',
			},
		],
		isRadio: false,
		isInclude: true,
		selectedOptions: [],
	},
	[FilterValue.TAGS]: {
		label: 'Tag',
		value: FilterValue.TAGS,
		icon: IconTag,
		type: [EntityType.all],
		options: [],
		isRadio: false,
		isInclude: true,
		selectedOptions: [],
	},
	[FilterValue.DATABASE]: {
		label: 'Database',
		value: FilterValue.DATABASE,
		icon: IconDatabase,
		type: [EntityType.all],
		options: [],
		isRadio: false,
		isInclude: true,
		selectedOptions: [],
	},
	[FilterValue.SCHEMA]: {
		label: 'Schema',
		value: FilterValue.SCHEMA,
		icon: IconSchema,
		type: [EntityType.all],
		options: [],
		isRadio: false,
		isInclude: true,
		selectedOptions: [],
	},
	[FilterValue.PARENT_ID]: {
		label: 'Group',
		value: FilterValue.PARENT_ID,
		icon: IconFolder,
		type: [EntityType.all],
		options: [],
		isRadio: false,
		isInclude: true,
		selectedOptions: [],
	},
	[FilterValue.QUESTION_PRIORITY]: {
		label: 'Priority',
		value: FilterValue.QUESTION_PRIORITY,
		icon: IconListNumbers,
		type: [EntityType.question],
		options: [
			{
				label: 'No priority',
				value: 'NONE',
			},
			{
				label: 'Low',
				value: 'LOW',
			},
			{
				label: 'Medium',
				value: 'MEDIUM',
			},
			{
				label: 'High',
				value: 'HIGH',
			},
		],
		isRadio: false,
		isInclude: true,
		selectedOptions: [],
	},
	[FilterValue.INTEGRATION_NAME]: {
		label: 'Integration',
		value: FilterValue.INTEGRATION_NAME,
		icon: IconPlugConnected,
		type: [EntityType.all],
		options: [],
		isRadio: false,
		isInclude: true,
		selectedOptions: [],
	},
	[FilterValue.SOURCES]: {
		label: 'Source',
		value: FilterValue.SOURCES,
		icon: IconSourceCode,
		type: [
			EntityType.table,
			EntityType.column,
			EntityType.schema,
			EntityType.database,
		],
		options: [],
		isRadio: false,
		isInclude: true,
		selectedOptions: [],
	},
	[FilterValue.SLACK_CHANNELS]: {
		label: 'Slack channel',
		value: FilterValue.SLACK_CHANNELS,
		icon: IconBrandSlack,
		type: [EntityType.all],
		options: [],
		isRadio: false,
		isInclude: true,
		selectedOptions: [],
	},
	// For related filters, we have to implement a custom component because it depends on
	// first lazy loading the list of entities based on search term, then selecting one of them
	[FilterValue.RELATED]: {
		label: 'Related',
		value: FilterValue.RELATED,
		options: [],
		type: [EntityType.all],
		isRadio: false,
		icon: IconArrowUpRight,
		isInclude: true,
		selectedOptions: [],
	},
};

export type FilterSelection = {
	selectedOptionValues: (string | boolean)[];
	isInclude: boolean;
};

export type SearchView = {
	label: string;
	value: string;
	selectedFilters: Record<FilterValue, FilterSelection>;
	isPersonal: boolean;
	teams: string[];
};
