import { FilterOptionType } from '../Filter';

export const DEFAULT_PAGINATION_SIZE = 50;
export const DEFAULT_MAX_RECORD_SIZE = 10000;

export const STICKY_COLUMNS = ['title', 'name'];

export const SKIP_RESTRICTED_FILTERS = [
	FilterOptionType.AI,
	FilterOptionType.IS_TESTED,
	FilterOptionType.TESTS_PASSING,
	FilterOptionType.IS_MONITORED,
	FilterOptionType.MONITORS_PASSING,
];
