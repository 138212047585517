import { EntityType } from '../../lib/types';
import { IRichEditorProps } from '../RichEditor';

interface EntityModalConfig {
	// by default, templates have a definition field, but for some types, we also have a description field in the template that should be used instead
	richTextTemplateField: 'definition' | 'description';
	richEditorPlaceholder: string;
	richEditorProps?: Partial<IRichEditorProps>;
	withTemplate?: boolean;
	withOwners?: boolean;
	withTags?: boolean;
	withCollections?: boolean;
	withParentCollection?: boolean;
	withAssignee?: boolean;
	withPriority?: boolean;
}

export const ENTITY_TYPES_MODAL_CONFIG: Record<string, EntityModalConfig> = {
	[EntityType.collection]: {
		richTextTemplateField: 'definition',
		richEditorPlaceholder: 'Add description...',
		withTemplate: true,
		withOwners: true,
		withParentCollection: true,
		withTags: true,
	},
	[EntityType.dictionary_term]: {
		richTextTemplateField: 'description',
		richEditorPlaceholder: 'Add dictionary term information...',
		withTemplate: true,
		withOwners: true,
		withCollections: true,
		withTags: true,
	},
	[EntityType.document]: {
		richTextTemplateField: 'definition',
		richEditorPlaceholder: 'Add document information...',
		withTemplate: true,
		withOwners: true,
		withCollections: true,
		withTags: true,
	},
	[EntityType.query]: {
		richTextTemplateField: 'definition',
		richEditorPlaceholder: 'Add query description...',
		withTemplate: true,
		withOwners: true,
		withCollections: true,
		withTags: true,
	},
	[EntityType.glossary]: {
		richTextTemplateField: 'description',
		richEditorPlaceholder: 'Add glossary term information...',
		withTemplate: true,
		withOwners: true,
		withCollections: true,
		withTags: true,
	},
	[EntityType.metric]: {
		richTextTemplateField: 'definition',
		richEditorPlaceholder: 'Add brief metric description...',
		withOwners: true,
		withCollections: true,
		withTags: true,
		richEditorProps: {
			limited: true,
		},
	},
	[EntityType.question]: {
		richTextTemplateField: 'definition',
		richEditorPlaceholder: 'Describe your question...',
		withAssignee: true,
		withPriority: true,
		withTags: true,
	},
};
