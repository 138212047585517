import {
	Box,
	Group,
	Select,
	SimpleGrid,
	Slider,
	Stack,
	createStyles,
} from '@mantine/core';
import { Icon, NumberInput, Text, Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { ScheduleCadence } from '../../../../api/types/models/schedule';
import { THRESHOLD_OPTIONS } from '../../../../pages/MonitorPage/constants';
import { getMonitorMetricUnit } from '../../../../pages/MonitorPage/utils';
import HorizontalScheduleSelector from '../../../ScheduleSelector/HorizontalScheduleSelector';
import { useAddMonitorStoreContext } from '../context';

const useStyles = createStyles((theme) => ({
	scheduleSelector: {
		fontSize: theme.fontSizes.sm,
		border: 'solid',
		borderWidth: 0.5,
		borderRadius: theme.radius.sm,
		borderColor: theme.other.getColor('border/input/default'),
		padding: `${theme.spacing.sm} ${theme.spacing.xs} ${theme.spacing.sm} ${theme.spacing.xs}`,
	},
	scheduleSelectorWarning: {
		border: 'solid',
		borderWidth: 0.5,
		borderRadius: theme.radius.md,
		borderColor: theme.other.getColor('fill/transparent/active'),
		boxShadow: theme.shadows.md,
		padding: theme.spacing.sm,
	},
	scheduleSelectorWarningIcon: {
		backgroundColor: theme.other.getColor('fill/caution/default'),
		borderRadius: theme.radius.sm,
		padding: theme.spacing['3xs'],
	},
	sliderMarkLabel: {
		color: theme.other.getColor('text/primary/default'),
	},
}));

function ConfigureStack() {
	const { classes } = useStyles();

	const store = useAddMonitorStoreContext();

	return (
		<Stack p={0} spacing="md">
			<Title size="md">Configure</Title>
			<Stack p={0} spacing="sm">
				<Stack p={0} spacing="xs">
					<Text size="sm" weight="semibold">
						Schedule
					</Text>
					<HorizontalScheduleSelector
						schedule={store.schedule}
						onScheduleUpdate={async (props) => store.setSchedule(props)}
						className={classes.scheduleSelector}
					/>
				</Stack>
				{store.schedule.cadence === ScheduleCadence.WEEKLY && (
					<Group noWrap className={classes.scheduleSelectorWarning}>
						<Box className={classes.scheduleSelectorWarningIcon}>
							<Icon name="alertTriangle" color="text/caution-on-fill/default" />
						</Box>
						<Text size="sm">
							Scheduling a weekly monitor may prolong automatic threshold
							determination. For faster results, consider manual thresholds or
							daily runs.
						</Text>
					</Group>
				)}
				<Stack p={0} spacing="xs">
					<Select
						label="Threshold"
						defaultValue={store.threshold}
						onChange={store.setThreshold}
						data={THRESHOLD_OPTIONS}
					/>
					{store.threshold === 'automatic' ? (
						<>
							<Text size="sm" color="text/secondary/default">
								Automatic thresholds use your monitor&apos;s historical data to
								adjust limits, improving accuracy progressively over time.
							</Text>
							<Text size="sm" color="text/primary/default" weight="semibold">
								Threshold sensitivity
							</Text>
							<Slider
								classNames={{
									markLabel: classes.sliderMarkLabel,
								}}
								pb="lg"
								w="100%"
								color="dark"
								label={null}
								showLabelOnHover={false}
								step={1}
								min={1}
								max={10}
								value={store.autoSensitivity}
								onChange={store.setAutoSensitivity}
								onChangeEnd={store.setAutoSensitivity}
								marks={[
									{ value: 1.05, label: 'Low' },
									{ value: 5, label: 'Default' },
									{ value: 9.9, label: 'High' },
								]}
							/>
							<Text size="sm" color="text/secondary/default">
								If adjusted, higher sensitivity catches smaller details, while
								lower sensitivity focuses on bigger changes.
							</Text>
						</>
					) : (
						<SimpleGrid cols={2}>
							<NumberInput
								label="Minimum"
								defaultValue={undefined}
								value={store.minSensitivity}
								onChange={store.setMinSensitivity}
								error={store.minSensitivityError}
								unit={getMonitorMetricUnit(store.monitorSpec.type.metric_type)}
							/>
							<NumberInput
								label="Maximum"
								defaultValue={undefined}
								value={store.maxSensitivity}
								onChange={store.setMaxSensitivity}
								error={store.maxSensitivityError}
								unit={getMonitorMetricUnit(store.monitorSpec.type.metric_type)}
							/>
						</SimpleGrid>
					)}
				</Stack>
			</Stack>
			<Stack p={0} spacing="xs" />
		</Stack>
	);
}

export default observer(ConfigureStack);
