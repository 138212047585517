import { Group, Stack, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Icon, IconNames, Text, Title } from '@repo/foundations';
import { isEmpty } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import {
	IBaseModel,
	IIntegration,
	integrationsQueryKeyFactory,
	queryClient,
	updateIntegration,
	useCreateDatabuilderJob,
	useDeleteIntegration,
} from '../../api';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import type { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';

import {
	incidentsQueryKeyFactory,
	monitorsQueryKeyFactory,
} from '../../api/hooks/monitoring/constants.ts';
import IntegrationLogo from '../../components/IntegrationLogo';
import { useDeleteManyAction } from '../../components/TableV2/BaseModel.hooks';
import {
	IntegrationStatusRender,
	RelativeTimeRender,
	TeamRender,
} from '../../components/TableV2/render';
import { DatabuilderJobType } from '../../interfaces';
import { EntityType } from '../../lib/types';
import { formatIntegrationType } from '../../utils/stringUtils';

export function useColumns<
	T extends IIntegration,
>(): ExtendedDataTableColumn<T>[] {
	const theme = useMantineTheme();

	const handleChangeIntegration = useCallback(
		(field: string) => (id: string) => (value: unknown) =>
			updateIntegration({
				data: {
					id,
					[field]: value,
				},
			}),
		[]
	);

	const columns: ExtendedDataTableColumn<T>[] = useMemo(
		() => [
			{
				accessor: 'name',
				title: 'Name',
				render: (record: IIntegration) => (
					<Group role="link" spacing="xs" noWrap>
						<IntegrationLogo
							integrationId={record.id}
							integrationType={record.type}
							width={theme.other.space[4]}
							height={theme.other.space[4]}
						/>
						<Stack spacing={0}>
							<Title size="sm" truncate>
								{isEmpty(record.name) ? 'Untitled' : record.name}
							</Title>
							<Text size="xs" color="text/secondary/default">
								{formatIntegrationType(record.type)}
							</Text>
						</Stack>
					</Group>
				),
			},
			{
				accessor: 'status',
				title: 'Status',
				render: (record: IIntegration) => (
					<IntegrationStatusRender record={record} />
				),
			},
			{
				accessor: 'scheduled_extractions_last_run',
				title: 'Last sync',
				render: (record: IIntegration) => (
					<RelativeTimeRender
						record={record}
						field={'scheduled_extractions_last_run'}
					/>
				),
			},
			{
				accessor: 'scheduled_extractions_next_run',
				title: 'Next sync',
				render: (record: IIntegration) => (
					<RelativeTimeRender
						record={record}
						field={'scheduled_extractions_next_run'}
						isFuture
					/>
				),
			},
			{
				navigate: false,
				accessor: 'teams',
				title: 'Teams',
				render: (record: IIntegration) => (
					<TeamRender
						record={record}
						onChange={handleChangeIntegration('teams')}
					/>
				),
			},
		],
		[handleChangeIntegration, theme.other.space]
	);

	return columns;
}

export const useActions = (): ICommandListItem<IIntegration>[] => {
	const { mutateAsync: createDatabuilderJob } = useCreateDatabuilderJob({});
	const { action } = useDeleteManyAction({
		name: 'integration',
		queryKeyFactory: integrationsQueryKeyFactory,
		useDelete: useDeleteIntegration,
	});

	const handleRunExtractions = useCallback(
		async (selected: IIntegration[]) => {
			const isRunning = selected.some(
				(integration) =>
					integration.status === 'running' || integration.status === 'queued'
			);

			if (isRunning) {
				showNotification({
					title: 'Integrations are queued or running',
					message:
						'Some of the selected integrations are queued or running. Please wait for the integrations to finish running to run sync again',
					color: 'red',
					icon: <Icon name="alertCircle" />,
				});
				return;
			}

			const promises = selected.map((integration) =>
				createDatabuilderJob({
					data: {
						integration_id: integration.id,
						type: DatabuilderJobType.METADATA,
					},
				})
			);

			try {
				await Promise.all(promises);
				showNotification({
					title: 'Integrations queued for running',
					message: 'The integrations have been queued for running',
					color: 'green',
					icon: <Icon name="check" />,
				});

				// ENG-10149 Invalidate the queries for monitors and incidents
				await queryClient.invalidateQueries(monitorsQueryKeyFactory.allLists());
				await queryClient.invalidateQueries(
					incidentsQueryKeyFactory.allLists()
				);
			} catch (e) {
				showNotification({
					title: 'Error running integrations',
					message: 'If the issues persists, please contact support',
					color: 'red',
					icon: <Icon name="alertCircle" />,
				});
			}
		},
		[createDatabuilderJob]
	);

	const actions = useMemo(
		() => [
			{
				id: 'actions::run',
				title: 'Run sync',
				name: 'Run',
				quickAction: { label: 'Run Run sync', value: true },
				iconName: 'playerPlay' as IconNames,
				hotkey: '/mp',
				type: EntityType.integration,
				team: undefined,
				category: 'actions',
				show: true,
				onClick: handleRunExtractions as (
					selected: IBaseModel[]
				) => Promise<void>,
			},
			action,
		],
		[action, handleRunExtractions]
	);

	return actions;
};
