import { Skeleton } from '@mantine/core';
import { IconSizes } from '../Icon';
import { IconSizeMap } from '../Icon/Icon';

interface IconSkeletonProps {
	size?: IconSizes;
}

export function IconSkeleton({ size = 'md' }: IconSkeletonProps) {
	return <Skeleton width={IconSizeMap[size]} height={IconSizeMap[size]} />;
}
