import type { MantineTheme, SelectItemProps } from '@mantine/core';
import { Divider, Group, Stack, TextInput, createStyles } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import type {
	FilterValue,
	FilterView,
} from '@repo/common/components/Filter/types';
import { IconWrapper } from '@repo/common/components/IconWrapper';
import { Button, MultiSelect, Text } from '@repo/foundations';
import { forwardRef } from 'react';
import { useFilterViewModal } from './useFilterViewModal';

const useStyles = createStyles((theme: MantineTheme) => ({
	modalTitle: {
		fontWeight: 600,
		fontSize: theme.fontSizes.md,
		lineHeight: theme.spacing.xl,
	},
	modalBody: {
		padding: 0,
	},
	valueWrapper: {
		borderRadius: theme.radius.sm,
		padding: `0 ${theme.spacing['2xs']}`,
	},

	buttonGroup: {
		padding: `0 ${theme.spacing['2xs']}`,
	},
}));

const Item = forwardRef<HTMLDivElement, SelectItemProps & { icon: string }>(
	({ label, icon, ...others }, ref) => (
		<div {...others} ref={ref}>
			<Group spacing="xs">
				<Text size="sm">{icon}</Text>
				{label}
			</Group>
		</div>
	)
);
Item.displayName = 'Item';

export interface FilterViewModalProps {
	onClose: (view?: FilterView | null) => void;
	view: FilterView | null;
	selectedFilters: FilterValue[];
}

export function FilterViewModal({
	onClose,
	view,
	selectedFilters,
}: FilterViewModalProps) {
	const { classes } = useStyles();

	const {
		handleNameChange,
		handleTeamChange,
		teams,
		selectedTeamIds,
		name,
		handleOnSave,
		handleOnUpdate,
	} = useFilterViewModal({ onClose, view, selectedFilters });

	const handleSave = async () => {
		await handleOnSave();
		showNotification({
			title: 'View created',
			message: 'Your view has been created successfully.',
		});
	};

	const handleUpdate = async () => {
		await handleOnUpdate();
		showNotification({
			title: 'View saved',
			message: 'Your view has been saved successfully.',
		});
	};

	return (
		<Stack className={classes.modalBody} spacing="md">
			<Stack spacing="3xs" className={classes.valueWrapper}>
				<Text size="sm" fw="500">
					Name
				</Text>
				<TextInput
					value={name}
					onChange={(e) => handleNameChange(e.currentTarget.value)}
				/>
			</Stack>
			<Stack spacing="3xs" className={classes.valueWrapper}>
				<Text size="sm" fw="500">
					Team visibility
				</Text>
				<MultiSelect
					data={teams ?? []}
					placeholder="Select"
					renderIcon={(item) => <IconWrapper>{item.icon}</IconWrapper>}
					setValue={handleTeamChange}
					value={selectedTeamIds}
				/>
			</Stack>
			<Divider />
			<Group spacing="xs" position="apart" className={classes.buttonGroup}>
				<Button onClick={() => onClose()} variant="default">
					Cancel
				</Button>
				{view ? (
					<Group spacing="xs" position="right">
						<Button onClick={handleSave} variant="default">
							Save as new view
						</Button>
						<Button onClick={handleUpdate} variant="primary">
							Update this view
						</Button>
					</Group>
				) : (
					<Button onClick={handleSave} variant="primary">
						Save
					</Button>
				)}
			</Group>
		</Stack>
	);
}
