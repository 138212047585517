import type { NodeType } from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';
import { Dispatch } from '../types';

export default function backspaceToParagraph(type: NodeType) {
	return function (state: EditorState, dispatch: Dispatch) {
		const { $from, from, to, empty } = state.selection;

		// If the selection has anything in it then use standard delete behavior
		if (!empty) {
			return null;
		}

		// Check we're in a matching node
		if ($from.parent.type !== type) {
			return null;
		}

		// Check if we're at the beginning of the heading
		const $pos = state.doc.resolve(from - 1);
		if ($pos.parent === $from.parent) {
			return null;
		}

		// Okay, replace it with a paragraph
		dispatch(
			state.tr
				.setBlockType(from, to, type.schema.nodes.paragraph)
				.scrollIntoView()
		);
		return true;
	};
}
