import { Group, Select, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button } from '@repo/foundations';
import { useRef, useState } from 'react';
import { ISecodaEntity } from '../../api';
import type { SecodaEntity } from '../../lib/models';
import { ChangeRequest as ChangeRequestModel } from '../../lib/models';
import MultiLineEditorTabs from '../CatalogView/TableView/selectors/MultiLineEditorSelector/MultiLineEditorTabs';

export interface IChangeRequestProps {
	entity: SecodaEntity | ISecodaEntity;
	close: () => void;
}

export function ChangeRequestModal({ entity, close }: IChangeRequestProps) {
	const [submitting, setSubmitting] = useState(false);

	const descriptionRef = useRef<string>(entity.description || '');

	const onSubmit = async () => {
		setSubmitting(true);
		await ChangeRequestModel.create({
			entity: entity.id,
			changes: { description: descriptionRef.current ?? '' },
		});
		setSubmitting(false);
		showNotification({
			title: 'Change request sent',
			message:
				'You will recieve a notification when an admin reviews the request',
			color: 'green',
		});
		close();
	};

	return (
		<Stack>
			<Select
				// These will be hardcoded since they don't change much between resources.
				value="Description"
				data={['Description']}
				required
				label="Field"
				placeholder="Select the field to change"
			/>
			<MultiLineEditorTabs
				placeholder="Enter the new description."
				charPress={undefined}
				defaultValue={entity.description}
				moveCaretToEnd={undefined}
				onChange={(value: string) => {
					descriptionRef.current = value;
				}}
			/>
			<Group position="right" mt="md">
				<Button onClick={onSubmit} type="submit" loading={submitting}>
					Submit
				</Button>
			</Group>
		</Stack>
	);
}
