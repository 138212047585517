import { Filter } from '@repo/api-codegen';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Icon } from '@repo/foundations';
import type { MouseEvent } from 'react';
import { useCallback } from 'react';
import { useCatalogInfiniteList } from '../../../api/hooks/resourceCatalog';
import { useEntityBreadcrumbTeam } from '../../../hooks/useEntityBreadcrumbs';
import { EntityType } from '../../../lib/types';
import { DataTreeLoader } from '../DataTreeLoader';
import { LoadMore, TreeNode } from '../TreeNode';
import { CommonEntityTreeNode, getSameEntityTypeChildren } from '../utils';

interface DocumentTreeProps {
	teamId: string;
}

export function DocumentTree({ teamId }: DocumentTreeProps) {
	const {
		data: result,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
	} = useCatalogInfiniteList({
		filters: {
			teams: teamId,
			filter: {
				operator: 'and',
				operands: [
					{
						operator: 'not',
						field: 'parent_id',
						operands: [],
					},
					{
						field: 'entity_type',
						operator: 'exact',
						value: EntityType.document,
					},
				],
			} as Filter,
			sort: {
				field: 'title',
				order: 'asc',
			},
		},
	});
	const nodes: CommonEntityTreeNode[] | undefined = result?.pages.map(
		(document) => ({
			id: document.id,
			entity: document,
			hasChildren: Boolean(document.has_child_of_same_type),
		})
	);

	const { setTeamBreadcrumb } = useEntityBreadcrumbTeam();

	const navigateHandler = useNavigateHandler();

	const handleNavigate = useCallback(
		(event: MouseEvent, node: CommonEntityTreeNode) => {
			setTeamBreadcrumb(teamId);
			navigateHandler(event)(`/docs/${node.id}`);
		},
		[]
	);

	if (!nodes) {
		return <DataTreeLoader />;
	}

	return (
		<>
			{nodes.map((node) => (
				<TreeNode
					key={node.id}
					node={node}
					onClick={handleNavigate}
					getIcon={() =>
						node.entity.icon ?? (
							<Icon name="file" color="icon/primary/default" />
						)
					}
					level={0}
					getLabel={(node) => node.entity.title}
					onLoadChildren={getSameEntityTypeChildren}
				/>
			))}
			{hasNextPage && (
				<LoadMore
					level={0}
					onClick={fetchNextPage}
					isLoading={isFetchingNextPage}
				/>
			)}
		</>
	);
}
