import { Group } from '@mantine/core';
import { useGetSlackChannels } from '@repo/api-codegen';
import { Icon, Title } from '@repo/foundations';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { ApiCatalogSort } from '../../../api';
import { ISecodaSlackChannel } from '../../../lib/models';
import { TeamRender, TextRender } from '../../TableV2/render.tsx';
import { ExtendedDataTableColumn } from '../../TableV2/types.ts';

export const useSecodaChannelList = () =>
	function (props: {
		page?: number | undefined;
		filters?: { search_term?: string; sort?: ApiCatalogSort };
	}) {
		const { page, filters } = props;

		return useGetSlackChannels({
			queryParams: {
				page: page || 1,
				search_term: filters?.search_term,
				page_size: 50,
				sort_by: JSON.stringify(filters?.sort) || JSON.stringify({}),
			},
		});
	};

export const useColumns = (): ExtendedDataTableColumn<ISecodaSlackChannel>[] =>
	useMemo(
		() => [
			{
				accessor: 'title',
				title: 'Title',
				width: 200,
				sortable: false,
				render: (channel) => (
					<Group spacing="xs" noWrap>
						<Icon name="hash" />
						<Title size="sm" truncate>
							{isEmpty(channel.title) ? 'Untitled' : channel.title}
						</Title>
					</Group>
				),
			},
			{
				accessor: 'teams',
				title: 'Teams',
				render: (record) => <TeamRender record={record} />,
			},
			{
				accessor: 'channel_type',
				title: 'Type',
				render: (record) => <TextRender record={record} field="channel_type" />,
			},
		],
		[]
	);
