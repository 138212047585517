/* eslint-disable no-bitwise */
import {
	Box,
	Center,
	createStyles,
	Group,
	Stack,
	useMantineTheme,
} from '@mantine/core';
import {
	LiteIntegration,
	useApiSuperuserDeleteMetric,
	useApiSuperuserGetOrCreateMetric,
	useApiSuperuserUpdateMetric,
} from '@repo/api-codegen';
import { EmptyState } from '@repo/common/components';
import { Button, Icon, Text, Title } from '@repo/foundations';
import { useDebounceFn } from 'ahooks';
import dayjs from 'dayjs';
import { countBy } from 'lodash-es';
import * as monaco from 'monaco-editor';
import { LanguageIdEnum, setupLanguageFeatures } from 'monaco-sql-languages';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSuperuserMetricExecution } from '../../../api/hooks/metric/useMetricExecution';
import { MetricJobProgress } from '../../../components/BackgroundJobProgress/MetricJobProgress';
import { DataDisplayTable } from '../../../components/DataDisplayTable';
import ErrorDrawer from '../../../components/ErrorDrawer/ErrorDrawer';
import { ErrorAction } from '../../../components/Sql/Actions/ErrorAction';
import { RunAction } from '../../../components/Sql/Actions/RunAction';
import { completionService } from '../../../components/Sql/SqlEditor/languageSetup';
import { getEditorOptions } from '../../../components/Sql/SqlEditor/SqlEditor.helpers';
import { getMonacoTheme } from '../../../components/Sql/SqlEditor/SqlEditorTheme/monacoTheme';
import { hashCode } from '../../../utils/utils';

export interface InspectSQLBlockProps {
	blockId: string;
	integration: LiteIntegration;
	onDelete: () => void;
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		padding: theme.spacing.md,
		minHeight: 300,
	},
	editor: {
		height: '100%',
		width: '100%',
		minHeight: 280,
		border: 'solid',
		borderRadius: theme.spacing.md,
		padding: theme.spacing.md,
	},
	results: {
		width: '100%',
		height: '100%',
		overflowX: 'auto',
		maxWidth: '90vw',
	},
	warningBox: {
		border: 'solid',
		borderWidth: 0.5,
		borderRadius: theme.radius.md,
		borderColor: theme.other.getColor('fill/transparent/active'),
		boxShadow: theme.shadows.md,
		padding: theme.spacing.sm,
	},
	warningIcon: {
		backgroundColor: theme.other.getColor('fill/caution/default'),
		borderRadius: theme.radius.sm,
		padding: theme.spacing['3xs'],
	},
}));

export default function InspectSQLBlock({
	blockId,
	integration,
	onDelete,
}: InspectSQLBlockProps) {
	const theme = useMantineTheme();
	const hostRef = useRef<HTMLDivElement>(null);
	const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();

	const { classes } = useStyles();

	// === Metric hooks
	// get or create
	const { data: metric } = useApiSuperuserGetOrCreateMetric({
		queryParams: {
			integration_id: integration.id,
			id: blockId,
		},
	});

	// delete
	const { mutateAsync: deleteMetric } = useApiSuperuserDeleteMetric({});
	const handleDelete = () => {
		deleteMetric({
			pathParams: {
				id: blockId,
			},
		});
		onDelete();
	};

	// Debounce update and flush
	const { mutateAsync } = useApiSuperuserUpdateMetric({});
	const updateMetric = async (sql: string) => {
		if (!metric) {
			return;
		}

		await mutateAsync({
			pathParams: {
				id: blockId,
			},
			body: {
				sql,
			},
		});
	};
	const { run: debounceUpdateMetric, flush: flushUpdateMetric } = useDebounceFn(
		updateMetric,
		{ wait: 350 }
	);

	// Run query
	const { execute, executionStatus, isExecuting } =
		useSuperuserMetricExecution(metric);
	const handleExecute = useCallback(async () => {
		await flushUpdateMetric();
		await execute();
	}, [execute, flushUpdateMetric]);
	const [showErrorDrawer, setShowErrorDrawer] = useState(false);

	// Results
	const results = executionStatus?.results ?? [];
	const key = hashCode(JSON.stringify(results));
	const [query, setQuery] = useState(metric?.sql || '');
	const sqlWarning = useMemo(() => {
		if (!query.toLowerCase().includes('limit')) {
			return 'Warning: Query does not contain a LIMIT clause. This will return ALL ROWS';
		}
	}, [query]);

	// Render editor
	useEffect(() => {
		if (hostRef.current && !editorRef.current) {
			setupLanguageFeatures(LanguageIdEnum.MYSQL, {
				completionItems: {
					enable: true,
					completionService: completionService(
						integration.id,
						integration.type || undefined
					),
				},
			});

			monaco.editor.defineTheme('sqlTheme', getMonacoTheme(theme));
			editorRef.current = monaco.editor.create(hostRef.current, {
				language: LanguageIdEnum.MYSQL,
				theme: 'sqlTheme',
				...getEditorOptions(true, false, false),
				glyphMargin: countBy(query)?.['\n'] > 20,
				value: '',
			});
			editorRef.current.onEndUpdate(() => {
				const value = editorRef?.current?.getValue() ?? '';
				setQuery(value);
				debounceUpdateMetric(value);
			});
			editorRef.current.addAction({
				id: 'runQuery',
				label: 'Run Query',
				precondition: 'editorTextFocus',
				keybindings: [
					monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
					monaco.KeyMod.WinCtrl | monaco.KeyCode.Enter,
					monaco.KeyMod.Shift | monaco.KeyCode.Enter,
				],
				run: () => {
					handleExecute();
				},
			});
		}
	}, [
		debounceUpdateMetric,
		integration.id,
		integration.type,
		handleExecute,
		query,
		theme,
	]);

	return (
		<Box className={classes.wrapper}>
			<Group noWrap className={classes.warningBox} mb="md">
				<Box className={classes.warningIcon}>
					<Icon name="alertTriangle" color="text/caution-on-fill/default" />
				</Box>
				<Stack spacing="xs">
					<Text size="md" weight="bold">
						ONLY RUN QUERY AGAINST CUSTOMER DATABASE AS LAST RESORT. THIS SHOULD
						NOT BE THE FIRST STEP OF DEBUGGING.
					</Text>
					<Text size="md" weight="bold">
						SHOULD ONLY BE USED BY ENGINEERS ON SUPPORT HERO. ASK QUESTIONS IF
						YOU'RE UNSURE.
					</Text>
				</Stack>
			</Group>
			<Group position="apart" w="100%" mb="md">
				<Button onClick={handleDelete} leftIconName="trash" size="md">
					Delete block
				</Button>
				<Group>
					<Text weight="bold" color="text/critical/default">
						{sqlWarning}
					</Text>
					<ErrorAction
						disabled={executionStatus?.status !== 'failed'}
						handleShow={() => setShowErrorDrawer(true)}
					/>
					<MetricJobProgress
						isExecuting={isExecuting}
						logs={executionStatus?.logs || undefined}
					/>
					<RunAction disabled={false} handleExecute={handleExecute} />
				</Group>
			</Group>
			<div ref={hostRef} className={classes.editor} />
			<Box key={key} className={classes.results}>
				<Title order={5} py="md">
					Results
				</Title>
				{/* We check for results.length <= 1 because the first row is the column names. */}
				{results.length <= 1 && (
					<Center data-testid="sql-editor-preview-empty-state" py={60}>
						<EmptyState
							size="lg"
							iconName="tableRow"
							title="No data found"
							description="This query returned no rows. Try adjusting the query"
							includeGoBack={false}
						/>
					</Center>
				)}
				{results.length > 0 && (
					<DataDisplayTable results={results as unknown as any[][]} />
				)}
			</Box>
			{executionStatus?.status === 'failed' && (
				<ErrorDrawer
					title={/\(.*errors.(.*)\)/.exec(executionStatus?.logs || '')?.[1]}
					errorMessage={executionStatus?.logs || ''}
					open={showErrorDrawer}
					onClose={() => setShowErrorDrawer(false)}
					errorAt={dayjs().toISOString()}
				/>
			)}
		</Box>
	);
}
