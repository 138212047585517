export function extractImageSizesFromSrc(src: string): {
	width: string | number | null;
	height: string | number | null;
} {
	let width = null;
	let height = null;

	const heightWidthRegex = /[a-zA-Z/]=([0-9]+)x([0-9]+)?$/;
	const heightWidthMatch = src.match(heightWidthRegex);

	if (heightWidthMatch && heightWidthMatch.length === 3) {
		width = heightWidthMatch[1].replace(/\D/g, '');
		height = heightWidthMatch[2].replace(/\D/g, '');
	} else {
		const onlyWidthRegex = /[a-zA-Z/]=([0-9]+)$/;
		const onlyWidthMatch = src.match(onlyWidthRegex);
		if (onlyWidthMatch && onlyWidthMatch.length === 2) {
			width = onlyWidthMatch[1].replace(/\D/g, '');
		}
	}

	let numWidth = parseFloat(width as string);
	const numHeight = parseFloat(height as string);

	if (!numWidth || Number.isNaN(numWidth)) {
		numWidth = 250;
	}

	return {
		width: numWidth,
		height: Number.isNaN(numHeight) ? null : numHeight,
	};
}
