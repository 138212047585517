import { Avatar, Group, createStyles } from '@mantine/core';
import { pluralize } from '@repo/common/utils';
import { Text } from '@repo/foundations';
import type { ColorNames } from '@repo/theme/utils';
import { lowerCase, slice } from 'lodash-es';
import { useMemo } from 'react';
import type { ITag, SearchResult } from '../../../../api';
import { filterTagsByIds } from '../../../../api';
import CollectionAvatar from '../../../../components/EntityAvatarGroup/CollectionAvatar';
import { TagAvatar } from '../../../../components/EntityAvatarGroup/TagAvatar';
import { UserAvatar } from '../../../../components/UserAvatar';
import type { Collection, User } from '../../../../lib/models';
import { EntityType } from '../../../../lib/types';

const useStyles = createStyles((theme, { bg }: { bg?: ColorNames }) => ({
	wrapper: {
		background: bg ? theme.other.getColor(bg) : undefined,
		borderRadius: theme.radius.sm,
		padding: `${theme.spacing['4xs']} ${theme.spacing['2xs']}`,
	},
}));

interface EntityAvatarGroupProps {
	entity: SearchResult;
	type: EntityType | 'tag';
	bg?: ColorNames;
}

function EntityAvatar({
	item,
	type,
}: {
	item: Collection | User | ITag;
	type: EntityType | 'tag';
}) {
	if (type === EntityType.collection) {
		return (
			<CollectionAvatar
				key={item.id}
				collection={item as Collection}
				size="xs"
			/>
		);
	}
	if (type === EntityType.user) {
		return <UserAvatar key={item.id} user={item as User} size="xxs" />;
	}
	if (type === 'tag') {
		return <TagAvatar key={item.id} tag={item as ITag} />;
	}
	return null;
}

export function EntityAvatarGroupV2({
	entity,
	type,
	bg,
}: EntityAvatarGroupProps) {
	const { classes } = useStyles({ bg });

	const collectionsData = entity.display_metadata.collections;
	const usersData = entity.display_metadata.owners;
	const tagsData = filterTagsByIds(entity.tags);

	const items = useMemo(() => {
		const entities = [];

		if (type === EntityType.collection) {
			entities.push(...collectionsData);
		} else if (type === EntityType.user) {
			entities.push(...usersData);
		} else if (type === 'tag') {
			entities.push(...tagsData);
		}

		return entities;
	}, [collectionsData, tagsData, type, usersData]) as Array<
		Collection | User | ITag
	>;

	if (items.length === 0) {
		return null;
	}

	const itemsToRender = items.length > 3 ? slice(items, 0, 3) : items;

	if (itemsToRender.length === 1) {
		let label = '';

		if (type === EntityType.collection) {
			label = (itemsToRender[0] as Collection).title;
		} else if (type === EntityType.user) {
			label = (itemsToRender[0] as User).display_name;
		} else if (type === 'tag') {
			label = (itemsToRender[0] as ITag).name;
		}

		return (
			<Group spacing="2xs">
				<EntityAvatar item={itemsToRender[0]} type={type} />
				<Text size="sm">{label}</Text>
			</Group>
		);
	}

	return (
		<Group spacing="3xs" className={classes.wrapper}>
			<Avatar.Group spacing="xs">
				{itemsToRender.map((item: Collection | User | ITag) => (
					<EntityAvatar key={item.id} item={item} type={type} />
				))}
			</Avatar.Group>
			<Text size="xs">{pluralize(lowerCase(type), items?.length, true)}</Text>
		</Group>
	);
}
