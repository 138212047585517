import type { IconNames } from '@repo/foundations';
import type { IBaseModel } from '../../../../api';
import type { EntityType } from '../../../../lib/types';

export interface ICommandListItem<T extends IBaseModel> {
	id: string;
	title: string;
	name: string;
	link?: string | null;
	show: boolean | ((selected: T[]) => boolean);
	iconName: IconNames | null;
	icon?: string;
	hotkey?: string;
	type: string | EntityType;
	team?: string | undefined;
	category?: string;
	selected?: T[];
	onClick?: (
		selected: T[],
		clearSelected: VoidFunction
	) => Promise<void> | void;
}

export const staticCommandList: ICommandListItem<IBaseModel>[] = [
	{
		id: 'command::Home',
		title: 'Home',
		name: 'Home',
		link: '/',
		show: true,
		iconName: 'home',
		hotkey: '/gh',
		type: 'static',
	},
	{
		id: 'command::Inbox',
		title: 'Inbox',
		name: 'Inbox',
		link: '/inbox',
		show: true,
		iconName: 'inbox',
		hotkey: '/gi',
		type: 'static',
	},
	{
		id: 'command::Search',
		title: 'Search',
		name: 'Search',
		link: '/search',
		show: true,
		iconName: 'search',
		hotkey: '/gs',
		type: 'static',
	},
	{
		id: 'command::Teams',
		title: 'Teams',
		name: 'Teams',
		link: '/teams',
		show: true,
		iconName: 'users',
		hotkey: '/gt',
		type: 'static',
	},
	{
		id: 'command::Assistant',
		title: 'Secoda AI',
		name: 'Secoda AI',
		link: '/ai/new',
		show: true,
		iconName: 'sparkles',
		hotkey: '/ai',
		type: 'static',
	},
	{
		id: 'command::Integrations',
		title: 'Integrations',
		name: 'Integrations',
		link: '/integrations',
		show: true,
		iconName: 'plugConnected',
		hotkey: '/in',
		type: 'static',
	},
	{
		id: 'command::Queries',
		title: 'Queries',
		name: 'Queries',
		link: '/queries',
		show: true,
		iconName: 'code',
		hotkey: '/qu',
		type: 'static',
	},
	{
		id: 'command::Analytics',
		title: 'Analytics',
		name: 'Analytics',
		link: '/analytics',
		show: true,
		iconName: 'chartBar',
		hotkey: '/ga',
		type: 'static',
	},
	{
		id: 'command::Settings',
		title: 'Settings',
		name: 'Settings',
		link: '/settings',
		show: true,
		iconName: 'settings',
		hotkey: '/se',
		type: 'static',
	},
	{
		id: 'command::Archive',
		title: 'Archive',
		name: 'Archive',
		link: '/archived',
		show: true,
		iconName: 'archive',
		hotkey: '/ar',
		type: 'static',
	},
];
export const groupCommandTitleList: ICommandListItem<IBaseModel>[] = [
	{
		id: 'title::Create',
		title: 'Create',
		name: 'Create',
		link: null,
		show: true,
		iconName: null,
		type: 'group',
	},
	{
		id: 'title::QuickNavigation',
		title: 'Quick navigation',
		name: 'Quick Navigation',
		link: null,
		show: true,
		iconName: null,
		type: 'group',
	},
	{
		id: 'title::Teams',
		title: 'Teams',
		name: 'Teams',
		link: null,
		show: true,
		iconName: null,
		type: 'group',
	},
	{
		id: 'title::Actions',
		title: 'Actions',
		name: 'Actions',
		link: null,
		show: true,
		iconName: null,
		type: 'group',
	},
];
