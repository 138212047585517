import { Box, Group } from '@mantine/core';
import { Button, ButtonProps, Icon } from '@repo/foundations';
import { ForwardedRef, forwardRef, ReactNode } from 'react';

interface SelectorTargetProps extends ButtonProps {
	children: ReactNode;
}

function SelectorTargetInternal(
	{ children, ...buttonProps }: SelectorTargetProps,
	ref: ForwardedRef<HTMLButtonElement>
) {
	return (
		<Button size="lg" {...buttonProps} ref={ref}>
			<Group spacing="2xs" style={{ flex: 1 }} position="apart">
				<Box style={{ flex: 1 }}>{children}</Box>
				<Icon name="selector" />
			</Group>
		</Button>
	);
}

export const SelectorTarget = forwardRef<
	HTMLButtonElement,
	SelectorTargetProps
>(SelectorTargetInternal);
