import { Box, createStyles, Stack } from '@mantine/core';
import { Text } from '@repo/foundations';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { ILineage } from '../../../api';
import { useLineageList } from '../../../api/hooks/lineage/useLineage';
import { TableV2 } from '../../../components/TableV2';
import { ExtendedDataTableColumn } from '../../../components/TableV2/types';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { useColumns } from './DownstreamResourcesTable.hooks';

export interface IDownstreamResourcesTableProps {
	targetId?: string;
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		height: '100%',
		flexGrow: 1,
	},
	title: {
		fontWeight: theme.other.typography.weight.bold,
	},
}));

function DownstreamResourcesTable({
	targetId,
}: IDownstreamResourcesTableProps) {
	const { monitoringV2 } = useFeatureFlags();
	const { classes } = useStyles();
	const navigate = useNavigate();

	const columns = useColumns();

	const handleClick = useCallback(
		(id: string) => {
			navigate(`/resource/${id}`);
		},
		[navigate]
	);

	if (!targetId) {
		return null;
	}

	return (
		<Stack className={classes.wrapper}>
			{!monitoringV2 && (
				<Text className={classes.title}>Downstream Resources</Text>
			)}
			<Box>
				<TableV2<ILineage>
					pluralTypeString="resources"
					withCheckbox={false}
					withInteractiveHeader
					withSearch
					withFilters={false}
					defaultSort={null}
					withCsvExport
					columns={columns}
					usePaginationList={useLineageList}
					defaultRequiredSearchParams={{
						id: targetId,
						depth: 3,
						direction: 'DOWNSTREAM',
					}}
					onCellClick={(cell: {
						column: ExtendedDataTableColumn<ILineage>;
						record: { id: string };
					}) => {
						if (cell.column.navigate !== false) {
							handleClick(cell.record.id);
						}
					}}
				/>
			</Box>
		</Stack>
	);
}

export default memo(DownstreamResourcesTable);
