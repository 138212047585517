import { Group, Stack, Table, useMantineTheme } from '@mantine/core';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Text } from '@repo/foundations';
import { useUserList } from '../../../api';
import { UserAvatar } from '../../../components/UserAvatar';
import { snakeCaseToTitleCase } from '../../../utils/shared.utils';
import { isNullOrWhiteSpace } from '../../../utils/stringUtils';
import type { TableComponentProps } from './MetricWidgetTable';

export function MetricUserTable({ infoColumns, values }: TableComponentProps) {
	const theme = useMantineTheme();
	const { data } = useUserList({
		filters: {
			email__in: values.map((v) => v.email).join(','),
		},
		options: {
			suspense: false,
		},
	});
	const navigateHandler = useNavigateHandler();

	const getUserByEmail = (email: string) =>
		data?.results.find((user) => user.email === email);

	const rows = values.map((user) => {
		const fullUser = getUserByEmail(user.email as string);

		const name = isNullOrWhiteSpace(user.first_name as string)
			? user.email
			: `${user.first_name} ${user.last_name}`;

		return (
			<tr
				style={{
					cursor: 'pointer',
				}}
				key={user.email}
				onClick={(e) => fullUser && navigateHandler(e)(`/user/${fullUser.id}`)}
			>
				<td>
					<Group h={52} noWrap>
						{fullUser ? <UserAvatar user={fullUser} size="xxs" /> : null}
						<Stack spacing={0}>
							<Text
								color="text/primary/default"
								size="sm"
								fw={500}
								lineClamp={1}
							>
								{name}
							</Text>
							<Text color="text/secondary/default" size="xs">
								{snakeCaseToTitleCase(
									getUserByEmail(user.email as string)?.role || ''
								)}
							</Text>
						</Stack>
					</Group>
				</td>
				{infoColumns.map((col) => (
					<td key={col.fieldName}>{user[col.fieldName]}</td>
				))}
			</tr>
		);
	});

	return (
		<Table highlightOnHover w="100%">
			<thead>
				<tr>
					<th>
						<Text color="text/secondary/default" fz="xs" fw={500}>
							User
						</Text>
					</th>
					{infoColumns.map((col) => (
						<th key={col.fieldName}>
							<Text color="text/secondary/default" fz="xs" fw={500}>
								{col.label}
							</Text>
						</th>
					))}
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</Table>
	);
}
