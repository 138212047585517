import { createStyles } from '@mantine/core';
import RichTooltip from '@repo/common/components/RichTooltip/RichTooltip';
import { Text } from '@repo/foundations';
import { filter, isEmpty } from 'lodash-es';
import { ReactNode, useMemo } from 'react';
import { IReport, reportsQueryKeyFactory, useDeleteReport } from '../../api';
import { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';
import { useDeleteManyAction } from '../../components/TableV2/BaseModel.hooks';
import { useGenericColumns } from '../../components/TableV2/SecodaEntity.hooks';
import { ROW_HEIGHT } from '../../components/TableV2/TableV2.styles';
import { BoldTextRender } from '../../components/TableV2/render';
import { ExtendedDataTableColumn } from '../../components/TableV2/types';
import AnalyticsCardRender from './AnalyticsCardRender';

const useStyles = createStyles(() => ({
	ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
}));

export function ReportTitleRender<T extends IReport>(field: keyof T) {
	return function Render(record: T) {
		const { classes, theme } = useStyles();

		return (
			<RichTooltip
				keepMounted={true}
				position="right"
				width={theme.other.space[120]}
				maxWidth={theme.other.space[160]}
				title={''}
				body={<AnalyticsCardRender data={record} />}
			>
				<Text
					size="sm"
					sx={{
						lineHeight: `${ROW_HEIGHT}px`,
					}}
					className={classes.ellipsis}
					fw={600}
				>
					{isEmpty(record?.[field] as unknown as object)
						? 'Untitled'
						: (record?.[field] as ReactNode)}
				</Text>
			</RichTooltip>
		);
	};
}

export function useColumns<T extends IReport>(): ExtendedDataTableColumn<T>[] {
	const genericColumns = useGenericColumns({}) as ExtendedDataTableColumn<T>[];

	const columns = useMemo(
		() => [
			{
				accessor: 'name',
				title: 'Name',
				render: (record) => <BoldTextRender record={record} field={'name'} />,
			},
			...filter(genericColumns, (column) =>
				['updated_at'].includes(column.accessor)
			),
		],
		[genericColumns]
	);

	return columns;
}

export const useActions = (): ICommandListItem<IReport>[] => {
	const { action } = useDeleteManyAction({
		name: 'report',
		queryKeyFactory: reportsQueryKeyFactory,
		useDelete: useDeleteReport,
	});

	const actions = useMemo(() => [action], [action]);

	return actions;
};
