import { createStyles, Group, Menu, Stack, Tooltip } from '@mantine/core';
import { OPERATORS_CONFIG } from '@repo/common/components/Filter/constants';
import { Button, IconButton, Text } from '@repo/foundations';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { AddFilter, Filter, type FilterValue } from '../../Filter';
import { FILTER_OPTIONS_CONFIG } from '../../Filter/constants';
import { GovernanceFilterStoreContext } from './store';
import type { GovernanceFilter } from './types';

interface AIGovernanceFilterCardProps {
	filter: GovernanceFilter;
	filterIndex: number;
}

const useStyles = createStyles((theme) => ({
	card: {
		padding: theme.spacing.md,
		border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		borderRadius: theme.radius.md,
	},
}));

export const GovernanceFilterCard = observer(
	({ filter, filterIndex }: AIGovernanceFilterCardProps) => {
		const { classes } = useStyles();
		const store = useContext(GovernanceFilterStoreContext);

		return (
			<Stack spacing="md" className={classes.card}>
				<Group position="apart">
					<Text size="sm">
						<Tooltip
							label={`Change to ${filter.type === 'included' ? 'Exclude' : 'Include'}`}
						>
							<Button
								variant="tertiary"
								onClick={store.onToggleType(filterIndex)}
							>
								{filter.type === 'included' ? (
									<strong>Include </strong>
								) : (
									<strong>Exclude </strong>
								)}
							</Button>
						</Tooltip>
						resources that match all of the following filters
					</Text>
					<Menu>
						<Menu.Target>
							<IconButton variant="tertiary" iconName="dots" />
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item onClick={store.onRemoveFilter(filterIndex)}>
								Remove
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Group>
				<Group spacing="xs">
					{filter.filterValues.map((value: FilterValue, idx: number) => (
						<Filter
							/* eslint-disable-next-line react/no-array-index-key */
							key={`filter-${idx}}`}
							value={toJS(value)}
							filterOption={FILTER_OPTIONS_CONFIG[value.filterType]}
							onChange={store.onChangeValue(filterIndex)(idx)}
							onClear={store.onClearValue(filterIndex)(idx)}
							showDetailedLabel
							operatorConfig={
								OPERATORS_CONFIG[
									FILTER_OPTIONS_CONFIG[value.filterType].filterDropdownConfig
										.dropdownType
								]
							}
						/>
					))}
					<AddFilter
						options={toJS(store.filterOptions)}
						onAddFilter={store.onAddValue(filterIndex)}
					/>
				</Group>
			</Stack>
		);
	}
);
