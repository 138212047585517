import { makeAutoObservable } from 'mobx';

import { LiteQueryOut } from '@repo/api-codegen';
import { IQuery } from '../../../api';
import { Lineage } from '../../../lib/models';
import type { EntityTabsStore } from '../../TableEntityPage/TableEntityTabs/TableEntityTabsStore';

export class DatabaseEntityTabsStore implements EntityTabsStore {
	lineageCount?: number | undefined;
	setLineageCount!: (count: number) => void;
	lineageUpstreamResults?: Lineage[] | undefined;
	lineageDownstreamResults?: Lineage[] | undefined;
	setLineageUpstreamResults?: ((results: Lineage[]) => void) | undefined;
	setLineageDownstreamResults?: ((results: Lineage[]) => void) | undefined;
	creationQuery?: IQuery | LiteQueryOut | undefined;
	setCreationQuery?: ((query: IQuery | LiteQueryOut) => void) | undefined;
	queryCount?: number | undefined;
	setQueryCount?: ((count: number) => void) | undefined;
	discussionCount: number | undefined = undefined;

	get isDiscussionLoading() {
		return this.discussionCount === undefined;
	}

	reset = () => {
		this.discussionCount = undefined;
	};

	setDiscussionCount = (count: number) => {
		this.discussionCount = count;
	};
	constructor() {
		makeAutoObservable(this);
	}
}
