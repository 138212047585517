import { createStyles } from '@mantine/core';
import { getColor } from '@repo/theme/utils';

export const useStyles = createStyles((theme) => ({
	modalHeader: {
		backgroundColor: getColor('surface/secondary/default'),
	},
	modalSelectDropdown: {
		maxHeight: 150,
		overflowY: 'auto',
	},
}));
