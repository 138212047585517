import { space } from './space';

export const width = {
	xs: space[60],
	sm: space[80],
	md: space[120],
	lg: space[160],
	xl: space[200],
	xxl: space[240],
	xxxl: space[280],
	xxxxl: space[320],
} as const;

export const height = {
	sm: space[8],
} as const;

export const iconSize = {
	xs: space[3],
	sm: space[4],
	md: space[5],
	lg: space[6],
	xl: space[8],
	xxl: space[12],
} as const;

export const borderRadius = {
	xs: space[1],
	sm: space[2],
	md: space[3],
	lg: space[4],
	xl: space[5],
	xxl: space[6],
};

export const borderWidth = {
	xs: space[0.125],
	sm: space[0.25],
	md: space[0.375],
	lg: space[0.5],
	xl: space[1],
};
