import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useMeasurementFromWidget } from '../../../api';
import { snakeCaseToTitleCase } from '../../../utils/shared.utils';
import type { ISampleOrActualMetricWidget } from '../constants';
import { convertValue, DEFAULT_COLORS, isEmptyMetric } from '../utils/utils';
import { MetricLoadingOrEmpty } from './MetricLoadingOrEmpty';
import {
	MetricWidgetBarChart,
	MetricWidgetBarChartProps,
} from './MetricWidgetBarChart/MetricWidgetBarChart';

export function MetricIntegrationBarChart(props: ISampleOrActualMetricWidget) {
	const { metricWidget, source } = props;
	const { data: datapoints, isLoading } =
		useMeasurementFromWidget(metricWidget);

	const isLoadingOrEmpty = isEmptyMetric(datapoints) || isLoading;

	const chartProps = useMemo(() => {
		if (isLoadingOrEmpty) {
			return { channels: [], data: [] };
		}

		const valueNames = Object.keys(metricWidget.metric_metadata?.value || {});

		const channels = valueNames.map((value) => ({
			label: snakeCaseToTitleCase(value),
			color:
				metricWidget.metric_metadata?.value_color?.[value] || DEFAULT_COLORS[0],
			x: 'date',
			y: value,
		})) as MetricWidgetBarChartProps<{ date: Date }>['channels'];

		const data = datapoints.map((point) => ({
			date: dayjs(point.timestamp).toDate(),
			...valueNames.reduce(
				(acc, value) => ({
					...acc,
					[value]: convertValue(point[value] as number),
				}),
				{}
			),
		}));

		function getTooltipData(dataIndex: number) {
			const point = data[dataIndex] as Record<string, number> & {
				date: Date;
			};

			const items = valueNames.map((value) => ({
				label: snakeCaseToTitleCase(value),
				value: convertValue(point[value] as number),
				color:
					metricWidget.metric_metadata?.value_color?.[value] ||
					DEFAULT_COLORS[0],
			}));

			return { date: point.date, items };
		}

		return {
			data,
			channels,
			getTooltipData,
		};
	}, [
		isLoadingOrEmpty,
		datapoints,
		metricWidget.metric_metadata?.value,
		metricWidget.metric_metadata?.value_color,
	]);

	if (isLoadingOrEmpty) {
		return (
			<MetricLoadingOrEmpty
				isLoading={isLoading}
				isEmpty={isEmptyMetric(datapoints)}
				source={source}
			/>
		);
	}

	return <MetricWidgetBarChart {...props} {...chartProps} />;
}
