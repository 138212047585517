/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/destructuring-assignment */
import { useMemo } from 'react';

import { MarketplaceIntegrationSpecVersionOut } from '@repo/api-codegen';
import { TextRender } from '../../components/TableV2/render';
import { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { useColumns as useMarketplaceIntegrationSpecVersionsColumns } from '../MarketplaceIntegrationSpecDetailPage/MarketplaceIntegrationSpecDetailPage.hooks';

export const useColumns =
	(): ExtendedDataTableColumn<MarketplaceIntegrationSpecVersionOut>[] => {
		const marketplaceIntegrationSpecVersionsColumns =
			useMarketplaceIntegrationSpecVersionsColumns();

		const columns: ExtendedDataTableColumn<MarketplaceIntegrationSpecVersionOut>[] =
			useMemo(
				() => [
					...marketplaceIntegrationSpecVersionsColumns.filter(
						(el) => el.accessor !== 'action'
					),
					{
						title: 'Comments',
						accessor: 'reviewer_comment',
						render: (record) => (
							<TextRender record={record} field="reviewer_comment" />
						),
					},
					{
						title: 'Reviewer',
						accessor: 'reviewer_email',
						render: (record) => (
							<TextRender record={record} field="reviewer_email" />
						),
					},
				],
				[marketplaceIntegrationSpecVersionsColumns]
			);

		return columns;
	};
