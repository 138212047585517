import { Menu } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { useNavigate } from 'react-router';
import { ISecodaEntity } from '../../../../api';
import { useDuplicateDocument } from '../../../../api/hooks/document/useDuplicateDocument';
import type { SecodaEntity } from '../../../../lib/models';
import { EntityType } from '../../../../lib/types';
import { buildResourceUrl } from '../../../../utils/navigationUtils';

interface DuplicateActionProps {
	entity: SecodaEntity | ISecodaEntity;
}

export function DuplicateAction({ entity }: DuplicateActionProps) {
	const navigate = useNavigate();

	const canDuplicate = entity.entity_type === EntityType.document;

	const { mutateAsync: duplicate } = useDuplicateDocument({
		onSuccess: (data) => {
			navigate(buildResourceUrl(data));
		},
	});

	if (!canDuplicate) {
		return null;
	}

	const handleDuplicateClick = async () => {
		duplicate({ entity });
	};

	return (
		<Menu.Item icon={<Icon name="copy" />} onClick={handleDuplicateClick}>
			Duplicate
		</Menu.Item>
	);
}
