import type { BadgeProps as MantineBadgeProps } from '@mantine/core';
import { DataQualityClassification } from '@repo/common/enums/dataQualityClassification';
import { Badge, BadgeVariant } from '@repo/foundations';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

const BADGE_VARIANT: Record<DataQualityClassification, BadgeVariant> = {
	[DataQualityClassification.GOOD]: 'info',
	[DataQualityClassification.MODERATE]: 'warning',
	[DataQualityClassification.POOR]: 'critical',
	[DataQualityClassification.UNAVAILABLE]: 'default',
};

export interface DataQualityBadgeProps
	extends Omit<MantineBadgeProps, 'variant' | 'color'> {
	classification: DataQualityClassification;
}

export const DataQualityBadge = forwardRef(
	(
		{ classification, ...props }: DataQualityBadgeProps,
		ref: ForwardedRef<HTMLDivElement>
	) => (
		<Badge ref={ref} variant={BADGE_VARIANT[classification]} {...props}>
			{classification}
		</Badge>
	)
);
DataQualityBadge.displayName = 'DataQualityBadge';
