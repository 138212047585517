import {
	ActionIcon,
	Card,
	Divider,
	Group,
	Image,
	Menu,
	SimpleGrid,
	Stack,
	Tooltip,
} from '@mantine/core';
import {
	apiQueryKey,
	useCreateLinearTicket,
	useGenerateWorkspaceLineage,
} from '@repo/api-codegen';
import { integrationList } from '@repo/common/constants/integration/integrations';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Icon, IconButton, Text, Title } from '@repo/foundations';
import { useState } from 'react';
import { queryClient, useCreateDatabuilderJob } from '../../../api';
import {
	invalidateInternalIntegrationStatusQueries,
	useRemoveDanglingJobs,
} from '../../../api/hooks/internalIntegrationStatus';
import type {
	IInternalDatabuilderJobStatus,
	IInternalIntegrationStatus,
} from '../../../api/types/models/internalIntegrationStatus';
import { SearchLoadingSpinner } from '../../../components/LoadingSpinner';
import { openConfirmModal, openModal } from '../../../components/ModalManager';
import { DatabuilderJobType } from '../../../interfaces';
import { BuiltinIntegrationSpec } from '../../../interfaces/IntegrationSpec';
import { LinkTicketModal } from './IntegrationLinkTicketModal';
import { useStyles } from './IntegrationsStatusPage.styles';
import { InternalJobStatusIcon } from './InternalJobStatusIcon';
import { LineageSummary } from './LineageSummary';

interface IIntegrationCardProps {
	type: string | null;
	statuses: IInternalIntegrationStatus[];
	setJobMetadata: (job: IInternalDatabuilderJobStatus) => void;
	ticketTitles: { [key: string]: string[] };
	setTicketTitles: (ticketTitles: { [key: string]: string[] }) => void;
}

export function IntegrationCard({
	type,
	statuses,
	setJobMetadata,
	ticketTitles,
	setTicketTitles,
}: IIntegrationCardProps) {
	const navigate = useNavigate();
	const { classes, theme } = useStyles();
	const [curIntegration, setCurIntegration] =
		useState<IInternalIntegrationStatus | null>(null);

	let integration: BuiltinIntegrationSpec | undefined;
	if (type) {
		integration = integrationList.find(
			(i) => i.type?.toLowerCase() === type.toLowerCase()
		);
	}

	const [integrationLoading, setIntegrationLoading] = useState<string | null>(
		null
	);

	const { mutate: createTicket } = useCreateLinearTicket({
		onSuccess: (data) => {
			const prevTickets = { ...ticketTitles };
			const match = data.url.match(/(ENG-\d+)/);
			if (!match) {
				return;
			}
			prevTickets[data.integration_id] = [
				...prevTickets[data.integration_id],
				match[1],
			];
			setTicketTitles(prevTickets);
			window.open(data.url, '_blank');
			setIntegrationLoading(null);
			queryClient.invalidateQueries(
				apiQueryKey('admin/linear/get_linear_tickets')
			);
		},
		onError: () => {},
	});

	const { mutateAsync: createDatabuilderJob } = useCreateDatabuilderJob({});
	const { mutate: cleanUpDanglingJobs } = useRemoveDanglingJobs();
	const { mutate: generateWorkspaceLineage } = useGenerateWorkspaceLineage();

	const handleGetTickets = (status: IInternalIntegrationStatus) => {
		setCurIntegration(status);
	};

	const handleRunSync = async (status: IInternalIntegrationStatus) => {
		openConfirmModal({
			title: `Run extraction for ${status.workspace_name} – ${status.integration_name}`,
			labels: {
				confirm: 'Confirm',
				cancel: 'Cancel',
			},
			cancelProps: {
				size: 'md',
			},
			confirmProps: {
				size: 'md',
				variant: 'primary',
			},
			onConfirm: async () => {
				await createDatabuilderJob({
					data: {
						integration_id: status.integration_id,
						type: DatabuilderJobType.METADATA,
					},
				});
				invalidateInternalIntegrationStatusQueries();
			},
		});
	};

	const handleCleanUpDanglingJobs = (status: IInternalIntegrationStatus) => {
		openConfirmModal({
			title: `Clean up dangling jobs for ${status.workspace_name} – ${status.integration_name}`,
			labels: {
				confirm: 'Confirm',
				cancel: 'Cancel',
			},
			cancelProps: {
				size: 'md',
			},
			confirmProps: {
				size: 'md',
				variant: 'primary',
			},
			onConfirm: async () => {
				await cleanUpDanglingJobs({
					integrationID: status.integration_id,
				});
				invalidateInternalIntegrationStatusQueries();
			},
		});
	};

	const handleInspectApi = (status: IInternalIntegrationStatus) => {
		navigate(
			`/internal/inspect-integration/?integrationId=${status.integration_id}`
		);
	};

	const handleCreateLinearTicket = (
		status: IInternalIntegrationStatus,
		integrationName: string | undefined
	) => {
		const failedOrTerminatedJobs = status.latest_k_jobs
			.filter((job) => job.status === 'failed' || job.status === 'terminated')
			.reverse();
		if (failedOrTerminatedJobs.length === 0) {
			openModal({
				title: (
					<Text size="md" weight="bold">
						No failing extraction found
					</Text>
				),
			});
		} else if (!integrationName) {
			openModal({
				title: (
					<Text size="md" weight="bold">
						Integration name not found
					</Text>
				),
			});
		} else {
			const title = `${integrationName} failed`;
			const params = {
				title,
				status,
				first_fail: failedOrTerminatedJobs[0],
			};
			setIntegrationLoading(status.integration_id);
			createTicket({ body: params });
		}
	};

	const handleClickTicket = (ticket: string) => {
		const url = `https://linear.app/secoda/issue/${ticket}`;
		window.open(url);
	};

	return (
		<>
			<Card className={classes.card}>
				<Group align="flex-start" noWrap>
					{type ? (
						<Image
							width={theme.other.space[8]}
							height={theme.other.space[8]}
							src={integration?.src}
						/>
					) : null}
					<Stack w="100%">
						<Title size="xl">
							{type ? integration?.name : statuses.at(0)?.workspace_name}
						</Title>
						<Divider />
						<SimpleGrid cols={1} spacing="xl" verticalSpacing="xl">
							<SimpleGrid cols={3} spacing="xl" verticalSpacing="xl">
								{statuses.map((status) => {
									const foundIntegration = integrationList.find(
										(i) =>
											i.type?.toLowerCase() ===
											status.integration_type.toLowerCase()
									);
									return (
										<Stack key={status.integration_id}>
											<Group>
												<Group spacing="xs">
													{!type ? (
														<Image
															width={theme.other.space[6]}
															height={theme.other.space[6]}
															src={foundIntegration?.src}
														/>
													) : null}
													<Title size="md">
														{type
															? status.workspace_name
															: foundIntegration?.name}
													</Title>
												</Group>
												<Menu position="bottom-start" withinPortal>
													<Menu.Target>
														<Tooltip
															label="More actions"
															withArrow
															withinPortal
														>
															<IconButton iconName="dots" />
														</Tooltip>
													</Menu.Target>
													<Menu.Dropdown>
														<Menu.Item
															icon={<Icon name="refresh" />}
															onClick={() => handleRunSync(status)}
														>
															Run sync
														</Menu.Item>
														<Menu.Item
															icon={<Icon name="wreckingBall" />}
															onClick={() => handleCleanUpDanglingJobs(status)}
														>
															Clean up dangling jobs
														</Menu.Item>
														<Menu.Item
															icon={<Icon name="code" />}
															onClick={() => handleInspectApi(status)}
														>
															Inspect API
														</Menu.Item>
														<Menu.Item
															icon={<Icon name="ticket" />}
															onClick={() =>
																handleCreateLinearTicket(
																	status,
																	foundIntegration?.name
																)
															}
														>
															Create new Linear ticket
														</Menu.Item>
														<Menu.Item
															icon={<Icon name="link" />}
															onClick={() => handleGetTickets(status)}
														>
															Link existing Linear ticket
														</Menu.Item>
														<Menu.Item
															icon={<Icon name={'plugConnected'} />}
															onClick={() => {
																generateWorkspaceLineage({
																	pathParams: {
																		workspaceId: status.workspace_id,
																	},
																});
															}}
														>
															Generate workspace lineage
														</Menu.Item>
													</Menu.Dropdown>
												</Menu>
												{ticketTitles[status.integration_id]?.length > 0 && (
													<Menu position="bottom-start" withinPortal>
														<Menu.Target>
															<Tooltip
																label="Linked Linear tickets"
																withArrow
																withinPortal
															>
																<IconButton iconName="ticket" />
															</Tooltip>
														</Menu.Target>
														<Menu.Dropdown
															style={{
																maxHeight: '200px',
																overflowY: 'auto',
															}}
														>
															{ticketTitles[status.integration_id]
																.sort()
																.reverse()
																.map((ticket) => (
																	<Menu.Item
																		key={ticket}
																		onClick={() => handleClickTicket(ticket)}
																		rightSection={<Icon name="chevronRight" />}
																	>
																		{ticket}
																	</Menu.Item>
																))}
														</Menu.Dropdown>
													</Menu>
												)}
												{integrationLoading === status.integration_id ? (
													<SearchLoadingSpinner />
												) : null}
											</Group>
											<Group>
												{status.latest_k_jobs.map((job) => (
													<ActionIcon
														key={job.databuilder_job_id}
														onClick={() => setJobMetadata(job)}
													>
														<InternalJobStatusIcon jobStatus={job} />
													</ActionIcon>
												))}
											</Group>
										</Stack>
									);
								})}
							</SimpleGrid>

							{!type && statuses[0]?.workspace_id && (
								<>
									<Divider />
									<LineageSummary
										integrationStatuses={statuses}
										workspaceId={statuses[0]?.workspace_id}
									/>
								</>
							)}
						</SimpleGrid>
					</Stack>
				</Group>
			</Card>
			<LinkTicketModal
				curIntegration={curIntegration}
				ticketTitles={ticketTitles}
				setTicketTitles={setTicketTitles}
				opened={!!curIntegration}
				onClose={() => setCurIntegration(null)}
			/>
		</>
	);
}
