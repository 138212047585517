import { observer } from 'mobx-react-lite';
import { CommentStoreProvider } from '../../components/Comment/context';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { IDictionaryTermPageProps } from './DictionaryTermPage';
import DictionaryTermPage from './DictionaryTermPage';

function DictionaryTermPageWithCommentStore({
	id: propsId,
}: IDictionaryTermPageProps) {
	const paramsId = useParamsIdSuffixUuid();
	const id = propsId || paramsId;

	return (
		<CommentStoreProvider>
			<DictionaryTermPage id={id} />
		</CommentStoreProvider>
	);
}

export default observer(DictionaryTermPageWithCommentStore);
