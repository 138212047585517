import { isNil, size } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import type { ICollection, ISecodaEntity } from '../../api';
import { useAuthUser, useUpdateCollection } from '../../api';
import { isViewerOfEntity } from '../../utils/authorization/roles';
import BookmarkToggle from '../BookmarkToggle/BookmarkToggle';
import EntityCreationQuery from '../EntityCreationQuery';
import EntityLink from '../EntityLink/EntityLink';
import { EntityPermissions } from '../EntityPermissions';
import PinnedToggle from '../PinnedToggle';
import { EntityActions } from './EntityActions';

interface INavBarEntityButtonsProps {
	entity: ISecodaEntity;
	withPinnedToggle?: boolean;
	creationQuery?: string;
}

function EntityPageActions({
	entity,
	withPinnedToggle,
	creationQuery,
}: INavBarEntityButtonsProps) {
	const { user } = useAuthUser();
	const editorOfEntity = !isViewerOfEntity(user, entity);

	const { mutate: updateCollection } = useUpdateCollection({});

	const hasExternalUrl = !isNil(entity?.url) && size(entity?.url) > 0;

	const handlePinCollection = useCallback(
		(pinned: boolean) => {
			updateCollection({
				data: {
					id: entity?.id,
					pinned,
				},
			});
		},
		[entity?.id, updateCollection]
	);

	return (
		<>
			{editorOfEntity && <EntityPermissions model={entity} />}
			{withPinnedToggle && editorOfEntity && (
				<PinnedToggle
					isPinned={(entity as unknown as ICollection)?.pinned}
					onChange={handlePinCollection}
				/>
			)}
			{creationQuery && (
				<EntityCreationQuery title={entity?.title} query={creationQuery} />
			)}
			<BookmarkToggle entity={entity} />
			{hasExternalUrl && <EntityLink entity={entity} />}
			<EntityActions entity={entity} />
		</>
	);
}

export default observer(EntityPageActions);
