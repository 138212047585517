import type { SelectItemProps } from '@mantine/core';
import { Box, createStyles, Group, Select } from '@mantine/core';
import { Icon as IconComponent, Text } from '@repo/foundations';
import { typography } from '@repo/theme/primitives';
import type { Icon as TablerIcon } from '@tabler/icons-react';
import { forwardRef } from 'react';
import { FILTER_OPTIONS, FILTER_OPTIONS_MAP } from './constants';

interface IFilterOptionSelectorProps {
	defaultValue: (typeof FILTER_OPTIONS)[number];
	onChange: (value: (typeof FILTER_OPTIONS)[number] | null) => void;
}

export interface IFilterOptionSelectorItem extends SelectItemProps {
	icon: TablerIcon;
	value: (typeof FILTER_OPTIONS)[number];
	label: string;
}

const useStyles = createStyles((theme) => ({
	root: {
		width: 250,
	},
	input: {
		borderRadius: 1000,
		backgroundColor: theme.fn.lighten(theme.colors.primary[0], 0.15),
		borderColor: theme.colors.primary[1],
		color: theme.colors.primary[6],
		fontWeight: typography.weight.semibold,
	},
	rightSection: {
		pointerEvents: 'none',
	},
	item: {
		'&:hover': {
			backgroundColor: theme.fn.lighten(theme.colors.primary[0], 0.15),
		},
		'&[data-selected]': {
			backgroundColor: 'transparent',
			color: theme.black,
		},
		'&:not([data-selected]) > div > svg.tabler-icon-check': {
			color: 'transparent',
		},
		'&[data-selected]:hover': {
			backgroundColor: 'transparent',
			color: theme.black,
		},
	},
}));

const SelectItem = forwardRef<HTMLDivElement, IFilterOptionSelectorItem>(
	({ icon: Icon, label, ...others }: IFilterOptionSelectorItem, ref) => (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Box ref={ref} {...others}>
			<Group noWrap position="apart">
				<Group noWrap>
					<Icon size={20} />
					<Text>{label.replace('Related by: ', '')}</Text>
				</Group>
			</Group>
		</Box>
	)
);

SelectItem.displayName = 'SelectItem';

export function FilterOptionSelector({
	onChange,
	defaultValue,
}: IFilterOptionSelectorProps) {
	const { classes } = useStyles();

	return (
		<Select
			size="xs"
			classNames={{
				root: classes.root,
				input: classes.input,
				rightSection: classes.rightSection,
				item: classes.item,
			}}
			defaultValue={defaultValue}
			onChange={onChange}
			data={FILTER_OPTIONS_MAP}
			itemComponent={SelectItem}
			rightSection={<IconComponent name="chevronDown" />}
		/>
	);
}
