import type { MantineTheme } from '@mantine/core';
import { createStyles, Group, Menu, Stack } from '@mantine/core';
import type { ISingleSelectorItemProps } from '@repo/common/components/SingleSelector/SingleSelectorItem';
import { Text } from '@repo/foundations';
import { IconCheck } from '@tabler/icons-react';
import { ReactNode } from 'react';

const useStyles = createStyles((theme: MantineTheme) => ({
	icon: {
		color: theme.other.getColor('icon/primary/default'),
		width: theme.other.iconSize.sm,
		height: theme.other.iconSize.sm,
	},
}));

function TableSelectorItem({
	item,
	onClick,
	isSelected,
}: ISingleSelectorItemProps) {
	const { classes } = useStyles();

	const handleClick = () => {
		onClick(item);
	};

	return (
		<Menu.Item onClick={handleClick}>
			<Group spacing="xs" position="apart" noWrap>
				<Group align="flex-start" spacing="xs" noWrap>
					{item.icon as ReactNode}
					<Stack spacing="none">
						<Text size="sm">{item.label}</Text>
						{item.description}
					</Stack>
				</Group>
				{isSelected && <IconCheck className={classes.icon} />}
			</Group>
		</Menu.Item>
	);
}

export default TableSelectorItem;
