import {
	Box,
	Group,
	Stack,
	Tooltip,
	createStyles,
	useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { KeyboardShortcut } from '@repo/common/components/KeyboardShortcut/KeyboardShortcut';
import { IconButton } from '@repo/foundations';
import { useQueryClient } from '@tanstack/react-query';
import { useBoolean, useKeyPress } from 'ahooks';
import { useCallback, useRef } from 'react';
import {
	questionReplyQueryKeyFactory,
	useAuthUser,
	useCreateQuestionReply,
	useWorkspace,
} from '../../../api';
import { RichEditor } from '../../../components/RichEditor';
import { UserAvatar } from '../../../components/UserAvatar';
import { trackEvent } from '../../../utils/analytics';
import { getModKey } from '../../../utils/keyboard';
import {
	getTTLCacheItem,
	invalidateTTLCacheItem,
	setTTLCacheItem,
} from '../utils/utils';
import { QUESTION_REPLY_LOCAL_STORAGE_KEY } from './constants';
import { trimMarkdown } from './helpers';
import { useStyles } from './styles';

const useLocalStyles = createStyles((theme) => ({
	editorWrapper: {
		width: 'calc(100% - 1rem - 32px)',
		marginRight: theme.spacing.md,
	},
	actionIcon: {
		backgroundColor: theme.other.getColor('fill/brand/default'),
		color: theme.other.getColor('text/brand-on-fill/default'),
		position: 'absolute',
		right: `calc(${theme.spacing.xs} * 1.5)`,
		bottom: theme.spacing.xs,
		'&[data-disabled]': {
			backgroundColor: theme.other.getColor('fill/brand/disabled'),
			color: theme.other.getColor('text/brand-on-fill/disabled'),
		},

		'&:hover, &:focus': {
			backgroundColor: theme.other.getColor('fill/brand/hover'),
			color: theme.other.getColor('text/brand-on-fill/hover'),
		},

		'&:active': {
			backgroundColor: theme.other.getColor('fill/brand/active'),
			color: theme.other.getColor('text/brand-on-fill/active'),
		},
	},
}));

export interface IQuestionNewReplyProps {
	id: string;
}

export function QuestionNewReply({ id }: IQuestionNewReplyProps) {
	const theme = useMantineTheme();
	const ref = useRef<string>();
	const queryClient = useQueryClient();
	const { classes: localClasses, cx } = useLocalStyles();
	const { classes } = useStyles({ accepted: false });
	const { user } = useAuthUser();
	const { workspace } = useWorkspace();

	const modKey = getModKey();
	const cacheKey = `${QUESTION_REPLY_LOCAL_STORAGE_KEY}-${id}`;
	const initialValue = getTTLCacheItem(cacheKey) || '';

	const [valid, setValid] = useBoolean(false);
	const [loading, { open: startLoading, close: stopLoading }] =
		useDisclosure(false);

	const { mutateAsync: createQuestionReply, isSuccess } =
		useCreateQuestionReply({
			options: {
				onMutate: () => {
					startLoading();
				},
				onSuccess: async () => {
					ref.current = '';
					invalidateTTLCacheItem(cacheKey);
					queryClient.invalidateQueries(
						questionReplyQueryKeyFactory.list(1, {
							question_id: id,
						})
					);

					// Due to the nested nature of the question replies,
					// there isn't a great way to determine when the parent reply
					// has been updated. Instead, we just wait for a short period
					// of time before stopping the loading state.
					setTimeout(() => {
						ref.current = '';
						stopLoading();
					}, 150);

					trackEvent(
						'question/reply/create',
						{
							id,
						},
						user,
						workspace
					);
				},
				onError: () => {
					showNotification({
						title: 'Error',
						message: 'Failed to create reply',
						color: 'red',
					});
				},
			},
		});

	const onSubmit = useCallback(async () => {
		if (!ref.current) return;
		if (ref.current && trimMarkdown(ref.current).length > 2) {
			createQuestionReply({
				data: {
					parent: id,
					definition: ref.current,
					owners: [user.id],
				},
			});
		}
	}, [createQuestionReply, id, user]);

	useKeyPress(['meta.Enter'], onSubmit);

	const handleChangeCallback = useCallback(
		(value: string | undefined) => {
			if (value) {
				ref.current = value;
				if (value && trimMarkdown(value).length > 2) {
					setValid.setTrue();
					// Cache the value in local storage so that it can be restored if the
					// user navigates away from the page.
					setTTLCacheItem(cacheKey, value, 1000 * 60 * 60 * 24 * 7);
				} else {
					setValid.setFalse();
				}
			} else {
				setValid.setFalse();
			}
		},
		[cacheKey, setValid]
	);

	return (
		<Group
			pos="relative"
			mt={theme.spacing.md}
			mb={`calc(${theme.spacing.xl} * 4)`}
			align="flex-start"
		>
			<Box miw={32} mt={`calc(${theme.spacing.xs} * 0.8)`}>
				<UserAvatar user={user} size="md" />
			</Box>
			<Group className={cx(classes.editorWrapper, localClasses.editorWrapper)}>
				<Stack className={classes.editorWhileEditing}>
					<RichEditor
						dataTestId="new-reply-editor"
						// Using `isSuccess` as a key forces the component to re-render on submission.
						key={String(isSuccess)}
						placeholder="Add a reply..."
						readOnly={loading}
						onChangeCallback={handleChangeCallback}
						initialValue={initialValue}
						disableTopGap
					/>
					<Tooltip
						label={
							<KeyboardShortcut keys={[modKey, 'Enter']} variant="dark">
								Send reply
							</KeyboardShortcut>
						}
					>
						<IconButton
							iconName="arrowUp"
							data-testid="new-reply-submit"
							disabled={!valid || loading}
							size="sm"
							onClick={onSubmit}
							className={localClasses.actionIcon}
						/>
					</Tooltip>
				</Stack>
			</Group>
		</Group>
	);
}
