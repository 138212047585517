import { baseQueryHooksFactory } from '../../factories';
import { AutomationHistory } from '../../types/models/automation/automationHistory';
import { automationHistoryQueryKeyFactory } from './constants';

const { useAutomationHistoryList, fetchAutomationHistoryList } =
	baseQueryHooksFactory<AutomationHistory, 'automationHistory'>(
		'automationHistory',
		automationHistoryQueryKeyFactory
	);

export { fetchAutomationHistoryList, useAutomationHistoryList };
