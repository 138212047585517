import { LiteUserOut } from '@repo/api-codegen';
import { UserRole } from '@repo/common/enums/UserRole';
import { cloneDeep } from 'lodash-es';
import type { Workspace } from '.';
import { apiClient } from '../../api/common';
import { BaseModel } from './base';

export class User extends BaseModel {
	// @ts-expect-error TS(2564): Property 'id' has no initializer and is not defini... Remove this comment to see the full error message
	id: string;

	// @ts-expect-error TS(2564): Property 'email' has no initializer and is not def... Remove this comment to see the full error message
	email: string;

	// @ts-expect-error TS(2564): Property 'display_name' has no initializer and is ... Remove this comment to see the full error message
	display_name: string;

	first_name = '';

	last_name = '';

	// @ts-expect-error TS(2564): Property 'profile_picture' has no initializer and ... Remove this comment to see the full error message
	profile_picture: string;

	// @ts-expect-error TS(2564): Property 'role' has no initializer and is not defi... Remove this comment to see the full error message
	role: UserRole;

	// @ts-expect-error TS(2564): Property '_role' has no initializer and is not defi... Remove this comment to see the full error message
	_role: UserRole;

	// @ts-expect-error TS(2564): Property 'pending' has no initializer and is not d... Remove this comment to see the full error message
	pending: boolean;

	// @ts-expect-error TS(2564): Property 'is_service_account' has no initializer a... Remove this comment to see the full error message
	is_service_account: boolean;

	// @ts-expect-error TS(2564): Property 'secoda_is_superuser' has no initializer ... Remove this comment to see the full error message
	secoda_is_superuser: boolean;

	// @ts-expect-error TS(2564): Property 'workspace' has no initializer and is not... Remove this comment to see the full error message
	workspace: string | Workspace;

	// @ts-expect-error TS(2564): Property 'service_account_count_popularity' has no... Remove this comment to see the full error message
	service_account_count_popularity: boolean;

	// @ts-expect-error TS(2564): Property 'user_groups' has no initializer and is n... Remove this comment to see the full error message
	user_groups: string[];

	// @ts-expect-error TS(2564): Property 'last_login' has no initializer and is no... Remove this comment to see the full error message
	last_login: Date;

	// @ts-expect-error TS(2564): Property 'new_feature_acknowledged' has no initializer and is no... Remove this comment to see the full error message
	new_feature_acknowledged: boolean;

	// @ts-expect-error TS(2564)
	teams: string[];

	// @ts-expect-error TS(2564)
	disabled: boolean;

	// @ts-expect-error TS(2564)
	onboarding_steps: Record<string, boolean>;

	// @ts-expect-error TS(2564)
	education_modals_dismissed: Record<string, boolean>;

	constructor(obj: User | LiteUserOut) {
		super(obj);
		Object.keys(obj).forEach((key) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get namespace() {
		return ['user'];
	}

	get full_name() {
		return this.display_name;
	}

	get is_admin() {
		return [UserRole.ADMIN].includes(this.role.toLowerCase() as UserRole);
	}

	get is_editor() {
		return [UserRole.EDITOR].includes(this.role.toLowerCase() as UserRole);
	}

	get is_viewer() {
		return [UserRole.VIEWER].includes(this.role.toLowerCase() as UserRole);
	}

	get is_admin_or_editor() {
		return this.is_admin || this.is_editor;
	}

	get domain() {
		return this.email?.split('@')?.[1];
	}

	static get fields() {
		return BaseModel.fields.concat([
			'email',
			'display_name',
			'first_name',
			'last_name',
			'role',
			'secoda_is_superuser',
			'profile_picture',
			'pending',
			'viewer_mode',
			'user_groups',
			'bookmarks',
			'workspace',
			'service_account_count_popularity',
			'is_service_account',
			'new_feature_acknowledged',
			'disabled',
		]);
	}

	static async getCurrentUser() {
		const res = await apiClient.get(`${this.apiRootPath}me/`);
		const user = new this(res.data);
		return user;
	}

	get initials() {
		return User.getUserInitialsFromName(this.display_name);
	}

	static getUserInitialsFromName(name?: string) {
		const names = name?.split(' ');
		let initials = names?.[0].substring(0, 1).toUpperCase();

		if (names && (names?.length ?? 0) > 1) {
			initials += names[names.length - 1].substring(0, 1).toUpperCase();
		}
		return initials;
	}
}
