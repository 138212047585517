/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Comlink from 'comlink';
import { useEffect, useState } from 'react';
import { cache } from '../../../lib/stores/CacheStore.helpers';
import { sha1 } from '../../../utils/utils';

// We use a web worker to format the SQL query. This is because formatting is
// CPU intensive, and we don't want to block the main thread while formatting.
export const sqlFmtWorker = new Worker(
	new URL('./worker/sql.worker.ts', import.meta.url),
	{ type: 'module' }
);

const wrappedSqlFmtWorker =
	Comlink.wrap<(query?: string, dialect?: string) => number>(sqlFmtWorker);

/**
 * Custom React hook that takes a SQL query as input and returns a formatted
 * version of the query. The formatting is obtained asynchronously using the
 * `formatSqlCache` function.
 *
 * NOTE: The reason we format on the frontend is that formatting is CPU
 * intensive, and we don't want to do it on the backend. We have run into issues
 * with formatting on the backend, where the formatting process would take too
 * long and cause the backend to time out.
 *
 * NOTE: Do not change the underlying package from `prettier-sql`: "5.1.1". This package
 * is tested and works with Vanta's dbt queries. `sql-formatter` will not work.
 *
 * @param {string} query - The original SQL query to be formatted.
 * @returns {string} formattedSql - The formatted SQL query obtained asynchronously.
 */
export function useFmtSql(
	query?: string,
	dialect?: string
): string | undefined | null {
	const [formattedSql, setFormattedSql] = useState<string | null>(null);

	useEffect(() => {
		(async () => {
			const hash = await sha1(query ?? '');
			let formatted = await cache.formatted_sql.get(hash);
			if (formatted && formatted.formatted) {
				setFormattedSql(formatted.formatted);
				return;
			}
			// We use a web worker to format the SQL query. This is because formatting is
			// CPU intensive, and we don't want to block the main thread while formatting.
			formatted = await wrappedSqlFmtWorker(query, dialect);
			setFormattedSql(formatted.toString());
			await cache.formatted_sql.put({ id: hash, formatted });
		})();
	}, [query, dialect]);

	return formattedSql;
}
