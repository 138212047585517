import type { Node as ProsemirrorNode } from 'prosemirror-model';
import type { Decoration, EditorView } from 'prosemirror-view';
import type React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import type { RichMarkdownEditor as Editor } from '..';
import { Providers } from '../../../../../providers';
import { light as lightTheme } from '../styles/theme';
import type Extension from './Extension';

export type ComponentProps = {
	node: ProsemirrorNode;
	theme: typeof lightTheme;
	isSelected: boolean;
	isEditable: boolean;
	getPos: () => number;
	view: EditorView;
};

type Component = (options: ComponentProps) => React.ReactElement;

export default class ComponentView {
	component: Component;

	editor: Editor;

	extension: Extension;

	node: ProsemirrorNode;

	view: EditorView;

	getPos: () => number;

	// @ts-expect-error TS(2315): Type 'Decoration' is not generic.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	decorations: Decoration<{ [key: string]: any }>[];

	isSelected = false;

	dom: HTMLElement | null;

	// See https://prosemirror.net/docs/ref/#view.NodeView
	constructor(
		// @ts-expect-error TS(7006): Parameter 'component' implicitly has an 'any' type... Remove this comment to see the full error message
		component,
		// @ts-expect-error TS(7031): Binding element 'editor' implicitly has an 'any' t... Remove this comment to see the full error message
		{ editor, extension, node, view, getPos, decorations }
	) {
		this.component = component;
		this.editor = editor;
		this.extension = extension;
		this.getPos = getPos;
		this.decorations = decorations;
		this.node = node;
		this.view = view;
		this.dom = node.type.spec.inline
			? document.createElement('span')
			: document.createElement('div');

		this.renderElement();
	}

	renderElement() {
		const theme = { ...lightTheme, ...this.editor.props.outlineThemeOverride };

		const children = this.component({
			theme,
			node: this.node,
			isSelected: this.isSelected,
			isEditable: this.view.editable,
			getPos: this.getPos,
			view: this.view,
		});

		ReactDOM.render(
			<Providers>
				<ThemeProvider theme={theme}>{children}</ThemeProvider>
			</Providers>,
			this.dom
		);
	}

	// @ts-expect-error TS(7006): Parameter 'node' implicitly has an 'any' type.
	update(node) {
		if (node.type !== this.node.type) {
			return false;
		}

		this.node = node;
		this.renderElement();
		return true;
	}

	selectNode() {
		if (this.view.editable) {
			this.isSelected = true;
			this.renderElement();
		}
	}

	deselectNode() {
		if (this.view.editable) {
			this.isSelected = false;
			this.renderElement();
		}
	}

	stopEvent() {
		return true;
	}

	destroy() {
		if (this.dom) {
			ReactDOM.unmountComponentAtNode(this.dom);
		}
		this.dom = null;
	}

	ignoreMutation() {
		return true;
	}
}
