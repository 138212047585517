import { createStyles, Loader, Tabs } from '@mantine/core';

import TabsList, { TabItem } from '@repo/common/components/TabsList';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';
import {
	type IQuery,
	type ISecodaEntity,
	useAuthUser,
	useIntegration,
	useIntegrationSpec,
} from '../../../api';
import Documentation from '../../../components/Documentation';
import EntityDiscussions from '../../../components/EntityDiscussions';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { LineageGraphWrapper } from '../../../components/LineageGraph/LineageGraph';
import UrlPreview from '../../../components/Preview/UrlPreview';
import { QueryList } from '../../../components/QueryList/QueryList';
import { trackEvent } from '../../../utils/analytics';
import { useTabNavigate } from '../../../utils/hook/useTabNavigate';
import { withParanthesis } from '../../../utils/shared.utils';
import type { ChartEntityTabsStore } from './ChartEntityTabsStore';

const useStyles = createStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		marginTop: theme.spacing.md,
	},
	tab: {
		paddingLeft: theme.spacing.sm,
		paddingRight: theme.spacing.sm,
	},
	panel: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
}));

export interface IChartEntityTabsProps {
	chart: ISecodaEntity;
	chartEntityTabsStore: ChartEntityTabsStore;
	tab: string | undefined;
}

function TabLoader() {
	return <Loader size={12} />;
}

function ChartEntityTabs({
	chart,
	chartEntityTabsStore: store,
	tab,
}: IChartEntityTabsProps) {
	const { classes } = useStyles();
	const { user, workspace } = useAuthUser();

	const isLineageDisabled = store.isLineageLoading;
	const lineageCount = withParanthesis(store.lineageCount);

	const queriesActive = (store.queryCount ?? 0) > 0;
	const isQueriesDisabled = store.isQueryLoading;
	const queriesCount = withParanthesis(store.queryCount);
	const showQueriesTab = store.isQueryLoading || queriesActive;

	const { data: integration } = useIntegration({ id: chart.integration ?? '' });
	const integrationSpec = useIntegrationSpec(chart.integration ?? '');

	const showPreviewTab =
		integrationSpec &&
		integrationSpec.type === 'builtin' &&
		integrationSpec.value.previewSupport;

	const isPreviewDisabled = false;

	const discussionsCount = withParanthesis(store.discussionCount);

	// Const relatedCount = withParanthesis(store.discussionCount);

	const documentationFirst = (chart.definition?.length || 0) > 2;

	const getDefaultTab = () => {
		if (showPreviewTab) {
			return 'preview';
		}
		if (documentationFirst) {
			return 'documentation';
		}
		return 'lineage';
	};

	const defaultTab = getDefaultTab();
	const currentTab = tab ?? defaultTab;

	const navigateToTab = useTabNavigate();

	const handleTabChange = (updatedTab: string) => {
		if (updatedTab === 'lineage') {
			trackEvent('chart/lineage/open', {}, user, workspace);
		}
		navigateToTab(updatedTab);
	};

	const tabsList: TabItem[] = [
		{
			value: 'preview',
			label: 'Preview',
			disabled: isPreviewDisabled,
			hidden: !showPreviewTab,
		},
		{
			value: 'lineage',
			label: 'Lineage',
			icon: store.isLineageLoading && <TabLoader />,
			disabled: isLineageDisabled,
		},
		{
			value: 'queries',
			label: 'Queries',
			hidden: !showQueriesTab,
		},
		{
			value: 'discussions',
			label: `Questions ${discussionsCount}`,
			icon: store.isDiscussionLoading && <TabLoader />,
		},
		{
			value: 'documentation',
			label: 'Documentation',
		},
	];

	return (
		<Tabs
			color="dark"
			classNames={{
				root: classes.root,
				tab: classes.tab,
				panel: classes.panel,
			}}
			value={currentTab}
			onTabChange={handleTabChange}
		>
			<TabsList tabs={tabsList} />
			{showPreviewTab && (
				<Tabs.Panel value="preview" pt="sm">
					<ErrorBoundary>
						<Suspense fallback={<Loader />}>
							<UrlPreview
								integration={integration?.type as string}
								entity={chart}
							/>
						</Suspense>
					</ErrorBoundary>
				</Tabs.Panel>
			)}
			<Tabs.Panel value="lineage" pt="sm">
				<LineageGraphWrapper
					id={chart.id}
					type={chart.native_type}
					store={store}
				/>
			</Tabs.Panel>

			<Tabs.Panel value="queries" pt="sm">
				<ErrorBoundary onError={() => store.setQueryCount(0)}>
					<Suspense fallback={<Loader />}>
						<QueryList
							entity={
								chart as unknown as ISecodaEntity & { creation_query?: IQuery }
							}
							store={store}
						/>
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>

			<Tabs.Panel value="discussions" pt="sm">
				<ErrorBoundary onError={() => store.setDiscussionCount(0)}>
					<Suspense fallback={<Loader />}>
						<EntityDiscussions entity={chart} store={store} />
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>

			<Tabs.Panel value="documentation" pt="sm">
				<Documentation entity={chart} />
			</Tabs.Panel>
		</Tabs>
	);
}

export default observer(ChartEntityTabs);
