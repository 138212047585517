import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useEntityDetailPageMatch } from './useCurrentEntityId';

function slugify(text: string): string {
	// Remove special characters and replace spaces with hyphens
	const slug = text
		.trim() // Remove leading and trailing spaces
		.toLowerCase() // Convert to lowercase
		.replace(/[^\w\s-]/g, '') // Remove non-word characters except hyphens and spaces
		.replace(/[\s]+/g, '-') // Replace spaces with hyphens
		.slice(0, 24); // Limit the length (adjust as needed)

	return slug;
}

export const titleWithUUID = (title: string, uuid: string) =>
	`${slugify(title)}-${uuid}`;

/**
 * Custom hook that redirects to a URL that includes both the title and UUID of an entity.
 * It constructs a new URL using the slugified title and the UUID from the match object.
 * If the new URL is different from the current one, it navigates to the new URL,
 * replacing the current one in the history.
 */
export const useRedirectURLIncludingTitle = (entity?: { title?: string }) => {
	const match = useEntityDetailPageMatch();
	const navigate = useNavigate();
	useEffect(() => {
		if (match && entity?.title) {
			const titleAndUUID = titleWithUUID(entity?.title, match.id);
			if (titleAndUUID !== match.titleAndUUID) {
				navigate(`/${match.pagePrefix}/${titleAndUUID}`, { replace: true });
			}
		}
	}, [match, entity?.title, navigate]);
};
