import { toggleMark } from 'prosemirror-commands';
import type { InputRule } from 'prosemirror-inputrules';
import type { MarkSpec } from 'prosemirror-model';
import markInputRule from '../lib/markInputRule';
import Mark, { MarkOptions } from './Mark';

export default class Bold extends Mark {
	get name() {
		return 'strong';
	}

	get schema(): MarkSpec {
		return {
			parseDOM: [{ tag: 'b' }, { tag: 'strong' }],
			toDOM: () => ['strong'],
		};
	}

	inputRules({ type }: MarkOptions): InputRule[] {
		return [markInputRule(/(?:\*\*)([^*]+)(?:\*\*)$/, type)];
	}

	keys({ type }: MarkOptions) {
		return {
			'Mod-b': toggleMark(type),
			'Mod-B': toggleMark(type),
		};
	}

	toMarkdown() {
		return {
			open: '**',
			close: '**',
			mixable: true,
			expelEnclosingWhitespace: true,
		};
	}

	parseMarkdown() {
		return { mark: 'strong' };
	}
}
