import { createStyles, MantineNumberSize, Skeleton } from '@mantine/core';

const useStyles = createStyles(
	(theme, { isSelected }: { isSelected?: boolean }) => ({
		skeleton: {
			border: `${theme.other.borderWidth.xl} solid ${isSelected ? '#0366d6' : 'transparent'}`,
		},
	})
);

interface BlockSkeletonProps {
	height?: MantineNumberSize;
	isSelected?: boolean;
}

export function BlockSkeleton({ height, isSelected }: BlockSkeletonProps) {
	const { classes } = useStyles({ isSelected });

	return <Skeleton className={classes.skeleton} height={height} />;
}
