import { FilterValue } from '@repo/common/components/Filter/types';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { deleteParamsFromUrl } from '../../utils/url';
import { captureError } from '../../web-tracing';
import { SearchFilterV2Store } from './store';
import { getSearchParamFilters } from './utils';

export function useFilterQueryString(store: SearchFilterV2Store) {
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const filterOptionsKeys = store.filterOptions.map((option) =>
			option === 'divider' ? 'divider' : option.type
		);

		if (!store.urlParamToSyncFilters) {
			return;
		}

		function syncFiltersIfDifferent(newFilters: FilterValue[]) {
			if (JSON.stringify(newFilters) !== JSON.stringify(store.values)) {
				store.setValues(newFilters);
			}
		}

		try {
			const filtersV1Params = getSearchParamFilters(
				searchParams,
				filterOptionsKeys
			);

			if (filtersV1Params?.length > 0) {
				// we have filters v1 params in URL
				syncFiltersIfDifferent(filtersV1Params);
			} else {
				// filters v2
				const filters = searchParams.get(store.urlParamToSyncFilters);

				if (filters) {
					syncFiltersIfDifferent(JSON.parse(filters));
				}
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error('[FILTERS]', 'failed to parse filters param', e);
			captureError(e);
			store.setValues([]);
			deleteParamsFromUrl(store.urlParamToSyncFilters);
		}
	}, [store, searchParams]);
}
