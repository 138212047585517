import {
	IconArrowsUpDown,
	IconLayoutGrid,
	IconPlugConnected,
} from '@tabler/icons-react';
import { concat, lowerCase, map, sortBy, uniqBy } from 'lodash-es';
import { makeAutoObservable } from 'mobx';
import { fetchIntegrationList } from '../../../../api';
import { EntityType } from '../../../../lib/types';
import type {
	FilterMenuItem,
	FilterMenuOption,
} from '../../../../pages/SearchPage/FilterCarousel/FilterCarousel.constants';
import {
	DEFAULT_FILTER_OPTIONS,
	FilterValue,
} from '../../../../pages/SearchPage/FilterCarousel/FilterCarousel.constants';
import { getEntityFilters } from '../../../../pages/SearchPage/utils';
import { getEntityTypeDisplayInfo } from '../../../../utils/entityDisplayUtils';
import { formatIntegrationType } from '../../../../utils/stringUtils';
import { LineageDirectionEnum } from '../../types';

class ImpactAnalysisStore {
	id: string | undefined = undefined;

	opened = false;

	filterMenuItems: Record<
		FilterValue.NATIVE_TYPE | FilterValue.INTEGRATION_NAME | 'direction',
		FilterMenuItem
	> = {
		[FilterValue.NATIVE_TYPE]: {
			label: 'Type',
			value: FilterValue.NATIVE_TYPE,
			icon: IconLayoutGrid,
			options: [],
			selectedOptions: [],
			isInclude: true,
		},
		[FilterValue.INTEGRATION_NAME]: {
			label: 'Integration',
			value: FilterValue.INTEGRATION_NAME,
			icon: IconPlugConnected,
			options: [],
			selectedOptions: [],
			isInclude: true,
		},
		direction: {
			label: 'Direction',
			// @ts-expect-error TS(2322)
			value: 'direction',
			icon: IconArrowsUpDown,
			type: [EntityType.all],
			options: [
				{
					label: 'Upstream',
					value: LineageDirectionEnum.UPSTREAM,
				},
				{
					label: 'Downstream',
					value: LineageDirectionEnum.DOWNSTREAM,
				},
			],
			isRadio: true,
			isInclude: true,
			selectedOptions: [
				{
					label: 'Downstream',
					value: LineageDirectionEnum.DOWNSTREAM,
				},
			],
		},
	};

	searchTerm = '';

	showSearch = false;

	constructor() {
		makeAutoObservable(this);
	}

	get queryParams() {
		return {
			direction: this.filterMenuItems.direction.selectedOptions[0].value,
			native_types: map(
				this.filterMenuItems.native_type.selectedOptions,
				'value'
			).join(','),
			integration_types: map(
				this.filterMenuItems.integration_name.selectedOptions,
				'value'
			).join(','),
			include_columns: true,
		};
	}

	async initialize() {
		const filters = await getEntityFilters();
		const nativeTypes = sortBy(uniqBy(filters.native_types, 'label'), [
			(nativeType) => lowerCase(nativeType.value as string),
		]);
		const nativeTypeFilters = map(nativeTypes, (n) => ({
			label: n.label,
			value: n.value,
			icon: getEntityTypeDisplayInfo(n.entityType)?.icon,
			entityType: n.entityType,
		}));

		this.filterMenuItems[FilterValue.NATIVE_TYPE].options = [
			...nativeTypeFilters,
			...DEFAULT_FILTER_OPTIONS.native_type.options,
		];

		const integrations = await fetchIntegrationList({});
		this.filterMenuItems[FilterValue.INTEGRATION_NAME].options = sortBy(
			map(uniqBy(integrations?.results, 'type'), (integration) => ({
				label: formatIntegrationType(integration.type),
				value: integration.type,
			})),
			'label'
		);
	}

	setId = (id: string) => {
		this.id = id;
	};

	setOpened = (opened: boolean) => {
		this.opened = opened;
	};

	setIsInclude = (filterValue: FilterValue, value: boolean) => {
		this.filterMenuItems[
			filterValue as FilterValue.NATIVE_TYPE | FilterValue.INTEGRATION_NAME
		].isInclude = value;
	};

	toggleSelectedOptions = (
		filterValue: FilterValue,
		filterOption: FilterMenuOption
	) => {
		if (
			this.filterMenuItems[
				filterValue as
					| FilterValue.NATIVE_TYPE
					| FilterValue.INTEGRATION_NAME
					| 'direction'
			].isRadio
		) {
			this.filterMenuItems[
				filterValue as
					| FilterValue.NATIVE_TYPE
					| FilterValue.INTEGRATION_NAME
					| 'direction'
			].selectedOptions = [filterOption];
		} else if (
			this.filterMenuItems[
				filterValue as
					| FilterValue.NATIVE_TYPE
					| FilterValue.INTEGRATION_NAME
					| 'direction'
			].selectedOptions.find((option) => option.value === filterOption.value)
		) {
			this.removeSelectedOptions(
				filterValue as
					| FilterValue.NATIVE_TYPE
					| FilterValue.INTEGRATION_NAME
					| 'direction',
				filterOption
			);
		} else {
			this.addSelectedOptions(
				filterValue as
					| FilterValue.NATIVE_TYPE
					| FilterValue.INTEGRATION_NAME
					| 'direction',
				filterOption
			);
		}
	};

	addSelectedOptions = (
		filterValue:
			| FilterValue.NATIVE_TYPE
			| FilterValue.INTEGRATION_NAME
			| 'direction',
		filterOption: FilterMenuOption
	) => {
		this.filterMenuItems[filterValue].selectedOptions = concat(
			this.filterMenuItems[filterValue].selectedOptions,
			filterOption
		);
	};

	removeSelectedOptions = (
		filterValue:
			| FilterValue.NATIVE_TYPE
			| FilterValue.INTEGRATION_NAME
			| 'direction',
		filterOption: FilterMenuOption
	) => {
		this.filterMenuItems[filterValue].selectedOptions = this.filterMenuItems[
			filterValue
		].selectedOptions.filter((option) => option.value !== filterOption.value);
	};

	clearSelectedOptions = (
		filterValue:
			| FilterValue.NATIVE_TYPE
			| FilterValue.INTEGRATION_NAME
			| 'direction'
	) => {
		this.filterMenuItems[filterValue].selectedOptions = [];
	};

	setSearchTerm = (searchTerm: string) => {
		this.searchTerm = searchTerm;
	};

	setShowSearch = (showSearch: boolean) => {
		this.showSearch = showSearch;
	};
}

export const impactAnalysisStore = new ImpactAnalysisStore();
