import {
	ApiGetSupportedEntitiesError,
	ApiGetSupportedEntitiesVariables,
	fetchApiGetSupportedEntities,
	SecodaNinjaPaginationOutputSuggestMonitorEntityOut,
	useApiContext,
} from '@repo/api-codegen';
import {
	useInfiniteQuery,
	UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { isNil } from 'lodash-es';

const useInfiniteApiGetSupportedEntities = (
	variables: Omit<ApiGetSupportedEntitiesVariables, 'page'>,
	options?: Omit<
		UseInfiniteQueryOptions<
			SecodaNinjaPaginationOutputSuggestMonitorEntityOut,
			ApiGetSupportedEntitiesError,
			SecodaNinjaPaginationOutputSuggestMonitorEntityOut
		>,
		'queryKey' | 'queryFn' | 'initialData'
	>
) => {
	const { fetcherOptions, queryKeyFn } = useApiContext();
	return useInfiniteQuery({
		queryKey: queryKeyFn({
			path: '/monitor/recommend/supported_entities/',
			operationId: 'apiGetSupportedEntities',
			variables,
		}),
		keepPreviousData: true,
		queryFn: ({ pageParam = 1 }) =>
			fetchApiGetSupportedEntities({
				...fetcherOptions,
				...{
					...variables,
					queryParams: {
						...variables.queryParams,
						page: pageParam,
					},
				},
			}),
		getPreviousPageParam: (firstPage) =>
			isNil(firstPage.meta.previous_page) ? null : firstPage.meta.previous_page,
		getNextPageParam: (lastPage) =>
			isNil(lastPage.meta.next_page) ? null : lastPage.meta.next_page,
		...options,
	});
};

export default useInfiniteApiGetSupportedEntities;
