import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import { authHeaders } from '../../api/common';
import { SecodaEntity } from './entity';
import { User } from './user';

export class ChangeRequest extends SecodaEntity {
	// @ts-expect-error TS(2564): Property 'user' has no initializer and is not defi... Remove this comment to see the full error message
	user: User;

	// @ts-expect-error TS(2564): Property 'entity' has no initializer and is not de... Remove this comment to see the full error message
	entity: SecodaEntity;

	// @ts-expect-error TS(2564): Property 'changes' has no initializer and is not d... Remove this comment to see the full error message
	changes: Record<string, string>;

	// @ts-expect-error TS(2564): Property 'applied' has no initializer and is not d... Remove this comment to see the full error message
	applied: boolean;

	constructor(obj: Partial<ChangeRequest>) {
		// @ts-expect-error TS(2345): Argument of type 'Partial<ChangeRequestTitle>' is not a... Remove this comment to see the full error message
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get fields() {
		return SecodaEntity.fields.concat(['user', 'entity', 'changes', 'applied']);
	}

	static get namespace() {
		return ['notification', 'change_requests'];
	}

	async accept() {
		await axios.post(`${this.apiPath}apply/`, {}, authHeaders());
		this.applied = true;
	}
}
