import { Group, Stack, createStyles } from '@mantine/core';
import { Button } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import type { Automation } from '../../../../../api';
import { useAuthUser } from '../../../../../api';
import { useAutomationStore } from '../../../../../pages/AutomationPage/context';
import { trackEvent } from '../../../../../utils/analytics';
import AutomationActionCardFilterRow from './AutomationActionCardFilterRow';
import { useAutomationActionCardFilterStore } from './context';

const useStyles = createStyles((theme) => ({
	group: {
		display: 'flex',
		gap: theme.spacing['2xs'],
	},
	button: {
		display: 'flex',
		width: 'fit-content',
	},
}));

interface AutomationActionCardFilterContentProps {
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
	searchKey?: string;
}

function AutomationActionCardFilterContent({
	handleAutomationUpdate,
	searchKey,
}: AutomationActionCardFilterContentProps) {
	const { classes } = useStyles();
	const store = useAutomationActionCardFilterStore();
	const { automation } = useAutomationStore();
	const { user, workspace } = useAuthUser();

	const handleAddFilter = () => {
		store.addFilter({});
		trackEvent('automation/add_filter_button/click', {}, user, workspace);
	};

	const filters = useMemo(() => {
		store.resetFilters();

		const filterSet = automation?.filter_config?.filter_sets.filter(
			(set) => set.key === searchKey
		);

		if (!filterSet || filterSet.length === 0) {
			return store.filters;
		}

		filterSet[0].filters.forEach((filter) => {
			store.addFilter({
				searchKey: filter.key,
				field: filter.field,
				condition: filter.condition,
				value: filter.value,
				display: filter.display,
			});
		});

		return store.filters;
	}, [automation?.filter_config?.filter_sets, store, searchKey]);

	return (
		<Stack>
			{filters.map((filter, i) => (
				<AutomationActionCardFilterRow
					field={filter.field}
					condition={filter.condition}
					value={filter.value}
					display={filter.display}
					key={filter.searchKey}
					filterSetSearchKey={searchKey}
					filterSearchKey={filter.searchKey}
					isLastElement={i === filters.length - 1}
					handleAutomationUpdate={handleAutomationUpdate}
				/>
			))}
			<Group className={classes.group}>
				<Button
					onClick={handleAddFilter}
					leftIconName="plus"
					className={classes.button}
				>
					Add filter
				</Button>
			</Group>
		</Stack>
	);
}

export default observer(AutomationActionCardFilterContent);
