import { Center, createStyles, Loader, MantineTheme } from '@mantine/core';
import { IconButton, ListBox, type IconNames } from '@repo/foundations';
import { size } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import type { SecodaEntity } from '../../lib/models';
import { search as searchEndpoint } from '../../lib/models';
import { ButtonDetails, EmptyState } from '../EmptyState';
import ResourceSelectorItem from './ResourceSelectorItem';

interface IResourceSelectorPopoverProps {
	targetButtonIconName: IconNames;
	targetButtonLabel: string;
	collectionId: string;
	types: string[];
	updateEntity: (entity: SecodaEntity) => void;
}

const useStyles = createStyles((theme: MantineTheme) => ({
	drawerLoadingWrapper: {
		height: 250,
	},
	emptyState: {
		padding: theme.spacing['2xs'],
	},
}));

function ResourceSelectorPopover({
	targetButtonIconName,
	targetButtonLabel,
	collectionId,
	types,
	updateEntity,
}: IResourceSelectorPopoverProps) {
	const [opened, setOpened] = useState(false);
	const [loading, setLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState('');
	const [results, setResults] = useState<SecodaEntity[]>([]);

	const handleSearch = (value: string) => {
		setLoading(true);
		setSearchTerm(value || '');

		const params = {
			search_term: value,
			page: 1,
			entity_type__in: types.join(),
		};
		searchEndpoint(params, false).then(({ data }) => {
			setResults(
				data.results.filter(
					(r: SecodaEntity) =>
						!r?.collections?.includes(collectionId) && r.id !== collectionId
				)
			);
			setLoading(false);
		});
	};

	const handleOnSelect = (entity: SecodaEntity) => {
		setResults((prev) => prev.filter((r) => r.id !== entity.id));
		updateEntity(entity);
		setOpened(false);
	};

	const { classes, theme } = useStyles();

	const emptyButtons: ButtonDetails[] = useMemo(
		() => [
			{
				name: 'Clear search',
				action: () => setSearchTerm(''),
				isPrimary: false,
				size: 'sm',
			},
		],
		[setSearchTerm]
	);

	const height =
		size(results) < 6
			? theme.other.space[60]
			: size(results) * theme.other.space[12];

	return (
		<ListBox
			opened={opened}
			onOpenChange={(newOpened) => {
				setSearchTerm('');
				handleSearch('');
				setOpened(newOpened);
			}}
			closeOnEscape
			placement="bottom-start"
		>
			<ListBox.Target>
				<IconButton
					iconName={targetButtonIconName}
					variant="tertiary"
					tooltip={targetButtonLabel}
				/>
			</ListBox.Target>
			<ListBox.ItemsDropdown
				usePortal
				search={{
					onChange: handleSearch,
					value: searchTerm,
					placeholder: 'Search resources',
				}}
				items={results}
				renderItem={(item, getProps) => (
					<ResourceSelectorItem
						item={item}
						onClick={handleOnSelect}
						isViewerUser={false}
						key={item.id}
						getProps={getProps}
					/>
				)}
			>
				{loading && (
					<Center h={height} w={theme.other.space[60]}>
						<Loader size="sm" />
					</Center>
				)}

				{size(results) === 0 && !loading && (
					<EmptyState
						iconName="search"
						title="No results found"
						description="No resources or invalid search"
						buttons={emptyButtons}
						includeGoBack={false}
						size="sm"
						width={theme.other.space[60]}
						className={classes.emptyState}
						stateHeight={height}
					/>
				)}
			</ListBox.ItemsDropdown>
		</ListBox>
	);
}

export default observer(ResourceSelectorPopover);
