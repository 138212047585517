import { Box, createStyles, Image } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { iconSize } from '@repo/theme/primitives';
import { vectorGraphicFromIntegrationType } from '../../../utils/integrationLogo';
import { useSpecCardStyles } from '../../IntegrationSpecCard/IntegrationSpecCard.helpers';
import { getMonitorSpec } from '../monitors';
import { MonitorType } from '../types';
interface MonitorIconProps {
	type: MonitorType;
}

const useStyles = createStyles((theme) => ({
	container: {
		alignItems: 'center',
		position: 'relative',
	},
	sourceLogo: {
		position: 'absolute',
		backgroundColor: 'white',
		borderRadius: '50%',
		padding: theme.other.space[0.5],
		bottom: `-${iconSize.sm / 3}px`,
		right: `-${iconSize.sm / 3 + 2}px`,
	},
}));

export function MonitorIcon({ type }: MonitorIconProps) {
	const { classes: cardClasses, theme, cx } = useSpecCardStyles();
	const { classes } = useStyles();

	const { iconName, iconColor, backgroundColor, integrationType } =
		getMonitorSpec(type);

	const src = integrationType
		? vectorGraphicFromIntegrationType(integrationType)
		: null;

	return (
		<Box
			className={cx(cardClasses.logoWrapper, classes.container)}
			style={{ backgroundColor: theme.other.getColor(backgroundColor) }}
		>
			<Icon name={iconName} color={iconColor} />
			{src && (
				<Box className={classes.sourceLogo}>
					<Image height={iconSize.sm} width={iconSize.sm} src={src} />
				</Box>
			)}
		</Box>
	);
}
