import { Box, createStyles, Group, Tooltip } from '@mantine/core';
import { Text } from '@repo/foundations';
import { isEmpty } from 'lodash-es';
import { useCallback, useMemo, useRef } from 'react';
import { type ISecodaEntity } from '../../api';
import { FilterOptionType } from '../../components/Filter';
import { ROW_HEIGHT } from '../../components/TableV2/TableV2.styles';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { useColumns as useCatalogColumns } from '../TeamCatalogPage/TeamCatalogPage.hooks';

const useStyles = createStyles(() => ({
	ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
}));

function TrashTitleRender({
	record,
}: {
	record: {
		id: string;
		title?: string;
		title_cased?: string;
		icon?: string;
		onExpandClick?: (id: string) => void;
		paramExpandedLevel?: number;
		paramExpandedParentPrefix?: string;
		paramExpanded?: boolean;
	};
}) {
	const { classes, theme } = useStyles();

	const textRef = useRef<HTMLDivElement>(null);

	const isEllipsisHidden = useCallback(
		(e: HTMLDivElement | null) => (e ? e.offsetWidth >= e.scrollWidth : false),
		[]
	);

	return (
		<Group
			data-testid="title-render"
			spacing={0}
			sx={{
				flexWrap: 'nowrap',
				height: ROW_HEIGHT,
			}}
		>
			{record.icon && <Box pr="2xs">{record.icon}</Box>}
			<Box
				display="flex"
				h="100%"
				w="100%"
				sx={{
					overflow: 'hidden',
				}}
			>
				<Tooltip
					maw={theme.other.space[90]}
					sx={{
						overflowWrap: 'break-word',
						whiteSpace: 'normal',
					}}
					zIndex={1000}
					withinPortal
					position="top"
					pos="absolute"
					openDelay={1000}
					label={record?.title_cased ?? record?.title ?? ''}
					hidden={
						isEmpty(record?.title_cased ?? record?.title) ||
						isEllipsisHidden(textRef.current)
					}
				>
					<Text
						role="link"
						ref={textRef}
						sx={{
							lineHeight: `${ROW_HEIGHT}px`,
						}}
						className={classes.ellipsis}
						color="text/primary/default"
						fw={600}
					>
						{isEmpty(record?.title_cased ?? record?.title)
							? 'Untitled'
							: (record?.title_cased ?? record?.title)}
					</Text>
				</Tooltip>
			</Box>
		</Group>
	);
}

export const useColumns = (): ExtendedDataTableColumn<ISecodaEntity>[] => {
	const catalogColumns = useCatalogColumns();

	const columns: ExtendedDataTableColumn<ISecodaEntity>[] = useMemo(() => {
		const otherColumns = catalogColumns.filter(
			(column) => !['title'].includes(column.accessor)
		);
		return [
			{
				accessor: 'title',
				title: 'Title',
				render: (record) => <TrashTitleRender record={record} />,
				filterOptionType: FilterOptionType.TITLE,
			},
			...otherColumns,
		];
	}, [catalogColumns]);

	return columns;
};
