import { createContext, ReactNode, useContext, useState } from 'react';

type JobType = 'PULL' | 'PUSH';

type DatabuilderJobPollingState = {
	[integrationId: string]: {
		[jobType in JobType]?: boolean;
	};
};

type DatabuilderJobPollingContextType = {
	pollingState: DatabuilderJobPollingState;
	setPollingState: (
		integrationId: string,
		jobType: JobType,
		state: boolean
	) => void;
};

const DatabuilderJobPollingContext = createContext<
	DatabuilderJobPollingContextType | undefined
>(undefined);

export function DatabuilderJobPollingProvider({
	children,
}: {
	children: ReactNode;
}) {
	const [pollingState, setPollingState] = useState<DatabuilderJobPollingState>(
		{}
	);

	const setPollingStateHandler = (
		integrationId: string,
		jobType: JobType,
		state: boolean
	) => {
		setPollingState((prevState) => ({
			...prevState,
			[integrationId]: {
				...prevState[integrationId],
				[jobType]: state,
			},
		}));
	};

	return (
		<DatabuilderJobPollingContext.Provider
			value={{ pollingState, setPollingState: setPollingStateHandler }}
		>
			{children}
		</DatabuilderJobPollingContext.Provider>
	);
}

export const useDatabuilderJobPollingContext =
	(): DatabuilderJobPollingContextType => {
		const context = useContext(DatabuilderJobPollingContext);
		if (context === undefined) {
			throw new Error(
				'usePollingContext must be used within a PollingProvider'
			);
		}
		return context;
	};
