import { Group, Menu, UnstyledButton } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { useState } from 'react';
import { SortOption } from '../Filter/constants';
import { SortValue } from '../Filter/types';
import { useStyles } from './SortMenu.styles';

interface SortMenuProps {
	value: SortValue;
	onChange: (value: SortValue) => void;
	options: SortOption[];
}

export function SortMenu({ value, onChange, options }: SortMenuProps) {
	const { classes } = useStyles();
	const [opened, setOpened] = useState(false);

	const selectedOption = options.find((option) => option.value === value);
	return (
		<Menu onChange={setOpened}>
			<Menu.Target>
				<Group className={classes.root}>
					<Text color="text/secondary/default" className={classes.targetText}>
						Sort by:
					</Text>
					<UnstyledButton className={classes.target}>
						<Text className={classes.targetText}>
							{selectedOption?.label || 'none'}
						</Text>
					</UnstyledButton>
					<Icon name={opened ? 'chevronUp' : 'chevronDown'} iconPadding={0} />
				</Group>
			</Menu.Target>
			<Menu.Dropdown>
				{options.map((option) => (
					<Menu.Item
						key={option.value}
						onClick={() => onChange(option.value)}
						rightSection={
							selectedOption?.value === option.value && <Icon name="check" />
						}
					>
						{option.label}
					</Menu.Item>
				))}
			</Menu.Dropdown>
		</Menu>
	);
}
