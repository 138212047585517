import { Box, createStyles, Stack } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useMemo, useState } from 'react';
import SecodaNavBar from '../SecodaAppShell/SecodaNavBar/SecodaNavBar';
import { sideBarStore } from '../SecodaAppShell/SideBar/store';
import { NavBarContext } from './NavBarContext';

interface IBodyWrapperProps {
	children: React.ReactNode;
	hideNavbar?: boolean;
}

const useStyles = createStyles(
	(
		_,
		{
			showSideBar,
			sideBarWidth,
		}: {
			showSideBar: boolean;
			sideBarWidth: number;
		}
	) => ({
		container: {
			paddingLeft: showSideBar ? sideBarWidth : 0,
			transition: 'margin-left 0.1ms ease-in-out',
			height: '100vh',
			overflow: 'hidden',
			display: 'flex',
			flexGrow: 1,
			flexDirection: 'column',

			'@media print': {
				paddingLeft: 0,
			},
		},
		bodyWrapper: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			overflow: 'auto',
		},
	})
);

function BodyWrapper({ children, hideNavbar = false }: IBodyWrapperProps) {
	const { classes, cx } = useStyles({
		showSideBar: sideBarStore.showSideBar,
		sideBarWidth: sideBarStore.width,
	});

	const [leftNode, setLeftNode] = useState<ReactNode | null>(null);
	const [rightNode, setRightNode] = useState<ReactNode | null>(null);

	const providerValue = useMemo(
		() => ({ setLeftNode, setRightNode, leftNode, rightNode }),
		[leftNode, rightNode]
	);

	return (
		<NavBarContext.Provider value={providerValue}>
			<Stack className={cx(classes.container, 'BodyWrapper')} spacing={0}>
				{!hideNavbar && <SecodaNavBar />}
				<Box className={classes.bodyWrapper}>{children}</Box>
			</Stack>
		</NavBarContext.Provider>
	);
}

export default observer(BodyWrapper);
