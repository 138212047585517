import {
	ActionIcon,
	createStyles,
	Group,
	Loader,
	ScrollArea,
	Stack,
} from '@mantine/core';
import { Button, Icon, Text } from '@repo/foundations';
import { useCallback } from 'react';
import { useAuthUser } from '../../api';
import { useAiEnabled } from '../../hooks/useAIEnabled';
import { useForceReRender } from '../../utils/hook/useForceReRender';
import { ExpandCollapse } from '../ExpandCollapse/ExpandCollapse';
import { RichEditor } from '../RichEditor';
import { useDescriptionEditorAi } from './useDescriptionEditorAi';

export interface DescriptionEditorProps {
	entityId?: string;
	readOnly?: boolean;
	value: string;
	onChange: (value: string) => void;
	save?: () => void;
	placeholder?: string;
	autoFocus?: boolean;
	withAi?: boolean;
	dataTestId?: string;
}

const useStyles = createStyles(
	(theme, { readOnly }: { readOnly: boolean }) => ({
		aiButton: {
			position: 'absolute',
			top: 0,
			right: 0,

			'@media print': {
				display: 'none',
			},
		},
		wrapper: {
			position: 'relative',
			width: '100%',
			gap: theme.spacing.xs,
			color: readOnly
				? theme.other.getColor('text/secondary/default')
				: theme.other.getColor('text/primary/default'),
		},
		scrollbarViewport: {
			maxWidth: '100%',
		},
	})
);

export function DescriptionEditor({
	entityId,
	value,
	onChange,
	save,
	readOnly = false,
	placeholder,
	autoFocus = false,
	withAi = true,
	dataTestId,
}: DescriptionEditorProps) {
	const { classes, theme } = useStyles({
		readOnly,
	});

	const { isViewerOrGuestUser } = useAuthUser();
	const { enableAi } = useAiEnabled();

	const { key: editorReRenderKey, forceUpdate } = useForceReRender();

	const {
		isStreaming,
		hasAIGeneratedText,
		aiGeneratedDescription,
		generateDescription,
		keepDescription,
		rejectDescription,
		stopAi,
	} = useDescriptionEditorAi({
		entityId,
		onKeep: (aiText: string) => {
			// Propagate changes to parent component
			onChange(aiText);
			save?.();
		},
		refreshEditor: forceUpdate,
	});

	const handleChange = useCallback(
		(updatedValue?: string) => {
			if (hasAIGeneratedText) {
				// Don't allow editing while Secoda AI is writing.
				return;
			}

			onChange(updatedValue ?? '');
		},
		[hasAIGeneratedText, onChange]
	);

	const showAiButton =
		// AI is enabled in the workspace
		enableAi &&
		// user can make changes
		!isViewerOrGuestUser &&
		// user can make changes
		!readOnly &&
		// AI is not writing
		!isStreaming &&
		// AI has not written anything
		!hasAIGeneratedText &&
		// AI is enabled for this component
		withAi;

	const editorValue = !hasAIGeneratedText ? value : aiGeneratedDescription;

	// Disable editing when Secoda AI is writing
	const editorReadOnly = isStreaming || hasAIGeneratedText || readOnly;

	return (
		<Stack className={classes.wrapper}>
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
			{/* @ts-ignore poorly typed component by Mantine */}
			<ScrollArea.Autosize
				offsetScrollbars
				classNames={{ viewport: classes.scrollbarViewport }}
			>
				<ExpandCollapse maxHeight={200}>
					<RichEditor
						key={editorReRenderKey}
						placeholder={placeholder ?? 'Add description...'}
						readOnly={editorReadOnly}
						initialValue={editorValue}
						onChangeCallback={handleChange}
						disableTopGap
						limited
						autoFocus={autoFocus}
						dataTestId={dataTestId}
					/>
				</ExpandCollapse>
			</ScrollArea.Autosize>
			{showAiButton && (
				<Group className={classes.aiButton}>
					<ActionIcon variant="subtle" onClick={generateDescription}>
						<Icon name="sparkles" color="icon/ai/default" />
					</ActionIcon>
				</Group>
			)}
			{isStreaming && (
				<Group position="apart">
					<Group spacing="xs">
						<Icon name="sparkles" color="icon/ai/default" />
						<Text size="sm" color="icon/ai/default" weight="semibold">
							Secoda AI is writing
						</Text>
						<Loader
							color={theme.other.getColor('icon/ai/default')}
							size="sm"
							variant="dots"
						/>
					</Group>
					<Button leftIconName="playerStop" variant="default" onClick={stopAi}>
						Stop
					</Button>
				</Group>
			)}
			{!isStreaming && hasAIGeneratedText && (
				<Group position="apart">
					<Button
						variant="default"
						leftIconName="x"
						onClick={rejectDescription}
					>
						Reject
					</Button>
					<Group>
						<Button
							variant="default"
							leftIconName="reload"
							onClick={generateDescription}
						>
							Try again
						</Button>
						<Button
							leftIconName="check"
							onClick={keepDescription}
							variant="primary"
						>
							Keep
						</Button>
					</Group>
				</Group>
			)}
		</Stack>
	);
}
