import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Icon } from '@repo/foundations';
import type { MouseEvent } from 'react';
import { useCallback, useMemo } from 'react';
import { useMetricInfiniteList } from '../../../api';
import { useEntityBreadcrumbTeam } from '../../../hooks/useEntityBreadcrumbs';
import type { DocumentTreeNode } from '../constants';
import { DataTreeLoader } from '../DataTreeLoader';
import { LoadMore, TreeNode } from '../TreeNode';
import { getMetricNodeChildren, getNodeFromMetric } from './utils';

interface MetricTreeProps {
	teamId: string;
}

export function MetricTree({ teamId }: MetricTreeProps) {
	const {
		data: result,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
	} = useMetricInfiniteList({
		filters: {
			teams: teamId,
			hidden: 'false',
			parent__isnull: 'true',
			sort: {
				field: 'title',
				order: 'asc',
			},
		},
	});

	const nodes = useMemo(
		() => result?.pages.map((metric) => getNodeFromMetric(metric)),
		[result]
	);

	const { setTeamBreadcrumb } = useEntityBreadcrumbTeam();

	const navigateHandler = useNavigateHandler();

	const handleNavigate = useCallback(
		(event: MouseEvent, node: DocumentTreeNode) => {
			setTeamBreadcrumb(teamId);
			navigateHandler(event)(`/metrics/${node.id}`);
		},
		[]
	);

	if (!nodes) {
		return <DataTreeLoader />;
	}

	return (
		<>
			{nodes.map((node) => (
				<TreeNode
					key={node.id}
					node={node}
					onClick={handleNavigate}
					getIcon={() => (
						<Icon name="chartHistogram" color="icon/primary/default" />
					)}
					level={0}
					getLabel={(n) => n.name}
					onLoadChildren={getMetricNodeChildren}
				/>
			))}
			{hasNextPage && (
				<LoadMore
					level={0}
					onClick={fetchNextPage}
					isLoading={isFetchingNextPage}
				/>
			)}
		</>
	);
}
