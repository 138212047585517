import { ListQueryParams } from '@repo/common/interfaces/params';
import type { QueryFunctionContext, QueryKey } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import type {
	IApiListResponse,
	IBaseModel,
	IBaseModelListArgs,
	Namespace,
} from '../../types';

export function getDefaultListQueryFn<TApiResponseData extends IBaseModel>(
	{ page = 1, filters = {} }: ListQueryParams,
	namespace: Namespace
) {
	const defaultQueryFn = async ({ signal }: QueryFunctionContext<QueryKey>) => {
		const url = getEndpoints(namespace).root();

		const params = { page, ...filters };

		const { data } = await apiClient.get<IApiListResponse<TApiResponseData>>(
			url,
			{ params, signal }
		);

		return data;
	};

	return defaultQueryFn;
}

/**
 * Hook for fetching a list of base models.
 *
 * **Usecase**: Fetching & caching a paginated list of models
 *
 * @param params Params for react-query. Page and filters are passed to the
 * default list queryFn if not provided.
 * - page => Page number for API pagination
 * - filters => Filters to apply to the API request (must match Django's model queries)
 * @returns React Query hook for pagination
 */
function useBaseModelList<
	TApiResponseData extends IBaseModel,
	TData = IApiListResponse<TApiResponseData>,
	TError = unknown,
>({
	queryKey,
	namespace,
	queryFn: customQueryFn,
	page,
	filters,
	options = {},
}: IBaseModelListArgs<IApiListResponse<TApiResponseData>, TData, TError>) {
	const queryFn =
		customQueryFn ||
		getDefaultListQueryFn<TApiResponseData>({ page, filters }, namespace);

	return useQuery<IApiListResponse<TApiResponseData>, TError, TData>(
		queryKey,
		queryFn,
		{
			suspense: false,
			keepPreviousData: true,
			...options,
		}
	);
}

export default useBaseModelList;
